<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="addialogVisible"
            custom-class="self-drawer"
            size="30%"
            :wrapperClosable="false"
            :before-close="closeDrawer"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                :LS="LS"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <template v-slot:url>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                <span class="must">*</span>
                                图片
                            </div>
                        </template>
                        <div class="upload-box">
                            <Upload
                                v-if="showUpload"
                                ref="uploadbox1"
                                :limitNum="limitNum"
                                :width="90"
                                :height="90"
                                :accept="accept"
                                :fileType="'image'"
                                :ossClientCur="ossClient"
                                :showImgList="showImgList"
                            ></Upload>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:colorUrl>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">色块</div>
                        </template>
                        <div class="upload-box">
                            <Upload
                                ref="uploadbox2"
                                :limitNum="1"
                                :width="90"
                                :height="90"
                                :accept="accept"
                                :fileType="'image'"
                                :ossClientCur="ossClient"
                            ></Upload>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="closeDrawer">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit" v-preventReClick="5000">
                    确定
                </el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import getOssMixin from '@/mixins/getOssMixin';
import Upload from '@/components/upload';
import { getQuestionsList, getStyleFileTree, addStyle } from '@/api/mkt/style-test';
export default {
    name: 'detailDrawer',
    mixins: [getOssMixin],
    components: { Upload },
    props: {},
    data() {
        return {
            accept: ['jpg', 'gif', 'jpeg', 'png', 'JPG', 'JPEG'],
            showImgList: [],
            addialogVisible: false,
            title: '新增图片',
            LS: { 'min-width': '120px' }, //设置label宽度
            columns: {
                questionsId: {
                    key: 'questionsId',
                    name: '题目',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'id',
                            label: 'questionsName',
                        },
                    },
                    rules: [{ required: true, message: '请输入题目', trigger: 'change' }],
                },
                styleCategory: {
                    key: 'styleCategory',
                    name: '测试分类',
                    type: 'input',
                    options: {
                        placeholder: '请选择',
                        disabled: true,
                    },
                    rules: [{ required: true, message: '请选择测试分类', trigger: 'change' }],
                },
                ofCategory: {
                    name: '所属类别',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'sysLabel',
                            label: 'sysLabel',
                        },
                        filterable: true,
                    },
                    rules: [{ required: true, message: '所属类别', trigger: 'change' }],
                },
                // ofColor: {
                //     name: '所属色彩',
                //     type: 'select',
                //     options: {
                //         placeholder: '请选择',
                //         options: [],
                //         props: {
                //             value: 'sysLabel',
                //             label: 'sysLabel',
                //         },
                //         filterable: true,
                //     },
                // },

                // titleName: {
                //     key: 'titleName',
                //     name: '标题',
                //     type: 'input',
                //     hidden: false,
                //     options: {
                //         placeholder: '',
                //         // maxlength: 20,
                //         // showWordLimit: true,
                //     },
                // },
                // colorName: {
                //     key: 'colorName',
                //     name: '色块名',
                //     type: 'hidden',
                //     // type: 'input',
                //     hidden: false,
                //     options: {
                //         placeholder: '',
                //         // maxlength: 20,
                //         // showWordLimit: true,
                //     },
                // },
                url: {
                    name: '图片',
                    type: 'text',
                    hidden: false,
                    options: {
                        placeholder: '',
                        defaultValue: [],
                    },
                    rules: [{ required: true, message: '请输入标题' }],
                },
                // colorUrl: {
                //     name: '色块',
                //     type: 'hidden',
                //      type: 'text',
                //      hidden: true,
                //     options: {
                //         placeholder: '',
                //     },
                // },
            },
            result: {
                questionsId: '',
                styleCategory: '',
                ofCategory: '',
                ofColor: '',
                titleName: '',
                colorName: '',
                url: [],
            },
            limitNum: 1,
            showUpload: false,
        };
    },
    async created() {
        this.getQuestionsList();
        this.getStyleFileTree();
        await this.getOss();
    },
    methods: {
        open() {
            this.addialogVisible = true;
            this.showUpload = true;
            this.title = '新增图片';
            this.showImgList = [];
            this.$nextTick(() => {
                this.$refs.groupform.$refs['CRM_TAG_GROUP'].clearValidate();
                this.limitNum = 400;
            });
            console.log(this.limitNum, 88);
            this.result = {
                questionsId: '',
                styleCategory: '',
                ofCategory: '',
                ofColor: '',
                titleName: '',
                colorName: '',
                url: [],
            };
        },
        edit(row) {
            this.addialogVisible = true;
            this.showUpload = true;
            this.limitNum = 1;
            this.getInfo(row);
            this.title = '编辑图片';
            this.showImgList = [{ url: row.url }];
        },
        closeDrawer(type) {
            this.addialogVisible = false;
            this.result = {
                questionsId: '',
                styleCategory: '',
                ofCategory: '',
                url: [],
            };
            this.$refs.uploadbox1.imgList = [];
            if (type == 'update') {
                // 清空数据
                this.$emit('handleClose');
            }
        },
        getInfo(row) {
            row.questionsId = row.questionsId + '';
            this.result = { ...row };
            this.twoLeverStyle =
                (this.oneLeverStyle.filter(d => d.sysLabel == row.styleCategory)[0] || {})
                    .childList || [];
            this.columns.ofCategory.options.options = this.twoLeverStyle || [];
            this.threeLeverStyle =
                (this.twoLeverStyle.filter(d => d.sysLabel == row.ofCategory)[0] || {}).childList ||
                [];
            setTimeout(() => {
                this.$refs.uploadbox1.setList([{ url: row.url }]);
                // if (row.colorUrl) {
                //     this.$refs.uploadbox2.setList([{ url: row.colorUrl }]);
                // }
            }, 500);
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.$refs.uploadbox1.imgList, 10);
                let urls = this.$refs.uploadbox1.imgList;
                this.result.url = urls.map(item => item.url) || [];
                // this.result.url = (this.$refs.uploadbox1.imgList[0] || {}).url || '';
                // this.result.colorUrl = (this.$refs.uploadbox2.imgList[0] || {}).url || '';
                if (!this.result.url?.length) {
                    this.$message.warning('请上传图片');
                    return false;
                }
                let params = {
                    ...this.result,
                };
                addStyle(params).then(res => {
                    if (res.errorCode == 200) {
                        let message = !this.result.id ? '添加成功' : '保存成功';
                        this.$message.success(message);
                        this.closeDrawer('update');
                    } else {
                        this.$message.info(res.message);
                    }
                });
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            console.log('---changeFn', data);
            if (data.row.key == 'questionsId') {
                if (data.value == 18) {
                    this.result.styleCategory = '风格测试';
                    this.$set(this.result, 'styleCategory', '风格测试');
                    this.twoLeverStyle =
                        (this.oneLeverStyle.filter(d => d.sysLabel == '风格测试')[0] || {})
                            .childList || [];
                } else {
                    this.result.styleCategory = '感觉测试';
                    this.$set(this.result, 'styleCategory', '感觉测试');
                    this.twoLeverStyle =
                        (this.oneLeverStyle.filter(d => d.sysLabel == '感觉测试')[0] || {})
                            .childList || [];
                }
                this.columns.ofCategory.options.options = this.twoLeverStyle || [];
                this.result.ofCategory = '';
            }
            if (data.row.key == 'styleCategory') {
                this.twoLeverStyle =
                    (this.oneLeverStyle.filter(d => d.sysLabel == data.value)[0] || {}).childList ||
                    [];
                this.columns.ofCategory.options.options = this.twoLeverStyle || [];
                this.result.ofCategory = '';
                // this.columns.ofColor.options.options = [];
                // this.result.ofColor = '';
            }
            if (data.row.key == 'ofCategory') {
                this.threeLeverStyle =
                    (this.twoLeverStyle.filter(d => d.sysLabel == data.value)[0] || {}).childList ||
                    [];
                // this.columns.ofColor.options.options = this.threeLeverStyle || [];
                // this.result.ofColor = '';
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        // 获取select 列表
        getQuestionsList() {
            getQuestionsList().then(res => {
                if (res?.isSuccess) {
                    if (res.data.length) {
                        res.data.map(d => (d.questionsName = d.orderSort + d.questionsName));
                    }
                    this.columns.questionsId.options.options = res.data || [];
                }
            });
        },
        getStyleFileTree() {
            getStyleFileTree().then(res => {
                if (res?.isSuccess) {
                    this.oneLeverStyle = res.data;
                    this.columns.styleCategory.options.options = this.oneLeverStyle || [];
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .upload-box {
    .avatar-uploader-icon {
        font-size: 22px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
.must {
    color: red;
    vertical-align: 2px;
}
</style>
