<template>
    <div>
        <el-dialog
            title="补充信息"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :before-close="close"
            width="30%"
        >
            <sc-form-v2
                tablename="SUPPLE"
                :columnsNum="1"
                :result="result"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @removeTag="removeTag"
                ref="From2"
            >
                <template v-slot:labelsAll>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">标签:</div>
                        </template>
                        <div style="text-align: left">
                            <el-cascader
                                v-model="selfSelect"
                                :options="labelsAllOptions"
                                :props="props"
                                clearable
                                @change="changeSelect"
                                @remove-tag="removeSelect"
                                ref="selfSelect"
                                style="width:100%;"
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini" :loading="loading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mixins from '@/mixins';

import { info, approval, worksEdit } from '@/api/mkt/article';
let selOldData = {};
export default {
    name: 'dialog-remark',
    mixins: [mixins],
    data() {
        return {
            selfSelect: [],
            labelsAllOptions: [],
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            localRow: {},
            dialogFormVisible: false,
            form: {
                remark: '',
            },

            result: {
                labelsAll: [],
                id: '',
            },

            loading: false,
            columns: [
                {
                    key: 'labelsAll',
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    // rules: [{ required: true, message: '请选择标签' }],
                },
            ],
            levelList: [],
        };
    },
    watch: {
        normalLabelList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'labelsAll') {
                        const result = val.map(item => {
                            const children =
                                item.children?.length &&
                                item.children.map(every => {
                                    return {
                                        id: every?.id,
                                        labelGroupId: every?.labelGroupId,
                                        labelName: every?.labelName,
                                        parentId: every?.parentId,
                                        properties: every?.properties,
                                    };
                                });
                            return {
                                ...item,
                                children: children,
                            };
                        });
                        item.options.options = result;
                        this.labelsAllOptions = result;
                    }
                });
            }
        },
    },
    created() {
        this.getLabelCommonList();
    },
    methods: {
        changeSelect(val) {
            console.log(val, 'val--------');
        },
        removeSelect(val) {
            console.log(val, 'removeSelect');
        },
        changeFn(val) {},
        removeTag(val) {
            this.deleteData = val.value;
        },
        changeLabel(arr, deleteData) {
            console.log(arr, deleteData);
        },
        openDrawer(row, type) {
            this.type = type;
            this.dialogFormVisible = true;
            this.localRow = { ...row };

            if (row) {
                this.result.id = row.id;
            }
        },
        submit() {
            //有标签属性
            let labelIdArr = [];
            let childAll = [];
            if (this.selfSelect.length) {
                this.labelsAllOptions.map(item => {
                    item &&
                        item.children &&
                        item.children.map(child => {
                            childAll.push({
                                labelId: child.id,
                                labelName: child.labelName,
                                properties: 2, //切片 新增的标签性质 自定义    1系统，2自定义 3运营 6AI标签
                                groupType: item.groupType, // 1静态标签 2模型标签 3预测标签 4运营标签 6AI标签组
                                isHighlight: 1,
                                // AI标签修改之后记得properties变成自定义标签、groupType等于AI标签组
                            });
                        });
                });
                this.selfSelect.map(item => {
                    childAll.map(child => {
                        if (item[1] == child.labelId) {
                            labelIdArr.push(child);
                        }
                    });
                });
            }
            console.log('-------------', this.selfSelect, labelIdArr);
            let data = {
                id: this.result.id,
                list: labelIdArr,
            };
            this.close(data);
        },
        close(data) {
            this.dialogFormVisible = false;
            this.result.labelsAll = [];
            this.selfSelect = [];
            this.$emit('closeDrawer', data);
        },
    },
};
</script>
