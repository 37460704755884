<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:qq>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">插槽：</div>
                    </template>
                    <div style="text-align: left">
                        <div class="input-border">
                            <el-input size="small"></el-input>
                        </div>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
export default {
    data() {
        return {
            columns: {
                name: {
                    name: '姓名',
                    type: 'input',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
            },
            result: {
                k1: '123',
            },
            LS: { 'min-width': '80px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: false, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
        };
    },
    methods: {
        changeFn() {},
        clickFn() {
            this.$store.commit('params/SET_ARTICLE_PARAM', { name: 1, type: 2 }); //同步方法保存
            // this.$store.dispatch('params/SET_ACTIVEMENU', '');  //异步方法保存
            console.log('--------this.$store.state.', this.$store.state.params.articleParams);
        },
    },
};
</script>
<style lang="scss" scoped></style>
