<template>
    <div class="content">
        <div class="top-btn-box" ref="topBox">
            <el-button type="primary" size="small" @click="editRow">新增分类</el-button>
            <!-- <el-button
                :disabled="!selectRowlist.length"
                type="primary"
                size="small"
                @click="editCatestate(0)"
            >
                上架
            </el-button>
            <el-button
                :disabled="!selectRowlist.length"
                type="primary"
                size="small"
                @click="editCatestate(1)"
            >
                下架
            </el-button> -->
            <!-- <el-button
                :disabled="!selectRowlist.length"
                type="primary"
                size="small"
                @click="opCheck"
            >
                维护栏目
            </el-button> -->
        </div>
        <div v-if="TableHeight">
            <PageList
                ref="multipleTable"
                :tableHead="tableHead"
                :tableData="list"
                @selection-change="selectChange"
                @select-all="selectAll"
                row-key="value"
                :defaultExpandAll="false"
                :TableHeight="TableHeight"
            >
                <template v-slot:special-column>
                    <el-table-column
                        :label-class-name="isHasSearch ? 'disabledSelection' : ''"
                        type="selection"
                        width="55"
                        :selectable="checkboxInit"
                    ></el-table-column>
                    <el-table-column width="230">
                        <template slot="header">
                            分类项
                            <i class="el-icon-right"></i>
                            <span style="color: rgb(64, 158, 255)">
                                已选{{ selectRowlist.length }}个
                            </span>
                        </template>
                        <template slot-scope="scope">{{ scope.row.label }}</template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">排序值</template>
                        <template slot-scope="scope">
                            {{ scope.row.orderSort }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column>
                        <template slot="header">适用栏目</template>
                        <template slot-scope="scope">
                            {{ isShowFormatter(scope.row) }}
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column>
                        <template slot="header">上架状态</template>
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.shelfStatus"
                                active-value="0"
                                inactive-value="1"
                                active-color="#13ce66"
                                inactive-color="#eee"
                                @change="handSwitch('shelfStatus', scope.row)"
                            ></el-switch>
                        </template>
                    </el-table-column> -->
                    <el-table-column>
                        <template slot="header">启用状态</template>
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.isShow"
                                :active-value="0"
                                :inactive-value="1"
                                active-color="#13ce66"
                                inactive-color="#eee"
                                @change="handSwitch('isShow', scope.row)"
                            ></el-switch>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="editRow(scope.row)">编辑</el-button>
                            <!-- <el-button
                                type="text"
                                v-if="
                                    !scope.row.isTop &&
                                        (!scope.row.children || !scope.row.children.length)
                                "
                                @click="opCheck(scope.row)"
                            >
                                选择栏目
                            </el-button> -->
                        </template>
                    </el-table-column>
                </template>
            </PageList>
        </div>
        <Add
            v-if="addiagvisible"
            :addiagvisible="addiagvisible"
            :current-id="currentId"
            @closeDiag="closeDiag"
        ></Add>
        <el-dialog title="适用栏目" :visible.sync="dialogVisible">
            <el-checkbox-group v-model="columnCheck">
                <el-checkbox v-for="item in columnData" :label="item.id" :key="item.id">
                    {{ item.columnsName }}
                </el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="saveCheckbox">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import PageList from 'conp/page-list';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-category');
import Add from './add.vue';
import {
    getCheckcolumn,
    getCategorycolumn,
    addColumsbatch,
    editCategory,
    editCatestate,
} from '@/api/mkt/category.js';

export default {
    components: {
        Add,
        PageList,
    },
    data() {
        return {
            tableHead: [
                // { name: '排序值', value: 'orderSort' },
                // { name: '适用栏目', value: 'label', formatter: this.isShowFormatter },
            ],
            addiagvisible: false,
            dialogVisible: false,
            isChooseall: false,
            columnData: [],
            columnCheck: [],
            columnCheckId: [],
            currentRowcate: [],
            selectRowlist: [],
            currentId: '',
            TableHeight: 0,
        };
    },
    computed: {
        ...mapState(['list', 'isHasSearch']), //// 映射 this.list 为 store.state.list
    },
    mounted() {
        this.$store.dispatch('mkt-category/list', ''); //使用vuex调用接口
        // this.getCategorycolumn();
        if (this.$refs?.topBox.scrollHeight) {
            this.TableHeight = window.innerHeight - this.$refs.topBox.scrollHeight - 200 + 'px';
        }
    },
    methods: {
        isShowFormatter(row) {
            if (row?.columnCateVoList?.length) {
                let list = [];
                row.columnCateVoList.map(item => {
                    if (!list.includes(item.columnsName)) {
                        list.push(item.columnsName);
                    }
                });
                return list.join(',');
            } else {
                return '';
            }
        },
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        async addColumsbatch() {
            const params = {
                categorysId: this.currentRowcate,
                columsList: this.columnCheckId,
            };
            const res = await addColumsbatch(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.dialogVisible = false;
                this.$store.dispatch('mkt-category/list', ''); //使用vuex调用接口
            }
        },
        async getCategorycolumn() {
            const res = await getCategorycolumn();
            if (res?.isSuccess) {
                this.columnData = res.data;
                this.columnData.map(item => {
                    if (item.isCheck) {
                        this.columnCheck.push(item.id);
                    }
                });
            }
        },
        async getCheckcolumn(id) {
            this.columnCheck = [];
            const res = await getCheckcolumn(id);
            if (res?.isSuccess) {
                res.data.map(item => {
                    if (item.isCheck) {
                        this.columnCheck.push(item.id);
                    }
                });
            }
        },
        async editCatestate(state) {
            let list = [];
            this.selectRowlist.map(item => {
                list.push(item.value);
            });
            const params = {
                id: list,
                state: state,
            };
            const res = await editCatestate(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.$store.dispatch('mkt-category/list', ''); //使用vuex调用接口
            }
        },
        pageChange() {},
        pageSizesChange() {},
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectChange(rows) {
            this.selectRowlist = rows;
        },
        async editCategory(params) {
            const res = await editCategory(params);
            if (res?.isSuccess) {
                this.$message.success('编辑成功');
                this.$store.dispatch('mkt-category/list', '');
            }
        },
        selectAll() {
            this.isChooseall = !this.isChooseall;
            if (this.selectRowlist?.length) {
                this.$refs.multipleTable.clearSelection();
            }
            this.splite(this.list);
            if (!this.isChooseall) {
                this.$refs.multipleTable.clearSelection();
            }
        },
        splite(data) {
            data.forEach(item => {
                if (item?.children?.length) {
                    this.splite(item.children);
                } else {
                    if (!item.isTop) {
                        this.$refs.multipleTable.toggleRowSelection(item, true);
                    }
                }
            });
        },
        editRow(row) {
            this.currentId = row.value;
            this.addiagvisible = true;
        },
        closeDiag() {
            this.addiagvisible = false;
        },
        saveCheckbox() {
            this.columnCheckId = [];
            this.columnData.map(item => {
                if (this.columnCheck.includes(item.id)) {
                    this.columnCheckId.push({
                        columnsId: item.id,
                        isCheck: true,
                    });
                } else {
                    this.columnCheckId.push({
                        columnsId: item.id,
                        isCheck: false,
                    });
                }
            });
            this.addColumsbatch();
        },
        //判断复选框是否可选
        checkboxInit(row) {
            if ((!row.children || !row.children.length) && !row.isTop) {
                // 每行数据在该条件下不可选
                return 1; //不可勾选
            } else {
                // 在该条件下可选
                return 0;
            }
        },
        opCheck(row) {
            this.columnCheck = [];
            if (row?.value) {
                this.currentRowcate = [row.value];
                // this.getCheckcolumn(row.value);
            } else {
                this.currentRowcate = [];
                this.selectRowlist.map(item => {
                    this.currentRowcate.push(item.value);
                });
            }
            this.dialogVisible = true;
        },
        handSwitch(type, row) {
            const params = {
                id: row.value,
                isShow: row.isShow,
                shelfStatus: row.shelfStatus,
            };
            this.editCategory(params);
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    margin: 10px 30px;
}
</style>
<style>
.disabledSelection > .el-checkbox {
    display: none;
}
</style>
