import rq from '@/utils/https.js';
const $ajax = rq.requests;

export const getList = data =>
    $ajax({
        url: '/worksWeek/worksWeekList',
        method: 'post',
        data,
    });
// 校验周案例
export const checkWeek = data =>
    $ajax({
        url: '/worksWeek/addWorksWeekCheck',
        method: 'post',
        data,
    });
export const saveWeek = data =>
    $ajax({
        url: '/worksWeek/saveWorksWeek',
        method: 'post',
        data,
    });
// 周案例详情
export const getCaseInfo = params =>
    $ajax({
        url: `/worksWeek/getWorksWeekById`,
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });

// 选择内容列表接口
export const getContentList = data =>
    $ajax({
        url: '/worksWeek/searchContent',
        method: 'post',
        data,
    });
// 放弃
export const getContentById = params =>
    $ajax({
        url: '/worksWeek/searchWorksById',
        method: 'get',
        params,
    });
// 删除
export const delWeekCase = params =>
    $ajax({
        url: '/worksWeek/delWorksWeekById',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
export default {};
