<template>
    <div>
        <div>
            <el-upload
                class="upload-demo"
                style="height:104px;overflow:hidden;"
                multiple
                :limit="limit"
                :before-upload="beforeUpload"
                :http-request="uploadRequest"
                :show-file-list="true"
                list-type="picture-card"
                :disabled="batchUpload || disabled"
                :file-list="fileList"
                :accept="fileType"
                :onExceed="handleExceed"
                action="*"
            >
                <template slot="default">
                    <div class="flex" style="display: flex; align-items: center">
                        <div
                            class="default"
                            :class="errorType ? 'default-error' : ''"
                            @click="handleClickIcon"
                            v-if="
                                !disabled && ((!localFileList?.length && limit == 1) || limit > 1)
                            "
                        >
                            <i class="el-icon-plus"></i>
                            <span class="text btnText">{{ btnText || '上传图片' }}</span>
                        </div>
                        <div v-if="qrcode" class="qrcode-text" @click="qrcodeUpload">
                            扫码上传图片
                        </div>
                        <div class="tip-wrap" v-else>
                            <div class="tip-title" v-if="tipTitle" style="text-align:left;">
                                {{ tipTitle || '上传说明：' }}
                            </div>
                            <div v-if="tip && Array.isArray(tip)">
                                <p class="tip-text" v-for="(item, index) in tip" :key="index">
                                    {{ item }}
                                </p>
                            </div>
                            <div v-else class="tip-text">
                                {{ tip }}
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="file" slot-scope="{ file }">
                    <div class="fileList" :class="getSelectClass(file)">
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                            v-if="file.url && isImageExtension(file.url)"
                            style="object-fit: contain;"
                        />

                        <svg
                            class="icon"
                            aria-hidden="true"
                            style="width: 102px; height: 102px"
                            v-else
                        >
                            <use :xlink:href="file.icon"></use>
                        </svg>
                        <span
                            style="
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 100%;
                                height: 20px;
                                background: rgba(0, 0, 0, 0.6);
                                color: #fff;
                                text-align: center;
                            "
                            v-if="showFileName"
                        >
                            {{ file.originName || file.name }}
                        </span>

                        <span
                            class="el-upload-list__item-actions"
                            @click="handleSelect(file)"
                            v-if="file.url"
                        >
                            <span
                                class="el-upload-list__item-preview"
                                @click.stop="handlePictureCardPreview(file)"
                                v-if="file.url && !isExtension(file.url)"
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="cus_el-upload-list__item-delete"
                                @click.stop="handleRemove(file.url)"
                            >
                                <i class="el-icon-error"></i>
                            </span>
                            <span v-if="crop">
                                <i class="el-icon-crop" @click.stop="handleCrop"></i>
                            </span>
                        </span>
                    </div>
                </template>
            </el-upload>
        </div>

        <el-dialog
            :visible.sync="dialogVisible"
            :top="isPPTExtension(dialogImageUrl) ? '0' : '5vh'"
            append-to-body
            @close="handleClose"
        >
            <div ref="videoBoxRef" v-if="isVideoExtension(dialogImageUrl)"></div>

            <div v-else-if="isPPTExtension(dialogImageUrl)">
                <iframe
                    :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + dialogImageUrl"
                    frameborder="0"
                    width="100%"
                    height="800px"
                ></iframe>
            </div>
            <div v-else-if="isImageExtension(dialogImageUrl)">
                <el-image
                    fit="contain"
                    :src="dialogImageUrl"
                    alt=""
                    style="width:100%;height:100%;"
                />
            </div>
            <div v-else>{{ dialogImageUrl }}, 格式不对,无法预览</div>
        </el-dialog>
    </div>
</template>

<script>
import ossMixins from '../mixins/getOssMixin';
import ossState from '@/utils/ossClient.js';
import videojs from 'video.js';
import { oss } from '@/config';
import { formatTimeTwo } from '@/utils/util.js';
const OSS_URL = 'https://sczs.voglassdc.com/';

export default {
    name: 'base-upload-dpwcc',
    mixins: [ossMixins],
    props: {
        limit: {
            type: Number,
            default: 1,
        },
        btnText: {
            type: String,
            default: '',
        },
        tipTitle: {
            type: String,
            default: '',
        },
        tip: {
            type: String,
            default: '1.PPT格式',
        },
        fileSize: {
            type: Number,
            default: 2,
        },
        minFileSize: {
            type: Number,
            default: 0,
        },
        fileType: {
            type: String,
            default: '',
        },
        fileModule: {
            type: String,
            default: 'test',
        },
        fileList: {
            type: Array,
            default: () => [],
        },
        selectFileList: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledDel: {
            type: Boolean,
            default: false,
        },
        batchUpload: {
            type: Boolean,
            default: false,
        },
        qrcode: {
            type: Boolean,
            default: false,
        },
        errorType: {
            type: Boolean,
            default: false,
        },
        showFileName: {
            type: Boolean,
            default: false,
        },
        getSize: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Boolean,
            default: false,
        },
        validate: {
            type: Boolean,
            default: false,
        },
        columnKey: {
            type: String,
            default: '',
        },
        showPercen: {
            type: Boolean,
            default: false,
        },
        crop: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        selectFileList: {
            handler(val) {
                this.selectList = val;
            },
            immediate: true,
            deep: true,
        },
        fileList: {
            handler(val) {
                this.localFileList = val;
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            ossClient: {},
            videoCom: null,
            videoPlayer: null,
            filesNumber: 0, //记录上传文件数量
            dialogImageUrl: '',
            showDialog: false,
            dialogVisible: false,
            selectList: [],
            num: 0,
        };
    },
    methods: {
        handleClose() {
            this.videoPlayer.pause();
        },
        handleCrop() {
            this.$emit('crop', true);
        },
        handlePictureCardPreview(file) {
            // ppt预览
            if (this.isPPTExtension(file.url)) {
                // 三方预览
                // window.open(
                //     'https://view.officeapps.live.com/op/view.aspx?src=' + file.url
                // );
                // return;
                // iframe预览
                this.dialogVisible = true;
                this.dialogImageUrl = file.url;
                return;
            }
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            /**video */

            // 重置 video.js 播放器
            if (this.videoPlayer) {
                this.videoPlayer.dispose();
            }
            this.$nextTick(() => {
                const videoBox = this.$refs['videoBoxRef'];
                videoBox.innerHTML = '';
                // 创建新的 video 标签
                const newVideoElement = document.createElement('video');
                videoBox.appendChild(newVideoElement);
                newVideoElement.className = 'video-js vjs-default-skin video-box';
                newVideoElement.style = 'width: 100%';
                newVideoElement.load();
                this.videoPlayer = videojs(newVideoElement, {
                    controls: true, // 显示控制条
                    sources: [
                        {
                            type: 'application/x-mpegURL',
                            src: '', // 替换为你的m3u8文件地址
                        },
                    ],
                });
                this.videoPlayer.src(file.url);
                this.videoPlayer.play();
            });
        },

        handleRemove(url) {
            let deleteItem = this.localFileList.find(item => item.url == url);
            if (['ssc_upload', 'original', 'noOriginal'].includes(this.columnKey)) {
                this.$confirm('此操作将删除相关内容, 是否继续?', '提醒', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    if (deleteItem?.id) {
                        let list = this.localFileList.filter(item => item.id !== deleteItem?.id);
                        this.$emit('fileList', list);
                        this.$bus.emit('deleteSpaceFile', {
                            file: deleteItem,
                            key: this.columnKey,
                        });
                    } else {
                        let list = this.localFileList.filter(item => item.url !== url);
                        this.$emit('fileList', list);
                        this.$emit('selectFileList', list);
                    }
                });
            } else {
                let list = this.localFileList.filter(item => item.url !== url);
                this.$emit('fileList', list);
                this.$emit('selectFileList', list);
            }
        },

        handleSelect(file) {
            const fileSuffix = file.url.substring(file.url.lastIndexOf('.'));
            if (this.fileType.includes(fileSuffix)) {
                if (this.limit == 1) {
                    this.selectList = [file];
                } else {
                    if (this.selectList.some(se => se.url == file.url)) {
                        this.selectList.splice(
                            this.selectList.findIndex(se => se.url == file.url),
                            1
                        );
                    } else {
                        this.selectList.push(file);
                    }
                }
                this.selectList.map(d => (d.id = ''));
                if (this.select) {
                    this.$emit('selectFileList', this.selectList);
                }
            }
        },

        getSelectClass(file) {
            return this.select && file && this.selectList.some(d => d.url == file.url)
                ? 'selected'
                : '';
        },
        isImageExtension(url) {
            const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
            return imageExtensions.includes(extension);
        },
        isExtension(url) {
            const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
            return ['ppt', 'pptx', 'pdf', 'dwt', 'dwg', 'dxf', 'dws'].includes(extension);
        },
        isPPTExtension(url) {
            const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
            return ['ppt', 'pptx', 'pdf'].includes(extension);
        },
        isDwtExtension(url) {
            const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
            return ['dwt', 'dwg', 'dxf', 'dws'].includes(extension);
        },
        isVideoExtension(url) {
            const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
            return [
                'mp4',
                'avi',
                'MP4',
                'AVI',
                'wmv',
                'mpeg',
                'mov',
                'mkv',
                'flv',
                'webm',
                '3gp',
                'mpg',
                'm3u8',
            ].includes(extension);
        },
        beforeUpload(file) {
            // this.ossClient = ossState.ossClient;
            this.filesNumber = this.filesNumber + 1;
            return new Promise(async (resolve, reject) => {
                let fileSize = file.size / 1024 / 1024;
                if (this.fileSize && fileSize > this.fileSize) {
                    this.$message({
                        message: `上传文件大小不能超过${this.fileSize}MB!`,
                        grouping: true,
                        type: 'error',
                    });
                    return reject(false);
                }
                if (this.minFileSize && fileSize <= 8) {
                    this.$message({
                        message: `单个文件大小不能小于${this.minFileSize}MB!`,
                        grouping: true,
                        type: 'error',
                    });
                    return reject(false);
                }
                if (file.type.indexOf('video') > -1 && this.validate) {
                    // 获取视频
                    window.URL = window.URL || window.webkitURL;
                    let url = URL.createObjectURL(file);
                    let audioElement = document.createElement('video');
                    audioElement.src = url;
                }
                resolve();
            });
        },

        handleClickIcon() {
            if (this.batchUpload) {
                this.$emit('batchUploadEvent');
            }
        },
        qrcodeUpload() {
            this.$emit('qrcodeUpload');
        },
        uploadRequest(file) {
            return new Promise((resolve, reject) => {
                file = file.file;
                const client = ossState.ossClient;

                let tempCheckpoint;
                const fileName = new Date().getTime() + '' + parseInt(Math.random() * 100);
                let fileNameArr = file.name.split('.');
                const fileSuffix = fileNameArr[fileNameArr.length - 1];
                let date = formatTimeTwo(new Date(), 'YMD');
                let result = client.multipartUpload(
                    `${oss.folderName}/${date}/${fileName}.${fileSuffix}`,
                    file,
                    {
                        progress(p, checkpoint) {
                            tempCheckpoint = checkpoint;
                        },
                        checkpoint: tempCheckpoint,
                        meta: {
                            year: 2022,
                            people: 'test',
                        },

                        mime: file.type === 'application/pdf' ? file.type : 'text/plain',
                    }
                );
                result
                    .then(res => {
                        let sizeObj = {};
                        let nameArr = res.name.split('/');
                        let newName = nameArr[nameArr.length - 1];
                        if (this.getSize) {
                            // 获取图片宽高
                            let reader = new FileReader();
                            reader.onload = function(event) {
                                let text = event.target.result;
                                let img = document.createElement('img');
                                img.src = text;
                                img.onload = function() {
                                    sizeObj = { width: img.width, height: img.height };
                                    this.$emit('fileList', [
                                        ...this.localFileList,
                                        {
                                            name: newName,
                                            url: OSS_URL + res.name,
                                            icon: this.suffixToIcon(fileSuffix),
                                            originName: file.name,
                                            ...sizeObj,
                                        },
                                    ]);
                                    return resolve(true);
                                };
                            };
                            reader.readAsDataURL(file);
                        } else {
                            this.$emit('fileList', [
                                ...this.localFileList,
                                {
                                    name: newName,
                                    url: OSS_URL + res.name,
                                    icon: this.suffixToIcon(fileSuffix),
                                    originName: file.name,
                                    ...sizeObj,
                                },
                            ]);
                            this.$emit('errorType', false);
                            return resolve(true);
                        }
                    })
                    .catch(err => {
                        this.$emit('fileList', [...this.localFileList]);
                        return reject(false);
                    });
            });
        },

        suffixToIcon(fileSuffix) {
            let icon = '';
            fileSuffix = fileSuffix.toLowerCase();
            switch (fileSuffix) {
                case 'doc':
                case 'docx':
                    icon = '#icon-doc';
                    break;
                case 'xlsx':
                case 'xls':
                    icon = '#icon-xls';
                    break;
                case 'ppt':
                case 'pptx':
                    icon = '#icon-ppt';
                    break;
                case 'pdf':
                    icon = '#icon-pdf';
                    break;
                case 'txt':
                    icon = '#icon-txt';
                    break;
                case 'rar':
                case 'zip':
                    icon = '#icon-zip';
                    break;

                case 'mov':
                case 'mp4':
                case 'm4v':
                case 'wmv':
                case 'avi':
                case 'webm':
                case 'ogv':
                case 'flv':
                case 'rm':
                case 'rmvb':
                case 'vob':
                    icon = '#icon-mov';
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                case 'ico':
                case 'jpeg':
                case 'svg':
                case 'svg':
                    icon = '#icon-a-jpg1';
                    break;
                case 'gif':
                    icon = '#icon-gif';
                    break;
                case 'ai':
                    icon = '#icon-ai';
                    break;
                case 'ps':
                    icon = '#icon-ps';
                    break;
                default:
                    icon = '#icon-fujian';
                    break;
            }
            return icon;
        },
        handleExceed() {
            this.$message({
                message: `当前限制上传${this.limit}项内容,请删除后重新上传!`,
                grouping: true,
                type: 'error',
            });
        },
    },
    mounted() {},
    beforeDestroy() {
        if (this.videoPlayer) {
            this.videoPlayer.dispose();
        }
    },
};
</script>

<style scoped lang="less">
.upload-demo {
    /deep/ .el-upload--picture-card {
        --el-upload-picture-card-size: none;
        height: 102px;
        background: transparent !important;
        border: 1px dashed transparent;
    }
    .fileList {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .selected {
        border: 1px solid red;
        border-radius: 6px;
    }

    .default {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 102px;
        text-align: center;
        height: 102px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px dashed #e7e7e7;
        .icon {
            font-size: 18px;
        }
        .text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: center;
            font-style: normal;
            margin-top: 8px;
        }
    }
    .tip-wrap {
        flex-shrink: 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #c1c1c1;
        line-height: 21px;
        font-style: normal;
        margin-left: 17px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;

        .icon {
            font-size: 18px;
        }
        .text {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: justify;
            font-style: normal;
        }
    }
    .default-error {
        border-color: red;
    }
}
.video-box {
    width: 100%;
}
.percen-box {
    width: 500px;
    padding: 20px 0 0;
}

/deep/ .el-upload-list__item {
    width: 102px;
    height: 102px;
    .cus_el-upload-list__item-delete {
        position: absolute;
        top: 4px;
        right: 4px;
        // background: rgb(222, 224, 227);
        border-radius: 1em;
        font-size: 12px;
        padding: 2px;
    }
}

.qrcode-text {
    // font-family: PingFangSC, PingFang SC;
    // font-weight: 400;
    margin-left: 17px;
    font-size: 14px;
    color: #407fff;
    line-height: 20px;
    text-align: justify;
    font-style: normal;
}
</style>
