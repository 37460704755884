<template>
    <div class="home">
        <div :id="'editor' + editorKey" class="home-editor"></div>
    </div>
</template>

<script>
// 引入 wangEditor
import { Loading } from 'element-ui';
import wangEditor from 'wangeditor';
// import { url } from '@/config.js';
import { getCookie } from '@/utils/util.js';
import { addFileUpload } from '@/api/common.js';
export default {
    name: 'wangeditor',
    props: {
        editorData: {
            type: String,
            default: function() {
                return '';
            },
        },
        editorDisabled: {
            type: Boolean,
            default: false,
        },
        editorConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        menus: {
            type: Array,
            default: function() {
                return [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'undo', // 撤销
                    'redo', // 重复
                ];
            },
        },
        editorKey: {
            type: String,
            default: function() {
                return '';
            },
        },
    },
    data() {
        return {
            editor: null,
            init: true,
            content: '',
            uploadUrl: '',
        };
    },
    mounted() {
        if (this.editorKey) {
            this.$nextTick(() => {
                this.initEditor();
            });
        } else {
            this.initEditor();
        }
    },
    methods: {
        initEditor() {
            let editor = new wangEditor(`#editor${this.editorKey}`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            // editor.config.onchange = newHtml => {
            //     this.$parent.editorData = this.content = newHtml;
            //     // this.content = newHtml;
            //     // this.$parent.editorData = newHtml;
            // };
            for (let key in this.editorConfig) {
                editor.config[key] = this.editorConfig[key];
            }
            editor.config.pasteText = true; // 只粘贴纯文本
            editor.config.pasteIgnoreImg = true; //粘贴时忽略图片
            // 删移除插入代码视频菜单
            editor.config.menus = this.menus;
            // this.setUploadVideoPrams(editor);
            this.setUploadImgPrams(editor);

            // 创建编辑器
            editor.create();
            this.editor = editor;
            if (this.editorDisabled) {
                this.editor.disable();
            }
            //如果回显数据
            if (this.editorData && this.init) {
                this.editor.txt.html(this.editorData);
            }
            this.init = false;
        },
        // getEditorData() {
        //     // 通过代码获取编辑器内容
        //     let data = this.editor.txt.html();
        //     alert(data);
        // },
        setUploadVideoPrams(editor) {
            editor.config.showLinkVideo = false; //关闭网络视频上传
            editor.config.uploadVideoMaxLength = 1; //一次最多同时长传1个视频
            editor.config.uploadVideoAccept = ['mp4']; //限制上传视频格式
            const ajaxUrl = this.uploadUrl;
            editor.config.uploadVideoServer = ajaxUrl;
            const ajaxHeader = {
                Authorization: getCookie('AuthorizationBe') || '',
            };
            editor.config.uploadVideoName = 'file';

            editor.config.uploadVideoHeaders = ajaxHeader; //上传请求头配置
            // const fileDir = this.$route.path.split('/')[1];
            editor.config.uploadVideoParams = {
                fileDir: 'rotation', //图片上传的路径
            }; //上传参数配置

            editor.config.uploadVideoHooks = {
                //监听
                before: function() {
                    this.loadingInstance = Loading.service({
                        text: '上传中...',
                    });
                },
                customInsert: function(insertVideo, result) {
                    this.loadingInstance && this.loadingInstance.close();
                    if (result.code == 0 && result.data.webUrl) {
                        insertVideo(result.data.webUrl);
                    } else {
                        this.$message.error(result.data.msg || '上传失败，请重试');
                    }
                },
            };
        },
        setUploadImgPrams(editor) {
            // const fileDir = this.$route.path.split('/')[1];
            const ajaxUrl = this.uploadUrl;
            const ajaxHeader = {
                Authorization: getCookie('Authentication') || '',
            };
            editor.config.uploadImgMaxSize = 2 * 1024 * 1024; //控制图片大小
            editor.config.uploadImgHeaders = ajaxHeader; //上传请求头配置
            editor.config.uploadFileName = 'files'; //上传参数 自定义
            editor.config.uploadImgServer = ajaxUrl;
            editor.config.showLinkImg = false; //关闭网络图片上传
            editor.config.uploadImgMaxLength = 5; //一次最多同时长传5张图片
            editor.config.uploadImgParams = {
                fileDir: 'rotation', //图片上传的路径
            }; //上传参数配置
            const that = this;
            // 自定义上传图片
            editor.config.customUploadImg = function(resultFiles, insertImgFn) {
                console.log(resultFiles, resultFiles[0], resultFiles[1]);
                that.loadingInstance = Loading.service({
                    text: '上传中...',
                });
                // formData.set('file', [resultFiles[0], resultFiles[1]]);
                // console.log(formData.get('file'));
                // resultFiles 是 input 中选中的文件列表
                // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                const imgUrls = [];
                let imgLen = resultFiles.length;
                let a = 0;
                for (let i = 0; i < resultFiles.length; i++) {
                    const file = resultFiles[i];
                    const formData = new FormData();
                    formData.append('fileDir', 'rotation');
                    formData.append('file', file);
                    imgUrls.push({
                        file: file,
                    });
                    addFileUpload({
                        data: formData,
                    })
                        .then(res => {
                            console.log(res);
                            a++;
                            for (let j = 0; j < imgUrls.length; j++) {
                                if (imgUrls[j].file == file) {
                                    imgUrls[j].url = res.code == 200 && res.data.webUrl;
                                }
                            }
                            if (a == imgLen) {
                                that.insertImgs(imgUrls, insertImgFn);
                            }
                        })
                        .catch(error => {
                            imgLen--;
                            if (a == imgLen) {
                                that.loadingInstance && that.loadingInstance.close();
                            }
                            console.log('upload error', error);
                            that.$message.error('上传图片失败，请重试');
                        });
                }
            };
        },
        insertImgs(urls, insetImgFn) {
            this.loadingInstance && this.loadingInstance.close();
            for (let item of urls) {
                if (item.url) {
                    insetImgFn(item.url);
                }
            }
        },
    },
    watch: {
        editorData(newData) {
            newData = newData || '';
            this.editor.txt.html(newData);
            // if (newData == null) {
            //     this.editor.txt.html('');
            // }

            this.content = newData;
        },
        editorDisabled(newVal) {
            newVal ? this.editor.disable() : this.editor.enable();
        },
        editorConfig: {
            handler(val) {
                this.editorConfig = val;
            },
            deep: true,
        },
    },
    beforeDestroy() {
        // 调用销毁 API 对当前编辑器实例进行销毁
        this.editor.destroy();
        this.editor = null;
    },
};
</script>

<style lang="less">
.home {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}
// .home-editor {
//     height: 100px;
// }
</style>
