<template>
    <div class="tag-container">
        <!-- <div class="tag-title">用户管理</div> -->
        <!-- <Top></Top> -->
        <Content></Content>
    </div>
</template>

<script>
import Content from './content';
// import Top from '../markets/top';
export default {
    name: 'article',
    components: { Content },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-pages/clear');
    },
    methods: {},
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.tag-title {
    padding: 20px 0 0 30px;
    font-size: 18px;
}
</style>
