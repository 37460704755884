<template>
    <div class="mkt" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        ></sc-search-v2>

        <el-tabs v-model="tabActive" @tab-click="tabClick">
            <el-tab-pane label="全部长图文" name="0"></el-tab-pane>
            <el-tab-pane label="我的草稿" name="1"></el-tab-pane>
            <el-tab-pane label="aa" name="888" disabled>
                <span slot="label">
                    <span style="margin-bottom: 15px; margin-left: 10px">
                        <el-button
                            type="primary"
                            size="small"
                            @click="createArticleImg"
                            style="margin-left:30px;"
                        >
                            新增长图文
                        </el-button>
                    </span>
                </span>
            </el-tab-pane>
        </el-tabs>
        <div class="tabs">
            <sc-table-v2
                row-key="articleId"
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <template v-slot:coverImage>
                    <el-table-column label="封面" width="72">
                        <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.coverImage"
                                style="width: 50px; height: 50px"
                                :src="scope.row.coverImage"
                                :preview-src-list="[scope.row.coverImage]"
                            ></el-image>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:createUserName>
                    <el-table-column label="创建人" width="260">
                        <template slot-scope="scope">
                            {{ scope.row.createUserName
                            }}{{
                                scope.row.createUserAccount &&
                                    '（' + scope.row.createUserAccount + '）'
                            }}
                            <el-tag type="text" size="mini" v-if="scope.row.createUserJob">
                                {{ scope.row.createUserJob }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="170">
                        <template slot-scope="scope">
                            <el-button type="text" @click="createArticleImg(scope.row)" size="mini">
                                编辑
                            </el-button>
                            <el-button
                                v-if="tabActive == 0"
                                type="text"
                                @click="editArticle(scope.row)"
                                size="mini"
                            >
                                <!-- v-if="scope.row.approvalStatus == 3" -->
                                补充
                            </el-button>
                            <!-- 一期审核通过不做编辑 -->
                            <sc-qrcode-popover
                                v-if="tabActive == 0"
                                :id="scope.row.articleId"
                                type="article-image"
                            ></sc-qrcode-popover>
                            <el-button
                                v-if="tabActive == 0"
                                type="text"
                                @click="handleStatus(scope.row)"
                                size="mini"
                                style="margin-left:10px;"
                            >
                                {{ scope.row.isShow == 0 ? '展示' : '隐藏' }}
                            </el-button>
                            <el-button
                                v-if="tabActive == 1"
                                type="text"
                                @click="delArticle(scope.row)"
                                size="mini"
                            >
                                <!-- v-if="scope.row.approvalStatus == 3" -->
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <completion ref="completionRef" @handleClose="handleClose"></completion>
    </div>
</template>

<script>
import completion from './components/completion.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import { getList, showArticle, deleteArticle } from '@/api/mkt/article-image.js';
import { getLabellist } from '@/api/mkt/topic.js';

export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { completion },
    data() {
        return {
            tabActive: 0, //是否草稿：0否，1是
            tableHandle: getList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '86px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: 'ID', key: 'articleId' },
                { name: '长图文名称', key: 'title' },
                { name: '封面图', key: 'coverImage' },
                { name: '标签', key: 'labelName' },
                { name: '权重值', key: 'weightSort', width: 70 },
                { name: '访问量', key: 'pageView', width: 70 },
                { name: '平均访问时长', key: 'averageDuration', width: '100' },
                { name: '评级', key: 'contentLevelName', width: 60 },
                { name: '创建人', key: 'createUserName', width: 200 },
                { name: '提交时间', key: 'createTime', width: '160' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            columns: {
                keywords: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '名称/话题/创建人姓名/昵称/描述/ID',
                    },
                    spanCol: 1,
                },
                createTime: {
                    name: '提交时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: 'aaa',
                        placeholder: '请输入提交时间',
                    },
                    spanCol: 1,
                },
                labelId: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },
                isWeight: {
                    name: '权重值',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            { value: 1, label: '无' },
                            { value: 2, label: '有' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                keywords: '',
                createTime: '',
                labelId: [],
                isWeight: '',
                createStartTime: '',
                createEndTime: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        if (this.$route.query && this.$route.query.isDraft) {
            console.log('-------------1111', this.$route.query.isDraft);
            this.tabActive = this.$route.query.isDraft ? '1' : '0';
        }
        this.init();
        this.getLabelOptions();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        tabClick(tab, event) {
            this.tabActive = tab.name;
            this.init();
        },
        createArticleImg(row) {
            let url = '/mkt/cm/article-img/detail';
            if (row.articleId) {
                url = `/mkt/cm/article-img/detail?id=${row.articleId}${(this.tabActive == 1 &&
                    '&isDraft=true') ||
                    ''}`;
            }
            this.$router.push({ path: url });
        },
        // 隐藏显示
        async handleStatus(row) {
            this.$confirm(`确认${row.isShow == 1 ? '隐藏' : '展示'}当前内容吗, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(async () => {
                    // 掉编辑接口
                    let isShow = row.isShow == 1 ? '0' : '1';
                    showArticle({ isShow, id: row.articleId }).then(res => {
                        this.$message.success('操作成功');
                        this.init();
                    });
                })
                .catch(() => {});
        },
        getLabelOptions(val) {
            getLabellist({}).then(res => {
                console.log(res, 'res-dddddd');
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                        }
                    }
                    let searchLabelOptions = this.getTreeData(res?.data) || [];

                    const result = searchLabelOptions.map(item => {
                        const children =
                            item.children?.length &&
                            item.children.map(every => {
                                return {
                                    id: every?.id,
                                    labelGroupId: every?.labelGroupId,
                                    labelName: every?.labelName,
                                    parentId: every?.parentId,
                                    properties: every?.properties,
                                };
                            });
                        return {
                            ...item,
                            children: children,
                        };
                    });
                    result.unshift({
                        children: [],
                        groupName: '无',
                        groupType: 0,
                        id: '100000',
                        labelList: [],
                        labelName: '无',
                    });
                    this.columns.labelId.options.options = result;
                }
            });
        },
        getTreeData(data) {
            data.forEach(item => {
                if (
                    (item.children && item.children.length < 1) ||
                    item.children == null ||
                    item.children == undefined
                ) {
                    item.children = undefined;
                } else {
                    this.getTreeData(item.children);
                }
            });
            return data;
        },
        async delArticle(row) {
            this.$confirm(`确认删除当前内容吗, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(async () => {
                    // 掉编辑接口
                    deleteArticle({ articleId: row.articleId }).then(res => {
                        this.$message.success('操作成功');
                        this.init();
                    });
                })
                .catch(() => {});
        },
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.filter = {
                createTime: null,
                createStartTime:
                    this.result.createTime && this.result.createTime[0]
                        ? this.result.createTime[0] + ' 00:00:00'
                        : null,
                createEndTime:
                    this.result.createTime && this.result.createTime[1]
                        ? this.result.createTime[1] + ' 23:59:59'
                        : null,
                articleType: 2,
                isDraft: this.tabActive, //0 长图文  1 草稿
            };
            this.page = {
                page: 1,
                size: 30,
                index: 1,
            };
            this.queryDataMixin();
        },
        onEdit(row) {
            this.$refs.completionRef.open(row);
        },
        editArticle(row) {
            this.$refs.completionRef.openDrawer(row, 1);
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        pageChange(val) {
            this.page.page = val;
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
