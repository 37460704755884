import rq from '@/utils/https.js';
const $ajax = rq.requests;
// ~~~~~~~~~~  设计师榜单 ~~~~~~~~~~~~~
// 设计师榜单列表
export const getUserDesignerCollectionList = params =>
    $ajax({
        url: '/user-designer-collection/list',
        method: 'get',
        params,
    });

// 设计师榜单分组新增
export const collectionSave = data =>
    $ajax({
        url: '/user-designer-collection/save',
        method: 'post',
        data,
    });

export const isShelfRanking = params =>
    $ajax({
        url: '/user-designer-collection/shelf',
        method: 'get',
        params,
        headers: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });
// 分组编辑回显
export const updateEcho = params =>
    $ajax({
        url: '/user-designer-collection/update/echo',
        method: 'get',
        params,
    });

// 设计师榜单分组删除
export const getCollectionDelete = params =>
    $ajax({
        url: '/user-designer-collection/delete',
        method: 'get',
        params,
    });

// 设计师榜单分组更新
export const collectionUpdateNew = params =>
    $ajax({
        url: '/user-designer-collection/update-new',
        method: 'get',
        params,
    });

// 设计师榜单分组编辑
export const collectionUpdate = data =>
    $ajax({
        url: '/user-designer-collection/update',
        method: 'post',
        data,
    });

// 下拉内容
export const getWorksOptions = params =>
    $ajax({
        url: '/works/option-list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });

// ~~~~~~~~~~  设计师榜单详情列表 ~~~~~~~~~~~~~
// 设计师列表
export const getRankingList = params =>
    $ajax({
        url: '/user-designer-collection/ranking/list',
        method: 'get',
        params,
    });

// 设计师隐藏
export const updateShow = params =>
    $ajax({
        url: '/user-designer-collection/ranking/update/show',
        method: 'get',
        params,
    });

// 设计师修改
export const rankingUpdate = data =>
    $ajax({
        url: '/user-designer-collection/ranking/update',
        method: 'post',
        data,
    });

// 榜单设计师排序
export const rankingSort = data =>
    $ajax({
        url: '/user-designer-collection/ranking/sort',
        method: 'post',
        data,
    });
// /user-designer-collection/ranking/collection/sort
export const collectionSort = data =>
    $ajax({
        url: '/user-designer-collection/ranking/collection/sort',
        method: 'post',
        data,
    });
// /user-designer-collection/{version}/ranking/add
export const rankingAdd = data =>
    $ajax({
        url: '/user-designer-collection/v4/ranking/add',
        method: 'get',
        params: data,
    });
