<template>
    <!-- 搜索发现 关键词 -->
    <div class="mkt" ref="table">
        <div class="btns">
            <el-button size="small" type="primary" @click="addTag()">新增分组</el-button>
        </div>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                style="color: red"
                                size="small"
                                @click="onDel(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
    </div>
</template>

<script>
import editDialog from './components/detail.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';

import { getSearchList, deleteKeywords } from '@/api/mkt/home-tab.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog },
    data() {
        return {
            tableHandle: getSearchList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '搜索词', key: 'dictValue', width: 160 },
                { name: '位置', key: 'sort' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            tableAddConfig: {},
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        onEdit(row = {}) {
            row.isLabel = false;
            this.$refs.editDialog.open(row);
        },
        addTag(row = {}) {
            row.isLabel = true;
            this.$refs.editDialog.open(row);
        },
        onDel(row) {
            console.log(row, 1111111111);
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                deleteKeywords({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.init();
                });
            });
        },
        rowClick() {},
        dbClick() {},
        click() {},
        rowChange() {},
        selectionChange() {},
        changeFn(data) {},
        // 点击事件回调方法
        clickFn(data) {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.btns {
    padding: 20px 0px 0px 25px;
}
</style>
