<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
    </div>
</template>

<script>
export default {
    data() {
        return {
            columns: {
                categorysName: {
                    name: '分类搜索',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '',
                    },
                    spanCol: 2,
                },
                isShow: {
                    name: '启用状态',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        placeholder: '',
                        options: [
                            {
                                value: '0',
                                label: '启用',
                            },
                            {
                                value: '1',
                                label: '禁用',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                },
            },
            result: {
                categorysName: '',
                isShow: '',
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
        };
    },
    methods: {
        changeFn() {},
        clickFn(e) {
            const { categorysName } = e;
            let result = e;
            if (categorysName) {
                this.$store.commit('mkt-category/setHas', true); //同步方法保存
            } else {
                this.$store.commit('mkt-category/setHas', false); //同步方法保存
            }
            this.$store.commit('mkt-category/setParams', result); //同步方法保存
            this.$store.dispatch('mkt-category/list', ''); //异步方法保存
        },
    },
};
</script>
<style lang="scss" scoped></style>
