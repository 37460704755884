<template>
    <div>
        <el-dialog
            append-to-body
            top="50px"
            :visible.sync="dialogFormVisible"
            :before-close="close"
        >
            <div class="preview-box">
                <div class="preview">
                    <div class="white-cover"></div>
                    <div class="title">活动通知</div>

                    <div class="time">{{ rowData.dateStr }}</div>
                    <div class="body">
                        <div class="body-img">
                            <img class="bi-img" :src="rowData.cover" alt="" />
                            <div class="title-wrap">
                                <p class="bi-title">
                                    {{ rowData.title || rowData.contentTitle }}
                                </p>
                            </div>
                        </div>
                        <div
                            class="body-content"
                            v-if="rowData.describe || rowData.messageDescribe"
                        >
                            <div class="bc">
                                <p class="bc-content">
                                    {{ rowData.describe || rowData.messageDescribe }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            rowData: {
                dateStr: '',
            },
        };
    },
    methods: {
        openPreview(row) {
            this.rowData = row;
            this.dialogFormVisible = true;
        },

        close() {
            this.dialogFormVisible = false;
        },
    },

    // headerCut.png
};
</script>
<style scoped lang="less">
.preview-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview {
    box-sizing: border-box;
    width: 375px;
    height: 756px;
    background: url('../../../../../assets/images/headerCut.png') no-repeat no-repeat;
    background-size: 100%;
    border-radius: 50px;
    overflow: hidden;
    border: solid 1px rgba(148, 146, 146, 0.1);
    position: relative;

    .white-cover {
        width: 270px;
        height: 30px;
        background: #ffffff;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 55px;
        margin-right: 20px;
    }

    .title {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #10120f;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        text-align: cente;
        top: 55px;
        position: absolute;
        // left: 0;
        // right: 0;
    }

    .body {
        position: absolute;
        top: 0;
        margin-top: 125px;
        width: 100%;
        padding: 0 15px;
        background: #f9f9f8;
        height: 100%;

        .body-img {
            width: 100%;
            height: 163px;
            position: relative;

            .bi-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .title-wrap {
                height: 109px;
                background: linear-gradient(rgba(238, 238, 238, 0) 0%, rgba(0, 0, 0, 0.59) 100%);
                border-radius: 0px 0px 1px 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
            .bi-title {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 16px;
                position: absolute;
                bottom: 12px;
                // margin-bottom: 20px;
                color: #ffffff;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .body-content {
            padding: 0 15px 11px;
            // height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // filter: brightness(10%);
            background: #fff;

            .bc {
                // padding-top: 12px;
                overflow: hidden;
                .bc-content {
                    margin-top: 11px;
                    color: #7f7f7f;
                    width: 100%;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // height: 88rpx;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #737373;
                }
            }

            .bc-date {
                width: 100%;
                font-size: 18px;
                padding-left: 5px;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .time {
        position: absolute;
        top: 100px;
        width: 100%;
        height: 20px;
        text-align: center;
        color: #ccc;
        font-size: 12px;
    }
}
</style>
