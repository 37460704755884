<template>
    <div class="editor-box">
        <Toolbar
            style="border-radius: 12px; width: 100%; padding: 0 24px;margin: 18px 0 16px;border: 1px solid #ccc;"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 1000px; overflow-y: hidden;border:1px solid rgb(204, 204, 204);border-radius: 12px;"
            v-model="html"
            :defaultConfig="editorConfigData"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="handleChange"
        />
    </div>
</template>

<script>
// 引入 wangEditor
import { Loading } from 'element-ui';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import ossState from '@/utils/ossClient.js';
const customUpload = async (file, insertFn) => {
    // file 即选中的文件
    // 自己实现上传，并得到图片 url alt href
    // 最后插入图片
    let newFile = await ossState.uploadRequest(file);
    // console.log('----------------customUpload回显富文本', file, newFile);
    insertFn(newFile.url, '', newFile.url).catch(err => console.log(err, 888));
};
export default {
    components: { Editor, Toolbar },
    props: {
        editorData: {
            type: String,
            default: function() {
                return '';
            },
        },
        editorDisabled: {
            type: Boolean,
            default: false,
        },
        editorConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        menus: {
            type: Array,
            default: function() {
                return [
                    // 'headerSelect', // 标题
                    'fontSize', // 字号
                    //   'lineHeight', // 行高
                    'header1', //h1,
                    'header2',
                    'header3',
                    'bold', // 粗体
                    // 'fontFamily', // 字体
                    // 'italic', // 斜体
                    // 'underline', // 下划线
                    // 'through', // 删除线
                    'color', // 文字颜色
                    // 'bgColor', // 背景颜色
                    'justifyLeft', // 对齐方式
                    'justifyRight', // 对齐方式
                    'justifyCenter', // 对齐方式
                    'justifyJustify', // 对齐方式
                    'bulletedList', // 无序列表
                    'numberedList', // 有序列表
                    // 'blockquote', // 引用
                    // 'emotion', // 表情
                    'undo', // 撤销
                    'redo', // 重复
                    'uploadImage',
                    'uploadVideo',
                ];
            },
        },
    },
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {
                excludeKeys: [], //excludeKeys: ['fullScreen'],
                // toolbarKeys: [],
            },
            mode: 'default',
            editorConfigData: {
                placeholder: '请输入内容...',
                zIndex: '9',
                MENU_CONF: {
                    uploadImage: { customUpload },
                    uploadVideo: { customUpload },
                    fontSize: { fontSizeList: ['17px', '18px', '19px'] },
                },
            },
        };
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
            // console.log('%c DomEditor--------------------', 'color:purple', DomEditor);
            this.html = this.editorData;
            this.editor.setHtml(this.editorData);

            // 是否为只读
            if (this.editorDisabled) {
                this.editor.disable();
            }
            console.log('---------editor.getAllMenuKeys()', editor.getAllMenuKeys());
        },
        handleChange(editor) {
            //造成闪烁问题
        },
    },
    mounted() {
        this.toolbarConfig.toolbarKeys = this.menus;
    },
    watch: {
        editorData(newVal) {
            this.html = newVal ? newVal : '';
            this.editor.setHtml(this.editorData);
        },
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less">
.editor-box {
    .editor-wrap {
        &:first-child {
            border: none;
        }
    }
}
</style>
