<!-- 案例列表 -->
<template>
    <layout><div>案例列表页面</div></layout>
</template>

<script>
import Layout from '@/components/layout.vue';
export default {
    name: '',
    components: { Layout },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped></style>
