<template>
    <div>
        <el-drawer
            title="编辑"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="true"
        >
            <sc-form-v2
                style="padding-bottom: 0px"
                name=""
                tablename="ALLOCATION"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="allocation"
                :LS="{ 'min-widthWidth': '100%' }"
            >
                <template v-slot:coverUpload>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <div>
                            <span style="color:red;">*&nbsp;</span>
                            榜单封面图
                        </div>
                        <UploadNew
                            class="upload"
                            ref="uploadbox1"
                            :limitNum="1000"
                            :width="90"
                            :height="90"
                            :accept="['jpg', 'gif', 'jpeg', 'png']"
                            :fileType="'image'"
                            :showImgList="coverImgOne"
                            @uploadImgList="uploadImgListOne"
                            :ossClientCur="ossClient"
                            @sortImgList="sortImgList"
                        ></UploadNew>

                        <!-- <el-empty v-else description="暂无封面图" :image-size="70" /> -->
                    </el-descriptions-item>
                </template>
                <template v-slot:caseCoverUpload>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <el-button size="small" @click="handleCaseCoverUpload">
                            +去案例中选择封面图
                        </el-button>
                    </el-descriptions-item>
                </template>
                <!-- <template v-slot:localUpload>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <UploadNew
                            ref="uploadbox2"
                            :limitNum="1000"
                            :width="90"
                            :height="90"
                            :accept="['jpg', 'gif', 'jpeg', 'png']"
                            :fileType="'image'"
                            :showImgList="coverImgToo"
                            @uploadImgList="uploadImgListToo"
                        ></UploadNew>
                    </el-descriptions-item>
                </template> -->
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="handleClose">
                    取消
                </el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>

        <workCollect
            ref="workCollect"
            @innerDrawerSubmit="innerDrawerSubmit"
            :selectList="coverImgOne"
        ></workCollect>
    </div>
</template>
<script>
import mixins from '@/mixins';
import UploadNew from '@/components/upload-new.vue';
import workCollect from './work-collect.vue';
import getOssMixin from '@/mixins/getOssMixin';
import { rankingUpdate } from '@/api/mkt/designer-ranking-quick';

export default {
    components: { UploadNew, workCollect },
    mixins: [mixins, getOssMixin],
    data() {
        return {
            drawer: false,
            result: {
                isDisplay: '0',
                collectionName: '',
                recommendation: '',
            },
            accept: [
                // 'mp4'
            ],
            columns: {
                collectionName: {
                    name: '设计师姓名',
                    type: 'input',
                    options: {
                        placeholder: '',
                        defaultValue: '',
                        // disabled: true,
                    },
                    rules: [{ required: true, message: '' }],
                },
                isDisplay: {
                    name: '是否显示',
                    type: 'radio',
                    options: {
                        defaultValue: '0',
                        placeholder: '请输入关键词',
                        options: [
                            {
                                value: '0',
                                label: '显示',
                            },
                            {
                                value: '1',
                                label: '不显示',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                    rules: [{ required: true, message: '请输入副标题' }],
                },
                recommendation: {
                    name: '推荐语',
                    type: 'input',
                    options: {
                        placeholder: '请输入副标题',
                        defaultValue: '',
                    },
                    // rules: [{ required: true, message: '请输入副标题' }],
                },
                coverUpload: {},
                caseCoverUpload: {},
                localUpload: {},
            },
            coverImgOne: [],
            coverImgToo: {},
            id: '',
            currentDesignID: '', // 当前设计师id
            ossClient: {},
            addContentIds: [],
        };
    },
    methods: {
        async openDrawer(row) {
            this.drawer = true;
            this.$nextTick(() => {
                this.result.isDisplay = String(row.isDisplay);
                this.result.collectionName = row.nickName;
                this.coverImgOne = row.coverVoList;
                this.result.recommendation = row.reason;
                this.currentDesignID = row.accountId;
            });

            this.id = row.id;
        },
        uploadImgList(file) {
            this.imgList.push(file);
            // imgList.forEach(i => {
            //     console.log(i, '----------------');
            //     this.imgList.push(i);
            // });
        },
        innerDrawerSubmit(caseCoverImg, addContentIds) {
            console.log(addContentIds, 'addContentIds---从案例列表里挑选的图片和id');
            let imgList = [];
            imgList = caseCoverImg;
            imgList.forEach(i => {
                this.coverImgOne.push(i);
            });
            this.addContentIds = addContentIds;
        },
        handleClose() {
            this.drawer = false;
        },
        handleCaseCoverUpload() {
            this.$refs.workCollect.openDrawer({
                accountId: this.currentDesignID,
                collectionId: this.$route.query.id,
            });
            // this.$refs.workCollect.open();
        },
        handleSubmit() {
            let check = false;
            let checkToo = false;
            if (!this.coverImgOne?.length) {
                this.$message.error('封面图必传');
                return;
            }
            this.$refs.allocation.validateAll('ALLOCATION', res => {
                if (res) {
                    check = true;
                }
            });
            if (check) {
                let data = {
                    id: this.id,
                    ...(this.result.collectionName && {
                        collectionName: this.result.collectionName,
                    }),
                    ...(this.result.isDisplay && {
                        isDisplay: this.result.isDisplay,
                    }),
                    coverVoList: [
                        // {
                        //     area: 0,
                        //     contentId: 0,
                        //     family: '',
                        //     highlight: '',
                        //     url: '',
                        // },
                    ],
                    reason: this.result.recommendation,
                };

                this.coverImgOne.forEach(i => {
                    data.coverVoList.push({
                        url: i.url,
                        contentId: i.contentId || i.designWorksId,
                    });
                });
                console.log(data);
                rankingUpdate(data).then(res => {
                    this.$message.success('修改成功');
                    this.handleClose();
                    this.$emit('upDataList');
                });
            }
            // this.$refs.allocation.validateAll('ALLOCATION_TOO', res => {
            //     if (res) {
            //         checkToo = true;
            //     }
            // });
            // if (check && checkToo) {
            // }
        },
        uploadImgListOne(list, item) {
            console.log(list, item, '拖拽');
            this.coverImgOne = list;
        },

        sortImgList(list) {
            this.coverImgOne = list;
        },
        uploadImgListToo() {},
        clickFn() {},
        changeFn() {},
    },
    created() {
        this.getOss();
    },
};
</script>
<style scoped>
.dialog-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 30px;
}
</style>
