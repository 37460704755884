<template>
    <div class="mkt">
        <sc-search-v2
            name="首页banner搜索项"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            size="small"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
        <div class="btns">
            <el-button size="small" type="primary" @click="handleAction('del')">删除</el-button>
            <el-button size="small" type="primary" @click="handleAction('up')">上架</el-button>
            <el-button size="small" type="primary" @click="add('add')">添加</el-button>
        </div>
        <div ref="table">
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="false"
                :selection="true"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @selectAll="selectAll"
                @selectionChange="selectionChange"
            >
                <template v-slot:path>
                    <el-table-column label="图片">
                        <template slot-scope="scope">
                            <el-image
                                :src="scope.row.path"
                                style="width:50px;height:50px;"
                            ></el-image>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:isShelf>
                    <el-table-column label="是否上架">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.isShelf"
                                active-text="上架"
                                inactive-text="下架"
                                @change="handleChangeIsShelf($event, scope.row)"
                                active-value="0"
                                inactive-value="1"
                            ></el-switch>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="160">
                        <template slot-scope="scope">
                            <span class="btn-style" type="text" @click="add(scope.row)">编辑</span>
                            <span class="btn-style" type="text" @click="del(scope.row)">删除</span>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <dialog-add ref="dialogAdd" @closeDrawer="closeDrawer"></dialog-add>
    </div>
</template>

<script>
// let contentTypeInnerLink = [
//     { label: '笔记', id: 1 },
//     { label: '案例', id: 2 },
//     { label: '灵感集', id: 3 },
//     { label: '评论', id: 4 },
//     { label: '话题', id: 5 },
//     { label: '图片', id: 6 },
//     { label: '设计师', id: 7 },
//     { label: '视频笔记', id: 8 },
//     { label: '案例切片', id: 10 },
//     { label: '合集', id: 12 },
//     { label: '设计师榜单', id: 13 },
//     { label: '外部链接', id: 15 },
// ];
import {
    getBannerList,
    delBanner,
    isShelfBanner,
    saveOrUpdateBanner,
} from '@/api/mkt/recommend-banner';
import dialogAdd from './components/details.vue';
import { getAdJumpTypeList } from '@/api/mkt/market.js';
export default {
    name: '',
    components: { dialogAdd },
    data() {
        return {
            columns: {
                id: {
                    name: '内容ID',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                search: {
                    name: '内容名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                contentType: {
                    name: '内容类型',
                    type: 'select',
                    options: {
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
            },
            result: {},
            tableHead: [
                {
                    name: '图片',
                    key: 'path',
                },
                { name: '跳转类型', key: 'contentType', formatter: this.parseContentType },
                { name: '跳转链接', key: 'link' },
                { name: '内容名称', key: 'contentName' },
                { name: '排序', key: 'defOrder' },
                // { name: 'tab 分类', key: 'recommendName' },
                { name: '是否上架', key: 'isShelf' },
                { name: '操作', key: 'control' },
            ],
            tableHeightCalc: 500,
            list: [{ img: '' }],
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            multipleSelection: [],
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 138;
        });
        this.init();
    },
    methods: {
        async init() {
            await this.getAdJumpTypeListFn();
            this.getData();
        },
        parseContentType(val) {
            let str =
                this.columns.contentType.options.options.find(
                    item => item.dictValue == val.contentType
                )?.label || '--';
            return str;
        },
        async getAdJumpTypeListFn() {
            getAdJumpTypeList().then(res => {
                this.columns.contentType.options.options = res.data;
            });
        },
        handleAction(type) {
            if (!this.multipleSelection.length) {
                this.$message.info('必选一条数据');
                return;
            }
            let _ids = [];
            this.multipleSelection.map(item => _ids.push(item.id));
            if (type == 'up') {
                this.isShelfBanner(0, _ids);
                return;
            } else {
                this.delBanner(_ids);
                return;
            }
        },

        add(row) {
            this.$refs.dialogAdd.open(row, this.columns.contentType.options.options);
        },
        selectionChange(val) {
            console.log(val);
            this.multipleSelection = val;
        },
        selectAll(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        del(row) {
            let _ids = [row.id];
            this.delBanner(_ids);
        },
        delBanner(ids) {
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                delBanner({ ids }).then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                });
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getData();
        },
        pageChange(val) {
            this.page.page = val;
            this.getData();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        getData() {
            getBannerList({
                ...this.result,
                page: this.page.page,
                size: this.page.size,
            }).then(res => {
                if (!res.data.children?.length) {
                    this.list = [];
                } else {
                    this.list = res.data.children.map(item => {
                        return {
                            ...item,
                            isShelf: String(item.isShelf),
                        };
                    });
                }

                this.page.count = res.data.total;
            });
        },
        handleChangeIsShelf(val, row) {
            let _ids = [row.id];
            this.isShelfBanner(val, _ids);
        },
        isShelfBanner(val, _ids) {
            isShelfBanner({
                ids: _ids,
                isShelf: val,
            }).then(res => {
                this.$message.success(res.message);
                this.getData();
            });
        },
        closeDrawer() {
            this.getData();
        },
        changeFn(data) {
            this.getData();
        },
        // 点击事件回调方法
        clickFn(data) {
            this.getData();
        },
    },
};
</script>
<style lang="less" scoped>
.mkt {
    .btns {
        padding: 20px 20px 0 20px;
    }
}
</style>
