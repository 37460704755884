//  /banner/getBannerList
import rq from '@/utils/https.js';
const $ajax = rq.requests;

//
export const getBannerList = data =>
    $ajax({
        url: '/banner/getBannerList',
        method: 'post',
        data,
    });
// /banner/getContentById
export const getContentById = params =>
    $ajax({
        url: '/banner/getContentById',
        method: 'get',
        params,
    });
// /banner/getTab
export const getTab = params =>
    $ajax({
        url: '/banner/getTab',
        method: 'get',
        params,
    });
// /banner/delBanner
export const delBanner = data =>
    $ajax({
        url: '/banner/delBanner',
        method: 'post',
        data,
    });
// /banner/isShelfBanner
export const isShelfBanner = data =>
    $ajax({
        url: '/banner/isShelfBanner',
        method: 'post',
        data,
    });
// /banner/saveOrUpdateBanner
export const saveOrUpdateBanner = data =>
    $ajax({
        url: '/banner/v3.54/saveOrUpdateBanner',
        method: 'post',
        data,
    });
