<template>
    <div class="add-tag-container">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="30%"
            :title="title"
            :before-close="close"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                :LS="LS"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="addialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getTabInfo, saveTab, editRecommendName } from '@/api/mkt/home-tab.js';
import mixins from '@/mixins';
export default {
    props: {},
    mixins: [mixins],
    data() {
        return {
            title: '新增',
            addialogVisible: false,
            dialogVisible: false,
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            LS: { 'min-width': '60px' }, //设置label宽度

            columns: {
                pageType: {
                    name: '配置方式',
                    type: 'radio',
                    options: {
                        options: [
                            { label: '内容ID', value: 1 },
                            { label: '链接', value: 2 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '' }],
                },
                nameID: {
                    name: '内容ID',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },
                title: {
                    name: '内容标题',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },
                name: {
                    name: '内容名称',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },

                orderSort: {
                    name: '排序值',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入排序值，数值越小权重越高',
                        inputType: 'number',
                        max: 9,
                    },
                    rules: [{ required: true, message: '请输入排序值' }],
                },
                showCover: {
                    name: '展示封面',
                    type: 'upload',
                    hidden: false,
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
            },
            result: {},
        };
    },
    watch: {},
    mounted() {
        this.getLabelCommonList();
    },
    methods: {
        open(row) {
            this.addialogVisible = true;
            if (row.id) {
                this.title = '编辑内容';
            }
            if (!row.id) {
                this.title = '添加内容';
            }
            this.$forceUpdate();
        },
        close() {
            this.addialogVisible = false;
            this.result = {};
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, this.result.labels, 88888);
                let params = {
                    recName: this.result.recName,
                    defOrder: parseInt(this.result.defOrder),
                };
                if (this.result.id) {
                    params.id = this.result.id;
                }
                if (this.columns.labels && this.columns.labels.type != 'hidden') {
                    //有标签属性
                    let paramsArr = [];
                    let childAll = [];
                    this.columns.labels.options.options.map(item => {
                        item &&
                            item.children &&
                            item.children.map(child => {
                                childAll.push({
                                    labelId: child.id,
                                    labelName: child.labelName,
                                    properties: child.properties,
                                    groupType: item.groupType,
                                });
                            });
                    });
                    this.result.labels.map(item => {
                        childAll.map(child => {
                            if (item == child.labelId) {
                                paramsArr.push(child);
                            }
                        });
                    });
                    params.labels = paramsArr;
                }
                if (this.title === '编辑') {
                    editRecommendName(params).then(res => {
                        console.log('%c 编辑------------', 'color:red');
                        this.close();
                        this.$emit('handleClose');
                    });
                } else {
                    saveTab(params).then(res => {
                        console.log(res, 'res-----');
                        this.close();
                        this.$emit('handleClose');
                    });
                }
            });
        },
        handleReset() {
            this.result = {
                publicData: '',
                job: 0,
                type: 0,
                isDisplay: '',
            };
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'defOrder') {
                this.result.defOrder = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.defOrder);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        changeSelect(val) {
            console.log(val, 'val--------');
        },
        removeSelect(val) {
            console.log(val, 'removeSelect');
        },
        showInfo(row) {
            getTabInfo({ id: row.id }).then(res => {
                this.result = res.data;
                // 标签数据回显
                if (res.data.operationLabels && res.data.operationLabels.length) {
                    this.worksInfo.operationIdsAll = res?.data?.operationLabels[0].id || '';
                }
                let tagList = res.data.labels.map(item => {
                    return item.labelId;
                });
                this.result.labels = tagList;
            });
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
