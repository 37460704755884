<template>
    <div class="page-head-body" :class="[className ? className : '']">
        <div class="btns-box">
            <div class="btns">
                <slot name="top-btn"></slot>
            </div>
            <div class="search" v-show="search.keywordDisable">
                <el-input
                    :placeholder="searchPlaceholder"
                    v-model.trim="search.keyword"
                    size="mini"
                    style="width: 180px; margin-right: 10px"
                    clearable
                ></el-input>
                <!-- <a href="javascript:;" class="el-icon-search" @click="searchClick"></a> -->
                <el-button type="primary" @click="searchClick" size="mini">搜索</el-button>
            </div>
        </div>
        <div class="search-box">
            <slot name="search"></slot>
        </div>
        <div class="tabs">
            <slot name="tabs"></slot>
        </div>
        <div class="table" v-show="tableDisable">
            <el-table
                size="mini"
                v-loading="load"
                :data="tableData"
                ref="tablename"
                :cell-style="cellStyle"
                :row-key="rowKey"
                :height="TableHeight"
                highlight-current-row
                @row-dblclick="dbClick"
                @row-click="rowClick"
                @selection-change="handleSelectionChange"
                @sort-change="handleSortChange"
                @select-all="handselectAll"
                :tree-props="{ children: 'children' }"
                :default-expand-all="defaultExpandAll"
            >
                >
                <template slot="empty">
                    <p style="line-height: 20px; color: #999">暂无数据</p>
                </template>

                <slot name="special-column"></slot>
                <slot name="special-colum-2"></slot>
                <template v-if="!hasKeyArray">
                    <el-table-column
                        v-for="(item, index) in tableHead"
                        show-overflow-tooltip
                        :align="
                            item.align == 'right'
                                ? 'right'
                                : item.align == 'center'
                                ? 'center'
                                : 'left'
                        "
                        :width="item.width"
                        :prop="item.value"
                        :label="item.name"
                        :formatter="item.formatter"
                        :key="index"
                        :sortable="item.sortable"
                    >
                        <!-- <template slot-scope="scope">
                            <a
                                v-if="item.isLink"
                                :href="scope.row.messageLink"
                                target="_blank"
                                class="blank-style"
                            >
                                {{ scope.row.messageLink }}
                            </a>
                            <span v-else>{{ scope.row[item.value] }}</span>
                        </template> -->
                    </el-table-column>
                </template>
                <template v-else>
                    <el-table-column
                        v-for="(item, index) in tableHead"
                        show-overflow-tooltip
                        :align="item.align == 'right' ? 'right' : 'left'"
                        :width="item.width"
                        :prop="item.value"
                        :label="item.name"
                        :formatter="item.formatter"
                        :key="index"
                        :sortable="item.sortable"
                    >
                        <template slot-scope="scope">
                            <template v-if="scope.row[item.value] instanceof Array">
                                <div
                                    v-for="(row_item, row_index) in scope.row[item.value]"
                                    :key="row_index"
                                >
                                    {{
                                        scope.row[item.value][row_index] ||
                                            (scope.row[item.value][row_index] === 0 ? 0 : '-')
                                    }}
                                </div>
                            </template>
                            <template v-else>
                                {{
                                    scope.row[item.value] || (scope.row[item.value] === 0 ? 0 : '-')
                                }}
                            </template>
                        </template>
                    </el-table-column>
                </template>

                <slot name="special-column-end"></slot>

                <!-- 控制行上下移动 -->
                <slot name="move"></slot>
                <slot name="control"></slot>
            </el-table>

            <div class="table-bottom">
                <div class="btns">
                    <slot name="bottom-btn"></slot>
                </div>
                <!-- pagination.count 分页是否显示 -->
                <div class="table-pagination" v-if="pagination.count">
                    <el-pagination
                        size="mini"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagination.count"
                        :page-sizes="[30, 50, 100, 200]"
                        :current-page="pagination.index"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                    ></el-pagination>
                    <!-- :disabled="pagination.count / pagination.size <= 1 ? true : false" -->
                </div>
            </div>
        </div>
        <slot name="bottom"></slot>
    </div>
</template>
<script>
export default {
    name: 'Menu',
    components: {},
    props: {
        className: {
            type: String,
            default: '',
        },
        tablename: {
            type: String,
            default: '',
        },
        tableDisable: {
            type: Boolean,
            default: true,
        },
        //table高度
        TableHeight: {
            type: [Number, String],
        },
        tableData: {
            type: Array,
            default: function() {
                return [];
            },
        },
        tableHead: {
            type: Array,
            default: function() {
                return [];
            },
        },
        load: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object,
            default: function() {
                return {
                    count: 0,
                    index: 0,
                };
            },
        },
        search: {
            type: Object,
            default: function() {
                return {
                    keywordDisable: false,
                    keyword: '',
                };
            },
        },
        searchPlaceholder: {
            type: String,
            default: '名字、手机号、客户编码',
        },
        rowKey: {
            type: String,
            default: 'id',
        },
        // table row中有参数含有数组
        hasKeyArray: {
            type: Boolean,
            default: false,
        },
        defaultExpandAll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        toggleRowSelection(row) {
            this.$refs.tablename.toggleRowSelection(row);
        },
        clearSelection(row) {
            this.$refs.tablename.clearSelection(row);
        },

        handleSizeChange(val) {
            this.$emit('sizeChange', val);
            /*const path = this.$route.path;
            if (path === '/market/product' || path === '/market/quan') {
                this.$emit('sizeChange', val);
            } else {
                this.$parent.$parent.$parent.pagination.size = val;
                this.$nextTick(() => {
                    this.$parent.$parent.$parent.getData();
                });
            }*/
        },
        handleCurrentChange(val) {
            this.$nextTick(() => {
                this.$refs.tablename.bodyWrapper.scrollTop = 0;
            });
            this.$emit('currentChange', val);
            /*const path = this.$route.path;
            if (path === '/market/product' || path === '/market/quan') {
                this.$emit('pageChange', val);
            } else {
                this.$parent.$parent.$parent.pagination.index = val;
                this.$nextTick(() => {
                    this.$parent.$parent.$parent.getData();
                });
            }*/
        },
        searchClick() {
            // this.$nextTick(() => {
            //     this.$parent.$parent.$parent.getData();
            // });
            this.pagination.index = 1;
            this.$emit('searchClick', this.search.keyword);
        },
        dbClick(row) {
            this.$emit('dbClick', row);
        },
        rowClick(row) {
            this.$emit('rowClick', row);
        },
        handleSelectionChange(row) {
            this.$emit('selection-change', row);
        },
        handselectAll() {
            this.$emit('select-all');
        },
        handleSortChange({ column, prop, order }) {
            this.$emit('sort-change', { column, prop, order });
        },
        cellStyle(e) {
            if (e.row?.isSplit) {
                return 'color: #999';
            } else {
                let obj = {};
                this.$emit('cellStyle', e, (color = {}) => {
                    obj = color;
                });
                return obj;
            }
        },
        reload() {
            this.$parent.$parent.$parent.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.detail-image {
    width: 40px;
    height: 30px;
}
.btns {
    width: 100%;
}
.blank-style {
    color: #006eff;
}
</style>
<style lang="less">
.el-tooltip__popper {
    max-width: 800px;
}
</style>
