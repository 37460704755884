import { render, staticRenderFns } from "./wang-editorToo.vue?vue&type=template&id=1db543d0&scoped=true"
import script from "./wang-editorToo.vue?vue&type=script&lang=js"
export * from "./wang-editorToo.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./wang-editorToo.vue?vue&type=style&index=1&id=1db543d0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db543d0",
  null
  
)

export default component.exports