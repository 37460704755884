<template>
    <div class="detail">
        <el-button size="mini" @click="handleBack">返回</el-button>
        <div class="form-wrap">
            <sc-form-v2
                tablename=""
                name=""
                :columnsNum="1"
                :result="result"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                ref="form1"
            >
                <template v-slot:files>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">图片</div>
                        </template>
                        <div style="text-align: left;display:flex;">
                            <div v-for="(item, ind) in result.files" :key="ind" class="img-list">
                                <el-image
                                    class="img"
                                    :src="item"
                                    fit="contain"
                                    style="width:150px;height:150px;margin-right:10px;"
                                    :preview-src-list="srcList"
                                ></el-image>
                                <div>{{ item.spaceName }}</div>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
        </div>
    </div>
</template>
<script>
import { getDetail } from '@/api/mkt/status';
export default {
    name: 'status-detail',
    data() {
        return {
            result: { files: [], picDescribe: '', spaceName: '', title: '' },
            columns: [
                {
                    key: 'files',
                    name: '图片',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'picDescribe',
                    name: '图片组描述',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'spaceName',
                    name: '所属空间',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'title',
                    name: '所属案例',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
            ],
            row: {},
            srcList: [],
        };
    },
    methods: {
        init() {
            getDetail({ id: this.row.id }).then(res => {
                this.result.files = res.data;
                this.srcList = res.data;
            });
        },
        handleBack() {
            this.$router.go(-1);
        },
    },
    created() {
        this.row = JSON.parse(this.$route.query.row);
        this.result.picDescribe = this.row.picDescribe;
        this.result.spaceName = this.row.spaceName;
        this.result.title = this.row.title;
        this.init();
    },
};
</script>
<style lang="less" scoped>
.form-wrap {
    background: #fff;
    margin-top: 20px;
    padding: 0 0 0 40px;
}
.img-list {
    display: flex;
}
</style>
