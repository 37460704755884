<template>
    <div class="mkt archives-container flex flex-col" style="height: calc(100vh - 60px)">
        <div class="flex-shrink">
            <sc-search-v2
                :columns="columns"
                :result="result"
                :btn="{
                    twoShow: true,
                }"
                :more-down="true"
                :LS="LS"
                :CS="CS"
                @click-more="setTableHeight"
                @change="searchChange"
                @search="searchSearch"
            >
                <!-- <template v-slot:dateRange></template> -->
            </sc-search-v2>
        </div>
        <!-- :default-sort="{ prop: 'LastAuditTime', order: 'descending' }" -->
        <sc-table-v2
            class="list-table flex-1 flex flex-col"
            :head="tableHead"
            :data="listData"
            :page="page"
            :load="isLoading"
            :tableHeight="tableHeight"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @sortChange="sortChange"
        >
            <template v-slot:shareStatus>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.shareStatus == 1" style="color:green">
                            启用中
                        </span>
                        <span v-else style="color:red">禁用中</span>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:imgUrl>
                <el-table-column label="头像">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.imgUrl"
                            fit="contain"
                            style="width: 50px; height: 50px"
                            :src="scope.row.imgUrl"
                            :preview-src-list="[scope.row.imgUrl]"
                        ></el-image>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="142">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="handleChangeStatus(scope.row)">
                            {{ scope.row.shareStatus == 1 ? '禁用' : '启用' }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="openTestAccountDialog(scope.row)"
                        >
                            分享动作明细
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <testAccountDialog ref="testAccountDialog" :title="title"></testAccountDialog>
    </div>
</template>
<script>
import lodash from 'lodash';
import getListMixin from '@/mixins/getListMixin';
import mixins from '@/mixins';

import { getStaffList, updateShareStatus } from '@/api/mkt/user';
import testAccountDialog from './components/testAccount-dialog'; //点收评分

// 用户ID、昵称、手机号、近30天访问次数、身份、角色、是否首次、活跃时间、工具测试、IP地址、登录所属页面、最近预约时间、最近访问时间、首次登录时间、来源人、
const pickerOptions = {
    disabledDate(time) {
        let nowDate = new Date();
        var year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1;
        let date = nowDate.getDate();
        let nowTimer = new Date(`${year}/${month}/${date}`).getTime();
        if (time.getTime() > nowTimer) {
            return true;
        }
        return false;
    },
};
const columns = {
    searchName: {
        name: '搜索',
        type: 'input',
        options: {
            clearable: true,
            defaultValueBase: '',
            placeholder: '请输入用户姓名',
        },
        spanCol: 1,
    },
    companyCode: {
        name: '所属分公司',
        type: 'select',
        options: {
            defaultValueBase: '',
            clearable: true,
            placeholder: '请输入分公司',
            options: [],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        spanCol: 1,
    },
    shareStatus: {
        name: '状态',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '1', sysLabel: '启用中' },
                { dictValue: '0', sysLabel: '禁用中' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
};
const initResult = {
    searchName: '',
    companyCode: '',
    shareStatus: '',
};
export default {
    name: 'user-archives-list',
    components: { testAccountDialog },
    mixins: [getListMixin, mixins],
    data() {
        return {
            columns: columns,
            result: {},
            tableHandle: getStaffList,
            LS: {
                'min-width': '100px',
                width: '100px',
                padding: 0,
                verticalAlign: 'bottom',
            },
            CS: {
                'min-width': '180px',
            },
            dateRange: {
                name: '访问时间范围',
                type: 'date',
                key: 'dateRange',
                options: {
                    clearable: true,
                    dateType: 'daterange',
                    defaultValueBase: '',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    format: 'yyyy-MM-dd',
                    valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    placeholder: '请选择',
                    pickerOptions: pickerOptions,
                },
                spanCol: 2,
            },
            tableHead: [
                {
                    name: '头像',
                    key: 'imgUrl',
                    minWidth: 280,
                    showOverflowTooltip: true,
                },
                { name: '姓名', key: 'name' },
                { name: '所属分公司', key: 'companyName', minWidth: 208 },

                { name: '岗位', key: 'job', sortable: true },
                { name: '手机号', key: 'mobile' },
                { name: '状态', key: 'shareStatus' },
                // {
                //     name: '是否真实用户',
                //     key: 'testAccount',
                //     minWidth: 94,
                //     formatter: this.testAccountFormatter,
                // },
                { name: '操作', key: 'control' },
            ],
        };
    },
    watch: {
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
    },
    created() {
        this.getCompany();
    },
    async mounted() {
        this.setTableHeight();
        // 下拉数据集合
        this.optionsData = {};
        this.filter = {
            bDate: '',
            eDate: '',
            // timeFrame 和 identity 只是为了覆盖接口的参数用
            dateRange: '',
            identity: null,
        }; // 筛选没有绑定result的数据集合
        this.queryDataMixin();
        window.addEventListener('resize', this.windowResize, false);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResize, false);
    },
    methods: {
        windowResize: lodash.debounce(function() {
            this.setTableHeight();
        }, 200),
        openTestAccountDialog(row) {
            this.$refs.testAccountDialog.open(row);
        },
        async handleChangeStatus(row) {
            let shareStatus = row.shareStatus == 1 ? 0 : 1;
            this.$confirm('确定要修改该用户状态吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                let res = await updateShareStatus({ id: row.id, shareStatus });
                if (res?.isSuccess) {
                    row.shareStatus = shareStatus;
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    });
                    this.listData.find(item => item.id == row.id).shareStatus = shareStatus;
                }
            });
        },
        shareStatusFormatter(row) {
            let obj = { 1: '启用中', 0: '禁用中' };
            console.log('-----', obj[row.shareStatus], row.shareStatus);
            return obj[row.shareStatus] || '禁用中';
        },
        loginPageFormatter(row) {
            return (
                (row.loginPage &&
                    row.loginPage + (row.loginSecondPage && '-' + row.loginSecondPage)) ||
                ''
            );
        },
        promotionFormatter(row) {
            let result = row.promotionChannel;
            if (row.promotionChannel == 'sczs-offiaccount') {
                result = '尚层装饰公众号';
            }
            return result;
        },
        getClientHeight() {
            var clientHeight = 0;
            if (document.body.clientHeight && document.documentElement.clientHeight) {
                clientHeight =
                    document.body.clientHeight < document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            } else {
                clientHeight =
                    document.body.clientHeight > document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            }
            return clientHeight;
        },
        setTableHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    // 设置table高度
                    const name = '.list-table';
                    let tableDom = document.querySelector(name);
                    if (tableDom) {
                        let topNum = tableDom.getBoundingClientRect().top;
                        let mainNum = this.getClientHeight();
                        this.tableHeight = mainNum - topNum - 47;
                    }
                }, 0);
            });
        },
        // 点击table 操作按钮
        clickControlBtn(row) {
            let url = `${location.origin}/#/mkt/user/archives/detail?accountId=${
                row.accountId
            }&ipRegion=${row.ipRegion && row.ipRegion.trim()}&customerStatus=${
                row.customerStatus
            }&identity=${row.identity}&terminalIdentification=${row.terminalIdentification}`;
            console.log('-----------row', url);

            window.open(url, '_blank');
        },
        searchChange(val) {
            console.log(val);
            this.pageChange(1);
        },
        searchSearch(val, type) {
            console.log(val, type);
            if (type === 'clear') {
                this.result = JSON.parse(JSON.stringify(initResult));
            }
            this.pageChange(1);
        },
        sortChange({ column, prop, order }) {
            console.log(column, prop, order);
            if (order == 'ascending') {
                // 上升
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 1;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 3;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 5;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 7;
                        break;
                    default:
                        break;
                }
            } else {
                // 下降
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 2;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 4;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 6;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 8;
                        break;
                    default:
                        break;
                }
            }
            this.pageChange(1);
        },
    },
};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.flex-align-center {
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.flex-col {
    flex-direction: column;
}
.flex-shrink {
    flex-shrink: 0;
}
.mkt {
    min-height: auto;
}
.archives-container {
    height: calc(100vh - 60px);
    background: #fff;
    overflow: hidden;
}

.sum-number-bar {
    margin: 15px 25px;
    padding: 15px 10px;
    background: #f8f8f8;
    font-size: 12px;
    .label {
        font-weight: bold;
        flex-shrink: 0;
    }
    .value {
        margin-left: 10px;
        justify-content: space-around;
        max-width: 867px;
    }
    .value-item {
        margin-right: 15px;
    }
}
.list-table {
    margin: 0 15px;
    padding-top: 0;
}
/deep/ .el-form .el-descriptions__body .el-descriptions-item__content {
    padding-bottom: 0;
}
/deep/ .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    padding-right: 0;
}
/deep/ .searchMain .button-box {
    margin-left: 10px;
}
/deep/ .el-icon-question {
    font-size: 14px;
    &.table-header-icon {
        margin-left: 2px;
    }
}
/deep/ .hide-label {
    display: none;
}
</style>
