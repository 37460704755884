<template>
    <div class="mkt">
        <Search></Search>
        <Content ref="contentRef"></Content>
    </div>
</template>

<script>
import Search from './search';
import Content from './content';

export default {
    name: 'exhibit-group',
    components: { Search, Content },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-exhibit-group/clear');
    },
};
</script>
<style lang="scss" scoped></style>
