<template>
    <sc-table-v2
        row-key="id"
        :head="tableHead"
        :data="list"
        :page="page"
        :page-hide="false"
        :read-table="true"
        :load="load"
        :pop-show="false"
        :show-add="false"
        :table-height="tableHeightCalc"
        :selection="false"
        ref="refTable"
        @pageChange="pageChange"
        @pageSizesChange="pageSizesChange"
    >
        <template v-slot:readNum>
            <el-table-column label="打开人数" width="100">
                <template slot-scope="scope">
                    <!-- <a href="javaScript:void(0);"> -->
                    <div class="item-box-link" @click="goAcquCustomer(scope.row)">
                        {{ scope.row.readNum }}
                    </div>
                    <!-- </a> -->
                </template>
            </el-table-column>
        </template>
        <template v-slot:readPersons>
            <el-table-column label="打开用户信息">
                <template slot-scope="scope">
                    <div class="item-box-user">
                        <!-- {{ scope.row.readPersons }} -->
                        <div
                            v-for="(item, index) in scope.row.readPersons"
                            :key="index + scope.row.id"
                            class="item-box-user-item"
                            v-if="item"
                        >
                            <el-image
                                class="image"
                                v-if="item.imgUrl"
                                fit="contain"
                                style=""
                                :src="item.imgUrl"
                                :preview-src-list="[item.imgUrl]"
                            ></el-image>
                            <div v-else class="image"></div>
                            <div class="text">{{ item.nickName }}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </template>
    </sc-table-v2>
</template>

<script>
import { getShareInfoList } from '@/api/mkt/user.js';

export default {
    name: 'browse-list',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {},
    data() {
        return {
            load: false,
            addialogVisible: false,
            tableHead: [
                { name: '分享时间', key: 'createTime', width: 140 },
                { name: '分享页面', key: 'contentName', width: 110 },
                { name: '内容标题', key: 'title', width: 300, showOverflowTooltip: true },
                { name: '分享用户昵称', key: 'nickName', width: 130 },
                { name: '分享用户手机号', key: 'mobile', width: 110 },
                { name: '打开人数', key: 'readNum', width: 90 },
                { name: '打开用户信息', key: 'readPersons', minWidth: 500 },
            ],
            tableHeightCalc: window.innerHeight - 120,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            type: '',
        };
    },
    methods: {
        init(row) {
            this.page.page = 1;
            this.row = row || {};
            this.getShareInfoListFn();
        },
        getShareInfoListFn() {
            this.load = true;
            getShareInfoList({
                shareAccountId: this.row.id,
                page: this.page.page,
                size: this.page.size,
            }).then(res => {
                if (res.code == 200) {
                    this.load = false;
                    this.list = res.data.children;
                    this.page.count = res.data.total;
                }
            });
        },
        goAcquCustomer(row) {
            this.$router.push({
                path: '/mkt/acquisition/acqu-customer',
                query: {
                    sharingRecordsId: row.id,
                },
            });
        },
        isOpenFormatter(row) {
            let obj = { 1: '是', 0: '否' };
            return obj[row.isOpen] || '';
        },
        pageChange(val) {
            this.page.page = val;
            this.getShareInfoListFn();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getShareInfoListFn();
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    /deep/ .el-dialog__body {
        padding: 10px 20px !important;
    }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
}
.item-box-link {
    width: 100px;
    cursor: pointer;
    color: #006eff;
}
.item-box-user {
    min-width: 500px;
    max-height: 156px;
    .item-box-user-item {
        display: inline-block;
        margin-right: 20px;
    }
    .image {
        width: 50px;
        height: 50px;
    }
    .text {
        text-wrap: nowrap;
    }
}
</style>
