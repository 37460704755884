import rq from '@/utils/https.js';
const $ajax = rq.requests;

// /sys-content-level/list
export const contentLevelList = data =>
    $ajax({
        url: '/sys-content-level/list',
        method: 'post',
        data,
    });
// /sys-content-level/save/content-level
export const contentLevelSave = data =>
    $ajax({
        url: '/sys-content-level/save/content-level',
        method: 'post',
        data,
    });
// /sys-content-level/del
export const contentLevelDel = params =>
    $ajax({
        url: '/sys-content-level/del',
        method: 'get',
        params,
    });
export const info = params =>
    $ajax({
        url: '/article/info',
        method: 'get',
        params,
    });
export const getContentById = params =>
    $ajax({
        url: '/sys-content-level/getContentById',
        method: 'get',
        params,
    });
