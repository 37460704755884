<template>
    <div class="mkt archives-container flex flex-col" style="height: calc(100vh - 60px)">
        <accountTable ref="accountTableRef" />
    </div>
</template>
<script>
import lodash from 'lodash';
import accountTable from '../acqu-staff/components/account-table.vue'; //点收评分

export default {
    name: 'user-archives-list',
    components: { accountTable },
    data() {
        return {
            result: {},
        };
    },
    watch: {},
    created() {},
    async mounted() {
        this.setTableHeight();
        window.addEventListener('resize', this.windowResize, false);
        this.$refs.accountTableRef.init();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResize, false);
    },
    methods: {
        windowResize: lodash.debounce(function() {
            this.setTableHeight();
        }, 200),

        getClientHeight() {
            var clientHeight = 0;
            if (document.body.clientHeight && document.documentElement.clientHeight) {
                clientHeight =
                    document.body.clientHeight < document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            } else {
                clientHeight =
                    document.body.clientHeight > document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            }
            return clientHeight;
        },
        setTableHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    // 设置table高度
                    const name = '.list-table';
                    let tableDom = document.querySelector(name);
                    if (tableDom) {
                        let topNum = tableDom.getBoundingClientRect().top;
                        let mainNum = this.getClientHeight();
                        this.tableHeight = mainNum - topNum - 47;
                    }
                }, 0);
            });
        },
    },
};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.flex-align-center {
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.flex-col {
    flex-direction: column;
}
.flex-shrink {
    flex-shrink: 0;
}
.mkt {
    min-height: auto;
}
.archives-container {
    height: calc(100vh - 60px);
    background: #fff;
    overflow: hidden;
}

/deep/ .el-form .el-descriptions__body .el-descriptions-item__content {
    padding-bottom: 0;
}
/deep/ .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    padding-right: 0;
}
/deep/ .searchMain .button-box {
    margin-left: 10px;
}
/deep/ .el-icon-question {
    font-size: 14px;
    &.table-header-icon {
        margin-left: 2px;
    }
}
/deep/ .hide-label {
    display: none;
}
</style>
