<template>
    <div class="content">
        <div>
            <sc-tables
                :head="table.tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeight"
                :read-table="true"
                :load="false"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <template v-slot:identification>
                    <el-table-column>
                        <template slot="header">用户类型</template>
                        <template slot-scope="scope">
                            {{ scope.row.identification | userTypeFilter }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:labelNames>
                    <el-table-column>
                        <template slot="header">用户标签</template>
                        <template slot-scope="scope">
                            {{ scope.row.labelNames | labelNamesFilter }}
                        </template>
                    </el-table-column>
                </template>
                //插槽例子，操作列用插槽写入
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="160">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                v-if="scope.row.identification == 2"
                                @click="onEdit(scope.row)"
                            >
                                编辑
                            </el-button>
                            <!-- <el-button type="text" size="small" @click="editTag(scope.row)">
                                维护标签
                            </el-button> -->
                            <span>&nbsp;</span>
                            <sc-qrcode-popover :id="scope.row.id" type="user"></sc-qrcode-popover>
                        </template>
                    </el-table-column>
                </template>
            </sc-tables>
        </div>
        <Add
            :item="row"
            :id="row.id"
            v-if="addiagvisible"
            :addiagvisible="addiagvisible"
            @closediag="closediag"
        ></Add>

        <postDialog
            :postDialogShow="postDialogShow"
            @onClosePostDialog="onClosePostDialog"
            :currentRow="currentRow"
        ></postDialog>
    </div>
</template>

<script>
import postDialog from './components/post-dialog';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-user');
import Add from './add.vue';

export default {
    data() {
        return {
            table: {
                tableHead: [
                    { name: '用户姓名', value: 'name' },
                    { name: '艺名', value: 'nickName' },
                    { name: '用户类型', value: 'identification' },
                    { name: '角色', value: 'role' },
                    { name: 'BPM账号', value: 'account' },
                    { name: '岗位', value: 'job', width: 130 },
                    { name: '用户标签', value: 'labelNames', width: 200 },
                    { name: '贡献内容', value: 'contributeNumber' },
                    { name: '所属城市', value: 'city', width: 130 },
                    { name: '首次登录时间', value: 'firstLoginTime', width: 160 },
                ],
                tableData: [],
            },
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            addiagvisible: false,
            row: {},
            tableHeight: window.innerHeight - 185,
            postDialogShow: false,
            currentRow: {},
        };
    },
    components: {
        Add,
        postDialog,
    },
    filters: {
        labelNamesFilter(val) {
            if (val != '--') {
                return val.join('、');
            }
            return '';
        },
        userTypeFilter(val) {
            let userTypeObj = {
                1: 'PRO设计师',
                2: '内部员工',
                3: '普通用户',
                4: '潜在客户',
            };
            if (val) {
                return userTypeObj[val];
            }
            return '';
        },
    },
    // computed: {
    //     ...mapState(['list']),
    // },
    computed: mapState({
        list: state => state.list,
        page: state => state.page,
    }),
    mounted() {
        this.$store.dispatch('mkt-user/list'); //使用vuex调用接口
        // setTimeout(() => {
        //     console.log(
        //         'state-------------------------------------',
        //         this.$store.state['mkt-user'].page
        //     );
        // }, 3000);
    },
    methods: {
        // 编辑岗位
        onEdit(row) {
            this.currentRow = row;
            this.postDialogShow = true;
        },

        onClosePostDialog() {
            this.postDialogShow = false;
            this.currentRow = {};
            this.identificationType = '';
            let params = {
                searchType: '',
                searchName: '',
                Identification: '',
                bDate: '',
                eDate: '',
            };

            this.$store.commit('mkt-user/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-user/list');
        },

        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        goAdd() {
            this.addiagvisible = true;
        },
        closediag() {
            this.addiagvisible = false;
        },
        pageChange(val) {
            let params = this.$store.state['mkt-user'].params;
            params.page = val;
            this.$store.commit('mkt-user/setParams', params);
            this.$store.dispatch('mkt-user/list');
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            let params = this.$store.state['mkt-user'].params;
            params.size = val;
            this.$store.commit('mkt-user/setParams', params);
            this.$store.dispatch('mkt-user/list');
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
        editTag(item) {
            this.addiagvisible = true;
            this.row = item;
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    margin: 10px 15px;
}
</style>
