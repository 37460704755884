<template>
    <div class="add-tag-container">
        <!-- <el-dialog :visible.sync="addiagvisible" width="40%" :before-close="handleClose"> -->
        <el-drawer
            title="编辑"
            :visible.sync="addiagvisible"
            direction="rtl"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="30%"
        >
            <sc-form-v2
                tablename="CRM_TAG"
                :columnsNum="1"
                :columns="columns"
                :result="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="tagform"
            >
                <template v-slot:qq>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">自定义标签：</div>
                        </template>
                        <div style="text-align: left">
                            <el-tag :key="tag" v-for="tag in result.divIds">{{ tag }}</el-tag>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">运营标签：</div>
                        </template>
                        <el-cascader
                            style="width:95%"
                            v-model="result.inspirationIds"
                            :size="size"
                            :options="operationList"
                            placeholder="最多选择20个"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                                multiple: true,
                            }"
                            :show-all-levels="false"
                            @change="selecttOpeTag"
                            ref="operaCascader"
                            clearable
                            filterable
                        ></el-cascader>

                        <!-- <div style="text-align: left">
                            <el-tag
                                :key="index"
                                v-for="(tag, index) in optionText"
                                closable
                                :disable-transitions="false"
                                @close="tagHandleClose(tag)"
                            >
                                {{ tag }}
                            </el-tag>
                            <el-input
                                class="input-new-tag"
                                v-if="tagInputVisible"
                                v-model="tagInputValue"
                                ref="saveTagInput"
                                size="small"
                                @keyup.enter.native="tagHandleInputConfirm"
                                @blur="tagHandleInputConfirm"
                            ></el-input>
                            <el-button
                                v-else
                                class="button-new-tag"
                                size="small"
                                @click="tagShowInput"
                            >
                                + New Tag
                            </el-button>
                        </div> -->
                        <!-- 注释分级标签  <el-cascader
                                v-model="result.cus_tag"
                                size="small"
                                :options="tagTree"
                                :props="{
                                    label: 'label',
                                    value: 'value',
                                    checkStrictly: true,
                                    emitPath: false,
                                    multiple: true,
                                }"
                                :show-all-levels="false"
                                @change="selectTagChange"
                                ref="cascader"
                                clearable
                                filterable
                            ></el-cascader> -->
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="handleReset">取消</el-button>
                <el-button size="mini" type="primary" @click="handSubmit">确认</el-button>
            </span> -->

            <div class="demo-drawer__footer" style="text-align: right;margin-top:50px;">
                <el-button size="mini" style="margin-right: 20px" @click="handleReset">
                    取消
                </el-button>
                <el-button type="primary" size="mini" @click="handSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { updateTag, getUserInfo, operationLabel } from '@/api/mkt/user.js';
export default {
    props: {
        addiagvisible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '',
        },
    },
    mounted() {
        this.getUserInfo();
        this.operationLabel();
    },
    data() {
        return {
            columns: [
                {
                    key: 'qq',
                },

                // {
                //     key: 'k3',
                //     name: '排序值',
                //     type: 'input',
                //     options: {
                //         placeholder: '',
                //     },
                // },
            ],
            result: {
                k1: '',
                k2: '',
                // k3: '',
                divIds: [],
                inspirationIds: [],
                labelLists: [], //提交到后台数据
            },
            tagInputVisible: false, //标签
            tagInputValue: '', ////标签
            optionText: [],
            operationList: [],
        };
    },
    watch: {
        id(newData, oldData) {
            newData = newData || '';
            if (newData != oldData) {
                this.getUserInfo();
                this.operationLabel();
            }
        },
    },
    methods: {
        async getUserInfo() {
            let res = await getUserInfo({ id: this.id });
            console.log(res);
            if (res.code == 200) {
                this.result.inspirationIds = res.data.inspirationIds || [];
                this.result.divIds = res.data.divIds || [];
            }
        },
        async operationLabel() {
            let res = await operationLabel();
            console.log(res);
            if (res.code == 200) {
                for (let item of res.data) {
                    item.labelName = item.groupName;
                    item.children = item.labelList;
                }
                this.operationList = res.data;
            }
        },
        selecttOpeTag() {
            if (this.inspirationIds?.length > 20) {
                this.$message.warning('最多选择20个标签');
                this.result.inspirationIds = this.inspirationIds.slice(0, 20);
            }
            let inspirationIds = this.result.inspirationIds;
            let data = this.$refs.operaCascader.getCheckedNodes();
            let labelLists = [];
            data.map(item => {
                let labelItem = {};
                labelItem.labelId = item.data.id;
                labelItem.labelName = item.data.labelName;
                if (labelItem.labelId && inspirationIds.indexOf(labelItem.labelId) > -1) {
                    labelLists.push(labelItem);
                }
            });
            this.result.labelLists = labelLists;
            console.log(this.result.labelLists, '9999');
        },

        handleClose() {
            (this.result = {
                k1: '',
                k2: '',
                // k3: '',
                divIds: [],
                inspirationIds: [],
                labelLists: [], //提交到后台数据
            }),
                this.$emit('closediag');
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        handSubmit() {
            this.$refs.tagform.validateAll('CRM_TAG', () => {
                //校验回调
                updateTag({
                    labelLists: this.result.labelLists,
                    userId: this.id,
                }).then(res => {
                    (this.result = {
                        k1: '',
                        k2: '',
                        // k3: '',
                        divIds: [],
                        inspirationIds: [],
                        labelLists: [], //提交到后台数据
                    }),
                        this.$store.dispatch('mkt-user/list');
                    this.$emit('closediag');
                });
            });
        },
        handleReset() {
            this.result = {
                k1: '',
                k2: '',
                // k3: '',
                divIds: [],
                inspirationIds: [],
                labelLists: [], //提交到后台数据
            };
            this.$emit('closediag');
        },
        // 精细化打标，只能向底层打标，不能向顶层打标，暂时限制上一级
        // selectTagChange() {
        //     let data = this.$refs.cascader.getCheckedNodes();
        //     let activeTagArr = [];
        //     if (data.length) {
        //         for (let item of data) {
        //             this.attrId(item.parent, activeTagArr);
        //         }
        //     }

        //     this.activeTagArr = activeTagArr;
        //     console.log('---------data', data, this.activeTagArr);
        //     if (this.ruleForm?.labelId?.length) {
        //         let labelId = this.ruleForm.labelId;
        //         for (let label of activeTagArr) {
        //             if (labelId.indexOf(label) > -1) {
        //                 labelId.splice(labelId.indexOf(label), 1);
        //             }
        //         }
        //         this.labelId = labelId;
        //     }
        //     let tagTree = Object.assign([], this.tagTree);
        //     this.attrDisabled(tagTree);
        //     this.tagTree = tagTree;
        //     this.$forceUpdate();
        //     console.log('-----this.tagTree', this.tagTree);
        // },
        // attrId(parent, activeTagArr) {
        //     if (parent?.value) {
        //         activeTagArr.push(parent?.value);
        //         if (parent.parent) {
        //             this.attrId(parent.parent, activeTagArr);
        //         }
        //     }
        // },
        // attrDisabled(children) {
        //     if (children && children.length) {
        //         for (let one of children) {
        //             if (!one.groupName) {
        //                 if (this.activeTagArr.includes(one.id)) {
        //                     one.disabled = true;
        //                 } else {
        //                     if (one.disabled) {
        //                         one.disabled = false;
        //                     }
        //                 }
        //             }

        //             if (one?.children?.length) {
        //                 this.attrDisabled(one.children);
        //             }
        //         }
        //     }
        // },

        // 标签操作
        // 删除标签 提交保存时才对应图片存储到searchOptionList字段
        // tagHandleClose(tag) {
        // console.log('------this.optionText', this.optionText);
        // this.optionText.splice(this.optionText.indexOf(tag), 1);

        // checkboxHaveData({ optionId }).then(res => {
        //     // console.log('checkboxHaveData', res, optionId);
        //     if (res.code === 200) {
        //         if (res.data) {
        //             //data为true标识已使用
        //             this.$confirm('当前选项已经在提供搜索使用，确定要删除吗？', '提示', {
        //                 confirmButtonText: '确定',
        //                 cancelButtonText: '取消',
        //                 type: 'warning',
        //             })
        //                 .then(() => {
        //                     this.optionText.splice(this.optionText.indexOf(tag), 1);
        //                 })
        //                 .catch(() => {});
        //         } else {
        //             // console.log('------this.optionText', this.optionText);
        //             this.optionText.splice(this.optionText.indexOf(tag), 1);
        //         }
        //     }
        // });
        // },
        // tagShowInput() {
        //     this.tagInputVisible = true;
        //     this.$nextTick(_ => {
        //         this.$refs.saveTagInput.$refs.input.focus();
        //     });
        // },
        // // 添加标签
        // tagHandleInputConfirm() {
        //     let tagInputValue = this.tagInputValue;
        //     if (tagInputValue) {
        //         this.optionText.push(tagInputValue);
        //     }
        //     this.tagInputVisible = false;
        //     this.tagInputValue = '';
        // },
    },
};
</script>
<style scoped lang="less">
/deep/ .self-drawer {
    padding: 0 20px;
}
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
