<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
    </div>
</template>

<script>
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                keywords: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '名称/话题/创建人姓名/昵称/描述/ID',
                    },
                    spanCol: 1,
                },
                noteType: {
                    name: '所属专题',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '所属专题',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                    spanCol: 1,
                },
                articleType: {
                    name: '类型',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        clearable: true,
                        placeholder: '类型',
                        options: [
                            { label: '视频', value: '1' },
                            { label: '图文', value: '3' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                approvalStatus: {
                    name: '审核状态',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '审核状态',
                        options: [
                            { label: '待审核', value: '1' },
                            { label: '审核通过', value: '3' },
                            { label: '审核退回', value: '4' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                            emitPath: false,
                            checkStrictly: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },

                labelId: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },

                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入分公司',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                updateTime: {
                    name: '提交时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: 'aaa',
                        placeholder: '请输入提交时间',
                    },
                    spanCol: 2,
                },

                contentLevel: {
                    name: '评级',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'dictValue',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                isWeight: {
                    name: '权重值',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            { value: 1, label: '无' },
                            { value: 2, label: '有' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                time: {
                    name: '审核时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: 'aaa',
                        placeholder: '请输入审核时间',
                    },
                    spanCol: 1,
                },
                createTime: {
                    name: '发布时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: 'aaa',
                        placeholder: '请输入发布时间',
                    },
                    spanCol: 1,
                },
            },
            result: {
                keywords: '',
                categoryId: '',
                articleType: '',
                page: 1,
                size: 30,
                approvalStatus: '',
                labelIdsArr: [],
                categoryIdArr: [],
                noteType: '',
                weightSortType: '',
                contentLevel: '',
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: false,
            levelList: [],
        };
    },
    watch: {
        searchLabelOptions(val) {
            if (val) {
                const result = val.map(item => {
                    const children =
                        item.children?.length &&
                        item.children.map(every => {
                            return {
                                id: every?.id,
                                labelGroupId: every?.labelGroupId,
                                labelName: every?.labelName,
                                parentId: every?.parentId,
                                properties: every?.properties,
                            };
                        });
                    return {
                        ...item,
                        children: children,
                    };
                });
                result.unshift({
                    children: [],
                    groupName: '无',
                    groupType: 0,
                    id: '100000',
                    labelList: [],
                    labelName: '无',
                });
                this.columns.labelId.options.options = result;
            }
        },
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
        cloumnsList(val) {
            if (val) {
                this.columns.categoryId.options.options = val;
            }
        },
        levelList(val) {
            if (val) {
                this.columns.contentLevel.options.options = val;
            }
        },
    },
    methods: {
        changeFn(e) {
            console.log(e, 'changeFn');
            if (e.row.key == 'time') {
                this.result.approvalStartTime = e.value && e.value[0] + ' 00:00:00';
                this.result.approvalEndTime = e.value && e.value[1] + ' 23:59:59';
            }
            if (e.row.key == 'createTime') {
                this.result.createStartTime = e.value && e.value[0] + ' 00:00:00';
                this.result.createEndTime = e.value && e.value[1] + ' 23:59:59';
            }
            if (e.row.key == 'updateTime') {
                this.result.updateStartTime = e.value && e.value[0] + ' 00:00:00';
                this.result.updateEndTime = e.value && e.value[1] + ' 23:59:59';
            }
            if (e.row.key == 'labelId') {
                this.result.labelIdsArr = this.disposeData(e.nodeData);
            }
            if (e.row.key == 'categoryId') {
                this.result.categoryIdArr = this.flDisposeData(e.nodeData);
            }
            this.result.page = 1;
            let params = {
                ...this.result,
            };
            delete params.time;
            delete params.createTime;
            delete params.updateTime;

            console.log(this.result, 'paramsparamsparamsparamsparams');

            this.$store.commit('mkt-content-article/setParams', params); //同步方法保存
            this.$parent.$refs.tabRef.getList();
        },

        flDisposeData(arr) {
            return arr.map(item => {
                return item.data.value;
            });
        },
        disposeData(arr) {
            return arr.map(item => {
                return item.data.id;
            });
        },
        clickFn(e, type) {
            console.log(e, type, 999);
            if (type == 'clear') {
                this.result = {
                    approvalStartTime: '',
                    approvalEndTime: '',
                    createStartTime: '',
                    createEndTime: '',
                    updateStartTime: '',
                    updateEndTime: '',
                    keywords: '',
                    time: null,
                    createTime: null,
                    updateTime: null,
                    companyCode: '',
                    count: '',
                    categoryId: '',
                    labelId: '',
                    approvalStatus: '',
                    articleType: '',
                    labelIdsArr: [],
                    categoryIdArr: [],
                    noteType: '',
                    isWeight: '',
                    weightSortType: '',
                    contentLevel: '',
                };

                this.$bus.$emit('clearSearchArticle');
            }
            this.result.page = 1;
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-content-article/setParams', params); //同步方法保存
            this.$parent.$refs.tabRef.getList();
        },
    },
    created() {
        this.getColumn();
        this.getSearchTagList();
        this.getCompany();
        this.getLevelList();
        this.getNoteTypeList();
        let params = this.$route.query;
        if (params.topicId) {
            // this.result.keywords = params.topicName;
            this.result.topicId = params.topicId;
            if (params.approvalStatus) {
                this.result.approvalStatus = params.approvalStatus;
            }
        }
        this.$store.commit('mkt-content-article/setParams', this.result); //同步方法保存
    },
};
</script>
<style scoped>
/deep/.cascader .el-tag__close {
    display: none !important;
}
</style>
