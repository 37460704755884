<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            direction="rtl"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="closeDrawer"
        >
            <sc-form-v2
                name=""
                tablename="DESIGNERS_INFO"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <template v-slot:content>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">抓取的内容</div>
                        </template>
                        <div class="content-box" v-for="(item, index) in commentList" :key="index">
                            <div class="left-photo">
                                <img :src="item.profilePicture" alt="" />
                            </div>
                            <div class="right-box">
                                <div class="one">
                                    <div class="nickName">{{ item.nickName }}</div>
                                    <div class="time">{{ item.commentDate }}</div>
                                </div>
                                <div class="two">
                                    <div class="star">{{ '评分 ' + item.star }}</div>
                                    <div class="price">{{ item.averagePrice }}</div>
                                </div>
                                <div class="tags">{{ item.tags }}</div>
                                <div class="c-content">{{ item.content }}</div>
                                <div class="area">
                                    <span>{{ homeHouseInfoTag }}</span>
                                    <span>
                                        {{
                                            homeHouseInfoTag &&
                                                ugcCertificateTag &&
                                                '|' + ugcCertificateTag
                                        }}
                                    </span>
                                </div>
                                <div class="pictures" v-if="item.pictures && item.pictures.length">
                                    <img
                                        :src="pic"
                                        alt=""
                                        v-for="(pic, index) in item.pictures"
                                        :key="index"
                                        object-fit="contain"
                                    />
                                </div>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="close">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { updateDesignerLink, getDesignerLink } from '@/api/mkt/user';
import editor from '@/components/wang-editor.vue';

export default {
    props: {},
    components: { editor },
    data() {
        return {
            title: '链接抓取',
            drawer: false,
            dialogVisible: false,
            columns: {
                // userId: {
                //     name: '设计师ID',
                //     type: 'input',
                //     hidden: false,
                //     options: {
                //         placeholder: '',
                //         disabled: true,
                //     },
                //     rules: [{ required: true, message: '' }],
                // },
                name: {
                    name: '设计师名称',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                    rules: [{ required: true, message: '' }],
                },
                url: {
                    name: '链接地址',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入',
                    },
                },
                content: {
                    name: '评价',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入',
                    },
                },
            },
            result: {},
            commentList: [],
        };
    },
    mounted() {},
    methods: {
        open(row) {
            console.log(row, 'show-----row--------');
            this.drawer = true;
            if (row.id) {
                this.getInfo(row);
                this.result.name = row.name;
                this.result.accountId = row.id;
            }
            this.$forceUpdate();
        },
        getInfo(row) {
            let params = {
                accountId: row.id,
                page: 1,
                size: 100,
            };
            getDesignerLink(params).then(res => {
                console.log(res, 'res-----');
                this.commentList = res.data.children || [];
            });
        },
        close() {
            this.drawer = false;
            this.result = {
                name: '',
                url: '',
            };
            this.$forceUpdate();
        },
        closeDrawer() {
            this.close('init');
        },
        updateSpeciality: function(index, e) {
            console.log('----------updateContent', e, index);
            // e.target.outerText
        },
        addSpeciality() {
            this.result.specialityList.push('');
        },
        addDesignerSaidList() {
            this.result.designerSaidList.push('');
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('DESIGNERS_INFO', () => {
                //校验回调
                let params = {
                    accountId: this.result.accountId,
                    url: decodeURIComponent(this.result.url),
                };
                console.log('params-----', params);

                updateDesignerLink(params).then(res => {
                    console.log(res, 'res-----');
                    if (res.isSuccess) {
                        this.$message.success('抓取评价成功了，请稍后查看');
                        this.getInfo({
                            id: this.result.accountId,
                        });
                    }
                    // this.close();
                    // this.$emit('handleClose');
                });
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'orderSort') {
                this.result.orderSort = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.orderSort);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    text-align: right;
    margin: 20px 30px 20px 0;
}
.content-box {
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    line-height: 1.5em;
    display: flex;
    .right-box {
        padding-left: 20px;
        .one {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            .nickname {
                color: #4e6b80;
            }
            .time {
                color: #999;
            }
        }
        .two {
            color: #333;
            display: flex;
            padding-bottom: 6px;

            .price {
                padding-left: 20px;
            }
        }
        .tags {
            padding-bottom: 6px;

            color: #999;
        }
        .c-content {
            color: #333;
            padding-bottom: 6px;
        }
        .area {
            color: #999;
            padding-bottom: 6px;
        }
        .pictures {
            img {
                width: 110px;
                height: 110px;
                margin-right: 5px;
            }
        }
    }
}
</style>
