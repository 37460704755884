export const columns2 = [
    {
        result: {},
        columns: {
            style: {
                name: '风格',
                type: 'select',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '风格',
                    options: [],
                    clearable: true,
                    props: {
                        label: 'labelName',
                        value: 'labelId',
                    },
                },
            },
            colorScheme: {
                name: '色系',
                type: 'select',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '色系',
                    options: [],
                    clearable: true,
                    props: {
                        label: 'labelName',
                        value: 'labelId',
                    },
                },
            },
            houseType: {
                name: '房屋类型',
                type: 'select',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '房屋类型',
                    options: [],
                    clearable: true,
                    props: {
                        label: 'sysLabel',
                        value: 'dictValue',
                    },
                },
            },
            area: {
                name: '面积',
                type: 'select',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '面积',
                    clearable: true,
                    options: [
                        { labelName: '0-300㎡', labelId: '0-300' },
                        { labelName: '301-500㎡', labelId: '301-500' },
                        { labelName: '501-800㎡', labelId: '501-800' },
                        { labelName: '801-1000㎡', labelId: '801-1000' },
                        { labelName: '1000㎡以上', labelId: '1000-' },
                    ],
                    props: {
                        label: 'labelName',
                        value: 'labelId',
                    },
                },
            },
            label: {
                name: '标签',
                type: 'cascader',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '标签',
                    options: [],
                    props: {
                        label: 'labelName',
                        value: 'labelId',
                        multiple: true,
                        emitPath: false,
                    },
                    clearable: true,
                    allLevels: true,
                    collapseTags: true,
                },
            },
            ownerDemand: {
                name: '业主需求',
                type: 'cascader',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '业主需求',
                    options: [],
                    props: {
                        label: 'sysLabel',
                        value: 'dictValue',
                        multiple: true,
                        emitPath: false,
                    },
                    clearable: true,
                    allLevels: true,
                    collapseTags: true,
                },
            },
            designHighlights: {
                name: '设计亮点',
                type: 'cascader',
                options: {
                    defaultValue: '',
                    defaultValueBase: '',
                    placeholder: '设计亮点',
                    options: [],
                    props: {
                        label: 'sysLabel',
                        value: 'dictValue',
                        multiple: true,
                        emitPath: false,
                    },
                    clearable: true,
                    allLevels: true,
                    collapseTags: true,
                },
            },
            text: {
                name: '',
                type: 'text',
                options: {},
            },
        },
    },
];
