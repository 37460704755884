<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="false"
            :destroy-on-close="true"
            :close-on-press-escape="false"
        >
            <sc-form-v2
                v-if="formCtl"
                name=""
                tablename="INSERT"
                :columnsNum="2"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="insertForm"
                :LS="{ 'min-width': '60px' }"
                :disabled="type == 'show' ? true : false"
            >
                <template v-slot:vacancy>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <!-- <template slot="label">
                            <div class="labelName">案例</div>
                        </template> -->
                        <div
                            v-if="result.contentType !== 7 && result.contentType !== 8"
                            style="display: flex;justify-content: space-between;padding-right: 20px;"
                        >
                            <!-- <el-input
                                style="width: 80%;"
                                v-model="result.contentTitle"
                                placeholder="请输入案例标题"
                                maxlength="30"
                                show-word-limit
                                size="small"
                            ></el-input> -->
                            <el-button
                                style=""
                                size="mini"
                                type="primary"
                                @click="onOpenContent"
                                v-if="type == 'show' ? false : true"
                            >
                                选择内容
                            </el-button>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:contentCover>
                    <el-descriptions-item span="2">
                        <div>
                            <div style="margin-bottom: 10px;">
                                <span style="color: red;vertical-align: -2px;">*</span>
                                内容封面
                            </div>
                            <UploadNew
                                class="upload"
                                ref="uploadbox"
                                :limitNum="1"
                                :width="90"
                                :height="90"
                                :accept="['jpg', 'gif', 'jpeg', 'png']"
                                :fileType="'image'"
                                :showImgList="imgList"
                                tip="建议上传 690px * 326px 尺寸图片"
                                @uploadImgList="uploadImgList"
                                :delShowCtl="type == 'show' ? false : true"
                            ></UploadNew>
                            <el-button @click="imgSelectClick" size="small" style="margin-top:4px;">
                                从已有图片选择
                            </el-button>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:sendDate>
                    <el-descriptions-item span="2">
                        <div style="margin-bottom: 10px;">
                            发送时间 :
                            <br />
                            <!-- 定时发送限定在整点发送，例：11:35的消息会在11:00发送,请选择整点时间 -->
                        </div>
                        <el-date-picker
                            v-model="result.sendDate"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            placeholder="选择日期时间"
                            size="small"
                            :disabled="result.sendMode == 1"
                            :picker-options="pickerOptions"
                            @change="timeChange"
                            popper-class="new-add-self-time"
                        ></el-date-picker>
                    </el-descriptions-item>
                </template>
                <template v-slot:sendCrowd>
                    <el-descriptions-item span="2">
                        <div style="margin-bottom: 10px;font-size: 16px;">
                            发送人群
                            <el-radio
                                v-model="crowdVal"
                                :label="1"
                                size="large"
                                @change="radioChange('crowdVal')"
                            >
                                {{ '' }}
                            </el-radio>
                        </div>
                        <div style="padding: 0px 20px 0px 10px;">
                            <el-row :gutter="15">
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">角色</div>
                                    <el-select
                                        key="roleVo"
                                        style="width: 100%;"
                                        v-model="result.roleVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        multiple
                                        collapse-tags
                                        @change="onSendMode"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.roleVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.dictValue"
                                        />
                                    </el-select>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">使用工具</div>
                                    <el-select
                                        key="toolsVo"
                                        v-model="result.toolsVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        multiple
                                        collapse-tags
                                        @change="onSendMode($event, 'tool')"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.toolsVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.dictValue"
                                            :disabled="item.disabled"
                                        />
                                    </el-select>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">预约咨询</div>
                                    <el-select
                                        key="reservationVo"
                                        v-model="result.reservationVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        @change="onSendMode"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.reservationVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.dictValue"
                                        />
                                    </el-select>
                                </el-col>
                            </el-row>
                            <el-row :gutter="15" style="margin-top: 10px">
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">终端</div>
                                    <el-select
                                        key="terminalVo"
                                        v-model="result.terminalVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        multiple
                                        collapse-tags
                                        @change="onSendMode"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.terminalVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.dictValue"
                                        />
                                    </el-select>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">是否首次</div>
                                    <el-select
                                        key="isFirstVo"
                                        v-model="result.isFirstVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        @change="onSendMode"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.isFirstVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.dictValue"
                                        />
                                    </el-select>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">地域</div>
                                    <el-select
                                        key="ipRegionVo"
                                        v-model="result.ipRegionVo"
                                        placeholder="请选择"
                                        size="small"
                                        clearable
                                        multiple
                                        collapse-tags
                                        @change="onSendMode"
                                        :disabled="crowdVal == 1 ? false : true"
                                    >
                                        <el-option
                                            v-for="item in sendCrowdOptions.ipRegionVo"
                                            :key="item.dictValue"
                                            :label="item.sysLabel"
                                            :value="item.sysLabel"
                                        />
                                    </el-select>
                                </el-col>
                            </el-row>
                            <el-row :gutter="15" style="margin-top: 10px">
                                <el-col :span="8">
                                    <div style="margin-bottom: 5px;">访问时间范围</div>
                                    <el-date-picker
                                        :disabled="crowdVal == 1 ? false : true"
                                        v-model="result.rangeDate"
                                        type="daterange"
                                        range-separator="To"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        size="small"
                                        @change="dataChange"
                                    />
                                </el-col>
                            </el-row>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:sendNumber>
                    <el-descriptions-item span="2">
                        <div style="width: 100%;text-align: center;">
                            发送人数：{{ personData.total }}
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:appointor>
                    <el-descriptions-item span="1">
                        <div>
                            指定人
                            <el-radio
                                v-model="specifyVal"
                                :label="1"
                                size="large"
                                @change="radioChange('specifyVal')"
                            >
                                {{ '' }}
                            </el-radio>
                        </div>
                        <el-select
                            :disabled="specifyVal == 1 ? false : true"
                            v-model="result.appointor"
                            multiple
                            collapse-tags
                            collapse-tags-tooltip
                            placeholder="指定人"
                            style="width: 100%;margin-top: 10px"
                            size="small"
                            @change="selectChange"
                            filterable
                        >
                            <el-option
                                v-for="item in columns.appointor.options.options"
                                :key="item.accountId"
                                :label="item.showContent"
                                :value="item.accountId"
                            />
                        </el-select>
                    </el-descriptions-item>
                </template>
                <template v-slot:describe>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">描述：</div>
                        </template>
                        <el-input
                            v-model="result.describe"
                            size="small"
                            placeholder="请输入描述"
                            maxlength="100"
                            show-word-limit
                            style="width:100%;box-size: border-box;"
                            class="customer-input"
                        ></el-input>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>

            <div class="dialog-footer" style="margin-bottom: 20px;">
                <el-button size="mini" type="primary" @click="preview" style="margin-right: 30px;">
                    预览
                </el-button>
                <el-button size="mini" @click="handleClose" v-if="type == 'show' ? false : true">
                    取消
                </el-button>
                <el-button
                    size="mini"
                    type="primary"
                    @click="handleSubmit"
                    v-if="type == 'show' ? false : true"
                >
                    确定
                </el-button>
            </div>
        </el-drawer>

        <content-dialog
            v-if="visibleContent"
            :visible="visibleContent"
            :id="1"
            :listType="this.result.contentType"
            :read="false"
            @closeDiag="closeContentDialog"
            @submit="submitContent"
        ></content-dialog>

        <ImgSelect ref="imgSelect" @innerDrawerSubmit="innerDrawerSubmit"></ImgSelect>
    </div>
</template>
<script>
import UploadNew from '@/components/upload-new';
import ossState from '@/utils/ossClient.js';
import contentDialog from '@/page/mkt/components/content-dialog.vue';
import ImgSelect from './img-select.vue';
import { columns } from './columns.js';

import {
    dossierList,
    getDossierUserList,
    saveUserMessage,
    getDossierUserListAppoint,
    getUserMessageById,
} from '@/api/mkt/message';

export default {
    components: { UploadNew, contentDialog, ImgSelect },
    data() {
        return {
            drawer: false,
            result: {
                contentType: '',
                contentId: '',
                contentTitle: '',
                sendMode: 1,
                regionVo: [],
                isFirstVo: '',
                reservationVo: '',
                dateStr: '',
                roleVo: [],
            },
            accept: [
                // 'mp4'
            ],
            columns,
            sendCrowdOptions: {
                reservationVo: [],
                roleVo: [],
                toolsVo: [],
                ipRegionVo: [],
                terminalVo: [],
                isFirstVo: [
                    {
                        dictValue: '0',
                        sysLabel: '是',
                    },
                    { dictValue: '1', sysLabel: '否' },
                ],
            },
            visibleContent: false,
            sendModeType: true,
            personData: {
                total: 0,
                accountIdList: [],
            },
            imgList: [],
            title: '',
            type: '',
            id: '',
            sendType: 'crowd', //crowd specify
            crowdVal: 1,
            specifyVal: null,
            formCtl: true,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            selectImgList: [],
        };
    },
    mounted() {
        ossState.getOssClient();
        dossierList().then(res => {
            if (res.data) {
                this.sendCrowdOptions.reservationVo = res.data.reservationVo; // 预约咨询
                this.sendCrowdOptions.roleVo = res.data.roleVo; // 角色
                this.sendCrowdOptions.toolsVo = res.data.toolsVo.map(item => {
                    return {
                        ...item,
                        disabled: false,
                    };
                }); // 工具
                this.sendCrowdOptions.terminalVo = res.data.terminalVo; // 终端
                this.sendCrowdOptions.ipRegionVo = res.data.ipRegionVo; // 地域
            }
        });
        getDossierUserListAppoint().then(res => {
            if (res.data) {
                this.columns.appointor.options.options = res.data;
            }
        });
    },
    methods: {
        timeChange(e) {
            this.result.sendDate = this.formatTime(e);
        },
        formatTime(date) {
            let _Date = new Date(date);
            let year = _Date.getFullYear();
            let month = (_Date.getMonth() + 1).toString().padStart(2, '0');
            let day = _Date
                .getDate()
                .toString()
                .padStart(2, '0');
            let hours = _Date
                .getHours()
                .toString()
                .padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:00`;
        },
        openDrawer(row, type, title) {
            this.title = title;
            this.type = type;
            this.drawer = true;

            if (type == 'edit' || type == 'show' || type == 'copy') {
                this.editEcho(row.id);
            } else {
                this.radioChange('crowdVal');
            }
        },
        handleClose() {
            this.$forceUpdate();
            this.result = {
                contentType: '',
                contentId: '',
                contentTitle: '',
                sendMode: 1,
                regionVo: [],
                roleVo: [],
                isFirstVo: '',
                reservationVo: '',
            };
            this.columns.contentId.type = 'select';
            this.columns.contentTitle.type = 'select';
            this.imgList = [];
            this.columns.appointor.options.disabled = true;
            this.crowdVal = 0;
            this.specifyVal = 1;
            this.drawer = false;
        },
        editEcho(id) {
            getUserMessageById({ id }).then(res => {
                if (res.data) {
                    let userIdList = [];
                    let str = res.data.userIdList;
                    let arr = str.replace(/\s+/g, '');
                    let newStr = arr.substring(arr.indexOf('[') + 1);
                    let newStr2 = newStr.substring(0, newStr.indexOf(']'));
                    userIdList = newStr2.split(',');

                    this.result = {
                        contentType: res.data.contentType,
                        contentId: res.data.contentId,
                        contentTitle: res.data.contentTitle,
                        title: res.data.title,
                        describe: res.data.messageDescribe,
                        sendMode: res.data.sendType,
                        ...(res.data.sendType == 2 && { sendDate: res.data.sendingTime }),
                        ...(res.data.populationType == 1 && {
                            ipRegionVo: res.data.region,
                            roleVo: res.data.role,
                            reservationVo: res.data.appointment,
                            toolsVo: res.data.tool,
                            terminalVo: res.data.device,
                            isFirstVo: res.data.isfirst + '',
                            rangeDate: [
                                res.data.accessStartTime ? res.data.accessStartTime : '',
                                res.data.accessEndTime ? res.data.accessEndTime : '',
                            ],
                        }),
                        ...(res.data.populationType == 2 && {
                            appointor: userIdList,
                        }),
                        dateStr: res.data.dateStr,
                    };

                    this.imgList.push({
                        url: res.data.cover,
                    });

                    res.data.populationType == 1
                        ? this.radioChange('crowdVal')
                        : this.radioChange('specifyVal');

                    this.id = res.data.id;
                    // 编辑的时候, 发送人数展示详情的number字段
                    this.personData.total = res.data.number;
                    // debugger;
                }
            });
        },
        changeFn(row) {
            if (row.row.key == 'contentType') {
                if (row.value == 7 || row.value == 8) {
                    this.columns.contentId.type = 'hidden';
                    this.columns.contentTitle.type = 'hidden';
                } else {
                    this.columns.contentId.type = 'select';
                    this.columns.contentTitle.type = 'select';
                }
            }

            if (row.row.key == 'sendMode') {
                if (row.value == 1) {
                    this.columns.sendDate.options.disabled = true;
                    this.result.sendDate = '';
                } else {
                    this.columns.sendDate.options.disabled = false;
                }
            }

            if (row.row.key == 'appointor') {
                this.personData.total = row.value.length;
                this.personData.accountIdList = row.value;
            }
            if (row.row.key == 'contentType') {
                this.result.contentId = '';
                this.result.contentTitle = '';
                this.imgList = [];
            }
        },

        onSendMode(event, type) {
            if (type == 'tool') {
                console.log('------------------11111111111111', this.result.toolsVo);
                if (event.length && this.result.toolsVo[this.result.toolsVo.length - 1] == 0) {
                    this.result.toolsVo = [0];
                } else {
                    this.result.toolsVo = this.result.toolsVo.filter(d => d != 0);
                }
            }
            let data = {
                ...(this.result.reservationVo && {
                    appointment: this.result.reservationVo ? this.result.reservationVo : null,
                }),
                ...(this.result.roleVo && {
                    role: this.result.roleVo.length > 0 ? this.result.roleVo : null,
                }),
                ...(this.result.toolsVo && {
                    tool: this.result.toolsVo.length > 0 ? this.result.toolsVo : null,
                }),
                ...(this.result.terminalVo && {
                    device: this.result.terminalVo.length > 0 ? this.result.terminalVo : null,
                }),
                ...(this.result.ipRegionVo && {
                    ipRegion: this.result.ipRegionVo.length > 0 ? this.result.ipRegionVo : null,
                }),
                ...(this.result.isFirstVo && {
                    isFirst: this.result.isFirstVo ? this.result.isFirstVo : null,
                }),
                ...(this.result.startTime && {
                    bDate: this.result.startTime ? this.result.startTime : null,
                }),
                ...(this.result.endTime && {
                    eDate: this.result.endTime ? this.result.endTime : null,
                }),
            };
            this.$forceUpdate(); //处理角色不更新bug
            this.dossierUserList(data);
        },
        dataChange() {
            if (this.result.rangeDate) {
                let start = new Date(this.result.rangeDate[0]);
                let end = new Date(this.result.rangeDate[1]);
                let startTime = this.dataConversion(start);
                let endTime = this.dataConversion(end, 'end');
                this.result.startTime = startTime;
                this.result.endTime = endTime;
            } else {
                this.result.startTime = '';
                this.result.endTime = '';
            }
            this.onSendMode();
        },
        dataConversion(time, type) {
            let year = time.getFullYear();
            let month = (time.getMonth() + 1).toString().padStart(2, '0'); // 月份加1并格式化为两位数
            let date = time
                .getDate()
                .toString()
                .padStart(2, '0'); // 日期格式化为两位数
            let hours = time
                .getHours()
                .toString()
                .padStart(2, '0'); // 小时格式化为两位数
            // 保留分钟和秒为 '00':'00' 如果有需要，可以根据时间对象的实际值来格式化
            let timer = '';
            if (type == 'end') {
                timer = `${year}-${month}-${date} 23:59:59`;
            } else {
                timer = `${year}-${month}-${date} 00:00:00`;
            }

            return timer;
        },
        dossierUserList(data) {
            getDossierUserList(data).then(res => {
                if (res.data) {
                    this.personData.total = res.data.total;
                    this.personData.accountIdList = res.data.accountIdList;
                }
            });
        },
        clickFn() {},

        handleSubmit() {
            if (this.result.contentType == 7) {
                this.result.contentId = 7;
                this.result.contentTitle = '风格测试';
            }

            if (this.result.contentType == 8) {
                this.result.contentId = 8;
                this.result.contentTitle = '智能测试';
            }

            let check = false;
            this.$refs.insertForm.validateAll('INSERT', res => {
                if (res) {
                    check = true;
                }
            });
            if (!check) return;
            if (!this.imgList.length) {
                this.$message.warning('请上传内容封面');
                return;
            }
            let data = {
                contentType: this.result.contentType,
                contentId: this.result.contentId,
                contentTitle: this.result.contentTitle,
                messageDescribe: this.result.describe,
                title: this.result.title,
                cover: this.imgList[0]?.url || '',
                sendType: this.result.sendMode,
                sendingTime: this.result.sendDate,
                role: this.result.roleVo,
                tool: this.result.toolsVo,
                appointment: this.result.reservationVo,
                device: this.result.terminalVo,
                isfirst: this.result.isFirstVo,
                region: this.result.ipRegionVo,
                userIdList: this.personData.accountIdList,
                number: this.personData.total,
                accessStartTime: this.result.startTime,
                accessEndTime: this.result.endTime,
                ...(this.type == 'edit' && {
                    id: this.id,
                }),
            };

            if (this.crowdVal == 1) data.populationType = 1;
            if (this.specifyVal == 1) data.populationType = 2;

            saveUserMessage(data).then(res => {
                if (res.isSuccess) {
                    let message = '新增成功';
                    if (this.type == 'edit') {
                        message = '修改成功';
                    }
                    this.$message.success(message);
                    this.$emit('pageReload');
                }
            });
        },
        onOpenContent() {
            this.visibleContent = true;
        },
        closeContentDialog(type) {
            this.visibleContent = false;
        },
        submitContent(e) {
            this.result.contentTitle = e.contentTitle;
            this.result.contentId = e.contentId;
            let imgArr = [];
            if (e.fileList && e.fileList.length > 0) {
                this.selectImgList = e.fileList;
                imgArr.push(e.fileList[0]);
                this.imgList = imgArr;
            } else {
                this.selectImgList = [{ url: e.cover }];
                this.imgList = [{ url: e.cover }];
            }

            // this.$refs.insertForm.validateAll('INSERT', res => {});
            this.closeContentDialog();
        },
        uploadImgList() {},
        radioChange(e) {
            if (e == 'crowdVal') {
                this.sendModeType = false;
                this.specifyVal = 0;
                this.crowdVal = 1;
                this.onSendMode();
            } else {
                this.sendModeType = true;
                this.specifyVal = 1;
                this.crowdVal = 0;
                this.result.roleVo = [];
                this.result.toolsVo = [];
                this.result.reservationVo = '';
                this.result.terminalVo = [];
                this.result.isFirstVo = '';
                this.result.ipRegionVo = [];
                this.result.rangeDate = [];
                this.personData.total = this.result.appointor?.length || 0;
                this.personData.accountIdList = this.result.appointor;
            }
        },
        selectChange() {
            if (this.result.appointor) {
                this.personData.total = this.result.appointor.length;
                this.personData.accountIdList = this.result.appointor;
            }
        },
        preview() {
            if (!this.result.contentType) {
                this.$message.warning('请先选择推送内容');
                return;
            }

            if (this.result.contentType == 7 || this.result.contentType == 8) {
                if (!this.result.title || !this.imgList[0]?.url) {
                    this.$message.warning('请填写消息标题及封面');
                    return;
                }
            } else {
                if (!this.result.contentTitle || !this.imgList[0]?.url) {
                    this.$message.warning('请填写内容标题及封面');
                    return;
                }
            }

            let row = {
                contentType: this.result.contentType,
                contentTitle: this.result.contentTitle,
                cover: this.imgList[0].url,
                title: this.result.title,
                describe: this.result.describe,
                dateStr: this.result.dateStr,
            };

            this.$emit('previewClick', row);
        },
        imgSelectClick() {
            this.$refs.imgSelect.openDrawer(this.selectImgList);
        },
        innerDrawerSubmit(imgList) {
            if (this.imgList.length > 0) {
                this.$message.warning('最多只能选择一张图片');
                return;
            }
            imgList.forEach(item => {
                this.imgList.push({
                    url: item.url,
                });
            });
        },
    },
};
</script>
<style scoped lang="less">
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
/deep/ .customer-input {
    .el-input__inner {
        padding-right: 60px;
    }
}
</style>

<style>
/deep/ .el-button--text {
    display: none;
}
</style>
