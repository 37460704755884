<template>
    <div class="add-tag-container">
        <el-drawer
            title="编辑情绪版图"
            :visible.sync="dialogVisible"
            direction="rtl"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="close"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                :uploadConfig="uploadConfig"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import mixins from '@/mixins';
import { uploadConfigData } from '@/config.js';
import { saveStyleInfo } from '@/api/mkt/style-test';
export default {
    props: {},
    mixins: [mixins],
    data() {
        return {
            uploadConfig: {},
            dialogVisible: false,
            columns: {
                styleName: {
                    name: '风格',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },
                keyword: {
                    name: '关键词',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },
                introduction: {
                    name: '正文',
                    type: 'textarea',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入' }],
                },
                url: {
                    name: '情绪版图片',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
                backUrl: {
                    name: '背景图',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
            },
            fileList: [],
            result: {
                backUrl: [],
                styleName: '',
                keyword: '',
                introduction: '',
                url: [],
            },
        };
    },
    mounted() {
        this.getOssConfig();
    },
    methods: {
        open(row) {
            this.dialogVisible = true;
            this.result = { ...row };
            this.result.url = [{ url: row.url }];
            this.result.backUrl = [{ url: row.backUrl }];
            this.$nextTick(() => {
                uploadConfigData.oss.accessKeyId = this.ossConfig.stsKeyId;
                uploadConfigData.oss.accessKeySecret = this.ossConfig.stsSecret;
                uploadConfigData.oss.stsToken = this.ossConfig.token;
                this.uploadConfig = uploadConfigData;
            });
        },
        close() {
            this.dialogVisible = false;
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, 88888);
                let params = {
                    ...this.result,
                    url: this.result.url[0].url,
                    backUrl: this.result.backUrl[0].url,
                };
                saveStyleInfo(params).then(res => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.close();
                        this.$emit('refresh');
                    }
                });
            });
        },

        changeFn(data) {
            console.log(data, 'edit');
        },
        clickFn(data) {
            console.log(data, 'edit');
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
