<template>
    <div class="mkt">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        >
            <template v-slot:searchName>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">列表搜索：</div>
                    </template>
                    <div style="text-align: left">
                        <el-select
                            size="small"
                            placeholder="用户姓名"
                            v-model="result.searchType"
                            style="width: 26%"
                            clearable
                        >
                            <template>
                                <el-option key="name" label="用户姓名" value="1"></el-option>
                                <el-option key="nickName" label="昵称" value="2"></el-option>
                                <el-option key="account" label="BPM账号" value="3"></el-option>
                                <el-option key="phone" label="手机号" value="4"></el-option>
                            </template>
                        </el-select>
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="result.searchName"
                            size="small"
                            style="width: 70%; margin-left: 10px"
                            clearable
                        ></el-input>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
        <div class="content">
            <sc-table-v2
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeight"
                :read-table="true"
                :load="loading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <template v-slot:name>
                    <el-table-column label="姓名" width="80">
                        <template slot-scope="scope">
                            <div class="">
                                <el-image
                                    v-if="scope.row.imgUrl"
                                    :src="scope.row.imgUrl"
                                    alt=""
                                    style="width:50px;height:50px;margin-right:5px;flex-shrink:0;"
                                />

                                <div style="flex-shrink:0;">
                                    {{ scope.row.name }}
                                    <el-tag size="mini" v-if="scope.row.isSuperior == 0">
                                        领导
                                    </el-tag>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="160">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                            <span>&nbsp;</span>
                            <sc-qrcode-popover :id="scope.row.id" type="user"></sc-qrcode-popover>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
    </div>
</template>

<script>
import editDialog from './components/detail.vue';
import mixins from '@/mixins';
import { getRolesList } from '@/api/mkt/user.js';
import { getList } from '@/api/mkt/staff.js';
export default {
    name: 'staff-index',
    mixins: [mixins],
    components: { editDialog },
    data() {
        return {
            loading: false,
            moreDown: false,
            tableHeight: window.innerHeight - 185,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '姓名', key: 'name', width: 90 },
                { name: '昵称', key: 'nickName' },
                { name: '手机号', key: 'mobile', width: 120 },
                { name: 'BPM账号', key: 'account', width: 100 },
                { name: '岗位', key: 'job', width: 130 },
                { name: '角色', key: 'role', width: 130, showOverflowTooltip: true },
                { name: '贡献内容数', key: 'contributeNumber' },
                { name: '分享内容次数', key: 'shareNumber' },
                { name: '分公司', key: 'companyName', width: 160, showOverflowTooltip: true },
                { name: '部门', key: 'detpName', width: 120 },
                { name: '最近访问时间', key: 'lastLoginTime', width: 160 },
                { name: '首次登录时间', key: 'firstLoginTime', width: 160 },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '160',
                },
            ],
            list: [],
            columns: {
                searchName: {
                    name: '插槽占位',
                    type: 'text',
                    spanCol: 2, //如果插槽占位，需要跟插槽中span一致
                },

                role: {
                    name: '角色',
                    type: 'select',
                    options: {
                        defaultValue: [],
                        clearable: true,
                        multiple: false,
                        placeholder: '角色',
                        options: [],
                        props: {
                            value: 'id',
                            label: 'label',
                        },
                    },
                    spanCol: 2,
                },
                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        clearable: true,
                        placeholder: '请输入分公司',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 2,
                },
                firstLoginTime: {
                    name: '首次登录时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入首次登录时间',
                    },
                    spanCol: 2,
                },
                lastLoginTime: {
                    name: '最后访问时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入最后访问时间',
                    },
                    spanCol: 2,
                },
                k2: {},
            },
            result: {
                BDate: '',
                EDate: '',
                zeDate: '',
                zbDate: '',
                searchType: '1',
            },
            tableAddConfig: {},
            page: {
                page: 1,
                size: 30,
                count: 0,
                index: 1,
            },

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    watch: {
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
    },
    created() {
        this.getRolesList();
        this.getCompany();
        this.init();
    },
    methods: {
        handleClickMore(data) {
            console.log(data);
            if (data) {
                this.tableHeight = window.innerHeight - 317;
            } else {
                this.tableHeight = window.innerHeight - 185;
            }
        },
        handleClose() {
            this.init();
        },
        init() {
            this.loading = true;
            let params = {
                BDate: this.result.BDate,
                EDate: this.result.EDate,
                companyCode: this.result.companyCode,
                mobile: this.result.mobile,
                role: this.result.role,
                searchName: this.result.searchName,
                searchType: this.result.searchType || 1,
                page: this.page.page,
                size: this.page.size,
                zeDate: this.result.zeDate,
                zbDate: this.result.zbDate,
            };
            getList(params).then(res => {
                this.loading = false;
                this.list = res.data.children;
                this.page.count = res.data.total;
            });
        },
        onEdit(row) {
            this.$refs.editDialog.open(this.roleList, row);
        },
        async getRolesList() {
            const res = await getRolesList();
            if (res?.isSuccess) {
                for (let i in this.columns) {
                    if (i == 'role') {
                        this.columns[i].options.options = res.data;
                        this.roleList = res.data;
                    }
                }
            }
        },
        rowClick() {},
        dbClick() {},
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
        pageChange(val) {
            this.page.page = val;
            this.page.index = val;
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            if (row.row.key == 'firstLoginTime') {
                if (row.value) {
                    this.result.BDate = row.value[0] + ' 00:00:00';
                    this.result.EDate = row.value[1] + ' 23:59:59';
                } else {
                    delete this.result.BDate;
                    delete this.result.EDate;
                }
            }
            if (row.row.key == 'lastLoginTime') {
                if (row.value) {
                    this.result.zbDate = row.value[0] + ' 00:00:00';
                    this.result.zeDate = row.value[1] + ' 23:59:59';
                } else {
                    delete this.result.zbDate;
                    delete this.result.zeDate;
                }
            }
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.result = {
                    searchType: '1',
                };
                this.page.page = 1;
                this.page.size = 30;
                this.page.index = 1;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
    align-items: center;
}
</style>
