<template>
    <div class="tabs" ref="table">
        <el-tabs v-model="tabActive" @tab-click="tabClick">
            <el-tab-pane label="全部案例" name="0"></el-tab-pane>
            <el-tab-pane label="我的草稿" name="2"></el-tab-pane>
            <!-- <el-tab-pane label="审核中" name="2"></el-tab-pane> -->
            <!-- <el-tab-pane label="审核通过" name="3"></el-tab-pane> -->
            <!-- <el-tab-pane label="审核退回" name="4"></el-tab-pane> -->
            <el-tab-pane label="审核设置" name="999" disabled>
                <span slot="label">
                    <span class="tabStyle" @click="setbtn">
                        <i class="el-icon-s-tools"></i>
                    </span>
                </span>
            </el-tab-pane>
        </el-tabs>
        <sc-table-v2
            title=""
            :head="tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :table-height="tableHeightCalc"
            :read-table="true"
            :load="loading"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @selection-change="selectionChange"
            @sortChange="sortChange"
            :default-sort="sort"
        >
            <!-- //插槽例子，指定某一列用插槽写入 -->
            <template v-slot:atlas>
                <el-table-column label="图集">
                    <template slot-scope="scope">
                        <el-image
                            :src="scope.row.cover"
                            fit="contain"
                            style="width: 50px; height: 50px"
                            :preview-src-list="[scope.row.cover]"
                        ></el-image>
                    </template>
                </el-table-column>
            </template>

            <template v-slot:houseLayout>
                <el-table-column label="房屋类型">
                    <template slot-scope="scope">
                        {{ scope.row.houseLayout | parseHouseList }}
                    </template>
                </el-table-column>
            </template>
            <template v-slot:approvalStatus>
                <el-table-column label="审核状态">
                    <template slot-scope="scope">
                        {{ scope.row.approvalStatus | parseApproval }}
                    </template>
                </el-table-column>
            </template>
            <template v-slot:createName>
                <el-table-column label="创建人" width="140px">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.createName
                            }}{{ scope.row.account && '（' + scope.row.account + '）' }}
                        </div>
                        <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                            {{ scope.row.createUserJob }}
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleEdit(scope.row)" size="mini">
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleExhibit(scope.row)"
                            size="mini"
                            v-if="scope.row.approvalStatus == 1 && !isDesigner"
                        >
                            审核
                        </el-button>

                        <el-button
                            type="text"
                            @click="editArticle(scope.row)"
                            size="mini"
                            v-if="scope.row.approvalStatus == 3"
                        >
                            补充
                        </el-button>
                        <sc-qrcode-popover :id="scope.row.id" type="exhibit"></sc-qrcode-popover>
                        <el-button
                            type="text"
                            @click="handleRejectOrPass(scope.row)"
                            size="mini"
                            style="margin-left:10px;"
                            v-if="scope.row.approvalStatus == 3 || scope.row.approvalStatus == 4"
                        >
                            {{ scope.row.approvalStatus == 3 ? '驳回' : '通过' }}
                        </el-button>
                        <!-- <el-button
                            type="text"
                            @click="handleUpTop(scope.row)"
                            size="mini"
                            style="margin-left:10px;"
                            v-if="scope.row.approvalStatus == 3"
                        >
                            {{ scope.row.isTopping == 0 ? '置顶' : '取消置顶' }}
                        </el-button> -->
                        <el-button
                            type="text"
                            @click="hideExhibit(scope.row)"
                            size="mini"
                            style="margin-left:10px;"
                            v-if="scope.row.approvalStatus == 3"
                        >
                            {{ scope.row.isShow == '1' ? '隐藏' : '展示' }}
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <drawer-dialog ref="drawerDialog" @closeDrawer="closeDrawer"></drawer-dialog>
        <audit-settings
            :settingsShow="settingsShow"
            :workType="1"
            ref="settingsDialog"
            @onCloseSet="onCloseSet"
        ></audit-settings>
        <pass-dialog ref="passdialog" @closeDrawer="closeDrawer"></pass-dialog>
        <!-- 驳回备注弹窗 -->
        <dialog-remark ref="remark" @remarkBak="remarkBak"></dialog-remark>
    </div>
</template>

<script>
import mixins from '@/mixins';
import drawerDialog from './details.vue';
import auditSettings from '../article/audit-settings.vue';
import passDialog from './pass-dialog.vue';
import dialogRemark from '../article/dialog-remark.vue';
import qrcodeUpload from './components/qrcode-upload.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('mkt-content-exhibit');
import { approvalReject, sysTop, hideExhibit } from '@/api/mkt/exhibit';
export default {
    components: { drawerDialog, auditSettings, passDialog, dialogRemark, qrcodeUpload },
    mixins: [mixins],
    data() {
        return {
            sort: { prop: '', order: '' }, //ascending,descending  如果只指定了prop, 没有指定order, 则默认顺序是ascending
            localRow: {},
            tabActive: '0',
            tableHead: [
                {
                    name: 'ID',
                    key: 'id',
                    show: true,
                    width: '156px',
                },
                {
                    name: '案例名称', //表头名称
                    key: 'title', //数据列的key值
                    show: true, //pop-show隐藏表头字段用到，默认true
                    width: '100px',
                },
                { name: '图集', key: 'atlas', width: '80px' },
                // { name: '楼盘', key: 'realEstate', width: '180px' },
                {
                    name: '户型',
                    key: 'houseLayout',
                    width: '100px',
                },
                { name: '审核状态', key: 'approvalStatus' },
                // { name: '分类', key: 'categoryName', width: '160' },
                { name: '标签', key: 'labelName', width: '180px' },
                { name: '荣誉奖项', key: 'awards', width: '180px', showOverflowTooltip: true }, // 新增荣誉奖项列
                { name: '权重值', key: 'weightSort', sortable: true, width: '82px' },
                // { name: '运营标签', key: 'operationName' },
                { name: '访问量', key: 'pageView', width: '82px' },
                { name: '平均访问时长', key: 'duration', width: '122px' },
                { name: '点赞量', key: 'praise', width: '82px' },
                { name: '收藏量', key: 'favorite', width: '82px' },
                { name: '评论量', key: 'comment', width: '82px' },
                // { name: '退回原因', key: 'approvalRemarks', width: '160' },
                { name: '创建人', key: 'createName' },
                // { name: '分公司', key: 'companyName', width: '240px' },
                { name: '评级', key: 'contentLevelName' },
                { name: '最近提交时间', key: 'updateTime', width: '180px', sortable: true },
                { name: '最近审核时间', key: 'approvalTime', width: '180px', sortable: true },
                { name: '发布时间', key: 'createTime', width: '180px', sortable: true },
                { name: '操作', key: 'control' },
            ],
            tableList: [],
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHeight: window.screen.height - 465,
            tableHeightCalc: 520,
            innerHeight: 0,
            isSearchDown: false,
            settingsShow: false,
            localHouseList: [],
            isDesigner: null,
            sortObj: {
                weightSort: '',
                updateTime: '',
                approvalTime: '',
                createTime: '',
            },
            loading: false,
        };
    },
    computed: {
        ...mapState(['list', 'page', 'params']), //// 映射 this.list 为 store.state.list
        ...mapMutations(['setParams']),
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 170;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 170;
        },
    },
    filters: {
        parseApproval(val) {
            let str = '';
            switch (val) {
                case 0:
                    str = '草稿';
                    break;
                case 1:
                    str = '待审核';
                    break;
                case 2:
                    str = '审核中';
                    break;
                case 3:
                    str = '审批通过';
                    break;
                case 4:
                    str = '审核退回';
                    break;

                default:
                    break;
            }
            return str;
        },
        parseHouseList(val) {
            let obj = {
                1: '独栋别墅',
                2: '联排别墅',
                3: '平层/公寓',
                4: '跃层/叠拼',
                5: '双拼别墅',
                8: '其它',
            };
            return obj[val] || '--';
        },
    },

    created() {
        let login = this.$store.state.login;
        this.isDesigner = login.isDesigner == 1 ? true : false;
        this.$store.commit('mkt-content-exhibit/clear');
        this.getList();
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$bus.$on('clearSearchExhibit', data => {
            this.$refs.refTable.$refs['table-box'].clearSort();
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 170;
        });
    },
    methods: {
        // 置顶/取消置顶
        handleUpTop(row) {
            sysTop({
                id: row.id,
                isTopping: row.isTopping == 0 ? 1 : 0,
            }).then(res => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 驳回/通过
        handleRejectOrPass(row) {
            this.localRow = { ...row };
            if (row.approvalStatus == 3) {
                this.reject();
            } else {
                this.pass();
            }
        },
        reject() {
            this.$refs.remark.open();
        },
        remarkBak(data) {
            let params = {
                approvalStatus: 4, // 3通过 4 驳回
                approvalRemarks: data,
                worksId: this.localRow.id,
            };
            approvalReject(params).then(res => {
                if (res.errorCode == 200) {
                    this.getList();
                }
            });
        },

        pass() {
            this.$refs.passdialog.open(this.localRow, 0);
        },
        handleEdit(row) {
            this.$emit('openAddDrawer', row);
        },
        editArticle(row) {
            this.$refs.passdialog.open(row, 1);
        },
        setbtn() {
            this.settingsShow = true;
        },
        onCloseSet() {
            this.settingsShow = false;
        },
        async getList() {
            this.loading = true;
            await this.$store.dispatch('mkt-content-exhibit/list');
            this.loading = false;
        },

        closeDrawer(row) {
            // 关闭弹窗
            this.getList();
        },

        sortChange(e) {
            console.log(e);
            Object.keys(this.sortObj).forEach(key => {
                console.log(key, 'key-----');
                this.sortObj[key] = '';
            });
            let params = {};

            let key = e.prop == 'weightSort' ? 'weightSortType' : e.prop + 'Sort';
            if (e.order == 'ascending') {
                this.sortObj[key] = 3; // 升序
            } else if (e.order == 'descending') {
                this.sortObj[key] = 2; // 倒序
            } else {
                this.sortObj[key] = '';
            }

            params = this.sortObj;
            this.$store.commit('mkt-content-exhibit/setParams', params);
            this.getList();
        },
        tabClick(tab, event) {
            this.$store.commit('mkt-content-exhibit/clear');
            this.tabActive = tab.name;
            let params = {
                approvalStatus: this.tabActive == 0 ? '' : this.tabActive,
                page: 1,
                toggleDraft: null,
            };
            if (tab.name == 2) {
                params.toggleDraft = 1;
                params.approvalStatus = null;
            }
            this.$store.commit('mkt-content-exhibit/setParams', params);
            this.getList();
        },
        handleExhibit(row) {
            this.$refs.drawerDialog.openDrawer(row);
        },
        // 选中项回调方法
        changeFn(data) {},
        // 点击事件回调方法
        clickFn(data) {},
        pageChange(val) {
            // 页码修改
            let params = { page: val };
            this.$store.commit('mkt-content-exhibit/setParams', params);
            this.$store.dispatch('mkt-content-exhibit/list');
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            let params = { size: val };
            this.$store.commit('mkt-content-exhibit/setParams', params);
            this.$store.dispatch('mkt-content-exhibit/list');
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange(val) {
            console.log(val, 'val----');
        },

        getData(list) {},
        hideExhibit(row) {
            let status = row.isShow ? '隐藏' : '展示';
            let paramsShow = row.isShow ? '0' : '1'; //1 隐藏  0展示
            console.log(paramsShow, 11111111111111);
            this.$confirm(`此操作将${status}该作品, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                hideExhibit({ id: row.id, isShow: paramsShow }).then(res => {
                    if (res) {
                        this.$message.success('操作成功');
                        this.getList();
                    }
                });
            });
        },
    },
};
</script>

<style scoped>
/deep/.el-dialog__body {
    padding: 0 20px !important;
}
.tabs {
    margin: 10px 15px;
}
/deep/#tab-1 {
    color: #000;
}
.tabStyle {
    cursor: pointer;
}
</style>
