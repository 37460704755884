import { render, staticRenderFns } from "./sc-import-official-dialog.vue?vue&type=template&id=2bab2fb3&scoped=true"
import script from "./sc-import-official-dialog.vue?vue&type=script&lang=js"
export * from "./sc-import-official-dialog.vue?vue&type=script&lang=js"
import style0 from "./sc-import-official-dialog.vue?vue&type=style&index=0&id=2bab2fb3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bab2fb3",
  null
  
)

export default component.exports