import rq from '@/utils/https.js';
const $ajax = rq.requests;

export const getTabList = params =>
    $ajax({
        url: '/rec-group/list',
        method: 'get',
        params,
    });
// 新增、修改
export const saveTab = data =>
    $ajax({
        url: '/rec-group/edit-recommend-group',
        method: 'post',
        data,
    });
export const editRecommendName = data => {
    return $ajax({
        url: '/rec-group/edit-recommend-name',
        method: 'post',
        data,
    });
};
export const deleteTab = params =>
    $ajax({
        url: `/rec-group/del-recommend-group`,
        method: 'delete',
        params,
    });

export const getTabInfo = params =>
    $ajax({
        url: '/rec-group/info',
        method: 'get',
        params,
    });

// 新增关键词信息
export const insertKeyword = params =>
    $ajax({
        url: '/dict/v3/search/keywords/add',
        method: 'get',
        params,
    });

// 删除关键词信息
export const deleteKeywords = params =>
    $ajax({
        url: '/dict/v3/search/keywords/update/delete',
        method: 'get',
        params,
    });

// 修改关键词信息
export const updateKeywords = params =>
    $ajax({
        url: '/dict/v3/search/keywords/update/update',
        method: 'get',
        params,
    });

// 获取关键词列表信息
export const getSearchList = params =>
    $ajax({
        url: '/dict/v3/search/keywords/list',
        method: 'get',
        params,
    });
