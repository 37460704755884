import rq from '@/utils/https.js';
const $ajax = rq.requests;

export const getList = params =>
    $ajax({
        url: '/StyleFile/getStyleFileList',
        method: 'get',
        params,
    });
// 新增、修改
export const addStyle = data =>
    $ajax({
        url: '/StyleFile/saveOrUpdateStyleFile',
        method: 'post',
        data,
    });
export const deleteStyle = params =>
    $ajax({
        url: `/StyleFile/delStyleFile`,
        method: 'delete',
        params,
    });
//题目列表
export const getQuestionsList = params =>
    $ajax({
        url: '/StyleFile/getQuestionsList',
        method: 'get',
        params,
    });
// 树形分类、类别、色彩
export const getStyleFileTree = params =>
    $ajax({
        url: '/StyleFile/getStyleFileTree',
        method: 'get',
        params,
    });
// 单项分类、类别、色彩
export const getStyleFileAll = params =>
    $ajax({
        url: '/StyleFile/getStyleFileAll',
        method: 'get',
        params,
    });
// /StyleFile/get-info-list
export const getInfoList = params =>
    $ajax({
        url: '/StyleFile/get-info-list',
        method: 'get',
        params,
    });
// StyleFile/save-style-info
export const saveStyleInfo = data =>
    $ajax({
        url: '/StyleFile/save-style-info',
        method: 'post',
        data,
    });
