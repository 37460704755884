<template>
    <div class="mkt" ref="table">
        <!-- 广告位列表 -->
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
        <div style="padding-left:30px;">
            <el-button size="small" type="primary" @click="add">新增</el-button>
        </div>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="170">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleDetail(scope.row)" size="mini">
                                查看详情
                            </el-button>
                            <el-button type="text" @click="handleIsShelf(scope.row)" size="mini">
                                {{ scope.row.isShelf == 1 ? '下架' : '上架' }}
                            </el-button>
                            <el-button
                                type="text"
                                @click="edit(scope.row)"
                                size="mini"
                                v-if="scope.row.isShelf != 1"
                            >
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <detail-dialog ref="details" @handleClose="queryDataMixin"></detail-dialog>
    </div>
</template>

<script>
import detailDialog from './details.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import {
    getAdManagementList,
    isShelfHomeAd,
    isShelfCheck,
    getAdJumpTypeList,
} from '@/api/mkt/market.js';
export default {
    name: 'mkt-management-index',
    mixins: [mixins, getListMixin],
    components: { detailDialog },
    data() {
        return {
            tableHandle: getAdManagementList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '名称', key: 'adName' },
                {
                    name: '类型',
                    key: 'jumpType',
                    formatter: this.jumpTypeFormat,
                },
                {
                    name: '有效时间',
                    key: 'startTime',
                    width: 300,
                    formatter: val => {
                        return val.startTime + '-' + val.endTime;
                    },
                },
                {
                    name: '发送范围',
                    key: 'sendRange',
                    formatter: val => {
                        let obj = {
                            1: '全部平台',
                            2: 'APP',
                            3: '小程序',
                        };
                        return obj[val.sendRange];
                    },
                },
                {
                    name: '发送用户',
                    key: 'sendUserType',
                    formatter: val => {
                        let obj = {
                            1: '全部',
                            2: '设计师',
                            3: '普通用户',
                            4: '非设计师',
                        };
                        return obj[val.sendUserType];
                    },
                },
                {
                    name: '状态',
                    key: 'isShelf',
                    formatter: val => {
                        let obj = {
                            0: '待上架',
                            1: '上架中',
                            2: '已下架',
                        };
                        return obj[val.isShelf];
                    },
                },
                { name: '操作', key: 'control' },
            ],
            columns: {
                adName: {
                    name: '广告名称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入广告名称',
                        options: [],
                        props: {},
                    },
                    spanCol: 1,
                },
                jumpType: {
                    name: '类型',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择类型',
                        options: [
                            // { label: '金刚区', value: '1' },
                            // { label: '案例主页', value: '2' },
                            // { label: '设计师主页', value: '3' },
                            // { label: '表示H5（端内带Token）', value: '4' },
                            // { label: '表示H5（端外）', value: '5' },
                        ],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
                isShelf: {
                    name: '状态',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择状态',
                        options: [
                            { label: '待上架', value: '0' },
                            {
                                label: '上架中',
                                value: '1',
                            },
                            {
                                label: '已下架',
                                value: '2',
                            },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                mobile: '',
                city: '',
                province: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },

    created() {
        this.init();
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        });
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
    },

    methods: {
        async init() {
            await this.getAdJumpTypeListFn();
            this.queryDataMixin();
        },
        handleDetail(row) {
            this.$refs.details.open(row, '查看详情', this.columns.jumpType.options.options);
        },
        async getAdJumpTypeListFn() {
            const res = await getAdJumpTypeList();
            this.columns.jumpType.options.options = res.data;
        },
        handleIsShelf(row) {
            if (row.isShelf == 1) {
                this.$confirm('确定进行下架操作，下架后前端将不再显示此条广告信息', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        ...row,
                        isShelf: 2,
                    };
                    this.isShelfHomeAdFn(params);
                });
            } else {
                this.isShelfCheckFn(row);
            }
        },
        isShelfCheckFn(row) {
            isShelfCheck(row).then(res => {
                let title = '',
                    type;
                if (res.isSuccess && res.data) {
                    title = '确定将此广告进行上架操作吗？';
                    type = true;
                } else {
                    title = '当前终端、用户类型有正在上架的广告，是否继续上架此广告？';
                    type = false;
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: type ? '确定' : '继续上架',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        ...row,
                        isShelf: 1,
                    };
                    this.isShelfHomeAdFn(params);
                });
            });
        },
        isShelfHomeAdFn(params) {
            isShelfHomeAd(params).then(res => {
                if (res.isSuccess) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    });
                    this.queryDataMixin();
                }
            });
        },
        jumpTypeFormat(row) {
            let label = this.columns.jumpType.options.options.find(d => d.dictValue == row.jumpType)
                ?.label;
            return label;
        },
        add() {
            this.$refs.details.open({}, '新增', this.columns.jumpType.options.options);
        },
        edit(row) {
            this.$refs.details.open(row, '编辑', this.columns.jumpType.options.options);
        },
        changeFn() {
            this.queryDataMixin();
        },
        clickFn() {
            this.queryDataMixin();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
