<template>
    <div class="operation-main">
        <!-- <Top></Top> -->
        <div class="tool-bar-main">
            <el-button type="primary" size="mini" @click="backHandle">返回</el-button>
        </div>
        <div class="operation-mesa">
            <div class="operation-content-area">
                <operation-content
                    ref="moduleContentRef"
                    @deleteChange="modelCancelHandle"
                    @moduleClick="moduleClickHandle"
                />
            </div>
            <div class="operation-config-area">
                <content-model
                    ref="contentRef"
                    :data="attributeList"
                    :active-module-data="moduleData"
                    @cancel="modelCancelHandle"
                    @updateData="updateDataHandle"
                    @publishChange="publishChangeHandle"
                />
            </div>
        </div>
    </div>
</template>

<script>
import operationContent from './content';
import contentModel from './components/content-model';
import { editControlEcho } from '@/api/mkt/markets.js';

export default {
    name: 'operation-main',
    components: {
        operationContent,
        contentModel,
    },
    data() {
        return {
            attributeList: [],
            moduleData: {
                activeModuleId: '',
                activeModuleName: '',
                modulePublishStatus: 0,
            },
        };
    },
    mounted() {},
    methods: {
        async moduleClickHandle(row) {
            this.moduleData.activeModuleId = row.moduleId;
            this.moduleData.activeModuleName = row.moduleName;
            this.moduleData.modulePublishStatus = row.isPublish;
            this.$refs.contentRef.initImageHandle();
            const { data, isSuccess } = await editControlEcho(row.moduleId || '');
            if (isSuccess) {
                this.attributeList = data.attributeList || [];
            } else {
                this.attributeList = [];
            }
        },
        modelCancelHandle() {
            this.attributeList = [];
            this.$refs.moduleContentRef.initActiveStatus(0);
        },
        publishChangeHandle() {
            if (this.moduleData.modulePublishStatus) {
                this.moduleData.modulePublishStatus = 0;
            } else {
                this.moduleData.modulePublishStatus = 1;
            }
            this.$refs.moduleContentRef.initActiveStatus(1);
        },
        updateDataHandle(url, name) {
            this.$refs.moduleContentRef.updateSeeModelPicHandle(url, name);
        },
        // 返回方法
        async backHandle() {
            this.$router.push('/mkt/om/pages');
        },
    },
};
</script>
<style lang="less" scoped>
.tool-bar-main {
    padding: 10px 0;
    text-align: right;
    padding-right: 50px;
    border-bottom: 1px solid #ccc;
}
.operation-main {
    width: 100%;
    height: 90vh;
    margin: 10px 15px 10px 5px;
    background-color: #fff;
    overflow: hidden;
}
.operation-mesa {
    display: flex;
    height: 94%;
    padding: 30px 0;
}
.operation-content-area {
    flex: 1;
}
.operation-config-area {
    width: 480px;
    margin: 0 40px 0 0;
}
</style>
