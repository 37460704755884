<template>
    <div class="add-tag-container">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="30%"
            title="编辑"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="addialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { updateDesignerSort } from '@/api/mkt/user';
export default {
    props: {},
    data() {
        return {
            addialogVisible: false,
            dialogVisible: false,
            columns: {
                userId: {
                    name: '设计师ID',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                    rules: [{ required: true, message: '' }],
                },
                name: {
                    name: '设计师名称',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                    rules: [{ required: true, message: '' }],
                },
                orderSort: {
                    name: '排序值',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入排序值，数值越小权重越高',
                        inputType: 'number',
                        max: 99999999,
                    },
                    rules: [{ required: true, message: '请输入排序值' }],
                },
            },
            result: {
                name: 1,
                userId: '',
                orderSort: '',
            },
        };
    },
    mounted() {},
    methods: {
        open(row) {
            console.log(row, 'show-----row--------');
            this.addialogVisible = true;
            this.result = {
                orderSort: row.orderSort,
                userId: row.id,
                name: row.name,
            };
            this.$forceUpdate();
        },
        close() {
            this.addialogVisible = false;
        },
        // handleClose() {
        //     this.$emit('handleClose');
        // },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, 88888);
                let params = {
                    userId: this.result.userId,
                    orderSort: parseInt(this.result.orderSort),
                };
                updateDesignerSort(params).then(res => {
                    console.log(res, 'res-----');
                    this.close();
                    this.$emit('handleClose');
                });
            });
        },
        handleReset() {
            this.result = {
                publicData: '',
                job: 0,
                type: 0,
                isDisplay: '',
            };
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'orderSort') {
                this.result.orderSort = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.orderSort);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
