<template>
    <div>
        <el-drawer
            title="抓取规则配置"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="true"
        >
            <sc-form-v2
                style="padding-bottom: 0px"
                name=""
                tablename="ALLOCATION"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="allocation"
                :LS="{ 'min-widthWidth': '100%' }"
            ></sc-form-v2>
            <sc-form-v2
                style="padding-top: 0px"
                name=""
                tablename="ALLOCATION_TOO"
                :columnsNum="2"
                :columns="columnsToo"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="allocationToo"
                :LS="{ 'min-widthWidth': '100%' }"
            ></sc-form-v2>

            <div class="dialog-footer">
                <el-button size="large" @click="handleClose">
                    取消
                </el-button>
                <el-button size="large" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer: false,
            result: {},
            columns: {
                title: {
                    name: '榜单标题',
                    type: 'input',
                    options: {
                        placeholder: '请输入榜单标题',
                        defaultValue: '',
                    },
                    rules: [{ required: true, message: '请输入榜单标题' }],
                },
                deputyTitle: {
                    name: '副标题',
                    type: 'input',
                    options: {
                        placeholder: '请输入副标题',
                        defaultValue: '',
                    },
                    rules: [{ required: true, message: '请输入副标题' }],
                },
            },
            columnsToo: {
                style: {
                    name: '风格',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '风格',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                colorScheme: {
                    name: '色系',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '色系',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                houseType: {
                    name: '房屋类型',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '房屋类型',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                area: {
                    name: '面积',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '面积',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                space: {
                    name: '特色空间',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '特色空间',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                softDecoration: {
                    name: '家具软装',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '家具软装',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                decorate: {
                    name: '装饰',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '装饰',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                designTechniques: {
                    name: '设计手法',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '设计手法',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                materialQuality: {
                    name: '材质',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        defaultValueBase: '',
                        placeholder: '材质',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                    },
                },
                text: {
                    name: '',
                    type: 'text',
                    options: {},
                },
            },
        };
    },
    methods: {
        openDrawer() {
            this.drawer = true;
        },
        handleClose() {
            this.drawer = false;
        },
        handleSubmit() {
            let check = false;
            let checkToo = false;
            this.$refs.allocation.validateAll('ALLOCATION', res => {
                if (res) {
                    check = true;
                }
            });
            if (check) {
                console.log('提交————————————————————————————————');
            }
            // this.$refs.allocation.validateAll('ALLOCATION_TOO', res => {
            //     if (res) {
            //         checkToo = true;
            //     }
            // });
            // if (check && checkToo) {
            //     console.log('提交', 22222222222222);
            // }
        },
        clickFn() {},
        changeFn() {},
    },
};
</script>
<style scoped>
.dialog-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 30px;
}
</style>
