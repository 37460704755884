<template>
    <div class="mkt">
        <Search></Search>
        <el-button @click="add" size="small" type="primary" style="margin:0 0 5px 30px">
            新增案例
        </el-button>
        <!-- <el-button
            type="primary"
            style="margin:0 0 5px 30px"
            size="small"
            @click="onBatchImport"
            v-if="!isDesigner"
        >
            批量导入
        </el-button>
        <el-button
            type="primary"
            style="margin:0 0 5px 30px"
            size="small"
            @click="onImportOfficial"
            v-if="!isDesigner"
        >
            官网数据导入
        </el-button> -->
        <Tab ref="tab" @openAddDrawer="openAddDrawer"></Tab>
        <!-- 批量导入弹窗 -->
        <sc-import-multiple-dialog
            v-if="batchImportShow"
            :batch-import-show="batchImportShow"
            @onClose="onBatchImportClose"
        ></sc-import-multiple-dialog>
        <!-- 导入官网数据弹窗 -->
        <sc-import-official-dialog
            v-if="importOfficialShow"
            :import-official-show="importOfficialShow"
            @onClose="onImportOfficialClose"
        ></sc-import-official-dialog>
        <add ref="add" @closeAddDrawer="closeAddDrawer"></add>
    </div>
</template>

<script>
import Add from './add.vue';
import Search from './search';
import Tab from './tab';
import scImportMultipleDialog from '../../components/sc-import-multiple-dialog.vue';
import scImportOfficialDialog from '../../components/sc-import-official-dialog.vue';
import { CASEURL } from '@/config.js';
import { getCookie } from '@/utils/util.js';

export default {
    name: 'exhibit-index',
    components: { Search, Tab, scImportMultipleDialog, scImportOfficialDialog, Add },
    data() {
        return {
            batchImportShow: false,
            importOfficialShow: false,
            isDesigner: null,
        };
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-exhibit/clear');
    },
    mounted() {
        let login = this.$store.state.login;
        this.isDesigner = login.isDesigner == 1 ? true : false;
    },
    methods: {
        openAddDrawer(row) {
            console.log('row', row);
            window.open(
                CASEURL +
                    '/#/make?id=' +
                    row.id +
                    '&type=adminPC' +
                    '&token=' +
                    getCookie('AuthorizationBe')
            );
            // this.$refs.add.open(row);
        },
        // 批量导入弹窗打开
        onBatchImport() {
            this.batchImportShow = true;
        },

        // 批量导入弹窗关闭
        onBatchImportClose() {
            this.batchImportShow = false;
            this.$refs.tab.init();
        },
        // 导入官网数据弹窗打开
        onImportOfficial() {
            this.importOfficialShow = true;
        },
        onImportOfficialClose() {
            this.importOfficialShow = false;
            this.$refs.tab.init();
        },
        add() {
            window.open(
                CASEURL + '/#/make?' + 'type=adminPC&token=' + getCookie('AuthorizationBe')
            );
            // this.$refs.add.open();
        },
        closeAddDrawer(tab) {
            console.log('-------closeAddDrawer', tab);
            this.$refs.tab.tabActive = '2';
            this.$refs.tab.tabClick(tab);
        },
    },
};
</script>
<style lang="scss" scoped></style>
