<template>
    <div>
        <el-button size="mini" style="margin-bottom: 20px" @click="goBack">返回</el-button>
        <div class="title-card">
            <div class="name-box">
                <div class="title">昵称：</div>
                <div class="img-box">
                    <el-image
                        fit="fill"
                        :preview-src-list="[currentRow.imgUrl || defaultAvatar]"
                        :src="currentRow.imgUrl || defaultAvatar"
                        style="width:40px;height:40px;border-radius: 50%;margin-right:10px;"
                        alt=""
                    />
                    {{ currentRow.nickName }}
                </div>
            </div>
            <div class="name-box">
                <div class="title">手机号：</div>
                <div class="img-box">{{ currentRow.mobile }}</div>
            </div>
            <div class="name-box">
                <div class="title">收藏内容数：</div>
                <div class="img-box">{{ currentRow.collection }}</div>
            </div>
        </div>
        <div class="content-card">
            <div class="tab-box">
                <div
                    class="tab"
                    :class="isCurrent === index ? 'act-tab' : ''"
                    v-for="(item, index) in tabList"
                    :key="index"
                    @click="handTabclick(index)"
                >
                    {{ item }}
                </div>
            </div>
            <Info v-if="isCurrent === 0" :row="currentRow"></Info>
            <Behavior v-if="isCurrent === 1" :id="currentRow.id"></Behavior>
            <Reservation v-if="isCurrent === 2" :id="currentRow.id"></Reservation>
            <msg-collect v-if="isCurrent == 3" :id="currentRow.id"></msg-collect>
            <browse-history v-if="isCurrent == 4" :id="currentRow.id"></browse-history>
        </div>
    </div>
</template>

<script>
import Behavior from './components/behavior.vue';
import Info from './components/info.vue';
import Reservation from './components/reservation.vue';
import msgCollect from './components/msgCollect.vue';
import browseHistory from './components/browseHistory.vue';

export default {
    name: '',
    components: { Info, Behavior, Reservation, msgCollect, browseHistory },
    data() {
        return {
            tabList: ['个人信息', '行为追踪', '预约记录', '信息收集', '浏览记录'],
            isCurrent: 0,
            currentRow: {},
            defaultAvatar:
                'https://dpwcc.voglassdc.com//test/customerApp/market/1686296583837-f6557c9a-c4c3-4c22.jpg',
        };
    },
    created() {
        this.currentRow = JSON.parse(this.$route.query.row);
        console.log(this.currentRow, 'this.$route.query.row');
    },
    mounted() {},
    methods: {
        handTabclick(index) {
            this.isCurrent = index;
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="less" scoped>
.title-card {
    background: #fff;
    display: flex;
}
.name-box {
    margin: 20px 0 20px 380px;
    .img-box {
        margin: 20px 0;
        display: flex;
        align-items: center;
        height: 40px;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    &:nth-child(1) {
        margin-left: 40px;
    }
}
.content-card {
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    .tab-box {
        display: flex;
        .tab {
            padding: 5px 10px;
            background: #a4adb3 100%;
            margin-right: 20px;
            color: #fff;
            cursor: pointer;
            &:hover {
                // background: #000;
            }
        }
        .act-tab {
            background: #000;
        }
    }
}
</style>
