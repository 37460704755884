<template>
    <div class="reservation-container">
        <sc-table-v2
            :head="tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :read-table="true"
            :load="false"
            :pop-show="false"
            :show-add="false"
            :table-height="tableHeightCalc"
            :selection="false"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
        >
            <!-- //插槽例子，指定某一列用插槽写入 -->
            <template v-slot:designerName>
                <el-table-column label="设计师名">
                    <!-- <template slot="header">预约设计师</template> -->
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;">
                            <el-image
                                v-if="scope.row.imgUrl"
                                style="width: 50px; height: 50px"
                                :src="scope.row.imgUrl"
                                :preview-src-list="[scope.row.imgUrl]"
                            ></el-image>
                            &nbsp;
                            <span>
                                {{
                                    scope.row.designerName +
                                        ((scope.row.job && ' / ' + scope.row.job) || '')
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
    </div>
</template>

<script>
import { getAppointmentList } from '@/api/mkt/user.js';

export default {
    name: '',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tableHead: [
                { name: '姓名', key: 'callName' },
                { name: '预约时间', key: 'appointmentTime' },
                { name: '设计师名', key: 'designerName' },
                { name: '创建时间', key: 'createTime' },
            ],
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHeightCalc: window.innerHeight - 440,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
        };
    },
    created() {
        this.getAppointmentList();
    },
    methods: {
        async getAppointmentList() {
            // console.log(this.id, 9999);
            const params = {
                id: this.id,
                // id: '1674354083028873218',
                page: this.page.page,
                size: this.page.size,
            };
            const res = await getAppointmentList(params);
            if (res?.isSuccess) {
                this.list = res.data.children || [];
                this.page.count = res.data.total;
            }
        },
        pageChange(val) {
            this.page.page = val;
            this.getAppointmentList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getAppointmentList();
        },
    },
};
</script>
<style lang="less" scoped>
.reservation-container {
    padding-top: 20px;
}
</style>
