<!--
 * @Author: panggege PP_panggege@126.com
 * @Date: 2023-05-10 20:33:13
 * @LastEditors: panggege PP_panggege@126.com
 * @LastEditTime: 2023-05-10 20:58:00
 * @FilePath: /dmarket/src/page/mkt/om/market/menu.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="menu-container">
        <el-tree
            :data="data"
            :props="defaultProps"
            :default-expand-all="true"
            @node-click="menuNodeHandle"
        >
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="data.isPublish != null">
                    <span v-if="data.isPublish === 0" class="circle"></span>
                    <span v-if="data.isPublish === 1" class="circle active"></span>
                </span>
            </span>
        </el-tree>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            defaultProps: {
                children: 'moduleList',
            },
            nodeList: [],
        };
    },
    mounted() {},
    methods: {
        menuNodeHandle(data, node) {
            if (node.level !== 1) {
                this.$emit('setModuleData', data || {});
                this.nodeList = [];
                this.platform(node);
                this.$emit('setBreadList', this.nodeList);
            }
        },
        // 递归函数
        platform(node) {
            if (!node.parent) {
                // 若无父节点，则直接返回
                return;
            }
            this.nodeList.unshift(node.data.label); // 将value保存起来
            //调用递归
            this.platform(node.parent);
        },
    },
};
</script>
<style lang="less" scoped>
.menu-container {
    min-width: 250px;
    min-height: 83vh;
    overflow-y: auto;
    padding: 8px 15px;
    background: #fff;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background: #b6b6b6;
}
.active {
    background: #4cad56;
}
</style>
