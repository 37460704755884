<!-- 评级 -->
<template>
    <div class="add-tag-container">
        <el-dialog :visible.sync="drawer" :close-on-click-modal="false" width="30%" :title="title">
            <sc-form-v2
                name=""
                tablename="DESIGNERS_INFO"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="close">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { updateDesignerLink, getDesignerLink } from '@/api/mkt/user';

export default {
    props: {},
    data() {
        return {
            title: '打官方标签',
            drawer: false,
            dialogVisible: false,
            columns: {
                case: {
                    name: '专业素质',
                    type: 'checkbox',
                    hidden: false,
                    options: {
                        placeholder: '',
                        options: [
                            {
                                value: '1',
                                label: 'aaa',
                            },
                            {
                                value: '2',
                                label: 'bbb',
                            },
                        ],
                        props: { label: 'label', value: 'value' },
                    },
                },
                case2: {
                    name: '通用素质',
                    type: 'checkbox',
                    hidden: false,
                    options: {
                        placeholder: '',
                        options: [
                            {
                                value: '1',
                                label: 'aaa',
                            },
                            {
                                value: '2',
                                label: 'bbb',
                            },
                        ],
                        props: { label: 'label', value: 'value' },
                    },
                },
                custom: {
                    name: '自定义标签',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
            },
            result: {
                case: [],
                case2: [],
                custom: '',
            },
            commentList: [],
        };
    },
    mounted() {},
    methods: {
        open(row) {
            this.drawer = true;
            if (row.id) {
                this.getInfo(row);
                this.result.accountId = row.id;
            }
            this.$forceUpdate();
        },
        getInfo(row) {
            let params = {
                accountId: row.id,
                page: 1,
                size: 100,
            };
            getDesignerLink(params).then(res => {
                console.log(res, 'res-----');
                this.commentList = res.data.children || [];
            });
        },
        close() {
            this.drawer = false;
            this.result = {
                name: '',
                url: '',
            };
            this.$forceUpdate();
        },
        closeDrawer() {
            this.close('init');
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('DESIGNERS_INFO', () => {
                //校验回调
                let params = {
                    accountId: this.result.accountId,
                    url: decodeURIComponent(this.result.url),
                };
                console.log('params-----', params);

                updateDesignerLink(params).then(res => {
                    if (res.isSuccess) {
                        this.$message.success('抓取评价成功了，请稍后查看');
                        this.getInfo({
                            id: this.result.accountId,
                        });
                    }
                    this.close();
                    // this.$emit('handleClose');
                });
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'orderSort') {
                this.result.orderSort = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.orderSort);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    text-align: right;
    margin: 20px 30px 20px 0;
}
.content-box {
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    line-height: 1.5em;
    display: flex;
    .right-box {
        padding-left: 20px;
        .one {
            display: flex;
            justify-content: space-around;
            padding-bottom: 10px;

            .nickname {
                color: #4e6b80;
            }
            .time {
                color: #999;
            }
        }
        .two {
            color: #333;
            display: flex;
            padding-bottom: 6px;

            .price {
                padding-left: 20px;
            }
        }
        .tags {
            padding-bottom: 6px;

            color: #999;
        }
        .c-content {
            color: #333;
            padding-bottom: 6px;
        }
        .area {
            color: #999;
            padding-bottom: 6px;
        }
        .pictures {
            img {
                width: 110px;
                height: 110px;
                margin-right: 5px;
            }
        }
    }
}
</style>
