import rq from '@/utils/https.js';
const $ajax = rq.requests;
// 获取列表
export const getList = data =>
    $ajax({
        url: '/works/group-list',
        method: 'post',
        data,
    });
// /works/group-urls
export const getDetail = params =>
    $ajax({
        url: '/works/group-urls',
        method: 'get',
        params,
    });
// /works/save-works-group-rec
export const saveWorksGroupRec = data =>
    $ajax({
        url: '/works/save-works-group-rec',
        method: 'post',
        data,
    });
// /works/save-works-group
export const saveWorksGroup = data =>
    $ajax({
        url: '/works/save-works-group',
        method: 'post',
        data,
    });

export const resetLabel = params =>
    $ajax({
        url: '/works/works-group/ai/label/reset',
        method: 'get',
        params,
    });
// 2.9.2 AI标签需求
// /works-group-label/transfer/ai/list
export const getAIList = data =>
    $ajax({
        url: '/works-group-label/transfer/ai/list',
        method: 'post',
        data,
    });
// /works-group-label/transfer/ai
export const submitAI = data =>
    $ajax({
        url: '/works-group-label/transfer/ai',
        method: 'post',
        data,
    });
