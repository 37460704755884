import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 设计师榜单列表
export const dossierList = params =>
    $ajax({
        url: '/message/dossier-list',
        method: 'get',
        params,
    });

// 消息发送人群筛选
export const getDossierUserList = data =>
    $ajax({
        url: '/message/getDossierUserList',
        method: 'post',
        data,
    });

// 保存消息
export const saveUserMessage = data =>
    $ajax({
        url: '/message/saveUserMessage',
        method: 'post',
        data,
    });

//消息发送人群指定人
export const getDossierUserListAppoint = params =>
    $ajax({
        url: '/message/getDossierUserListAppoint',
        method: 'get',
        params,
    });

// 消息列表
export const userMessageList = data =>
    $ajax({
        url: '/message/userMessageList',
        method: 'post',
        data,
    });

// 消息删除
export const delUserMessageById = params =>
    $ajax({
        url: '/message/delUserMessageById',
        method: 'get',
        params,
    });

// 编辑回显
export const getUserMessageById = params =>
    $ajax({
        url: '/message/getUserMessageById',
        method: 'get',
        params,
    });
/**
 * 3.5.1
 * 设计师干预列表 添加设计师个人资料接口
 */
// /user/designer-add
export const designerAdd = params =>
    $ajax({
        url: '/user/designer-add',
        method: 'get',
        params,
    });
// /user/information-edit-echo-list-intervene
export const informationEditEchoListIntervene = params =>
    $ajax({
        url: '/user/information-edit-echo-list-intervene',
        method: 'get',
        params,
    });
export const informationEditEchoIntervene = params =>
    $ajax({
        url: '/user/information-edit-echo-intervene',
        method: 'get',
        params,
    });
// /user/information-edit-intervene
export const informationEditIntervene = data =>
    $ajax({
        url: '/user/information-edit-intervene',
        method: 'post',
        data,
    });
