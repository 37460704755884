<template>
    <div>
        <sc-search-v2
            name="案例图库的搜索"
            :columns="columns"
            :columnsNum="2"
            :clientFalg="false"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="formWorkCollect"
        >
            <template v-slot:spaceId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">空间：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            v-model="result.spaceId"
                            size="small"
                            :options="spaceList"
                            placeholder="请选择"
                            :props="{
                                label: 'spaceName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            ref="spaceRef"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:label>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">标签：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            v-model="result.label"
                            size="small"
                            :options="labelOptions"
                            placeholder="请选择"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            ref="cascader"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>

            <template v-slot:companyCode>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">分公司：</div>
                    </template>
                    <div style="text-align: left">
                        <el-select v-model="result.companyCode" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in companyData"
                                :key="item.ouCode"
                                :label="item.ouName"
                                :value="item.ouCode"
                            ></el-option>
                        </el-select>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
        <div style="text-align: left; padding-left: 30px;margin:10px 0;">
            <el-button size="mini" type="primary" @click="handleSetData">
                {{ show ? '案例组图模式' : '仅灵感图模式' }}
            </el-button>
            <!-- <el-button size="mini" @click="handleSetData"></el-button> -->
        </div>
        <div class="content-wrap">
            <!-- 展示案例 -->
            <div class="content-scroll">
                <div v-if="show" style="padding: 0 30px;">
                    <el-card
                        class="box-card"
                        v-for="(item, index) in list"
                        :key="item.id"
                        style="margin-bottom:30px;"
                    >
                        <div slot="header" class="clearfix">
                            <div class="title">
                                <div class="label">案例名称: {{ item.title }}</div>
                                <div class="label">案例ID: {{ item.id }}</div>
                                <div class="label">设计师: {{ item.createName }}</div>
                                <!-- <div class="label">项目地址: {{ item.projectAddress }}</div> -->
                            </div>
                        </div>

                        <div class="wrap" v-if="item.worksFileVos && item.worksFileVos.length">
                            <div v-for="(items, ind) in item.worksFileVos" :key="ind">
                                <el-checkbox
                                    v-model="items.checked"
                                    class="checkbox"
                                    @change="handleChangeCheckbox"
                                ></el-checkbox>
                                <el-image
                                    style="width: 80px; height:80px"
                                    :src="
                                        items.url +
                                            '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                                    "
                                    :preview-src-list="[items.url]"
                                    lazy="true"
                                ></el-image>

                                <p>{{ items.spaceName }}</p>
                            </div>
                        </div>
                        <!-- <div v-else style="padding-left:20px;">暂无图片数据</div> -->
                    </el-card>
                </div>
                <div class="img-list" v-if="!show">
                    <div class="list-for">
                        <div
                            v-for="(items, ind) in localArr"
                            :key="ind"
                            style="margin-bottom:30px;"
                        >
                            <el-checkbox
                                v-model="items.checked"
                                class="checkbox"
                                @change="handleChangeCheckboxPicture"
                            ></el-checkbox>
                            <el-image
                                style="width: 80px; height: 80px;"
                                :src="
                                    items.url +
                                        '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                                "
                                :preview-src-list="[items.url]"
                                lazy="true"
                            ></el-image>

                            <p>{{ items.spaceName }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-wrap">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 30, 50, 100, 200]"
                    :page-size="page.size"
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <div class="footer">
            <el-button size="small" @click="handleCancle">取消</el-button>
            <el-button type="primary" size="small" @click="submit">确定</el-button>
        </div>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { mapdepot } from '@/api/mkt/exhibit-group';
export default {
    name: 'exhibit-group-search',
    mixins: [mixins],
    data() {
        return {
            currentPage: 1,
            show: true,
            list: [{ worksFileVos: [{ checked: false }] }],
            page: { page: 1, size: 30 },
            total: 0,
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            ],
            imgLists: [],
            checked: false,
            columns: {
                id: {
                    name: '案例ID',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入id搜索',
                    },
                },
                search: {
                    name: '案例名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
                realEstate: {
                    name: '楼盘',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
                spaceId: {
                    name: '空间',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
                designer: {
                    name: '设计师',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
                label: {
                    name: '标签',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入分公司',
                    },
                },
            },
            result: {
                label: '',
                id: '',
                search: '',
                companyCode: '',
                designer: '',
                realEstate: '',
                projectName: '',
                spaceId: '', // 空间id
                // worksIds: '', // 删选标签下案例id
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: true,
            checkedList: [],
            localArr: [],
        };
    },
    created() {
        // this.init();
        this.getTagList();
        this.getSpaceList();
        this.getCompany();
    },
    methods: {
        handleCancle() {
            this.$emit('handleCancle');
        },
        // 案例选择模式
        handleChangeCheckbox() {
            console.log(this.list, '当前列表所有数据');
            // 前端的处理, 先不要外层的id, 只传图片带的id
            let _list = [];
            this.list.map(item => {
                item.worksFileVos.map(items => {
                    if (items.checked) {
                        _list.push(items);
                    }
                });
            });

            this.checkedList = _list;
        },
        // 组图选择模式
        handleChangeCheckboxPicture() {
            console.log(this.list, '当前列表所有数据');
            // 前端的处理, 先不要外层的id, 只传图片带的id
            let _list = [];
            this.localArr.map(items => {
                if (items.checked) {
                    _list.push(items);
                }
            });
            this.checkedList = _list;
        },
        submit() {
            if (!this.checkedList.length) {
                this.$message.info('请至少选择一个');
                return;
            }
            if (this.checkedList.length > 9) {
                this.$message.info('最多上传9张');
                return;
            }

            this.$emit('innerDrawerSubmit', this.checkedList);
        },
        init(calllist) {
            if (calllist) {
                console.log('反显已选中的列表', calllist);
            }
            var param = {};

            let obj = {
                page: this.page.page,
                size: this.page.size,
                ...this.result,
                spaceId: this.result.spaceId.toString(),
            };
            for (var key in obj) {
                if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                    param[key] = obj[key];
                }
            }
            mapdepot(param).then(res => {
                console.log(res);

                this.total = res.data.total;
                if (calllist) {
                    let _list = res.data.children;
                    calllist.map(ii => {
                        _list.map(item => {
                            item.worksFileVos.map((items, index) => {
                                if (ii.worksFileId == items.id) {
                                    items.checked = true;
                                }
                            });
                        });
                    });
                    this.$nextTick(() => {
                        this.list = _list;
                    });
                } else {
                    if (this.show) {
                        this.list = res.data.children;
                    } else {
                        let arr = [];
                        res.data.children.map(item => {
                            item.worksFileVos.map(items => {
                                arr.push(items);
                            });
                        });
                        this.localArr = arr;
                    }
                    this.$forceUpdate();
                }
            });
        },
        handleSetData() {
            //切换组图类
            this.show = !this.show;
            let _list = this.list;
            let arr = [];
            _list.map(item => {
                item.worksFileVos.map(items => {
                    arr.push(items);
                });
            });
            this.localArr = arr;
        },
        changeFn() {
            this.init();
        },
        clickFn(event, type) {
            if (type == 'clear') {
                this.result = {
                    label: '',
                    id: '',
                    search: '',
                    companyCode: '',
                    designer: '',
                    realEstate: '',
                    projectName: '',
                    spaceId: '', // 空间id
                    // worksIds: '', // 删选标签下案例id
                };
                this.page = {
                    page: 1,
                    size: 30,
                };
            }
            console.log('--------1111', this.result, type);
            this.init();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page.size = val;
            this.init();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page.page = val;
            this.init();
        },
    },
};
</script>
<style lang="less" scoped>
.content-scroll {
    // height: calc(100vh - 200px);
    // overflow-y: auto;
}
.title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .label {
        margin-right: 30px;
    }
}
.list-for {
    display: flex;
    flex-flow: wrap;
}
.wrap,
.list-for {
    display: flex;
    flex-flow: wrap;
    padding-left: 20px;
    > div {
        margin-right: 20px;
        position: relative;
        .checkbox {
            position: absolute;
            right: 3px;
            top: 0;
            z-index: 10;
        }
    }
}
.page-wrap,
.footer {
    display: flex;
    justify-content: flex-end;
}
.footer {
    margin-top: 10px;
    margin-right: 50px;
}
/deep/ .el-input__icon.el-input__validateIcon.el-icon-circle-close {
    display: none;
}
</style>
