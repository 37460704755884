<template>
    <div class="mkt" ref="table">
        <div class="tabs">
            <div style="padding: 10px 0px">合集名称：{{ queryName }}</div>

            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="clickFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>
            <div class="btns">
                <el-button size="small" type="primary" @click="onEdit()">新增内容</el-button>
                <el-button class="router-back" size="small" type="primary" @click="routerBack()">
                    返回
                </el-button>
            </div>
            <div>
                <sc-table-v2
                    class="list-table"
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:deag>
                        <el-table-column label="" width="60" align="center">
                            <i class="el-icon-sort icon" style="font-size:20px;"></i>
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="100">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="upDataEdit(scope.row)">
                                    编辑
                                </el-button>
                                <el-button
                                    type="text"
                                    style="color: red"
                                    size="small"
                                    @click="onDel(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>

        <!-- <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog> -->
        <NewAdd ref="edit" @closeDrawer="closeDrawer"></NewAdd>
    </div>
</template>

<script>
import editDialog from './components/detail.vue';
import NewAdd from './components/newAdd.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import Sortable from 'sortablejs';

import { getGroupRowList, getGroupList, delGroup, contentSort } from '@/api/mkt/home-group.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog, NewAdd },
    data() {
        return {
            tabActive: '',
            tabList: [],
            tableHandle: getGroupList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '1', key: 'deag' },
                { name: '内容ID', key: 'contentId' },
                { name: '内容名称', key: 'contentName' },
                { name: '内容类型', key: 'contentType' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            tableAddConfig: {},
            filter: {},
            listData: [],
            columns: {
                contentId: {
                    name: '内容ID',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                name: {
                    name: '内容名称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                type: {
                    name: '内容类型',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        clearable: true,
                        placeholder: '请选择',
                        options: [
                            { label: '笔记', id: 1 },
                            { label: '案例', id: 2 },
                            { label: '案例切片', id: 10 },
                        ],
                        props: {
                            label: 'label',
                            value: 'id',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                name: '',
                contentId: '',
                type: '',
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            queryId: '',
            queryName: '',
            isLoading: false,
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 300;
        });

        this.queryId = this.$route.query.id;
        this.queryName = this.$route.query.title;
        if (this.queryId) {
            this.queryIdData();
        }
        //  else {
        //     this.init();
        // }
    },

    mounted() {
        const tbody = document.querySelector('.list-table tbody');
        let that = this;
        Sortable.create(tbody, {
            handle: '.icon', // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
            onEnd({ newIndex, oldIndex }) {
                if (newIndex != oldIndex) {
                    that.listData.splice(newIndex, 0, that.listData.splice(oldIndex, 1)[0]);
                    var newArray = that.listData.slice(0);
                    that.listData = [];
                    that.$nextTick(function() {
                        that.listData = newArray;
                        // 拖拽事件结束
                        let list = [];
                        that.listData.map(item => list.push(item.id));
                        contentSort(list);
                        console.log('move', newIndex, oldIndex, that.listData);
                        this.queryIdData();
                    });
                }
            },
        });
    },

    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 300;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 300;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.getList();
        },
        async init() {
            let res = await getGroupRowList({ page: 1, size: 30 });

            if (res) {
                this.tabList = res.data.children;
                this.tabActive = this.tabList[0]?.id;
                this.filter = {
                    collectionId: this.tabActive,
                };
            }
            await this.getList();
        },
        async getList() {
            if (!this.filter.collectionId) {
                return false;
            }
            let data = {
                ...this.filter,
                ...this.result,
                page: this.page.page,
                size: this.page.size,
            };
            let res = await getGroupList(data);
            this.listData = res.data.children;
        },
        async queryIdData() {
            let response = await getGroupRowList({ page: 1, size: 30 });
            if (response) {
                this.tabList = response.data.children;
                this.tabActive = this.tabList[0]?.id;
                this.filter = {
                    collectionId: this.tabActive,
                };
            }

            this.filter = {
                collectionId: this.queryId,
            };
            let data = {
                ...this.filter,
                ...this.result,
                page: this.page.page,
                size: this.page.size,
            };

            let res = await getGroupList(data);
            console.log(res, 11111111111111);

            this.listData = res.data.children;
            this.page.total = res.data.total;
            this.page.count = res.data.total;
        },
        tabClick(tab, event) {
            this.tabActive = tab.name;
            this.filter = {
                collectionId: this.tabActive,
            };
            this.getList();
        },
        onEdit(row = {}) {
            row.collectionId = this.tabActive;
            this.$refs.edit.openDrawer(row, 'add', '新增内容');
        },
        upDataEdit(row) {
            this.$refs.edit.openDrawer(row, 'edit', '内容编辑');
            console.log(row, 'uEdit----------------');
        },
        addTag(row) {
            row.isLabel = true;
            this.$refs.editDialog.open(row);
        },
        onDel(row) {
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                delGroup({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.queryIdData();
                });
            });
        },
        closeDrawer() {
            this.queryIdData();
            this.$refs.edit.handleClose();
            console.log(11111111111);
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.getList();
        },
        clickFn(row, type) {
            console.log('--------------111');
            if (type == 'clear') {
                this.page.page = 1;
            }
            this.getList();
        },
        rowChange() {},
        selectionChange() {},
        routerBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.btns {
    padding: 20px 0px 0px 25px;
    width: 100%;
    padding-left: 10px;
}
.router-back {
    position: absolute;
    right: 0;
    margin-right: 35px;
}
</style>
