<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="direction"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="closeDrawer"
        >
            <sc-form-v2
                tablename="BESIC"
                name=""
                :columnsNum="1"
                :result="result"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                ref="articleFrom"
            >
                <template v-slot:titleOne>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <div class="title-first">
                            <span class="head-line"></span>
                            <span>发布图片或视频</span>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:files>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <div class="upload-box">
                            <Upload
                                v-if="result.articleType == 1"
                                ref="uploadbox"
                                :limitNum="1"
                                :width="90"
                                :height="90"
                                :accept="accept"
                                :fileType="'video'"
                                :ossClientCur="ossClient"
                                @edit="editCover"
                                :showPercen="true"
                                objectFit="cover"
                            ></Upload>
                            <Upload
                                v-else
                                ref="uploadbox"
                                :limitNum="30"
                                :width="90"
                                :height="90"
                                :accept="accept"
                                :fileType="'image'"
                                :ossClientCur="ossClient"
                                objectFit="cover"
                            ></Upload>
                        </div>
                    </el-descriptions-item>
                </template>
                <!-- <template v-slot:authorId>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <el-select
                            filterable
                            style="width: 50%"
                            v-model="result.authorId"
                            size="mini"
                            placeholder="请选择"
                            key="authorId"
                        >
                            <el-option
                                v-for="item in columns.authorId.options.options"
                                :key="item.userId"
                                :label="item.createUserName"
                                :value="item.userId"
                            ></el-option>
                        </el-select>
                    </el-descriptions-item>
                </template> -->
                <template v-slot:topicParamList>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <div class="topicInput" style="width:100%;padding-right: 20px;">
                            <!-- <el-input
                                type="textarea"
                                v-model="topicParam"
                                style="margin: 0 20px 10px 0;"
                            ></el-input> -->
                            <div
                                class="result-topic"
                                v-show="result.topicParamList.length"
                                contenteditable="true"
                                @input="updateTopicParam"
                            >
                                <span v-for="item in result.topicParamList" :key="item.value">
                                    {{ '#' + item.topicName }}
                                </span>
                            </div>
                        </div>
                        <el-button
                            size="mini"
                            style="margin-bottom: 20px"
                            @click="toggleTopicOptions"
                            v-show="showTopicBtn"
                        >
                            话题
                        </el-button>
                        <div>
                            <el-select
                                ref="topicRef"
                                v-show="!showTopicBtn"
                                v-model="result.topic"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请选择话题"
                                :remote-method="getTopicOptions"
                                size="mini"
                                style="width:100%;padding-right: 20px;overflow-x: hidden;"
                            >
                                <el-option
                                    v-for="item in topicList"
                                    :key="item.value"
                                    :label="'#' + item.topicName"
                                    :value="item.topicId"
                                    @click.native="clickTopic(item)"
                                    style="width:25vw;;overflow-x: hidden;"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="title-secend">
                            发布设置
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:address>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">添加地址</div>
                        </template>
                        <div style="text-align: left">
                            <el-select
                                v-model="mapAddress"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请选择地址"
                                :remote-method="getSearchAddress"
                                :loading="addressLoading"
                                size="mini"
                                style="width:100%;padding-right: 20px;"
                            >
                                <el-option
                                    v-for="item in poiList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.name"
                                    @click.native="clickAddress(item)"
                                >
                                    <div>
                                        <span style="float: left">{{ item.name }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            {{
                                                item.cityname + '' + item.adname + '' + item.address
                                            }}
                                        </span>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>

            <div style="text-align: right; margin:0 30px 20px 0">
                <el-button
                    size="mini"
                    style="margin-right: 20px"
                    @click="submit(1)"
                    v-preventReClick="5000"
                >
                    存草稿箱
                </el-button>
                <el-button type="primary" size="mini" @click="submit(0)" v-preventReClick="5000">
                    发布笔记
                </el-button>
            </div>
        </el-drawer>
        <!-- 编辑封面 -->
        <cover-dialog ref="coverdialog" @submitCoverDialog="submitCoverDialog"></cover-dialog>
    </div>
</template>

<script>
import mixins from '@/mixins';
import getOssMixin from '@/mixins/getOssMixin';
import {
    info,
    approval,
    addArticle,
    getNoteTypeListOption,
    getTopicOptions,
    getDesignerList,
} from '@/api/mkt/article';
import { getMapSearch, getDictList } from '@/api/common';
import Upload from '@/components/upload';
import coverDialog from './cover-dialog';
import { getCookie } from '@/utils/util.js';

export default {
    name: 'detailDrawer',
    mixins: [mixins, getOssMixin],
    components: { Upload, coverDialog },
    data() {
        return {
            title: '发布笔记',
            drawer: false,
            direction: 'rtl',
            accept: ['mp4'],
            addressLoading: false,
            mapAddress: '',
            poiList: [],
            topicList: [],
            topicListOrigin: [], //原始话题数据
            showTopicBtn: true,
            cover: {}, //封面对象 ，保存使用
            isClip: false, //是否裁剪过
            topicParam: '', //回显话题内容
            result: {
                articleType: 3,
                authorId: '',
                content: '',
                isDisplayLocation: 0, //是否展示位置
                location: '',
                locationCity: '',
                noteType: 0,
                noteTypeName: '',
                title: '',
                topicParamList: [],
                specialSubjectDet: [],
                specialSubjectDetVos: '',
                // files: [
                //     {
                //         fileType: 0,
                //         height: 0,
                //         orderSort: 0,
                //         smallUrl: '',
                //         url: '',
                //         width: 0,
                //     },
                // ],
            },
            columns: {
                titleOne: {
                    name: '',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                articleType: {
                    name: '',
                    type: 'radio',
                    options: {
                        // defaultValue: 3, //1视频2长图文3短图文
                        placeholder: '',
                        options: [
                            {
                                value: 3,
                                label: '图片',
                            },
                            {
                                value: 1,
                                label: '视频',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                },
                files: {
                    name: '',
                    type: 'text',
                },
                title: {
                    name: '',
                    type: 'input',
                    options: {
                        placeholder: '请输入笔记标题',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                },
                content: {
                    name: '',
                    type: 'textarea',
                    options: {
                        placeholder: '请输入笔记描述',
                    },
                },
                authorId: {
                    name: '作者',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [
                            // {
                            //     id: '1',
                            //     name: 'aaa',
                            // },
                        ],
                        props: {
                            value: 'userId',
                            label: 'createUserName',
                        },
                        filterable: true,
                    },
                },
                topicParamList: {
                    name: '话题',
                    type: 'text',
                },
                specialSubjectDetVos: {
                    name: '所属专题',
                    type: 'radio',
                    options: {
                        placeholder: '占位',
                        options: [
                            // {
                            //     id: '1',
                            //     name: 'aaa',
                            // },
                        ],
                        props: {
                            value: 'id',
                            label: 'name',
                        },
                    },
                },
                address: {
                    key: 'address',
                    name: '添加地址',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
            },
            srcList: [],
            deleteData: '',
            pic_limit_num: 30,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getNoteTypeListOption();
        this.getTopicOptions();
        this.getDesignerList();
        this.getDictList();
    },
    methods: {
        changeFn(val) {
            if (val.row.key == 'specialSubjectDetVos') {
                let item =
                    this.columns.specialSubjectDetVos.options.options.filter(
                        d => d.id == val.value
                    )[0] || {};
                this.result.noteType = item.id;
                this.result.noteTypeName = item.name;
                console.log('--------noteType', item);
            }
            if (val.row.key == 'articleType') {
                this.$refs.uploadbox.imgList = [];
            }
        },
        // 关键字搜索地址 （Web服务）
        getSearchAddress(query) {
            console.log('--------------getSearch', query, this.mapAddress);
            if (query && query.trim() !== '') {
                let params = { keywords: query, city: '北京' };
                this.addressLoading = true;
                this.mapAddress = query;
                getMapSearch(params).then(res => {
                    console.log(res, 'res--------');
                    if (res.isSuccess) {
                        this.addressLoading = false;
                        let poiList = JSON.parse((res.data && res.data.pois) || '[]');
                        let poiObj = {};
                        let newPoiList = [];
                        poiList.map(d => {
                            if (!poiObj[d.name]) {
                                newPoiList.push(d);
                                poiObj[d.name] = true;
                            }
                        });
                        this.poiList = poiList;
                    } else {
                        this.$message.info(res.message);
                    }
                });
            } else {
                this.poiList = [];
                this.mapAddress = '';
            }
        },
        updateTopicParam: function(e) {
            console.log('----------updateContent', e);
            let arr = e.target.outerText.split(' ');
            let newArr = [];
            this.result.topicParamList.filter((item, index) => {
                console.log('---item', item);

                if (arr[index] == '#' + item.topicName) {
                    newArr.push(item);
                }
            });
            this.$set(this.result, 'topicParamList', newArr);
            console.log('----arr', arr);
            // if(topicParamList)
            // this.topicParam = e.target.innerHTML;
        },
        // 专题筛选列表
        getNoteTypeListOption() {
            getNoteTypeListOption().then(res => {
                if (res?.isSuccess) {
                    this.columns.specialSubjectDetVos.options.options = res.data || [];
                }
            });
        },
        clickAddress(item) {
            console.log('---------clickAddress', item);
            if (item.name) {
                this.mapAddress = item.name;
                this.result.location = item.name;
                this.result.locationCity = item.cityname;
            }
        },
        // 话题筛选列表
        getTopicOptions(query) {
            let params = { topicName: query };
            getTopicOptions(params).then(res => {
                if (res?.isSuccess) {
                    this.topicList = res.data || [];
                    if (!query) {
                        this.topicListOrigin = res.data;
                    }
                }
            });
        },
        clickTopic(item) {
            console.log('-----clickTopic', item);
            if (item.topicName) {
                this.topicParam += ' #' + item.topicName;
                this.result.topicParamList.push({
                    topicId: item.topicId,
                    topicName: item.topicName,
                });
                // this.$nextTick(() => {
                //     this.$set(this.result, 'topicParam', this.topicParam);
                // });
                this.result.topic = '';
                this.topicList = this.topicListOrigin;

                console.log('---------this.result', this.topicParam);
                // this.$forceUpdate();
            }
            this.showTopicBtn = true;
        },
        getDesignerList() {
            getDesignerList().then(res => {
                if (res?.isSuccess) {
                    res.data.map(item => {
                        item.createUserName =
                            item.createUserName + '（ ' + item.createUserAccount + ' ）';
                    });
                    this.columns.authorId.options.options = res.data;
                }
            });
        },
        // 获取校验字段值-图片数量
        getDictList() {
            getDictList({ parentId: '217' }).then(res => {
                if (res?.isSuccess) {
                    let item = res.data.find(d => d.dictName == 'pic_limit_article'); //案例pic_limit_works，笔记pic_limit_pic_limit_article
                    this.pic_limit_num = parseInt(item.dictValue || 0);
                    // console.log('pic_limit_num', item.dictValue);
                }
            });
        },
        // 打开筛序项
        toggleTopicOptions() {
            this.$refs.topicRef.toggleMenu();
            this.showTopicBtn = false;
        },

        toggleTopicVisible(visible) {
            if (visible) {
                this.showTopicBtn = !this.showTopicBtn;
            } else {
                console.log('-------不展示');

                this.showTopicBtn = !this.showTopicBtn;
            }
        },

        removeTag(val) {
            this.deleteData = val.value;
        },
        handleChangeCloumn(val) {
            this.worksInfo.categoryId = val;
        },
        changeLabel(arr, deleteData) {
            // 多选删除事件的时候需要手动删除处理
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理成后端需要的数据得数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        // isDraft 0发布;1草稿；
        submit(isDraft) {
            let that = this;
            let newfiles = this.$refs.uploadbox.imgList || [];
            if (!newfiles.length || newfiles.length < 1) {
                this.$message.warning('上传图片/视频不能为空');
                return false;
            }
            if (!this.result.authorId && isDraft == 0) {
                this.$message.warning('作者不能为空');
                return false;
            }
            if (this.result.articleType == 2 || this.result.articleType == 3) {
                let imgNumber = newfiles.length;
                if (imgNumber > this.pic_limit_num) {
                    this.$message.warning(`笔记中图片不能超过${this.pic_limit_num}张`);
                    return false;
                }
            }

            let files = this.getFiles(newfiles);
            let topicParamList = this.result.topicParamList;
            let params = {
                articleId: this.result.articleId,
                articleType: this.result.articleType,
                isDraft: isDraft,
                authorId: this.result.authorId || '',
                content: this.result.content || '',
                files: files,
                isDisplayLocation: this.result.location ? 1 : 0,
                location: this.result.location,
                locationCity: this.result.locationCity,
                noteType: this.result.noteType,
                noteTypeName: this.result.noteTypeName,
                title: this.result.title,
                topicParamList,
            };
            console.log('-----params', params);
            // return;
            addArticle(params).then(res => {
                if (res.errorCode == 200) {
                    let message = isDraft == 0 ? '添加成功' : '保存成功';
                    this.$message.success(message);
                    this.close(isDraft);
                } else {
                    this.$message.info(res.message);
                }
            });
        },
        getFiles(newfiles) {
            let files = [];
            newfiles.map((item, index) => {
                let fileType = this.result.articleType == 1 ? 2 : 1; //
                files.push({
                    fileType: fileType, //1图片 2视频 3ppt 4pdf 5 视频封面
                    height: item.height || '',
                    orderSort: index,
                    smallUrl: item.smallUrl || '',
                    url: item.url || '',
                    width: item.width || '',
                });
            });
            if (this.result.articleType == 1) {
                //视频添加封面
                if (this.cover.url && this.isClip) {
                    //编辑过封面
                    files.push(this.cover);
                } else {
                    this.cover = Object.assign({}, newfiles[0]);
                    this.cover.fileType = 5;
                    this.cover.url = this.cover.smallUrl = this.cover.videoUrl;
                    files.push(this.cover);
                }
            }
            return files;
        },
        // 打开添加信息弹窗
        async openDrawer(row) {
            console.log(row);
            this.drawer = true;
            if (row && row.articleId) {
                console.log('--------row', row);
                this.showInfo(row);
                this.title = '编辑笔记';
            } else {
                this.title = '新增笔记';
            }
            await this.getOss(); //调取mixins中获取
        },
        close(type) {
            this.drawer = false;
            this.cover = {};
            this.isClip = false;
            this.topicParam = '';
            this.showTopicBtn = true;
            this.mapAddress = '';
            this.poiList = [];
            this.result = {
                articleType: 3,
                authorId: '',
                content: '',
                isDisplayLocation: 0, //是否展示位置
                location: '',
                locationCity: '',
                noteType: 0,
                noteTypeName: '',
                title: '',
                topicParamList: [],
                specialSubjectDet: [],
                specialSubjectDetVos: [],
            };
            this.$refs.uploadbox.imgList = [];
            this.$forceUpdate();
            if (type != 'init') {
                // 清空数据
                this.$emit('closeDrawer', type);
            }
        },
        closeDrawer() {
            this.close('init');
        },
        // 打开编辑封面
        editCover(files = []) {
            this.$refs.coverdialog.openDrawer(files);
        },
        // 确认 封面弹窗 把封面保存到imgList中
        submitCoverDialog(file) {
            let that = this;
            if (file) {
                this.isClip = true;
                // 获取图片宽高
                let result = { url: file, smallUrl: file, fileType: 5, orderSort: 1 };
                let img = new Image();
                img.src = file;
                img.onload = function() {
                    //多图设置方式
                    result.width = img.width;
                    result.height = img.height;
                    let imgList = that.$refs.uploadbox.imgList;
                    imgList[0].videoUrl = file;
                    // 存到封面
                    that.cover = result;
                    that.$refs.uploadbox.setList(imgList);
                };
            }
        },
        selectImg(imgUrl) {
            this.cacheImgUrl = imgUrl;
        },
        // 处理后端需要的数据
        changLabelArr(arr) {
            if (arr.length > 0) {
                arr.map(item => {
                    item.labelId = item.id;
                    delete item.id;
                });
                return arr;
            } else {
                return [];
            }
        },
        showInfo(row) {
            info({ articleId: row.articleId }).then(res => {
                this.result = {
                    ...res.data,
                    authorId: res.data.accountId,
                    specialSubjectDetVos: res.data.noteType + '',
                };
                let account = getCookie('account');
                if (res.data.account == account) {
                    this.result.authorId = '';
                }
                let result = res.data;
                let files = result.files;
                if (result.files) {
                    if (result.files.filter(d => d.fileType == 5).length) {
                        console.log('-------111', result.files);
                        result.files.map(d => {
                            if (d.fileType == 3 || d.fileType == 2) {
                                d.videoUrl = result.files.filter(d => d.fileType == 5)[0].url;
                            }
                        });

                        this.cover = result.files.filter(d => d.fileType == 5)[0];
                        files = result.files.filter(d => d.fileType == 3 || d.fileType == 2);
                    } else {
                        // result.files[0].videoUrl =
                        //     result.files[0].url +
                        //     '?x-oss-process=video/snapshot,t_3000,f_jpg,w_750';
                        console.log('-------222', result.files);
                    }
                    console.log('-------files', files);
                    this.$refs.uploadbox.setList(files);
                }
                this.mapAddress = result.location;
                let topicParam = '';
                this.result.topicParamList.map(item => {
                    if (item) {
                        topicParam += ' #' + item.topicName;
                    }
                });
                this.topicParam = topicParam;
            });
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .self-drawer {
    .head-line {
        display: inline-block;
        width: 4px;
        height: 14px;
        background: #409eff;
        margin-right: 4px;
    }
    .title-first {
        margin: 0 0 10px;
        font-size: 16px;
        font-weight: 700;
        color: #303133;
        display: flex;
        align-items: center;
    }
    .title-secend {
        margin: 40px 0 10px;
        font-size: 16px;
        font-weight: 700;
        color: #303133;
    }
    .topicInput .el-textarea__inner {
        border: 0;
        resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
    }
}

/deep/ .upload-box {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 22px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }
}
/deep/ .self-drawer {
    padding: 0 20px;
}
.img-list {
    float: left;
    .img {
        width: 50px;
        height: 50px;
    }
}
/deep/ .texts {
    padding-left: 0px !important;
    .texts-d {
        font-size: 12px !important;
    }
}

/deep/ .el-descriptions__title {
    font-size: 14px;
}
/deep/ .el-divider--horizontal {
    margin: 0;
}
/deep/ .el-radio {
    margin-bottom: 10px;
}
.result-topic {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 6px 12px 22px;
    outline: none;
    color: #606266;
    margin-bottom: 10px;
    &:focus {
        border: 1px solid #409eff;
    }
}
</style>
