export let columnsBaseInfo = {
    k1: {
        name: '性别',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k2: {
        name: '年龄',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k3: {
        name: '星座',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k4: {
        name: '所在城市',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k5: {
        name: '工作年限',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k6: {
        name: '职级',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k7: {
        name: '设计项目',
        type: 'input',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k8: {
        name: '擅长风格',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k9: {
        name: '个性标签',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k10: {
        name: '行业资质',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
    k11: {
        name: '获奖荣誉',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择性别',
            options: [
                { label: '男', value: '男' },
                { label: '女', value: '女' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择' }],
        spanCol: 1,
    },
};
export let columnsInfo = {
    k1: {
        key: 'topicCover',
        name: '设计理念',
        type: 'upload',
        fileList: [],
        options: {
            defaultValue: [],
            btnText: '上传图片',
            tip: '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
            size: 2000,
            fileSize: 2,
            limit: 3,
            fileType: 'jpg,png,jpeg',
        },
    },
    k2: {
        name: '设计理念',
        type: 'textarea',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k3: {
        name: '特质',
        type: 'input',
        options: {
            placeholder: '请输入特质',
            maxlength: 100,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k4: {
        name: '特质',
        type: 'input',
        options: {
            placeholder: '请输入特质',
            maxlength: 100,
            showWordLimit: true,
        },
        spanCol: 1,
    },
    k5: {
        name: '特质',
        type: 'input',
        options: {
            placeholder: '请输入特质',
            maxlength: 100,
            showWordLimit: true,
        },
        spanCol: 1,
    },
    k6: {
        name: '我的故事',
        type: 'input',
    },
    k7: {
        name: '设计师说',
        type: 'input',
    },
    k8: {
        key: 'topicCover',
        name: '我们',
        type: 'upload',
        fileList: [],
        options: {
            defaultValue: [],
            btnText: '上传图片',
            tip: '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
            size: 2000,
            fileSize: 2,
            limit: 3,
            fileType: 'jpg,png,jpeg',
        },
    },
    k9: {
        key: 'topicCover',
        name: '关于我',
        type: 'upload',
        fileList: [],
        options: {
            defaultValue: [],
            btnText: '上传图片',
            tip: '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
            size: 2000,
            fileSize: 2,
            limit: 3,
            fileType: 'jpg,png,jpeg',
        },
    },
};
export let columnsShowInfo = {
    k1: {
        name: '姓名',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k2: {
        name: '昵称',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k3: {
        name: '手机号',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k4: {
        name: '员工账号',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k5: {
        name: '分公司',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
    k6: {
        name: '岗位',
        type: 'text',
        options: {
            placeholder: '请输入设计理念',
            maxlength: 1000,
            showWordLimit: true,
        },
        rules: [{ required: true, message: '请输入' }],
        spanCol: 1,
    },
};
