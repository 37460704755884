<!-- 发布案例 独立页面-->

<template>
    <div>发布案例页面</div>
</template>

<script>
export default {
    name: '',
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped></style>
