<template>
    <div class="content">
        <div>
            <sc-tables
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="500"
                :read-table="true"
                :load="false"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <template v-slot:ShareCategory>
                    <el-table-column>
                        <template slot="header">业绩类型</template>
                        <template slot-scope="scope">
                            {{ scope.row.ShareCategory.options.defaultValue }}
                        </template>
                    </el-table-column>
                </template>
                //插槽例子，操作列用插槽写入
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" @click="editPage(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-tables>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-pages');
export default {
    data() {
        return {
            tableHead: [
                { name: '页面名称', value: 'pageName' },
                { name: '页面类型', value: 'pageType' },
                // { name: '状态', value: 'isPublish' },
                { name: '最近更新时间', value: 'updateTime' },
                { name: '所属组织', value: 'companyName' },
            ],
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            page: {
                count: 0, //总条数
                page: 1, //当前页
                sizes: [], //每页显示个数选择器的选项设置，默认[30, 50, 100, 200]
                show: true, //是否显示，默认false
            },
            addiagvisible: false,
        };
    },
    components: {},
    computed: mapState({
        topObj: state => state.topObj,
        list: state => state.list,
    }),
    mounted() {
        // let params = this.$store.state['mkt-pages'].params;
        // params.companyCode = '138';
        // this.$store.commit('mkt-pages/setParams', params);
        // 第一版默认 使用集团的
        this.$store.dispatch('mkt-pages/list');
        setTimeout(() => {
            console.log(
                'state-------------------------------------',
                this.$store.state['mkt-pages'].list
            );
        }, 3000);
    },
    methods: {
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getData();
        },
        pageChange(val) {
            this.page.page = val;
            this.getData();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        editPage(item) {
            this.$router.push({
                path: '/mkt/om/markets?pageId=' + item.pageId,
                query: { id: item.id },
            });
        },
        openImage(item) {
            // item.qrCodeUrl
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    margin: 10px 15px;
}
</style>
