<template>
    <el-dialog title="编辑" width="40%" :visible.sync="visible" :before-close="onClose">
        <sc-form-v2
            :columns-num="1"
            :columns="columns"
            :result="form"
            :esay-style="true"
            tablename="supplyDescription"
            :columns-padding-right="20"
            direction="vertical"
            ref="form"
        ></sc-form-v2>

        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { updateUserJob, getRolesList } from '@/api/mkt/user.js';
export default {
    name: 'post-dialog',
    props: {
        postDialogShow: {
            type: Boolean,
            default: false,
        },
        currentRow: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            visible: false,
            columns: [
                {
                    key: 'job',
                    name: '岗位',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        placeholder: '请输入',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入' }],
                },
                {
                    key: 'role',
                    name: '角色',
                    type: 'select',
                    options: {
                        defaultValue: [],
                        clearable: true,
                        multiple: true,
                        options: [],
                        props: {
                            value: 'id',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
            ],
            form: {
                job: '', //	岗位
                role: [], //用户类型
            },
            currentId: '',
        };
    },
    watch: {
        postDialogShow: {
            handler(val) {
                this.visible = val;
                if (val) {
                    this.getRolesList();
                }
            },
            immediate: true,
        },
        currentRow: {
            handler(val) {
                this.form.role = [];
                this.form.job = val?.job; //	岗位
                if (val?.roleId?.length && val?.roleId != '--') {
                    this.form.role = val.roleId;
                }
                this.currentId = val?.id;
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        async getRolesList() {
            const res = await getRolesList();
            if (res?.isSuccess) {
                this.columns.map(item => {
                    if (item.key == 'role') {
                        item.options.options = res.data;
                    }
                });
            }
        },
        // 保存
        onSubmit() {
            let params = {
                job: this.form.job, //	岗位
                role: this.form.role, //用户类型
                userId: this.currentId,
            };
            this.$refs.form.validateAll('supplyDescription', async () => {
                let { code, data } = await updateUserJob(params);
                if (code === 200) {
                    this.$message.success('操作成功！');
                    this.onClose();
                }
            });
        },

        // 取消
        onClose() {
            this.$emit('onClosePostDialog');
        },
    },
};
</script>

<style lang="less" scoped></style>
