<template>
    <div class="add-tag-container">
        <el-drawer :visible.sync="addiagvisible" size="30%" :title="name" :wrapperClosable="false">
            <sc-form-v2
                name=""
                tablename="CRM_TAG"
                :columnsNum="1"
                :columns="columns"
                :result="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="tagform"
            >
                <template v-slot:labelGroupId>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                所属标签组:
                                <span class="must">*</span>
                            </div>
                        </template>
                        <div style="text-align: left; display: flex">
                            <el-select
                                v-model="result.labelGroupId"
                                size="small"
                                @change="changeGroupList"
                                :disabled="name == '编辑标签' && result.labelGroupName != 'AI标签'"
                            >
                                <el-option
                                    v-for="item in groupList"
                                    value-key="item"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="item.disabled"
                                ></el-option>
                            </el-select>
                            <div style="margin-left: 10px" v-if="name != '编辑标签'">
                                <el-button size="small" type="primary" @click="onQuickCreate">
                                    快速新建
                                </el-button>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
                <!-- <template v-slot:parentId>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">所属标签:</div>
                        </template>
                        <div style="text-align: left">
                            <el-cascader
                                v-model="result.parentId"
                                size="small"
                                :options="childList"
                                :disabled="name == '编辑标签'"
                                :props="{
                                    label: 'labelName',
                                    value: 'id',
                                    checkStrictly: true,
                                    emitPath: false,
                                }"
                                :show-all-levels="false"
                                @change="selectTagChange"
                                clearable
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template> -->
                <!-- <template v-slot:properties>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                标签类型:
                                <span class="must">*</span>
                            </div>
                        </template>
                        <div style="text-align: left">
                            <el-radio-group
                                v-model="result.properties"
                                :disabled="name == '编辑标签'"
                            >
                                <el-radio
                                    :label="1"
                                    :disabled="
                                        selGroupObj.groupType == '5' || selGroupObj.groupType == '4'
                                    "
                                >
                                    系统
                                </el-radio>
                                <el-radio
                                    :label="2"
                                    :disabled="
                                        selGroupObj.groupType == '1' || selGroupObj.groupType == '4'
                                    "
                                >
                                    自定义
                                </el-radio>
                                <el-radio
                                    :label="3"
                                    :disabled="
                                        selGroupObj.groupType == '1' || selGroupObj.groupType == '5'
                                    "
                                >
                                    运营
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </el-descriptions-item>
                </template> -->
                <template v-slot:orderSort>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">排序</div>
                            <el-input
                                v-model.number="result.orderSort"
                                style="width: 100px"
                                type="number"
                                size="mini"
                                @input="handleSort"
                            ></el-input>
                        </template>
                        <div style="text-align: left"></div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="addiagvisible = false">取消</el-button>
                <el-button size="mini" @click="handSubmit" type="primary">确定</el-button>
            </div>
        </el-drawer>
        <div v-if="createTagShow">
            <create-tag-group
                :createTagShow="createTagShow"
                @onCloseCreateTag="onCloseCreateTag"
            ></create-tag-group>
        </div>
    </div>
</template>
<script>
import mixins from '@/mixins';
import { addAndEdit, labellist } from '@/api/mkt/tag';
import { getTagList, getChildList } from '@/api/mkt/tag.js';
import createTagGroup from './components/create-tag-group';
// group集合
let groupObj = {};
export default {
    props: {},
    components: { createTagGroup },
    mixins: [mixins],
    data() {
        return {
            createTagShow: false,
            labels: [],
            addiagvisible: false,
            labelOptions: [],
            columns: {
                labelName: {
                    name: '标签名称',
                    type: 'input',
                    options: {
                        placeholder: '请输入标签名称',
                        maxlength: 15,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入标签名称' }],
                },
                labelGroupId: {
                    name: '所属标签组',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        placeholder: '请选择所属标签组',
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择所属标签组' }],
                },
                // parentId: {
                //     name: '所属标签',
                //     type: 'select',
                //     options: {
                //         defaultValue: '0',
                //         placeholder: '请选择所属标签',
                //         options: [],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     rules: [{ required: false, message: '请选择所属标签' }],
                // },
                orderSort: {
                    // name: '排序值',
                    // type: 'input',
                    // options: {
                    //     placeholder: '请输入排序值',
                    // },
                },
                // properties: {
                //     name: '标签类型',
                //     type: 'radio',
                //     options: {
                //         defaultValue: '0',
                //         placeholder: '',
                //         options: [
                //             {
                //                 label: '系统',
                //                 value: 1,
                //             },
                //             {
                //                 label: '运营',
                //                 value: 3,
                //             },
                //             {
                //                 label: '自定义',
                //                 value: 2,
                //             },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     rules: [{ required: true, message: '请选择标签类型' }],
                // },
            },
            result: {
                labelName: '',
                labelGroupId: {},
                orderSort: 0,
                // properties: '',
                labellist: '',
                // parentId: '',
            },
            tagList: [],
            tagObj: {},
            name: '',
            groupList: [],
            childList: [],
            selGroupObj: {},
        };
    },
    created() {},
    methods: {
        getTagList() {
            getTagList().then(res => {
                if (res.code == 200) {
                    let tagObj = {};
                    let _data = res.data.children || [];
                    for (let one of _data) {
                        tagObj[one.id] = one;
                        if (one.children && one.children.length) {
                            for (let two of one.children) {
                                // two.children = null;
                                tagObj[two.id] = two;
                                if (two?.children?.length) {
                                    for (let three of two.children) {
                                        tagObj[three.id] = three;
                                        if (three?.children?.length) {
                                            for (let four of three.children) {
                                                tagObj[four.id] = four;
                                                if (four?.children?.length) {
                                                    for (let five of four.children) {
                                                        tagObj[five.id] = five;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    _data.unshift({ id: '0', labelName: '无', value: '', label: '无' });
                    this.tagList = _data;
                    this.tagObj = tagObj;
                    // this.load = false;
                }
            });
        },

        handleSort(val) {
            var regPos = /^[0-9]*[1-9][0-9]*$/; // 非负整数
            if (!regPos.test(val)) {
                this.result.orderSort = 0;
                return;
            }
            if (val > 9999) {
                this.result.orderSort = 9999;
            }
        },
        changeGroupList(val) {
            this.getChildList();
            this.selGroupObj = groupObj[val] || {};
            console.log(groupObj, groupObj[val], 'value_------');
            console.log(this.selGroupObj);
            // if (this.selGroupObj.groupType === '4') {
            //     this.result.properties = 3;
            // } else if (this.selGroupObj.groupType === '5') {
            //     this.result.properties = 2;
            // } else if (this.selGroupObj.groupType === '1') {
            //     this.result.properties = 1;
            // }
        },
        getChildList() {
            getChildList(this.result.labelGroupId).then(res => {
                // this.childList = res.data.children;
                if (res.code == 200) {
                    let tagObj = {};
                    let _data = res.data.children || [];
                    for (let one of _data) {
                        tagObj[one.id] = one;
                        if (one.children && one.children.length) {
                            for (let two of one.children) {
                                // two.children = null;
                                tagObj[two.id] = two;
                                if (two?.children?.length) {
                                    for (let three of two.children) {
                                        tagObj[three.id] = three;
                                        if (three?.children?.length) {
                                            for (let four of three.children) {
                                                tagObj[four.id] = four;
                                                if (four?.children?.length) {
                                                    for (let five of four.children) {
                                                        tagObj[five.id] = five;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    _data.unshift({ id: '0', labelName: '无', value: '', label: '无' });
                    this.childList = _data;
                    this.tagObj = tagObj;
                    // this.load = false;
                }
            });
        },
        getGroupList() {
            labellist().then(res => {
                let arr = [];
                groupObj = {};
                res.data.children.map(item => {
                    arr.push({
                        label: item.groupName,
                        value: item.id,
                        disabled: item.state == 0 ? false : true,
                    });
                    groupObj[item.id] = item;
                });
                this.groupList = arr;
            });
        },
        open(row) {
            this.getTagList();
            this.getGroupList();
            this.result = {
                orderSort: 0,
                // properties: null,
            };

            if (row && row.id) {
                console.log(row);
                this.name = '编辑标签';
                this.result = { ...row };
                this.getChildList(this.result.labelGroupId);
                // this.result.parentId = row.parentId;

                this.addiagvisible = true;
            } else {
                console.log('创建');
                this.name = '创建标签';
                this.addiagvisible = true;
            }
        },
        close() {
            this.addiagvisible = false;
        },
        handleClose() {
            this.$emit('closediag');
        },

        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            if (data.row.key == 'labelGroupId') {
                this.result.labelGroupId = data.value;
                this.getChildList();
            }
        },
        // 点击事件回调方法
        clickFn(data) {},

        selectTagChange(value) {
            console.log(value, 'selectTagChange----value------');
        },
        handSubmit() {
            this.$refs.tagform.validateAll('CRM_TAG', () => {
                //校验回调
                // this.result.labelGroupId = null;
                // labelGroupId
                if (!this.result.labelGroupId) {
                    this.$message.warning('请选择所属标签组');
                    return;
                }

                let data = '';
                this.groupList.forEach(el => {
                    if (el.label === this.result.labelGroupId) {
                        data = el.value;
                    }
                });

                let params = {
                    ...this.result,
                    labelGroupId: data || this.result.labelGroupId,
                };

                addAndEdit(params).then(res => {
                    console.log(res, 'res-----');
                    this.addiagvisible = false;
                    this.$emit('closediag');
                });
            });
        },
        handleReset() {
            this.result = {};
        },

        // 快速创建标签
        onQuickCreate() {
            this.createTagShow = true;
        },

        onCloseCreateTag(row) {
            this.createTagShow = false;
            this.getGroupList();
            this.result.labelGroupId = row.labelGroupId || '';
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
}
.must {
    color: red;
    vertical-align: -2px;
}
</style>
