<template>
    <div class="content" ref="table">
        <div>
            <el-button
                type="primary"
                style="margin-left: 10px"
                size="small"
                @click="createTopic('新增话题')"
            >
                新增话题
            </el-button>
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:createUserName>
                    <el-table-column label="创建人" width="270">
                        <template slot-scope="scope">
                            <span>
                                {{ scope.row.createUserName }}
                                <span>（{{ scope.row.createUserAccount }}）</span>
                            </span>
                            <el-tag size="mini">
                                {{ scope.row.createUserJob }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:enabled>
                    <el-table-column label="是否启用" fixed="right" width="70">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.enabled"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="1"
                                :inactive-value="0"
                                @change="onChangeEnabled(scope.row)"
                            ></el-switch>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="220">
                        <template slot-scope="scope">
                            <span
                                class="btn-style"
                                type="text"
                                @click="createTopic('编辑话题', scope.row.topicId)"
                            >
                                编辑
                            </span>
                            <span class="btn-style" type="text" @click="onArticle(scope.row)">
                                关联笔记
                            </span>
                            <sc-qrcode-popover
                                :id="scope.row.topicId"
                                type="topic"
                                class="btn-style"
                            ></sc-qrcode-popover>
                            <span
                                class="btn-style"
                                style="color: red"
                                type="text"
                                @click="ondeleteTopic(scope.row)"
                            >
                                删除
                            </span>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <drawer-dialog ref="drawerDialog"></drawer-dialog>
    </div>
</template>

<script>
import drawerDialog from './details.vue';
import { onIsEnabled, removeTopic } from '@/api/mkt/topic.js';
import { getOssConfig } from '@/api/login.js';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('mkt-topic');
export default {
    components: { drawerDialog },
    data() {
        return {
            tableHead: [
                {
                    name: '话题名称', //表头名称
                    key: 'topicName', //数据列的key值
                },
                // { name: '所属分类', key: 'categoryName', width: '160' },
                { name: '来源类型', key: 'sourceType' },
                { name: '内容数量', key: 'contentCount' },
                // { name: '标签', key: 'labelName', width: '160' },
                { name: '创建人', key: 'createUserName' },
                { name: '创建时间', key: 'createTime' },
                // { name: '是否启用', key: 'enabled' },
                { name: '操作', key: 'control' },
            ],
            tableHeightCalc: 120,
            innerHeight: 0,
            isSearchDown: false,
        };
    },
    computed: {
        ...mapState(['list', 'page']), // // 映射 this.list 为 store.state.list
        ...mapMutations(['setParams']),
    },
    created() {
        this.$store.dispatch('mkt-topic/list');
        this.page.count = this.$store.state['mkt-topic'].page.count;
        this.getOssConfig();
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        });
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
    },
    methods: {
        init() {
            this.$store.dispatch('mkt-topic/list');
        },
        getOssConfig() {
            const params = {};
            getOssConfig(params)
                .then(res => {
                    if (res?.isSuccess) {
                        this.$store.commit('mkt-topic/setOssConfig', res.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'err');
                });
        },
        createTopic(title, row) {
            this.$refs.drawerDialog.openDrawer(title, row);
        },
        onChangeEnabled(row) {
            let data = {};
            data.topicId = row.topicId;
            data.enabled = row.enabled === 1 ? 1 : 0;
            onIsEnabled(data).then(res => {
                if (res?.isSuccess) {
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    });
                    this.$store.dispatch('mkt-topic/list');
                }
            });
        },
        ondeleteTopic(row) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    removeTopic({ topicId: row.topicId }).then(res => {
                        if (res?.isSuccess) {
                            this.$message({
                                type: 'success',
                                message: '删除成功',
                            });
                            this.$store.dispatch('mkt-topic/list');
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 关联笔记
        onArticle(row) {
            this.$router.push({
                path: '/mkt/cm/contact-article',
                query: { topicName: row.topicName, topicId: row.topicId },
            });
        },
        pageChange(val) {
            let params = { page: val };
            this.$store.commit('mkt-topic/setParams', params);
            this.$store.dispatch('mkt-topic/list');
            this.$nextTick(() => {
                // console.log('--------------pageChange', this.$refs.refTable.$refs['table-box']);
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            let params = { size: val };
            this.$store.commit('mkt-topic/setParams', params);
            // this.$store.dispatch('mkt-content-article/list');
            this.init();
        },
    },
};
</script>

<style>
.content {
    margin: 10px 15px;
}
.btn-style {
    color: #409eff;
    margin-right: 12px;
    cursor: pointer;
}
</style>
