<template>
    <div class="mkt" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        ></sc-search-v2>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <template v-slot:city>
                    <el-table-column label="活动城市">
                        <template slot-scope="scope">
                            {{ scope.row.city }}
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
    </div>
</template>

<script>
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import { getUserSysAppointmentList } from '@/api/mkt/user.js';
export default {
    name: 'mkt-management-index',
    mixins: [mixins, getListMixin],
    components: {},
    data() {
        return {
            tableHandle: getUserSysAppointmentList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '活动名称', key: 'activityName' },
                { name: '手机号码', key: 'mobile' },
                { name: '预约城市', key: 'city' },
                { name: '活动渠道', key: 'activityChannel' },
            ],
            columns: {
                mobile: {
                    name: '手机号码',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入手机号码',
                        options: [],
                        props: {},
                    },
                    spanCol: 1,
                },
                // province: {
                //     name: '预约省份',
                //     type: 'input',
                //     options: {
                //         defaultValueBase: '',
                //         placeholder: '请输入省份',
                //         options: [],
                //         props: {
                //             label: 'label',
                //             value: 'value',
                //         },
                //         filterable: true,
                //     },
                // },
                city: {
                    name: '预约城市',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入城市',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        filterable: true,
                    },
                },
            },
            result: {
                mobile: '',
                city: '',
                province: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },

    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
        this.getCompany();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        onEdit(row) {
            this.$refs.editDialog.open(row);
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
                this.page.index = 1;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
