import rq from '@/utils/https.js';
const $ajax = rq.requests;

export const getGroupRowList = params =>
    $ajax({
        url: '/content-collection/list',
        method: 'get',
        params,
    });
export const getRankingRowList = params =>
    $ajax({
        url: '/user-designer-collection/v3.0/list',
        method: 'get',
        params,
    });
export const getGroupList = params =>
    $ajax({
        url: '/content-collection/ref/list',
        method: 'get',
        params,
    });
// 内容分组排序
export const collectionSort = data =>
    $ajax({
        url: '/content-collection/sort',
        method: 'post',
        data,
    });
// 内容排序
export const contentSort = data =>
    $ajax({
        url: '/content-collection/content/sort',
        method: 'post',
        data,
    });
export const getRankingList = params =>
    $ajax({
        url: '/user-designer-collection/v3.0/ranking/list',
        method: 'get',
        params,
    });
// 新增合集分组
export const addGroupRow = data =>
    $ajax({
        url: '/content-collection/save',
        method: 'post',
        data,
    });
export const editGroupRow = data =>
    $ajax({
        url: '/content-collection/update',
        method: 'post',
        data,
    });
export const delGroupRow = params =>
    $ajax({
        url: '/content-collection/delete',
        method: 'get',
        params,
    });
// 新增合集
export const addGroup = params =>
    $ajax({
        url: '/content-collection/v3.0/ref/save',
        method: 'get',
        params,
    });
export const delGroup = params =>
    $ajax({
        url: '/content-collection/v3.0/ref/delete',
        method: 'get',
        params,
    });
// 新增设计师榜单分组
export const addRankingRow = data =>
    $ajax({
        url: '/user-designer-collection/v3.0/save',
        method: 'post',
        data,
    });
export const editRankingRow = data =>
    $ajax({
        url: '/user-designer-collection/v3.0/update',
        method: 'post',
        data,
    });
export const delRankingRow = params =>
    $ajax({
        url: '/user-designer-collection/v3.0/delete',
        method: 'get',
        params,
    });
// 新增设计师榜单
export const addRanking = params =>
    $ajax({
        url: '/user-designer-collection/v3.0/ranking/add',
        method: 'get',
        params,
    });
export const delRanking = params =>
    $ajax({
        url: '/user-designer-collection/v3.0/ranking/delete',
        method: 'get',
        params,
    });
// 设计师排序
export const sortRanking = data =>
    $ajax({
        url: 'user-designer-collection/v3.0/ranking/sort',
        method: 'post',
        data,
    });

// 合集详情内容新增
export const collectionRefSave = data =>
    $ajax({
        url: '/content-collection/ref/save',
        method: 'post',
        data,
    });

//内容编辑回显
export const efrUpdateEhco = params =>
    $ajax({
        url: '/content-collection/ref/update/ehco',
        method: 'get',
        params,
    });

// 内容编辑
export const collectionRefUpdate = data =>
    $ajax({
        url: 'content-collection/ref/update',
        method: 'post',
        data,
    });
// /content-collection/shelf
export const collectionShelf = params =>
    $ajax({
        url: '/content-collection/shelf',
        method: 'get',
        params,
    });
