<template>
    <div>
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :btn="btn"
            :LS="LS"
            :moreDown="moreDown"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:searchFileName>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">列表搜索：</div>
                    </template>
                    <div style="text-align: left; display: flex">
                        <el-select
                            size="small"
                            placeholder="请选择"
                            v-model="result.searchFileName"
                            style="width: 150px"
                            clearable
                        >
                            <template>
                                <el-option key="1" label="标签组名称" value="1"></el-option>
                                <!-- <el-option key="2" label="标签创建人" value="2"></el-option> -->
                            </template>
                        </el-select>
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="result.searchFileValue"
                            size="small"
                            style="width: 50%; margin-left: 10px"
                            clearable
                        ></el-input>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
export default {
    data() {
        return {
            columns: {
                searchFileName: {
                    name: '插槽占位',
                    type: 'text',
                    options: {
                        defaultValueBase: '',
                        placeholder: '',
                    },
                },

                empty: {
                    name: '',

                    spanCol: 1,
                },
                empty1: {
                    name: '',
                    spanCol: 1,
                },
                groupType: {
                    name: '类型',
                    type: 'select',
                    options: {
                        options: [
                            { label: '系统', value: '1' },
                            { label: '用户', value: '5' },
                            { label: '运营', value: '4' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                state: {
                    name: '状态',
                    type: 'select',
                    options: {
                        options: [
                            { label: '启用', value: '0' },
                            { label: '禁用', value: '1' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                searchFileName: '1',
                searchFileValue: '',
                groupType: '',
                state: '',
            },
            LS: { 'min-width': '200px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
        };
    },
    created() {
        this.result = {
            searchFileName: '1',
            searchFileValue: '',
            groupType: '',
            state: '',
        };
        this.$store.commit('mkt-tag-group/setParams', this.result);
    },
    methods: {
        changeFn() {
            let params = {
                ...this.result,
                state: Number(this.result.state),
            };
            this.$store.commit('mkt-tag-group/setParams', params); //同步方法保存
        },
        clickFn(e, type) {
            if (type == 'clear') {
                this.result = {
                    searchFileName: '1',
                    searchFileValue: '',
                    groupType: '',
                    state: '',
                };
            }
            let params = {
                ...this.result,
                state: this.result.state ? Number(this.result.state) : '',
            };
            this.$store.commit('mkt-tag-group/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-tag-group/list'); //异步方法保存
        },
    },
};
</script>
<style lang="scss" scoped></style>
