<template>
    <div class="tabs" ref="table">
        <el-tabs v-model="tabActive" @tab-click="tabClick">
            <el-tab-pane label="全部笔记" name="0"></el-tab-pane>
            <el-tab-pane label="我的草稿" name="1"></el-tab-pane>
            <!-- <el-tab-pane label="审核中" name="2"></el-tab-pane> -->
            <!-- <el-tab-pane label="审核通过" name="3"></el-tab-pane> -->
            <!-- <el-tab-pane label="审核退回" name="4"></el-tab-pane> -->
            <el-tab-pane label="审核设置" name="999" disabled>
                <span slot="label">
                    <span class="tabStyle" @click="setbtn">
                        <i class="el-icon-s-tools"></i>
                    </span>
                </span>
            </el-tab-pane>
            <el-tab-pane label="审核设置" name="888" disabled>
                <span slot="label">
                    <span style="margin-bottom: 15px; margin-left: 10px">
                        <el-button type="primary" size="mini" @click="handleAdd">
                            新增笔记
                        </el-button>
                    </span>
                </span>
            </el-tab-pane>
        </el-tabs>
        <div>
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="loading"
                :selection="false"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @sortChange="sortChange"
                :default-sort="sort"
                ref="refTable"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <!-- <template v-slot:title>
                    <el-table-column label="笔记名称" width="160">
                        <template slot-scope="scope">
                            【{{ scope.row.articleType | formatterArticleType }}】{{
                                scope.row.title
                            }}
                        </template>
                    </el-table-column>
                </template> -->
                <template v-slot:coverImage>
                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.coverImage"
                                style="width: 50px; height: 50px"
                                :src="scope.row.coverImage"
                                :preview-src-list="[scope.row.coverImage]"
                            ></el-image>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                </template>
                <!-- //插槽例子，操作列用插槽写入 -->
                <template v-slot:sourceType>
                    <el-table-column label="来源类型">
                        <template slot-scope="scope">
                            {{ scope.row.sourceType | parseSourceType }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:articleType>
                    <el-table-column label="类型" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.articleType | parseArticleType }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:noteType>
                    <el-table-column label="所属专题" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.noteType | parseNoteType }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:approvalStatus>
                    <el-table-column label="审核状态" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.approvalStatus | parseApprovalStatus }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:createUserName>
                    <el-table-column label="创建人" width="140">
                        <template slot-scope="scope">
                            {{ scope.row.createUserName
                            }}{{
                                scope.row.createUserAccount &&
                                    '（' + scope.row.createUserAccount + '）'
                            }}
                            <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                                {{ scope.row.createUserJob }}
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="170">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="handleExhibit(scope.row)"
                                size="mini"
                                v-if="scope.row.approvalStatus == 1"
                            >
                                审核
                            </el-button>
                            <el-button type="text" @click="handleAdd(scope.row)" size="mini">
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                @click="editArticle(scope.row)"
                                size="mini"
                                v-if="scope.row.approvalStatus == 3"
                            >
                                补充
                            </el-button>
                            <!-- 一期审核通过不做编辑 -->
                            <sc-qrcode-popover
                                :id="scope.row.articleId"
                                type="article"
                            ></sc-qrcode-popover>
                            <el-button
                                type="text"
                                @click="handleRejectOrPass(scope.row)"
                                size="mini"
                                style="margin-left:10px;"
                                v-if="
                                    scope.row.approvalStatus == 3 || scope.row.approvalStatus == 4
                                "
                            >
                                {{ scope.row.approvalStatus == 3 ? '驳回' : '通过' }}
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <drawer-dialog ref="drawerDialog" @closeDrawer="closeDrawer"></drawer-dialog>
        <add ref="addDrawer" @closeDrawer="closeDrawer"></add>
        <audit-settings
            :settingsShow="settingsShow"
            :workType="0"
            ref="settingsDialog"
            @onCloseSet="onCloseSet"
        ></audit-settings>
        <pass-dialog ref="passdialog" @closeDrawer="closeDrawer"></pass-dialog>

        <!-- 驳回备注弹窗 -->
        <dialog-remark ref="remark" @remarkBak="remarkBak"></dialog-remark>
    </div>
</template>

<script>
import add from './add.vue';

import drawerDialog from './details.vue';
import auditSettings from './audit-settings.vue';
import passDialog from './pass-dialog.vue';
import dialogRemark from './dialog-remark.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('mkt-content-article');
import { approval } from '@/api/mkt/article';
export default {
    components: { drawerDialog, auditSettings, passDialog, add, dialogRemark },
    data() {
        return {
            sort: { prop: '', order: '' }, //ascending,descending  如果只指定了prop, 没有指定order, 则默认顺序是ascending
            localRow: {},
            tabActive: 0,
            tableHead: [
                {
                    name: 'ID',
                    key: 'articleId',
                    width: '156px',
                },
                { name: '笔记名称', key: 'title' },
                { name: '封面', key: 'coverImage' },
                { name: '类型', key: 'articleType' },
                { name: '所属专题', key: 'noteType' },
                { name: '审核状态', key: 'approvalStatus' },
                // { name: '来源类型', key: 'sourceType' },
                // { name: '分类', key: 'categoryName', width: '160' },
                { name: '权重值', key: 'weightSort', sortable: true, width: '82px' },
                { name: '标签', key: 'labelName', width: '160' },
                { name: '关联话题', key: 'topicName', width: '160' },
                { name: '访问量', key: 'pageView' },
                { name: '点赞量', key: 'praise' },
                { name: '收藏量', key: 'favorite' },
                { name: '评论量', key: 'comment' },
                // { name: '退回原因', key: 'approvalRemarks', width: '160' },
                // { name: '运营标签', key: 'operationName' },
                { name: '创建人', key: 'createUserName' },
                // { name: '分公司', key: 'companyName', width: '240' },
                { name: '评级', key: 'contentLevelName' },
                { name: '最近提交时间', key: 'updateTime', width: '160' },
                { name: '最近审核时间', key: 'approvalTime', width: '160' },
                { name: '发布时间', key: 'createTime', width: '160' },

                { name: '操作', key: 'control' },
            ],
            tableHeightCalc: 120,
            innerHeight: 0,
            isSearchDown: false,
            settingsShow: false,
            loading: false,
        };
    },
    filters: {
        parseSourceType(val) {
            return val == 1 ? '外部' : '内部';
        },
        parseApprovalStatus(val) {
            return val == 1 ? '待审核' : val == 3 ? '审核通过' : val == 0 ? '草稿' : '审核退回';
        },
        parseArticleType(val) {
            return val == 1 ? '视频' : '图文';
        },

        formatterArticleType(val) {
            return val == 1 ? '视频' : '短图文';
        },
        parseNoteType(val) {
            const obj = {
                1: '工作剪影',
                2: '设计师说',
                3: '工地勘察',
                4: '生活点滴',
                5: '客户故事',
                6: '其他',
            };
            return obj[val] || '--';
        },
    },
    computed: {
        ...mapState(['list', 'page', 'params']), // 映射 this.list 为 store.state.list
        ...mapMutations(['setParams']),
    },
    created() {
        // let params = this.$store.state['mkt-content-article'].params;
        // params.page = 1;

        // this.$store.dispatch('mkt-content-article/list');
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$bus.$on('clearSearchArticle', data => {
            this.$refs.refTable.$refs['table-box'].clearSort();
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
    },
    methods: {
        // 通过/驳回
        handleRejectOrPass(row) {
            this.localRow = { ...row };
            if (row.approvalStatus == 3) {
                this.reject();
            } else {
                this.pass();
            }
        },
        reject() {
            this.$refs.remark.open();
        },
        remarkBak(data) {
            let params = {
                approvalStatus: 4, // 3通过 4 驳回
                articleId: this.localRow.articleId,
                approvalRemarks: data,
            };
            approval(params).then(res => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.init();
                }
            });
        },

        pass() {
            this.$refs.passdialog.openDrawer(this.localRow, 0, true);
        },
        editArticle(row) {
            this.$refs.passdialog.openDrawer(row, 1, true);
        },
        onCloseSet() {
            this.settingsShow = false;
        },
        setbtn() {
            this.settingsShow = true;
        },
        closeDrawer(type) {
            // 关闭弹窗
            if (type == 1) {
                this.tabActive = '1';
                let params = { isDraft: this.tabActive, page: 1 };
                this.$store.commit('mkt-content-article/setParams', params);
            }
            this.init();
        },
        async init() {
            let params = this.$store.state['mkt-content-article'].params;
            if (this.$route.query && this.$route.query.topicName) {
                params.keywords = this.$route.query.topicName;
                params.page = 1;
            } else {
                params.isDraft = this.tabActive;
            }
            this.$store.commit('mkt-content-article/setParams', params);
            this.getList();
        },
        async getList() {
            this.loading = true;
            await this.$store.dispatch('mkt-content-article/list');
            this.loading = false;
        },
        sortChange(e) {
            if (e.order == 'ascending') {
                this.sort.prop = 'weightSort';
                this.params.weightSortType = 3;
            } else if (e.order == 'descending') {
                this.sort.prop = 'weightSort';
                this.params.weightSortType = 2;
            } else {
                this.sort.prop = '';
                this.params.weightSortType = '';
            }
            this.init();
        },
        tabClick(tab, event) {
            this.tabActive = tab.name;
            let params = { isDraft: this.tabActive, page: 1 };
            this.$store.commit('mkt-content-article/setParams', params);
            this.init();
        },
        handleExhibit(row) {
            this.$refs.drawerDialog.openDrawer(row);
        },
        handleAdd(row) {
            this.$refs.addDrawer.openDrawer(row || '');
        },
        pageChange(val) {
            // 页码修改
            console.log(val, '分页修改');
            // this.page.index = val;
            let params = { page: val };
            this.$store.commit('mkt-content-article/setParams', params);
            // this.$store.dispatch('mkt-content-article/list');
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            console.log(val, '条数修改');
            this.page.size = val;
            let params = { size: val };
            this.$store.commit('mkt-content-article/setParams', params);
            // this.$store.dispatch('mkt-content-article/list');
            this.init();
        },
    },
};
</script>

<style scoped>
/deep/.el-dialog__body {
    padding: 0 20px !important;
}
.tabs {
    margin: 10px 15px;
}
.toexamiset {
    position: absolute;
}
/deep/#tab-1 {
    color: #000;
}
.tabStyle {
    cursor: pointer;
}
</style>
