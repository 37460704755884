<!-- 批量上传 -->
<template>
    <div></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped></style>
