<template>
    <div>
        <el-dialog
            title="补充信息"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :before-close="close"
            width="30%"
        >
            <sc-form-v2
                tablename="SUPPLE"
                :columnsNum="1"
                :result="result"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @removeTag="removeTag"
                ref="From2"
            >
                <template v-slot:labelsAll>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">标签:</div>
                        </template>
                        <div style="text-align: left">
                            <el-cascader
                                v-model="selfSelect"
                                :options="labelsAllOptions"
                                :props="props"
                                clearable
                                @change="changeSelect"
                                @remove-tag="removeSelect"
                                ref="selfSelect"
                                style="width:100%;"
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini" :loading="loading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mixins from '@/mixins';

import { info, approval, worksEdit } from '@/api/mkt/article';
let selOldData = {};
export default {
    name: 'dialog-remark',
    mixins: [mixins],
    data() {
        return {
            selfSelect: [],
            labelsAllOptions: [],
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            localRow: {},
            dialogFormVisible: false,
            form: {
                remark: '',
            },

            result: {
                labels: [],
                labelsAll: [],
                operationIdsAll: [],
                operationIds: [],
                categoryId: '',
                contentLevel: '',
                weightSort: null,
                rewardName: '',
                // rewardYears: '',
            },

            loading: false,
            columns: [
                {
                    key: 'categoryId',
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            emitPath: false,
                            checkStrictly: true,
                            value: 'value',
                            label: 'label',
                        },
                    },
                    // rules: [{ required: true, message: '请选择所属分类' }],
                },
                {
                    key: 'operationIdsAll',
                    name: '运营标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            // checkStrictly: true,
                            emitPath: false,
                            // multiple: true,
                        },
                    },
                },
                {
                    key: 'labelsAll',
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    // rules: [{ required: true, message: '请选择标签' }],
                },
                {
                    key: 'contentLevel',
                    name: '评级',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                            checkStrictly: true,
                            emitPath: false,
                        },
                    },
                },
                {
                    key: 'weightSort',
                    name: '权重值',
                    type: 'number',
                    options: {
                        placeholder: '请输入权重值',
                        clearable: true,
                        max: 9999,
                        min: 0,
                    },
                },
                {
                    key: 'rewardName',
                    name: '获奖标签标题',
                    type: 'input',
                    options: {
                        disabled: true,
                        placeholder: '请输入获奖标签标题',
                        clearable: true,
                    },
                },
                // {
                //     key: 'rewardYears',
                //     name: '获奖年/月',
                //     type: 'date',
                //     options: {
                //         dateType: 'month',
                //         format: 'yyyy/MM',
                //         valueFormat: 'yyyy/MM',
                //         placeholder: '请选择年/月',
                //         clearable: true,
                //     },
                // },
            ],
            levelList: [],
        };
    },
    watch: {
        cloumnsList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'categoryId') {
                        val.forEach(ele => {
                            ele.disabled = true;
                        });
                        item.options.options = val;
                    }
                });
            }
        },
        levelList(val) {
            console.log('-------levelList222222222222', val);
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'contentLevel') {
                        item.options.options = val;
                    }
                });
            }
        },
        normalLabelList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'labelsAll') {
                        const result = val.map(item => {
                            const children =
                                item.children?.length &&
                                item.children.map(every => {
                                    return {
                                        id: every?.id,
                                        labelGroupId: every?.labelGroupId,
                                        labelName: every?.labelName,
                                        parentId: every?.parentId,
                                        properties: every?.properties,
                                    };
                                });
                            return {
                                ...item,
                                children: children,
                            };
                        });
                        item.options.options = result;
                        this.labelsAllOptions = result;
                    }
                });
            }
        },
        operationListDis(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'operationIdsAll') {
                        console.log(val, 'operationIdsAll');
                        item.options.options = val;
                    }
                });
            }
        },
    },
    created() {
        this.getColumn();
        this.getOperationListDis();
        this.getLabelCommonList();
        this.getLevelList();
    },
    methods: {
        initSelData() {
            let selNowArr = [];
            Object.entries(selOldData).forEach(item => {
                const [label, value] = item;
                const labelArr = label.split(',');
                selNowArr.push([...labelArr, value]);
            });
            this.$nextTick(() => {
                this.selfSelect = selNowArr;
            });
        },
        changeSelect(val) {
            console.log(val, 'val--------');
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                const fatherValue = selOldData[fatherStr];
                if (fatherValue !== child) {
                    selOldData[fatherStr] = child;
                    break;
                }
            }
            this.initSelData();
        },
        removeSelect(val) {
            console.log(val, 'removeSelect');
            const fatherStr = val.slice(0, val.length - 1).join(',');
            delete selOldData[fatherStr];
            this.initSelData();
        },
        changeFn(val) {
            if (val.row.key == 'categoryId') {
                this.result.categoryName = val.nodeData && val.nodeData[0].label;
            }

            if (val.row.key == 'operationIdsAll') {
                console.log('operationIdsAll', val);

                if (this.result.operationIdsAll?.length > 20) {
                    this.$message.warning('最多选择20个标签');
                    this.result.operationIds = this.result.operationIdsAll.slice(0, 20);
                }
                this.result.operationIds = this.changeLabel(val.nodeData, this.deleteData);
            }
        },
        removeTag(val) {
            this.deleteData = val.value;
        },
        handleChangeCloumn(val) {
            this.result.categoryId = val;
        },
        changeLabel(arr, deleteData) {
            console.log(arr, deleteData);
            // 多选删除事件的时候需要手动删除处理
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理成后端需要的数据得数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        changeLabel2(arr, deleteData) {
            console.log(arr, deleteData, 'changeLabel22222------');

            // 多选删除事件的时候需要手动删除处理
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理成后端需要的数据得数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        open(row, type) {
            this.type = type;
            this.localRow = { ...row };
            console.log(this.localRow);
            selOldData = {};
            this.selfSelect = [];
            this.dialogFormVisible = true;
        },
        openDrawer(row, type, isEditRewardName) {
            this.type = type;
            this.dialogFormVisible = true;
            this.localRow = { ...row };
            this.result.weightSort = null;
            // this.result.rewardYears = null;
            if (row) {
                this.showInfo(row);
            }
            if (isEditRewardName) {
                this.columns[5].type = 'hidden';
            }
        },
        showInfo(row) {
            info({ articleId: row.articleId }).then(res => {
                this.besicResult = res.data;
                this.result.articleId = row.articleId;
                this.result.categoryId = res.data.categoryId || '';
                this.result.weightSort = res.data.weightSort || '';
                this.result.contentLevel =
                    row.contentLevel + '' || res.data.contentLevel + '' || '';
                // 标签数据回显
                // this.result.labelsAll = res?.data?.labels.map(item => {
                //     return item.id;
                // });
                // 运营标签数据回显
                if (res.data.operationLabels && res.data.operationLabels.length) {
                    this.result.operationIdsAll = res?.data?.operationLabels[0].id || '';
                }

                // id转成labelId
                this.result.labels = this.changLabelArr(res.data.labels);
                this.result.operationIds = this.changLabelArr(res.data.operationLabels);
                let tagList = [];
                console.log(res.data.labels, 'res.data.labels-------');
                res.data.labels.map(item => {
                    tagList.push(item.labelId);
                });
                console.log(tagList, 'tagList-----');
                let showTagList = [];
                this.labelsAllOptions.map(item => {
                    if (item.labelList && item.labelList.length) {
                        item.labelList.map(child => {
                            if (tagList.includes(child.id)) {
                                showTagList.push([item.id, child.id]);
                            }
                        });
                    }
                });
                // this.selfSelect = showTagList;
                // selOldData  在这里对 selOldData 进行赋值
                this.initSelectData(showTagList);
            });
        },
        initSelectData(val) {
            selOldData = {};
            this.selfSelect = [];
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                selOldData[fatherStr] = child;
            }
            this.initSelData();
        },
        // 处理后端需要的数据
        changLabelArr(arr) {
            if (arr.length > 0) {
                arr.map(item => {
                    item.labelId = item.id;
                    delete item.id;
                });
                return arr;
            } else {
                return [];
            }
        },
        submit() {
            this.loading = true;
            let arr = [];
            this.selfSelect.map(item => {
                return arr.push(item[1]);
            });
            console.log(arr, 'arr------');
            console.log(this.selfSelect, '99999', this.labelsAllOptions);
            let paramsArr = [];
            this.labelsAllOptions.map(item => {
                item &&
                    item.labelList &&
                    item.labelList.map(child => {
                        if (arr.includes(child.id)) {
                            paramsArr.push({
                                labelId: child.id,
                                labelName: child.labelName,
                                properties: child.properties,
                                groupType: item.groupType,
                            });
                        }
                    });
            });
            let params = {
                approvalStatus: 3, // 3 审核通过  4 , 审核退回
                categoryId: this.result.categoryId,
                labels: paramsArr,
                articleId: this.localRow.articleId,
                operationLabels: this.result.operationIds,
                // rewardYears: this.result.rewardYears,
                weightSort: this.result.weightSort,
            };
            if (this.result.contentLevel) {
                params.contentLevel = parseInt(this.result.contentLevel);
            }
            if (this.type == 0) {
                approval(params)
                    .then(res => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.$message.success('审核通过');
                            this.close();
                        } else {
                            this.$message.info(res.message);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                worksEdit(params)
                    .then(res => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.$message.success('编辑成功');
                            this.close();
                        } else {
                            this.$message.info(res.message);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        close() {
            this.dialogFormVisible = false;
            this.result.categoryId = '';
            this.result.categoryName = '';
            this.result.operationIds = [];
            this.result.labels = [];
            this.result.labelsAll = [];
            this.result.operationIdsAll = [];
            selOldData = {};
            this.selfSelect = [];
            this.$emit('closeDrawer');
        },
    },
};
</script>
