<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <div v-loading="drawerLoading">
                <sc-form-v2
                    style="padding-bottom: 0px"
                    name=""
                    tablename="ALLOCATION"
                    :columnsNum="1"
                    :columns="columns"
                    :result.sync="result"
                    :esayStyle="true"
                    direction="vertical"
                    :columnsPaddingRight="20"
                    @change="changeFn"
                    @click="clickFn"
                    ref="allocation"
                    :LS="{ 'min-widthWidth': '100%' }"
                ></sc-form-v2>

                <div style="position: relative;">
                    <div v-for="(item, index) in columns2" :key="index">
                        <div
                            style=" display: flex;justify-content: space-between; align-items: center;padding-right: 30px;"
                        >
                            <div>配置{{ index + 1 }}</div>
                            <div>
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="addGroup(index)"
                                    v-if="index + 1 == columns2.length"
                                >
                                    新增分组
                                </el-button>
                                <el-button
                                    v-if="columns2.length > 1 && index != 0"
                                    type="primary"
                                    size="small"
                                    @click="delGroup(index)"
                                >
                                    删除分组
                                </el-button>
                            </div>
                        </div>
                        <sc-form-v2
                            style="padding-top: 0px"
                            name=""
                            tablename="ALLOCATION_TOO"
                            :columnsNum="2"
                            :columns="item.columns"
                            :result.sync="item.result"
                            :esayStyle="true"
                            direction="vertical"
                            :columnsPaddingRight="20"
                            @change="changeFn"
                            @click="clickFn"
                            ref="allocationToo"
                            :LS="{ 'min-widthWidth': '100%' }"
                        ></sc-form-v2>
                    </div>
                </div>
                <uploadNew
                    ref="uploadbox"
                    :limitNum="1"
                    :width="90"
                    :height="90"
                    :accept="['jpg', 'gif', 'jpeg', 'png']"
                    :fileType="'image'"
                    :showImgList="cover"
                    @uploadImgList="uploadImgList"
                ></uploadNew>
                <div class="tips" style="font-size:12px;color:#606266;">
                    上传说明： 建议尺寸：375*308px，图片大小不超过2M
                </div>

                <div class="dialog-footer" style="margin-bottom: 30px;margin-top: 10px;">
                    <el-button size="large" @click="handleClose">
                        取消
                    </el-button>
                    <el-button size="large" type="primary" @click="handleSubmit">确定</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import uploadNew from '@/components/upload-new.vue';
import ossState from '@/utils/ossClient.js';
import { deepClone } from '@/utils/util.js';
import { columns2 } from './columns';
import {
    getWorksOptions,
    collectionSave,
    updateEcho,
    collectionUpdate,
} from '@/api/mkt/designer-ranking-quick';

export default {
    components: { uploadNew },
    data() {
        return {
            title: '抓取规则配置',
            drawer: false,
            result: {
                title: '',
                deputyTitle: '',
                contentLevel: '3',
            },
            columns: {
                title: {
                    name: '榜单标题',
                    type: 'input',
                    options: {
                        placeholder: '请输入榜单标题',
                        defaultValue: '',
                    },
                    rules: [{ required: true, message: '请输入榜单标题' }],
                },
                deputyTitle: {
                    name: '副标题',
                    type: 'input',
                    options: {
                        placeholder: '请输入副标题',
                        defaultValue: '',
                    },
                    rules: [{ required: true, message: '请输入副标题' }],
                },
                contentLevel: {
                    name: '评级',
                    type: 'radio',
                    options: {
                        placeholder: '请选择',
                        options: [
                            { label: '精选 ', dictValue: '1' },
                            { label: '无', dictValue: '3' },
                        ],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
            },
            columns2: columns2,
            selectArrData: [],
            cover: [],
            options: [],
            type: '',
            id: '',
            drawerLoading: false,
        };
    },
    created() {
        this.selectOptions();
    },
    mounted() {
        ossState.getOssClient();
    },
    methods: {
        selectOptions() {
            getWorksOptions().then(res => {
                console.log(res, 11111111111);
                if (res.data) {
                    this.options = res.data;
                    this.columns2[0].columns.style.options.options = res.data.styleVo;
                    this.columns2[0].columns.colorScheme.options.options = res.data.colorVo;
                    this.columns2[0].columns.houseType.options.options = res.data.houseLayoutVo;
                    this.columns2[0].columns.ownerDemand.options.options = res.data.equirement;
                    this.columns2[0].columns.designHighlights.options.options = res.data.highlight;
                    this.columns2[0].columns.label.options.options = this.setAILabel(
                        res.data.aiLable
                    );
                    // 处理aiLabel数据
                }
            });
        },
        setAILabel(data) {
            let keys = Object.keys(data);
            console.log(keys);
            const arr = [];
            keys.map((item, index) => {
                arr.push({
                    labelId: index,
                    labelName: item,
                    children: data[item],
                });
            });
            return arr;
        },
        openDrawer(row, type, title) {
            this.title = title;
            if (type == 'edit') {
                this.getUpdateEcho(row.id);
                this.type = 'edit';
            } else {
                let newColumns = deepClone(columns2);
                this.columns2 = newColumns;
                this.type = '';
                this.result.contentLevel = '3';
            }

            this.drawer = true;
        },
        getUpdateEcho(id) {
            this.drawerLoading = true;
            updateEcho({ id })
                .then(res => {
                    if (res.data) {
                        this.result.title = res.data.designerName;
                        this.result.deputyTitle = res.data.subtitle;
                        this.result.contentLevel =
                            (res.data.contentLevel && res.data.contentLevel.toString()) || '3';
                        this.id = res.data.id;

                        let columnsArr = [];
                        res.data.groupParamList?.forEach(item => {
                            // item.
                            let _ownerDemand = item.equirementType.map(items => items.dictValue);
                            let _designHighlights = item.highlightType.map(
                                items => items.dictValue
                            );
                            let _lable = item.lableList.map(d => d.id);
                            columnsArr.push({
                                columns: this.columns2[0].columns,
                                result: {
                                    style: item.styleId,
                                    colorScheme: item.colorId,
                                    houseType: item.houseType,
                                    area: item.area,
                                    // ownerDemand: [],
                                    // designHighlights: [],
                                    ownerDemand: _ownerDemand,
                                    designHighlights: _designHighlights,
                                    label: _lable,
                                },
                            });
                        });
                        console.log(columnsArr, 'columnsArr---------');
                        this.columns2 = columnsArr;
                        this.cover = [];
                        this.cover.push({
                            url: res.data.url,
                        });
                    }
                    this.drawerLoading = false;
                    // this.$message.success('修改成功');
                    // this.$emit('alloCationClose');
                })
                .catch(err => {
                    this.drawerLoading = false;
                });
        },
        handleClose() {
            this.drawer = false;
            this.resultInitialization();
        },
        uploadImgList(list, item) {
            this.cover = list || [];
            this.result.cover = list[0] && list[0].url;
        },
        handleSubmit() {
            let check = false;
            this.$refs.allocation.validateAll('ALLOCATION', res => {
                if (res) {
                    check = true;
                }
            });
            if (!check) return;
            if (this.cover.length == 0) {
                this.$message.error('图片封面必传');
                return;
            }
            let equirementOptions = [];
            this.options.equirement.forEach(i => {
                i.children.forEach(j => {
                    equirementOptions.push(j);
                });
            });

            let highlightOptions = [];
            this.options.highlight.forEach(i => {
                i.children.forEach(j => {
                    highlightOptions.push(j);
                });
            });
            let labelOptions = [];
            console.log('----------this.columns2[0]', this.columns2[0]);
            this.columns2[0].columns.label.options.options.forEach(i => {
                i.children.forEach(j => {
                    labelOptions.push(j);
                });
            });

            let equirementArr = [];
            let highlightArr = [];
            let lableList = [];
            this.columns2.forEach(item => {
                this.options.styleVo.forEach(i => {
                    if (i.labelId == item.result.style) {
                        item.result.styleObj = {
                            styleId: i.labelId,
                            styleName: i.labelName,
                        };
                    }
                });

                this.options.colorVo.forEach(i => {
                    if (i.labelId == item.result.colorScheme) {
                        item.result.colorObj = {
                            colorId: i.labelId,
                            colorName: i.labelName,
                        };
                    }
                });

                this.options.houseLayoutVo.forEach(i => {
                    if (i.dictValue == item.result.houseType) {
                        item.result._houseType = {
                            houseType: i.dictValue,
                            houseTypeName: i.sysLabel,
                        };
                    }
                });

                item.columns.area.options.options.forEach(i => {
                    if (i.labelId == item.result.area) {
                        item.result._area = i.labelId;
                    }
                });

                item.result.ownerDemand?.forEach(j => {
                    equirementOptions.forEach(i => {
                        if (i.dictValue == j) {
                            equirementArr.push({
                                dictName: i.sysLabel,
                                dictValue: i.dictValue,
                            });
                        }
                    });
                    item.result.equirementType = equirementArr;
                });

                item.result.designHighlights?.forEach(j => {
                    highlightOptions.forEach(i => {
                        if (i.dictValue == j) {
                            highlightArr.push({
                                dictName: i.sysLabel,
                                dictValue: i.dictValue,
                            });
                        }
                    });
                    item.result.highlightType = highlightArr;
                });
                item.result.designHighlights?.forEach(j => {
                    highlightOptions.forEach(i => {
                        if (i.dictValue == j) {
                            highlightArr.push({
                                dictName: i.sysLabel,
                                dictValue: i.dictValue,
                            });
                        }
                    });
                    item.result.highlightType = highlightArr;
                });
                // 标签
                item.result.label?.forEach(j => {
                    labelOptions.forEach(i => {
                        if (i.labelId == j) {
                            lableList.push({
                                id: i.labelId,
                                name: i.labelName,
                            });
                        }
                    });
                    item.result.lableList = lableList;
                });
                console.log('0---------------', item.result.label, lableList);
            });

            let params = {
                designerName: this.result.title,
                subtitle: this.result.deputyTitle,
                contentLevel: this.result.contentLevel,
                groupParamList: [],
                url: this.cover[0].url,
            };

            this.columns2?.forEach(item => {
                params.groupParamList.push({
                    area: item.result?._area,
                    colorId: item.result.colorObj?.colorId,
                    colorName: item.result.colorObj?.colorName,
                    equirementType: item.result.equirementType,
                    highlightType: item.result.highlightType,
                    houseType: item.result._houseType?.houseType,
                    houseTypeName: item.result._houseType?.houseTypeName,
                    styleId: item.result.styleObj?.styleId,
                    styleName: item.result.styleObj?.styleName,
                    lableList: item.result.lableList,
                });
            });

            console.log('提交--------------', params, this.type);
            if (this.type == 'edit') {
                params.id = this.id;
                collectionUpdate(params).then(res => {
                    if (res.isSuccess) {
                        this.$message.success('修改成功');
                        this.$emit('alloCationClose');
                        this.resultInitialization();
                    }
                });
                return;
            }

            collectionSave(params).then(res => {
                if (res.isSuccess) {
                    this.$message.success('提交成功');
                    this.$emit('alloCationClose');
                    this.resultInitialization();
                }
            });
            // this.$refs.allocation.validateAll('ALLOCATION_TOO', res => {
            //     if (res) {
            //         checkToo = true;
            //     }
            // });
            // if (check && checkToo) {
            //     console.log('提交', 22222222222222);
            // }
        },
        addGroup(index) {
            let obj = {};
            obj = JSON.parse(JSON.stringify(this.columns2[0]));
            obj.result = {};
            this.columns2.splice(index + 1, 0, obj);
        },
        delGroup(index) {
            this.columns2.splice(index, 1);
        },
        clickFn() {},
        changeFn(row) {},
        resultInitialization() {
            for (const iterator in this.result) {
                this.result[iterator] = '';
            }
            this.columns2.forEach(item => {
                item.columns = this.columns2[0].columns;
                item.result = {};
            });
            this.cover = [];
        },
    },
};
</script>
<style scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
