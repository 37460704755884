<template>
    <div>
        <sc-form-v2
            tablename=""
            name=""
            :columnsNum="1"
            :result="result"
            :columns="columns"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
            ref="articleFrom"
        >
            <template v-slot:k0>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">奖项</div>
                    </template>
                    <div style="text-align: left">
                        <el-checkbox-group v-model="result.k0">
                            <el-checkbox label="红玺杯"></el-checkbox>
                            <el-checkbox label="季度评优"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div>
                        <el-button type="text">奖项提报提示:</el-button>
                        如果希望额外添加「项目平面图 / 实景图 /
                        效果图」等资料，需在发案例页面的「空间展示和户型图」模块进行补充
                    </div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>
        <sc-form-v2
            tablename="填写资料"
            name="填写资料"
            :columnsNum="4"
            :result="result"
            :columns="columnsInfo"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="40"
            @change="changeFn"
            ref="infoFrom"
        ></sc-form-v2>
        <sc-form-v2
            tablename="填写资料"
            name="资料上传"
            :columnsNum="3"
            :result="result"
            :columns="columnsUpload"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="40"
            @change="changeFn"
            ref="uploadFrom"
        ></sc-form-v2>
    </div>
</template>
<script>
export default {
    name: 'add-award',
    data() {
        return {
            result: {
                k0: '',
                k1: '',
            },
            columns: {
                k0: {},
                k2: {
                    name: '选择案例',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择性别',
                        options: [
                            { label: '男', value: '男' },
                            { label: '女', value: '女' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                    spanCol: 1,
                },
            },
            columnsInfo: {
                k3: {
                    name: '设计师简介',
                    type: 'input',
                    options: {
                        placeholder: '请输入',
                        maxlength: 100,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入' }],
                    spanCol: 1,
                },
                k4: {
                    name: '项目名',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            { label: '男', value: '男' },
                            { label: '女', value: '女' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                    spanCol: 1,
                },
                k5: {
                    name: '详细地址',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                    spanCol: 1,
                },
                k6: {
                    name: '特色空间',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                    spanCol: 1,
                },
                k7: {
                    name: '作品简介',
                    type: 'input',
                    options: {
                        placeholder: '请输入',
                        maxlength: 100,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入' }],
                    spanCol: 1,
                },
                k8: {
                    name: '设计说明',
                    type: 'input',
                    options: {
                        placeholder: '请输入',
                        maxlength: 100,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入' }],
                    spanCol: 1,
                },
                k9: {},
                k10: {},
            },
            columnsUpload: {
                k1: {
                    key: 'topicCover',
                    name: '设计师个人照片',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip: '上传图片：JPG格式, 分辨率不低于300dpl',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                k2: {
                    key: 'topicCover',
                    name: '初步方案PPT',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传文件',
                        tip: '上传文件：PPT格式',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                k3: {
                    key: 'topicCover',
                    name: '深化方案PPT',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传文件',
                        tip: '上传文件：PPT格式',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                k4: {
                    key: 'topicCover',
                    name: '全套完整施工图',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传文件',
                        tip: '上传文件：CAD格式',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                k5: {
                    key: 'topicCover',
                    name: '施工过程照片',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip: '上传文件：JPG格式,不少于20张',
                        size: 2000,
                        fileSize: 2,
                        limit: 120,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                k6: {
                    key: 'topicCover',
                    name: '作品视频',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '上传视频: 1920*1080分辨率,模板(16:9)比例, MP4或MOV格式高清版,无字幕, 无水印, 无LOGO的作品空境',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
            },
        };
    },
    methods: {
        changeFn() {},
    },
};
</script>
