<template>
    <div>
        <el-dialog
            title="添加AI标签"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :before-close="close"
            width="30%"
        >
            <div class="wrap">
                <div v-if="list.length">
                    <el-tag
                        v-for="(item, ind) in list"
                        :key="ind"
                        :type="item.tagType"
                        style="margin-right:10px;margin-bottom:10px;min-width:60px;text-align: center;cursor: pointer;"
                        @click="handleClickTag(item, ind)"
                    >
                        {{ item.labelName }}
                    </el-tag>
                </div>
                <el-empty description="暂时没有AI标签" v-if="!list.length"></el-empty>
                <div>
                    <span>标签分组:</span>
                    <el-radio-group v-model="radio" style="margin-top:10px;">
                        <el-radio :label="item.id" v-for="(item, ind) in radioList" :key="item.id">
                            {{ item.groupName }}
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close" size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    @click="submit"
                    size="mini"
                    :loading="loading"
                    :disabled="!list.length"
                >
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mixins from '@/mixins';
import { getAIList, submitAI } from '@/api/mkt/status';
import { labellist } from '@/api/mkt/tag';
// let selOldData = {};
export default {
    name: 'dialog-remark',
    mixins: [mixins],
    data() {
        return {
            selfSelect: [],
            labelsAllOptions: [],
            localRow: [],
            dialogFormVisible: false,
            list: [],
            loading: false,
            radioList: [],
            radio: '',
        };
    },
    watch: {},
    created() {
        this.getRadioList();
    },
    methods: {
        open(arr) {
            this.localRow = arr;
            this.dialogFormVisible = true;
            this.init();
            this.radio = '';
        },
        submit() {
            console.log(this.radio);
            if (!this.radio) {
                this.$message.info('请选择标签分组');
                return;
            }
            let arr = [];
            this.list.map(item => {
                if (item.tagType == 'primary') {
                    item.labelGroupId = this.radio;
                    arr.push(item);
                }
            });
            if (!arr.length) {
                this.$message.info('请选择AI标签');
                return;
            }

            submitAI(arr).then(res => {
                console.log(res, '提交成功');
                this.close();
            });
        },
        close() {
            this.dialogFormVisible = false;
            this.$emit('closeDrawer');
        },
        init() {
            getAIList(this.localRow).then(res => {
                this.list = res.data.map(item => {
                    return {
                        ...item,
                        tagType: 'info',
                    };
                });
            });
        },
        handleClickTag(item) {
            item.tagType = item.tagType == 'primary' ? 'info' : 'primary';
        },
        getRadioList() {
            labellist().then(res => {
                this.radioList = res.data.children;
            });
        },
    },
};
</script>
