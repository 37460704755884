<template>
    <div>
        <el-drawer
            title="新增/编辑案例"
            :visible.sync="drawer"
            direction="rtl"
            size="70%"
            :destroy-on-close="true"
            :wrapperClosable="false"
            :close-on-press-escape="false"
        >
            <div class="input-box">
                <el-input
                    v-model="result.title"
                    placeholder="请输入案例标题"
                    maxlength="20"
                    show-word-limit
                    size="small"
                ></el-input>
            </div>
            <div class="table">
                <add-table :hasEdit="hasEdit" :workFiles="workFiles" ref="addTable"></add-table>
            </div>

            <div class="cover-box">
                <h2 style="margin:0 0 6px">设置封面</h2>
                <el-image
                    :src="result.cover"
                    fit="contain"
                    style="width:100px;height:100px"
                    v-if="result.cover"
                    @click="openClip('upload')"
                ></el-image>
                <i v-else class="el-icon-plus upload-icon" @click="openClip"></i>
            </div>
            <div class="bottom-box">
                <sc-form-v2
                    tablename="BESIC"
                    name="案例信息"
                    :columnsNum="1"
                    :result="result"
                    :columns="columns"
                    direction="vertical"
                    :esayStyle="true"
                    :columnsPaddingRight="20"
                    ref="articleFrom"
                    @change="handleChange"
                >
                    <template v-slot:authorId>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">作者</div>
                            </template>
                            <div style="text-align: left">
                                <el-select
                                    v-model="result.authorId"
                                    clearable
                                    placeholder="请选择"
                                    style="width:100%; padding-right: 20px;"
                                    size="small"
                                    @change="handleChangeAuthor"
                                    filterable
                                >
                                    <el-option
                                        v-for="item in authorIdLists"
                                        :key="item.userId"
                                        :label="
                                            item.createUserName +
                                                '（ ' +
                                                item.createUserAccount +
                                                ' ）'
                                        "
                                        :value="item.userId"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-descriptions-item>
                        >
                    </template>
                    <template v-slot:realEstate>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">楼盘地址</div>
                            </template>
                            <div style="text-align: left">
                                <el-select
                                    v-model="result.realEstate"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="请选择地址"
                                    :remote-method="getSearchAddress"
                                    :loading="addressLoading"
                                    size="mini"
                                    style="width:100%;padding-right: 20px;"
                                >
                                    <el-option
                                        v-for="item in poiList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.name"
                                        @click.native="clickAddress(item)"
                                    >
                                        <div>
                                            <span style="float: left">{{ item.name }}</span>
                                            <span
                                                style="float: right; color: #8492a6; font-size: 13px"
                                            >
                                                {{ item.cityname + item.adname + item.address }}
                                            </span>
                                        </div>
                                    </el-option>
                                </el-select>
                            </div>
                        </el-descriptions-item>
                    </template>
                </sc-form-v2>
                <div class="footer" style="text-align: right;margin:0px 30px 20px 0px;">
                    <el-button size="mini" @click="submit('1')" v-preventReClick="5000">
                        存草稿箱
                    </el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click="submit('0')"
                        v-preventReClick="5000"
                    >
                        发布案例
                    </el-button>
                </div>
            </div>
        </el-drawer>
        <set-cover
            ref="set-cover"
            :newClip="false"
            @coverImgUrl="getCoverImgUrl"
            :showImgList="showImgList"
        ></set-cover>
    </div>
</template>
<script>
import setCover from './components/set-cover.vue';
import { getWorksOptions, draft, worksSave } from '../../../../api/mkt/exhibit';
import { getDesignerList } from '@/api/mkt/article';
import { getMapSearch, getDictList } from '../../../../api/common';
// import clipImg from '../../../../components/clip-img.vue';
// import selfUpload from '../../../../components/upload.vue';
import addTable from './add-table.vue';
// import { formatTimeTwo } from '@/utils/util';
// import { oss } from '@/config.js';
import { getCookie } from '@/utils/util.js';
export default {
    name: 'add',
    components: { addTable, setCover },
    data() {
        return {
            drawer: false,
            authorIdLists: [],
            columns: {
                authorId: {
                    name: '作者',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'userId',
                            label: 'createUserName',
                        },
                        filterable: true,
                    },
                },
                styleVo: {
                    key: 'styles',
                    name: '风格',
                    type: 'radio',
                    options: {
                        options: [],
                        props: {
                            value: 'labelId',
                            label: 'labelName',
                        },
                    },
                },
                colorVo: {
                    key: 'title',
                    name: '色系',
                    type: 'radio',
                    options: {
                        options: [],
                        props: {
                            value: 'labelId',
                            label: 'labelName',
                        },
                    },
                },
                houseLayoutVo: {
                    key: 'houseLayoutVo',
                    name: '户型',
                    type: 'radio',
                    options: {
                        options: [],
                        props: {
                            value: 'houseId',
                            label: 'houseName',
                        },
                    },
                },
                area: {
                    name: '面积(㎡)',
                    type: 'input',
                    options: {
                        placeholder: '请输入面积',
                        inputType: 'number',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                },
                realEstate: {
                    name: '楼盘地址',
                    type: 'input',
                    options: {
                        placeholder: '请选择或输入地址',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                },
                projectCost: {
                    name: '项目费用',
                    type: 'input',
                    options: {
                        placeholder: '请输入项目费用',
                        inputType: 'number',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                },
                ownerDemand: {
                    name: '',
                    type: 'textarea',
                    options: {
                        placeholder: '请输入业主需求',
                        maxlength: 200,
                        showWordLimit: true,
                    },
                },
                designIdeas: {
                    name: '',
                    type: 'textarea',
                    options: {
                        placeholder: '请输入设计方案亮点',
                        maxlength: 200,
                        showWordLimit: true,
                    },
                },
            },
            result: { payTypes: '', cover: '', coverHeight: '', coverWidth: '' },
            local: { labels: [] },
            loading: false,
            addressLoading: false,
            mapAddress: '',
            poiList: [],
            topicList: [],
            showImgList: [], // 头像图片list
            workFiles: [],
            hasEdit: false, // 模式为编辑还是新增
            bpmProject: {},
            filesuffix: '',
            pic_limit_num: 30,
            isDesigner: null,
        };
    },
    created() {
        this.isDesigner = this.$store.state.login.isDesigner == 1 ? true : false;
        this.init();
        this.getDesignerList();
        this.getDictList();
        // this.getInfo();
    },
    methods: {
        handleChangeAuthor(val) {
            console.log(val, '00000000');
            this.result.authorId = val;
            this.result.authorAccount = this.authorIdLists.find(
                item => item.userId == val
            ).createUserAccount;
        },
        submit(isDraft) {
            let flag;
            if (isDraft == 1) {
                flag = this.saveDraftBox();
            } else {
                flag = this.releaseCaseVal();
            }
            if (!flag) return;

            let workFiles = this.getTableData();
            flag = this.validImgNumbers(workFiles);

            if (!flag) return;

            if (!this.result.coverHeight || !this.result.coverWidth) {
                let info = this.getImgInfo(this.result.cover);
                this.result.coverHeight = info.naturalHeight;
                this.result.coverWidth = info.naturalWidth;
            }

            const params = {
                articleSource: 1,
                authorAccount: this.result.authorAccount,
                ...this.result,
                authorAccountId: this.result.authorId,
                bpmProject: { area: this.result.area, ...this.bpmProject },
                labels: [...this.local.styleLabels, ...this.local.colorLabels],
                workFiles,
                isDraft: isDraft,
                houseLayout: this.result.houseLayoutVo,
            };
            worksSave(params).then(res => {
                const { isSuccess, message } = res;
                if (isSuccess) {
                    this.drawer = false;
                    this.$message.success(message);
                    if (isDraft == 1) {
                        //更新为草稿列表
                        this.$emit('closeAddDrawer', { name: '2' });
                    } else {
                        this.$store.dispatch('mkt-content-exhibit/list');
                    }
                }
            });
        },
        getImgInfo(cover) {
            var img = new Image();
            img.src = cover;
            let obj = {
                naturalWidth: img.naturalWidth,
                naturalHeight: img.naturalHeight,
            };
            return obj;
        },
        getCoverImgUrl(obj) {
            console.log(obj, 'url----');
            let { url, coverWidth, coverHeight } = obj;
            this.result.coverHeight = coverHeight;
            this.result.coverWidth = coverWidth;
            this.$set(this.result, 'cover', url);
        },
        hasAllReady(list) {
            let hasAll = false;
            list.forEach(item => {
                const groups = item.groupList;
                groups.forEach(group => {
                    if (group.picDescribe && !group.picList.length) {
                        hasAll = false;
                    }
                });
                groups.filter(item => {
                    if (item.picList?.length > 0) {
                        hasAll = true;
                    }
                });
            });
            return hasAll;
        },

        releaseCaseVal() {
            let _tableData = this.getTableData();
            console.log(_tableData, '999');

            let flag = this.hasAllReady(_tableData);
            console.log(flag, 'table校验');
            if (!flag) {
                this.$message.info('空间必传一张图片');
                return false;
            }
            if (!this.result.cover) {
                this.$message.info('封面必传');
                return false;
            }
            if (!this.result.authorId) {
                this.$message.warning('作者不能为空');
                return false;
            }
            if (!this.result.styleVo) {
                this.$message.warning('风格不能为空');
                return false;
            }
            if (!this.result.colorVo) {
                this.$message.warning('色系不能为空');
                return false;
            }
            if (!this.result.houseLayoutVo) {
                this.$message.warning('户型不能为空');
                return false;
            }
            if (!this.result.area) {
                this.$message.warning('面积不能为空');
                return false;
            }
            if (!this.result.realEstate) {
                this.$message.warning('楼盘地址不能为空');
                return false;
            }
            if (!this.result.title) {
                this.$message.warning('案例标题不能为空');
                return false;
            } else {
                return true;
            }
        },
        validImgNumbers(arr) {
            // console.log('-----------countEvenNumbers', arr);
            let number = 0;
            let flag = false;
            arr.map(item => {
                if (Array.isArray(item.groupList)) {
                    // 如果当前项是数组，递归调用 countEvenNumbers
                    item.groupList.map(group => {
                        number += (group.picList && group.picList.length) || 0;
                    });
                }
            });
            // console.log('-----------countEvenNumbers', number);
            if (number > this.pic_limit_num) {
                this.$message.warning(`案例中图片不能超过${this.pic_limit_num}张`);
                flag = false;
            } else {
                flag = true;
            }
            return flag;
        },
        saveDraftBox() {
            if (!this.result.cover) {
                this.$message.info('封面必传');
                return false;
            }
            return true;
        },
        getDesignerList() {
            getDesignerList().then(res => {
                if (res?.isSuccess) {
                    if (this.isDesigner) {
                        let account = getCookie('account');
                        this.authorIdLists = [
                            res.data.find(item => item.createUserAccount == account),
                        ];
                        return;
                    }
                    this.authorIdLists = res.data;
                }
            });
        },
        clickAddress(item) {
            if (item.name) {
                this.mapAddress = item.name;
                this.result.location = item.name;
                this.result.locationCity = item.cityname;
                this.bpmProject = {
                    city: item.cityname,
                    province: item.pname,
                    regions: item.adname,
                    projectAddress: item.address,
                };
            }
        },

        handleChange(row) {
            if (row.row.key == 'authorId') {
                let obj = this.columns.authorId.options.options.find(
                    item => item.userId == row.value
                );
                this.result.authorAccount = obj.createUserAccount;
                this.result.authorAccountId = row.value;
            }

            if (row.row.key == 'colorVo') {
                let _colorObj = this.columns.colorVo.options.options.find(
                    item => item.labelId == row.value
                );

                this.local.colorLabels = [_colorObj];
                console.log(
                    '--------colorVo this.local.colorLabels',
                    this.local.colorLabels,
                    _colorObj
                );

                return;
            }
            if (row.row.key == 'styleVo') {
                let _styleObj = this.columns.styleVo.options.options.find(
                    item => item.labelId == row.value
                );
                this.local.styleLabels = [_styleObj];
                console.log('--------styleVo this.local.styleLabels', this.local.styleLabels);

                return;
            }
        },
        getTableData() {
            return this.$refs.addTable.getTableData();
        },
        open(row) {
            if (row && row.id) {
                this.draft(row.id);
                this.hasEdit = true;
            } else {
                this.hasEdit = false;
            }
            this.drawer = true;
            this.result.cover = '';
            this.result = {};
            this.local.styleLabels = [];
            this.local.colorLabels = [];
        },
        // 获取反显信息
        draft(id) {
            draft({ id }).then(res => {
                this.result = {
                    ...res.data,
                    styleVo: res.data.styleLabelId,
                    colorVo: res.data.colorLabelId,
                    houseLayoutVo: res.data.houseLayout,
                    area: res.data.bpmProject.area,
                    realEstate: res.data.realEstate,
                    authorId: res.data.authorAccountId,
                };
                this.workFiles = res.data.workFiles;
                if (res.data.styleLabelId) {
                    let _styleObj = this.columns.styleVo.options.options.find(
                        item => item.labelId == res.data.styleLabelId
                    );
                    this.local.styleLabels.push(_styleObj);
                }
                if (res.data.colorLabelId) {
                    let _colorObj = this.columns.colorVo.options.options.find(
                        item => item.labelId == res.data.colorLabelId
                    );
                    this.local.colorLabels.push(_colorObj);
                }
                if (res.data.authorAccountId) {
                    let obj = this.authorIdLists.some(
                        item => item.userId == res.data.authorAccountId
                    );
                    if (!obj) this.result.authorId = '';
                }
            });
        },
        openClip(type) {
            let _list = this.getTableData();
            let _arr = [];
            _list.map(item => {
                if (item.groupList?.length) {
                    item?.groupList.map(group => {
                        if (group.picList?.length) {
                            group.picList.map(img => {
                                _arr.push(img.url);
                                return;
                            });
                        }
                    });
                }
            });
            this.showImgList = _arr;
            // if (!this.showImgList.length) {
            //     this.$message.info('请先上传图片');
            //     return;
            // }
            this.$refs['set-cover'].open(this.showImgList, this.result.cover);
        },
        init() {
            getWorksOptions().then(res => {
                this.columns.styleVo.options.options = res.data.styleVo;
                this.columns.houseLayoutVo.options.options = res.data.houseLayoutVo;
                this.columns.colorVo.options.options = res.data.colorVo;
            });
        },
        // 获取校验字段值-图片数量
        getDictList() {
            getDictList({ parentId: '217' }).then(res => {
                if (res?.isSuccess) {
                    let item = res.data.find(d => d.dictName == 'pic_limit_works'); //案例pic_limit_works，笔记pic_limit_pic_limit_article
                    this.pic_limit_num = parseInt(item.dictValue || 0);
                    // console.log('pic_limit_num', item.dictValue);
                }
            });
        },
        getSearchAddress(query) {
            if (query !== '') {
                let params = { keywords: query, city: '北京' }; //默认给北京
                this.addressLoading = true;
                this.mapAddress = query;
                getMapSearch(params).then(res => {
                    if (
                        res.errorCode == '200' &&
                        res.data &&
                        res.data.status == '1' &&
                        res.data.info === 'OK'
                    ) {
                        this.addressLoading = false;
                        let poiList = JSON.parse((res.data && res.data.pois) || '[]');
                        let poiObj = {};
                        let newPoiList = [];
                        poiList.map(d => {
                            if (!poiObj[d.name]) {
                                newPoiList.push(d);
                                poiObj[d.name] = true;
                            }
                        });
                        this.poiList = newPoiList;
                    } else {
                        this.$message.info(res.message);
                    }
                });
            } else {
                this.poiList = [];
                this.mapAddress = '';
            }
        },
    },
};
</script>
<style lang="less" scoped>
.input-box {
    padding: 0 30px;
    margin: 20px 0 0;
}
.cover-box {
    margin: 24px 0 40px 30px;
}
.bottom-box {
    padding-left: 20px;
}
/deep/ .el-radio {
    margin-bottom: 10px;
    width: 80px;
}
.upload-icon {
    font-size: 26px;
    border: 1px solid #d7dbe0;
    border-radius: 3px;
    padding: 36px;
    color: #8c939d;
    cursor: pointer;
}
</style>
