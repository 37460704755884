<template>
    <div v-if="dialogFormVisible">
        <el-drawer
            title="添加banner"
            :visible.sync="dialogFormVisible"
            width="30%"
            :wrapperClosable="false"
            :before-close="close"
            destroy-on-close
        >
            <div class="content-wrap">
                <sc-form-v2
                    tablename="SUPPLE"
                    :columnsNum="1"
                    :result="result"
                    :columns="columns"
                    direction="vertical"
                    :esayStyle="true"
                    :columnsPaddingRight="20"
                    @change="changeFn"
                    :uploadConfig="uploadConfig"
                    ref="From2"
                ></sc-form-v2>
                <div class="dialog-footer">
                    <el-button @click="close" size="mini">取 消</el-button>
                    <el-button
                        type="primary"
                        @click="submit"
                        size="mini"
                        :loading="loading"
                        v-preventReClick="5000"
                    >
                        确 定
                    </el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import mixins from '@/mixins';
import { uploadConfigData } from '@/config.js';
import { saveOrUpdateBanner, getContentById, getTab } from '@/api/mkt/recommend-banner';
export default {
    name: 'dialog-add',
    mixins: [mixins],
    data() {
        return {
            uploadConfig: {},
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            localRow: {},
            dialogFormVisible: false,
            contentTypeOut: ['1', '4', '5', '12', '13', '14', '15'],
            result: {
                linkType: '',
                id: '',
                recommendId: '',
                defOrder: '',
                contentType: 0,
                link: '',
                url: [],
                pageLocation: null,
            },
            loading: false,
            columns: {
                pageLocation: {
                    name: 'banner位置',
                    type: 'radio',
                    options: {
                        placeholder: '请选择',
                        clearable: true,
                        options: [
                            // { label: '原有banner', value: 0 },
                            { label: '顶部banner', value: 1 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    rules: [{ required: true, message: '请选择banner位置' }],
                },
                contentType: {
                    key: 'contentType',
                    name: '跳转类型',
                    type: 'select',
                    hidden: false,
                    options: {
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请选择跳转类型' }],
                },
                link: {
                    key: 'link',
                    name: '跳转链接',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入跳转链接' }],
                },
                contentName: {
                    key: 'content',
                    name: '内容名称',
                    type: 'input',
                    // disabled: true,
                    options: {
                        placeholder: '',
                        // disabled: true,
                        showWordLimit: false,
                    },
                },
                defOrder: {
                    key: 'defOrder',
                    name: '排序',
                    type: 'number',
                    options: {
                        placeholder: '',
                        min: 0,
                        max: 9999,
                        showWordLimit: false,
                    },
                    rules: [{ required: true, message: '请输入排序' }],
                },
                recommendId: {
                    key: 'recommendId',
                    name: 'tab分类',
                    type: 'hidden',
                    // type: 'select',
                    options: {
                        placeholder: '请选择tab分类',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'recName',
                            value: 'id',
                        },
                    },
                    rules: [{ required: true, message: '请选择tab分类' }],
                },
                url: {
                    key: 'url',
                    name: '上传',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：375*355像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                        fileList: [],
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
            },
        };
    },
    watch: {},
    created() {
        this.getOssConfig();
        this.getTab();
    },
    methods: {
        getTab() {
            getTab().then(res => {
                this.columns.recommendId.options.options = res.data;
            });
        },
        changeFn(val) {
            if (val.row.key == 'pageLocation') {
                if (val.value == 0) {
                    this.columns.recommendId.type = 'select';
                } else {
                    this.columns.recommendId.type = 'hidden';
                }
                this.result.recommendId = '';
            }
            if (val.row.key == 'contentType') {
                this.$set(this.result, 'link', '');
            }
            if (
                val.row.key == 'link' &&
                val.value &&
                !this.contentTypeOut.includes(this.result.contentType)
            ) {
                let lastIndex = val.value.lastIndexOf('/');
                let id = val.value.slice(lastIndex + 1);
                if (/\D/.test(id)) {
                    this.$message.info('内容id只能输入数字');
                    return;
                }
            }
            if (val.row.key == 'contentType' && val.value) {
                let list = this.columns.contentType.options.options;
                let value = list.find(item => item.dictValue == val.value).description || '';
                this.$set(this.result, 'link', value);
            }
        },

        open(row, options) {
            this.dialogFormVisible = true;
            this.columns.contentType.options.options = options;
            //不需要内容id的类型
            if (row != 'add') {
                this.result = { ...row };
                this.result.contentType = String(row.contentType);
                this.result.url = [{ url: row.path }];
                this.columns.recommendId.type = row.pageLocation == 0 ? 'select' : 'hidden';
            } else {
                this.result = {
                    id: '',
                    recommendId: '',
                    defOrder: '',
                    contentType: '',
                    link: '',
                    url: [],
                };
            }

            this.$nextTick(() => {
                uploadConfigData.oss.accessKeyId = this.ossConfig.stsKeyId;
                uploadConfigData.oss.accessKeySecret = this.ossConfig.stsSecret;
                uploadConfigData.oss.stsToken = this.ossConfig.token;
                this.uploadConfig = uploadConfigData;
            });
        },
        submit() {
            let params = { ...this.result };
            this.$refs.From2.validateAll('SUPPLE', async () => {
                params.path = this.result.url[0].url;
                params.url = null;
                this.loading = true;
                if (!params.id) {
                    delete params.id;
                }
                let res = await saveOrUpdateBanner(params);
                this.$message.success(res.message);
                this.close();
                this.loading = false;
            });
        },
        close() {
            this.dialogFormVisible = false;
            this.result = {
                id: '',
                recommendId: '',
                defOrder: '',
                contentType: '',
                url: [],
                link: '',
            };
            this.$emit('closeDrawer');
        },
    },
};
</script>
<style lang="less" scoped>
.dialog-footer {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
/deep/ .el-descriptions-item__cell {
    padding-bottom: 15px !important;
}
.content-wrap {
    padding: 0 0 0 20px;
}
</style>
