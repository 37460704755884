<template>
    <div class="mkt archives-container flex flex-col" style="height: calc(100vh - 60px)">
        <div class="flex-shrink">
            <sc-search-v2
                :columns="columns"
                :result="result"
                :btn="{
                    twoShow: true,
                }"
                :more-down="true"
                :LS="LS"
                :CS="CS"
                @click-more="setTableHeight"
                @change="searchChange"
                @search="searchSearch"
            >
                <!-- <template v-slot:dateRange></template> -->
            </sc-search-v2>
        </div>
        <!-- :default-sort="{ prop: 'LastAuditTime', order: 'descending' }" -->
        <sc-table-v2
            class="list-table flex-1 flex flex-col"
            row-key="id"
            :head="tableHead"
            :data="listData"
            :page="page"
            :load="isLoading"
            :tableHeight="tableHeight"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @sortChange="sortChange"
        >
            <template v-slot:shareStatus>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.shareStatus == 1" style="color:green">
                            启用中
                        </span>
                        <span v-else style="color:red">禁用中</span>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:imgUrl>
                <el-table-column label="头像">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.imgUrl"
                            fit="contain"
                            style="width: 50px; height: 50px"
                            :src="scope.row.imgUrl"
                            :preview-src-list="[scope.row.imgUrl]"
                        ></el-image>
                        <!-- <span v-else>-</span> -->
                    </template>
                </el-table-column>
            </template>
            <template v-slot:customerStatus>
                <el-table-column label="角色" minWidth="90">
                    <template slot="header">
                        <span>角色</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="客户登录的手机号和bpm库进行比对，库里有为老潜客，无则是新潜客"
                            placement="top"
                        >
                            <i class="el-icon-question table-header-icon"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.customerStatus }}
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="162">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="clickControlBtn(scope.row)">
                            用户档案
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="openTestAccountDialog(scope.row)"
                        >
                            查看行为明细
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <testAccountDialog ref="testAccountDialog"></testAccountDialog>
    </div>
</template>
<script>
import lodash from 'lodash';
import getListMixin from '@/mixins/getListMixin';
import mixins from '@/mixins';

import { getShareUserList } from '@/api/mkt/user';
import testAccountDialog from './components/testAccount-dialog';
import { getDossierList } from '@/api/mkt/user';

// 用户ID、昵称、手机号、近30天访问次数、身份、角色、是否首次、活跃时间、工具测试、IP地址、登录所属页面、最近预约时间、最近访问时间、首次登录时间、来源人、
const pickerOptions = {
    disabledDate(time) {
        let nowDate = new Date();
        var year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1;
        let date = nowDate.getDate();
        let nowTimer = new Date(`${year}/${month}/${date}`).getTime();
        if (time.getTime() > nowTimer) {
            return true;
        }
        return false;
    },
};
const columns = {
    searchName: {
        name: '搜索',
        type: 'input',
        options: {
            clearable: true,
            defaultValueBase: '',
            placeholder: '请输入用户id/设备号/昵称进行查询',
        },
        spanCol: 2,
    },
};
const initResult = {
    searchName: '',
};
export default {
    name: 'user-archives-list',
    components: { testAccountDialog },
    mixins: [getListMixin, mixins],
    data() {
        return {
            columns: columns,
            result: {
                searchName: '',
                sharingRecordsId: '',
            },
            tableHandle: getShareUserList,
            LS: {
                'min-width': '100px',
                width: '100px',
                padding: 0,
                verticalAlign: 'bottom',
            },
            CS: {
                'min-width': '180px',
            },
            dateRange: {
                name: '访问时间范围',
                type: 'date',
                key: 'dateRange',
                options: {
                    clearable: true,
                    dateType: 'daterange',
                    defaultValueBase: '',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    format: 'yyyy-MM-dd',
                    valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    placeholder: '请选择',
                    pickerOptions: pickerOptions,
                },
                spanCol: 2,
            },
            tableHead: [
                { name: '用户ID', key: 'readId', minWidth: 180 },
                { name: '昵称', key: 'nickName', minWidth: 100, showOverflowTooltip: true },
                {
                    name: '头像',
                    key: 'imgUrl',
                    minWidth: 280,
                    showOverflowTooltip: true,
                },
                {
                    name: '装修状态',
                    key: 'decorationStatus',
                    minWidth: 108,
                    formatter: this.dStatusFormatter,
                },
                { name: '近30天访问次数', key: 'pv', minWidth: 110 },
                { name: '身份', key: 'identity' },
                { name: '角色', key: 'customerStatus', minWidth: 90 },
                { name: '工具测试', key: 'tool', minWidth: 150, showOverflowTooltip: true },
                { name: '地域', key: 'ipRegion', minWidth: 150, showOverflowTooltip: true },
                { name: '最近预约时间', key: 'appointmentTime', minWidth: 150 },

                {
                    name: '分享人',
                    key: 'shareName',
                    formatter: this.shareNameFormatter,
                    minWidth: 150,
                },
                { name: '首次查看时间', key: 'createTime', minWidth: 150 },
                // {
                //     name: '是否真实用户',
                //     key: 'testAccount',
                //     minWidth: 94,
                //     formatter: this.testAccountFormatter,
                // },
                { name: '操作', key: 'control' },
            ],
        };
    },
    watch: {
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
    },
    created() {},
    async mounted() {
        this.setTableHeight();
        // 下拉数据集合
        this.optionsData = {};
        await this.getDossierListData();
        console.log(
            '-------this.$route.query.sharingRecordsId',
            this.$route.query.sharingRecordsId
        );
        this.result.sharingRecordsId = this.$route.query.sharingRecordsId || '';
        this.queryDataMixin();
        window.addEventListener('resize', this.windowResize, false);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResize, false);
    },
    methods: {
        windowResize: lodash.debounce(function() {
            this.setTableHeight();
        }, 200),
        openTestAccountDialog(row) {
            this.$refs.testAccountDialog.open(row);
        },
        filterTableData(list) {
            if (list.length === 0) {
                return list;
            }
            for (let item of list) {
                const roleData = this.optionsData['identity'].find(
                    val => val.dictValue == item.identity
                );
                item.identity = roleData?.sysLabel;
                item.customerStatus = roleData?.children?.find(
                    val => val.dictValue == item.customerStatus
                )?.sysLabel;
                if (Array.isArray(item.tool) && item.tool.length > 0) {
                    item.tool = item.tool
                        .map(val => {
                            return this.optionsData['tool'].find(val2 => val2.dictValue == val)
                                ?.sysLabel;
                        })
                        .join('/');
                } else {
                    item.tool = '';
                }
            }
            return list;
        },
        getDossierListData() {
            return new Promise(resolve => {
                getDossierList({}).then(res => {
                    console.log(res);
                    if (res.code === 200 && res.isSuccess) {
                        const map = {
                            identity: 'identification', // 身份角色
                            tool: 'toolsVo', // 工具测试
                            appointment: 'reservationVo', // 预约咨询
                            device: 'terminalVo', // 对应列表terminal
                            terminal: 'terminalVo',
                            timeFrame: 'timeVo', // 已废弃 改为选择时间范围
                        };
                        let obj = {};
                        for (let key in map) {
                            const value = map[key];
                            obj[key] = res.data[value];
                            if (this.columns[key]) {
                                this.columns[key].options.options = obj[key];
                            }
                        }
                        this.optionsData = obj;
                        resolve();
                    }
                });
            });
        },

        dStatusFormatter(row) {
            let obj = { 173: '即将要装修', 174: '正在装修中', 175: '不需要装修' };
            return obj[row.decorationStatus] || '';
        },
        shareStatusFormatter(row) {
            let obj = { 1: '启用中', 0: '禁用中' };
            console.log('-----', obj[row.shareStatus], row.shareStatus);
            return obj[row.shareStatus] || '禁用中';
        },
        shareNameFormatter(row) {
            let name = `${row.shareName || ''}${(row.shareName && row.mobile && '/') ||
                ''}${row.mobile || ''}`;
            return name || '';
        },
        loginPageFormatter(row) {
            return (
                (row.loginPage &&
                    row.loginPage + (row.loginSecondPage && '-' + row.loginSecondPage)) ||
                ''
            );
        },
        promotionFormatter(row) {
            let result = row.promotionChannel;
            if (row.promotionChannel == 'sczs-offiaccount') {
                result = '尚层装饰公众号';
            }
            return result;
        },
        getClientHeight() {
            var clientHeight = 0;
            if (document.body.clientHeight && document.documentElement.clientHeight) {
                clientHeight =
                    document.body.clientHeight < document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            } else {
                clientHeight =
                    document.body.clientHeight > document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            }
            return clientHeight;
        },
        setTableHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    // 设置table高度
                    const name = '.list-table';
                    let tableDom = document.querySelector(name);
                    if (tableDom) {
                        let topNum = tableDom.getBoundingClientRect().top;
                        let mainNum = this.getClientHeight();
                        this.tableHeight = mainNum - topNum - 47;
                    }
                }, 0);
            });
        },
        // 点击table 操作按钮
        clickControlBtn(row) {
            let url = `${location.origin}/#/mkt/user/archives/detail?accountId=${
                row.readId
            }&ipRegion=${row.ipRegion && row.ipRegion.trim()}&customerStatus=${
                row.customerStatus
            }&identity=${row.identity}&terminalIdentification=${row.terminalIdentification}`;
            console.log('-----------row', url);

            window.open(url, '_blank');
        },
        searchChange(val) {
            console.log(val);
            this.page.page = 1;
            this.queryDataMixin();
        },
        searchSearch(val, type) {
            console.log(val, type);
            if (type === 'clear') {
                this.result = JSON.parse(JSON.stringify(initResult));
            }
            this.page.page = 1;
            this.queryDataMixin();
        },
        sortChange({ column, prop, order }) {
            console.log(column, prop, order);
            if (order == 'ascending') {
                // 上升
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 1;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 3;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 5;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 7;
                        break;
                    default:
                        break;
                }
            } else {
                // 下降
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 2;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 4;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 6;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 8;
                        break;
                    default:
                        break;
                }
            }
            this.pageChange(1);
        },
    },
};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.flex-align-center {
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.flex-col {
    flex-direction: column;
}
.flex-shrink {
    flex-shrink: 0;
}
.mkt {
    min-height: auto;
}
.archives-container {
    height: calc(100vh - 60px);
    background: #fff;
    overflow: hidden;
}

.sum-number-bar {
    margin: 15px 25px;
    padding: 15px 10px;
    background: #f8f8f8;
    font-size: 12px;
    .label {
        font-weight: bold;
        flex-shrink: 0;
    }
    .value {
        margin-left: 10px;
        justify-content: space-around;
        max-width: 867px;
    }
    .value-item {
        margin-right: 15px;
    }
}
.list-table {
    margin: 0 15px;
    padding-top: 0;
}
/deep/ .el-form .el-descriptions__body .el-descriptions-item__content {
    padding-bottom: 0;
}
/deep/ .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    padding-right: 0;
}
/deep/ .searchMain .button-box {
    margin-left: 10px;
}
/deep/ .el-icon-question {
    font-size: 14px;
    &.table-header-icon {
        margin-left: 2px;
    }
}
/deep/ .hide-label {
    display: none;
}
</style>
