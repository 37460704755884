<template>
    <div class="mkt">
        <Search></Search>
        <Content></Content>
    </div>
</template>

<script>
import Search from './search';
import Content from './content';

export default {
    name: 'topic-index',
    components: { Search, Content },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-topic/clear');
    },
};
</script>
<style scoped>
.title-t {
    padding-top: 10px;
    padding-left: 20px;
    font-size: 18px;
}
</style>
