<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="false"
            :close-on-press-escape="false"
            :destroy-on-close="true"
        >
            <sc-form-v2
                style="padding-bottom: 0px"
                name=""
                tablename="INSERT"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="insertForm"
                :LS="{ 'min-widthWidth': '100%' }"
            >
                <template v-slot:contentId>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                <span style="color:red;">*</span>
                                内容ID
                            </div>
                        </template>
                        <div
                            style="display: flex;justify-content: space-between;padding-right: 20px;"
                        >
                            <el-input
                                style="width: 80%;"
                                v-model="result.contentId"
                                placeholder="请输入案例标题"
                                maxlength="30"
                                show-word-limit
                                size="small"
                                :disabled="true"
                            ></el-input>
                            <el-button
                                style=""
                                size="mini"
                                type="primary"
                                @click="onOpenContent"
                                :disabled="!result.contentType || type == 'edit'"
                            >
                                选择内容
                            </el-button>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <sc-form-v2
                style="padding-bottom: 0px"
                name=""
                tablename="INSERT1"
                :columnsNum="2"
                :columns="columnsToo"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="insertForm1"
                :LS="{ 'min-widthWidth': '100%' }"
            >
                <template v-slot:contentCover>
                    <el-descriptions-item>
                        <div style=" margin-bottom: 10px;display: flex">
                            背景图
                            <p
                                style="color: red;display: inline-block;height: 19px;line-height: 25px;padding-left: 5px;"
                            >
                                *
                            </p>
                        </div>

                        <UploadNew
                            class="upload"
                            ref="uploadbox"
                            :limitNum="1000"
                            :width="90"
                            :height="90"
                            :accept="['jpg', 'gif', 'jpeg', 'png']"
                            :fileType="'image'"
                            :showImgList="imgList"
                            @uploadImgList="uploadImgList"
                            @sortImgList="sortImgList"
                        ></UploadNew>
                        <div class="tips" v-if="result.contentType == 2 && result.contentId">
                            当前背景图限制3张，如果继续操作，请先删除
                        </div>
                        <el-button
                            @click="imgSelectClick"
                            size="small"
                            icon="el-icon-plus"
                            v-if="result.contentType == 2 && result.contentId"
                        >
                            去案例中选择封面图
                        </el-button>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>

            <div class="dialog-footer">
                <el-button size="mini" @click="handleClose">
                    取消
                </el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
        <workCollect
            ref="workCollect"
            @innerDrawerSubmit="innerDrawerSubmit"
            :limitNum="3"
            :selectList="imgList"
        ></workCollect>
        <!-- listType // 内容类型(1-笔记 2-案例 3-切片 4- 设计师主页 5-合集 6-设计师榜 7-风格测试 8-智能匹配) -->
        <content-dialog
            v-if="visibleContent"
            :visible="visibleContent"
            :id="1"
            :listType="this.result.contentType"
            :read="false"
            @closeDiag="closeContentDialog"
            @submit="submitContent"
        ></content-dialog>
    </div>
</template>
<script>
import UploadNew from '@/components/upload-new';
import ossState from '@/utils/ossClient.js';
import contentDialog from '@/page/mkt/components/content-dialog.vue';
import { collectionRefSave, efrUpdateEhco, collectionRefUpdate } from '@/api/mkt/home-group.js';
import workCollect from '../../designer-ranking/components/work-collect.vue';
export default {
    components: { UploadNew, contentDialog, workCollect },
    data() {
        return {
            title: '',
            drawer: false,
            accept: [
                // 'mp4'
            ],
            columns: {
                contentType: {
                    name: '内容类型',
                    type: 'select',

                    options: {
                        options: [
                            { label: '笔记', value: 1 },
                            { label: '案例', value: 2 },
                            { label: '案例切片', value: 3 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        placeholder: '',
                        defaultValue: '',
                        disabled: false,
                    },
                    rules: [{ required: true, message: '请选择内容类型', trigger: 'blur' }],
                },
                contentId: {},
                contentTitle: {
                    name: '内容名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                        defaultValue: '',
                        // disabled: true,
                    },
                    rules: [{ required: true, message: '请输入内容名称', trigger: 'blur' }],
                },
                reason: {
                    name: '推荐理由',
                    type: 'input',
                    options: {
                        placeholder: '',
                        defaultValue: '',
                        // disabled: true,
                    },
                },
            },
            columnsToo: {
                publicationContent: {
                    name: '刊登媒体',
                    type: 'input',
                    options: {
                        placeholder: '',
                        defaultValue: '',
                        // disabled: true,
                    },
                },
                publicationTime: {
                    name: '刊登时间',
                    type: 'date',
                    options: {
                        placeholder: '',
                        defaultValue: '',
                        // disabled: true,
                    },
                },
                contentCover: {},
            },
            result: {
                // contentType: '',
                // contentId: '',
                // reason: '',
                // publicationContent: '',
                // publicationTime: '',
                // contentTitle: '',
            },
            imgList: [],
            visibleContent: false,
            type: '',
            collectionId: '',
            id: '',
            addContentIds: [],
        };
    },
    mounted() {
        ossState.getOssClient();
        this.collectionId = this.$route.query.id;
    },
    methods: {
        deWeightThree(name, list) {
            let map = new Map();
            for (let item of list) {
                if (!map.has(item[name])) {
                    map.set(item[name], item);
                }
            }
            return [...map.values()];
        },
        innerDrawerSubmit(caseCoverImg, addContentIds) {
            console.log(caseCoverImg, 'case');

            caseCoverImg.forEach(i => {
                this.imgList.push(i);
            });
            // this.imgList = this.deWeightThree('url', this.imgList);
            // console.log(this.imgList, ' -----');
            this.addContentIds = addContentIds;
        },
        imgSelectClick() {
            let imgList = this.imgList;
            let urls = imgList.map(item => item.url);
            this.$refs.workCollect.openDrawer(
                { contentId: this.result.contentId, urls: urls },
                '案例'
            );
        },
        async openDrawer(row, type, title) {
            console.log(row, 'row---------------------');
            this.title = title;
            this.type = type;
            this.id = row.id;
            // this.resultInitialization();
            if (type == 'edit') {
                this.columns.contentType.options.disabled = true;
                this.getEfrUpdateEhco();
            } else {
                this.columns.contentType.options.disabled = false;
                this.type = '';
            }
            this.drawer = true;
        },
        getEfrUpdateEhco() {
            efrUpdateEhco({ id: this.id }).then(res => {
                console.log(res.data, 1111111111);
                if (res.data) {
                    this.result = {
                        contentId: res.datacontentId,
                        contentType: res.data.contentType == 10 ? 3 : res.data.contentType,
                        contentId: res.data.contentId,
                        reason: res.data.reason,
                        publicationContent: res.data.publicationContent,
                        publicationTime: res.data.publicationTime,
                        contentTitle: res.data.contentName,
                    };

                    res.data?.fileParams?.slice(0, 3).forEach(item => {
                        this.imgList.push({
                            url: item,
                        });
                    });
                }
            });
        },

        changeFn(item) {
            console.log(item, 'item------');
            if (item.row.key == 'contentType') {
                this.result.contentId = '';
            }
        },
        clickFn() {},
        handleClose() {
            this.drawer = false;
            this.resultInitialization();
        },
        onOpenContent() {
            this.visibleContent = true;
        },
        closeContentDialog(type) {
            this.visibleContent = false;
        },
        uploadImgList() {},
        sortImgList(list) {
            this.imgList = list;
        },
        submitContent(e) {
            console.log(e, 33);
            let imgArr = [];
            this.result = {
                ...this.result,
                contentTitle: e.contentTitle,
                contentId: e.contentId,
            };
            e?.fileList?.slice(0, 3).forEach(item => {
                imgArr.push({
                    url: item.url,
                    id: item.id,
                });
            });
            this.imgList = imgArr || [];

            this.closeContentDialog();
        },
        handleSubmit() {
            let check = false;
            let checkToo = false;
            this.$refs.insertForm.validateAll('INSERT', res => {
                if (res) {
                    check = true;
                }
            });

            if (!check) return;

            if (!this.imgList || this.imgList.length == 0) {
                this.$message.warning('封面是必传项');
                return;
            }
            if (!this.result.contentId) {
                this.$message.warning('内容是必传项');
                return;
            }
            if (this.imgList?.length > 3) {
                this.$message.warning('封面不能超过3张图片');
                return;
            }
            let params = {
                collectionId: this.collectionId,
                contentType: this.result.contentType == 3 ? 10 : this.result.contentType,
                contentId: this.result.contentId,
                contentName: this.result.contentTitle,
                reason: this.result.reason,
                publicationContent: this.result.publicationContent,
                publicationTime: this.result.publicationTime,
                fileParams: [],
            };

            this.imgList.forEach(item => {
                params.fileParams.push(item.url);
            });

            console.log('params-----------------', params);
            if (this.type == 'edit') {
                params.id = this.id;
                collectionRefUpdate(params).then(res => {
                    this.$message.success('修改成功');
                    this.$emit('closeDrawer');
                });
                return;
            }

            collectionRefSave(params).then(res => {
                this.$message.success('新增成功');
                this.$emit('closeDrawer');
            });
        },
        resultInitialization() {
            this.result = this.$options.data.call(this).result;
            for (const iterator in this.result) {
                this.result[iterator] = '';
            }
            this.imgList = [];
        },
    },
};
</script>
<style scoped lang="less">
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
.tips {
    font-size: 12px;
    color: #606266;
    // margin-top: 7px;
}
</style>
