/**
 * 使用方法  
    1在使用的页面引入import ossState from '@/utils/ossClient.js';
 *  2调用 ossState.getOssClient();
 *  3组件上可以使用ossClient功能了 ossState.ossClient
 * */

import Vue from 'vue';
import OSS from 'ali-oss';
import { getOssConfig } from '@/api/login.js';
import { oss } from '@/config.js';
import { formatTimeTwo } from '@/utils/util';

async function fetchSTSToken() {
    // 这里替换为您的服务端接口调用，获取STS信息
    const response = await fetch('/api/get-sts-token');
    const data = await response.json();
    return { accessKeyId: data.stsKeyId, accessKeySecret: data.stsSecret, stsToken: data.token };
} // （getOssConfig接口替代此函数）--定义一个全局状态对象来保存OSS客户端实例及刷新配置
const ossState = {
    ossClient: null,
    refreshInterval: 3600 * 1000, // 默认1小时刷新一次，可根据需求更改
    lastRefreshTime: null,
};
// 初始化或刷新OSS客户端
ossState.getOssClient = async (region, bucket) => {
    const now = Date.now();
    if (!ossState.ossClient || now - ossState.lastRefreshTime > ossState.refreshInterval) {
        let res = await getOssConfig({});
        const OSS_CONFIG = {
            accessKeyId: res.data.stsKeyId,
            accessKeySecret: res.data.stsSecret,
            stsToken: res.data.token,
            bucket: 'sczs',
            region: 'oss-cn-beijing',
        };
        ossState.ossClient = new OSS(OSS_CONFIG);
        ossState.lastRefreshTime = now;
    }
    return ossState.ossClient;
};
// 上传函数
ossState.uploadRequest = async (file, itype) => {
    console.log('------------file', file);
    let that = this;
    let newFile = new Date().getTime() + '' + parseInt(Math.random() * 100);
    let fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
    console.log('---------------1111', fileSuffix);
    let fileNameOrigin = file.name;
    let tempCheckpoint;
    let date = formatTimeTwo(new Date(), 'YMD');
    let url = `${oss.folderName}/${date}/${newFile}.${fileSuffix}`;
    if (itype == 'video') {
        url = `${oss.folderName}/video/${date}/${newFile}.${fileSuffix}`;
    }
    let result = ossState.ossClient.multipartUpload(url, file, {
        progress(p, checkpoint) {
            if (itype === 'video') {
                tempCheckpoint = checkpoint;
                //p为上传进度数
                that.videoPercentage = parseInt(p * 100);
            }
        },
        checkpoint: tempCheckpoint,
        // 设置并发上传的分片数量。
        parallel: 1,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 2 * 1024 * 1024,
        meta: {
            year: 2022,
            people: 'test',
        },
        mime: 'text/plain',
    });
    let res = await result;
    if (res.name) {
        let newFile = {};
        newFile.url = 'https://sczs.voglassdc.com/' + res.name;
        // // 获取图片宽高
        // let reader = new FileReader();
        // reader.onload = function(event) {
        //     let text = event.target.result;
        //     let img = document.createElement('img');
        //     img.src = text;
        //     img.onload = function() {
        //         //多图设置方式
        //         // console.log('-----------mainImage width height', img.width, img.height);
        //         newFile.width = img.width;
        //          newFile.height = img.height;
        //     };
        // };
        // reader.readAsDataURL(file);
        console.log('上传后文件', newFile);
        return newFile;
    }
};
// 自动刷新OSS客户端（假设每小时刷新一次）
const timer = setInterval(async () => {
    await ossState.getOssClient('oss-cn-beijing', 'your-bucket-name');
}, ossState.refreshInterval); // 返回清除定时器的函数以便在不再需要时调用

new Vue({
    beforeCreate() {
        // 在组件销毁时清除定时器
        this.$once('hook:beforeDestroy', () => {
            clearInterval(timer);
        });
    },
});
export default ossState;
