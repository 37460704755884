<!-- 二维码弹窗 -->
<template>
    <div>
        <div @click="visible = true">展示弹窗</div>
        <div v-clickOutside="clickImgOut" v-if="visible" class="modal-content">
            <div class="modal-wrap">
                <div class="img">
                    <img
                        src="https://p1-q.mafengwo.net/s19/M00/13/5B/CoNHZmXXE-ZM5HQYAAAEBT0D0HY.png?imageMogr2%2Fthumbnail%2F%21150x150r%2Fquality%2F90"
                        alt=""
                        class="qrcode"
                    />
                    <h4>
                        上传手机照片
                    </h4>
                    <div class="qr-tips">用微信扫描二维码轻松上传手机照片</div>
                </div>
                <div class="btn-box">
                    <div class="btn">
                        上传本地图片
                    </div>
                    <div class="tips">
                        选取的图片尺寸应大于100*50，大小应小于30M
                        <br />
                        按ctrl键可支持多选，只支持jpg格式
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            visible: false,
        };
    },
    directives: {
        ClickOutside: {
            bind(el, { value }) {
                function clickHandler(e) {
                    //先判断点击的元素是否是本身，如果是本身，则返回
                    if (el.contains(e.target)) return;
                    //判断指令中是否绑定了函数
                    if (typeof value === 'function') {
                        //如果绑定了函数，则调用函数，此处value就是clickImgOut方法
                        value();
                    }
                }
                // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
                el.handler = clickHandler;
                //添加事件监听
                setTimeout(() => {
                    document.addEventListener('click', el.handler);
                }, 0);
            },
            unbind(el) {
                //解除事件监听
                document.removeEventListener('click', el.handler);
            },
        },
    },
    mounted() {},
    methods: {
        clickImgOut() {
            this.visible = false;
            console.log('点击弹窗外部');
        },
    },
};
</script>
<style lang="less" scoped>
.modal-content {
    width: 680px;
    height: 250px;
    padding: 30px 0;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    .modal-wrap {
        .img {
            float: left;
            width: 260px;
            text-align: center;
            border-right: 1px solid #e5e5e5;
            .qrcode {
                width: 150px;
                height: 150px;
            }
            .qr-tips {
                color: #999;
                font-size: 12px;
                text-align: center;
            }
        }
    }
}
.btn-box {
    float: left;
    .btn {
        width: 240px;
        height: 60px;
        margin: 40px 50px 30px;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        border-radius: 30px;
        background: -webkit-linear-gradient(
            -30deg,
            transparent 0,
            rgba(173, 217, 101, 0.3) 40%,
            rgba(173, 217, 101, 1) 90%
        );
        background-color: #47afbd;
        &:hover {
            background-color: #add965;
            text-decoration: none;
            color: #fff;
        }
    }

    .tips {
        color: #999;
        font-size: 12px;
        text-align: center;
    }
}
</style>
