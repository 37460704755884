export default {
    // 集成分页 接口
    // 1、page listData 在mixin定义 避免页面重复定义
    // 2、使用页面定义 在data中定义tableHandle：值为引用的接口变量
    // 3、页面使用queryDataMixin调用
    data() {
        return {
            isLoading: false,
            page: {
                size: 30, //每页多少条数据
                page: 1, //当前页码
                count: 0, //数据总条数
                index: 1,
            },
            listData: [], //列表数据
            tableHeight: 300, //table高度
        };
    },
    computed: {},
    watch: {},
    mounted: function() {},
    methods: {
        //获取api数据
        async queryDataMixin() {
            if (this.isLoading) return false;
            this.isLoading = true;
            const { page, size } = this.page;
            let searchResult = this.result || {};
            let filter = this.filter || {};
            let params = { ...searchResult, page, size, ...filter };
            let searchResultTemp = {};
            for (var key in params) {
                let value = params[key];
                if (
                    value !== null &&
                    value !== '' &&
                    value !== 'undefined' &&
                    value !== undefined &&
                    JSON.stringify(value) != '[]'
                ) {
                    searchResultTemp[key] = value;
                }
            }
            params = searchResultTemp;
            await this.tableHandle(params)
                .then(res => {
                    this.isLoading = false;
                    if (res.code === 200 || res.errorCode === 200) {
                        let { children, total } = res.data;

                        this.page.count = total;
                        if (children && children.length) {
                            if (this.filterTableData) {
                                children = this.filterTableData(children);
                            }

                            this.listData = children;
                            this.setTableHeight &&
                                this.$nextTick(() => {
                                    this.setTableHeight();
                                });
                            console.log('-----------this.listData', this.listData);
                            return;
                        } else if (!children && res.data.length) {
                            this.listData = res.data;
                            this.setTableHeight &&
                                this.$nextTick(() => {
                                    this.setTableHeight();
                                });
                        } else {
                            this.listData = children;
                            this.setTableHeight &&
                                this.$nextTick(() => {
                                    this.setTableHeight();
                                });
                            return;
                        }
                    } else {
                        this.$toast(res.errorDesc);
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
            // const { url, headers } = this.tableAjaxConfigMixin;
            // Common.ajax({
            //     url: url,
            //     data: params,
            //     headers: headers || {},
            //     success: res => {
            //         this.isLoading = false;
            //         if (res) {
            //             let { children, total } = res.data;
            //             this.page.count = total;
            //             if (this.filterDataMixin) {
            //                 children = this.filterDataMixin(children); //api数据特殊处理后
            //             }
            //             if (children && children.length) {
            //                 this.data = children;
            //             } else {
            //                 this.data = [];
            //             }
            //             this.$nextTick(() => {
            //                 this.setTableHeight();
            //             });
            //         }
            //     },
            // });
        },
        pageSizesChange(val) {
            this.page.index = 1;
            this.page.page = 1;
            this.page.size = val;
            this.queryDataMixin();
        },
        pageChange(val) {
            this.page.index = val;
            this.page.page = val;
            this.queryDataMixin();
            this.$nextTick(() => {
                // console.log('--------------pageChange', this.$refs.refTable.$refs['table-box']);
                if (this.$refs.refTable) {
                    this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
                }
            });
        },

        // setTableHeight() {
        //     // 设置table高度
        //     const name = `.list-table`;
        //     let tableDom = document.querySelector(name);
        //     if (tableDom) {
        //         let topNum = tableDom.getBoundingClientRect().top;
        //         let mainNum = Tool.getClientHeight();
        //         this.tableHeight = mainNum - topNum - 64;
        //     }
        // },
    },
};
