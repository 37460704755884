<template>
    <div>
        <el-drawer
            title="图片选择"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleCancle"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="true"
        >
            <div>当前内容所有图片</div>

            <!-- 展示案例 -->
            <div class="img-list" style="padding-top: 20px;">
                <div class="list-for">
                    <div v-for="(items, ind) in localArr" :key="ind" style="margin-bottom:30px;">
                        <el-checkbox
                            v-model="items.checked"
                            class="checkbox"
                            @change="handleChangeCheckbox(ind)"
                        ></el-checkbox>

                        <el-image
                            style="width: 80px; height: 80px;"
                            :src="items.url"
                            :preview-src-list="[items.url]"
                        ></el-image>
                    </div>
                </div>
            </div>

            <div class="footer">
                <el-button size="small" @click="handleCancle">取消</el-button>
                <el-button type="primary" size="small" @click="submit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    name: 'exhibit-group-search',
    data() {
        return {
            drawer: false,
            total: 0,
            checked: false,
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: true,
            checkedList: [],
            localArr: [],
        };
    },
    created() {},
    methods: {
        async openDrawer(row) {
            console.log(row, 11111111);
            this.drawer = true;
            let imgArr = [];
            row.map(item => {
                item.checked = false;
                imgArr.push(item);
            });
            this.localArr = imgArr;

            // await this.getOss(); //调取mixins中获取
        },
        handleCancle() {
            this.drawer = false;
            // this.$emit('handleCancle');
        },
        handleChangeCheckbox(ind) {
            console.log(this.localArr, '当前列表所有数据');
            this.$forceUpdate();
            // 前端的处理, 先不要外层的id, 只传图片带的id
            // 只能选一张
            this.localArr.map(item => {
                item.checked = false;
            });
            let _list = [];
            this.localArr[ind].checked = true;
            this.localArr.map(item => {
                if (item.checked) {
                    _list.push(item);
                }
            });
            this.checkedList = _list;
            console.log(this.checkedList, 222222222222);
        },
        submit() {
            if (!this.checkedList.length) {
                this.$message.info('请至少选择一个');
                return;
            }
            if (this.checkedList.length > 9) {
                this.$message.info('最多上传9张');
                return;
            }

            this.$emit('innerDrawerSubmit', this.checkedList);
            this.localArr.map(item => {
                if (item.checked) {
                    item.checked = false;
                }
            });
            this.handleCancle();
        },
    },
};
</script>
<style lang="less" scoped>
.title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .label {
        margin-right: 30px;
    }
}
.list-for {
    display: flex;
    flex-flow: wrap;
}
.wrap,
.list-for {
    display: flex;
    flex-flow: wrap;
    padding-left: 20px;
    > div {
        margin-right: 20px;
        position: relative;
        .checkbox {
            position: absolute;
            right: 3px;
            top: 0;
            z-index: 10;
        }
    }
}
.page-wrap,
.footer {
    display: flex;
    justify-content: flex-end;
}
.footer {
    margin-top: 10px;
    margin-right: 50px;
}
/deep/ .el-input__icon.el-input__validateIcon.el-icon-circle-close {
    display: none;
}
</style>
