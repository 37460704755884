<template>
    <div>
        <div class="content">
            <sc-table-v2
                :head="tableHead"
                :data="list"
                :page-hide="true"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeight"
                :read-table="true"
                :load="loading"
                @click="clickFn"
            >
                <template v-slot:url>
                    <el-table-column label="图片" width="100">
                        <template slot-scope="scope">
                            <div class="">
                                <el-image
                                    v-if="scope.row.url"
                                    :src="scope.row.url"
                                    alt=""
                                    style="width:50px;height:50px;"
                                />
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:introduction>
                    <el-table-column label="风格正文">
                        <template slot-scope="scope">
                            <div class="content-text">
                                {{ scope.row.introduction }}
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <edit ref="editRef" @refresh="refresh"></edit>
    </div>
</template>
<script>
import edit from './components/edit.vue';
import { getInfoList } from '@/api/mkt/style-test.js';
// import mixins from '@/mixins/getListMixin.js';
export default {
    name: 'emotion-list',
    components: { edit },
    mixins: [],
    data() {
        return {
            tableHead: [
                { name: '情绪版图片', key: 'url', width: 120 },
                { name: '风格', key: 'styleName', width: 100 },
                { name: '风格正文', key: 'introduction' },
                { name: '风格关键词', key: 'keyword' },
                { name: '操作', key: 'control' },
            ],
            list: [],
            tableHandle: getInfoList,
            tableHeight: window.innerHeight - 185,
            loading: false,
        };
    },
    created() {
        // this.queryDataMixin();
        this.init();
    },
    methods: {
        refresh() {
            this.init();
        },
        clickFn() {},
        init() {
            getInfoList({
                page: 1,
                limit: 30,
            }).then(res => {
                this.list = res.data;
            });
        },
        onEdit(row) {
            this.$refs.editRef.open(row);
        },
    },
};
</script>
<style scoped>
.content-text {
    white-space: pre-line;
}
</style>
