<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
    </div>
</template>

<script>
import { getSource } from '@/api/mkt/topic.js';
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                topicName: {
                    name: '话题名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                // categoryId: {
                //     name: '分类',
                //     type: 'cascader',
                //     options: {
                //         placeholder: '最多选择20个',
                //         allLevels: false,
                //         clearable: true,
                //         options: [],
                //         props: {
                //             emitPath: false,
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     spanCol: 1,
                // },
                sourceType: {
                    name: '来源',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'dictValue',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                // labelId: {
                //     name: '标签',
                //     type: 'cascader',
                //     options: {
                //         placeholder: '最多选择20个',
                //         allLevels: false,
                //         clearable: true,
                //         options: [],
                //         props: {
                //             label: 'labelName',
                //             value: 'id',
                //             checkStrictly: true,
                //             emitPath: false,
                //         },
                //     },
                //     spanCol: 1,
                // },
                // enabled: {
                //     name: '是否启用',
                //     type: 'select',
                //     options: {
                //         defaultValueBase: 'aaa',
                //         placeholder: '请输入关键词',
                //         options: [
                //             {
                //                 value: '1',
                //                 label: '启用',
                //             },
                //             {
                //                 value: '0',
                //                 label: '不启用',
                //             },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     spanCol: 1,
                // },
                time: {
                    name: '创建时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                k1: {},
            },
            result: {
                // categoryId: '',
                createEndTime: '',
                createStartTime: '',
                // enabled: '',
                labelId: '',
                sourceType: '',
                topicName: '',
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            categoryList: [],
            sourceTypeList: [],
            labelCommonOptions: [],
        };
    },
    created() {
        this.getSourceTypeList();
        this.getTagList();
        this.getColumn();
    },
    watch: {
        cloumnsList(val) {
            if (val) {
                // this.columns.categoryId.options.options = val;
            }
        },
        labelOptions(val) {
            if (val) {
                // this.columns.labelId.options.options = val;
            }
        },
    },
    methods: {
        // 获取来源数据
        getSourceTypeList() {
            getSource({ dictName: 'source_type' }).then(res => {
                if (res?.isSuccess) {
                    this.sourceTypeList = res.data || [];
                    this.columns.sourceType.options.options = this.sourceTypeList;
                }
            });
        },

        changeFn(e) {
            if (e.row.key == 'time') {
                this.result.createStartTime = e.value[0] + ' 00:00:00';
                this.result.createEndTime = e.value[1] + ' 23:59:59';
                delete this.result.time;
            }
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-topic/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-topic/list');
        },

        clickFn(e, type) {
            if (type == 'clear') {
                this.result = {
                    // categoryId: '',
                    createEndTime: '',
                    createStartTime: '',
                    // enabled: '',
                    labelId: '',
                    sourceType: '',
                    topicName: '',
                };
            }
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-topic/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-topic/list');
        },
    },
};
</script>
<style lang="scss" scoped></style>
