<template>
    <div class="mkt" ref="table">
        <!-- 广告位列表 -->
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>

        <div class="tabs">
            <sc-table-v2
                row-key="priceCode"
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:houseString>
                    <el-table-column min-width="110">
                        <template slot="header">户型</template>
                        <template slot-scope="scope">
                            <div>
                                <el-popover
                                    placement="top-start"
                                    popper-class="cus-popover"
                                    title=""
                                    trigger="hover"
                                >
                                    <div slot="reference" class="row-value">
                                        {{ scope.row.houseString }}
                                    </div>
                                    <div slot class="popover-content-list">
                                        <div
                                            class="item"
                                            v-for="(item, index) in scope.row.houses"
                                            :key="index"
                                        >
                                            <span class="name">{{ item.house }}</span>
                                            <span class="num">数量{{ item.houseNum }}</span>
                                            <span class="area">
                                                总地面面积{{ item.houseArea }}㎡
                                            </span>
                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </template>

                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="96">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleDetail(scope.row)" size="mini">
                                查看报价结果
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <detail-dialog ref="details" @handleClose="queryDataMixin"></detail-dialog>
    </div>
</template>

<script>
import detailDialog from './details.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import { getGaisuanList } from '@/api/mkt/market.js';
export default {
    name: 'mkt-management-index',
    mixins: [mixins, getListMixin],
    components: { detailDialog },
    data() {
        return {
            tableHandle: getGaisuanList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '记录编号', key: 'priceCode', width: 250 },
                {
                    name: '来源',
                    key: 'quoteSource',
                    width: 86,
                },
                {
                    name: '用户',
                    key: 'startTime',
                    minWidth: 180,
                    formatter: val => {
                        return `${val.nickName || ''}
                            ${((val.mobile && val.nickName && '(') || '') +
                                val.mobile +
                                ((val.mobile && val.nickName && ')') || '')}`;
                    },
                },
                {
                    name: '房屋类型',
                    key: 'houseType',
                    width: 86,
                },
                {
                    name: '套内面积（㎡）',
                    key: 'insideArea',
                    width: 110,
                },
                {
                    name: '楼层（层）',
                    key: 'floorNum',
                    width: 86,
                },
                {
                    name: '层高（米）',
                    key: 'floorHeight',
                    width: 86,
                },
                {
                    name: '户型',
                    key: 'houseString',
                },
                {
                    name: '所在城市',
                    key: 'city',
                    formatter: val => {
                        return (val.province || '') + (val.city || '') + (val.region || '');
                    },
                },
                {
                    name: '是否有采暖',
                    key: 'isHeating',
                    formatter: val => {
                        return val.isHeating == 1 ? '有' : '无';
                    },
                    width: 86,
                },
                {
                    name: '提交时间',
                    key: 'bpmCreateTime',
                    width: 140,
                },
                { name: '操作', key: 'control' },
            ],
            columns: {
                priceCode: {
                    name: '编号',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入编号',
                        props: {},
                    },
                    spanCol: 1,
                },
                nickName: {
                    name: '用户',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入昵称/手机号',
                        props: {},
                    },
                    spanCol: 1,
                },
            },
            result: {
                priceCode: '',
                mobile: '',
                accountId: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },

    created() {
        this.init();
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        });
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
    },

    methods: {
        async init() {
            this.queryDataMixin();
        },
        filterTableData(list) {
            if (list.length === 0) {
                return list;
            }
            for (let item of list) {
                item.houseString = this.houseFormat(item);
                item.province = ['北京市', '天津市', '上海市', '重庆市'].includes(item.province)
                    ? ''
                    : item.province;
            }
            return list;
        },
        handleDetail(row) {
            this.$refs.details.open(row);
        },
        houseFormat(row) {
            let arr = [
                { name: '卧室', unit: '室' },
                { name: '公区', unit: '厅' },
                { name: '厨房', unit: '厨' },
                { name: '卫生间', unit: '卫' },
            ];
            let house = '';
            for (let item of arr) {
                let num = row?.houses?.find(space => space.house == item.name)?.houseNum;
                house += (num && num + item.unit) || '';
            }
            return house;
        },
        changeFn() {
            this.queryDataMixin();
        },
        clickFn() {
            this.queryDataMixin();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.row-value {
    color: #4290f7;
}
.cus-popover {
    .row-value {
        color: #4290f7;
    }
    .popover-content-list {
        min-width: 350px;
        font-size: 12px;
        .item {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #efefef;
        }

        .name {
            display: inline-block;
            width: 100px;
            font-weight: 700;
        }
        .num {
            width: 60px;
        }
        .area {
            width: 120px;
            text-align: left;
        }
    }
}
</style>
