<template>
    <div class="add-tag-container">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            :title="title"
            @close="close"
            destroy-on-close
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                :LS="LS"
                ref="groupform"
            >
                <template v-slot:img>
                    <el-descriptions-item span="1">
                        <template slot="label">
                            <span class="must">*</span>
                            <span>图片</span>
                        </template>
                        <div class="upload-box">
                            <el-form-item
                                style="margin-bottom:0;margin-left:20px;"
                                prop="img"
                                :rules="[
                                    { required: true, message: '请上传图片', trigger: 'blur' },
                                ]"
                            >
                                <el-input v-model="result.img" v-if="false"></el-input>
                                <Upload
                                    ref="uploadbox"
                                    :limitNum="1"
                                    :fileSize="1"
                                    :width="90"
                                    :height="90"
                                    :fileType="'image'"
                                    accepts=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
                                    :delShowCtl="title != '查看详情'"
                                    :ossClientCur="ossClient"
                                    @uploadImgList="uploadImgList"
                                    :tip="uploadTip[result.adPosition]"
                                    @delImg="delImg"
                                ></Upload>
                            </el-form-item>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:k1>
                    <el-descriptions-item span="1">
                        <template slot="label">
                            <span class="must">*</span>
                            <span>有效时间</span>
                        </template>
                        <div>
                            <el-date-picker
                                v-if="addialogVisible"
                                v-model="effectivetime"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                size="small"
                                style="width:100%;"
                                :picker-options="pickerOptions"
                                :default-time="[startHour, '23:59:59']"
                                :disabled="title == '查看详情'"
                                @change="handleDateChange"
                            ></el-date-picker>
                            <br />
                            <div class="is-error" v-if="validate">请选择时间</div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="cancel">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">发布</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getHomeAdById, saveHomeAd } from '@/api/mkt/market';
import mixins from '@/mixins';
import getOssMixin from '../../../../mixins/getOssMixin';
import Upload from '@/components/upload';

export default {
    props: {},
    components: { Upload },
    mixins: [mixins, getOssMixin],
    data() {
        return {
            effectivetime: [],
            title: '新增',
            addialogVisible: false,
            dialogVisible: false,
            LS: { 'min-width': '60px' }, //设置label宽度
            uploadTip: [
                '',
                '建议尺寸：522*694像素；大小：1M以内；格式：JPG、PNG、JPEG',
                '',
                '建议尺寸：336*448像素；大小：1M以内；格式：JPG、PNG、JPEG',
            ],
            columns: {
                adName: {
                    name: '名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入名称' }],
                },
                adPosition: {
                    name: '位置',
                    type: 'select',
                    options: {
                        options: [
                            {
                                label: '首页弹窗广告',
                                value: 1,
                            },
                            {
                                label: '首页Feed流',
                                value: 3,
                            },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请选择位置' }],
                },
                orderSort: {
                    name: '排序值',
                    type: 'hidden',
                    options: {
                        placeholder: '请输入排序值',
                        inputType: 'number',
                        min: 0,
                        max: 1000,
                    },
                    rules: [{ required: true, message: '请输入排序值' }],
                },
                img: {
                    key: 'img',
                    name: '图片',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过1M',
                        size: 1000,
                        fileSize: 1,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
                jumpType: {
                    name: '跳转类型',
                    type: 'select',
                    options: {
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请选择跳转类型' }],
                },
                link: {
                    name: '跳转链接',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入跳转链接' }],
                },
                k1: {
                    name: '有效时间',
                },
                sendRange: {
                    name: '发送范围',
                    type: 'radio',
                    // disabled: false,
                    options: {
                        options: [
                            { label: '全平台', value: 1 },
                            { label: 'APP', value: 2 },
                            { label: '小程序', value: 3 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        placeholder: '',
                        disabled: false,
                    },
                    rules: [{ required: true, message: '请选择发送范围' }],
                },
                sendUserType: {
                    name: '发送用户',
                    type: 'radio',

                    options: {
                        options: [
                            { label: '全部用户', value: 1 },
                            { label: '设计师', value: 2 },
                            { label: '非设计师', value: 4 },
                            { label: '普通用户', value: 3 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        placeholder: '',
                        disabled: false,
                    },
                    rules: [{ required: true, message: '请选择发送用户' }],
                },
            },
            result: {
                adName: 1,
                time: '',
                link: '',
                jumpType: null,
                adPosition: 1,
                orderSort: '',
                sendRange: 1,
                sendUserType: 1,
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                },
            },
            defaultTime: [],
            startHour: null,
            validate: false,
            selectableRange: [],
        };
    },

    mounted() {
        this.getOss();
    },
    methods: {
        handleDateChange(value) {
            if (!value) {
                this.effectivetime = [];
            }
            const date1 = this.formatDate(new Date()).split(' ')[0];
            const date2 = this.effectivetime[0].split(' ')[0];
            if (date1 == date2) {
                this.timeDifferFn(value);
                if (value && value.length === 2 && value[0] === value[1]) {
                    this.effectivetime = [];
                    this.$message({
                        message: '起始时间和结束时间不能相同',
                        type: 'warning',
                    });
                }
            }
        },
        formatDate(dateString) {
            let date = new Date(dateString);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let monthStr = month < 10 ? '0' + month : '' + month;
            let dayStr = day < 10 ? '0' + day : '' + day;
            let hoursStr = hours < 10 ? '0' + hours : '' + hours;
            let minutesStr = minutes < 10 ? '0' + minutes : '' + minutes;
            let newFormat = `${year}-${monthStr}-${dayStr} ${hoursStr}:${minutesStr}`;
            return newFormat;
        },
        setStartHour() {
            let now = new Date();
            let oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
            let defaultTimeStart = oneHourLater.toLocaleTimeString('zh-CN', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return defaultTimeStart;
        },
        delImg(item) {
            this.result.img = '';
        },
        open(row, title, options) {
            this.addialogVisible = true;
            this.title = title;
            this.columns.jumpType.options.options = options;

            this.startHour = this.setStartHour();

            if (title != '新增') {
                getHomeAdById({ id: row.id }).then(res => {
                    if (res.code == 200) {
                        this.result = {
                            ...res.data,
                            img: [{ url: res.data.path }],
                            jumpType: String(res.data.jumpType),
                        };
                        if (this.result.adPosition == 3) {
                            this.columns.orderSort.type = 'input';
                        }
                        this.effectivetime = [res.data.startTime, res.data.endTime];
                        let arr = [{ url: res.data.path }];
                        this.$nextTick(() => {
                            //column hidden变化 图片不展示问题
                            this.$refs.uploadbox.setList(arr);
                        });
                    }
                });
            } else {
                this.handleReset();
            }
            this.setDisableStatus();
        },
        setDisableStatus() {
            if (this.title == '查看详情') {
                this.columns.adName.options.disabled = true;
                this.columns.adPosition.options.disabled = true;
                this.columns.jumpType.options.disabled = true;
                this.columns.link.options.disabled = true;
                this.columns.sendRange.options.disabled = true;
                this.columns.sendUserType.options.disabled = true;
                this.columns.orderSort.options.disabled = true;
            } else {
                this.columns.adName.options.disabled = false;
                this.columns.adPosition.options.disabled = false;
                this.columns.jumpType.options.disabled = false;
                this.columns.link.options.disabled = false;
                this.columns.sendRange.options.disabled = false;
                this.columns.sendUserType.options.disabled = false;
                this.columns.orderSort.options.disabled = false;
            }
        },
        close() {
            this.addialogVisible = false;
            this.handleReset();
            this.$refs.groupform.$refs.CRM_TAG_GROUP.clearValidate();
        },

        handleSubmit() {
            if (this.title == '查看详情') {
                this.close();
                return;
            }

            console.log(this.effectivetime);
            if (!this.effectivetime.length) {
                this.validate = true;
                // return;
            }
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', res => {
                //校验回调

                if (this.title == '新增') {
                    this.submitFn();
                } else {
                    this.editFn();
                }
            });
        },
        timeDifferFn(value) {
            let flag;
            let endTime = this.startHour.slice(0, 5);
            if (value[0].split(' ')[1] < endTime) {
                this.$message({
                    message: `起始时间不能小于${endTime}`,
                    type: 'warning',
                });
                flag = false;
            } else {
                flag = true;
            }
            return flag;
        },
        editFn() {
            this.$confirm('确定发布广告么？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    const date1 = this.formatDate(new Date()).split(' ')[0];
                    const date2 = this.effectivetime[0].split(' ')[0];
                    if (date1 > date2) {
                        this.$message({
                            message: '起始时间不能小于今天',
                            type: 'warning',
                        });
                    } else if (date1 == date2) {
                        let flag = this.timeDifferFn(this.effectivetime);
                        flag && this.submitFn();
                    } else {
                        this.submitFn();
                    }
                })
                .catch(() => {});
        },
        submitFn() {
            let params = {
                id: this.result.id,
                ...this.result,
                startTime: this.effectivetime[0],
                endTime: this.effectivetime[1],
                path: this.result.img[0].url,
                img: undefined,
                time: undefined,
            };
            saveHomeAd(params).then(res => {
                if (res.isSuccess) {
                    this.close();
                    this.$emit('handleClose');
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning',
                    });
                }
            });
        },
        uploadImgList(list) {
            this.result.img = list;
        },
        handleReset() {
            this.result = {
                adPosition: 1,
                link: '',
                adName: '',
                jumpType: null,
                img: [],
                sendRange: 1,
                sendUserType: 1,
            };
            this.columns.orderSort.type = 'hidden';
            this.validate = false;
            this.effectivetime = [];
            this.$nextTick(() => {
                this.$refs.uploadbox.setList([]);
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            console.log(data);
            if (data.row.key == 'jumpType' && data.value) {
                let list = this.columns.jumpType.options.options;
                let value = list.find(item => item.dictValue == data.value).description || '';
                this.$set(this.result, 'link', value);
            }
            if (data.row.key == 'adPosition') {
                if (data.value == 3) {
                    this.columns.orderSort.type = 'input';
                } else {
                    this.columns.orderSort.type = 'hidden';
                }
            }
            if (data.row.key == 'orderSort') {
                if (data.value < 0) {
                    this.result.orderSort = 0;
                } else {
                    this.result.orderSort = parseInt(data.value);
                }
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        cancel() {
            if (this.title != '查看详情') {
                this.$confirm('当前广告还未发布，确定退出？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        this.handleReset();
                        this.addialogVisible = false;
                        this.$refs.groupform.$refs.CRM_TAG_GROUP.clearValidate();
                    })
                    .catch(err => {
                        this.handleReset();
                        this.addialogVisible = false;
                        this.$refs.groupform.$refs.CRM_TAG_GROUP.clearValidate();
                    });
            } else {
                this.handleReset();
                this.addialogVisible = false;
                this.$refs.groupform.$refs.CRM_TAG_GROUP.clearValidate();
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item {
    width: 100%;
}
/deep/ .el-dialog__body {
    padding-top: 0 !important;
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
.must {
    color: red;
}

/deep/ .upload-row {
    width: 90px;
    height: 90px;
}
/deep/ .esay-style .el-form-item__error {
    top: 103% !important;
}
.is-error {
    color: #f56c6c;
    font-size: 12px;
}
</style>
