<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="cancelClose"
            custom-class="self-drawer"
            size="30%"
        >
            <sc-form-v2
                tablename="CRM_CATEGORY"
                :columnsNum="1"
                :columns="columns"
                :uploadConfig="uploadConfig"
                :result="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @removeTag="removeTag"
                ref="topicFrom"
            ></sc-form-v2>
            <div class="demo-drawer__footer" style="text-align: right">
                <el-button size="mini" style="margin-right: 20px" @click="cancelClose">
                    取消
                </el-button>
                <el-button type="primary" size="mini" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { saveTopic, existTopic, topicDetail } from '@/api/mkt/topic.js';

import { uploadConfigData } from '@/config.js';
import mixins from '@/mixins';
export default {
    name: 'details-drawer',
    mixins: [mixins],
    components: {},
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            title: '',
            size: 'small',
            columns: [
                {
                    key: 'topicName',
                    name: '话题名称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        maxlength: '20',
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入文字描述', trigger: 'blur' }],
                },
                {
                    key: 'topicCover',
                    name: '话题封面',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip: '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                },
                // {
                //     key: 'categoryId',
                //     name: '分类',
                //     type: 'cascader',
                //     options: {
                //         placeholder: '最多选择20个',
                //         allLevels: false,
                //         clearable: true,
                //         options: [],
                //         props: {
                //             emitPath: false,
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     rules: [{ required: true, message: '请选择所属分类' }],
                // },
                // {
                //     key: 'labelsAll',
                //     name: '标签',
                //     type: 'cascader',
                //     options: {
                //         placeholder: '最多选择20个',
                //         allLevels: false,
                //         clearable: true,
                //         options: [],
                //         props: {
                //             label: 'labelName',
                //             value: 'id',
                //             checkStrictly: true,
                //             emitPath: false,
                //             multiple: true,
                //         },
                //     },
                // },

                // {
                //     key: 'enabled',
                //     name: '是否启用',
                //     type: 'radio',
                //     options: {
                //         defaultValue: '1',
                //         options: [
                //             {
                //                 value: '1',
                //                 label: '启用',
                //             },
                //             {
                //                 value: '0',
                //                 label: '不启用',
                //             },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                // },
            ],

            result: {
                // categoryId: '',
                // enabled: '1',
                // labels: [],
                topicName: '',
                topicCover: [],
                categoryName: '',
            },
            // labels: [],
            deleteData: '',
            toipcId: '',
            uploadConfig: {},
        };
    },
    computed: {},
    watch: {
        cloumnsList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'categoryId') {
                        item.options.options = val;
                    }
                });
            }
        },
        normalLabelList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'labelsAll') {
                        item.options.options = val;
                    }
                });
            }
        },
    },
    created() {
        this.getOssConfig();
        this.getColumn();
        this.getLabelCommonList();
    },
    mounted() {},
    methods: {
        openDrawer(title, row) {
            this.drawer = true;
            this.title = title;
            this.$nextTick(() => {
                uploadConfigData.oss.accessKeyId = this.ossConfig.stsKeyId;
                uploadConfigData.oss.accessKeySecret = this.ossConfig.stsSecret;
                uploadConfigData.oss.stsToken = this.ossConfig.token;
                this.uploadConfig = uploadConfigData;
            });

            if (this.title == '编辑话题') {
                this.toipcId = row;
                this.getDetail();
            }
        },
        cancelClose() {
            this.$confirm('确认关闭？')
                .then(() => {
                    this.drawer = false;
                    this.result = {
                        // categoryId: '',
                        // enabled: '1',
                        // labels: [],
                        topicName: '',
                        topicCover: [],
                        categoryName: '',
                    };
                })
                .catch(() => {});
        },
        // 处理后端需要的数据
        changLabelArr(arr) {
            if (arr.length > 0) {
                arr.map(item => {
                    item.labelId = item.id;
                    delete item.id;
                });
                return arr;
            } else {
                return [];
            }
        },
        // 处理图片，需要转换成数组
        changeImg(data) {
            if (data) {
                let arr = [
                    {
                        url: data,
                    },
                ];
                return arr;
            } else {
                return [];
            }
        },
        getDetail() {
            topicDetail({ topicId: this.toipcId }).then(res => {
                if (res?.isSuccess) {
                    this.result = res.data;
                    // this.result.labelsAll = res?.data?.labels.map(item => {
                    //     return item.id;
                    // });

                    this.result.topicCover = this.changeImg(res.data.topicCover);
                    // this.result.labels = this.changLabelArr(res.data.labels);
                    // this.result.enabled = res?.data?.enabled.toString();
                }
            });
        },
        handleSubmit() {
            let params = {
                topicName: this.result.topicName,
                topicId: this.toipcId,
            };

            this.$refs.topicFrom.validateAll('CRM_CATEGORY', () => {
                existTopic({ ...params }).then(res => {
                    //    判断话题名称是否重复  errorCode=200时，说明话题不存在，反之则已经存在。
                    if (res.errorCode == 200) {
                        let data = {
                            ...this.result,
                        };
                        data.topicCover =
                            this.result.topicCover && this.result.topicCover.length > 0
                                ? this.result.topicCover[0].url
                                : '';
                        saveTopic(data).then(res => {
                            if (res?.isSuccess) {
                                this.$message({
                                    type: 'success',
                                    message: '保存成功',
                                });
                                this.drawer = false;
                                this.result = {
                                    // categoryId: '',
                                    // enabled: '1',
                                    // labels: [],
                                    topicName: '',
                                    topicCover: [],
                                    categoryName: '',
                                };
                                console.log(this.drawer, 'this.drawer');
                                this.$store.dispatch('mkt-topic/list');
                            } else {
                                this.$message({
                                    type: 'success',
                                    message: res.message,
                                });
                            }
                        });
                    }
                });
            });
        },

        changeFn(val) {
            if (val.row.key == 'categoryId') {
                this.result.categoryName = val.nodeData && val.nodeData[0].label;
            }
            if (val.row.key == 'labelsAll') {
                if (this.result.labelsAll?.length > 20) {
                    this.$message.warning('最多选择20个标签');
                    this.result.labels = this.result.labelsAll.slice(0, 20);
                }
                this.result.labels = this.changeLabel(val.nodeData, this.deleteData);
            }
        },

        changeLabel(arr, deleteData) {
            // 多选删除需要手动删除
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理后端需要的数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        removeTag(val) {
            this.deleteData = val.value;
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .self-drawer {
    padding: 0 20px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    > div {
        text-align: center;
    }
    .img {
        width: 100px;
        height: 100px;
    }
}
.must {
    color: red;
    vertical-align: -2px;
}
</style>
