<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
    </div>
</template>

<script>
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                keywordsNc: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输名称/创建人',
                    },
                    spanCol: 1,
                },
                associationStatus: {
                    name: '状态',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '关联状态',
                        options: [
                            { label: '未关联', value: '0' },
                            { label: '已关联', value: '1' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },

                labelId: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },

                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入分公司',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                // createTime: {
                //     name: '创建时间',
                //     type: 'date',
                //     options: {
                //         dateType: 'daterange',
                //         defaultValueBase: 'aaa',
                //         placeholder: '请输入创建时间',
                //     },
                //     spanCol: 2,
                // },
                // time: {
                //     name: '审核时间',
                //     type: 'date',
                //     options: {
                //         dateType: 'daterange',
                //         defaultValueBase: 'aaa',
                //         placeholder: '请输入审核时间',
                //     },
                //     spanCol: 2,
                // },
            },
            result: {
                keywordsNc: '',
                categoryId: '',
                articleType: '',
                labelId: [],
                associationStatus: '',
                page: 1,
                size: 30,
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: false,
        };
    },
    watch: {
        searchLabelOptions(val) {
            if (val) {
                this.columns.labelId.options.options = val;
            }
        },
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
        cloumnsList(val) {
            if (val) {
                this.columns.categoryId.options.options = val;
            }
        },
    },
    methods: {
        changeFn(e) {
            // if (e.row.key == 'time') {
            //     this.result.approvalStartTime = e.value[0] + ' 00:00:00';
            //     this.result.approvalEndTime = e.value[1] + ' 23:59:59';
            //     delete this.result.time;
            // }
            // if (e.row.key == 'createTime') {
            //     this.result.startTime = e.value[0] + ' 00:00:00';
            //     this.result.endTime = e.value[1] + ' 23:59:59';
            //     delete this.result.createTime;
            // }
            if (e.row.key == 'labelId') {
                this.result.labelIdsArr = this.disposeData(e.nodeData);
            }
            if (e.row.key == 'categoryId') {
                this.result.categoryIdArr = this.flDisposeData(e.nodeData);
            }
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-content-assarticle/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-content-assarticle/list');
        },
        flDisposeData(arr) {
            return arr.map(item => {
                return item.data.value;
            });
        },
        disposeData(arr) {
            return arr.map(item => {
                return item.data.id;
            });
        },
        clickFn(e, type) {
            console.log(e, type, 999);
            if (type == 'clear') {
                this.result = {
                    approvalStartTime: '',
                    approvalEndTime: '',
                    startTime: '',
                    endTime: '',
                    keywordsNc: '',
                    // time: null,
                    // createTime: null,
                    companyCode: '',
                    count: '',
                    categoryId: null,
                    labelId: null,
                    associationStatus: '',
                    labelIdsArr: null,
                    categoryIdArr: null,
                };
            }
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-content-assarticle/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-content-assarticle/list');
        },
    },
    created() {
        this.getColumn();
        this.getSearchTagList();
        this.getCompany();
        let params = this.$route.query;
        if (params.topicId) {
            // this.result.keywordsNc = params.topicName;
            this.result.topicIdAn = params.topicId;
            // if (params.associationStatus) {
            //     this.result.associationStatus = params.associationStatus;
            // }
        }
        this.$store.commit('mkt-content-assarticle/setParams', this.result); //同步方法保存
    },
};
</script>
<style lang="scss" scoped></style>
