<template>
    <div class="mkt-main">
        <!-- <Top></Top> -->
        <div class="mkt-content">
            <Menu
                :data="menuList"
                @setModuleData="moduleEchoHandle"
                @setBreadList="setBreadListHandle"
            ></Menu>
            <Info :bread="breadList" :data="modelInfo"></Info>
            <img-list
                class="mkt-main-list"
                v-if="rowModuleData.controlType === 2"
                ref="imgRef"
                :row-module-list="rowModuleData"
            ></img-list>
            <List
                class="mkt-main-list"
                v-else
                ref="moduleListRef"
                :row-module-list="rowModuleData"
            ></List>
        </div>
    </div>
</template>

<script>
import Menu from './menu';
import Info from './info';
import List from './list';
import imgList from './imgtext-detail.vue';
import { contentRunList, moduleEcho } from '@/api/mkt/market.js';

export default {
    name: 'exhibit-index',
    components: { Menu, Info, List, imgList },

    data() {
        return {
            breadList: [],
            menuList: [],
            modelInfo: {},
            rowModuleData: {},
        };
    },
    mounted() {
        this.getMenuList();
    },
    methods: {
        setBreadListHandle(data) {
            this.breadList = data;
        },
        async getMenuList() {
            const { data, isSuccess } = await contentRunList();
            if (isSuccess) {
                this.menuList = this.initTreeDataHandle(data);
            }
        },
        initTreeDataHandle(arr) {
            let initArr = null;
            if (arr && arr.length) {
                initArr = arr.map(item => {
                    let initObj = {
                        isPublish: item.isPublish,
                        label: `${item.label}`,
                        value: item.value,
                        moduleList: item.moduleList
                            ? this.initTreeDataHandle(item.moduleList)
                            : null,
                        controlType: item.controlType,
                    };
                    return initObj;
                });
            }
            return initArr;
        },
        async moduleEchoHandle(row) {
            this.rowModuleData = row;
            const { data, isSuccess } = await moduleEcho(row.value);
            if (isSuccess) {
                this.modelInfo = data;
            }
            this.rowModuleData.listTypeValue = this.modelInfo.listTypeValue;
            if (this.$refs?.moduleListRef && this.rowModuleData.controlType !== 2) {
                this.$refs.moduleListRef.getModuleListHandle();
            } else if (this.$refs?.imgRef) {
                this.$refs.imgRef.getTextimglist();
            }
        },
    },
};
</script>
<style lang="less" scoped>
.mkt-main {
    width: 100%;
    background: #f5f5f5;
    .mkt-content {
        display: flex;
        width: 100%;
        padding-top: 20px;
        .mkt-main-list {
            flex: 1;
        }
    }
}
</style>
