<template>
    <div>
        <el-dialog
            title="设置封面"
            :visible.sync="dialogFormVisible"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            width="840px"
            top="50px"
            :append-to-body="true"
            :before-close="close"
        >
            <!-- <cropper :video="video" /> -->
            <clip-img
                ref="clipimg"
                :showImgList="showImgList"
                :cacheImgUrl="cacheImgUrl"
                :init="visible"
                @selectImg="selectImg"
            ></clip-img>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini" :loading="loading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import clipImg from './clip-img.vue';
import { baseToOss } from '../../../../api/mkt/exhibit';
import { formatTimeTwo } from '@/utils/util';
import { oss } from '@/config.js';
import cropper from './cropper.vue';
export default {
    name: 'cover-dialog',
    components: { clipImg, cropper },

    data() {
        return {
            dialogFormVisible: false,
            loading: false,
            showImgList: [],
            cacheImgUrl: '', //截取的图片链接；
            video: {}, //视频文件对象
        };
    },
    methods: {
        openDrawer(row) {
            this.dialogFormVisible = true;
            console.log('-------row', row);
            if (row) {
                let newFiles = [];
                row.map(d => {
                    newFiles.push(d.videoUrl);
                });
                this.showImgList = newFiles;
                this.video = row[0];
                this.$forceUpdate();
            }
        },
        // 裁剪图片回调
        selectImg(imgUrl) {
            this.cacheImgUrl = imgUrl;
        },
        async submit() {
            // 确定封面
            // 上传图片
            if (!this.cacheImgUrl) {
                this.$emit('coverImgUrl', '');
                return;
            }
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${oss.folderName}/${date}/`;
            let res = await baseToOss({
                base64FileStr: this.cacheImgUrl,
                filePath: url,
            });
            console.log('-------submit', res.data.webUrl);
            this.$emit('submitCoverDialog', res.data.webUrl);
            this.dialogFormVisible = false;
            this.close();
        },
        close() {
            this.dialogFormVisible = false;
            this.showImgList = [];
            this.cacheImgUrl = '';
        },
    },
};
</script>
<style lang="less" scoped></style>
