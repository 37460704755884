<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="adDialogVisible"
            direction="rtl"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="close"
        >
            <sc-form-v2
                v-if="show"
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                :uploadConfig="uploadConfig"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="close">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
const contentTypeInnerLink = [
    { label: '笔记', value: 1 },
    { label: '案例', value: 2 },
    // { label: '案例切片', value: 10 },
    { label: '外部链接', value: 15 },
];
import { contentLevelSave } from '@/api/mkt/feed-sort';
import { uploadConfigData } from '@/config.js';
import mixins from '@/mixins';
import { getContentById } from '@/api/mkt/feed-sort';
export default {
    name: 'feed-sort-edit',
    mixins: [mixins],
    props: {},
    data() {
        return {
            show: false,
            uploadConfig: {},
            title: '编辑内容',
            adDialogVisible: false,

            columns: {
                contentType: {
                    name: '内容类型',
                    type: 'select',
                    hidden: false,
                    options: {
                        placeholder: '',
                        options: contentTypeInnerLink,
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                linkUrl: {
                    name: '链接',
                    type: 'hidden',
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '' }],
                },
                contentId: {
                    name: '内容ID',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '内容ID不能为空' }],
                },
                title: {
                    name: '内容名称',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                    rules: [{ required: true, message: '内容名称不能为空' }],
                },

                orderSort: {
                    name: '排序值',
                    type: 'number',
                    hidden: false,
                    options: {
                        placeholder: '请输入排序值，数值越小权重越高',
                        min: 0,
                        max: 9999,
                    },
                    rules: [{ required: true, message: '请输入排序值' }],
                },
                cover: {
                    name: '展示封面',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传图片' }],
                },
            },
            result: {
                contentId: '',
                orderSort: '',
                title: '',
                cover: [],
                linkUrl: '',
                contentType: '',
            },
        };
    },
    created() {
        this.getOssConfig();
    },
    methods: {
        open() {
            this.adDialogVisible = true;
            this.show = true;
            this.$nextTick(() => {
                this.$refs.groupform.$refs['CRM_TAG_GROUP'].clearValidate();
                this.result = {
                    contentId: '',
                    orderSort: '',
                    title: '',
                    linkUrl: '',
                    contentType: '',
                    cover: [],
                };
                this.result.cover = [];
            });
            this.title = '新增内容';
            this.setOssInfo();
        },
        setOssInfo() {
            this.$nextTick(() => {
                uploadConfigData.oss.accessKeyId = this.ossConfig.stsKeyId;
                uploadConfigData.oss.accessKeySecret = this.ossConfig.stsSecret;
                uploadConfigData.oss.stsToken = this.ossConfig.token;
                this.uploadConfig = uploadConfigData;
            });
        },
        edit(row) {
            this.adDialogVisible = true;
            this.show = true;
            this.setOssInfo();
            this.result = { ...row };
            if (row.id && row.contentType == 15) {
                this.columns.title.options.disabled = false;
                this.columns.linkUrl.type = 'input';
                this.columns.contentId.type = 'hidden';
            } else if (row.id && row.contentType != 15) {
                this.columns.title.options.disabled = true;
                this.columns.linkUrl.type = 'hidden';
                this.columns.contentId.type = 'input';
            }
            this.result.cover = [{ url: row.cover }];
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                let _path = this.result.cover[0].url;
                let params = { ...this.result, cover: _path, contentName: this.result.title };
                contentLevelSave(params).then(res => {
                    this.$message.success(res.message);
                    this.close();
                    this.$emit('refresh');
                });
            });
        },
        close() {
            this.result = {
                contentId: '',
                orderSort: '',
                title: '',
                cover: [],
                linkUrl: '',
                contentType: '',
            };
            this.show = false;
            this.adDialogVisible = false;
            this.$emit('refresh');
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            console.log(data);
            if (data.row.key == 'contentType') {
                if (data.value == 15) {
                    this.columns.title.options.disabled = false;
                    this.columns.linkUrl.type = 'input';
                    this.columns.contentId.type = 'hidden';
                } else {
                    this.columns.title.options.disabled = true;
                    this.columns.linkUrl.type = 'hidden';
                    this.columns.contentId.type = 'input';
                }

                this.$set(this.result, 'contentId', '');
                this.$set(this.result, 'title', '');
                this.$set(this.result, 'linkUrl', '');
            }
            if (
                data.row.key == 'contentId' &&
                this.result.contentId &&
                this.result.contentType != 15
            ) {
                this.validContentId(this.result.contentId);
            }
        },
        async validContentId(id) {
            let res = await getContentById({ id: id });
            if (!res.data) {
                this.$message.info('查询不到对应标题');
                this.result.title = '';
                this.result.contentId = '';
                return false;
            }
            this.$set(this.result, 'title', res.data.title);
            this.$set(this.result, 'contentType', res.data.contentType);
            return true;
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            console.log(data);
            if (data.row.key == 'cover' && data.value?.length) {
                let reader = new FileReader();
                reader.readAsDataURL(data.value[0].raw);
                reader.onload = e => {
                    const img = e.target.result;
                    const image = new Image();
                    image.src = img;
                    image.onload = () => {
                        this.result.coverHeight = image.height;
                        this.result.coverWidth = image.width;
                    };
                };
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
