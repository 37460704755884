<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="addialogVisible"
            direction="rtl"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="closeDrawer"
            :destroy-on-close="true"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="2"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <template v-slot:contentTitle>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">内容标题</div>
                        </template>
                        <div
                            style="display: flex;justify-content: space-between;padding-right: 20px;"
                        >
                            <el-input
                                style="width: 80%;"
                                v-model="result.contentTitle"
                                placeholder="请输入内容标题"
                                maxlength="30"
                                show-word-limit
                                size="small"
                            ></el-input>
                            <el-button style="" size="mini" type="primary" @click="onOpenContent">
                                选择内容
                            </el-button>
                            <!-- <el-select
                                v-model="result.contentTitle"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请选择地址"
                                :remote-method="getSearchAddress"
                                :loading="nameLoading"
                                size="mini"
                                style="width:100%;padding-right: 20px;"
                            >
                                <el-option
                                    v-for="item in nameList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    @click.native="clickOption(item)"
                                >
                                    <div>
                                        <span style="float: left">{{ item.name }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            {{ item.cityname + item.adname + item.address }}
                                        </span>
                                    </div>
                                </el-option>
                            </el-select> -->
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:contentYear>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">年</div>
                        </template>
                        <el-date-picker
                            v-model="result.contentYear"
                            type="year"
                            size="small"
                            placeholder="选择年"
                            format="yyyy"
                            value-format="yyyy"
                        ></el-date-picker>
                    </el-descriptions-item>
                </template>
                <template v-slot:contentWeek>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">周</div>
                        </template>
                        <el-select
                            style="width: 220px;"
                            v-model="result.contentWeek"
                            size="small"
                            placeholder="请选择周"
                            key="contentWeek"
                        >
                            <el-option
                                v-for="item in weekArr"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-descriptions-item>
                </template>
                <template v-slot:cover>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">封面</div>
                        </template>
                        <div class="upload-box">
                            <Upload
                                ref="uploadbox"
                                :limitNum="1"
                                :width="90"
                                :height="90"
                                :accept="['jpg', 'gif', 'jpeg', 'png']"
                                :fileType="'image'"
                                :showImgList="cover"
                                @uploadImgList="uploadImgList"
                                tip="建议尺寸：690*492像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M"
                            ></Upload>
                            <el-button @click="imgSelectClick" size="small" icon="el-icon-plus">
                                去案例中选择封面图
                            </el-button>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="closeDrawer">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
        <content-dialog
            v-if="visibleContent"
            :visible="visibleContent"
            listType="2"
            :read="false"
            @closeDiag="closeContentDialog"
            @submit="submitContent"
        ></content-dialog>
        <!-- 选择案例图片 -->
        <workCollect
            ref="workCollect"
            @innerDrawerSubmit="innerDrawerSubmit"
            :limitNum="1"
            :selectList="cover"
        ></workCollect>
    </div>
</template>
<script>
import Upload from '@/components/upload-new';
import contentDialog from '@/page/mkt/components/content-dialog.vue';
import ossState from '@/utils/ossClient.js';
import { checkWeek, saveWeek, getCaseInfo, getContentList } from '@/api/mkt/week-case.js';
import workCollect from '../../designer-ranking/components/work-collect.vue';
export default {
    props: {},
    components: { Upload, contentDialog, workCollect },
    data() {
        return {
            title: '新增',
            addialogVisible: false,
            dialogVisible: false,
            columns: {
                contentTitle: {
                    name: '内容标题',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    spanCol: 2,
                    rules: [{ required: true, message: '', trigger: 'blur' }],
                },
                designWorksId: {
                    name: '内容ID',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    spanCol: 2,
                    rules: [{ required: true, message: '请填写内容ID', trigger: 'blur' }],
                },
                contentYear: {
                    name: '年份',
                    type: 'date',
                    options: {
                        placeholder: '',
                        options: {
                            type: 'contentYear',
                        },
                    },
                    spanCol: 1,
                    rules: [{ required: true, message: '', trigger: 'change' }],
                },
                contentWeek: {
                    name: '第几周',
                    type: 'input',
                    options: {
                        placeholder: '',
                        disabled: false,
                    },
                    spanCol: 1,
                },
                cover: {
                    name: '内容封面',
                    type: 'input',
                    options: {
                        placeholder: '',
                        disabled: false,
                    },
                    spanCol: 2,
                },
            },
            result: {
                contentTitle: '',
                designWorksId: '',
                contentYear: '',
                contentWeek: '',
                cover: '',
                id: '',
            },
            cover: [],
            currentDate: {
                contentYear: '',
                contentWeek: '',
            },
            weekArr: [],
            nameList: [], //案例搜索列表
            nameLoading: false, //案例搜索
            visibleContent: false, //选择内容弹窗
        };
    },
    mounted() {
        ossState.getOssClient();
    },
    methods: {
        open(row = {}) {
            console.log(row, 'show-----row--------');
            this.addialogVisible = true;
            this.initDate();
            if (row.id) {
                this.getInfo(row);
                this.title = '编辑';
            }
            this.$forceUpdate();
        },
        getInfo(row) {
            let params = {
                id: row.id,
            };
            getCaseInfo(params).then(res => {
                console.log(res, 'res-----');
                for (let key in this.result) {
                    this.result[key] = res.data[key];
                }
                this.result.contentYear = res.data.contentYear + '';
                this.result.contentWeek = res.data.contentWeek + '';
                this.result.contentMonth = res.data.contentMonth + '';
                this.cover = [
                    {
                        url: res.data?.cover || '',
                    },
                ];
            });
        },
        // 打开选择内容弹窗
        onOpenContent() {
            this.visibleContent = true;
        },
        // 打开选择封面弹窗
        imgSelectClick() {
            if (!this.result.designWorksId) {
                this.$message.error('请先选择内容,完善内容Id');
                return;
            }
            let imgList = this.cover;
            let urls = imgList.map(item => item.url);
            this.$refs.workCollect.openDrawer(
                { contentId: this.result.designWorksId, urls: urls },
                '案例'
            );
        },
        innerDrawerSubmit(caseCoverImg, addContentIds) {
            console.log(caseCoverImg, 'case');
            this.cover = caseCoverImg;
            this.result.cover = caseCoverImg[0].url;
        },
        // 选中内容
        submitContent(data) {
            console.log('------data', data);
            this.visibleContent = false;
            this.result.designWorksId = data.contentId || '';
            this.result.contentTitle = data.contentTitle || '';
            this.cover =
                (data.cover && [{ url: data.cover }]) ||
                (data.fileList && data.fileList[0] && [{ url: data.fileList[0].url }]) ||
                [];
            this.result.cover =
                data.cover || (data.fileList && data.fileList[0] && data.fileList[0].url) || '';
            if (data.contentId) {
                this.$refs.groupform.$refs.CRM_TAG_GROUP.clearValidate();
            }
            // this.result.cover = data.fileList && data.fileList[0] && data.fileList[0].url;
            // this.cover = (data.coverImage && [{ url: data.coverImage }]) || [];
        },
        closeContentDialog(type) {
            this.visibleContent = false;
        },
        initDate() {
            let now = new Date();
            let contentYear = now.getFullYear();
            let firstDayOfYear = new Date(now.getFullYear(), 0, 1);
            let daysDiff = (now - firstDayOfYear) / (1000 * 60 * 60 * 24);
            let contentWeek = Math.ceil((daysDiff + (7 - firstDayOfYear.getDay())) / 7);
            this.currentDate = { contentYear, contentWeek };
            this.result.contentYear = contentYear + '';
            this.result.contentWeek = contentWeek + '';
            this.weekArr = [];
            let arr = [];
            for (let i = 1; i <= 52; i++) {
                let item = { id: i + '', name: '第' + i + '周' };
                arr.push(item);
            }
            this.weekArr = arr;
            console.log('-----------33333333', contentWeek, this.weekArr);
        },
        // 关闭详情抽屉
        closeDrawer(type) {
            this.addialogVisible = false;
            this.result = {
                contentTitle: '',
                designWorksId: '',
                contentYear: '',
                contentWeek: '',
                cover: '',
                id: '',
            };
            // this.$refs.uploadbox.imgList = [];
            this.cover = [];
            if (type) {
                // 清空数据
                this.$emit('handleClose');
            }
        },
        async handleSubmit() {
            console.log('-----------33333333', this.result);
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', async () => {
                //校验回调
                console.log(this.result, 88888);
                let params = {
                    ...this.result,
                };
                if (this.result.id) {
                    this.saveWeekFn(params);
                } else {
                    let checkres = await checkWeek(params);
                    console.log('----------checkres', checkres);
                    if (checkres.errorCode == 200 && checkres.data) {
                        this.$confirm(
                            `当前已有${this.result.contentYear}年 ${this.result.contentWeek} 周数据，是否继续添加?`,
                            '提示',
                            {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning',
                            }
                        ).then(async () => {
                            this.saveWeekFn(params);
                        });
                    } else {
                        this.saveWeekFn(params);
                    }
                }
            });
        },
        async saveWeekFn(params) {
            await saveWeek(params).then(res => {
                console.log(res, 'res-----');
                if (res) {
                    this.closeDrawer(true);
                    this.$emit('handleClose');
                }
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            console.log('changeFn', data);
            if (data.row.key == 'designWorksId' && this.result.designWorksId) {
                this.validContentId(this.result.designWorksId);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            console.log('clickFn', data);
        },
        // 根据id查询内容
        async validContentId(id) {
            let res = await getContentList({
                contentId: id,
                contentType: 2,
                matchType: 2,
                page: 1,
                size: 50,
            });
            if (!res.data?.children?.length) {
                this.$message.info('查询不到对应标题');
                this.result.contentTitle = '';
                this.result.designWorksId = '';
                this.result.cover = '';
                this.cover = [];
                return false;
            }
            this.result.contentTitle = res.data?.children[0]?.contentTitle;
            this.result.cover = res.data?.children[0]?.fileList[0]?.url;
            this.cover =
                (res.data?.children[0]?.fileList[0]?.url && [
                    { url: res.data.children[0].fileList[0]?.url },
                ]) ||
                [];
            return true;
        },
        // 更新图片
        uploadImgList(list, item) {
            this.cover = list || [];
            this.result.cover = list[0] && list[0].url;
        },
        // 回显案例名称
        getSearchAddress(query) {
            console.log('--------------getSearch', query, this.mapAddress);
            if (query && query.trim() !== '') {
                let params = { keywords: query, city: '北京' }; //默认给北京
                this.nameLoading = true;
                this.mapAddress = query;
                getCaseSearch(params).then(res => {
                    console.log(
                        '--------pois',
                        res.errorCode == '200' &&
                            res.data &&
                            res.data.status == '1' &&
                            res.data.info === 'OK',
                        JSON.parse((res.data && res.data.pois) || '[]')
                    );
                    if (
                        res.errorCode == '200' &&
                        res.data &&
                        res.data.status == '1' &&
                        res.data.info === 'OK'
                    ) {
                        this.nameLoading = false;
                        this.nameList = JSON.parse((res.data && res.data.pois) || '[]');
                    } else {
                        this.$message.info(res.message);
                    }
                });
            } else {
                this.nameList = [];
                this.mapAddress = '';
            }
        },
        clickOption(item) {
            console.log('---------clickOption', item);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
<style>
/deep/ .el-button--text {
    display: none;
}
</style>
