<template>
    <div class="mkt">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="风格图上传" name="1"></el-tab-pane>
            <el-tab-pane label="情绪版上传" name="2"></el-tab-pane>
        </el-tabs>
        <div v-if="activeName == 1">
            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="clickFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>
            <div class="btn-box">
                <el-button type="primary" class="top-btn" size="small" @click="onAdd">
                    新建图片
                </el-button>
            </div>
            <div class="content">
                <sc-table-v2
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeight"
                    :read-table="true"
                    :load="loading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <!-- //插槽例子，指定某一列用插槽写入 -->
                    <template v-slot:url>
                        <el-table-column label="图片">
                            <template slot-scope="scope">
                                <div class="">
                                    <el-image
                                        v-if="scope.row.url"
                                        :src="scope.row.url"
                                        alt=""
                                        style="width:50px;height:50px;"
                                    />

                                    <div style="flex-shrink:0;">
                                        {{ scope.row.name }}
                                        <el-tag size="mini" v-if="scope.row.isSuperior == 0">
                                            领导
                                        </el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="onEdit(scope.row)">
                                    编辑
                                </el-button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <el-button type="text" size="small" @click="deleteStyle(scope.row)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>

            <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
        </div>
        <div v-else>
            <div v-show="activeName == 2">
                <emotion-list></emotion-list>
            </div>
        </div>
    </div>
</template>

<script>
import editDialog from './components/details.vue';
import emotionList from './emotion-list.vue';
import mixins from '@/mixins';
import { getList, getStyleFileAll, deleteStyle } from '@/api/mkt/style-test';
export default {
    name: 'staff-index',
    mixins: [mixins],
    components: { editDialog, emotionList },
    data() {
        return {
            activeName: '1',
            loading: false,
            moreDown: false,
            tableHeight: window.innerHeight - 285,
            LS: { 'min-width': '100px' }, //设置label宽度
            tableHead: [
                { name: '图片', key: 'url' },
                { name: '题目', key: 'questionsName' },
                { name: '测试分类', key: 'styleCategory' },
                { name: '所属类别', key: 'ofCategory' },
                // { name: '所属色彩', key: 'ofColor', width: 130 },
                // { name: '标题', key: 'titleName' },
                // { name: '色块名', key: 'colorName' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '160',
                },
                {
                    name: 'ID',
                    key: 'id',
                },
            ],
            list: [{}],
            columns: {
                styleCategory: {
                    name: '测试分类',
                    type: 'select',
                    options: {
                        defaultValue: [],
                        clearable: true,
                        multiple: false,
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'id',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                ofCategory: {
                    name: '所属类别',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        clearable: true,
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                k1: {
                    name: '',
                    spanCol: 1,
                },
                k2: {
                    name: '',
                    spanCol: 1,
                },
            },
            result: {
                styleCategory: '',
                ofCategory: '',
                ofColor: '',
            },
            tableAddConfig: {},
            page: {
                page: 1,
                size: 30,
                count: 0,
                index: 1,
            },

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    watch: {
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
    },
    created() {
        this.getStyleFileAll();
        this.init();
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event, 88);
            this.activeName = tab.name;
        },
        handleClickMore() {
            this.tableHeight = window.innerHeight - 280;
        },
        handleClose() {
            this.init();
        },
        init() {
            let params = {
                styleCategory: this.result.styleCategory,
                ofCategory: this.result.ofCategory,
                ofColor: this.result.ofColor,
                page: this.page.page,
                size: this.page.size,
            };
            getList(params).then(res => {
                this.list = res.data.children || [];
                this.page.count = res.data.total;
            });
        },
        onEdit(row) {
            this.$refs.editDialog.edit(row);
        },
        onAdd() {
            this.$refs.editDialog.open();
        },
        async getStyleFileAll() {
            const res = await getStyleFileAll();
            if (res?.isSuccess) {
                this.columns['styleCategory'].options.options = res.data.styleCategory;
                this.columns['ofCategory'].options.options = res.data.ofCategory;
                // this.columns['ofColor'].options.options = res.data.ofColor;
            }
        },
        deleteStyle(row) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    deleteStyle({
                        id: row.id,
                    }).then(res => {
                        if (res.errorCode == 200) {
                            this.$message.success('删除成功');
                            this.init();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        rowClick() {},
        dbClick() {},
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
        pageChange(val) {
            this.page.page = val;
            this.page.index = val;
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
                this.page.index = 1;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.btn-box {
    padding: 10px 30px 0;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
