<template>
    <div class="mkt">
        <Search @callback="onCallback"></Search>
        <div class="content">
            <div>
                <el-button
                    type="primary"
                    style="margin-left:10px"
                    size="small"
                    @click="createTopic('新增专题')"
                >
                    新增专题
                </el-button>
                <sc-table-v2
                    title=""
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :table-height="height"
                    :read-table="true"
                    :load="false"
                    ref="table"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                >
                    <template v-slot:enabled>
                        <el-table-column label="是否启用" width="120">
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.enabled"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="onChangeEnabled(scope.row)"
                                ></el-switch>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:createName>
                        <el-table-column label="创建人">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.createName
                                    }}{{ scope.row.account && '（' + scope.row.account + '）' }}
                                </div>
                                <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                                    {{ scope.row.createUserJob }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="220">
                            <template slot-scope="scope">
                                <span
                                    class="btn-style"
                                    type="text"
                                    @click="ondeleteArticle(scope.row)"
                                >
                                    删除
                                </span>
                                <span
                                    class="btn-style"
                                    type="text"
                                    @click="createTopic('编辑话题', scope.row)"
                                >
                                    编辑
                                </span>
                                <span
                                    class="btn-style"
                                    type="text"
                                    @click="createTopic('管理专题', scope.row)"
                                >
                                    管理专题
                                </span>
                                <i class="el-icon-paperclip btn-style"></i>
                                <i class="el-icon-set-up btn-style"></i>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
            <drawer-dialog ref="drawerDialog"></drawer-dialog>
        </div>
    </div>
</template>

<script>
import Search from './search';
import drawerDialog from './details.vue';
import { getList, onIsEnabled, removeArticle } from '@/api/mkt/article-group.js';
export default {
    components: { drawerDialog, Search },
    data() {
        return {
            tableHead: [
                {
                    name: '专题名称', //表头名称
                    key: 'specialName', //数据列的key值
                },
                { name: '所属分类', key: 'categoryName' },
                { name: '内容数量', key: 'contentLabelId' },
                { name: '运营标签', key: 'relevanceLabelParams' },
                { name: '是否启用', key: 'enabled' },
                { name: '公司范围', key: 'companys' },
                { name: '创建人', key: 'createName' },
                { name: '创建时间', key: 'createTime', width: 160 },
                { name: '操作', key: 'control' },
            ],
            height: 650,
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            list: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        onCallback(e) {
            var param = {};
            let obj = e;
            for (var key in obj) {
                if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                    param[key] = obj[key];
                }
            }
            this.getData(param);
        },

        getData(param) {
            let searchData = Object.assign(this.page, param);
            getList(searchData).then(res => {
                if (res?.isSuccess) {
                    console.log(res, '列表数据');
                    this.list = res.data.children || [];
                    this.page.count = res.data.total || 0;
                }
            });
        },
        createTopic(title, row) {
            this.$refs.drawerDialog.openDrawer(title, row);
        },
        pageChange(v) {
            console.log(v, 'v');
        },
        pageSizesChange(e) {
            console.log(e, 'e');
        },
        ondeleteArticle(row) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    removeArticle(row.id).then(res => {
                        if (res?.isSuccess) {
                            this.$message({
                                type: 'success',
                                message: '删除成功',
                            });
                            this.getData();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        onChangeEnabled(row) {
            onIsEnabled(row.id).then(res => {
                if (res?.isSuccess) {
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    });
                    this.getData();
                }
            });
        },
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-article/clear');
    },
};
</script>

<style>
.content {
    margin: 10px 15px;
}
.btn-style {
    color: #409eff;
    margin-right: 12px;
    cursor: pointer;
}
</style>
