<template>
    <div>
        <div class="search-box search-top-box">
            <span class="title">时间范围：</span>
            <span
                class="item"
                :class="{ active: item.selected }"
                v-for="(item, index) in timeList"
                @click="timeClick(item)"
                :key="index"
            >
                {{ item.name }}
            </span>

            <el-date-picker
                v-model="timeValue"
                type="daterange"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="timeChange"
            ></el-date-picker>
        </div>
        <div class="search-box">
            <span class="title">内容类型：</span>
            <span
                class="item"
                :class="{ active: item.selected }"
                v-for="(item, index) in typeList"
                :key="index"
                @click="typeClick(item)"
            >
                {{ item.name }}
            </span>
        </div>
        <div class="table">
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="true"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            ></sc-table-v2>
        </div>
    </div>
</template>
<script>
import { formatDay } from '@/utils/util.js';
import { getBrowsingHistory } from '@/api/mkt/user';
export default {
    name: 'browse-history',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            timeValue: '',
            typeList: [
                { name: '全部', key: 0, selected: true },
                { name: '案例', key: 2 },
                { name: '笔记', key: 1 },
                { name: '设计师', key: 7 },
                // { name: '图片', key: 6 },
                // { name: '话题', key: 5 },
                // { name: '灵感集', key: 3 },
                { name: '案例切片', key: 9 },
            ],
            timeList: [
                { name: '全部', key: '', selected: true },
                { name: '最近7日', key: 7, selected: false },
                { name: '最近30日', key: 30, selected: false },
            ],
            result: { contentType: 0 },

            tableHead: [
                { name: '访问时长', key: 'dwellTime' },
                // { name: '分享人', key: 'mobile' },
                { name: '类型', key: 'accessType' },
                { name: '访问内容', key: 'accessContent' },
                { name: '访问时间', key: 'accessTime' },
            ],
            list: [],
            tableHeightCalc: window.innerHeight - 510,
            page: {
                page: 1,
                size: 9999,
            },
        };
    },
    methods: {
        async init() {
            let res = await getBrowsingHistory({
                accountId: this.id,
                page: this.page.page,
                size: this.page.size,
                contentType: this.result.contentType,
                startTime: this.page.startTime,
                endTime: this.page.endTime,
            });
            if (res.isSuccess) {
                this.list = res.data;
            }
        },
        timeClick(item) {
            this.timeList.map(i => {
                if (i.key == item.key) {
                    i.selected = true;
                } else {
                    i.selected = false;
                }
            });
            this.timeValue = '';
            if (item) {
                let tempTime = new Date();
                let time = formatDay(tempTime);
                if (item.key == 7) {
                    let startTime = formatDay(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
                    this.page.startTime = startTime + ' 00:00:00';
                    this.page.endTime = time + ' 23:59:59';
                } else if (item.key == 30) {
                    let startTime = formatDay(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
                    this.page.startTime = startTime + ' 00:00:00';
                    this.page.endTime = time + ' 23:59:59';
                }
            } else {
                this.page.startTime = '';
                this.page.endTime = '';
            }

            this.init();
        },
        convertSecondsToHMS(row) {
            console.log(row, 8);
            if (!row.dwell_time) return 0;
            let seconds = row.dwell_time;
            let hours = Math.floor(seconds / 3600); // 计算小时部分
            hours = hours ? hours + '小时' : '';
            seconds %= 3600; // 取余得到不足1小时的秒数
            let minutes = Math.floor(seconds / 60); // 计算分钟部分
            minutes = minutes < 10 ? `0${minutes}分` : minutes + '分';
            seconds %= 60; // 取余得到不足1分钟的秒数
            seconds = seconds + '秒';
            let str = `${hours}${minutes}${seconds}`;
            return str; // 返回结果
        },

        timeChange(value) {
            this.timeList.map(i => (i.selected = false));
            if (value.length) {
                this.page.startTime = value[0] + ' 00:00:00';
                this.page.endTime = value[1] + ' 23:59:59';
            } else {
                this.page.startTime = '';
                this.page.endTime = '';
            }
            this.init();
        },
        pageChange(val) {
            this.page.page = val;
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
        typeClick(item) {
            for (let i of this.typeList) {
                if (i.key == item.key) {
                    i.selected = true;
                    this.page.type = i.key;
                } else {
                    i.selected = false;
                }
            }
            // console.log(item, 88);
            this.result.contentType = item.key;
            this.init();
        },
    },
    created() {
        this.init();
    },
};
</script>
<style scoped lang="less">
.search-box {
    margin: 20px;
    .item {
        padding: 5px 10px;
        background: #a4adb3 100%;
        color: #fff;
        cursor: pointer;
        margin: 0 10px;
    }
    .active {
        background: #000;
    }
}
.search-top-box {
    margin-top: 40px;
}
</style>
