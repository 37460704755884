<template>
    <div class="table">
        <div
            style="display: flex;justify-content: space-between;align-items: center;margin:20px 0;"
        >
            <span style="font-size:14px;">空间信息</span>
            <el-cascader
                v-model="value"
                :options="options"
                placeholder="添加空间"
                :props="{
                    expandTrigger: 'hover',
                    value: 'id',
                    label: 'spaceName',
                    children: 'childSpaces',
                }"
                ref="spaceName"
                clearable
                size="mini"
                :show-all-levels="false"
                @change="handleChangeSpace"
            ></el-cascader>
        </div>
        <div></div>
        <table
            ref="tableList"
            class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
        >
            <thead class="el-table__header-wrapper">
                <tr class="el-table__row">
                    <td class="el-table__cell" width="120">
                        <div class="cell">空间名称</div>
                    </td>
                    <td class="el-table__cell">
                        <div class="cell">图片</div>
                    </td>
                    <td class="el-table__cell">
                        <div class="cell">描述</div>
                    </td>
                    <td class="el-table__cell" width="220">
                        <div class="cell">操作</div>
                    </td>
                </tr>
            </thead>
            <draggable
                @start="onStart"
                style="display: contents;"
                v-model="tableData"
                draggable=".sortItem"
            >
                <tbody
                    :class="list.spaceName === '户型图' ? 'notSort' : 'sortItem'"
                    v-for="(list, listInd) in tableData"
                    :key="list.spaceId"
                >
                    <tr class="el-table__row" v-for="(item, index) in list.groupList" :key="index">
                        <td
                            class="el-table__cell"
                            v-if="index === 0"
                            :rowspan="list.groupList.length"
                        >
                            <div class="cell">
                                <img
                                    src="../../../../assets/images/drag.png"
                                    alt=""
                                    width="10px"
                                    height="16px"
                                    style="vertical-align: middle;margin-right:5px"
                                    v-if="list.spaceName !== '户型图'"
                                />

                                <span>{{ list.spaceName }}</span>
                            </div>
                        </td>
                        <td class="el-table__cell">
                            <div class="cell upload-box">
                                <self-upload
                                    :limitNum="30"
                                    :width="56"
                                    :height="56"
                                    fileType="image"
                                    :showImgList="item.picList"
                                    :rowIndex="index"
                                    :listIndex="listInd"
                                    @uploadImgList="upList => getUploadImgList(upList, item)"
                                    :ossClientCur="ossClient"
                                    @sortImgList="upList => sortImgList(upList, item)"
                                    :deleteConfirm="deleteFile"
                                ></self-upload>
                            </div>
                        </td>
                        <td class="el-table__cell">
                            <div class="cell">
                                <el-input
                                    type="textarea"
                                    maxlength="200"
                                    :placeholder="item.desc"
                                    :autosize="{ minRows: 3, maxRows: 10 }"
                                    :rows="3"
                                    :disabled="item.picList.length <= 0"
                                    v-model="item.picDescribe"
                                    show-word-limit
                                ></el-input>
                            </div>
                        </td>
                        <td class="el-table__cell">
                            <div class="cell">
                                <el-button
                                    v-if="list.spaceName !== '户型图'"
                                    type="text"
                                    size="small"
                                    @click="handleDel(list, index, listInd)"
                                >
                                    删除
                                </el-button>
                                <el-button
                                    size="small"
                                    type="text"
                                    v-if="list.groupList.length > 1 && index != 0"
                                    @click="handleUpSort(list.groupList, index, 1)"
                                >
                                    上移
                                </el-button>
                                <el-button
                                    size="small"
                                    type="text"
                                    v-if="
                                        list.groupList.length > 1 &&
                                            index + 1 != list.groupList.length
                                    "
                                    @click="handleUpSort(list.groupList, index, -1)"
                                >
                                    下移
                                </el-button>
                                <el-button
                                    v-if="list.spaceName !== '户型图' && item.picList.length > 0"
                                    type="text"
                                    size="small"
                                    @click="addGroup(list.groupList, index, list.spaceName)"
                                >
                                    新增分组
                                </el-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </draggable>
        </table>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
            append-to-body
        >
            <el-input v-model.trim="selfSpaceName" placeholder="请输入自定义空间"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel" size="small">取 消</el-button>
                <el-button type="primary" @click="save" size="small" v-preventReClick="5000">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    getSpaceList,
    getDefaultSpace,
    saveCustomSpace,
    delWorksSpace,
    delWorksGroup,
    delWorksFile,
} from '../../../../api/mkt/exhibit';
import draggable from 'vuedraggable';
import selfUpload from '../../../../components/upload.vue';
import getOssMixin from '@/mixins/getOssMixin';

export default {
    name: 'add-table',
    components: { selfUpload, draggable },
    mixins: [getOssMixin],
    props: {
        workFiles: {
            type: Array,
            default: () => [],
        },
        hasEdit: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        workFiles(newV) {
            this.tableData = [];
            if (!this.hasEdit) return;
            const newData = newV.map(item => {
                if (!item.groupList || !item.groupList.length) {
                    item.groupList = [
                        {
                            desc: `请输入描述`,
                            picDescribe: '',
                            picList: [],
                        },
                    ];
                }
                return item;
            });
            this.tableData = newData;
        },
    },
    data() {
        return {
            selfSpaceName: '',
            dialogVisible: false,
            value: '',
            options: [],
            tableData: [{ groupList: [{ picList: [] }] }],
            mergeObj: {},
            groupList: [],
            picList: [],
            dragOptions: {
                dragOptions: {
                    animation: 200, // 动画时间，单位毫秒
                    disabled: true, // 是否禁用拖拽功能
                    sort: true, // 是否启用排序功能
                    filter: '.notSort', // 不可拖拽元素的选择器
                    draggable: '.sortItem',
                },
            },
            local: { nodes: [], parentId: '' },
        };
    },
    created() {
        this.tableData = [];
        this.getSpaceList();
        if (!this.hasEdit) {
            this.getDefaultSpace();
        }
        this.getOss(); //调取mixins中获取
    },
    mounted() {},
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(() => {
                    done();
                })
                .catch(() => {});
        },
        save() {
            if (!this.selfSpaceName) {
                this.$message.warning('不能为空');
                return;
            }
            saveCustomSpace({
                parentId: this.local.parentId,
                spaceName: this.selfSpaceName,
            }).then(res => {
                if (res.isSuccess) {
                    this.options.map(item => {
                        if (item.id == this.local.parentId && item.childSpaces.length) {
                            item.childSpaces.splice(-1, 0, {
                                spaceName: this.selfSpaceName,
                                id: res.data,
                                parentId: this.local.parentId,
                            });
                        }
                    });
                    this.dialogVisible = false;
                    this.value = '';
                    // this.addSpace(this.local.nodes, this.selfSpaceName, res.data);
                }
            });
        },
        cancel() {
            this.dialogVisible = false;
            this.value = '';
        },
        handleUpSort(row, index, num) {
            this.swapItem(row, index, index - num);
        },
        swapItem(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
        },
        getTableData() {
            return this.tableData;
        },
        onStart(evt) {
            if (evt.oldIndex === 0) {
                return false;
            }
        },
        getDefaultSpace() {
            getDefaultSpace().then(res => {
                this.tableData = res.data.map(item => {
                    if (!item.groupList || !item.groupList.length) {
                        item.groupList = [
                            {
                                picDescribe: '',
                                desc: `请输入描述`,
                                picList: [],
                            },
                        ];
                    }
                    return item;
                });
            });
        },
        getSpaceList() {
            getSpaceList().then(res => {
                this.options = res.data.map(item => {
                    if (item.childSpaces?.length) {
                        item.childSpaces.push({
                            spaceName: '自定义空间',
                            id: 'special',
                        });
                        item.childSpaces.map(child => {
                            if (!child.childSpaces?.length) {
                                delete child.childSpaces;
                                return;
                            }
                        });
                    }
                    return item;
                });
            });
        },
        handleChangeSpace(val) {
            const nodes = this.$refs.spaceName.getCheckedNodes();
            console.log(nodes, 9999);
            this.local.nodes = nodes;

            if (nodes[0].value == 'special') {
                this.selfSpaceName = '';
                this.dialogVisible = true;
                this.local.parentId = nodes[0].parent.value;
                return;
            }
            this.addSpace(nodes);
        },
        addSpace(nodes, value, res) {
            console.log(nodes, '100000');
            let _index = this.tableData.findIndex(item => item.spaceName == nodes[0].label);
            if (_index != -1) {
                this.$message.info(nodes[0].label + '已经存在');
                return;
            }
            this.tableData.push({
                spaceName: value || nodes[0].label,
                groupList: [{ picDescribe: '', picList: [], desc: '请输入描述' }],
                id: this.tableData.length + 1,
                spaceId: res || nodes[0].value,
            });
        },
        getUploadImgList(list, item) {
            item.picList = list;
            this.$emit('data', this.tableData);
        },
        sortImgList(list, item) {
            item.picList = list;
            this.$emit('data', this.tableData);
        },
        addGroup(row, index, spaceName) {
            row.splice(index + 1, 0, {
                picList: [],
                picDescribe: '',
                desc: `请输入描述`,
            });
        },
        handleDel(space, index, listInd) {
            let row = space.groupList;
            let isRequest = false;
            if (space.id) {
                let deleApi = delWorksSpace;
                let delParams = {};
                if (row.length <= 1 && space.id) {
                    delParams = { id: space.id };
                    isRequest = true;
                }
                if (row.length > 1 && row[index].groupId) {
                    deleApi = delWorksGroup;
                    delParams = {
                        id: row[index].groupId,
                    };
                    isRequest = true;
                }
                if (isRequest) {
                    this.$confirm('此操作将删除相关内容, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    })
                        .then(() => {
                            deleApi(delParams).then(res => {
                                if (res?.isSuccess) {
                                    if (row.length > 1) {
                                        row.splice(index, 1);
                                    } else {
                                        this.tableData.splice(listInd, 1);
                                    }
                                }
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消删除',
                            });
                        });
                } else {
                    if (row.length > 1) {
                        row.splice(index, 1);
                    } else {
                        this.tableData.splice(listInd, 1);
                    }
                }
            }
        },
        deleteFile(item, fn) {
            if (item.id) {
                this.$confirm('此操作将删除相关内容, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        delWorksFile({ id: item.id }).then(res => {
                            if (res?.isSuccess) {
                                if (fn) {
                                    fn();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        });
                    });
            } else {
                if (fn) {
                    fn();
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
.table {
    margin: 0 30px;
}
.cell {
    display: flex;
    align-items: center;
}
/deep/ .upload-box {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 22px;
        color: #8c939d;
        width: 56px;
        height: 56px;
        line-height: 56px;
        text-align: center;
    }
}
/deep/ .el-textarea.is-disabled {
    .el-textarea__inner {
        border: none !important;
    }
}
</style>
