<template>
    <div class="options-container">
        <el-dialog
            :visible.sync="optionvisible"
            width="40%"
            :before-close="handleClose"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
            <el-dialog
                v-if="innervisible"
                :visible.sync="innervisible"
                :title="nodeList.length ? `${nodeList[0].label}-${nodeList[1].label}` : ''"
                width="40%"
                custom-class="inner-diag"
                :close-on-click-modal="false"
                append-to-body
            >
                <div class="option-out-box">
                    <div class="options-box">
                        <div
                            class="options-item"
                            :class="currentItem.value === item.value ? 'active-item ' : ''"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="handCheck(item)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                    <el-cascader
                        v-if="sonOption.length && currentItem.label == '标签'"
                        ref="cascader"
                        v-model="sonValue"
                        size="small"
                        :options="sonOption"
                        placeholder="请选择"
                        :props="{
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                        }"
                        :show-all-levels="false"
                        clearable
                        filterable
                    ></el-cascader>
                    <el-cascader
                        v-if="sonOption.length && currentItem.label == '分类'"
                        ref="cascader"
                        v-model="sonValue"
                        :show-all-levels="false"
                        size="small"
                        :options="sonOption"
                        :props="{
                            emitPath: false,
                            value: 'value',
                            label: 'label',
                            checkStrictly: true,
                        }"
                        clearable
                    ></el-cascader>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="primary" @click="handleSubmit('inner')">
                        确定
                    </el-button>
                </span>
            </el-dialog>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="handleSubmit('out')">确定</el-button>
            </span>
        </el-dialog>
        <Detail
            v-if="diag"
            :exhibit-diag="diag"
            :diag-type="diagType"
            :out-type="outType"
            @closeDiag="closeDiag"
        ></Detail>
    </div>
</template>

<script>
import { getLink, getLinkdetail, getLabellist, getCategoryList } from '@/api/mkt/market.js';
import Detail from './options-detail.vue';

export default {
    name: '',
    components: {
        Detail,
    },
    props: {
        optionvisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            data: [],
            currentItem: {
                value: '',
                label: '',
            },
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            nodeList: [],
            outType: '',
            sonValue: '',
            innervisible: false,
            diag: false,
            options: [],
            sonOption: [],
            diagType: '',
        };
    },
    mounted() {
        this.getLink();
    },
    methods: {
        async getLink() {
            const res = await getLink();
            if (res?.isSuccess) {
                res.data.map(item => {
                    if (item?.label == '营销活动' || item?.label == '专题') {
                        item.children = null;
                    }
                    if (item?.children) {
                        item.children.push({
                            value: 'detail',
                            label: '详情',
                        });
                        this.data.push(item);
                        if (item?.label == '话题') {
                            item.children = item.children.slice(1);
                        }
                        if (
                            item?.label &&
                            [
                                '作品',
                                '图片',
                                '设计师',
                                '智能匹配',
                                '风格测试',
                                '户型规划',
                                '装修预算',
                            ].includes(item.label)
                        ) {
                            item.children = item.children.slice(0, 1);
                        }
                    }
                });
            }
        },
        async getLinkdetail(id) {
            const res = await getLinkdetail(id);
            if (res?.isSuccess) {
                this.options = [];
                res.data.map(item => {
                    if (item?.label != '专题') {
                        this.options.push(item);
                    }
                });
                // this.innervisible = true;
            }
        },
        // 获取标签列表
        getLabelOptions() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.sonOption = res.data;
                }
            });
        },
        // 获取分类列表
        getCate() {
            // 获取分类列表
            getCategoryList({ isShow: 0 }).then(res => {
                if (res?.isSuccess) {
                    this.sonOption = res.data.children || [];
                }
            });
        },
        handleNodeClick(data, node) {
            this.nodeList = [];
            this.platform(node);
            if (data?.value && node?.parent?.parent) {
                if (data?.label == '指定列表') {
                    this.getLinkdetail(this.nodeList[0].value);
                    this.outType = this.nodeList[0].label;
                    this.innervisible = true;
                }
                if (data?.value === 'detail') {
                    this.diagType = this.nodeList[0].label;
                    this.diag = true;
                }
            }
        },
        handCheck(item) {
            this.sonValue = '';
            this.currentItem = item;
            if (this.currentItem.label === '专题') {
                this.diag = true;
                this.diagType = 'innerSub';
            } else if (this.currentItem.label === '话题') {
                this.diag = true;
                this.diagType = 'innerTop';
            } else if (this.currentItem.label === '设计师') {
                this.diag = true;
                this.diagType = 'innerDesign';
            }
            if (item.label == '标签') {
                this.getLabelOptions();
                this.currentItem.type = 2;
            } else if (item.label == '分类') {
                this.currentItem.type = 1;
                this.getCate();
            }
        },
        // 递归函数
        platform(node) {
            if (!node.parent) {
                // 若无父节点，则直接返回
                return;
            }
            this.nodeList.unshift({
                label: node.data.label,
                value: node.data.value,
            }); // 将value保存起来
            //调用递归
            this.platform(node.parent);
        },
        handleClose() {
            this.$emit('closeOption');
        },
        handleSubmit(type) {
            if (type == 'out') {
                let list = this.nodeList;
                list[1] = {
                    label: '默认列表',
                    value: '24',
                };
                this.$emit('sendOption', list);
            } else if (type == 'inner') {
                if (!this.sonValue) {
                    this.$message.warning('请至少选择其中一项');
                    return;
                }
                let data = this.$refs.cascader.getCheckedNodes();
                if (data) {
                    this.innervisible = false;
                    this.$emit('sendOption', this.nodeList, this.sonValue, this.currentItem, data);
                }
            }
            // if (type) {
            //     // this.$emit('sendOption', this.nodeList);
            // } else {
            //     this.$emit('sendOption', this.nodeList);
            // }
        },
        closeDiag(row) {
            this.diag = false;
            if (row) {
                this.innervisible = false;
                this.$emit('sendOption', this.nodeList, row.id, {
                    label: row.title,
                    type: row.type,
                    origin: row.origin,
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
.option-out-box {
    display: flex;
    align-items: center;
    .options-box {
        width: 100px;
        margin: 0 20px;
    }
    .options-item {
        margin: 10px 0;
        cursor: pointer;
    }
    .active-item {
        color: burlywood;
    }
}
</style>
<style lang="less">
.inner-diag {
    min-height: 200px;
}
</style>
