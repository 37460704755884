<template>
    <el-drawer
        v-if="visible"
        :append-to-body="true"
        :visible.sync="visible"
        size="70%"
        :wrapperClosable="false"
        :before-close="handleClose"
        :show-close="false"
    >
        <div ref="table" style="padding-top:30px">
            <sc-search-v2
                name="提交人信息"
                :columns="columns"
                :columnsNum="2"
                :clientFalg="false"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                ref="form1"
                :moreDown="true"
                @search="searchFn"
                @change="changeFn"
            ></sc-search-v2>
            <sc-table-v2
                ref="refTable"
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="contentLoading"
                @change="changeFn"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @select="handleSelectionChange"
            >
                <template v-slot:selection>
                    <el-table-column
                        type="selection"
                        width="55"
                        :selectable="checkboxInit"
                    ></el-table-column>
                </template>
                <template v-slot:coverImage>
                    <el-table-column prop="coverImage" label="图片">
                        <template slot-scope="scope">
                            <img
                                v-if="scope.row.cover"
                                style="width: 45px; height: 45px"
                                :src="scope.row.cover"
                            />
                            <img
                                v-else
                                style="width: 45px; height: 45px"
                                :src="scope.row.fileList[0]?.url"
                            />
                            <!-- 
                                '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                             -->
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="90">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                style="color: red"
                                size="small"
                                @click="onDelete(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>

            <div class="bottom-btn">
                <el-button size="mini" @click="handleClose">取消</el-button>
                <el-button type="primary" size="mini" @click="submit">确定</el-button>
            </div>
        </div>
    </el-drawer>
</template>
<script>
import { getPoolist, saveDec, getList, saveartDec } from '@/api/mkt/market.js';
import { getContentList } from '@/api/mkt/week-case.js';
import { getLabellist } from '@/api/mkt/topic.js';
import getListMixin from '@/mixins/getListMixin';
import mixins from '@/mixins';

export default {
    mixins: [mixins, getListMixin],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [Number, String],
            default: 0,
        },
        // 内容类型(1-笔记 2-案例 3-切片 4- 设计师主页 5-合集 6-设计师榜 7-风格测试 8-智能匹配)
        listType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            contentLoading: false,
            tableHandle: getContentList,
            tableHeightCalc: 300,
            columns: {
                contentTitle: {
                    name: '搜索名称',
                    type: 'input',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '搜索名称',
                    },
                    spanCol: 1,
                },
                contentId: {
                    name: '搜索ID',
                    type: 'input',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '搜索ID',
                    },
                    spanCol: 1,
                },
            },
            result: {
                contentId: '',
                contentTitle: '',
                contentType: '',
            },
            LS: { 'min-width': '80px' }, //设置label宽度
            CS: { width: '80px', 'padding-right': '0px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空筛选', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            tableHead: [
                { name: '', key: 'selection' },
                { name: 'ID', key: 'contentId' },
                { name: '名称', key: 'contentTitle' },
                { name: '图片', key: 'coverImage' },
                // { name: '分类', key: 'categoryName' },
                // { name: '标签', key: 'labelName' },
                // { name: '内容数量', key: 'quantity' },
                // { name: '运营标签', key: 'operationName' },
                { name: '创建人', key: 'userName' },
                { name: '创建时间', key: 'createTime' },
            ],
            tableData: [],
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            selectRowlist: [],
            selectItem: {},
            labelOptions: [],
            companyData: [],
            selectListForList: [],
        };
    },
    watch: {
        visible: {
            handler: function(val) {
                if (val) {
                    console.log('-11111---------------------------', this.listType);
                    this.result.contentType = this.listType;
                    this.getList();
                }
            },
            immediate: true,
        },
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            console.log('-----------this.$refs.table.offsetTop', this.$refs);
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 200;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
    },
    mounted() {},
    methods: {
        //判断复选框是否可选
        checkboxInit(row) {
            if (row.isCheck) {
                return 0; //不可勾选
            } else {
                return 1;
            }
        },
        async getList() {
            this.contentLoading = true;
            await this.queryDataMixin();
            this.contentLoading = false;
        },
        searchFn() {
            this.result.contentType = this.listType;
            this.page.page = 1;
            this.getList();
        },
        handleClose() {
            this.$emit('closeDiag');
        },
        changeFn(e) {
            console.log('-------------changeFn', e);
        },
        // 单项逻辑
        handleSelectionChange(selection, row) {
            console.log('--------1111', selection, row, this.$refs.refTable);
            this.$refs.refTable.clearSelection();
            if (row?.noChoose) return; //不满足条件数据不可操作
            this.$refs.refTable.clearSelection(); // 这里因为需求做的是必选一个，如有其他需求可做调整
            this.$refs.refTable.toggleRowSelection(row, true);
            this.selectItem = row;
            // this.selectRowlist = selection;
        },
        disposeData(arr) {
            return arr.map(item => {
                return item.data.id;
            });
        },
        clickFn() {},
        submit() {
            if (this.selectItem) {
                this.$emit('submit', this.selectItem);
            }
        },
        async saveDec(params) {
            const res = await saveDec(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.$emit('closeDiag', 'success');
            }
        },
        async saveartDec(params) {
            const res = await saveartDec(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.$emit('closeDiag', 'success');
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-table thead {
    // 第一种
    .el-checkbox__input {
        display: none !important;
    }
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
/deep/ .el-table__body .cell:empty::before {
    content: '--';
}
</style>
