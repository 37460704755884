<template>
    <div>
        <sc-search-v2
            name=""
            :columns="columns"
            :result.sync="result"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:categoryId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">分类：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            v-model="result.categoryId"
                            :show-all-levels="false"
                            :size="size"
                            :options="cloumnsList"
                            :props="{ emitPath: false, value: 'categoryId', label: 'categoryName' }"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:labelId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">标签：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            v-model="result.labelId"
                            size="small"
                            :options="labelOptions"
                            placeholder="最多选择20个"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            ref="cascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <!-- <template v-slot:operationId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">运营标签：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            v-model="result.operationId"
                            size="small"
                            :options="operationList"
                            placeholder="请选择"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            ref="cascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template> -->
            <template v-slot:companyCode>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">分公司：</div>
                    </template>
                    <div style="text-align: left">
                        <el-select v-model="result.companyCode" placeholder="请选择">
                            <el-option
                                v-for="item in companyData"
                                :key="item.ouCode"
                                :label="item.ouName"
                                :value="item.ouCode"
                            ></el-option>
                        </el-select>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
import { getColumnId, getCategoryList, getLabellist } from '@/api/mkt/topic.js';
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                specialName: {
                    name: '专题名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '分类',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                labelId: {
                    name: '标签',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                // operationId: {
                //     name: '运营标签',
                //     type: 'input',
                //     options: {
                //         defaultValueBase: '',
                //         placeholder: '请输入',
                //     },
                //     spanCol: 1,
                // },
                companyCode: {
                    name: '分公司',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                enabled: {
                    name: '是否启用',
                    type: 'radio',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '请输入关键词',
                        options: [
                            {
                                value: '1',
                                label: '启用',
                            },
                            {
                                value: '0',
                                label: '不启用',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },

                time: {
                    name: '创建时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 2,
                },
            },
            result: {
                categoryId: '',
                companyCode: '',
                createEndTime: '',
                createStartTime: '',
                enabled: '',
                labelId: '',
                specialName: '',
                operationId: '',
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            cloumnsList: [],
            labelOptions: [],
            sourceTypeList: [],
        };
    },
    created() {
        this.getColumn();
        this.getLabelOptions();
    },
    methods: {
        // 获取分类列表
        // getColumn() {
        //     // 获取栏目id
        //     getColumnId({}).then(r => {
        //         let columns = r.data || [];
        //         columns.map(item => {
        //             if (r?.isSuccess) {
        //                 if (item.columnsName == '话题') {
        //                     // 通过栏目id获取分类列表
        //                     getCategoryList({ columnId: item.id }).then(res => {
        //                         if (res?.isSuccess) {
        //                             this.cloumnsList = res.data || [];
        //                             this.$store.commit(
        //                                 'mkt-article-group/setCategoryList',
        //                                 this.cloumnsList
        //                             ); //同步方法保存
        //                         }
        //                     });
        //                 }
        //             }
        //         });
        //     });
        // },
        // 获取标签列表
        getLabelOptions() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.labelOptions = res?.data || [];
                    this.$store.commit('mkt-article-group/setLabelList', this.labelOptions); //同步方法保存
                }
            });
        },
        // 解决最后一级显示暂无数据的问题
        getTreeData(data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    data[i].children = undefined;
                } else {
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        changeFn(e) {
            if (e.row.key == 'time') {
                if (e.value[0]) {
                    this.result.createStartTime = e.value[0] + ' 23:59:59';
                    this.result.createEndTime = e.value[1] + ' 23:59:59';
                }
                delete this.result.time;
            }

            let params = {
                ...this.result,
            };
            this.$emit('callback', params);
        },
        clickFn() {
            let params = {
                ...this.result,
            };
            this.$emit('callback', params);
        },
    },
};
</script>
<style scoped>
/deep/.cascader .el-tag__close {
    display: none !important;
}
</style>
