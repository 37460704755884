<template>
    <div class="behavior-container">
        <div class="search-box search-top-box">
            <span class="title">时间范围：</span>
            <span
                class="item"
                :class="{ active: item.selected }"
                v-for="(item, index) in timeList"
                @click="timeClick(item.key)"
                :key="index"
            >
                {{ item.name }}
            </span>

            <el-date-picker
                v-model="timeValue"
                type="daterange"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="timeChange"
            ></el-date-picker>
        </div>
        <div class="search-box">
            <span class="title">内容类型：</span>
            <span
                class="item"
                :class="{ active: item.selected }"
                v-for="(item, index) in typeList"
                :key="index"
                @click="typeClick(item)"
            >
                {{ item.name }}
            </span>
        </div>
        <sc-tables
            :head="table.tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :show-add-config="tableAddConfig"
            :read-table="true"
            :load="false"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
        >
            <template v-slot:title>
                <el-table-column>
                    <template slot="header">访问内容</template>
                    <template slot-scope="scope">
                        <span v-if="scope.row.contentType == 7">
                            <el-image
                                class="row-image"
                                :src="scope.row.imgUrl"
                                :preview-src-list="[scope.row.imgUrl]"
                                v-if="scope.row.imgUrl"
                            ></el-image>
                            <span>
                                {{
                                    (scope.row.designerName || '') +
                                        ((scope.row.designerName && scope.row.job && '/') || '') +
                                        scope.row.job
                                }}
                            </span>
                        </span>
                        <span v-else>
                            <el-image
                                class="row-image"
                                v-if="scope.row.cover"
                                :src="scope.row.cover"
                                :preview-src-list="[scope.row.cover]"
                            ></el-image>
                            <span>{{ scope.row.title }}</span>
                        </span>
                    </template>
                </el-table-column>
            </template>
        </sc-tables>
    </div>
</template>

<script>
import { getTraceList } from '@/api/mkt/user.js';
import { formatDay } from '@/utils/util.js';

export default {
    name: '',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            timeValue: '',
            typeList: [
                { name: '全部', key: '', selected: true },
                { name: '案例', key: 2 },
                { name: '笔记', key: 1 },
                { name: '设计师', key: 7 },
                { name: '图片', key: 6 },
                { name: '话题', key: 5 },
                { name: '灵感集', key: 3 },
            ],
            timeList: [
                { name: '全部', key: '', selected: true },
                { name: '最近7日', key: 7 },
                { name: '最近30日', key: 30 },
            ],
            table: {
                tableHead: [
                    { name: '访问时间', value: 'createTime' },
                    { name: '分享人', value: 'shareName' },
                    { name: '类型', value: 'contentType', formatter: this.contentTypeFormatter },
                    { name: '访问内容', value: 'title' },
                    { name: '访问时长', value: 'duration' },
                ],
                tableData: [],
            },
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHeight: window.innerHeight - 185,
            page: {
                size: 30,
                page: 1,
                count: 0,
                type: '',
                startTime: '',
                endTime: '',
            },
            list: [],
        };
    },
    created() {
        this.getTraceList();
    },
    methods: {
        async getTraceList() {
            // console.log(this.id, 8888);
            let params = {
                id: this.id,
                // id: '1674354083028873218',
                page: this.page.page,
                size: this.page.size,
                type: '',
                startTime: this.page.startTime,
                endTime: this.page.endTime,
            };
            if (this.page.type) {
                params.type = this.page.type;
            }
            const res = await getTraceList(params);
            if (res?.isSuccess) {
                this.list = res.data.children || [];
                this.page.count = res.data.total;
            }
        },
        pageChange(val) {
            this.page.page = val;
            this.getTraceList();
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getTraceList();
        },
        typeClick(item) {
            for (let i of this.typeList) {
                if (i.key == item.key) {
                    i.selected = true;
                    this.page.type = i.key;
                } else {
                    i.selected = false;
                }
            }
            this.getTraceList();
        },
        timeClick(type) {
            for (let i of this.timeList) {
                if (i.key == type) {
                    i.selected = true;
                } else {
                    i.selected = false;
                }
            }
            if (type) {
                console.log('------------------new Date()', new Date());
                let tempTime = new Date();
                let time = formatDay(tempTime);
                console.log('----------', time);
                if (type == 7) {
                    let startTime = formatDay(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
                    this.page.startTime = startTime + ' 00:00:00';
                    this.page.endTime = time + ' 23:59:59';
                } else if (type == 30) {
                    let startTime = formatDay(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
                    this.page.startTime = startTime + ' 00:00:00';
                    this.page.endTime = time + ' 23:59:59';
                }
            } else {
                this.page.startTime = '';
                this.page.endTime = '';
            }

            this.getTraceList();
        },
        timeChange(value) {
            console.log('----------change', value);
            if (value.length) {
                this.page.startTime = value[0] + ' 00:00:00';
                this.page.endTime = value[1] + ' 23:59:59';
            } else {
                this.page.startTime = '';
                this.page.endTime = '';
            }
            this.getTraceList();
        },
        contentTypeFormatter(row) {
            let text = '';
            switch (row?.contentType) {
                case 1:
                    text = '笔记';
                    break;
                case 2:
                    text = '案例';
                    break;
                case 3:
                    text = '灵感集';
                    break;
                case 5:
                    text = '话题';
                    break;
                case 6:
                    text = '设计师';
                    break;
                case 7:
                    text = '图片';
                    break;
            }
            return text;
        },
    },
};
</script>
<style lang="less" scoped>
.behavior-container {
    .search-box {
        margin: 20px;
        .item {
            padding: 5px 10px;
            background: #a4adb3 100%;
            color: #fff;
            cursor: pointer;
            margin: 0 10px;
        }
        .active {
            background: #000;
        }
    }
    .search-top-box {
        margin-top: 40px;
    }
    .row-image {
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
}
</style>
