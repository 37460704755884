<template>
    <div>
        <div class="cropper-content">
            <!-- 这里有一个上传的逻辑 -->
            <div class="cropper" style="text-align: center; height: 800px;width:800px;">
                <div v-loading="loading">
                    <!-- <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :output-size="option.outputSize"
                        :output-type="option.outputType"
                        :info="option.info"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :fixed-box="option.fixedBox"
                        :fixed="option.fixed"
                        :fixed-number="option.fixedNumber"
                        :can-move="option.canMove"
                        :can-move-box="option.canMoveBox"
                        :original="option.original"
                        :center-box="option.centerBox"
                        :info-true="option.infoTrue"
                        :full="option.full"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        :high="false"
                        :max-img-size="99999"
                    ></vue-cropper> -->

                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :output-size="option.size"
                        :output-type="option.outputType"
                        :info="true"
                        :full="option.full"
                        :fixed="option.fixed"
                        :fixed-number="option.fixedNumber"
                        :can-move="option.canMove"
                        :can-move-box="option.canMoveBox"
                        :fixed-box="option.fixedBox"
                        :original="option.original"
                        :auto-crop="option.autoCrop"
                        :can-scale="option.canScale"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :center-box="option.centerBox"
                        :high="option.high"
                        mode="contain"
                    ></vue-cropper>
                </div>
                <div style="height:130px; margin: 10px 0 0;display: flex;overflow-x: scroll;">
                    <!-- closeUpload:{{ closeUpload }} -->
                    <el-form v-if="!uploadImg">
                        <el-form-item label="" v-if="!closeUpload">
                            <el-upload
                                class="upload-demo"
                                ref="upload"
                                list-type="picture-card"
                                action="*"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :http-request="fnUploadRequest.bind(this)"
                                :on-exceed="handleExceed"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div class="upload-img" v-if="uploadImg && !closeUpload">
                        <el-image
                            :src="uploadImg"
                            style="width:100px;height:100px;margin-right:10px;"
                        ></el-image>
                        <i class="el-icon-close" @click="handleDel"></i>
                    </div>
                    <div style="margin-right: 10px;height:100px;"></div>
                    <el-image
                        v-for="(item, ind) in showImgList"
                        :src="item"
                        :key="ind"
                        style="width:100px;height:100px;margin-right:10px;flex-shrink: 0;"
                        fit="contain"
                        @click="changeImg(item)"
                    ></el-image>
                    <!-- <el-button @click="handleChangeSize(1)" size="mini">放大</el-button>
                    <el-button @click="handleChangeSize(-1)" size="mini">缩小</el-button>
                    <el-button @click="handleLeft" size="mini">向左旋转</el-button>
                    <el-button @click="handleRight" size="mini">向右旋转</el-button> -->
                </div>
            </div>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancle">取消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import { VueCropper } from 'vue-cropper';
// import DEV from '@list/config.js';
import { getOssConfig } from '../api/login';
import { oss } from '@/config.js';
import { ossToBase } from '../api/mkt/exhibit';
import OSS from 'ali-oss';
import { formatTimeTwo } from '@/utils/util';
// 1、封面图只展示在商场组件位置 不做回显。
// 2、默认回显列表第一张图作为封面
export default {
    name: 'clip-img',
    components: {
        VueCropper,
    },
    props: {
        appendToBody: {
            type: Boolean,
            default: false,
        },
        showImgList: {
            type: Array,
            default: () => [],
        },
        cacheImgUrl: {
            type: String,
            default: '',
        },
        init: {
            type: Boolean,
            default: false,
        },
        closeUpload: {
            type: Boolean,
            default: false,
        },
        // 截取框按图片尺寸大小展示
        fixedChange: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        showImgList: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch showImgList', newV);
                let that = this;
                if (newV?.length) {
                    that.loading = true;
                    let _url = that.$props.cacheImgUrl || newV[0];
                    that.ossToBase(_url, true);
                    return;
                }
                that.option.img = '';
            },
        },
        cacheImgUrl: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch cacheImgUrl', newV);
                this.uploadImg = newV;
            },
        },
        init: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch initClip', newV);
                this.initCanvas = newV;
            },
        },
        fixedChange: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch fixedChange', newV);
                if (newV) {
                    this.option.fixed = false;
                    this.$forceUpdate();
                } else {
                    this.option.fixed = true;
                }
            },
        },
    },

    data() {
        return {
            uploadImg: '', //默认封面图
            loading: false, //加载base文件
            dialogVisiblex: false,
            initCanvas: false, //初始
            option: {
                img: '',
                size: 1,
                full: true,
                outputType: 'png',
                canMove: true,
                fixedBox: false,
                original: false,
                canMoveBox: true,
                autoCrop: true,
                // 只有自动截图开启 宽度高度才生效
                autoCropWidth: '50%',
                autoCropHeight: '50%',
                centerBox: true,
                high: false,
                max: 99999,
                canScale: false,
            },
            fileList: [],
            local: { obj: {} },
            filesuffix: '',
        };
    },

    mounted: function() {
        //oss签名
        this.uploadImg = '';
        getOssConfig().then(res => {
            if (res.code == 200) {
                let ossConfig = res.data;
                this.ossClient = new OSS({
                    accessKeyId: ossConfig.stsKeyId,
                    accessKeySecret: ossConfig.stsSecret,
                    stsToken: ossConfig.token,
                    bucket: oss.bucket,
                    region: oss.region,
                });
            }
        });
    },
    methods: {
        // 裁剪之后的数据
        realTime(img) {
            console.log(img, 'img----');
            // this.$refs.cropper.getCropData(data => {
            //     // 初始化不做对外更新
            //     if (!this.initCanvas) {
            //         this.$emit('selectImg', data, this.filesuffix);
            //         console.log(data, '裁剪之后的数据');
            //     } else {
            //         console.log('---------------this.initCanvas true', this.option.img);
            //     }
            // });
        },
        clear() {
            this.uploadImg = '';
        },

        handleDel() {
            this.uploadImg = '';
            // this.option.img = '';
            this.$emit('selectImg', '', '');
        },
        changeImg(item) {
            // this.option.img = item;
            this.loading = true;
            this.ossToBase(item);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件`);
            return;
        },
        handleCancle() {
            this.dialogVisiblex = false;
            this.fileList = [];
        },
        fnUploadRequest(option) {
            let file = option.file;
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let tempCheckpoint;
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${oss.folderName}/${date}/${this.file}.${this.filesuffix}`;
            let result = this.ossClient.multipartUpload(url, file, {
                checkpoint: tempCheckpoint,
                // 设置并发上传的分片数量。
                parallel: 1,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 2 * 1024 * 1024,
                meta: {
                    year: 2022,
                    people: 'test',
                },
                mime: 'text/plain',
            });
            result.then(res => {
                if (res.name) {
                    // console.log('上传阿里云成功', res);
                    file.url = 'https://sczs.voglassdc.com/' + res.name;
                    // this.imgList.push(file);
                    this.ossToBase(file.url);
                    // this.option.img = file.url;

                    this.uploadImg = file.url;
                }
            });
        },
        beforeUpload(file) {
            // console.log(file, 'file-------');
            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            this.filesuffix = file.name.substring(first + 1, file.name.length);
            if (!['jpg', 'jpeg', 'png'].includes(this.filesuffix)) {
                this.$message.warning(`图片格式不对`);
                this.fileList = [];
                return false;
            }
            if (file.size > 5 * 1024 * 1024) {
                // 判断文件大小
                this.$message.warning(`文件超过5M了`);
                this.fileList = [];
                return false;
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            // console.log('--------transBase64FromImage');
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width, image.height);
            // 可选其他值 image/jpeg
            return canvas.toDataURL('image/jpeg');
        },

        async ossToBase(src, init) {
            let res = await ossToBase({ url: src });
            // console.log('-------------fixedChange', this.fixedChange);
            if (this.fixedChange) {
                let img = new Image();
                img.src = src;
                let that = this;
                img.onload = function() {
                    if (img.height > img.width) {
                        that.option.autoCropWidth = parseInt((500 / img.height) * img.width);
                        that.option.autoCropHeight = 500;
                    } else {
                        that.option.autoCropWidth = 800;
                        that.option.autoCropHeight = parseInt((800 / img.width) * img.height);
                    }
                    console.log(
                        '-----------mainImage width height',
                        src,
                        img.width,
                        img.height,
                        that.option.autoCropWidth,
                        that.option.autoCropHeight
                    );
                };
            }

            if (!init) {
                //watch时 不改变initCanvas 不会触发裁剪方法 realTime
                this.initCanvas = false;
            }
            if (res.isSuccess) {
                this.option.img = 'data:image/jpg;base64,' + res.data;
                // console.log(res, ossToBase, 'ossToBase');
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.cropper-content {
    width: 800px;
    height: 650px;
}
.cropper .vue-cropper {
    height: 500px !important;
}
/deep/ .el-form-item {
    flex-shrink: 1;
}
/deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
}
/deep/ .el-upload-list__item-thumbnail,
/deep/ .el-upload-list__item-actions,
/deep/ .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
}
.upload-img {
    position: relative;
    width: 100px;
    height: 100px;
    .el-icon-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: #333;
        background: #fff;
    }
}
</style>
