<template>
    <div class="operation-content-main">
        <div class="operation-page-module">
            <p class="page-module-title">页面组件</p>
            <draggable
                v-model="pageModuleArr"
                :options="firstDraggableOption"
                animation="300"
                :move="onMovePageHandle"
                :clone="cloneDefaultField"
            >
                <transition-group>
                    <div
                        v-for="(item, index) in pageModuleArr"
                        class="page-module-item"
                        :key="index + 1"
                    >
                        <i class="page-module-icon" :class="item.icon"></i>
                        <p>{{ item.moduleName }}</p>
                    </div>
                </transition-group>
            </draggable>
        </div>
        <div class="operation-see-module">
            <div class="operation-see-frame">
                <!-- 固定头部 -->
                <div class="operation-see-title">
                    <img src="@/assets/images/operation-see-title.png" alt="" />
                </div>
                <draggable
                    v-model="seeModuleArr"
                    filter=".forbid"
                    :options="secondDraggableOption"
                    animation="300"
                    :move="onMoveSeeHandle"
                    @add="onAddPageHandle"
                    @update="moduleSortHandle"
                >
                    <transition-group class="see-module-group">
                        <div
                            class="see-module-item"
                            v-for="(item, index) in seeModuleArr"
                            :key="index + 1"
                            :class="{
                                forbid: item.unDrag,
                                'active-see-module': activeSeeModule === index,
                            }"
                            @click.stop="activeSeeModuleHandle(item, index)"
                        >
                            <img :src="item.picUrl" alt="" />
                            <div class="see-module-title-box">
                                <div class="tips-group">
                                    <p class="see-module-title">{{ item.moduleName }}</p>
                                    <p class="publish-status">
                                        {{ item.isPublish ? '已发布' : '未发布' }}
                                    </p>
                                </div>
                                <i
                                    v-if="pageTagShowHandle(index) && !item.isPublish"
                                    class="el-icon-delete"
                                    @click.stop="moduleDeleteHandle(item, index)"
                                ></i>
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { addControl, pageEcho, moduleDelete, moduleSort } from '@/api/mkt/markets.js';

export default {
    name: 'operation-content-main',
    components: { draggable },
    data() {
        return {
            //定义要被拖拽对象的数组
            pageModuleArr: [
                {
                    icon: 'el-icon-c-scale-to-original',
                    moduleName: 'banner',
                    controlType: 1,
                    picUrl: 'https://bpm1.shangceng.com.cn/dpw/be/images/banner-perch.png',
                },
                {
                    icon: 'el-icon-tickets',
                    moduleName: '图文导航',
                    controlType: 2,
                    picUrl: 'https://bpm1.shangceng.com.cn/dpw/be/images/img-text-perch.png',
                },
                {
                    icon: 'el-icon-s-operation',
                    moduleName: '内容列表',
                    controlType: 3,
                    picUrl: 'https://bpm1.shangceng.com.cn/dpw/be/images/content-perch.png',
                },
            ],
            seeModuleArr: [],
            firstDraggableOption: {
                group: {
                    name: 'operationCont',
                    pull: 'clone',
                },
                sort: false,
            },
            secondDraggableOption: {
                group: {
                    name: 'operationCont',
                    pull: false,
                },
            },
            activeSeeModule: null,
            pageId: '',
            isHome: 0,
        };
    },
    mounted() {
        this.pageId = this.$route.query.pageId || '1';
        this.pageEchoHandle();
    },
    methods: {
        // 获取页面
        async pageEchoHandle() {
            const { data, isSuccess } = await pageEcho(this.pageId);
            if (isSuccess) {
                if (data.moduleList.length && data.isHomePage) {
                    data.moduleList[0].unDrag = true;
                }
                this.isHome = data.isHomePage;
                this.seeModuleArr = data.moduleList || [];
            }
        },
        //move 拖拽前置拦截
        onMoveSeeHandle(e) {
            if (e.relatedContext.element.unDrag) return false;
            return true;
        },
        onMovePageHandle(e) {
            if (e.draggedContext.futureIndex === 0 && this.isHome) return false;
            return true;
        },
        activeSeeModuleHandle(data, index) {
            this.activeSeeModule = index;
            this.$emit('moduleClick', data);
        },
        async moduleDeleteHandle(row, index) {
            const { isSuccess } = await moduleDelete(row.moduleId || '', this.pageId);
            if (isSuccess) {
                this.seeModuleArr.splice(index, 1);
                this.$emit('deleteChange');
                this.$message.success('删除成功');
            }
        },
        cloneDefaultField(e) {
            return JSON.parse(JSON.stringify(e));
        },
        // 添加组件接口
        async onAddPageHandle(e) {
            const { controlType, moduleName } = this.seeModuleArr[e.newIndex];
            const createModuleParams = {
                controlType,
                controlTypeName: moduleName,
                pageId: this.pageId,
                orderSort: e.newIndex,
            };
            const { data, isSuccess } = await addControl(createModuleParams);
            if (isSuccess) {
                this.seeModuleArr[e.newIndex].moduleId = data.moduleId || '';
                this.seeModuleArr[e.newIndex].moduleName = data.moduleName;
            }
        },
        async moduleSortHandle() {
            const { pageId, seeModuleArr } = this;
            const moduleIdList = seeModuleArr.map(item => {
                return item.moduleId || '';
            });
            await moduleSort({
                moduleIdList,
                pageId,
            });
        },
        initActiveStatus(status) {
            if (status) {
                this.pageEchoHandle();
            } else {
                this.activeSeeModule = null;
            }
        },
        updateSeeModelPicHandle(url, name) {
            if (url) {
                this.seeModuleArr[this.activeSeeModule].picUrl = url;
            }
            if (name) {
                this.seeModuleArr[this.activeSeeModule].moduleName = name;
            }
        },
        pageTagShowHandle(index) {
            let tagStatus = true;
            if (index === 0 && this.isHome) {
                tagStatus = false;
            }
            return tagStatus;
        },
    },
};
</script>
<style lang="less" scoped>
.operation-content-main {
    display: flex;
    height: 100%;
}
.operation-page-module {
    width: 100px;
    margin: 0 0 10px 20px;
    .page-module-title {
        font-weight: 700;
        font-size: 14px;
        color: #333;
        margin-bottom: 15px;
    }
}
.operation-see-module {
    min-width: 600px;
    height: 100%;
    flex: 1;
    margin: 0 20px 20px 20px;
    padding: 10px 0;
    background-color: #d4dce1;
}
.page-module-item {
    text-align: center;
    padding: 6px 10px;
    margin-bottom: 10px;
}
.page-module-item:hover {
    background-color: #fdfdfd;
    cursor: move;
}
.page-module-icon {
    font-size: 28px;
}
.operation-see-frame {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 0;
    margin: 0 auto;
}
.operation-see-title {
    width: 320px;
    margin: 0 auto;
    text-align: center;
    img {
        width: 100%;
        visibility: visible;
    }
}
.see-module-group {
    display: block;
    min-height: 100px;
}
.operation-see-frame::-webkit-scrollbar {
    width: 4px;
}
.operation-see-frame::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
    opacity: 0.1;
}
.operation-see-frame::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
    border-radius: 0;
}
.see-module-item {
    position: relative;
    width: 320px;
    margin: 0 auto;
    z-index: 9;
    background-color: #fff;
    &.active-see-module {
        border: 1px dashed #5399e1;
    }
    img {
        width: 100%;
        visibility: visible;
    }
    &:hover {
        cursor: move;
    }
    .see-module-title-box {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        left: -133px;
        width: 130px;
        color: #333;
        padding: 5px 20px 5px 8px;
        font-size: 13px;
        background-image: url(../../../../assets/images/title-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 10;
        p {
            display: inline-block;
            margin-right: 5px;
        }
        i {
            cursor: pointer;
        }
    }
}
</style>
