import rq from '@/utils/https.js';
const $ajax = rq.requests;

// // /article/page
export const getList = data =>
    $ajax({
        url: '/article/note-page',
        method: 'post',
        data,
    });
export const getInfo = params =>
    $ajax({
        url: '/article/note-info',
        method: 'get',
        params,
        headers: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });
// 添加笔记
// 1.当isDraft=0时，表示发布信息;
// 2.当isDraft=1时，表示存入草稿箱;
// 3.当articleId=null时，表示新增文章，反之为修改;
// **注意：发布时title（文章标题）、files（文件集合）必传
export const addArticleImage = data =>
    $ajax({
        url: '/article/note-save-update',
        method: 'post',
        data,
    });
// 隐藏笔记
export const showArticle = data =>
    $ajax({
        url: '/article/is-show-note',
        method: 'post',
        data,
        headers: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });
// 删除笔记
export const deleteArticle = params =>
    $ajax({
        url: '/article/sys-remove',
        method: 'get',
        params,
        headers: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });
// 话题筛选项列表
export const getTopicOptions = params =>
    $ajax({
        url: '/topic/congruence/list',
        method: 'get',
        params,
    });
// 作者列表
export const getDesignerList = params =>
    $ajax({
        url: '/user/internal/list',
        method: 'get',
        params,
    });
export const getDesignerListOnly = params =>
    $ajax({
        url: '/user/getDesignerList',
        method: 'get',
        params,
    });
