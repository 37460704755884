<template>
    <el-dialog
        :visible.sync="addialogVisible"
        :close-on-click-modal="false"
        width="30%"
        title="添加设计师"
        :before-close="close"
    >
        <sc-form-v2
            name=""
            tablename=""
            :columnsNum="1"
            :columns="columns"
            :result.sync="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
            @click="clickFn"
        ></sc-form-v2>
        <div class="dialog-footer">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { getDesignerList } from '@/api/mkt/article-image.js';
import { rankingAdd } from '@/api/mkt/designer-ranking-quick.js';
export default {
    name: 'add-designer-dom',
    data() {
        return {
            addialogVisible: false,
            columns: {
                accountId: {
                    name: '设计师',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'userId',
                            label: 'createUserName',
                        },
                        filterable: true,
                    },
                    rules: [{ require: true }],
                },
            },
            result: { accountId: '' },
        };
    },
    methods: {
        async getDesignerList() {
            let res = await getDesignerList();
            if (res?.isSuccess) {
                res.data.map(item => {
                    item.createUserName =
                        item.createUserName + '（ ' + item.createUserAccount + ' ）';
                });
                this.columns.accountId.options.options = res.data;
            }
        },
        changeFn() {},
        clickFn() {},
        open() {
            this.addialogVisible = true;
            this.getDesignerList();
        },
        handleSubmit() {
            console.log(this.result);
            rankingAdd({
                collectionId: this.$route.query.id,
                accountId: this.result.accountId,
            }).then(res => {
                console.log(res);
                if (res.isSuccess) {
                    this.close();
                    this.$emit('refresh', true);
                }
            });
        },
        close() {
            this.addialogVisible = false;
            this.result.accountId = '';
        },
    },
};
</script>
<style scoped lang="less">
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
