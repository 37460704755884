<template>
    <Layout>
        <div class="mkt" ref="table">
            <div class="data-box">
                <span class="data-name">数据统计</span>
                <div class="data-right">
                    <span class="data-item" v-for="(item, index) in dataList" :key="index">
                        <span class="name">{{ item.label }}</span>
                        <span class="value">{{ item.value }}</span>
                    </span>
                </div>
            </div>
            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="clickFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>
            <div class="tabs">
                <sc-table-v2
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="180">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onEdit(scope.row, 'detail')"
                                >
                                    查看
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onEdit(scope.row, 'rate')"
                                >
                                    评级
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onHide(scope.row, 'hide')"
                                >
                                    隐藏
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onEdit(scope.row, 'tag')"
                                >
                                    官方标签
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>

            <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
            <rate-dialog ref="rateDialog" @handleClose="handleClose"></rate-dialog>
            <tag-dialog ref="tagDialog" @handleClose="handleClose"></tag-dialog>
        </div>
    </Layout>
</template>

<script>
import editDialog from '../../user/designer/components/detail.vue';
import rateDialog from './components/rate.vue';
import tagDialog from './components/tag.vue';

import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';

import { getDesignerList, hideDesigner } from '@/api/mkt/user.js';
import Layout from '@/components/layout.vue';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog, rateDialog, tagDialog, Layout },
    data() {
        return {
            dataList: [
                { label: '任务完成率', value: '90%' },
                { label: '个人主页转发数', value: '1232' },
                { label: '个人主页曝光数', value: '1232' },
            ],
            tableHandle: getDesignerList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '110px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '设计师姓名', key: 'name' },
                { name: '任务完成率', key: 'order1', width: '82' },
                { name: '个人主页转发数', key: 'order2', width: '106' },
                { name: '个人主页曝光数', key: 'order3', width: '106' },
                { name: '案例发布数', key: 'order4', width: '82' },
                { name: '资料完成度', key: 'order5', width: '82' },
                { name: '平面规划能力', key: 'order6', width: '96' },
                { name: '效果美学能力', key: 'demo1', width: '96' },
                { name: '产品二次设计能力', key: 'demo2', width: '118' },
                { name: '全屋方案制作能力', key: 'demo3', width: '118' },
                { name: '最近案例发布时间', key: 'demo4' },
                { name: '最近资料更新时间', key: 'demo5' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '150',
                },
            ],
            columns: {
                company: {
                    name: '公司',
                    type: 'select',
                    options: {
                        // defaultValueBase:2
                        placeholder: '请选择',
                        options: [
                            { label: '有配置', value: '0' },
                            { label: '无配置', value: '1' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                depart: {
                    name: '部门',
                    type: 'select',
                    options: {
                        // defaultValueBase:2
                        placeholder: '请选择',
                        options: [
                            { label: '有配置', value: '0' },
                            { label: '无配置', value: '1' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                searchName: {
                    name: '设计师姓名',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                        options: [],
                        props: {},
                    },
                    spanCol: 1,
                },
                isSpeciality: {
                    name: '平面规划能力',
                    type: 'select',
                    options: {
                        // defaultValueBase:2
                        placeholder: '请选择',
                        options: [
                            { label: '有配置', value: '0' },
                            { label: '无配置', value: '1' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                id: '',
                searchName: '',
                isDesignerSaid: '',
                isShortStory: '',
                isSpeciality: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        onEdit(row, type) {
            switch (type) {
                case 'detail':
                    this.$refs.editDialog.open(row);
                    break;
                case 'rate':
                    this.$refs.rateDialog.open(row);
                    break;
                case 'tag':
                    this.$refs.tagDialog.open(row);
                    break;
                default:
                    this.$refs.editDialog.open(row);
                    break;
            }
        },
        onHide(row) {
            this.$confirm(`您确定隐藏设计师 ${row.name} 吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                hideDesigner({ accountId: row.id }).then(() => {
                    this.$message.success('隐藏成功');
                    this.init();
                });
            });
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                for (const key in this.result) {
                    if (typeof this.result[key] != 'string') {
                        this.result[key] = '';
                    }
                }
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.data-box {
    display: flex;
    padding: 10px 30px 10px;
    .data-name {
        color: #000;
        font-size: 22px;
        padding-right: 28px;
    }
    .data-right {
        display: flex;
        align-items: flex-end;
        .data-item {
            pading: 0 40px;
            .value {
                padding: 0 60px 0 20px;
            }
        }
    }
}
</style>
