<template>
    <div class="checkbox-container">
        <!-- <Search></Search> -->
        <Content></Content>
    </div>
</template>

<script>
import Search from './search';
import Content from './content';

export default {
    name: 'article',
    components: { Search, Content },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-article/clear');
    },
};
</script>
<style lang="less" scoped>
.checkbox-container {
    background: #fff;
}
/deep/ .el-table__body .cell:empty::before {
    content: '--';
}
</style>
