export const columnsBaseInfo = {
    sex: {
        name: '性别',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [
                { label: '男', value: 1 },
                { label: '女', value: 2 },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择性别' }],
        spanCol: 1,
    },
    nickName: {
        name: '昵称',
        type: 'input',
        options: {
            defaultValueBase: '',
            placeholder: '请输入',
        },
        rules: [{ required: true, message: '请输入昵称' }],
        spanCol: 1,
    },
    age: {
        name: '年龄',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            props: {
                label: 'content',
                value: 'id',
            },
        },
        rules: [{ required: true, message: '请选择年龄' }],
        spanCol: 1,
    },
    constellation: {
        name: '星座',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [
                { label: '白羊座', value: '白羊座' },
                { label: '金牛座', value: '金牛座' },
                { label: '双子座', value: '双子座' },
                { label: '巨蟹座', value: '巨蟹座' },
                { label: '狮子座', value: '狮子座' },
                { label: '处女座', value: '处女座' },
                { label: '天秤座', value: '天秤座' },
                { label: '天蝎座', value: '天蝎座' },
                { label: '射手座', value: '射手座' },
                { label: '摩羯座', value: '摩羯座' },
                { label: '水瓶座', value: '水瓶座' },
                { label: '双鱼座', value: '双鱼座' },
            ],
            props: {
                label: 'label',
                value: 'value',
            },
        },
        rules: [{ required: true, message: '请选择星座' }],
        spanCol: 1,
    },
    city: {
        name: '所在城市',
        type: 'cascader',
        options: {
            placeholder: '请选择',
            options: [],
            props: {
                label: 'name',
                value: 'name',
                children: 'subset',
            },
        },
        rules: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        spanCol: 1,
    },
    yearsService: {
        name: '工作年限',
        type: 'input',
        options: {
            defaultValueBase: '',
            placeholder: '请输入',
            unit: '年',
        },
        rules: [
            { required: true, message: '请输入工作年限', trigger: 'blur' },
            { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' },
        ],
        spanCol: 1,
    },
    rank: {
        name: '职级',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            props: {
                label: 'content',
                value: 'id',
            },
        },
        rules: [{ required: true, message: '请选择职级' }],
        spanCol: 1,
    },

    style: {
        name: '擅长风格',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            multiple: true,
            collapseTags: true,
            maxCollapseTags: 1,
            props: {
                label: 'content',
                value: 'id',
            },
        },
        rules: [{ required: true, message: '请选择擅长风格' }],
        spanCol: 1,
    },
    individuality: {
        name: '个人特质',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            multiple: true,
            collapseTags: true,
            maxCollapseTags: 1,
            max: 3,
            props: {
                label: 'content',
                value: 'id',
            },
            valueKey: 'id',
        },
        rules: [{ required: true, message: '请选择个人特质' }],
        spanCol: 1,
    },
    communicationStyle: {
        name: '沟通风格',
        type: 'select',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            multiple: true,
            max: 2,
            collapseTags: true,
            maxCollapseTags: 1,
            props: {
                label: 'content',
                value: 'id',
            },
        },
        rules: [{ required: true, message: '请选择沟通风格' }],
        spanCol: 1,
    },
    hobby: {
        name: '爱好',
        type: 'cascader',
        options: {
            defaultValueBase: '',
            placeholder: '请选择',
            options: [],
            multiple: true,
            max: 5,
            collapseTags: true,
            allLevels: false,
            maxCollapseTags: 1,
            props: {
                label: 'content',
                value: 'id',
                children: 'lableList',
                multiple: true,
                emitPath: false,
            },
        },
        rules: [{ required: true, message: '请选择爱好' }],
        spanCol: 1,
    },
};
// export const localResult = ;
export const addColumns = {
    account: {
        name: 'bpm账号',
        type: 'input',
        options: {
            defaultValue: '',
            clearable: true,
            placeholder: '请输入',
            options: [],
            props: {},
        },
        rules: [{ required: true, message: '请输入bpm账号' }],
        spanCol: 1,
    },
    mobile: {
        name: '手机号',
        type: 'input',
        options: {
            defaultValue: '',
            clearable: true,
            placeholder: '请输入',
            options: [],
            props: {},
        },
        rules: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
        ],
        spanCol: 1,
    },
    btn: {},
};
