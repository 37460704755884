<template>
    <div>
        <sc-search-v2
            name=""
            :columns="columns"
            :result.sync="result"
            :btn="btn"
            :size="size"
            :columnsNum="columnsNum"
            :clientFalg="clientFalg"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:categoryId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">分类：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width:100%"
                            v-model="result.categoryId"
                            :show-all-levels="false"
                            :size="size"
                            :options="categoryList"
                            :props="{ emitPath: false, value: 'categoryId', label: 'categoryName' }"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:labelId>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">标签：</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width:100%"
                            v-model="result.labelId"
                            :size="size"
                            :options="labelList"
                            placeholder="最多选择20个"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            ref="cascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:companyCode>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">分公司：</div>
                    </template>
                    <div style="text-align: left">
                        <el-select
                            v-model="result.companyCode"
                            style="width:100%"
                            :size="size"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in companyData"
                                :key="item.ouCode"
                                :label="item.ouName"
                                :value="item.ouCode"
                            ></el-option>
                        </el-select>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-article-group');
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                specialName: {
                    name: '内容名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                contentType: {
                    name: '内容类型',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '所属分类',
                    type: 'input',
                },
                labelId: {
                    name: '标签',
                    type: 'input',
                    spanCol: 1,
                },
                companyCode: {
                    name: '分公司',
                    type: 'input',
                    spanCol: 1,
                },
                time: {
                    name: '创建时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 2,
                },
            },
            result: {
                categoryId: '',
                companyCode: '',
                createEndTime: '',
                createStartTime: '',
                enabled: '',
                labelId: '',
                specialName: '',
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            columnsNum: 2,
            clientFalg: false,
            size: 'small',
        };
    },
    computed: {
        ...mapState(['labelList']),
        ...mapState(['categoryList']),
    },
    created() {},
    methods: {
        changeFn(e) {
            if (e.row.key == 'time') {
                this.result.createStartTime = e.value[0] + ' 00:00:00';
                this.result.createEndTime = e.value[1] + ' 23:59:59';
                delete this.result.time;
            }
            console.log(this.result, 'this.result');
            let params = {
                ...this.result,
            };
            this.$emit('callback', params);
        },
        clickFn() {
            let params = {
                ...this.result,
            };
            this.$emit('callback', params);
        },
    },
};
</script>
<style lang="scss" scoped></style>
