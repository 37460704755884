<template>
    <div class="tag-container">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :btn="btn"
            :LS="LS"
            :moreDown="moreDown"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:searchFileName>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">列表搜索：</div>
                    </template>
                    <div style="text-align: left; display: flex">
                        <el-select
                            size="small"
                            placeholder="请选择"
                            v-model="result.searchFileName"
                            style="width: 115px"
                            clearable
                            @change="changeFn"
                        >
                            <template>
                                <el-option key="1" label="标签名称" value="1"></el-option>
                                <el-option key="2" label="标签组名称" value="5"></el-option>
                            </template>
                        </el-select>
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="result.searchFileValue"
                            @change="changeFn"
                            size="small"
                            style="width: 50%; margin-left: 3px"
                            clearable
                        ></el-input>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
        <div class="content" ref="table">
            <el-button type="primary" class="top-btn" size="small" @click="goAdd">
                新建标签
            </el-button>
            <PageList
                :tableHead="tableHead"
                :tableData="listData"
                :pagination="page"
                :defaultExpandAll="false"
                @sizeChange="sizeChange"
                @currentChange="currentChange"
                :table-height="tableHeightCalc"
            >
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleCloseBtn(scope.row)">
                                {{ scope.row.state == 0 ? '禁用' : '启用' }}
                            </el-button>
                            <el-button type="text" @click="editBtn(scope.row)">编辑</el-button>
                            <el-button type="text" @click="handleDelBtn(scope.row)">
                                <span style="color: red">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </PageList>

            <Add ref="addDialog" @closediag="closediag"></Add>
        </div>
    </div>
</template>

<script>
import { del, editLabelLibraryState } from '@/api/mkt/tag';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-tag');
import { getTagList } from '@/api/mkt/tag.js';
import Add from './add.vue';
import mixinsLists from '@/mixins/getListMixin';
import PageList from 'conp/page-list';
export default {
    name: 'article-tag',
    mixins: [mixinsLists],
    components: { Add, PageList },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
    },
    data() {
        return {
            tableHandle: getTagList,
            columns: {
                searchFileName: {
                    name: '插槽占位',
                    type: 'text',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                    },
                },
                groupType: {
                    name: '类型',
                    type: 'select',
                    options: {
                        options: [
                            { label: '系统', value: '1' },
                            { label: '用户', value: '5' },
                            { label: '运营', value: '4' },
                            { label: 'AI标签', value: '6' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                state: {
                    name: '状态',
                    type: 'select',
                    options: {
                        options: [
                            { label: '启用', value: '0' },
                            { label: '禁用', value: '1' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                searchFileName: '1',
                searchFileValue: '',
                groupType: '',
                state: '',
            },
            LS: { 'min-width': '120px' }, //设置label宽度
            // CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
            tableHead: [
                { name: '标签名称', value: 'labelName' },
                // { name: '标签ID', value: 'id' },
                { name: '所属标签组', value: 'labelGroupName' },
                { name: '类型', value: 'groupType', formatter: this.groupTypeFormatter },
                { name: '状态', value: 'state', formatter: this.stateFormatter },
                { name: '排序值', value: 'orderSort' },
                { name: '创建人', value: 'createName' },
                { name: '创建时间', value: 'createTime' },
            ],
            addiagvisible: false,
            tableHeightCalc: 520,
            innerHeight: 0,
            isSearchDown: false,
        };
    },
    mounted() {
        this.init();
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        });
    },
    beforeDestroy() {},
    methods: {
        editBtn(row) {
            this.$refs.addDialog.open(row);
        },
        handleDelBtn(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    del({
                        id: row.id,
                    }).then(res => {
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        handleCloseBtn(row) {
            this.$confirm(`是否操作${row.state == 0 ? '禁用' : '启用'}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    editLabelLibraryState({
                        id: row.id,
                        state: row.state == 1 ? 0 : 1,
                    }).then(res => {
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作',
                    });
                });
        },

        init() {
            this.queryDataMixin();
        },

        goAdd() {
            this.$refs.addDialog.open();
        },
        closediag() {
            this.addiagvisible = false;
            this.init();
        },

        editTag() {
            this.addiagvisible = true;
        },
        stateFormatter(val) {
            let str = val.state == 0 ? '可用' : '禁用';
            return str;
        },

        groupTypeFormatter(row) {
            let text = '';
            switch (row?.groupType) {
                case 1:
                    text = '系统';
                    break;
                case 4:
                    text = '运营标签';
                    break;
                case 5:
                    text = '用户自定义标签组';
                    break;
                case 6:
                    text = 'AI标签';
                    break;
            }
            return text;
        },

        propertiesFormatter(val) {
            let obj = {
                1: '系统',
                2: '自定义',
                3: '运营',
            };
            return obj[val.properties];
        },
        sizeChange(val) {
            this.page.size = val;

            this.init();
        },
        currentChange(val) {
            this.page.page = val;
            this.page.index = val;

            this.init();
        },
        changeFn() {
            this.page.page = 1;
            this.page.index = 1;
            this.init();
        },
        clickFn(e, type) {
            if (type == 'clear') {
                this.result = {
                    searchFileName: '1',
                    searchFileValue: '',
                    groupType: '',
                    state: '',
                };
                this.page = {
                    page: 1,
                    size: 30,
                };
            }
            this.page.page = 1;
            this.page.index = 1;

            this.filter = { ...this.result, ...this.page };
            this.init();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.tag-title {
    padding: 20px 0 0 30px;
    font-size: 18px;
}
</style>
