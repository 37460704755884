<template>
    <el-drawer
        :visible.sync="detailDiag"
        :wrapperClosable="false"
        size="30%"
        :before-close="handleClose"
    >
        <sc-form-v2
            tablename="OM_MARKET"
            :columnsNum="1"
            :columns="columns"
            :uploadConfig="uploadConfig"
            :result="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            custom-class="self-drawer"
            ref="marketform"
            @click="handRules"
        >
            <template v-slot:k0>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">编号</div>
                    </template>
                    <span>10109013388</span>
                </el-descriptions-item>
            </template>
            <template v-slot:link>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            跳转链接
                            <!-- <span style="color: red">*</span> -->
                        </div>
                    </template>
                    <el-button size="mini" @click="opOptions" :disabled="read">设置</el-button>
                    <div class="option-item-box" v-if="optionList">
                        <div class="content">
                            <span class="option-item">
                                {{ optionList }}
                            </span>
                        </div>
                        <i v-if="!read" class="el-icon-circle-close" @click="deleoption"></i>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:releaseTime>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <!-- <el-date-picker
                        v-if="result.timeType == 2"
                        :disabled="read"
                        v-model="result.releaseTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间"
                        size="mini"
                    ></el-date-picker>
                    <el-date-picker
                        v-if="result.timeType == 3"
                        :disabled="read"
                        v-model="result.time"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        size="mini"
                        style="width:300px"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    ></el-date-picker> -->
                    <el-select
                        v-if="result.putingCityType === 2"
                        style="width: 50%"
                        v-model="companys"
                        size="mini"
                        multiple
                        placeholder="请选择"
                        @change="seletChange"
                    >
                        <el-option
                            v-for="item in companyData"
                            :key="item.ouCode"
                            :label="item.ouName"
                            :value="item.ouCode"
                        ></el-option>
                    </el-select>
                </el-descriptions-item>
            </template>
            <template v-slot:orderSort>
                <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            排序
                            <span style="color: red">*</span>
                        </div>
                        <el-input
                            :disabled="read"
                            v-model.number="result.orderSort"
                            style="width: 100px"
                            type="number"
                            size="mini"
                            @input="handleSort"
                        ></el-input>
                    </template>
                    <div style="text-align: left"></div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>

        <div class="bottom-btn">
            <el-button type="primary" size="mini" @click="submit" :disabled="read">保存</el-button>
        </div>
        <options
            :optionvisible="optionvisible"
            @closeOption="closeOption"
            @sendOption="sendOption"
        ></options>
    </el-drawer>
</template>
<script>
import Options from './options.vue';
import { getOssConfig } from '@/api/login.js';
import { uploadConfigData } from '@/config.js';
import { addEcoration, getRotaion } from '@/api/mkt/market.js';
import { getLabellist } from '@/api/mkt/topic.js';
import { getCompany } from '@/api/mkt/article-group.js';
// import mixins from '@/mixins';

export default {
    // mixins: [mixins],
    components: {
        Options,
    },
    props: {
        detailDiag: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '',
        },
        moduleId: {
            type: String,
            default: '',
        },
        read: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    key: 'id',
                },
                {
                    key: 'resourcePath',
                    name: '广告图',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '配置',
                        disabled: this.read,
                        tip: '建议图片最小宽度800-3000px，图片不超过10M',
                        size: 1024000,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    spanCol: 3,
                    rules: [{ required: true, message: '请选择广告图' }],
                },
                {
                    key: 'title',
                    name: '名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                        maxlength: 20,
                        showWordLimit: true,
                        disabled: this.read,
                    },
                    rules: [{ required: true, message: '请输入名称' }],
                },
                // {
                //     key: 'resourceDescribe',
                //     name: '描述',
                //     type: 'textarea',
                //     options: {
                //         placeholder: '请输入描述文字',
                //         maxlength: 100,
                //         showWordLimit: true,
                //         disabled: this.read,
                //     },
                // },
                // {
                //     key: 'labelIds',
                //     name: '标签',
                //     type: 'cascader',
                //     options: {
                //         placeholder: '最多选择20个',
                //         allLevels: false,
                //         clearable: true,
                //         options: [],
                //         props: {
                //             label: 'labelName',
                //             value: 'id',
                //             checkStrictly: true,
                //             emitPath: false,
                //             multiple: true,
                //         },
                //     },
                //     spanCol: 1,
                // },
                {
                    key: 'link',
                    name: '跳转链接',
                },
                // {
                //     key: 'orderSort',
                //     name: '排序',
                //     type: 'number',
                //     options: {
                //         disabled: this.read,
                //     },
                //     rules: [{ required: true, message: '请输入排序' }],
                // },
                {
                    key: 'putingCityType',
                    name: '投放城市',
                    type: 'radio',
                    options: {
                        placeholder: '',
                        disabled: this.read,
                        options: [
                            {
                                value: 1,
                                label: '全国可见',
                            },
                            {
                                value: 2,
                                label: '部分城市可见',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择投放城市' }],
                },
                // {
                //     key: 'timeType',
                //     name: '投放时间',
                //     type: 'radio',
                //     options: {
                //         placeholder: '',
                //         disabled: this.read,
                //         options: [
                //             {
                //                 value: 1,
                //                 label: '不限时间',
                //             },
                //             {
                //                 value: 2,
                //                 label: '指定时间',
                //             },
                //             {
                //                 value: 3,
                //                 label: '指定时间范围',
                //             },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                //     rules: [{ required: true, message: '请选择投放时间' }],
                // },
                {
                    key: 'releaseTime',
                },
                {
                    key: 'shelfStatus',
                    name: '是否上架',
                    type: 'radio',
                    options: {
                        placeholder: '',
                        disabled: this.read,
                        options: [
                            {
                                value: 0,
                                label: '是',
                            },
                            {
                                value: 1,
                                label: '否',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择是否上架' }],
                },
            ],
            labelOptions: [],
            result: {
                resourcePath: [], // 图片
                title: '', // 标题
                // resourceDescribe: '', // 描述
                // labelIds: [], // 标签
                linkType: '',
                linkPath: '',
                link: '',
                orderSort: 0, // 排序值
                putingCityType: 1, // 投放城市
                releaseTime: '', // 投放时间
                time: [],
                timeType: 1, // 投放类型
                shelfStatus: 0, // 上架状态
                companyLists: [],
            },
            companys: [],
            uploadConfig: {},
            optionvisible: false,
            value1: '',
            optionList: '',
        };
    },
    mounted() {
        this.getOssFn();
        if (this.id) {
            this.getRotaion(this.id);
            this.result.time = [];
            this.result.releaseTime = '';
        }
        this.getLabelOptions();
        this.getCompany();
    },
    methods: {
        getOssFn() {
            getOssConfig().then(res => {
                if (res?.isSuccess) {
                    uploadConfigData.oss.accessKeyId = res.data.stsKeyId;
                    uploadConfigData.oss.accessKeySecret = res.data.stsSecret;
                    uploadConfigData.oss.stsToken = res.data.token;
                    this.uploadConfig = uploadConfigData;
                }
            });
        },
        getCompany() {
            getCompany().then(res => {
                if (res?.isSuccess) {
                    this.companyData = res?.data || [];
                }
            });
        },
        handleSort(val) {
            var regPos = /^[0-9]*[1-9][0-9]*$/; // 非负整数
            if (!regPos.test(val)) {
                this.result.orderSort = 0;
                return;
            }
            if (val > 9999) {
                this.result.orderSort = 9999;
            }
        },
        handleClose() {
            this.$emit('closeDiag');
        },
        closeOption() {
            this.optionvisible = false;
        },
        sendOption(e, val, row, tagName) {
            this.optionvisible = false;
            let list = [];
            if (e?.length) {
                e.map(item => list.push(item.label));
                this.optionList = list.join('-');
                // this.result.linkType = 1;
                this.result.linkType = this.getType(e[0].label);
                this.result.detailsType = this.getdetailType(e[1].label);
                this.result.linkPath = this.optionList;
                this.result.link = e[1].value;
            }
            if (val) {
                this.result.link = val;
            }
            if (row) {
                if (row?.origin) {
                    this.optionList = this.optionList + '-' + row.origin;
                }
                this.optionList = this.optionList + '-' + row.label;
                this.result.linkPath = this.optionList;
            }
            if (tagName?.length) {
                this.optionList = this.optionList + '-' + tagName[0].label;
                this.result.linkPath = this.optionList;
            }
            if (row?.type) {
                this.result.linkParamType = row.type;
            }
        },
        getType(label) {
            let type = 0;
            switch (label) {
                case '笔记':
                    type = 2;
                    break;
                case '案例':
                    type = 1;
                    break;
                case '探索灵感':
                    type = 3;
                    break;
                case '话题':
                    type = 5;
                    break;
                case '设计师':
                    type = 4;
                    break;
                default:
                    break;
            }
            return type;
        },
        getdetailType(label) {
            let type = 0;
            switch (label) {
                case '默认列表':
                    type = 1;

                    break;
                case '指定列表':
                    type = 2;
                    break;
                case '详情':
                    type = 3;
                    break;
                default:
                    break;
            }
            return type;
        },
        seletChange(val) {
            let obj = {};
            let list = [];
            this.companyData.map(item => {
                if (val.includes(item.ouCode)) {
                    obj.companyCode = item.ouCode;
                    obj.companyName = item.ouName;
                    list.push(obj);
                }
            });
            this.result.companyLists = list;
        },
        opOptions() {
            this.optionvisible = true;
        },
        deleoption() {
            this.optionList = '';
            this.result.link = '';
            this.result.linkPath = '';
            this.result.linkType = '';
        },
        handRules(e) {
            console.log(e, 8888);
        },
        submit() {
            this.$refs.marketform.validateAll('OM_MARKET', () => {
                //校验回调
                const params = {
                    ...this.result,
                };
                // if (!params.link) {
                //     this.$message.warning('请选择跳转链接');
                //     return;
                // }
                params.resourcePath = params.resourcePath[0].url;
                if (this.id) {
                    params.id = this.id;
                }
                params.orderSort = Number(params.orderSort);
                params.moduleId = this.moduleId;
                if (this.result.putingCityType === 2 && !this.companys?.length) {
                    this.$message.warning('请选择投放城市');
                    return;
                }
                if (this.result.putingCityType === 1) {
                    params.companyLists = [];
                }
                if (params.timeType == 3) {
                    if (params?.time?.length) {
                        params.startTime = this.result.time[0];
                        params.endTime = this.result.time[1];
                        params.releaseTime = '';
                    } else {
                        this.$message.warning('请选择具体时间');
                        return;
                    }
                } else if (params.timeType == 2) {
                    if (!params.releaseTime) {
                        this.$message.warning('请选择具体时间');
                        return;
                    }
                    params.startTime = '';
                    params.endTime = '';
                }
                delete params.time;
                // if (params.labelIds?.length) {
                //     const list = this.handLabel(this.labelOptions);
                //     params.labelIds = list;
                // }
                this.addEcoration(params);
            });
        },
        handLabel(options) {
            let list = [];
            options.map(item => {
                if (item?.children?.length) {
                    // item.children.map(i => {
                    //     if (this.result.labelIds.includes(i.id)) {
                    //         list.push({
                    //             groupType: item.groupType,
                    //             labelName: i.labelName,
                    //             labelId: i.id,
                    //         });
                    //     }
                    // });
                    this.handLabel(item.children);
                }
            });
            return list;
        },
        async addEcoration(params) {
            const res = await addEcoration(params);
            if (res?.code === 200) {
                this.$message.success('保存成功');
                this.$emit('closeDiag', 'success');
            }
        },
        // 获取标签列表
        getLabelOptions() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.labelOptions = res.data;
                    // this.columns.map(item => {
                    //     if (item.key === 'labelIds') {
                    //         item.options.options = res.data;
                    //     }
                    // });
                }
            });
        },
        async getRotaion(id) {
            const res = await getRotaion(id);
            if (res?.isSuccess) {
                for (let i in this.result) {
                    this.result[i] = res.data[i];
                }
                this.result.resourcePath = [
                    {
                        url: res.data.resourcePath,
                    },
                ];
                if (res.data?.startTime) {
                    this.result.time = [res.data.startTime, res.data.endTime];
                }
                this.result.labelIds = res.data.inspirationIds;
                if (res.data?.companyLists?.length) {
                    res.data.companyLists.map(item => this.companys.push(item.companyCode));
                }
                this.optionList = res.data.linkPath;
                // console.log(this.result);
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .self-drawer {
    padding: 0 20px;
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
/deep/ .el-descriptions__header {
    display: none;
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
.option-item-box {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 286px;
    min-height: 30px;
    padding: 0 10px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(192, 170, 130, 100);
    font-size: 12px;
    border: 1px solid #eee;
}
.el-icon-circle-close {
    cursor: pointer;
}
</style>
