<template>
    <div class="mkt">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
        <div class="content">
            <sc-table-v2
                :head="tableHead"
                :data="tableData"
                :page="pagination"
                :table-height="tableHeight"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="handleSizeChange"
                class="list-table"
            ></sc-table-v2>
        </div>
    </div>
</template>

<script>
import { getCodeList } from '@/api/mkt/user.js';
export default {
    name: 'code-list',
    data() {
        return {
            columns: {
                phone: {
                    name: '手机号',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
            },
            result: {
                phone: '', //手机号
            },
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            tableHeight: window.innerHeight - 185,
            size: 'small',
            moreDown: false,
            tableHead: [
                { name: '手机号', key: 'phone' },
                { name: '验证码', key: 'openCode' },
                { name: '创建时间', key: 'createTime' },
            ],
            tableData: [],
            pagination: { count: 0, index: 1, size: 30 },
        };
    },

    created() {
        this.getData();
    },

    methods: {
        async getData() {
            let params = {
                page: this.pagination.index,
                size: this.pagination.size,
                phone: this.result.phone,
            };
            let { code, data } = await getCodeList(params);
            if (code === 200) {
                this.tableData = data?.children || [];
                this.pagination.count = data?.total;
            }
        },

        clickFn(e, type) {
            if (type === 'clear') {
                this.result.phone = '';
            }
            this.getData();
        },

        handleSizeChange(val) {
            this.pagination.size = val;
            this.getData();
        },

        pageChange(val) {
            this.pagination.index = val;
            this.getData();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
    },
};
</script>

<style lang="less" scoped></style>
