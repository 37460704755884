<template>
    <div>
        <div class="subject-btn">
            <el-button type="info" plain style="margin-left:10px;float: left;" size="small">
                批量移除
            </el-button>
            <el-button type="primary" size="small" @click="createArticle" style="float:right">
                添加内容
            </el-button>
        </div>
        <subsetSearch @callback="onCallback" />
        <sc-table-v2
            title=""
            :head="tableHead"
            :data="contentList"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :table-height="height"
            :read-table="true"
            :load="false"
            ref="table"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @selection-change="handleSelectionChange"
        >
            <template v-slot:selection>
                <el-table-column type="selection" width="55"></el-table-column>
            </template>
            <template v-slot:createUserName>
                <el-table-column label="创建人" width="120">
                    <template slot-scope="scope">
                        {{ scope.row.createUserName }}
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="60">
                    <template slot-scope="scope">
                        <span class="btn-style" type="text">添加</span>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <article-drawer ref="articleDialog" />
    </div>
</template>

<script>
import articleDrawer from './article-drawer';
import subsetSearch from './subset-search.vue';
import { getArticleList } from '@/api/mkt/article-group.js';

export default {
    name: 'subject-from',
    components: { articleDrawer, subsetSearch },
    props: {
        drawer: {
            type: Boolean,
        },
        id: {
            typeof: Number,
        },
    },
    data() {
        return {
            tableHead: [
                { name: '', key: 'selection' },
                {
                    name: '内容名称', //表头名称
                    key: 'specialName', //数据列的key值
                },
                { name: '内容类型', key: 'categoryName' },
                { name: '所属分类', key: 'categoryName' },
                { name: '运营标签', key: 'contentLabelId' },
                { name: '标签', key: 'enabled' },
                { name: '创建人', key: 'createUserName' },
                { name: '创建时间', key: 'createTime' },
                { name: '投放城市', key: 'createTime' },
                { name: '操作', key: 'control' },
            ],
            height: window.screen.height - 340,

            contentList: [
                {
                    specialName: '222',
                    categoryName: '3333',
                    contentLabelId: '3444',
                    enabled: '5555',
                    companys: '66666',
                    createUserName: '67777',
                    createTime: '8888',
                },
            ],
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            searchData: {},
        };
    },

    watch: {
        // drawer(v) {
        //     if (v) {
        //         this.searchData.specialId = this.id;
        //         console.log('管理专题列表');
        //         this.getData();
        //     }
        // },
        drawer: {
            handler(newName, oldName) {
                console.log(newName, oldName, '管理专题列表');
                if (newName) {
                    this.searchData.specialId = this.id;
                    this.getData();
                }
            },
            immediate: true,
        },
    },
    created() {},
    methods: {
        onCallback(e) {
            var param = {};
            let obj = e;
            for (var key in obj) {
                if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                    param[key] = obj[key];
                }
            }
            this.getData(param);
        },
        getData(param) {
            this.searchData = Object.assign(this.page, param);
            getArticleList(this.searchData).then(res => {
                if (res?.isSuccess) {
                    console.log(res, '列表数据');
                    this.list = res.data.children || [];
                    this.page.count = res.data.total || 0;
                }
            });
        },
        createArticle() {
            this.$refs.articleDialog.openDrawer();
        },
        pageSizesChange(val) {
            console.log(val, '条数修改');
            this.page.size = val;
        },

        pageChange() {},
        handleSelectionChange(val) {
            console.log(val, 'val----');
        },
    },
};
</script>

<style scoped lang="less">
.subject-btn {
    margin-bottom: 10px;
    overflow: hidden;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}
</style>
