<template>
    <div class="mkt">
        <Search></Search>
        <Tab ref="tabRef"></Tab>
    </div>
</template>

<script>
import Search from './search';
import Tab from './tab';
export default {
    name: 'article-index',
    components: { Search, Tab },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-article/clear');
    },
    mounted() {},
};
</script>
<style lang="scss" scoped></style>
