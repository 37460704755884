<template>
    <el-drawer
        :visible.sync="addiagvisible"
        size="30%"
        :before-close="handleClose"
        custom-class="self-drawer"
        direction="rtl"
    >
        <sc-form-v2
            name="选择属性"
            tablename="CRM_PerformanceSharing_M"
            :columnsNum="1"
            :columns="columns"
            :result="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
            @click="clickFn"
            ref="form1"
        >
            <template v-slot:k6>
                <el-descriptions-item
                    span="2"
                    :labelStyle="{ background: '#fff', color: '#000', paddingTop: '20px' }"
                    v-for="(item, findex) in slotList"
                    :key="findex"
                >
                    <template slot="label">
                        <div class="labelName">{{ item.title }}</div>
                    </template>
                    <draggable
                        v-model="item.inpList"
                        handle=".mover"
                        animation="300"
                        @start="onStart.bind(this, item, findex)"
                        @end="onEnd.bind(this, item, findex)"
                        :key="findex"
                    >
                        <transition-group>
                            <div
                                class="inp-item mover"
                                v-for="(two, sindex) in item.inpList"
                                :key="sindex + 1"
                            >
                                <span>{{ two.filterName }}</span>
                                <i class="el-icon-circle-close" @click="dele(findex, sindex)"></i>
                            </div>
                        </transition-group>
                    </draggable>
                    <div
                        class="inp-item"
                        @click="add(item, findex)"
                        v-if="!(item.inpList.length > 2 && findex == 0)"
                    >
                        <span>
                            <i class="el-icon-circle-plus"></i>
                            添加
                        </span>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>
        <div class="bottom-btn">
            <el-button size="mini" @click="handleClose">取 消</el-button>
            <el-button size="mini" type="primary" @click="handleSubmit">保 存</el-button>
        </div>
        <Inner
            :options="options"
            :option-type="optionType"
            :inneriagvisible="inneriagvisible"
            :originList="originList"
            :primaryslotList="primaryslotList"
            @commitData="commitData"
            @closeinnerDiag="closeinnerDiag"
        ></Inner>
    </el-drawer>
</template>
<script>
import draggable from 'vuedraggable';
import Inner from './inner-add.vue';
import { getCheckboxItem, getCheckboxOptions, saveCheckboxItem } from '@/api/mkt/checkbox';
export default {
    components: {
        draggable,
        Inner,
    },
    props: {
        addiagvisible: {
            type: Boolean,
            default: false,
        },
        pageId: {
            type: String,
            default: '',
        },
        pageName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            columns: [
                {
                    key: 'pageName',
                    name: '页面',
                    type: 'input',
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                },
                {
                    key: 'k6',
                },
            ],
            result: {
                pageName: '',
            },
            slotList: [
                {
                    title: '主筛选',
                    inpList: [{ id: 1 }, { id: 2 }, { id: 3 }],
                    filterType: 1,
                },
                {
                    title: '次筛选',
                    inpList: [{ id: 1111 }, { id: 1112 }],
                    filterType: 2,
                },
                // {
                //     title: '运营配置项',
                //     inpList: [
                //         { id: 2221 },
                //         { id: 2222 },
                //     ],
                // },
            ],
            inneriagvisible: false,
            options: {
                classifyPropertiesList: [], //分类属性,
                labelPropertiesList: [], //标签属性
                otherPropertiesList: [], //其它
                projectPropertiesList: [], //项目属性
                worksPropertiesList: [], //案例属性
            },
            optionType: '',
            filterIdArrTmp: [],
            originList: [],
            primaryslotList: [], //主筛选项list
        };
    },
    watch: {
        pageId(newData, oldData) {
            newData = newData || '';
            this.editor.txt.html(newData);
            if (newData != oldData) {
                this.getCheckboxItem();
            }
        },
    },
    mounted() {
        this.getCheckboxItem();
    },
    methods: {
        // 获取回显数据

        async getCheckboxItem() {
            if (this.pageId) {
                let res = await getCheckboxItem({ pageId: this.pageId || 1 });
                if (res.code == 200) {
                    // this.list = res.data;
                    // console.log('-----------getCheckboxItem', res.data);
                    this.slotList[0].inpList = (res.data && res.data.primaryFilterList) || [];
                    this.slotList[1].inpList = (res.data && res.data.secondaryFilterList) || [];
                    this.result.pageName = res.data.pageName || this.pageName;
                }
                this.originList = [...this.slotList[0].inpList, ...this.slotList[1].inpList];
                console.log('-----slotList', this.slotList);
            }
        },
        async getCheckboxOptions(item, findex) {
            if (this.pageId) {
                let res = await getCheckboxOptions({
                    pageId: this.pageId || 1,
                    filterType: item.filterType || 1,
                });
                let tempArr = this.primaryslotList.map(d => d.filterId);
                if (findex == 0) {
                    for (let d of res.data) {
                        d.filterIds = [];
                        // 由于接口数据不对 无法比对 所以清空自己用显示数据 比对回显disabled 和数量

                        // let arr = [...d.filterIds];
                        // if (!arr.filter(val => tempArr.some(value => val === value)).length) {
                        //     d.filterIds = [];
                        //     console.log(
                        //         '--------------------------------------------------------------------',
                        //         d.filterIds.filter(val => tempArr.some(value => val === value))
                        //             .length,
                        //         arr,
                        //         tempArr
                        //     );
                        // }
                    }
                }
                console.log('------res', res, this.primaryslotList, tempArr);
                if (res.code == 200) {
                    // this.list = res.data;
                    this.options = res.data || [];
                }
            }
        },
        // 开始拖拽事件
        onStart(item, findex, options) {
            this.drag = true;
        },
        // 拖拽结束事件
        onEnd(item, findex, options) {
            this.drag = false;
        },
        changeFn() {},
        clickFn() {},
        dele(findex, sindex) {
            this.slotList[findex].inpList.splice(sindex, 1);
            this.originList = [...this.slotList[0].inpList, ...this.slotList[1].inpList];

            console.log('--------------------------------------add', this.options);
        },
        add(item, index) {
            this.getCheckboxOptions(item, index);
            this.inneriagvisible = true;
            this.optionType = index === 1 ? 'secondaryFilterList' : 'primaryFilterList';
            if (index === 0) {
                console.log('----------', item.inpList);
                this.primaryslotList = item.inpList;
            }
        },
        // 添加内容
        commitData(data, type) {
            console.log('-------data', data, type);
            let { filterArr } = data;
            // 去重
            for (let item of this.originList) {
                filterArr = filterArr.filter(fil => fil.filterId != item.filterId);
            }
            if (type == 'primaryFilterList') {
                let originItem = this.slotList[0].inpList;
                this.slotList[0].inpList = [...originItem, ...filterArr];
            } else {
                let originItem = this.slotList[1].inpList;
                this.slotList[1].inpList = [...originItem, ...filterArr];
            }
            this.originList = [...this.slotList[0].inpList, ...this.slotList[1].inpList];
            this.inneriagvisible = false;
        },
        closeinnerDiag() {
            this.inneriagvisible = false;
        },
        handleClose() {
            this.$confirm('确认关闭？')
                .then(() => {
                    this.$emit('closeDiag');
                })
                .catch(() => {});
        },
        async handleSubmit() {
            if (this.pageId) {
                let list = [...this.slotList[0].inpList, ...this.slotList[1].inpList];
                let res = await saveCheckboxItem({ filterDetParamList: list, pageId: this.pageId });
                if (res.code == 200) {
                    this.$emit('init');
                    this.$emit('closeDiag');
                    this.originList = [];
                }
                console.log('-----slotList', this.slotList);
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .self-drawer {
    padding: 0 20px;
}
.inp-item {
    padding: 10px;
    width: 400px;
    border: 1px solid #eee;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #666;
}
.el-icon-circle-close {
    font-size: 16px;
    cursor: pointer;
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
    text-align: center;
}
</style>
