<template>
    <!-- 设计师榜 -->
    <div class="mkt" ref="table">
        <div class="tabs">
            <!-- <el-tabs v-model="tabActive" @tab-click="tabClick">
                <el-tab-pane
                    :label="item.designerName"
                    :key="item.id"
                    :name="item.id"
                    v-for="(item, index) in tabList"
                ></el-tab-pane>
            </el-tabs> -->

            <sc-search-v2
                style="padding-top: 20px;"
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="searchFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>
            <div class="btns">
                <el-button class="button-back" size="small" type="primary" @click="addDesign">
                    添加设计师
                </el-button>
                <el-button class="button-back" size="small" type="primary" @click="routerBack()">
                    返回
                </el-button>
            </div>
            <div class="tabs">
                <sc-table-v2
                    class="list-table"
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="true"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:deag>
                        <el-table-column label="" width="60" align="center">
                            <i class="el-icon-sort icon" style="font-size:20px;"></i>
                        </el-table-column>
                    </template>
                    <template v-slot:imgUrl>
                        <el-table-column label="背景图">
                            <template slot-scope="scope">
                                <el-image
                                    :src="
                                        scope.row.imgUrl +
                                            '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                                    "
                                    fit="contain"
                                    style="width: 50px; height: 50px"
                                    :preview-src-list="[scope.row.imgUrl]"
                                ></el-image>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:coverVoList>
                        <el-table-column label="展示封面">
                            <template slot-scope="scope">
                                <el-image
                                    v-if="scope.row.coverVoList && scope.row.coverVoList[0]"
                                    :src="
                                        scope.row.coverVoList &&
                                            scope.row.coverVoList[0].url +
                                                '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                                    "
                                    fit="contain"
                                    style="width: 50px; height: 50px"
                                    :preview-src-list="[
                                        scope.row.coverVoList && scope.row.coverVoList[0].url,
                                    ]"
                                ></el-image>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                    </template>

                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="100">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="onEdit(scope.row)">
                                    编辑
                                </el-button>
                                <el-button type="text" size="small" @click="onIsShow(scope.row)">
                                    {{ scope.row.isDisplay == 0 ? '不展示' : '展示' }}
                                </el-button>
                                <!-- <el-button
                                    type="text"
                                    style="color: red"
                                    size="small"
                                    @click="onDel(scope.row)"
                                >
                                    删除
                                </el-button> -->
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>
        <edit ref="edit" @upDataList="upDataList"></edit>
        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
        <add-designer ref="addDesignerRef" @refresh="init()"></add-designer>
    </div>
</template>

<script>
import edit from './components/edit.vue';
import editDialog from './components/detail.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import Sortable from 'sortablejs';
import addDesigner from './components/add-designer.vue';
import {
    getRankingList,
    updateShow,
    updateEcho,
    rankingSort,
} from '@/api/mkt/designer-ranking-quick';

export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog, edit, addDesigner },
    data() {
        return {
            size: 'small',
            tabActive: '',
            tabList: [],
            // tableHandle: getRankingList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '130px', 'padding-bottom': '15px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '1', key: 'deag' },
                { name: '设计师ID', key: 'accountId' },
                { name: '设计师名称', key: 'nickName' },
                { name: '设计师头像', key: 'imgUrl' },
                // { name: '来源', key: '' },
                { name: '展示封面', key: 'coverVoList' },
                {
                    name: '是否显示',
                    key: 'isDisplay',
                    formatter: val => {
                        return val.isDisplay == 0 ? '展示' : '不展示';
                    },
                },
                // { name: '编辑人', key: '' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            columns: {
                accountId: {
                    name: '设计师ID',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择',
                    },
                    spanCol: 1,
                },
                name: {
                    name: '设计师姓名/昵称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择',
                    },
                    spanCol: 1,
                },
                isShow: {
                    name: '是否显示',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择',
                        options: [
                            { label: '全部', value: ' ' },
                            { label: '展示', value: '0' },
                            { label: '不展示', value: '1' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清除搜索', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            result: {},
            tableAddConfig: {},
            filter: {},
            listData: [],
            queryId: '',
            isLoading: false,
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 220;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });

        this.queryId = this.$route.query.id;
        if (this.queryId) {
            this.init();
        }
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 220;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 220;
        },
    },
    mounted() {
        const tbody = document.querySelector('.list-table tbody');
        let that = this;
        Sortable.create(tbody, {
            handle: '.icon', // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
            onEnd({ newIndex, oldIndex }) {
                if (newIndex != oldIndex) {
                    that.listData.splice(newIndex, 0, that.listData.splice(oldIndex, 1)[0]);
                    var newArray = that.listData.slice(0);
                    that.listData = [];
                    that.$nextTick(function() {
                        that.listData = newArray;
                        // 拖拽事件结束
                        let list = [];
                        that.listData.map(item => list.push(item.id));
                        rankingSort(list);
                        console.log('move', newIndex, oldIndex, that.listData);
                    });
                }
            },
        });
    },
    methods: {
        addDesign() {
            this.$refs.addDesignerRef.open();
        },
        handleClickMore() {},
        handleClose() {
            this.getList();
        },
        async init() {
            this.rankingList();
            // let res = await getRankingRowList();
            // console.log(res.data.children);
            // if (res) {
            //     this.tabList = res.data.children;
            //     this.tabActive = this.tabList[0]?.id;
            //     this.filter = {
            //         collectionId: this.tabActive,
            //     };
            // }
            // await this.getList();
        },
        rankingList(params) {
            let data = {};
            data = {
                collectionId: this.queryId,
            };
            if (params) {
                data = {
                    collectionId: this.queryId,
                    ...params,
                };
            }
            this.isLoading = true;
            getRankingList(data)
                .then(res => {
                    if (res.data) {
                        this.listData = res.data;
                    }
                    this.isLoading = false;
                })
                .catch(err => {
                    this.isLoading = false;
                });
        },
        async getList() {
            // this.isLoading = true;
            if (!this.filter.collectionId) {
                return false;
            }
            let data = this.filter;
            let res = await getRankingList(data);
            // this.isLoading = false;
            this.listData = res.data;
        },
        async queryIdData() {
            this.filter = {
                collectionId: this.queryId,
            };
            this.getList();
            let res = await getRankingRowList();
            if (res) {
                this.tabList = res.data.children;
                this.tabActive = this.tabList[0]?.id;
                // this.filter = {
                //     collectionId: this.tabActive,
                // };
            }
            return;
        },
        tabClick(tab) {
            this.tabActive = tab.name;
            this.filter = {
                collectionId: this.tabActive,
            };
            this.getList();
        },
        onEdit(row = {}) {
            row.collectionId = this.tabActive;
            this.$refs.edit.openDrawer(row);
        },
        addTag(row) {
            row.isLabel = true;
            this.$refs.editDialog.open(row);
        },
        onIsShow(row) {
            updateShow({ id: row.id, isShow: row.isDisplay == 0 ? 1 : 0 }).then(res => {
                this.$message.success(row.isDisplay == 0 ? '隐藏成功' : '展示成功');
                this.init();
            });
        },
        onDel(row) {
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                delRanking({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.getList();
                });
            });
        },
        upDataList() {
            this.init();
        },
        rowClick() {},
        dbClick() {},
        click() {},
        rowChange() {},
        selectionChange() {},
        changeFn() {
            this.rankingList(this.result);
        },
        searchFn() {
            this.rankingList(this.result);
        },
        // 点击事件回调方法
        clickFn() {},
        routerBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.btns {
    width: 100%;
    // height: 52px;
    padding: 0px 0px 0px 25px;
    display: flex;
    justify-content: space-between;
}

.button-back {
    // position: absolute;
    // right: 0;
    // margin-right: 25px;
}
</style>
