<template>
    <div class="tabs" ref="table">
        <div style="margin-top: 15px; margin-left: 10px">
            <el-button type="primary" size="mini" @click="handleAdd">新增灵感集</el-button>
        </div>
        <sc-table-v2
            title=""
            :head="tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :table-height="tableHeightCalc"
            :read-table="true"
            :load="loading"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @sortChange="sortChange"
            :default-sort="sort"
        >
            <template v-slot:createName>
                <el-table-column label="创建人" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createName
                        }}{{ scope.row.account && '（' + scope.row.account + '）' }}
                        <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                            {{ scope.row.createUserJob }}
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:isPublish>
                <el-table-column label="是否发布" fixed="right" width="90">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isPublish"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="1"
                            :inactive-value="0"
                            @change="onChangeEnabled(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
            </template>

            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="handleEdit(scope.row)">
                            编辑
                        </el-button>
                        <sc-qrcode-popover
                            :id="scope.row.id"
                            type="exhibit-group"
                        ></sc-qrcode-popover>
                        <el-button
                            type="text"
                            size="mini"
                            style="margin-left:10px;"
                            @click="handleExhibit(scope.row, 'del')"
                        >
                            <span style="color:red;">删除</span>
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <drawer-dialog ref="drawerDetail" @closeDrawer="closeDrawer"></drawer-dialog>
    </div>
</template>

<script>
import { del, update } from '@/api/mkt/exhibit-group';
import drawerDialog from './details.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('mkt-content-exhibit-group');
export default {
    name: 'exhibit-group-content',
    components: { drawerDialog },
    data() {
        return {
            sort: { prop: '', order: '' }, //ascending,descending  如果只指定了prop, 没有指定order, 则默认顺序是ascending
            tableHead: [
                {
                    name: 'ID',
                    key: 'id',
                    width: '156px',
                },
                {
                    name: '灵感集名称', //表头名称
                    key: 'inspirationName',
                    width: 200, //数据列的key值
                },
                { name: '分类', key: 'categoryName', width: 100, showOverflowTooltip: true },
                { name: '标签', key: 'labelName', width: 100, showOverflowTooltip: true },
                { name: '权重值', key: 'weightSort', sortable: true, width: 82 },
                { name: '内容数量', key: 'quantity', sortable: true, width: 94 },
                { name: '浏览量', key: 'pageView' },
                { name: '点赞量', key: 'praise' },
                { name: '收藏', key: 'favorite' },
                { name: '运营标签', key: 'operationName', width: 100, showOverflowTooltip: true },

                { name: '投放城市', key: 'launchCity', width: '160', showOverflowTooltip: true },
                // { name: '评级', key: 'contentLevel', formatter: this.parseLevel, width: '70px' }, // 精选 普通 无
                { name: '创建人', key: 'createName', width: '160px' },
                { name: '创建时间', key: 'createTime', sortable: true, width: '180px' },
                { name: '是否发布', key: 'isPublish' },
                { name: '操作', key: 'control' },
            ],
            tableHeightCalc: 520,
            innerHeight: 0,
            isSearchDown: false,
            sortObj: {
                weightSort: '',
                numberTime: '',
                createTime: '',
            },
            loading: false,
        };
    },
    computed: {
        ...mapState(['list', 'page']), // // 映射 this.list 为 store.state.list
        ...mapMutations(['setParams']),
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
    },
    methods: {
        onChangeEnabled(row) {
            console.log(row, 'row--------');
            this.handleSend(row, 'single');
        },
        async init() {
            this.loading = true;
            await this.$store.dispatch('mkt-content-exhibit-group/list');
            this.loading = false;
        },
        handleAdd() {
            this.$refs.drawerDetail.openDrawer();
        },
        parseLevel(row) {
            let levelList = [
                { label: '精选 ', dictValue: '1' },
                { label: '精选 ', dictValue: '2' },
                { label: '无', dictValue: '3' },
            ];
            let str = levelList.find(item => item.dictValue == row.contentLevel)?.label;
            return str || null;
        },
        closeDrawer() {
            this.$refs.drawerDetail.closeDrawer();
            this.getList();
        },
        sortChange(e) {
            console.log(e);
            Object.keys(this.sortObj).forEach(key => {
                console.log(key, 'key-----');
                this.sortObj[key] = '';
            });
            let params = {};

            let key =
                e.prop == 'quantity'
                    ? 'numberSort'
                    : e.prop == 'weightSort'
                    ? 'weightSort'
                    : e.prop + 'Sort';
            if (e.order == 'ascending') {
                this.sortObj[key] = 3; // 升序
            } else if (e.order == 'descending') {
                this.sortObj[key] = 2; // 倒序
            } else {
                this.sortObj[key] = '';
            }

            params = this.sortObj;
            this.$store.commit('mkt-content-exhibit-group/setParams', params);
            this.getList();
        },
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 编辑
        handleEdit(row) {
            this.$refs.drawerDetail.openDrawer(row.id);
        },
        handleSend(row, type) {
            this.$confirm(`确认${row.isPublish == 1 ? '发布' : '取消发布'}吗, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // 掉编辑接口
                    update({
                        id: row.id,
                        isPublish: row.isPublish,
                        singleRelease: type ? 1 : 0,
                    }).then(res => {
                        console.log(res, 'res----发布接口');
                        this.getList();
                    });
                })
                .catch(() => {
                    this.getList();
                });
        },
        handleExhibit(row, type) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    if (type == 'del') {
                        del({ id: row.id }).then(res => {
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                            });
                            this.getList();
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        pageChange(val) {
            this.page.page = val;
            let params = { page: val };
            this.$store.commit('mkt-content-exhibit-group/setParams', params);
            this.$store.dispatch('mkt-content-exhibit-group/list');
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            let params = { size: val };
            this.$store.commit('mkt-content-exhibit-group/setParams', params);
            this.$store.dispatch('mkt-content-exhibit-group/list');
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
    },
    created() {
        this.$store.commit('mkt-content-exhibit-group/clear');
        this.init();

        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$bus.$on('clearSearchExhibitGroup', data => {
            this.$refs.refTable.$refs['table-box'].clearSort();
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        });
    },
};
</script>

<style scoped>
.tabs {
    margin: 10px 15px;
}
</style>
