<template>
    <!-- 点赞 收藏 评论 分享 -->
    <div class="item-box">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="80%"
            title="用户行为明细"
            :before-close="close"
        >
            <div class="infor-item">
                <sc-table-v2
                    v-if="addialogVisible"
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :read-table="true"
                    :load="load"
                    :pop-show="false"
                    :show-add="false"
                    :table-height="tableHeightCalc"
                    :selection="false"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                ></sc-table-v2>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getUserHistory } from '@/api/mkt/user.js';

export default {
    name: 'browse-list',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {},
    data() {
        return {
            load: false,
            addialogVisible: false,
            tableHead: [
                // { name: 'ID', key: 'contentId' },
                // { name: '设备ID', key: 'deviceId ', minWidth: 280, showOverflowTooltip: true },
                { name: '登录所属页面', key: 'loginByPage', minWidth: 100 },
                {
                    name: '事件类型',
                    key: 'eventType',
                    formatter: this.eventTypeFormatter,
                    minWidth: 80,
                },
                { name: '访问页面', key: 'requestPage', minWidth: 150 },
                { name: '操作按钮', key: 'pageButtonClicked', minWidth: 100 },
                { name: '来源人', key: 'sharer' },
                { name: '来源渠道', key: 'promotionChannel', minWidth: 150 },
                // {
                //     name: '搜索关键字',
                //     key: 'searchKeywords',
                //     minWidth: 80,
                //     showOverflowTooltip: true,
                // },
                { name: '记录开始时间', key: 'startEventTime', minWidth: 126 },
                { name: '记录结束时间', key: 'endEventTime', minWidth: 126 },
                { name: '停留时长', key: 'duration', width: 70, formatter: this.durationFormatter },
                { name: '版本', key: 'version', width: 70 },
            ],
            tableHeightCalc: window.innerHeight - 420,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            type: '',
        };
    },
    methods: {
        getUserHistoryFn() {
            this.load = true;
            getUserHistory({
                uid: this.row.readId,
                shareId: this.row.shareId,
                page: this.page.page,
                size: this.page.size,
            }).then(res => {
                if (res.code == 200) {
                    this.load = false;
                    this.list = res.data.children;
                    this.page.count = res.data.total;
                }
            });
        },
        open(row) {
            this.page.page = 1;
            this.row = row || {};
            this.getUserHistoryFn();
            this.addialogVisible = true;
        },
        close() {
            this.addialogVisible = false;
        },
        pageChange(val) {
            this.page.page = val;
            this.getUserHistoryFn();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getUserHistoryFn();
        },
        eventTypeFormatter(row) {
            let obj = {
                open: '启动',
                login: '登录',
                view: '浏览',
                close: '关闭',
                share: '分享',
                search: '搜索',
                background: '隐藏后台',
            };
            return obj[row.eventType] || '';
        },
        durationFormatter(row) {
            if (row.eventType == 'background') {
                return '';
            }
            if (row.duration > 0) {
                let time = (parseInt(row.duration) / 1000).toFixed(2) || 0;
                return time + 's';
            }
            return '0s';
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    /deep/ .el-dialog__body {
        padding: 10px 20px !important;
    }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
    }
}
</style>
