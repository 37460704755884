let selOldData = {};
export default {
    data() {
        return {
            selfSelect: [],
            labelsAllOptions: [],
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
        };
    },
    watch: {
        normalLabelList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'labelsAll') {
                        const result = val.map(item => {
                            const children =
                                item.children?.length &&
                                item.children.map(every => {
                                    return {
                                        id: every?.id,
                                        labelGroupId: every?.labelGroupId,
                                        labelName: every?.labelName,
                                        parentId: every?.parentId,
                                        properties: every?.properties,
                                    };
                                });
                            return {
                                ...item,
                                children: children,
                            };
                        });
                        item.options.options = result;
                        this.labelsAllOptions = result;
                    }
                });
            }
        },
    },
    methods: {
        initSelData() {
            let selNowArr = [];
            Object.entries(selOldData).forEach(item => {
                const [label, value] = item;
                const labelArr = label.split(',');
                selNowArr.push([...labelArr, value]);
            });
            this.$nextTick(() => {
                this.selfSelect = selNowArr;
            });
        },
        changeSelect(val) {
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                const fatherValue = selOldData[fatherStr];
                if (fatherValue !== child) {
                    selOldData[fatherStr] = child;
                    break;
                }
            }
            this.initSelData();
        },
        removeSelect(val) {
            const fatherStr = val.slice(0, val.length - 1).join(',');
            delete selOldData[fatherStr];
            this.initSelData();
        },
        initSelectData(val) {
            selOldData = {};
            this.selfSelect = [];
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                selOldData[fatherStr] = child;
            }
            this.initSelData();
        },
    },
};
