var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-tag-container"},[_c('el-drawer',{attrs:{"title":"编辑","visible":_vm.addiagvisible,"direction":"rtl","before-close":_vm.handleClose,"custom-class":"self-drawer","size":"30%"},on:{"update:visible":function($event){_vm.addiagvisible=$event}}},[_c('sc-form-v2',{ref:"tagform",attrs:{"tablename":"CRM_TAG","columnsNum":1,"columns":_vm.columns,"result":_vm.result,"direction":"vertical","esayStyle":true,"columnsPaddingRight":20},on:{"change":_vm.changeFn,"click":_vm.clickFn},scopedSlots:_vm._u([{key:"qq",fn:function(){return [_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v("自定义标签：")])]),_c('div',{staticStyle:{"text-align":"left"}},_vm._l((_vm.result.divIds),function(tag){return _c('el-tag',{key:tag},[_vm._v(_vm._s(tag))])}),1)],2),_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v("运营标签：")])]),_c('el-cascader',{ref:"operaCascader",staticStyle:{"width":"95%"},attrs:{"size":_vm.size,"options":_vm.operationList,"placeholder":"最多选择20个","props":{
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },"show-all-levels":false,"clearable":"","filterable":""},on:{"change":_vm.selecttOpeTag},model:{value:(_vm.result.inspirationIds),callback:function ($$v) {_vm.$set(_vm.result, "inspirationIds", $$v)},expression:"result.inspirationIds"}})],2)]},proxy:true}])}),_c('div',{staticClass:"demo-drawer__footer",staticStyle:{"text-align":"right","margin-top":"50px"}},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"size":"mini"},on:{"click":_vm.handleReset}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handSubmit}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }