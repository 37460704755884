<template>
    <div>
        <el-dialog title="备注" :visible.sync="dialogFormVisible" width="30%">
            <el-form :model="form" ref="remarkForm">
                <el-form-item
                    label=""
                    prop="remark"
                    :rules="[{ required: true, message: '请输入备注', trigger: 'blur' }]"
                >
                    <el-input
                        type="textarea"
                        v-model="form.remark"
                        maxlength="20"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'dialog-remark',
    data() {
        return {
            dialogFormVisible: false,
            form: {
                remark: '',
            },
        };
    },
    methods: {
        open() {
            this.dialogFormVisible = true;
            this.form.remark = '';
        },
        close() {
            this.dialogFormVisible = false;
            this.form.remark = '';
        },
        submit() {
            this.$refs['remarkForm'].validate(valid => {
                if (valid) {
                    this.$emit('remarkBak', this.form.remark);
                    this.close();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>
