<template>
    <!-- <div class="create"> -->
    <el-drawer
        :title="title"
        :visible.sync="drawer"
        direction="rtl"
        :before-close="handleClose"
        custom-class="self-drawer"
        size="50%"
        :wrapperClosable="false"
        :destroy-on-close="true"
    >
        <div style="margin-left: 10px">
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="exhibitgroupform"
            >
                <template v-slot:inspirationWorks>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                灵感集图片:
                                <span class="must">*</span>
                            </div>
                        </template>
                        <div style="text-align: left">
                            <draggable v-model="result.inspirationWorks" @sort="handleSort">
                                <transition-group class="warp" style="width: 40vw">
                                    <div
                                        v-for="(item, index) in result.inspirationWorks"
                                        :key="item.worksFileId + index"
                                        style="margin-bottom: 10px"
                                    >
                                        <el-card :body-style="{ display: 'flex' }" shadow="hover">
                                            <el-image
                                                style="
                                                    width: 30px;
                                                    height: 30px;
                                                    margin-right: 10px;
                                                "
                                                :src="item.url"
                                                fit="cover"
                                                :preview-src-list="[item.url]"
                                            ></el-image>
                                            <el-input
                                                style=""
                                                v-model="item.spaceDescribe"
                                                maxlength="30"
                                                show-word-limit
                                                size="mini"
                                                placeholder="请输入图片描述"
                                            ></el-input>
                                            <el-button
                                                size="mini"
                                                @click="handleDelete(item)"
                                                style="margin-left: 10px"
                                            >
                                                <i class="el-icon-delete"></i>
                                            </el-button>
                                        </el-card>
                                    </div>
                                </transition-group>
                            </draggable>
                            <el-button
                                type="primary"
                                @click="handleOpenInner"
                                size="mini"
                                style="margin-left: 10px"
                            >
                                添加灵感
                            </el-button>
                            <el-drawer
                                title="案例图库"
                                :append-to-body="true"
                                :before-close="handleClose"
                                :visible.sync="innerDrawer"
                                size="50%"
                            >
                                <work-collect
                                    v-if="innerDrawer"
                                    @innerDrawerSubmit="getCheckMsg"
                                    ref="workCollect"
                                    @handleCancle="handleCancle"
                                ></work-collect>
                            </el-drawer>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:companys v-if="isShowCompanys">
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                分公司
                                <span class="must">*</span>
                            </div>
                        </template>
                        <div style="text-align: left">
                            <el-select
                                v-model="companys"
                                :size="size"
                                multiple
                                placeholder="请选择"
                                @change="seletChange"
                            >
                                <el-option
                                    v-for="item in companyData"
                                    :key="item.ouCode"
                                    :label="item.ouName"
                                    :value="item.ouCode"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:labelIds>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                标签
                                <span class="must">*</span>
                            </div>
                        </template>
                        <div style="text-align: left">
                            <el-cascader
                                v-model="selfSelect"
                                :options="normalLabelList"
                                :props="props"
                                clearable
                                @change="changeSelect"
                                @remove-tag="removeSelect"
                                ref="selfSelect"
                                style="width:100%;"
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
        </div>
        <div class="footer">
            <el-button size="small" @click="clear">取消</el-button>
            <el-button type="primary" size="small" @click="submit" v-preventReClick="3000">
                确定
            </el-button>
        </div>
    </el-drawer>
    <!-- </div> -->
</template>
<script>
import draggable from 'vuedraggable';
import mixins from '@/mixins';
import { getLabellist } from '@/api/mkt/topic.js';
import { save, detailID, update } from '@/api/mkt/exhibit-group';
import workCollect from './work-collect.vue';
let selOldData = {};
export default {
    name: 'exhibit-group-details',
    mixins: [mixins],
    components: { workCollect, draggable },
    data() {
        return {
            selfSelect: [],
            normalLabelList: [],
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            labelOptions: [],
            isShowCompanys: false,
            labelIds: [],
            inspirationWorks: [],
            companys: [],

            url: '',
            srcList: [],

            labelList: [],
            operationList: [],
            title: '',
            size: 'mini',
            columns: {
                inspirationName: {
                    key: 'inspirationName',
                    name: '灵感集名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入灵感集名称', trigger: 'blur' }],
                },
                putingCityType: {
                    key: 'putingCityType',
                    name: '投放城市',
                    type: 'radio',
                    options: {
                        defaultValue: '',
                        placeholder: '占位',
                        options: [
                            {
                                value: '1',
                                label: '全国',
                            },
                            {
                                value: '2',
                                label: '部分城市可见',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择投放城市' }],
                },
                companys: {
                    key: 'companys',
                    name: '分公司',
                    type: 'hidden',
                    rules: [{ required: true, message: '请选择分公司' }],
                },
                inspirationWorks: {
                    key: 'inspirationWorks',
                    name: '灵感集图片',
                    rules: [{ required: true, message: '请选择灵感集图片' }],
                },
                labelIds: {
                    key: 'labelIds',
                    name: '标签',
                    type: 'select',
                    multiple: true,
                    options: {
                        defaultValue: '0',
                        placeholder: '',

                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择标签' }],
                },
                isPublish: {
                    key: 'isPublish',
                    name: '是否发布',
                    type: 'radio',
                    options: {
                        defaultValue: '1',
                        placeholder: '占位',
                        options: [
                            {
                                value: '1',
                                label: '是',
                            },
                            {
                                value: '0',
                                label: '否',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择是否发布' }],
                },
                weightSort: {
                    key: 'weightSort',
                    name: '权重值',
                    type: 'number',
                    options: {
                        placeholder: '请输入权重值',
                        clearable: true,
                        max: 9999,
                        min: 0,
                    },
                },
                recommendation: {
                    key: 'recommendation',
                    name: '灵感集描述',
                    type: 'textarea',
                    options: {
                        placeholder: '请输入灵感集描述',
                        maxlength: 200,
                        showWordLimit: true,
                    },
                    // rules: [{ required: true, message: '请输入灵感集描述' }],
                },
                contentLevel: {
                    name: '评级',
                    type: 'radio',
                    options: {
                        placeholder: '请选择',
                        options: [
                            { label: '精选 ', dictValue: '1' },
                            { label: '无', dictValue: '3' },
                        ],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                inspirationName: '',
                recommendation: '',
                putingCityType: null,
                inspirationWorks: [],
                labelIds: [],
                isPublish: '0',
                weightSort: null,
                contentLevel: '3',
            },
            drawer: false,

            innerDrawer: false,
            nodeData: '',
        };
    },
    watch: {
        normalLabelList(val) {
            if (val) {
                const result = val.map(item => {
                    const children =
                        item.children?.length &&
                        item.children.map(every => {
                            return {
                                id: every?.id,
                                labelGroupId: every?.labelGroupId,
                                labelName: every?.labelName,
                                parentId: every?.parentId,
                                properties: every?.properties,
                            };
                        });
                    return {
                        ...item,
                        children: children,
                    };
                });
                this.columns.labelIds.options.options = result;
            }
        },
    },
    methods: {
        // 选择投放城市
        selectCityType(val) {
            console.log('--------', val);
        },
        // 选择分公司
        seletChange(val) {
            let arr = [];
            this.companyData.map(item => {
                if (val.includes(item.ouCode)) {
                    arr.push({
                        companyCode: item.ouCode,
                        companyName: item.ouName,
                    });
                }
            });
            this.result.companys = arr;
        },

        getLabelOptions() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.labelOptions = res?.data || [];
                    // this.$store.commit('mkt-topic/setLabelList', this.labelOptions); //同步方法保存
                }
            });
        },
        getCheckMsg(list) {
            // 获取到图库
            this.innerDrawer = false;
            console.log(list, '获取选中的list');
            console.log(this.result.inspirationWorks, 'inspirationWorks-------');
            let inspirationWorksNums = this.result.inspirationWorks.map(items => items.worksFileId);
            let checkList = list.filter(d => d.id && !inspirationWorksNums.includes(d.id));
            let newArr = checkList.map((item, index) => {
                return {
                    worksFileId: item.id,
                    worksId: item.designWorksId,
                    spaceName: item.spaceName,
                    smallUrl: item.smallUrl,
                    url: item.url,
                    spaceDescribe: item.fileDescribe,
                    width: item.width,
                    height: item.height,
                };
            });

            let arr = [...this.result.inspirationWorks, ...newArr];

            this.$set(this.result, 'inspirationWorks', arr);
        },
        openDrawer(id) {
            this.result = {
                inspirationName: null,
                recommendation: '',
                companys: [],
                inspirationWorks: [],
                isPublish: '1',
                labelIds: [],
                putingCityType: '1',
                weightSort: null,
                contentLevel: '3',
            };
            this.selfSelect = [];
            this.labelIds = [];
            this.companys = [];
            this.drawer = true;
            this.title = '新增灵感集';
            this.getLabelOptions();
            this.getLabelCommonList();

            console.log(this.normalLabelList, '00000');
            if (id) {
                this.title = '编辑灵感集';
                // 编辑
                // 请求详情接口
                detailID(id).then(res => {
                    console.log(res, 'res-----');
                    this.result.id = res.data.id;
                    this.result.isPublish = res.data.isPublish.toString();
                    this.result.inspirationName = res.data.inspirationName;
                    this.result.recommendation = res.data.recommendation;
                    this.result.inspirationWorks = res.data?.worksVos || [];
                    this.result.labelIds = res.data.labels;
                    this.result.weightSort = res.data.weightSort;
                    this.result.contentLevel =
                        (res.data.contentLevel && res.data.contentLevel.toString()) || '3';
                    let arr = [];
                    arr = res.data?.labels.map(item => {
                        return item.labelId;
                    });
                    if (res.data.putingCityType == 2) {
                        this.isShowCompanys = true;
                        this.companys = res.data?.companyRefs.map(item => {
                            return item.companyCode.toString();
                        });
                        this.result.companys = res.data.companyRefs;
                    }
                    let tagList = [];
                    console.log(res.data.labels, 'res.data.labels-------');
                    res.data.labels.map(item => {
                        tagList.push(item.labelId);
                    });
                    console.log(tagList, 'tagList-----');
                    let showTagList = [];
                    this.normalLabelList.map(item => {
                        if (item.labelList && item.labelList.length) {
                            item.labelList.map(child => {
                                if (tagList.includes(child.id)) {
                                    showTagList.push([item.id, child.id]);
                                }
                            });
                        }
                    });
                    // this.selfSelect = showTagList;
                    // selOldData  在这里对 selOldData 进行赋值
                    this.initSelectData(showTagList);
                    this.labelIds = arr;
                    console.log('标签反显', this.labelIds);
                    this.result.putingCityType = res.data.putingCityType.toString();
                    this.result.companys = res.data.companyRefs;
                    this.$forceUpdate();
                });
            }
        },

        removeTag(val) {
            this.nodeData = val;
        },
        handleCancle() {
            this.innerDrawer = false;
        },
        closeDrawer() {
            this.drawer = false;
        },
        handleClose(done) {
            done();
        },
        handleOpenInner() {
            this.innerDrawer = true;
            setTimeout(() => {
                this.$refs.workCollect.init(this.result.inspirationWorks);
            }, 0);
        },
        changeFn(e) {
            if (e.row.key == 'putingCityType' && e.value == '2') {
                this.isShowCompanys = true;
            } else {
                this.isShowCompanys = false;
            }
            console.log(e, 'e------');
        },
        clickFn() {},
        handleSort() {
            this.result.inspirationWorks = this.result.inspirationWorks.map((item, index) => {
                return {
                    ...item,
                    orderSort: index + 1,
                };
            });
            console.log(this.result.inspirationWorks, '000000000');
        },
        // 处理标签得数据
        changeLabel(arr) {
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        submit() {
            if (!this.result?.inspirationWorks?.length) {
                this.$message.error('灵感集图片不能为空');
                return false;
            } else {
                let imgLength = this.result.inspirationWorks.filter(d => d.url).length;
                let describeLength = this.result.inspirationWorks.filter(d => d.spaceDescribe)
                    .length;
                if (imgLength != describeLength) {
                    this.$message.error('灵感集图片描述不能为空');
                    return false;
                }
            }

            if (!this.selfSelect.length) {
                this.$message.error('标签不能为空');
                return false;
            }
            console.log(this.$refs.exhibitgroupform, '函数');
            let arr = [];
            this.selfSelect.map(item => {
                return arr.push(item[1]);
            });
            console.log(arr, 'arr------');
            console.log(this.selfSelect, '99999', this.labelsAllOptions);
            let paramsArr = [];
            this.normalLabelList.map(item => {
                if (item.labelList && item.labelList.length) {
                    item.labelList.map(child => {
                        if (arr.includes(child.id)) {
                            paramsArr.push({
                                labelId: child.id,
                                labelName: child.labelName,
                                properties: child.properties,
                                groupType: item.groupType,
                            });
                        }
                    });
                }
            });
            this.result.labelIds = paramsArr;
            this.$refs.exhibitgroupform.validateAll('CRM_TAG_GROUP', () => {
                this.result.inspirationWorks.map((item, index) => {
                    item.orderSort = index + 1;
                });
                let params = this.result;
                if (!this.result.id) {
                    save(params).then(res => {
                        console.log(res);
                        this.$emit('closeDrawer');
                    });
                } else {
                    // 编辑
                    update(params).then(res => {
                        console.log(res, 'res======');
                        this.$emit('closeDrawer');
                    });
                }
                return;
            });

            // let companys = [];
            // (this.result.companyData || []).map(item => {
            //     let obj = {};
            //     if (this.result.companys.includes(item.ouCode)) {
            //         obj.companyCode = item.ouCode;
            //         obj.companyName = item.ouName;
            //         companys.push(obj);
            //     }
            // });
            // this.result.companys = companys;
        },
        clear() {
            this.$emit('closeDrawer');
        },
        handleDelete(item) {
            console.log(item, this.result.inspirationWorks.indexOf(item), 999);
            this.result.inspirationWorks.splice(this.result.inspirationWorks.indexOf(item), 1);
            this.$forceUpdate();
        },
        initSelData(type) {
            let selNowArr = [];
            Object.entries(selOldData).forEach(item => {
                const [label, value] = item;
                const labelArr = label.split(',');
                selNowArr.push([...labelArr, value]);
            });
            this.$nextTick(() => {
                this.selfSelect = selNowArr;
                if (type == 'show') {
                    return;
                }
                const cascader = this.$refs.selfSelect;
                const trigger = cascader.$el.querySelector('.el-cascader__tags');
                trigger.click();
            });
        },
        changeSelect(val) {
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                const fatherValue = selOldData[fatherStr];
                if (fatherValue !== child) {
                    selOldData[fatherStr] = child;
                    break;
                }
            }
            this.initSelData('change');
        },
        removeSelect(val) {
            console.log(val, 'removeSelect');
            const fatherStr = val.slice(0, val.length - 1).join(',');
            delete selOldData[fatherStr];
            this.initSelData('change');
        },
        initSelectData(val) {
            selOldData = {};
            this.selfSelect = [];
            for (let i = 0; i < val.length; i++) {
                const item = val[i];
                const fatherStr = item.slice(0, item.length - 1).join(',');
                const child = item[item.length - 1];
                selOldData[fatherStr] = child;
            }
            // 数据反显
            this.initSelData('show');
        },
    },
    created() {
        this.getOperationList();
        this.getCompany();
        this.getColumn();
        this.getLabelCommonList();
    },
};
</script>
<style lang="less" scoped>
/deep/ .self-drawer {
    padding: 0 20px;
}
.flex {
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #c1c1c1;
    text-align: center;
}
/deep/ .el-descriptions__table > tbody {
    display: flex;
}

/deep/.is-required .el-form-item__label:after {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
/deep/.is-required .el-form-item__label:before {
    display: none;
}
.create {
    background: #fff;
}
/deep/ .el-input {
    width: 240px;
}
.footer {
    text-align: right;
    margin-bottom: 30px;
    margin-right: 50px;
}
.must {
    color: red;
    vertical-align: -2px;
}
/deep/ .el-descriptions-item__label {
    width: 150px;
}
/deep/ .el-textarea {
    .el-textarea__inner {
        width: 20vw;
    }
}
/deep/.el-input__inner {
    padding-right: 50px;
}
</style>
