<template>
    <el-drawer
        v-if="exhibitDiag"
        :visible.sync="exhibitDiag"
        size="70%"
        :wrapperClosable="false"
        :before-close="handleClose"
    >
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :columnsNum="2"
            :clientFalg="false"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            ref="form1"
            :moreDown="true"
            @search="searchFn"
            @change="changeFn"
        ></sc-search-v2>
        <sc-tables
            :head="tableHead"
            :data="tableData"
            :page="page"
            :page-hide="true"
            :upload-config="table.uploadConfig"
            :pop-show="false"
            :show-add="false"
            :show-add-config="tableAddConfig"
            :table-height="500"
            :read-table="true"
            :load="false"
            @selection-change="selectChange"
        >
            <template v-slot:special-column>
                <el-table-column
                    type="selection"
                    width="55"
                    :selectable="checkboxInit"
                ></el-table-column>
            </template>

            <template v-slot:inspirationUrl>
                <el-table-column prop="inspirationUrl" label="图片">
                    <template slot-scope="scope">
                        <img
                            v-if="scope.row.inspirationUrl"
                            style="width: 45px; height: 45px"
                            :src="scope.row.inspirationUrl"
                        />
                    </template>
                </el-table-column>
            </template>
            <template v-slot:coverImage>
                <el-table-column prop="coverImage" label="图片">
                    <template slot-scope="scope">
                        <img
                            v-if="scope.row.coverImage"
                            style="width: 45px; height: 45px"
                            :src="scope.row.coverImage"
                        />
                    </template>
                </el-table-column>
            </template>
        </sc-tables>
        <div class="bottom-btn">
            <el-button type="primary" size="mini" @click="submit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getPoolist, saveDec, getArticle, saveartDec } from '@/api/mkt/market.js';
import { getLabellist } from '@/api/mkt/topic.js';
import { getCompany } from '@/api/mkt/article-group.js';
import mixins from '@/mixins';

export default {
    mixins: [mixins],
    props: {
        exhibitDiag: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '',
        },
        listTypeValue: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            columns: {
                search: {
                    name: '搜索名称',
                    type: 'input',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '搜索名称',
                    },
                    spanCol: 1,
                },
                labelId: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            multiple: true,
                            emitPath: false,
                        },
                    },
                    spanCol: 1,
                },
                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'ouCode',
                            label: 'ouName',
                        },
                    },
                    spanCol: 1,
                },
                time: {
                    name: '创建时间',
                    type: 'date',
                    options: {
                        defaultValueBase: 'aaa',
                        placeholder: '创建时间',
                        dateType: 'daterange',
                    },
                    spanCol: 2,
                },
            },
            tableHead: [],
            result: {
                search: '',
                labelId: [],
                // operationId: '',
                companyCode: '',
                time: '',
                labelIdArr: [],
            },
            LS: { 'min-width': '80px' }, //设置label宽度
            CS: { width: '80px', 'padding-right': '0px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空筛选', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            table: {
                tableHead: [
                    { name: '名称', value: 'contentName' },
                    { name: '图片', value: 'inspirationUrl' },
                    { name: '分类', value: 'categoryName' },
                    { name: '标签', value: 'labelName' },
                    { name: '内容数量', value: 'quantity' },
                    { name: '运营标签', value: 'operationName' },
                    // { name: '是否发布', value: 'isPublish', formatter: this.isPublish },
                    { name: '创建人', value: 'createName' },
                    { name: '创建时间', value: 'createTime' },
                ],
                tableartHead: [
                    { name: '名称', value: 'contentName' },
                    { name: '封面', value: 'coverImage' },
                    { name: '分类', value: 'categoryName' },
                    { name: '标签', value: 'labelName' },
                    // { name: '是否发布', value: 'isPublish', formatter: this.isPublish },
                    { name: '创建人', value: 'createName' },
                    { name: '创建时间', value: 'createTime' },
                ],
                uploadConfig: {
                    //上传组件相关配置项
                    oss: {
                        url: '', //oss访问域名
                        accessKeyId: '', //接口返回的keyId
                        accessKeySecret: '', //接口返回的keySecret
                        stsToken: '', //接口返回的token
                        bucket: 'sczs',
                        region: 'oss-cn-beijing',
                    },
                    ENV: 'test', //环境变量（保存文件到对应文件夹下）【dev、test、pre、master】
                    fileModule: 'market', //业务模块名称（保存文件到对应文件夹下）
                },
            },
            tableData: [],
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            page: {
                count: 0, //总条数
                index: 1, //当前页
                sizes: [], //每页显示个数选择器的选项设置，默认[30, 50, 100, 200]
                show: true, //是否显示，默认false
            },
            selectRowlist: [],
            labelOptions: [],
            companyData: [],
        };
    },
    watch: {
        searchLabelOptions(val) {
            if (val) {
                const result = val.map(item => {
                    const children =
                        item.children?.length &&
                        item.children.map(every => {
                            return {
                                id: every?.id,
                                labelGroupId: every?.labelGroupId,
                                labelName: every?.labelName,
                                parentId: every?.parentId,
                                properties: every?.properties,
                            };
                        });
                    return {
                        ...item,
                        children: children,
                    };
                });
                this.columns.labelId.options.options = result;
            }
        },
    },
    mounted() {
        this.getLabelOptions();
        this.getCompany();
        if (this.listTypeValue) {
            this.tableHead = this.table.tableartHead;
            this.getArticle();
        } else {
            this.tableHead = this.table.tableHead;
            this.getPoolist();
        }
    },
    methods: {
        isPublish(row) {
            return row.isPublish == 1 ? '是' : '否';
        },
        //判断复选框是否可选
        checkboxInit(row) {
            if (row.isCheck) {
                return 0; //不可勾选
            } else {
                return 1;
            }
        },
        getCompany() {
            getCompany().then(res => {
                if (res?.isSuccess) {
                    for (let i in this.columns) {
                        if (i === 'companyCode') {
                            this.columns[i].options.options = res.data || [];
                        }
                    }
                }
            });
        },
        async getPoolist() {
            const params = {
                ...this.result,
            };
            if (params?.time?.length) {
                params.startTime = this.result.time[0] + ' 00:00:00';
                params.endTime = this.result.time[1] + ' 23:59:59';
                delete params.time;
            }
            if (!this.result?.companyCode) {
                delete params.companyCode;
            }
            params.isPublish = 1;
            params.page = 1;
            params.size = 10000;
            params.labelId = this.result.labelIdArr || null;
            const res = await getPoolist(params);
            if (res?.isSuccess) {
                this.tableData = res.data.children || [];
            }
        },
        async getArticle() {
            const params = {};
            if (this.result?.time?.length) {
                params.createStartTime = this.result.time[0] + ' 00:00:00';
                params.createEndTime = this.result.time[1] + ' 23:59:59';
            }
            params.companyCode = this.result.companyCode || null;
            params.keywords = this.result.search || null;
            params.labelId = this.result.labelIdArr || null;
            params.approvalStatus = 3;
            params.page = 1;
            params.size = 10000;
            const res = await getArticle(params);
            if (res?.isSuccess) {
                this.tableData = res.data.children || [];
            }
        },
        selectChange(rows) {
            this.selectRowlist = rows;
        },
        searchFn() {
            if (this.listTypeValue) {
                this.getArticle();
            } else {
                this.getPoolist();
            }
        },
        handleClose() {
            this.$emit('closeDiag');
        },
        changeFn(e) {
            if (e.row.key == 'labelId') {
                this.result.labelIdArr = this.disposeData(e.nodeData);
                console.log(this.result.labelIdArr, 'this.result.labelIdArr----');
            }
        },
        disposeData(arr) {
            return arr.map(item => {
                return item.data.id;
            });
        },
        clickFn() {},
        submit() {
            let list = [];
            this.selectRowlist.map(item => list.push(item.poolId));
            if (this.listTypeValue) {
                this.saveartDec({ id: list, moduleId: this.id });
            } else {
                this.saveDec({ id: list, moduleId: this.id });
            }
        },
        async saveDec(params) {
            const res = await saveDec(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.$emit('closeDiag', 'success');
            }
        },
        async saveartDec(params) {
            const res = await saveartDec(params);
            if (res?.isSuccess) {
                this.$message.success('操作成功');
                this.$emit('closeDiag', 'success');
            }
        },
        // 获取标签列表
        getLabelOptions() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            // item.disabled = true;
                        }
                    }
                    for (let i in this.columns) {
                        if (i === 'labelId') {
                            const result = res.data.map(item => {
                                const children =
                                    item.children?.length &&
                                    item.children.map(every => {
                                        return {
                                            id: every?.id,
                                            labelGroupId: every?.labelGroupId,
                                            labelName: every?.labelName,
                                            parentId: every?.parentId,
                                            properties: every?.properties,
                                        };
                                    });
                                return {
                                    ...item,
                                    children: children,
                                };
                            });
                            this.columns[i].options.options = result;
                        }
                    }
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
/deep/ .el-table__body .cell:empty::before {
    content: '--';
}
</style>
