<template>
    <div>
        <el-drawer
            title="编辑案例切片"
            :visible.sync="drawer"
            direction="rtl"
            size="70%"
            :destroy-on-close="true"
            :wrapperClosable="false"
            :close-on-press-escape="false"
            :before-close="closeDrawer"
        >
            <div class="bottom-box" v-loading="aiLoading" element-loading-text="加载中">
                <sc-form-v2
                    tablename="BESIC"
                    name=""
                    :columnsNum="1"
                    :result="result"
                    :columns="columns"
                    direction="vertical"
                    :esayStyle="true"
                    :LS="LS"
                    :columnsPaddingRight="20"
                    ref="articleFrom"
                    @change="handleChange"
                >
                    <template v-slot:url>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="flex">
                                    <div class="labelName">图片与标签</div>
                                    <el-button type="primary" size="small" @click="addAITag">
                                        AI标签添加至
                                    </el-button>
                                </div>
                            </template>
                            <div class="row" v-for="(item, findex) in result.files" :key="item.id">
                                <span class="row-left">
                                    <el-image
                                        :src="item.url"
                                        fit="cover"
                                        class="row-left-image"
                                        :preview-src-list="[item.url]"
                                    ></el-image>
                                    <div
                                        class="ai-btn"
                                        @click="handleAI(item)"
                                        v-if="!result.spaceName.includes('户型图')"
                                    >
                                        AI
                                    </div>
                                </span>
                                <div class="row-content">
                                    <draggable
                                        v-model="item.fileLabels"
                                        handle=".mover"
                                        animation="300"
                                        :disabled="false"
                                        @start="onStart"
                                        @end="onEnd.bind(this, item)"
                                        :key="findex"
                                    >
                                        <transition-group>
                                            <div
                                                class="drag-box mover"
                                                :class="
                                                    tag.properties == 2 && tag.groupType == 1
                                                        ? 'cus-tag'
                                                        : ''
                                                "
                                                v-for="tag in item.fileLabels"
                                                :key="tag.index"
                                            >
                                                <el-input
                                                    v-show="tag.tagInputVisible"
                                                    :key="tag.index + '1' + item.orderSort"
                                                    class="input-new-tag "
                                                    v-model="tag.labelName"
                                                    :ref="
                                                        `saveTagInput${tag.index}${item.orderSort}`
                                                    "
                                                    size="small"
                                                    @blur="handleTagConfirm(tag, item)"
                                                ></el-input>
                                                <el-tag
                                                    v-show="!tag.tagInputVisible"
                                                    :key="tag.index + '2' + item.orderSort"
                                                    :type="tag.isHighlight == 1 ? '' : 'info'"
                                                    :effect="
                                                        tag.isHighlight == 1 ? 'light' : 'plain'
                                                    "
                                                    closable
                                                    @click="editTag(tag, item)"
                                                    @close="deleteTag(tag, item)"
                                                >
                                                    {{ tag.labelName }}
                                                </el-tag>
                                            </div>
                                        </transition-group>
                                    </draggable>
                                    <div class="fileDescribe">
                                        <el-input
                                            :key="findex"
                                            v-model="item.fileDescribe"
                                            size="small"
                                            maxlength="30"
                                            show-word-limit
                                            placeholder="请输入AI描述"
                                        ></el-input>
                                    </div>
                                    <div class="btn-box">
                                        <el-button
                                            class="button-tag"
                                            @click="addTag(item)"
                                            size="small"
                                            type="primary"
                                            round
                                        >
                                            新增
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <template v-slot:weightSort>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">权重值</div>
                            </template>
                            <div class="row">
                                <el-input-number
                                    v-model="result.weightSort"
                                    :min="0"
                                    :max="9999"
                                ></el-input-number>
                            </div>
                        </el-descriptions-item>
                    </template>
                </sc-form-v2>
            </div>
            <div class="cover-box">
                <h2 style="margin:0 0 6px">设置封面</h2>
                <el-image
                    :src="result.cover"
                    fit="contain"
                    style="width:100px;height:100px"
                    v-if="result.cover"
                    @click="openClip('upload')"
                ></el-image>
                <i v-else class="el-icon-plus upload-icon" @click="openClip"></i>
            </div>
            <div class="footer" style="text-align: right;margin:0px 30px 20px 0px;">
                <el-button size="mini" @click="drawer = false" v-preventReClick="5000">
                    取消
                </el-button>
                <el-button size="mini" type="primary" @click="handleSubmit" v-preventReClick="5000">
                    确定
                </el-button>
            </div>
        </el-drawer>
        <set-cover
            ref="set-cover"
            :newClip="false"
            @coverImgUrl="getCoverImgUrl"
            :showImgList="showImgList"
        ></set-cover>
        <add-tag ref="addTag" @closeDrawer="closeTagDialog"></add-tag>
        <add-AI-tag ref="addAITag" @closeDrawer="closeAITagDialog"></add-AI-tag>
    </div>
</template>
<script>
import setCover from '../exhibit/components/set-cover';
import { getDetail, saveWorksGroup, resetLabel } from '@/api/mkt/status';
import mixins from '@/mixins';
import draggable from 'vuedraggable';
import addTag from './add-tag.vue';
import addAITag from './add-AI-tag.vue';
//切片  新增的标签性质properties 自定义    1系统，2自定义 3运营 6AI标签
// 组类型groupType 1静态标签 2模型标签 3预测标签 4运营标签 6AI标签组
// AI标签修改之后记得properties变成自定义标签、groupType等于AI标签组 等产品确认 修改后自定义无法编辑
export default {
    name: 'add',
    mixins: [mixins],
    components: { setCover, draggable, addTag, addAITag },
    data() {
        return {
            levelList: [],
            drawer: false,
            LS: {},
            columns: {
                url: {
                    name: '图片',
                    type: 'img',
                    options: {},
                },
                picDescribe: {
                    name: '图片组描述',
                    type: 'text',
                    options: {},
                },
                spaceName: {
                    name: '所属空间',
                    type: 'text',
                    options: {},
                },
                title: {
                    name: '所属案例',
                    type: 'text',
                    options: {},
                },
                contentLevel: {
                    name: '评级',
                    type: 'radio',
                    options: {
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },

                weightSort: {
                    name: '权重值',
                    type: 'number',
                    options: {
                        placeholder: '请输入',
                        max: 9999,
                        min: 0,
                    },
                    spanCol: 1,
                },
                // labelId: {
                //     name: '标签',
                //     type: 'hidden',
                //     options: {
                //         placeholder: '请选择',
                //         allLevels: false,
                //         clearable: true,
                //         collapseTags: true,
                //         options: [],
                //         props: {
                //             label: 'labelName',
                //             value: 'id',
                //             checkStrictly: false,
                //             emitPath: false,
                //             multiple: true,
                //         },
                //     },
                //     spanCol: 1,
                // },
            },

            result: {
                cover: '',
                picDescribe: '',
                url: '',
                spaceName: '',
                contentLevel: '',
                urls: [],
                files: [
                    // {
                    //     id: '1717817986167586819',
                    //     url:
                    //         'https://sczs.voglassdc.com/app-scj/test/market/20231027/169839465572192.png',
                    //     orderSort: 1,
                    //     fileLabels: [
                    //         {
                    //             groupType: 0,
                    //             isHighlight: 0,
                    //             labelId: '',
                    //             labelName: '欧式风格',
                    //             properties: 0,
                    //             index: 0,
                    //             id: 'aa',
                    //         },
                    //         {
                    //             groupType: 0,
                    //             isHighlight: 0,
                    //             labelId: 'edf',
                    //             labelName: '美式风格dasdfasdf',
                    //             properties: 0,
                    //             index: 1,
                    //             id: 'bb',
                    //         },
                    //         {
                    //             groupType: 0,
                    //             isHighlight: 0,
                    //             labelId: 'ddd',
                    //             labelName: '中式风格fsdfsdfafafasdfasdf',
                    //             properties: 0,
                    //             index: 2,
                    //             id: 'cc',
                    //         },
                    //     ],
                    // },
                    // {
                    //     id: '1717817986163392513',
                    //     url:
                    //         'https://sczs.voglassdc.com/app-scj/test/market/20231027/16983946557227.png',
                    //     orderSort: 2,
                    //     fileLabels: [],
                    // },
                    // {
                    //     id: '1717817986167586818',
                    //     url:
                    //         'https://sczs.voglassdc.com/app-scj/test/market/20231027/16983946557230.png',
                    //     orderSort: 3,
                    //     fileLabels: [],
                    // },
                ],
            },
            local: { labels: [] },
            loading: false,
            showImgList: [], // 头像图片list
            hasEdit: false, // 模式为编辑还是新增
            tagInputVisibleObj: {},
            aiLoading: false, //ai按钮请求接口loading
        };
    },
    watch: {
        levelList(val) {
            if (val) {
                this.columns.contentLevel.options.options = val;
            }
        },
    },
    created() {
        this.getLevelList();
    },
    methods: {
        addAITag() {
            console.log(this.result.files);
            let arr = this.result.files.map(item => item.id);
            this.$refs.addAITag.open(arr);
        },
        open(row) {
            // this.getSearchTagList();
            this.drawer = true;
            getDetail({ id: row.id, designWorksId: row.designWorksId }).then(res => {
                res.data.files.map(file => {
                    file.fileLabels &&
                        file.fileLabels.length &&
                        file.fileLabels.map((d, index) => {
                            d.index = index;
                            d.tagInputVisible = false;
                        });
                    file.fileDescribe = file.fileDescribe || ''; //未完  取接口
                });
                row.contentLevel = row.contentLevel + '';
                this.result = { files: res.data.files, ...row };
                this.originResult = JSON.parse(JSON.stringify(this.result));
                console.log('------------', this.result, this.tagInputVisibleObj);
                this.result.cover = row.cover || row.url;
            });
        },
        // 关闭选择标签弹窗
        closeDrawer() {
            this.drawer = false;
            this.result = {
                cover: '',
                picDescribe: '',
                url: '',
                spaceName: '',
                contentLevel: '',
                urls: [],
                files: [],
                id: '',
            };
            this.originResult = [];
        },
        handleSubmit() {
            let fileParams = [];
            const params = {
                cover: this.result.cover,
                contentLevel: this.result.contentLevel,
                designWorksId: this.result.designWorksId,
                id: this.result.id,
                coverHeight: this.result.coverHeight,
                coverWidth: this.result.coverWidth,
                weightSort: this.result.weightSort,
            };
            this.result.files.map(file => {
                file.fileLabels &&
                    file.fileLabels.length &&
                    file.fileLabels.map((d, index) => {
                        d.orderSort = index;
                        d.fileId = file.id;
                    });
                fileParams.push({
                    fileId: file.id,
                    fileDescribe: file.fileDescribe || '',
                });
                params.fileParams = fileParams;
            });
            if (
                this.result.files.findIndex(file => file.fileLabels && file.fileLabels.length > 0) >
                -1
            ) {
                let fileLabelParams = [];
                this.result.files.map(file => {
                    if (file.fileLabels && file.fileLabels.length) {
                        file.fileLabels.map(label => {
                            fileLabelParams.push(label);
                        });
                    }
                });
                params.fileLabelParams = fileLabelParams;
            }
            saveWorksGroup(params).then(res => {
                const { isSuccess, message } = res;
                if (isSuccess) {
                    this.closeDrawer();
                    this.$message.success(message);
                    this.$emit('refresh');
                }
            });
        },
        // 拖拽
        // 开始拖拽事件
        onStart() {},
        // 拖拽结束事件
        onEnd(e) {},
        // 设置标签相关
        editTag(tag, row) {
            // 处理双击事件
            // 如果点击的id不相同，则重置点击次数
            if (row.id + '' + tag.index != this.treeClickId) {
                this.tagClickNum = 0;
            }
            this.treeClickId = row.id + '' + tag.index;
            this.tagClickNum++;

            window.clearTimeout(this.treeClickTimeout);
            this.treeClickTimeout = window.setTimeout(() => {
                this.tagClickNum = 0;
            }, 300);
            // 连续点击多次则不做任何事情
            if (this.tagClickNum > 2) return;
            // 点击两次时双击事件
            if (this.tagClickNum == 2) {
                this.dblClickTag(tag, row);
                return;
            }
            // 点击一次时单击事件
            if (this.tagClickNum == 1) {
                this.clickTag(tag, row);
            }
        },
        // 标签修改后
        handleTagConfirm(tag, row) {
            console.log('-----------handleTagConfirm');
            tag.tagInputVisible = false;
            let isUpdate = false;
            for (let file of this.originResult.files) {
                if (file.id == row.id) {
                    for (let label of file.fileLabels) {
                        if (label.index == tag.index && label.labelName != tag.labelName) {
                            console.log('-------------------', label, tag);
                            isUpdate = true;
                            break;
                        }
                    }
                }
            }
            console.log('--------------------isUpdate', isUpdate);
            if (!isUpdate) {
                return;
            }
            // AI标签修改之后记得properties变成自定义标签 等产品确认 修改后自定义无法编辑
            tag.properties = 2;
            let index = this.result.files.findIndex(d => d.id == row.id);
            this.result.files[index].fileLabels.splice(tag.index, 1, tag);
        },
        // 双击标签 编辑
        dblClickTag(tag, row) {
            clearTimeout(this.timer);
            console.log('-------------editTag tag', tag);
            if (
                tag.properties === 1 ||
                (tag.properties === 2 && tag.groupType == 1) ||
                tag.isHighlight === 1
            ) {
                return;
            }
            tag.tagInputVisible = true;
            let index = this.result.files.findIndex(d => d.id == row.id);
            this.result.files[index].fileLabels.splice(tag.index, 1, tag);
            this.$nextTick(() => {
                this.$refs[`saveTagInput${tag.index}${row.orderSort}`][0].focus();
            });
        },
        // 单次选中 标签高亮
        clickTag(tag, row) {
            this.timer = setTimeout(() => {
                this.timer = null;

                this.timer = null;
                console.log('-----------clickTag');
                tag.isHighlight = tag.isHighlight ? 0 : 1;
                let index = this.result.files.findIndex(d => d.id == row.id);
                let tagIndex = this.result.files[index].fileLabels.findIndex(
                    t => t.labelName == tag.labelName
                );
                this.result.files[index].fileLabels.splice(tagIndex, 1, tag);
                this.$nextTick(() => {});
            }, 300);
        },
        // 删除标签
        deleteTag(tag, row) {
            let index = this.result.files.findIndex(d => d.id == row.id);
            console.log('-----deleteTag', index, row.id, tag.index);
            // let arrLength = this.result.files[index].fileLabels.length;
            let tagIndex = this.result.files[index].fileLabels.findIndex(
                d => d.labelName == tag.labelName
            );
            this.result.files[index].fileLabels.splice(tagIndex, 1);
            this.result.files[index].fileLabels.map((d, index) => {
                d.index = index;
            });
        },
        // 选择添加标签
        addTag(row) {
            this.$refs.addTag.openDrawer({ id: row.id });
        },
        // 关闭选择标签弹窗
        closeTagDialog(data) {
            if (data.list && data.list.length) {
                let index = this.result.files.findIndex(d => d.id == data.id);
                let fileLabels = this.result.files[index].fileLabels || [];
                if (
                    fileLabels.filter(
                        d => data.list.findIndex(aItem => aItem.labelName == d.labelName) > -1
                    ).length
                ) {
                    this.$message.warning('标签已存在,请重新选择');
                    return;
                }
                data.list.map((d, index) => {
                    d.index = fileLabels.length + index;
                    d.tagInputVisible = false;
                    // 加参数
                });
                console.log('-----closeTagDialog', index, data.id);
                let arrLength = fileLabels.length;
                if (!fileLabels.length) {
                    this.result.files[index].fileLabels = [];
                }
                this.result.files[index].fileLabels.splice(arrLength, 0, ...data.list);
                console.log('-----------------------', this.result.files);
            }
        },
        // 关闭AI弹窗
        closeAITagDialog() {
            // this.closeDrawer();
            // this.$emit('refresh');
        },
        // AI重置标签
        async handleAI(row) {
            console.log(row);
            this.aiLoading = true;
            let res = await resetLabel({ fileId: row.id });
            this.aiLoading = false;

            if (res) {
                res.data.map((d, index) => {
                    d.index = index;
                    d.tagInputVisible = false;
                });
                let index = this.result.files.findIndex(d => d.id == row.id);
                console.log(res.data, this.result.files[index].fileLabels, 'res');

                this.result.files[index].fileLabels = res.data;
                // this.result.files[index].fileDescribe = '';
            }
        },

        getCoverImgUrl(obj) {
            let { url, coverWidth, coverHeight } = obj;
            this.result.coverHeight = coverHeight;
            this.result.coverWidth = coverWidth;
            this.$set(this.result, 'cover', url);
        },

        saveDraftBox() {
            if (!this.result.cover) {
                this.$message.info('封面必传');
                return false;
            }
            return true;
        },

        handleChange(row) {},
        getTableData() {
            return this.$refs.addTable.getTableData();
        },
        openClip(type) {
            this.showImgList = [];
            this.result.files.map(item => {
                this.showImgList.push(item.url);
            });
            this.$refs['set-cover'].open(this.showImgList, this.result.cover, 'close', true);
        },
    },
};
</script>
<style lang="less" scoped>
.input-box {
    padding: 0 30px;
    margin: 20px 0 0;
}
.cover-box {
    margin: 24px 0 40px 30px;
}
.bottom-box {
    padding-left: 20px;
    padding-right: 20px;
}
/deep/ .el-radio {
    margin-bottom: 10px;
    width: 80px;
}
.upload-icon {
    font-size: 26px;
    border: 1px solid #d7dbe0;
    border-radius: 3px;
    padding: 36px;
    color: #8c939d;
    cursor: pointer;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 70px;
    box-sizing: border-box;
}
.row {
    display: flex;
    justify-content: flex-start;
    .row-left {
        position: relative;
        .row-left-image {
            width: 100px;
            height: 160px;
            margin: 0 30px 20px 0;
            background: #000;
        }
        .ai-btn {
            position: absolute;
            right: 36px;
            top: 6px;
            background: #000000b8;
            border-radius: 16px;
            font-size: 16px;
            color: #fff;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
        }
    }
    .drag-box {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;

        .el-tag__close:hover {
            color: #fff;
            background-color: #909399;
        }
        .input-new-tag {
            width: 90rpx;
        }
        &:first-of-type {
            margin-left: 0;
        }
    }
    .row-content {
        position: relative;
        width: 100%;
        .fileDescribe {
            width: 70%;
            position: absolute;
            bottom: 35px;
        }
    }
    .btn-box {
        position: absolute;
        bottom: 35px;
        right: 60px;
        .button-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
/deep/ .el-tag {
    border-radius: 1.5em;
}

/deep/ .cus-tag .el-tag--plain.el-tag--info {
    color: #e6a23c !important;
}

/deep/ .row .drag-box .el-input--small .el-input__inner {
    border-radius: 1.5em;
}
</style>
