<template>
    <div class="add-tag-container">
        <el-dialog :visible.sync="addialogVisible" size="30%" title="编辑" :wrapperClosable="false">
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            ></sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="addialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { userJob } from '@/api/mkt/staff';
export default {
    props: {},
    data() {
        return {
            addialogVisible: false,
            dialogVisible: false,
            columns: {
                publicData: {
                    name: '潜客数据',
                    type: 'radio',
                    options: {
                        options: [
                            { value: 1, label: '仅自己' },
                            { value: 2, label: '部门' },
                            { value: 3, label: '分公司' },
                            { value: 4, label: '全部' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请输入组名称' }],
                },
                job: {
                    name: '岗位',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        maxlength: 20,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入岗位' }],
                },
                role: {
                    name: '角色',
                    type: 'cascader',
                    hidden: false,
                    options: {
                        placeholder: '',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            value: 'id',
                            label: 'label',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    rules: [{ required: true, message: '请选择角色' }],
                },
                isDisplay: {
                    name: '前台是否展示',
                    type: 'radio',
                    options: {
                        options: [
                            {
                                value: '0',
                                label: '展示',
                            },
                            {
                                value: '1',
                                label: '不展示',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    hidden: false,
                    rules: [{ required: true, message: '请选择前台是否展示' }],
                },
            },
            result: {
                publicData: 1,
                job: '',
                isDisplay: 1,
            },
        };
    },
    mounted() {},
    methods: {
        open(roleList, row) {
            console.log(row, 'show-----row--------');
            this.addialogVisible = true;
            this.result = {
                publicData: row.publicData || 1,
                job: row.job,
                userId: row.id,
                backId: row.backId,
                isDisplay: row.isDisplay || '0',
                role: row.roleId,
                account: row.account,
                isInfo: row.isInfo, //0 展示 1 不展示
            };
            this.$forceUpdate();
            console.log(this.result);
            this.columns.role.options.options = roleList;
            if (row.isInfo === 1) {
                // delete this.columns.job;
                // this.columns = { publicData: this.columns.publicData };
                this.columns.isDisplay.type = 'hidden';
                this.columns.job.type = 'hidden';
                this.columns.role.type = 'hidden';
            } else {
                this.columns.isDisplay.type = 'radio';
                this.columns.job.type = 'input';
                this.columns.role.type = 'cascader';
            }
        },
        close() {
            this.addialogVisible = false;
        },
        // handleClose() {
        //     this.$emit('handleClose');
        // },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, 88888);
                let params = {
                    ...this.result,
                };
                userJob(params).then(res => {
                    console.log(res, 'res-----');
                    this.close();
                    this.$emit('handleClose');
                });
            });
        },
        handleReset() {
            this.result = {
                publicData: '',
                job: 0,
                type: 0,
                isDisplay: '',
            };
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            // if (data.row.key == 'job') {
            //     if (parseInt(data.value) < 0) {
            //         this.result.job = '0';
            //         console.log('----------------', this.result.job);
            //     }
            // }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
