<template>
    <div class="info-container">
        <div class="content-box">
            <div v-for="(item, index) in list" :key="index">
                <p class="content-match " v-if="item.questionsName || item.optionName">
                    <span class="left-title-match">{{ item.questionsName }}：</span>
                    <span>{{ item.optionName || '' }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { getDemandList } from '@/api/mkt/user';
export default {
    name: 'msg-collect',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            list: [],
        };
    },
    methods: {
        async init() {
            let res = await getDemandList({ userId: this.id });
            if (res.isSuccess) {
                this.list = res.data;
            }
        },
    },
    created() {
        this.init();
    },
};
</script>
<style lang="less" scoped>
.content-box {
    // margin-top: 40px;
    margin-left: 20px;
    .title {
        color: rgba(16, 16, 16, 100);
        font-size: 16px;
        font-family: PingFangSC-regular;
    }
    .content {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #bbbbbb;
        width: 387px;
        .left-title {
            width: 150px;
            display: inline-block;
        }
        .span-box {
            padding: 5px 10px;
            background: #000;
            color: #fff;
            border-radius: 4px;
        }
    }
    .content-match {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #bbbbbb;
        width: 600px;
        .left-title-match {
            width: 200px;
            display: inline-block;
        }
    }
}
</style>
