<template>
    <el-drawer
        :visible.sync="addiagvisible"
        :wrapperClosable="false"
        size="30%"
        :before-close="handleClose"
    >
        <sc-form-v2
            name="基本信息"
            tablename="CRM_CATEGORY"
            :columnsNum="1"
            :columns="columns"
            :uploadConfig="uploadConfig"
            :result="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
            @click="clickFn"
            ref="categoryform"
        >
            <template v-slot:orderSort>
                <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            排序
                            <span style="color: red">*</span>
                        </div>
                        <el-input
                            v-model.number="result.orderSort"
                            style="width: 100px"
                            type="number"
                            size="mini"
                            @input="handleSort"
                        ></el-input>
                    </template>
                    <div style="text-align: left"></div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>
        <div class="bottom-btn">
            <!-- <el-button size="mini">取消</el-button> -->
            <el-button type="primary" size="mini" @click="submit">保存</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getOssConfig } from '@/api/login.js';
import {
    addCategory,
    getCategoryinfo,
    editCategory,
    getCategorycolumn,
} from '@/api/mkt/category.js';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-category');
import { uploadConfigData } from '@/config.js';

export default {
    props: {
        addiagvisible: {
            type: Boolean,
            default: false,
        },
        currentId: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState(['list']), //// 映射 this.list 为 store.state.list
    },
    data() {
        return {
            columns: [
                {
                    key: 'cateName',
                    name: '分类项名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                        maxlength: 30,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入分类项名称' }],
                },
                {
                    key: 'cateParentId',
                    name: '所属类目',
                    type: 'cascader',
                    options: {
                        defaultValue: '0',
                        placeholder: '占位',
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                            checkStrictly: true,
                        },
                        disabled: false,
                    },
                },
                // {
                //     key: 'shelfStatus',
                //     name: '上架状态（用于控制前台是否展示该分类）',
                //     type: 'radio',
                //     options: {
                //         defaultValue: '',
                //         placeholder: '',
                //         options: [
                //             {
                //                 value: '0',
                //                 label: '上架',
                //             },
                //             {
                //                 value: '1',
                //                 label: '下架',
                //             },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                // },
                {
                    key: 'isShow',
                    name: '启用状态（用于控制后台是否可选用该分类）',
                    type: 'radio',
                    options: {
                        defaultValue: '',
                        placeholder: '',
                        options: [
                            {
                                value: 0,
                                label: '启用',
                            },
                            {
                                value: 1,
                                label: '禁用',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                },
                {
                    key: 'catePicurl',
                    name: '分类图示',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '配置',
                        disabled: this.read,
                        tip: '建议图片最小宽度800-3000px，图片不超过10M',
                        size: 1024000,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    spanCol: 3,
                },
                {
                    key: 'orderSort',
                    name: '排序值',
                    type: 'number',
                    options: {
                        placeholder: '',
                    },
                },
            ],
            result: {
                cateName: '', // 名称
                cateParentId: '', // 所属类目
                catePicurl: [], // 分类图示
                // columnsIds: [], // 适用栏目
                shelfStatus: '0', // 上架状态
                orderSort: 0, // 排序值
                isShow: 0, // 展示
                columnCheck: [],
            },
            uploadConfig: {},
            columnData: [],
            columnsList: [],
        };
    },
    mounted() {
        this.getOssFn();
        this.columns.map(item => {
            if (item.key === 'cateParentId') {
                item.options.options = this.list;
                if (this.currentId) {
                    item.options.disabled = true;
                } else {
                    item.options.disabled = false;
                }
            }
        });
        if (this.currentId) {
            this.getCategoryinfo();
        }
    },
    methods: {
        getOssFn() {
            getOssConfig().then(res => {
                if (res?.isSuccess) {
                    uploadConfigData.oss.accessKeyId = res.data.stsKeyId;
                    uploadConfigData.oss.accessKeySecret = res.data.stsSecret;
                    uploadConfigData.oss.stsToken = res.data.token;
                    this.uploadConfig = uploadConfigData;
                }
            });
        },
        handleClose() {
            this.$emit('closeDiag');
        },
        async getCategorycolumn() {
            const res = await getCategorycolumn();
            if (res?.isSuccess) {
                this.columnData = res.data;
            }
        },
        changeFn() {
            if (this.result.cateParentId?.length > 2) {
                this.$message.warning('当前分类最多支持三级！');
                this.result.cateParentId = this.result.cateParentId.slice(0, 2);
            }
        },
        clickFn() {},
        submit() {
            this.$refs.categoryform.validateAll('CRM_CATEGORY', () => {
                //校验回调
                const params = {
                    ...this.result,
                };
                if (this.result?.catePicurl?.length) {
                    params.catePic = this.result.catePicurl[0].url;
                    delete params.catePicurl;
                } else {
                    params.catePic = '';
                }
                if (this.currentId) {
                    this.editCategory(params);
                } else {
                    this.addCategory(params);
                }
            });
        },
        handleSort(val) {
            var regPos = /^[0-9]*[1-9][0-9]*$/; // 非负整数
            if (!regPos.test(val)) {
                this.result.orderSort = 0;
                return;
            }
            if (val > 9999) {
                this.result.orderSort = 9999;
            }
        },
        async addCategory(params) {
            if (this.result?.cateParentId?.length) {
                params.cateParentId = this.result.cateParentId.slice(-1)[0];
            }
            const res = await addCategory(params);
            if (res?.isSuccess) {
                this.$message.success('添加成功');
                this.$store.dispatch('mkt-category/list', ''); //使用vuex调用接口
                this.$emit('closeDiag');
            }
        },
        async getCategoryinfo() {
            const res = await getCategoryinfo(this.currentId);
            if (res?.isSuccess) {
                for (let i in this.result) {
                    this.result[i] = res.data[i];
                }
                if (res.data.catePic) {
                    this.result.catePicurl = [{ url: res.data.catePic }];
                }
            }
        },
        async editCategory(params) {
            params.id = this.currentId;
            const res = await editCategory(params);
            if (res?.isSuccess) {
                this.$message.success('编辑成功');
                this.$store.dispatch('mkt-category/list', '');
                this.$emit('closeDiag');
            }
        },
        selectCategoryChange(value) {
            if (value?.length) {
                let data = this.$refs.cascader.getCheckedNodes();
                if (data[0] && data[0].level == 3) {
                    this.$nextTick(() => {
                        this.result.cateParentId = '';
                    });
                    return this.$message.error('当前，分类最多支持三级！');
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
/deep/ .el-descriptions__header {
    display: none;
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
</style>
