<template>
    <div>
        <el-dialog title="批量导入" width="35%" :visible.sync="visible" :before-close="onClose">
            <div>
                <div>
                    栏目模板：
                    <el-button type="text" @click="downloadTemplate">下载模板</el-button>
                </div>

                <div>
                    <div class="template">
                        <div class="template-text">导入模板：  </div>
                        <div :class="associatedFileShow ? 'main' : ''">
                            <div class="template-upload">
                                <el-upload
                                    action=""
                                    :before-upload="beforeUpload.bind(this, 'excelFile')"
                                    :on-remove="uploadRemove.bind(this, 'excelFileList')"
                                    multiple
                                    :limit="1"
                                    :on-exceed="handleExceedFile"
                                    :file-list="fileList"
                                    :http-request="uploadHttpRequest.bind(this, 'excelFileList')"
                                >
                                    <el-button type="text" v-if="!associatedFileShow">
                                        添加模板
                                    </el-button>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                   <div class="tips">一次最多导入2000条数据，超出时请分批导入</div>
                </div>

                <div v-show="associatedFileShow" class="associated-file" >
                    <div class="template1">
                        <div class="template-text">导入文件：

                        </div>
                        <div class="template-upload">
                            <div class="template-upload-text">
                                
                                <div class="template-tag" v-if="loading&&!filesParam.length" v-loading="loading">
                                  
                                </div>
                                <div class="template-tag" v-if="filesParam.length">
                                    <div>已上传{{ this.filesParam.length }}个</div>
                                    <div class="progress">
                                        <el-progress :percentage="percentage"></el-progress>
                                    </div>
                                </div>
                                <div v-else>
                                    <el-upload
                                        action=""
                                        :before-upload="beforeUpload.bind(this, 'excelFileList1')"
                                        :on-remove="uploadRemove.bind(this, 'excelFileList1')"
                                        multiple
                                        :limit="3000"
                                        :on-exceed="handleExceedFile"
                                        :file-list="[]"
                                        :http-request="
                                            uploadHttpRequest.bind(this, 'excelFileList1')
                                        "
                                    >
                                        <el-button type="text">上传图片</el-button>
                                    </el-upload>
                                </div>
                                <div v-show="filesParam.length">
                                    <el-upload
                                        action=""
                                        :before-upload="beforeUpload"
                                        :on-remove="uploadRemove.bind(this, 'excelFileList1')"
                                        multiple
                                        :limit="3000"
                                        :on-exceed="handleExceedFile"
                                        :file-list="[]"
                                        :http-request="
                                            uploadHttpRequest.bind(this, 'excelFileList1')
                                        "
                                    >
                                        <el-button type="text">继续上传</el-button>
                                    </el-upload>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="tips" v-if="associatedFileShow">上传文件，再在文件表格中填入对应的字段名称，最多上传3000张</div>

            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="onClose">取 消</el-button>
                <el-button type="primary" :loading="loadingSubmit" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { excelDataExperimental , batchWorks,downloadTemplate } from '@/api/mkt/exhibit.js';
import { oss, url } from '@/config.js';
import { getOssConfig } from  '@/api/login.js';
import {formatTimeTwo} from '@/utils/util.js';
import OSS from 'ali-oss';
export default {
    name:'sc-import-multiple-dialog',
    props: {
        batchImportShow: {
            type: Boolean,
            default: false,
        },
        columnName: {
            //栏目名字
            type: String,
            default: '',
        },
    },

    data() {
        return {
            visible: false,
            percentage: 0, //进度条百分比
            fileList: [], //导入模板
            fileList1: [], //关联文件
            ossClient: {},
            selectedCallFun: null,
            folderName: oss.folderName, //文件夹名字
            file: new Date().getTime() + '' + parseInt(Math.random() * 100),
            filesuffix: '',
            templateList: [], //模板数据
            ruleForm: {
                imageItemList: [],
                dataItemList: [],
            },
            filesParam: [], //关联文件
            mainImage: { width: '', height: '' }, //主图宽高
            filePath: '', //表格文件路径
            associatedFileShow: false, //关联文件上传
            filesNumber: 0, //记录上传文件数量
            filesSuccessNumber: 0,
            loadingSubmit:false,
            loading:false
        };
    },

    watch: {
        batchImportShow: {
            handler(val) {
                this.visible = val;
                if (val) {
                    this.getOssConfig();
                }
            },
            immediate: true,
        },
    },
    mounted() {},
    methods: {
        getOssConfig() {
            //oss签名
            // let ossConfig={
            //     stsKeyId: "STS.NTPyqqFz1sBSSBCfxNbvKCVp2",
            //     stsSecret: "DyDjMgWXZBWKaDNPrVtWhuWetp6nQYicY9koVb3xmn56",
            //     token: "CAIS9AF1q6Ft5B2yfSjIr5flMsvFq6UQxIC4UWTygngbbvlnrJPb0Dz2IHhJf3NvA+8XtPszmm1S6vwflqJIRoReREvCUcZr8symc5swi9OT1fau5Jko1beHewHKeTOZsebWZ+LmNqC/Ht6md1HDkAJq3LL+bk/Mdle5MJqP+/UFB5ZtKWveVzddA8pMLQZPsdITMWCrVcygKRn3mGHdfiEK00he8TontPninpHGs0eC0Q2lk7Uvyt6vcsT+Xa5FJ4xiVtq55utye5fa3TRYgxowr/8r1/Ybo2iW5I7BXQQLv0/bKYjO+9hoNxRla7MmC16LQ0AkfWURGoABbVeNkXZEOKT/uFYDxIdHZMX+/IOeIGFQ6dDE8DbqeXYqBqWqWTX65mlo7kAMaC9pHmuLys/UAFBWWCTP60L3dztDn7/fquiAojhvUCDAbL6cdwejWGe4LhnigoXtfKw0HfPELuDkZSsNE5EfIqjh4ytXocAjLZdEo1XZoIjDWTc="
            // }
            //          this.ossClient = new OSS({
            //             accessKeyId: ossConfig.stsKeyId,
            //             accessKeySecret: ossConfig.stsSecret,
            //             stsToken: ossConfig.token,
            //             bucket: oss?.bucket,
            //             region: oss?.region,
            //         });
            getOssConfig().then(res => {
                if (res.code === 200) {
                    let ossConfig = res.data;
                    this.ossClient = new OSS({
                        accessKeyId: ossConfig.stsKeyId,
                        accessKeySecret: ossConfig.stsSecret,
                        stsToken: ossConfig.token,
                        bucket: oss?.bucket,
                        region: oss?.region,
                    //       endpoint: 'https://sczs.voglassdc.com',
                    // cname: true,
                    });
                }
            });
        },

        // 下载模板
        async downloadTemplate() {
            let params = {};
            let { code, data, msg } = await downloadTemplate(params);
            if (code === 200) {
                if (data) {
                    window.open(data);
                }
            } else {
                this.$message.error(msg);
            }
        },

        beforeUpload(itype,file) {
            console.log('-----------file',file)
            this.filesNumber = this.filesNumber + 1;
            if (this.selectedCallFun) {
                this.selectedCallFun();
            }

            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            let namelength = file.name.length; //取到文件名长度
            this.filesuffix = file.name.substring(first + 1, namelength);

            if (!['jpg', 'xlsx', 'jpeg', 'png'].includes(this.filesuffix)) {
                this.$message.error('暂时不支持此格式，请重新上传');
                return false;
                //给个flag终止上传行为发生
            }
            if(itype=='excelFileList1'){
                this.loading=true
            }

            // 获取图片宽高
            let reader = new FileReader();
            reader.onload = () => {
                let text = event.target.result;
                let img = document.createElement('img');
                img.src = text;
                img.onload = () => {
                    this.mainImage['width_' + file.name] = img.width;
                    this.mainImage['height_' + file.name] = img.height;
                };
            };

            reader.readAsDataURL(file);

        },

        uploadRemove(itype, file, fileList) {
            if (itype == 'fileListDemo') {
                this.ruleForm.imageItemList = this.ruleForm.imageItemList.filter(d => {
                    if (file.name) {
                        //当前上传的数据有 file.name;旧数据没有file.name,使用url匹配
                        return d.fileName != file.name;
                    } else {
                        let isTrue = file.url.indexOf(d.filePath) == -1; //之前上传旧数据；过滤不包含的
                        return isTrue;
                    }
                });
            } else if (itype == 'excelFileList1') {
                this.filesParam = this.filesParam.filter(d => {
                    if (file.name) {
                        //当前上传的数据有 file.name;旧数据没有file.name,使用url匹配
                        return d.fileName != file.name;
                    } else {
                        let isTrue = file.url.indexOf(d.filePath) == -1; //之前上传旧数据；过滤不包含的
                        return isTrue;
                    }
                });
            } else {
            this.associatedFileShow = false
                this.c = 0;
                this.ruleForm.dataItemList = this.ruleForm.dataItemList.filter(d => {
                    if (file.name) {
                        //当前上传的数据有 file.name;旧数据没有file.name,使用url匹配
                        return d.fileName != file.name;
                    } else {
                        let isTrue = file.url.indexOf(d.filePath) == -1; //之前上传旧数据；过滤不包含的
                        return isTrue;
                    }
                });
                this.ruleForm.dataItemList = [];
            }
            this.ossClient.cancel();
        },

        handleExceedFile(files, fileList) {
            this.$message.warning('当前文件超出最大限制,请删除后重新上传');
        },

        //oss上传   注意文件夹 ${this.folderName}/other
        async uploadHttpRequest(itype, option) {

            this.isUpload = true;
            const _that = this;
            let fileNameOrigin = option.file.name;

            let file = option.file;
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let tempCheckpoint;
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${this.folderName}/${date}/${this.file}.${this.filesuffix}`;
            // let url = `${this.folderName}/node/${this.file}.${this.filesuffix}`;
            console.log('--------------111',this.ossClient)
            let result = this.ossClient.multipartUpload(url, file, {
                progress(p, checkpoint) {
                    tempCheckpoint = checkpoint;
                    //p为上传进度数
                    if (itype == 'excelFileList1') {
                        if (_that.filesSuccessNumber === _that.filesNumber - 1) {
                            _that.percentage = parseInt(p * 100);
                        }
                    }
                },

                checkpoint: tempCheckpoint,
                // 设置并发上传的分片数量。
                parallel: 1,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 2 * 1024 * 1024,
                meta: {
                    year: 2022,
                    people: 'test',
                },
                mime: 'text/plain',
            });
            let res = await result;
            console.log('--------------222',result)

            // this.filesSuccessNumber = ++this.filesSuccessNumber;

            if (res?.name) {
                if (itype == 'fileListDemo') {
                    this.ruleForm.imageItemList.push({
                        filePath: res.name,
                        fileName: fileNameOrigin,
                        suffix: this.filesuffix,
                        width: this.mainImage['width_' + fileNameOrigin],
                        height: this.mainImage['height_' + fileNameOrigin],
                    });
                }
                if (itype == 'excelFileList1') {
                    // 使用全路径
                    this.filesParam.push({
                        filePath: res.name,
                        smallFilePath: "https://dpwcc.oss-cn-beijing.aliyuncs.com/"+res.name,
                        fileName: fileNameOrigin,
                        suffix: this.filesuffix,
                        width: this.mainImage['width_' + fileNameOrigin] || null,
                        height: this.mainImage['height_' + fileNameOrigin] || null,
                    });
                } else {
                    console.log('-----------')
                    this.filePath = res?.name; //文件路径
                    this.ruleForm.dataItemList=[{
                        filePath: res.name,
                        fileName: fileNameOrigin,
                        suffix: this.filesuffix,
                        width:0,
                        height:0
                    }];
                }
                if (this.successCallFun) {
                    this.successCallFun(res.res.requestUrls[0]);
                }
            }

            _that.filesSuccessNumber = ++_that.filesSuccessNumber
            await this.toExcelDataExperimental();

        },

        // 表格数据验证
        async toExcelDataExperimental() {
            let params = {
                filePath: this.filePath,
            };
            let res = await excelDataExperimental(params);
            if (res?.code === 200) {
                this.associatedFileShow = true;
            } else {
                this.associatedFileShow = false;
            }
        },

        // 保存
        async onSubmit() {
            let obj = (this.ruleForm.dataItemList?.length && this.ruleForm.dataItemList[0]) || {};
            if(!obj.filePath){
                this.$message({
                        showClose: true,
                        message: "请上传模板",
                        type: 'error'
                    })
                    return false;
            }
            this.loadingSubmit=true
            let params = {
                excel: { ...obj },
                filesParam: [...this.filesParam],
            };
            try {
                let { code, data, isSuccess,message } = await batchWorks(params);
                this.onClose();
                this.loadingSubmit = false
                if (code === 200) {
                    if(data&&data!=null){
                        this.$alert(data, '提示', {
                            confirmButtonText: '确定',
                        })
                    // this.$message({
                    //     showClose: true,
                    //     message: data,
                    //     type: 'error'
                    // })
                    }else{
                        this.$message.success('操作成功');
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: message||'操作失败',
                        type: 'error'
                    });
                }
            } catch (error) {
                this.loadingSubmit=false
                console.log('------------------error',error)
                //  this.$message({
                //     showClose: true,
                //     message: error,
                //     type: 'error'
                // });
            }
        },

        onClose() {
            this.visible = false;
            this.$emit('onClose');
        },
    },
};
</script>

<style lang="less" scoped>

.delete-btn {
    margin-left: 10px;
}

.template {
    display: flex;
    flex-direction: row;
}

.template1 {
    display: flex;
    flex-direction: row;
}

.template-text {
    margin-top: 10px;
}

.template-upload{
    margin-left: 8px;
}

.progress {
    width: 150px;
    margin-left: 10px;
}

.common {
    display: inline-block;
}

.tips {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
}

.template-tag {
    display: flex;
    margin-top: 10px;
}

.associated-file{
    margin-top: 10px;
}

.main {
    margin-top: -23px;
}

.template-upload-text{
    display: flex;
}

.template1{
/deep/ .el-upload-list__item {
    transition: none !important;
    -webkit-transition: none !important;
}
/deep/ .el-upload-list__item-name {
    transition: none !important;
    -webkit-transition: none !important;
}

/deep/ .el-upload-list__item.is-ready {
    display: none;
  }
}


</style>
</style>
