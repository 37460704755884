<template>
    <div class="imgtext-container">
        <div class="tips">
            <el-link type="warning" :underline="false">
                <i class="el-icon-warning-outline"></i>
                修改内容后，点击【保存】后生效！
            </el-link>

            <el-button size="mini" class="top-btn" @click="add">+添加</el-button>
        </div>

        <div class="table-box">
            <div class="table-head">
                <div class="img">图片</div>
                <div class="link">名称/链接</div>
                <div class="operation"></div>
            </div>
            <draggable
                v-model="tableData"
                handle=".mover"
                animation="300"
                @start="onStart"
                @end="onEnd"
            >
                <transition-group>
                    <div class="table-item mover" v-for="(item, index) in tableData" :key="item.id">
                        <span class="img">
                            <img v-if="item.resourcePath" :src="item.resourcePath" alt="" />
                            <el-upload
                                v-if="!item.resourcePath"
                                action=""
                                :before-upload="beforeUploadDemo.bind(this, item)"
                                :on-exceed="handleExceedDemo"
                                :show-file-list="false"
                                :file-list="fileList"
                                :http-request="fnUploadRequest.bind(this, item, index)"
                            >
                                <img src="../../../../assets/images/upload-img.png" />
                            </el-upload>
                            <img
                                v-if="item.resourcePath"
                                src="../../../../assets/images/upload-close.png"
                                class="el-icon-circle-close"
                                @click.self="uploadRemove(item, index)"
                            />
                        </span>
                        <span class="link">
                            <el-input
                                type="text"
                                class="file-cls"
                                v-model="item.title"
                                maxlength="8"
                                size="mini"
                            />
                            <el-button size="mini" class="btn" @click="opOptions(item)">
                                设置
                            </el-button>
                            <div
                                class="option-item-box"
                                v-if="item.linkPath && delItem.id !== item.id"
                            >
                                <div class="content">
                                    <span class="option-item">
                                        {{ item.linkPath }}
                                    </span>
                                </div>
                                <i
                                    class="el-icon-circle-close"
                                    @click="deleoption(item, index)"
                                ></i>
                            </div>
                        </span>
                        <span class="operation">
                            <el-button type="primary" size="mini" @click="editTitle(item)">
                                保存
                            </el-button>
                            <i class="el-icon-s-operation"></i>
                            <i class="el-icon-delete" @click="deleData(item)"></i>
                        </span>
                    </div>
                </transition-group>
            </draggable>
        </div>
        <options
            :optionvisible="optionvisible"
            @closeOption="closeOption"
            @sendOption="sendOption"
        ></options>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import Options from './options.vue';
import { getOssConfig } from '@/api/login.js';
import { uploadConfigData } from '@/config.js';
import { getTextimglist, addEcoration, orderDec, delDec } from '@/api/mkt/market.js';
import { formatTimeTwo } from '@/utils/util.js';
import OSS from 'ali-oss';

export default {
    components: {
        draggable,
        Options,
    },
    props: {
        rowModuleList: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            tableData: [],
            drag: false,
            optionvisible: false,
            filename: '',
            currentItem: {
                optionList: '',
                linkType: '',
                linkPath: '',
                link: '',
            },
            delItem: {
                optionList: '',
            },
            uploadConfig: {},
            fileList: [],
        };
    },
    mounted() {
        this.getOssFn();
        this.getTextimglist();
    },
    methods: {
        getOssFn() {
            getOssConfig().then(res => {
                if (res?.isSuccess) {
                    uploadConfigData.oss.accessKeyId = res.data.stsKeyId;
                    uploadConfigData.oss.accessKeySecret = res.data.stsSecret;
                    uploadConfigData.oss.stsToken = res.data.token;
                    this.uploadConfig = uploadConfigData;
                }
            });
        },
        async addEcoration(params) {
            const res = await addEcoration(params);
            if (res?.isSuccess) {
                this.$message.success('保存成功');
                this.getTextimglist();
            }
        },
        async orderDec(params) {
            const res = await orderDec(params);
            if (res?.isSuccess) {
                this.getTextimglist();
            }
        },
        async delDec(params) {
            const res = await delDec(params);
            if (res?.isSuccess) {
                this.$message.success('删除成功');
                this.getTextimglist();
            }
        },
        // 开始拖拽事件
        onStart() {
            this.drag = true;
        },
        // 拖拽结束事件
        onEnd() {
            let list = [];
            this.tableData.map(item => list.push(item.id));
            const params = {
                ids: list,
            };
            this.orderDec(params);
            this.drag = false;
        },
        geteditName() {},
        opOptions(item) {
            this.currentItem = item;
            this.optionvisible = true;
        },
        closeOption() {
            this.optionvisible = false;
        },
        sendOption(e, val, row, tagName) {
            // console.log(e, val, row, tagName, 999999);
            this.optionvisible = false;
            let list = [];
            console.log('---------------e', e);
            if (e?.length) {
                e.map(item => list.push(item.label));
                this.currentItem.linkType = this.getType(e[0].label);
                this.currentItem.detailsType = this.getdetailType(e[1].label);
                this.currentItem.optionList = list.join('-');
                // this.currentItem.linkType = 1;
                this.currentItem.linkPath = this.currentItem.optionList;
                this.currentItem.link = e[1].value;
            }
            if (val) {
                this.currentItem.link = val;
            }
            if (row) {
                if (row?.origin) {
                    this.currentItem.optionList = this.currentItem.optionList + '-' + row.origin;
                }
                this.currentItem.optionList = this.currentItem.optionList + '-' + row.label;
                this.currentItem.linkPath = this.currentItem.optionList;
            }
            if (tagName?.length) {
                this.currentItem.optionList = this.currentItem.optionList + '-' + tagName[0].label;
                this.currentItem.linkPath = this.currentItem.optionList;
            }
            if (row?.type) {
                this.currentItem.linkParamType = row.type;
            }
            // this.editTitle(this.currentItem);
        },
        getType(label) {
            let type = 0;
            switch (label) {
                case '笔记':
                    type = 2;
                    break;
                case '作品':
                    type = 1;
                    break;
                case '案例':
                    type = 1;
                    break;
                case '探索灵感':
                    type = 3;
                    break;
                case '话题':
                    type = 5;
                    break;
                case '设计师':
                    type = 4;
                    break;
                case '智能匹配':
                    type = 8;
                    break;
                case '设计师专题':
                    type = 9;
                    break;
                case '风格测试':
                    type = 10;
                    break;
                case '户型规划':
                    type = 15;
                    break;
                case '装修预算':
                    type = 14;
                    break;
                default:
                    break;
            }
            return type;
        },
        getdetailType(label) {
            let type = 0;
            switch (label) {
                case '默认列表':
                    type = 1;

                    break;
                case '指定列表':
                    type = 2;
                    break;
                case '详情':
                    type = 3;
                    break;
                default:
                    break;
            }
            return type;
        },
        beforeUploadDemo(item, file) {
            if (!item?.title) {
                this.$message.error('请先输入标题再上传图片！');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 ' + 10 + 'MB!');
                return;
            }
            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            let namelength = file.name.length; //取到文件名长度
            this.filesuffix = file.name.substring(first + 1, namelength);
            if (!['jpg', 'gif', 'jpeg', 'png'].includes(this.filesuffix)) {
                this.$message.error('暂时不支持此格式，请重新上传');
                return false;
            }
        },
        handleExceedDemo() {
            this.$message.warning(`当前限制上传1张图片,请删除后重新上传`);
        },
        // 获取随机数(时间戳+四位随机数)
        getRandomNumber() {
            var num = ''; //定义用户编号
            for (
                var i = 0;
                i < 4;
                i++ //4位随机数，用以加在时间戳后面。
            ) {
                num += Math.floor(Math.random() * 10);
            }
            num = new Date().getTime() + num; //时间戳，用来生成用户编号。
            return num;
        },
        fnUploadRequest(item, index, option) {
            let file = option.file;
            let uploadConfig = this.uploadConfig;
            return new Promise((resolve, reject) => {
                const client = new OSS({
                    accessKeyId: uploadConfig.oss.accessKeyId,
                    accessKeySecret: uploadConfig.oss.accessKeySecret,
                    stsToken: uploadConfig.oss.stsToken,
                    bucket: uploadConfig.oss.bucket,
                    region: uploadConfig.oss.region,
                });
                let tempCheckpoint;
                let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
                let namelength = file.name.length; //取到文件名长度
                let filesuffix = file.name.substring(first + 1, namelength); //文件后缀名
                let filesName = file.name.split('.')[0];
                let date = formatTimeTwo(new Date(), 'YMD');
                let result = client.multipartUpload(
                    `${
                        uploadConfig.fileModule
                    }/${date}/${filesName}${this.getRandomNumber()}.${filesuffix}`,
                    file,
                    {
                        progress(p, checkpoint) {
                            tempCheckpoint = checkpoint;
                        },
                        checkpoint: tempCheckpoint,
                        meta: {
                            year: 2022,
                            people: 'test',
                        },
                        mime: 'text/plain',
                    }
                );
                result.then(res => {
                    this.fileList = [];
                    this.fileList.push({
                        name: res.name.split('/')[3],
                        url: uploadConfig.oss.url + res.name,
                    });
                    item.resourcePath = this.fileList[0].url;
                    this.tableData[index].resourcePath = this.fileList[0].url;
                    console.log(this.tableData, ' this.tableData');
                    return resolve(true);
                });
            });
        },
        uploadRemove(item, index) {
            if (item?.resourcePath) {
                item.resourcePath = '';
                this.tableData[index].resourcePath = '';

                this.fileList = [];
                this.$forceUpdate();
            }
        },
        deleoption(item, index) {
            this.delItem = item;
            this.tableData[index].linkType = '';
            // this.editTitle(item, 'link');
        },
        deleData(row) {
            const params = {
                ids: [row.id],
            };
            this.delDec(params);
        },
        add() {
            // if (this.tableData.length >= 4) {
            //     this.$message.warning('最多添加四项');
            //     return;
            // }
            let num = 1;
            this.tableData.map(item => {
                if (item?.type == 'Add') {
                    num++;
                }
            });
            this.tableData.unshift({
                img: '',
                id: Math.random() * 10,
                type: 'Add',
                title: `导航${num}`,
            });
        },
        editTitle(row, status) {
            if (!row?.resourcePath) {
                this.$message.warning('请上传图片');
                return;
            }
            if (!row?.title) {
                this.$message.warning('请输入标题');
                return;
            }
            if (!row?.linkType) {
                this.$message.warning('请设置链接');
                return;
            }

            const params = {
                title: row.title,
                id: row.id,
                resourcePath: row.resourcePath,
                linkType: row.linkType,
                detailsType: row.detailsType,
                linkPath: row.linkPath,
                link: row.link,
                linkParamType: row.linkParamType,
            };
            if (row?.type === 'Add') {
                delete params.id;
            }
            if (this.rowModuleList?.value) {
                params.moduleId = this.rowModuleList.value;
            }
            if (status) {
                params.linkType = '';
                params.linkPath = '';
                params.link = '';
            }
            this.addEcoration(params);
        },
        async getTextimglist() {
            const params = {
                moduleId: this.rowModuleList.value,
                orderIndexSortFlag: 2,
            };
            const res = await getTextimglist(params);
            if (res?.isSuccess) {
                this.delItem = {
                    optionList: '',
                };
                this.tableData = res.data.children;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.imgtext-container {
    background: #f5f5f5;
    padding: 10px;
    .tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .top-btn {
        float: right;
    }
    .table-box {
        margin-top: 40px;
    }
    .table-head {
        display: flex;
        width: 100%;
        background: #f2f2f9 100%;
        height: 30px;
        align-items: center;
    }
    .table-item {
        display: flex;
        width: 100%;
        min-height: 54px;
        background: #fff;
        margin: 10px 0;
        align-items: center;
    }
    .img {
        position: relative;
        width: 150px;
        margin-left: 33px;
        padding: 10px 0;
        img {
            width: 100px;
            height: 100px;
        }
        .el-icon-circle-close {
            position: absolute;
            // left: 30px;
            top: 10px;
            right: 50px;
            width: 20px;
            height: 20px;
            // bottom: 13px;
            cursor: pointer;
            z-index: 99;
            color: #fff;
        }
    }
    .link {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        .file-cls {
            width: 110px;
            // border: none;
        }
        .btn {
            width: 80px;
            margin-top: 10px;
        }
    }
    .operation {
        display: flex;
        width: 150px;
        justify-content: space-around;
        margin-right: 20px;
        .el-icon-s-operation {
            font-size: 24px;
            cursor: pointer;
        }
        .el-icon-delete {
            font-size: 24px;
            cursor: pointer;
        }
    }
    .option-item-box {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 246px;
        min-height: 30px;
        padding: 0 10px;
        background-color: rgba(255, 255, 255, 100);
        color: rgba(192, 170, 130, 100);
        font-size: 12px;
        border: 1px solid #eee;
    }
    .el-icon-circle-close {
        cursor: pointer;
    }
    .upload-border {
        border: 1px solid #dcdfe6;
        color: #767671;
        padding: 20px 10px;
    }
}
</style>
