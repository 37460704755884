<template>
    <div class="mkt search-data" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        ></sc-search-v2>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="loading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <template v-slot:index>
                    <el-table-column>
                        <template slot="header">序列</template>
                        <template slot-scope="scope">
                            <div class="nick-box">
                                <span>{{ scope.row.index + 1 }}</span>
                                <!-- <img :src="scope.row.nickName" alt=""> -->
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="80">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { getSearchHit } from '@/api/mkt/user.js';
const dateArr = [
    { key: 1, value: '近1天' },
    { key: 7, value: '近7天' },
    { key: 15, value: '近15天' },
    { key: 30, value: '最近30天' },
    { key: 2, value: '全部' },
];
export default {
    name: 'staff-index',
    mixins: [mixins],
    data() {
        return {
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '序列', key: 'index' },
                { name: '搜索关键词', key: 'searchName' },
                { name: '搜索PV', key: 'pvNum' },
                { name: '搜索UV', key: 'uvNum' },
                { name: '第1位转化率', key: 'oneRate' },
                { name: '第2位转化率', key: 'twoRate' },
                { name: '第3位转化率', key: 'threeRate' },
                { name: '第4位转化率', key: 'fourRate' },
                { name: '第5位转化率', key: 'fiveRate' },
                { name: '总转化率', key: 'total' },
            ],
            list: [{}],
            columns: {
                key1: {
                    spanCol: 3,
                },
                searchType: {
                    name: '日期',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: dateArr,
                        props: {
                            value: 'key',
                            label: 'value',
                        },
                        filterable: true,
                    },
                    spanCol: 1,
                },
            },
            result: {
                searchType: 1,
            },
            tableAddConfig: {},
            page: {
                page: 1,
                size: 30,
                count: 0,
                index: 1,
            },

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: false, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            let params = {
                page: this.page.page,
                size: this.page.size,
            };
            if (this.result.searchType) {
                params.searchType = this.result.searchType;
            }
            this.loading = true;
            getSearchHit(params).then(res => {
                this.loading = false;
                res.data.children.map(item => {
                    Object.keys(item).forEach(items => {
                        // item[items] ? '' : (item[items] = '--');
                        if (item[items] === null || item[items] === '') {
                            item[items] = '--';
                        }
                    });
                });
                this.list = res.data.children;
                this.page.count = res.data.total;
            });
        },
        onEdit(row) {
            this.$refs.editDialog.open(row);
        },
        rowClick() {},
        dbClick() {},
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
        pageChange(val) {
            this.page.page = val;
            this.page.index = val;
            this.init();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
                this.page.index = 1;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
<style lang="less">
.search-data .button-box {
    display: flex;
    justify-content: left;
    padding-top: 6px;
}
</style>
