<!-- 设计师资料 -->
<template>
    <div class="mkt" ref="table">
        <sc-form-v2
            name="基础信息"
            tablename="CRM_TAG_GROUP"
            :columnsNum="1"
            :columns="columns"
            :result.sync="result"
            :uploadConfig="uploadConfig"
            :esayStyle="true"
            :LS="LS"
            :columnsPaddingRight="20"
            @change="changeFn"
            @click="clickFn"
            ref="groupform"
        ></sc-form-v2>
        <div class="border">
            <sc-form-v2
                name=""
                tablename="designer-show-info"
                :columnsNum="4"
                :columns="columnsShowInfo"
                :result.sync="showResult"
                :esayStyle="true"
                :LS="labelWidth"
                :columnsPaddingRight="0"
                @change="changeFn"
                @click="clickFn"
                ref="showform"
            ></sc-form-v2>
        </div>
        <div class="border">
            <sc-form-v2
                name="基础信息2"
                tablename="designer-base-info"
                :columnsNum="4"
                :columns="columnsBaseInfo"
                :result.sync="result"
                :esayStyle="true"
                direction="vertical"
                :LS="LS"
                :columnsPaddingRight="30"
                @change="changeFn"
                @click="clickFn"
                ref="baseform"
            ></sc-form-v2>
        </div>
        <div class="border">
            <sc-form-v2
                name=""
                tablename="designer-info"
                :columnsNum="1"
                :columns="columnsInfo"
                direction="vertical"
                :result.sync="result"
                :esayStyle="true"
                :LS="LS"
                :columnsPaddingRight="0"
                @change="changeFn"
                @click="clickFn"
                ref="infoform"
            >
                <template v-slot:k6>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                我的故事
                                <el-button type="text" size="mini">查看案例</el-button>
                            </div>
                        </template>
                        <div>
                            <div class="input-border">
                                <el-input
                                    size="small"
                                    type="textarea"
                                    placeholder="请输入我的故事"
                                    maxlength="2000"
                                    show-word-limit
                                ></el-input>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-slot:k7>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">
                                设计师说
                                <el-button type="text" size="mini">查看案例</el-button>
                            </div>
                        </template>
                        <div>
                            <div class="input-border">
                                <el-input
                                    size="small"
                                    type="textarea"
                                    placeholder="请输入设计师说"
                                    maxlength="2000"
                                    show-word-limit
                                ></el-input>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
        </div>
    </div>
</template>

<script>
import { uploadConfigData } from '@/config.js';
import { columnsBaseInfo, columnsInfo, columnsShowInfo } from '../data.js';
export default {
    name: '',
    data() {
        return {
            uploadConfig: {},
            LS: { 'min-width': '100px' }, //设置label宽度
            labelWidth: { 'min-width': '40px' },
            columns: {
                k1: {
                    key: 'topicCover',
                    name: '设计师头像',
                    type: 'upload',
                    fileList: [],
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
            },
            columnsBaseInfo: columnsBaseInfo,
            columnsInfo: columnsInfo,
            columnsShowInfo: columnsShowInfo,
            showResult: {
                k1: '1234',
            },
            result: {},
        };
    },
    mounted() {},
    methods: {
        changeFn() {},
        clickFn() {},
    },
};
</script>
<style lang="less" scoped>
.mkt {
    padding: 0 20px;
}
.border {
    border: 1px solid #dcdfe6;
    margin-bottom: 10px;
}
</style>
