import { oss } from '@/config.js';
import { getOssConfig } from '@/api/login.js';
import OSS from 'ali-oss';
export default {
    data() {
        return {
            ossClient: null,
        };
    },
    methods: {
        //oss签名   ---必须优先调用 await（） 保证token在接口数据请求前获取到
        async getOss() {
            let ossRes = await getOssConfig();
            if (ossRes.code == 200) {
                let ossConfig = ossRes.data;
                this.ossClient = new OSS({
                    accessKeyId: ossConfig.stsKeyId,
                    accessKeySecret: ossConfig.stsSecret,
                    stsToken: ossConfig.token,
                    bucket: oss.bucket,
                    region: oss.region,
                });
            }
        },
        // 查看图片 *parmas暂为缩放宽度 *snapshot为视频封面宽度
        signatureUrl(url, params, snapshot) {
            let data = { expires: 36000 };
            if (params) {
                data.process = 'image/resize,w_' + params;
            }
            if (snapshot) {
                // "?x-oss-process=video/snapshot,t_0,f_jpg,w_750"
                data.process = 'video/snapshot,t_0,f_jpg,w_' + snapshot;
            }
            if (url) {
                return (this.ossClient && this.ossClient.signatureUrl(url, data)) || '';
            } else {
                return '';
            }
        },
    },
};
