<template>
    <div class="mkt" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        ></sc-search-v2>
        <el-button type="primary" size="small" @click="onAdd" style="margin-left:30px;">
            新增
        </el-button>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @selection-change="selectionChange"
            >
                <template v-slot:cover>
                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <el-image
                                style="width:50px;height:50px;"
                                :src="scope.row?.cover || ''"
                                :preview-src-list="[scope.row?.cover || '']"
                            ></el-image>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="90">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                style="color: red"
                                size="small"
                                @click="onDelete(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
    </div>
</template>

<script>
import editDialog from './components/detail.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';

import { getDesignerList } from '@/api/mkt/user.js';
import { delWeekCase, getList } from '@/api/mkt/week-case.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog },
    data() {
        return {
            tableHandle: getList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '内容ID', key: 'designWorksId' },
                { name: '内容标题', key: 'contentTitle' },
                { name: '封面', key: 'cover' },
                { name: '第几周', key: 'contentWeek' },
                { name: '创建时间', key: 'createTime' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            columns: {
                contentTitle: {
                    name: '内容标题',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                        options: [],
                        props: {},
                    },
                    spanCol: 1,
                },
            },
            result: {
                BDate: '',
                EDate: '',
                zeDate: '',
                zbDate: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
        },
    },

    methods: {
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        onEdit(row) {
            this.$refs.editDialog.open(row);
        },
        onAdd() {
            this.$refs.editDialog.open();
        },
        onDelete(row) {
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                delWeekCase({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.init();
                });
            });
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
