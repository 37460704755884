<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
    </div>
</template>

<script>
import { getLabellist } from '@/api/mkt/topic.js';
import mixins from '@/mixins';
export default {
    name: 'exhibit-group-search',
    mixins: [mixins],
    data() {
        return {
            value1: '',
            labelOptions: [],
            columns: {
                search: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入关键词',
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },
                labelIds: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },
                companyCode: {
                    name: '投放城市',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入投放城市',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                isPublish: {
                    name: '是否发布',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            {
                                value: '1',
                                label: '已发布',
                            },
                            {
                                value: '0',
                                label: '未发布',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                createTime: {
                    name: '创建时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '',
                    },
                    spanCol: 2,
                },
            },
            result: {
                companyCode: '',
                createTime: null,
                isPublish: '',
                labelIds: [],
                labelIdsArr: [],
                categoryId: [],
                categoryIdArr: [],
                search: '',
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: false,
            deleteData: '',
            flDeleteData: '',
        };
    },
    watch: {
        searchLabelOptions(val) {
            if (val) {
                this.columns.labelIds.options.options = val;
            }
        },
        cloumnsList(val) {
            if (val) {
                this.columns.categoryId.options.options = val;
            }
        },
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
    },
    created() {
        // this.getLabelOptions();
        this.getSearchTagList();
        this.getCompany();
        this.getColumn();
    },
    methods: {
        changeFn(e) {
            console.log(e, 'ssssssssss');
            // 处理时间
            if (e.row.key == 'createTime') {
                this.result.startTime = e.value[0] + ' 00:00:00';
                this.result.endTime = e.value[1] + ' 23:59:59';
                delete this.result.createTime;
            }
            if (e.row.key == 'labelIds') {
                this.result.labelIdsArr = this.disposeData(e.nodeData);
            }
            if (e.row.key == 'categoryId') {
                this.result.categoryIdArr = this.flDisposeData(e.nodeData);
            }
            this.result.page = 1;
            let params = {
                // 搜索框里面的参数
                ...this.result,
            };
            console.log('灵感集搜素哦条件', params);
            this.$store.commit('mkt-content-exhibit-group/setParams', params); //同步方法保存
            this.$parent.$refs.contentRef.getList();
        },
        // removeTag(val) {
        //     if (val.row.key == 'categoryId') {
        //         this.flDeleteData = val.value;
        //     }
        //     if (val.row.key == 'labelIds') {
        //         this.deleteData = val.value;
        //     }
        // },
        flDisposeData(arr) {
            return arr
                .map(item => {
                    return item.data.value;
                })
                .toString();
        },
        disposeData(arr) {
            return arr
                .map(item => {
                    return item.data.id;
                })
                .toString();
        },
        clickFn(e, type) {
            if (type == 'clear') {
                this.result = {
                    approvalStartTime: '',
                    approvalEndTime: '',
                    startTime: '',
                    endTime: '',
                    labelIds: [],
                    search: '',
                    companyCode: '',
                    page: 1,
                    size: 30,
                    operationId: '',
                    count: '',
                    isPublish: '',
                    spaceId: '',
                    type: '',
                    categoryId: [],
                    labelIdsArr: [],
                    categoryIdArr: [],
                };
                this.$bus.$emit('clearSearchExhibitGroup');
            }
            this.result.page = 1;
            let params = {
                // 搜索框里面的参数
                ...this.result,
            };
            console.log(params, 'params');
            this.$store.commit('mkt-content-exhibit-group/setParams', params); //同步方法保存
            // this.$store.dispatch('params/SET_ACTIVEMENU', '');  //异步方法保存
            this.$parent.$refs.contentRef.getList();
        },
    },
};
</script>
<style scoped>
/deep/.cascader .el-tag__close {
    display: none !important;
}
</style>
