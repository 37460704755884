<!-- 提报奖项表单页面 -->

<template>
    <layout>
        <div class="mkt">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="提报奖项" name="first"></el-tab-pane>
                <el-tab-pane label="已提报" name="second"></el-tab-pane>
            </el-tabs>

            <div v-if="activeName == 'second'">
                <sc-table-v2
                    title=""
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :table-height="height"
                    :read-table="true"
                    :load="false"
                    :selection="false"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                >
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="170">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    @click="handleCheckDetail(scope.row)"
                                    size="mini"
                                >
                                    查看详细提报
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
            <div v-else>
                <add></add>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layout.vue';
import add from './components/add.vue';
export default {
    name: '',
    components: { Layout, add },
    data() {
        return {
            activeName: 'first',
            tableHead: [
                { name: '内容标题', key: 'specialName' },
                { name: '内容类型', key: 'categoryName' },
                { name: '奖项类型', key: 'enabled' },
                { name: '提报时间', key: 'createUserName' },
                { name: '操作', key: 'control' },
            ],
            height: window.screen.height - 340,
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            list: [{}],
        };
    },
    mounted() {},
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleCheckDetail() {
            this.activeName = 'first';
        },
    },
};
</script>
<style lang="less" scoped></style>
