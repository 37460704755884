<template>
    <div class="mkt" ref="table">
        <div class="tabs" style="padding-top: 12px;">
            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="searchFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>

            <div class="btns">
                <el-button class="btns-button" size="small" type="primary" @click="newAdd()">
                    新增消息
                </el-button>
            </div>

            <div class="tabs">
                <sc-table-v2
                    class="list-table"
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:cover>
                        <el-table-column label="封面" width="72">
                            <template slot-scope="scope">
                                <el-image
                                    v-if="scope.row.cover"
                                    fit="cover"
                                    :src="scope.row.cover"
                                    style="width: 50px; height: 50px"
                                    :preview-src-list="[scope.row.cover]"
                                ></el-image>
                                <span v-else>无</span>
                            </template>
                            <!-- <i class="el-icon-sort icon" style="padding:12px"></i> -->
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="120">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="edit(scope.row, 'show')"
                                    v-if="scope.row.messageState == 2"
                                >
                                    查看
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="edit(scope.row, 'edit')"
                                    v-if="scope.row.messageState != 2"
                                >
                                    编辑
                                </el-button>

                                <el-button
                                    type="text"
                                    style="color: red"
                                    size="small"
                                    @click="onDel(scope.row)"
                                    v-if="scope.row.messageState != 2"
                                >
                                    删除
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="edit(scope.row, 'copy')"
                                >
                                    复制
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>

        <NewAdd ref="newAdd" @pageReload="pageReload" @previewClick="previewClick"></NewAdd>
        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import NewAdd from './components/newAdd.vue';
import Preview from './components/preview.vue';
import { userMessageList, delUserMessageById } from '@/api/mkt/message';
import mixins from '@/mixins';

export default {
    components: { NewAdd, Preview },
    data() {
        return {
            LS: { 'min-width': '100px', 'padding-bottom': '15px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            columns: {
                contentTitle: {
                    name: '内容标题',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                contentType: {
                    name: '类型',
                    type: 'select',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择',
                        options: [
                            { sysLabel: '笔记', dictValue: 1 },
                            { sysLabel: '案例', dictValue: 2 },
                            { sysLabel: '切片', dictValue: 3 },
                            { sysLabel: '设计师主页', dictValue: 4 },
                            { sysLabel: '合集', dictValue: 5 },
                            { sysLabel: '设计师榜', dictValue: 6 },
                            { sysLabel: '风格测试', dictValue: 7 },
                            { sysLabel: '智能匹配', dictValue: 8 },
                        ],
                        props: {
                            label: 'sysLabel',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {},
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清除搜索', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },

            tableHead: [
                { name: '内容标题', key: 'contentTitle' },
                { name: '标题', key: 'title' },
                { name: '封面', key: 'cover' },
                { name: '发送人群', key: 'sendPopulationShow' },
                {
                    name: '发送状态',
                    key: 'messageState',
                    formatter: (row, column) => {
                        return row.messageState == 2 ? '已发送' : '未发送';
                    },
                },
                {
                    name: '类型',
                    key: 'contentName',
                },
                {
                    name: '创建时间',
                    key: 'createTime',
                },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '120',
                },
            ],
            listData: [],
            tableAddConfig: {},
            tableHeightCalc: 120,
            isLoading: false,
            size: 'small',
            page: {
                size: 30, //每页多少条数据
                page: 1, //当前页码
                count: 0, //数据总条数
                index: 1,
            },
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 240;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    mounted() {},
    methods: {
        init() {
            this.page.page = 1;
            this.page.size = 30;
            this.getUserMessageList();
        },
        getUserMessageList(type) {
            let params = {};
            if (type == 'search') {
                params = {
                    ...this.result,
                };
            }
            params.page = this.page.page;
            params.size = this.page.size;
            userMessageList(params).then(res => {
                this.listData = res.data.children;
                this.page.count = res.data.total;
            });
        },
        newAdd(row) {
            this.$refs.newAdd.openDrawer(row, 'add', '新增消息');
            this.init();
        },
        onDel(row) {
            console.log(row, 'row----------------');
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                delUserMessageById({ id: row.id }).then(res => {
                    this.$message.success('删除成功');
                    this.init();
                });
            });
        },
        edit(row, type) {
            let title = '';
            if (type == 'edit') {
                title = '编辑';
            } else if (type == 'copy') {
                title = '复制';
            } else {
                title = '查看';
            }
            this.$refs.newAdd.openDrawer(row, type, title);
        },
        searchFn() {
            this.getUserMessageList('search');
        },
        changeFn() {
            this.getUserMessageList('search');
        },
        pageReload() {
            this.$refs.newAdd.handleClose();
            this.init();
        },
        handleClickMore() {},
        clickFn() {},
        rowClick() {},
        dbClick(row) {
            this.$refs.preview.openPreview(row);
        },
        rowChange() {},
        selectionChange() {},
        pageSizesChange() {},
        pageChange(e) {
            this.page.page = e;
            this.getUserMessageList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        previewClick(row) {
            this.$refs.preview.openPreview(row);
        },
    },
};
</script>
<style lang="less" scoped>
.btns {
    position: relative;
    height: 32px;

    .btns-button {
        position: absolute;
        right: 0;
        margin-right: 25px;
    }
}
</style>
