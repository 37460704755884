<template>
    <div>
        <sc-search-v2
            name="提交人信息"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:updateTime>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">提交时间：</div>
                    </template>
                    <div style="text-align: left">
                        <el-date-picker
                            v-model="updateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="changeDate('updateTime')"
                            size="small"
                            key="updateTime"
                            style="width:100%;"
                        ></el-date-picker>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:createTime>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">发布时间：</div>
                    </template>
                    <div style="text-align: left">
                        <el-date-picker
                            v-model="createTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="changeDate('createTime')"
                            size="small"
                            key="createTime"
                            style="width:100%;"
                        ></el-date-picker>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:time>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">审核时间：</div>
                    </template>
                    <div style="text-align: left">
                        <el-date-picker
                            v-model="time"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @change="changeDate('time')"
                            size="small"
                            key="time"
                            style="width:100%;"
                        ></el-date-picker>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { getSysAwards } from '@/api/mkt/exhibit';
export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                search: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '名称/楼盘/创建人姓名/昵称/描述/ID',
                    },
                    spanCol: 1,
                },
                houseLayout: {
                    name: '房屋类型',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '房屋类型',
                        options: [],
                        props: {
                            label: 'name',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },

                approvalStatus: {
                    name: '审核状态',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '审核状态',
                        options: [
                            { label: '待审核', value: '1' },
                            { label: '审核通过', value: '3' },
                            { label: '审核退回', value: '4' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                categoryId: {
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            value: 'value',
                            label: 'label',
                            multiple: true,
                            emitPath: false,
                        },
                    },
                    spanCol: 1,
                },
                labelIds: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            multiple: true,
                            emitPath: false,
                        },
                    },
                    spanCol: 1,
                },
                companyCode: {
                    name: '分公司',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入分公司',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
                createTime: {
                    name: '发布时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        startPlaceholder: '开始时间',
                        endPlaceholder: '结束时间',
                        valueFormat: 'yyyy-MM-DD',
                        format: 'yyyy-MM-DD',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                time: {
                    name: '审核时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        placeholder: '',
                        startPlaceholder: '开始时间',
                        endPlaceholder: '结束时间',
                        valueFormat: 'yyyy-MM-DD',
                        format: 'yyyy-MM-DD',
                        rangeSeparator: '-',
                    },
                    spanCol: 1,
                },
                contentLevel: {
                    name: '评级',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            value: 'dictValue',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                awardsIds: {
                    name: '获奖标签',
                    type: 'cascader',
                    options: {
                        placeholder: '请选择',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'sysLabel',
                            value: 'dictValue',
                            multiple: true,
                            emitPath: false,
                        },
                    },
                    spanCol: 1,
                },
                isWeight: {
                    name: '权重值',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            { value: 1, label: '无' },
                            { value: 2, label: '有' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                updateTime: {
                    name: '提交时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '',
                        startPlaceholder: '开始时间',
                        endPlaceholder: '结束时间',
                        valueFormat: 'yyyy-MM-DD',
                        format: 'yyyy-MM-DD',
                    },
                    spanCol: 1,
                },
                // isTopping: {
                //     name: '置顶',
                //     type: 'select',
                //     options: {
                //         placeholder: '请选择',
                //         defaultValueBase: '全部',
                //         options: [
                //             { value: '全部', label: '全部' },
                //             { value: '1', label: '置顶' },
                //             { value: '0', label: '未置顶' },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                // },
                // awardType: {
                //     name: '奖项提报',
                //     type: 'select',
                //     options: {
                //         placeholder: '请选择',
                //         defaultValueBase: '全部',
                //         options: [
                //             { value: '0', label: '无' },
                //             { value: '1', label: '红玺杯' },
                //             { value: '2', label: '季度评优' },
                //         ],
                //         props: {
                //             value: 'value',
                //             label: 'label',
                //         },
                //     },
                // },
                k1: {},
            },
            result: {
                search: '',
                categoryId: '',
                worksType: '',
                type: '',
                labelIds: '',
                approvalStatus: '',
                labelIdsArr: [],
                categoryIdArr: [],
                houseLayout: '',
                contentLevel: '',
                rewardName: '',
                // isTopping: '全部',
                // awardType: '',
            },
            updateTime: '', //提交时间
            createTime: '', // 发布时间
            time: '',
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: false,
            levelList: [],
            time: '',
            createTime: '',
            updateTime: '',
        };
    },
    watch: {
        searchLabelOptions(val) {
            if (val) {
                const result = val.map(item => {
                    const children =
                        item.children?.length &&
                        item.children.map(every => {
                            return {
                                id: every?.id,
                                labelGroupId: every?.labelGroupId,
                                labelName: every?.labelName,
                                parentId: every?.parentId,
                                properties: every?.properties,
                            };
                        });
                    return {
                        ...item,
                        children: children,
                    };
                });
                result.unshift({
                    children: [],
                    groupName: '无',
                    groupType: 0,
                    id: '100000',
                    labelList: [],
                    labelName: '无',
                });
                this.columns.labelIds.options.options = result;
            }
        },
        cloumnsList(val) {
            if (val) {
                this.columns.categoryId.options.options = val;
            }
        },
        companyData(val) {
            if (val) {
                this.columns.companyCode.options.options = val;
            }
        },
        houseList(val) {
            if (val) {
                this.columns.houseLayout.options.options = val;
            }
        },
        levelList(val) {
            console.log('-------levelList', val);
            if (val) {
                this.columns.contentLevel.options.options = val;
            }
        },
    },
    methods: {
        changeDate(value) {
            if (value == 'updateTime') {
                this.result.updateStartTime = this[value] && this[value][0] + ' 00:00:00';
                this.result.updateEndTime = this[value] && this[value][1] + ' 23:59:59';
            }
            if (value == 'createTime') {
                this.result.startTime = this[value] && this[value][0] + ' 00:00:00';
                this.result.endTime = this[value] && this[value][1] + ' 23:59:59';
            }
            if (value == 'time') {
                this.result.approvalStartTime = this[value] && this[value][0] + ' 00:00:00';
                this.result.approvalEndTime = this[value] && this[value][1] + ' 23:59:59';
            }
            let params = {
                ...this.result,
            };
            this.$store.commit('mkt-content-exhibit/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-content-exhibit/list');
        },
        changeFn(e) {
            console.log('---------e', e);

            if (e.row.key == 'labelIds') {
                this.result.labelIdsArr = this.disposeData(e.nodeData);
            }
            if (e.row.key == 'categoryId') {
                this.result.categoryIdArr = this.flDisposeData(e.nodeData);
            }
            this.result.page = 1;
            let params = {
                ...this.result,
            };
            // if (params.isTopping == '全部') {
            //     params.isTopping = '';
            // }
            console.log(params, 'paramsparamsparams');
            this.$store.commit('mkt-content-exhibit/setParams', params); //同步方法保存
            this.$parent.$refs.tabRef.getList();
        },

        flDisposeData(arr) {
            return arr.map(item => {
                return item.data.value;
            });
        },
        disposeData(arr) {
            return arr.map(item => {
                return item.data.id;
            });
        },
        clickFn(e, type) {
            console.log(e, type, 999);
            if (type == 'clear') {
                this.result = {
                    approvalStartTime: '',
                    approvalEndTime: '',
                    startTime: '',
                    endTime: '',
                    search: '',
                    categoryId: '',
                    worksType: '',
                    type: '',
                    labelIds: '',
                    companyCode: '',
                    count: '',
                    approvalStatus: '',
                    labelIdsArr: [],
                    categoryIdArr: [],
                    houseLayout: '',
                    contentLevel: '',
                    rewardName: '',
                    // isTopping: '',
                    isWeight: '',
                    weightSortType: '',
                    updateEndTime: '',
                    updateStartTime: '',
                    updateTime: null,
                    time: null,
                    createTime: null,
                    weightSortSort: '',
                    updateTimeSort: '',
                    approvalTimeSort: '',
                    createTimeSort: '',
                };
                this.time = '';
                this.updateTime = '';
                this.createTime = '';
                this.$bus.$emit('clearSearchExhibit');
            }
            this.result.page = 1;
            let params = {
                ...this.result,
            };
            // if (params.isTopping == '全部') {
            //     params.isTopping = '';
            // }
            this.$store.commit('mkt-content-exhibit/setParams', params); //同步方法保存
            this.$parent.$refs.tab.getList();
        },
        getAwaredList() {
            getSysAwards().then(res => {
                this.columns.awardsIds.options.options = res.data;
            });
        },
    },
    created() {
        this.getColumn();
        this.getSearchTagList();
        this.getCompany();
        this.getHouseLayoutList();
        this.getLevelList();
        this.getAwaredList();
    },
};
</script>
<style scoped>
/deep/.cascader .el-tag__close {
    display: none !important;
}
</style>
