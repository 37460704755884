<template>
    <div class="content" ref="table">
        <el-button type="primary" class="top-btn" size="small" @click="goAdd">新建标签组</el-button>
        <sc-table-v2
            :head="tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :table-height="500"
            :read-table="true"
            :load="false"
            :tableHeight="tableHeightCalc"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
        >
            <template v-slot:state>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.state == 0 ? '可用' : '禁用' }}
                    </template>
                </el-table-column>
            </template>

            <template v-slot:groupTypeData>
                <el-table-column label="类型">
                    <template slot-scope="scope">
                        {{ scope.row | groupTypeFormatter }}
                    </template>
                </el-table-column>
            </template>

            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleCloseBtn(scope.row)"
                            v-if="scope.row.groupType == 1"
                        >
                            {{ scope.row.state == 0 ? '禁用' : '启用' }}
                        </el-button>
                        <el-button type="text" @click="editTag(scope.row)">编辑</el-button>

                        <el-button
                            type="text"
                            @click="handleDelBtn(scope.row)"
                            v-if="scope.row.groupType == 1"
                        >
                            <span style="color: red">删除</span>
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>

        <Addtag @handleClose="handleClose" ref="dialogEdit"></Addtag>
    </div>
</template>

<script>
import { editLabelGroupState, del, editgroup } from '@/api/mkt/tag-group';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-tag-group');
import Addtag from './add.vue';

export default {
    components: {
        Addtag,
    },

    data() {
        return {
            currentPage: 1,
            tableHead: [
                // { name: '组ID', key: 'id', show: true },
                { name: '标签组名称', key: 'groupName' },
                { name: '创建人', key: 'createName' },
                { name: '状态', key: 'state' },
                { name: '排序值', key: 'orderSort' },

                {
                    name: '类型',
                    key: 'groupTypeData',
                },

                { name: '备注', key: 'remark', showOverflowTooltip: true },
                { name: '创建时间', key: 'createTime' },
                { name: '操作', key: 'control' },
            ],

            tableHeightCalc: 320,
            innerHeight: 0,
            isSearchDown: false,
            addialogVisible: false,
            row: {},
        };
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        },
    },
    computed: {
        ...mapState(['list', 'page']), // // 映射 this.list 为 store.state.list
    },
    created() {
        this.init();
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 168;
        });
    },
    filters: {
        groupTypeFormatter(row) {
            let text = '';
            switch (row?.groupType) {
                case '1':
                    text = '系统';
                    break;
                case '4':
                    text = '运营标签';
                    break;
                case '5':
                    text = '用户自定义标签组';
                    break;
                case '6':
                    text = 'AI标签';
                    break;
                case '7':
                    text = '设计师标签';
                    break;
            }
            return text;
        },
    },
    methods: {
        init() {
            let params = {
                orderSort: 2,
            };
            this.$store.commit('mkt-tag-group/setParams', params);
            this.$store.dispatch('mkt-tag-group/list'); //使用vuex调用接口
        },
        // 删除
        handleDelBtn(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    del({
                        id: row.id,
                    }).then(res => {
                        this.$message.info(res.message);
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 禁用
        handleCloseBtn(row) {
            this.$confirm(`是否操作${row.state == 0 ? '禁用' : '启用'}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    editLabelGroupState({
                        id: row.id,
                        state: row.state == 1 ? 0 : 1,
                    }).then(res => {
                        console.log('修改启用-禁用状态');
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作',
                    });
                });
        },
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },

        pageChange(val) {
            let params = { page: val };
            this.$store.commit('mkt-tag-group/setParams', params);
            this.$store.dispatch('mkt-tag-group/list');
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            let params = { size: val };
            this.$store.commit('mkt-tag-group/setParams', params);
            this.$store.dispatch('mkt-tag-group/list');
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
        goAdd() {
            this.$refs.dialogEdit.open();
        },
        handleClose() {
            this.init();
            // this.$refs.dialogEdit.close();
        },
        editTag(row) {
            this.$refs.dialogEdit.open(row);
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    margin: 10px 20px;
}
.footer-page {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
</style>
