<template>
    <div class="mkt tabs">
        <el-tabs v-model="tabActive" @tab-click="tabClick">
            <el-tab-pane label="待审核案例" name="first"></el-tab-pane>
            <el-tab-pane label="待审核笔记" name="second"></el-tab-pane>
        </el-tabs>
        <div>
            <sc-table-v2
                v-if="tabActive == 'first'"
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeight"
                :read-table="true"
                :load="false"
                :selection="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @selection-change="selectionChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <!-- <template v-slot:labelName>
                    <el-table-column>
                        <template slot="header">业绩类型</template>
                        <template slot-scope="scope">
                            {{ scope.row.labelName.options.defaultValue }}
                        </template>
                    </el-table-column>
                </template> -->
                <!-- //插槽例子，操作列用插槽写入 -->
                <template v-slot:atlas>
                    <el-table-column label="图集" key="scope.row.articleId" width="72">
                        <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.atlas"
                                style="width:50px;height:50px;"
                                :src="scope.row.atlas"
                                :preview-src-list="[scope.row.atlas]"
                            ></el-image>
                        </template>
                    </el-table-column>
                </template>

                <template v-slot:approvalStatus>
                    <el-table-column label="审核状态" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.approvalStatus | parseApproval }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="80">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleExhibit(scope.row)">
                                审核
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:createName>
                    <el-table-column label="创建人" width="140px">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.createName
                                }}{{ scope.row.account && '（' + scope.row.account + '）' }}
                            </div>
                            <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                                {{ scope.row.createUserJob }}
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>

            <sc-table-v2
                v-else
                key="second"
                title=""
                :head="tableHead2"
                :data="list2"
                :page="page2"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeight"
                :read-table="true"
                :load="false"
                :selection="false"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @selection-change="selectionChange"
                ref="refTable"
            >
                <!-- <template v-slot:title>
                    <el-table-column label=笔记名称">
                        <template slot-scope="scope">
                            【{{ scope.row.articleType | formatterArticleType }}】{{
                                scope.row.title
                            }}
                        </template>
                    </el-table-column>
                </template> -->
                <template v-slot:coverImage>
                    <el-table-column label="封面" width="72">
                        <template slot-scope="scope">
                            <el-image
                                v-if="scope.row.coverImage"
                                style="width:50px;height:50px;"
                                :src="scope.row.coverImage"
                                :preview-src-list="[scope.row.coverImage]"
                            ></el-image>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                </template>
                <!-- //插槽例子，操作列用插槽写入 -->
                <template v-slot:sourceType>
                    <el-table-column label="来源类型">
                        <template slot-scope="scope">
                            {{ scope.row.sourceType | parseSourceType }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:approvalStatus>
                    <el-table-column label="审核状态" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.approvalStatus | parseApprovalStatus }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:createUserName>
                    <el-table-column label="创建人">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.createUserName
                                }}{{
                                    scope.row.createUserAccount &&
                                        '（' + scope.row.createUserAccount + '）'
                                }}
                            </div>
                            <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                                {{ scope.row.createUserJob }}
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="80">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleExhibit(scope.row)">
                                审核
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <drawerDialogExhibit
            ref="drawerDialogExhibit"
            @closeDrawer="closeDrawer"
        ></drawerDialogExhibit>
        <drawerDialogArticle
            ref="drawerDialogArticle"
            @closeDrawer="closeArticleDrawer"
        ></drawerDialogArticle>
    </div>
</template>

<script>
import drawerDialogExhibit from '../../cm/exhibit/details';
import drawerDialogArticle from '../../cm/article/details';
import { createNamespacedHelpers } from 'vuex';
let { mapState, mapMutations } = createNamespacedHelpers('mkt-content-exhibit');

export default {
    components: { drawerDialogExhibit, drawerDialogArticle },
    data() {
        return {
            tabActive: 'first',
            tableHead: [
                {
                    name: '案例名称', //表头名称
                    key: 'title', //数据列的key值
                    show: true, //pop-show隐藏表头字段用到，默认true
                },
                { name: '图集', key: 'atlas' },
                { name: '楼盘', key: 'realEstate' },
                { name: '标签', key: 'labelName' },
                // { name: '运营标签', key: 'operationName' },
                { name: '审核状态', key: 'approvalStatus' },
                { name: '创建人', key: 'createName' },
                { name: '分公司', key: 'companyName', width: '240px' },
                { name: '最近提交时间', key: 'updateTime' },
                { name: '最近审核时间', key: 'approvalTime' },
                { name: '操作', key: 'control', width: 60 },
            ],
            tableHead2: [
                { name: '笔记名称', key: 'title' },
                { name: '封面', key: 'coverImage' },
                // { name: '来源类型', key: 'sourceType' },
                { name: '分类', key: 'categoryName' },
                { name: '标签', key: 'labelName' },
                // { name: '运营标签', key: 'operationName' },
                { name: '审核状态', key: 'approvalStatus' },
                { name: '创建人', key: 'createUserName' },
                { name: '分公司', key: 'companyName', width: '240px' },
                { name: '最近提交时间', key: 'updateTime' },
                { name: '最近审核时间', key: 'approvalTime' },
                { name: '操作', key: 'control' },
            ],

            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHeight: window.innerHeight - 200,
        };
    },
    filters: {
        parseApproval(val) {
            let str = '';
            switch (val) {
                case 1:
                    str = '待审核';
                    break;
                case 2:
                    str = '审核中';
                    break;
                case 3:
                    str = '审批通过';
                    break;
                case 4:
                    str = '审核失败';
                    break;

                default:
                    str = '待审核';
                    break;
            }
            return str;
        },
        parseSourceType(val) {
            return val == 1 ? '外部' : '内部';
        },
        parseApprovalStatus(val) {
            return val == 1 ? '待审核' : val == 3 ? '审核通过' : val == 0 ? '草稿' : '审核未通过';
        },
        formatterArticleType(val) {
            return val == 1 ? '视频' : val == 2 ? '长图文' : '短图文';
        },
    },
    computed: {
        // ...mapState(['list']), //// 映射 this.list 为 store.state.list
        list() {
            return this.$store.state['mkt-content-exhibit'].list;
        },
        list2() {
            return this.$store.state['mkt-content-article'].list;
        },
        page() {
            return this.$store.state['mkt-content-exhibit'].page;
        },
        page2() {
            return this.$store.state['mkt-content-article'].page;
        },
    },
    watch: {
        // '$store.state.mkt-content-exhibit.list': function (newVal) {
        //     this.getData(newVal);
        // },
    },
    created() {
        let params = this.$store.state['mkt-content-exhibit'].params;
        params.approvalStatus = 1;
        params.page = 1;
        this.$store.commit('mkt-content-exhibit/setParams', params);
        this.$store.dispatch('mkt-content-exhibit/list');
    },
    methods: {
        tabClick(tab, event) {
            this.tabActive = tab.name;
            if (tab.name == 'first') {
                let params = this.$store.state['mkt-content-exhibit'].params;
                params.approvalStatus = 1;
                params.page = 1;
                params.size = 30;
                this.$store.commit('mkt-content-exhibit/setParams', params);
                this.$store.dispatch('mkt-content-exhibit/list');
            }
            if (tab.name == 'second') {
                let params = this.$store.state['mkt-content-article'].params;
                params.approvalStatus = 1;
                params.isDraft = 0;
                params.page = 1;
                params.size = 30;
                this.$store.commit('mkt-content-article/setParams', params);
                this.$store.dispatch('mkt-content-article/list');
            }
        },
        handleExhibit(row) {
            if (this.tabActive == 'first') {
                this.$refs.drawerDialogExhibit.openDrawer(row);
            }
            if (this.tabActive == 'second') {
                this.$refs.drawerDialogArticle.openDrawer(row);
            }
        },
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        pageChange(val) {
            // 页码修改
            console.log(val, '分页修改');
            let params = { page: val };
            if (this.tabActive == 'first') {
                this.$store.commit('mkt-content-exhibit/setParams', params);
                this.$store.dispatch('mkt-content-exhibit/list');
            } else {
                this.$store.commit('mkt-content-article/setParams', params);
                this.$store.dispatch('mkt-content-article/list');
            }
            this.$nextTick(() => {
                console.log('--------------pageChange', this.$refs.refTable.$refs['table-box']);
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            console.log(val, '条数修改');

            let params = { size: val, page: 1 };
            this.page.size = val;
            if (this.tabActive == 'first') {
                this.$store.commit('mkt-content-exhibit/setParams', params);
                this.$store.dispatch('mkt-content-exhibit/list');
            } else {
                this.$store.commit('mkt-content-article/setParams', params);
                this.$store.dispatch('mkt-content-article/list');
            }
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
        getData(list) {
            this.page.count = this.$store.state['mkt-content-exhibit'].page.count;
        },
        closeDrawer() {
            // 关闭弹窗
            this.$store.dispatch('mkt-content-exhibit/list');
        },
        closeArticleDrawer() {
            this.$store.dispatch('mkt-content-article/list');
        },
        deleteExhibit(row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // this.$message({
                    //     type: 'success',
                    //     message: '删除成功!',
                    // });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-article/clear');
        this.$store.commit('mkt-content-exhibit/clear');
    },
};
</script>

<style scoped lang="less">
.tabs {
    padding: 10px 15px;
}
/deep/ .el-table__body .cell .text {
    // display: inline-block;
    // background: yellow;
}
/deep/ .el-table__body .cell .text:empty::before {
    content: '--';
}
</style>
