<template>
    <div class="tabs" ref="table">
        <sc-table-v2
            title=""
            :head="tableHead"
            :data="list"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :table-height="tableHeightCalc"
            :read-table="true"
            :load="false"
            :selection="false"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
        >
            <template v-slot:coverImage>
                <el-table-column label="封面">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.coverImage"
                            style="width: 50px; height: 50px"
                            :src="scope.row.coverImage"
                            :preview-src-list="[scope.row.coverImage]"
                        ></el-image>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:createUserName>
                <el-table-column label="创建人" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createUserName
                        }}{{
                            scope.row.createUserAccount && '（' + scope.row.createUserAccount + '）'
                        }}
                        <el-button type="text" size="mini" v-if="scope.row.createUserJob">
                            {{ scope.row.createUserJob }}
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            v-if="scope.row.isAssociationStatus == 0"
                            @click="handleExhibit(scope.row, 1)"
                            size="mini"
                        >
                            关联
                        </el-button>
                        <el-button
                            type="text"
                            v-if="scope.row.isAssociationStatus == 1"
                            @click="handleExhibit(scope.row, 0)"
                            size="mini"
                        >
                            取消关联
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('mkt-content-assarticle');

import { association } from '@/api/mkt/article.js';

export default {
    data() {
        return {
            tabActive: '0',
            tableHead: [
                { name: '笔记名称', key: 'title' },
                { name: '封面', key: 'coverImage' },
                { name: '分类', key: 'categoryName', width: '160' },
                { name: '标签', key: 'labelName', width: '160' },
                { name: '创建人', key: 'createUserName' },
                { name: '分公司', key: 'companyName', width: '240' },
                { name: '操作', key: 'control' },
            ],
            tableHeightCalc: 120,
            innerHeight: 0,
            isSearchDown: false,
            settingsShow: false,
        };
    },
    filters: {
        parseSourceType(val) {
            return val == 1 ? '外部' : '内部';
        },
        parseApprovalStatus(val) {
            return val == 1 ? '待审核' : val == 3 ? '审核通过' : '审核退回';
        },
        formatterArticleType(val) {
            return val == 1 ? '视频' : val == 2 ? '长图文' : '短图文';
        },
    },
    computed: {
        ...mapState(['list', 'page', 'params']), //// 映射 this.list 为 store.state.list
        ...mapMutations(['setParams']),
    },
    created() {
        // let params = this.$store.state['mkt-content-assarticle'].params;
        // params.page = 1;

        // this.$store.dispatch('mkt-content-assarticle/list');
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 178;
        },
    },
    methods: {
        onCloseSet() {
            this.settingsShow = false;
        },
        setbtn() {
            this.settingsShow = true;
        },
        closeDrawer(row) {
            // 关闭弹窗
            this.init();
        },
        init() {
            if (this.$route.query && this.$route.query.topicName) {
                let params = this.$store.state['mkt-content-assarticle'].params;
                // params.keywords = this.$route.query.topicName;
                params.page = 1;
                console.log(params, '-------------------------------');
                this.$store.commit('mkt-content-assarticle/setParams', params);
            }
            this.$store.dispatch('mkt-content-assarticle/list');
        },

        handleExhibit(row, e) {
            // this.$refs.drawerDialog.openDrawer(row);
            var params = {
                articleId: row.articleId,
                associationStatus: e,
                topicId: this.$route.query.topicId,
                topicName: this.$route.query.topicName,
            };
            association(params).then(res => {
                if (res?.isSuccess) {
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    });
                    this.init();
                }
            });
        },
        pageChange(val) {
            // 页码修改
            console.log(val, '分页修改');
            // this.page.index = val;
            let params = { page: val };
            this.$store.commit('mkt-content-assarticle/setParams', params);
            this.$store.dispatch('mkt-content-assarticle/list');
            // this.init();
        },
        pageSizesChange(val) {
            console.log(val, '条数修改');
            this.page.size = val;
            let params = { size: val };
            this.$store.commit('mkt-content-assarticle/setParams', params);
            // this.$store.dispatch('mkt-content-assarticle/list');
            this.init();
        },
    },
};
</script>

<style scoped>
.tabs {
    margin: 10px 15px;
}
.toexamiset {
    position: absolute;
}
.tabStyle {
    cursor: pointer;
}
</style>
