<template>
    <!-- feed干预排序 -->
    <div class="mkt" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :moreDown="false"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>
        <el-button type="primary" size="small" @click="createArticle" style="margin-left:30px;">
            添加内容
        </el-button>
        <div class="tabs">
            <sc-table-v2
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="isLoading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="text" size="small" @click="onDelete(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>

        <edit-dialog ref="editDialog" @refresh="refresh"></edit-dialog>
    </div>
</template>

<script>
const contentTypeInnerLink = [
    { label: '笔记', value: 1 },
    { label: '案例', value: 2 },
    // { label: '案例切片', value: 10 },
    { label: '外部链接', value: 15 },
];
import editDialog from './components/detail.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';

// import { getDesignerList } from '@/api/mkt/user.js';
import { contentLevelList, contentLevelDel } from '@/api/mkt/feed-sort.js';
export default {
    name: 'feed-sort',
    mixins: [mixins, getListMixin],
    components: { editDialog },
    data() {
        return {
            tableHandle: contentLevelList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },

            tableHead: [
                { name: '内容ID', key: 'contentId' },
                { name: '外部链接', key: 'linkUrl' },
                { name: '内容名称', key: 'title' },
                { name: '内容类型', key: 'contentType', formatter: this.parseContentType },
                { name: '排序值', key: 'orderSort' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '180',
                },
            ],
            columns: {
                id: {
                    name: '内容ID',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请输入',
                        options: [],
                        props: {},
                    },
                    spanCol: 1,
                },
                search: {
                    name: '内容名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
                contentType: {
                    name: '内容类型',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                        options: contentTypeInnerLink,
                        props: { value: 'value', label: 'label' },
                    },
                    spanCol: 1,
                },
            },
            result: {},
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 230;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        parseContentType(val) {
            let str = contentTypeInnerLink.find(item => item.value == val.contentType).label;
            return str;
        },

        refresh() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        onEdit(row) {
            this.$refs.editDialog.edit(row);
        },
        createArticle() {
            this.$refs.editDialog.open();
        },
        onDelete(row) {
            this.$confirm(`确认要将已配置的排序内容删除吗?`, '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                contentLevelDel({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.init();
                });
            });
        },

        changeFn(row, key) {
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
