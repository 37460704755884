import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取列表
export const getList = params =>
    $ajax({
        url: '/user/v2.2/list',
        method: 'get',
        params,
    });
// 修改岗位
// /user/user-job
export const userJob = data =>
    $ajax({
        url: '/user/user-job',
        method: 'post',
        data,
    });
