<template>
    <div class="mkt">
        <!-- <div class="tag-title">用户管理</div> -->
        <Search></Search>
        <Content></Content>
    </div>
</template>

<script>
import Search from './search';
import Content from './content';

export default {
    name: 'article',
    components: { Search, Content },

    data() {
        return {};
    },
    created() {
        this.$store.commit('mkt-user/clear');
    },
    methods: {},
};
</script>
<style lang="less" scoped>
.tag-title {
    padding: 20px 0 0 30px;
    font-size: 18px;
}
</style>
