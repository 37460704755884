<template>
    <el-form label-position="left">
        <div class="project">
            <div>
                <el-form-item
                    label="行业资质"
                    v-for="(item, index) in qualifications"
                    :key="index"
                    label-width="100px"
                >
                    <div style="display:flex;">
                        <el-input
                            v-model="item.value"
                            placeholder="请填写您所获得的行业资质，例如：中国室内装饰协会注册高级设计师"
                            size="default"
                            style="width: 500px; margin-right: 30px"
                            :disabled="disabled"
                        />
                        <img
                            src="@/assets/images/btn-delete.svg"
                            alt=""
                            @click="handleDel(index)"
                            v-if="index > 0"
                            :style="{
                                cursor: 'pointer',
                                opacity: query.type == 'disabled' ? '0.4' : '1',
                            }"
                        />
                        <div
                            :class="['add', query.type == 'disabled' ? 'add-disabled' : '']"
                            @click="handleAdd"
                            v-show="index == 0"
                        >
                            <img src="@/assets/images/btn-add.svg" alt="" />
                            <span>添加</span>
                        </div>
                    </div>
                </el-form-item>
            </div>
        </div>
        <el-form-item label="荣誉奖项" label-width="100px">
            <div class="flex">
                <el-cascader
                    style="width: 500px; margin-right: 30px"
                    v-model="result.awards"
                    :options="options"
                    :props="{
                        multiple: true,
                        value: 'id',
                        label: 'content',
                        children: 'lableList',
                        emitPath: false,
                    }"
                    collapse-tags
                    collapse-tags-tooltip
                    filterable
                    clearable
                    ref="cascaderRef"
                    :show-all-levels="false"
                    @change="handleChangeAwards"
                    @clear="handleClear"
                    :disabled="disabled"
                    @remove-tag="handleRemoveTag"
                ></el-cascader>
                <div
                    :class="['add', query.type == 'disabled' ? 'add-disabled' : '']"
                    @click="isAddAward = true"
                    v-if="!isAddAward"
                >
                    <span>自定义</span>
                </div>
                <template v-else>
                    <el-input
                        size="default"
                        style="width: 200px"
                        v-model="awardName"
                        class="award-input"
                        placeholder="请输入"
                    />
                    <el-button type="primary" size="small" @click="handleConfirmAwards">
                        确定
                    </el-button>
                    <el-button size="small" @click="handleClearAwards">取消</el-button>
                </template>
            </div>
        </el-form-item>
        <div class="flex" style="flex-wrap: wrap; align-items: flex-start">
            <div
                v-for="(item, index) in awardsImgs"
                :key="index"
                style="width: 140px; margin-right: 10px"
            >
                <base-upload-dpwcc
                    :fileList="item.contentFile"
                    tipTitle=""
                    tip=""
                    :fileType="'.jpg, .jpeg, .png, .gif, JPG'"
                    :fileSize="20"
                    :limit="1"
                    btnText="上传图片"
                    :disabled="disabled"
                    @fileList="sendFileList($event, index)"
                ></base-upload-dpwcc>

                <div style="margin: 5px 0 30px 0; text-align: left">
                    {{ item.content }}
                </div>
            </div>
        </div>
        <div style="display: flex">
            <div>
                <el-form-item
                    label="项目经验"
                    class="flex jingyan"
                    :class="item.errorType ? 'is-error' : ''"
                    v-for="(item, ind) in indesignItems"
                    :key="ind"
                >
                    <template #label>
                        <div class="flex">
                            <span class="label">项目经验</span>
                            <span class="must"></span>
                        </div>
                    </template>
                    <div class="flex" style="padding-left:10px;">
                        <el-input
                            size="default"
                            style="width: 500px; margin-right: 30px"
                            v-model="item.value"
                            placeholder="请填写您的项目经验，例如：北京格拉斯小镇"
                            @input="handleInput($event, ind)"
                            :disabled="disabled"
                        ></el-input>
                        <div
                            class="el-form-item__error"
                            v-if="item.errorType"
                            style="text-indent:10px;"
                        >
                            请填写项目经验
                        </div>
                        <img
                            src="@/assets/images/btn-delete.svg"
                            alt=""
                            @click="handleDelDesignItems(ind)"
                            v-if="ind > 0"
                            :style="{
                                cursor: 'pointer',
                                opacity: query.type == 'disabled' ? '0.4' : '1',
                            }"
                        />
                        <div
                            :class="['add', query.type == 'disabled' ? 'add-disabled' : '']"
                            @click="handleAddDesignItems"
                            v-if="ind == 0"
                        >
                            <img src="@/assets/images/btn-add.svg" alt="" />
                            <span>添加</span>
                        </div>
                    </div>
                </el-form-item>
            </div>
        </div>
    </el-form>
</template>
<script>
import baseUploadDpwcc from '@/components/base-upload-dpwcc.vue';
import { getRandomNumber } from '@/utils/util.js';
export default {
    name: 'design-project',
    components: { baseUploadDpwcc },
    props: {
        awardsList: {
            type: Array,
        },
        backResult: {
            type: Object,
            default: () => {},
        },
        errorStyle: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        backResult(val) {
            console.log(val, 'design-project-backResult');
            let arr = val?.industryQualifications?.map(item => {
                return {
                    value: item,
                };
            });
            console.log(arr, 'arr');
            this.qualifications = arr?.length ? arr : [{}];

            let awards = val.awards && val?.awards.map(item => item.id);
            console.log(awards, '荣誉奖项ids');
            // let res = this.findAwardsWithLabels(this.$props.awardsList, awards);
            // console.log(res, '反显数据');
            this.result.awards = awards || [''];

            this.awardsImgs = val?.awards;
            let _indesignItems = val?.designItems?.map(item => {
                return {
                    value: item,
                    errorType: false,
                };
            });
            this.indesignItems = _indesignItems?.length
                ? _indesignItems
                : [{ value: '', errorType: false }];
            let otherAwards = [];
            val?.awards.forEach(item => {
                if (!item.id || item.id == 'null') {
                    let optionsItem = {
                        content: item.content,
                        isCustom: true,
                        contentFile: [],
                        id: getRandomNumber(),
                    };
                    otherAwards.push(optionsItem);
                    item.id = optionsItem.id;
                }
            });
            setTimeout(() => {
                this.options = this.options.concat(otherAwards);
                this.result.awards = val.awards.map(item => item.id);
                console.log('------result.value', this.result.awards);
            }, 1000);
        },
        awardsList: {
            handler: function(val) {
                this.options = val;
                this.initAwards(val);
            },
            deep: true,
            immediate: true,
        },
        errorStyle(val) {
            // this.$nextTick(() => {});
            this.indesignItems = val;
            console.log(val, 'errorStyle');
        },
    },

    data() {
        return {
            options: [],
            qualifications: [{}],
            result: {
                awards: [],
                industryQualifications: [],
                designItems: [],
            },
            isAddAward: false,
            awardName: '',
            awardsImgs: [],
            cascader: '',
            value: '',
            indesignItems: [{}],
            baseInfoInit: {},
            newData: {},
            query: {
                type: '',
            },
            disabled: false,
            cacheAward: {},
            deleteListId: '',
        };
    },
    methods: {
        findAwardsWithLabels(awardsList, targetIds) {
            console.log(awardsList, targetIds, 'findAwardsWithLabels');
            const result = [];
            for (let award of awardsList) {
                for (let labelId of targetIds) {
                    const label = award.lableList?.find(label => label.id === labelId);
                    if (label) {
                        result.push([award.id, label.id]);
                    }
                }
            }
            return result;
        },
        initAwards(list) {
            list.forEach(item => {
                this.cacheAward[item.id] = item;
                item.lableList && this.initAwards(item.lableList);
            });
        },
        setDesignProject() {
            let backResult = this.$props.backResult;
            console.log(backResult, 'backResult');
            backResult?.industryQualifications?.forEach((item, index) => {
                this.qualifications[index] = {
                    value: item,
                };
            });
            let otherAwards = [];
            backResult.awards.forEach(item => {
                if (!item.id || item.id == 'null') {
                    let optionsItem = {
                        content: item.content,
                        isCustom: true,
                        contentFile: [],
                        id: getRandomNumber(),
                    };
                    otherAwards.push(optionsItem);
                    item.id = optionsItem.id;
                }
            });
            setTimeout(() => {
                this.options = this.options.concat(otherAwards);
                this.result.awards = backResult.awards.map(item => item.id);
                console.log('------result.value', this.result.awards);
            }, 1000);
            if (backResult?.designItems?.length) {
                this.indesignItems = backResult?.designItems.map((item, index) => {
                    return {
                        value: item,
                    };
                });
            } else {
                this.indesignItems = [{ value: '' }];
            }

            this.awardsImgs = backResult.awards || [];
            setTimeout(() => {
                this.baseInfoInit = true;
            }, 1000);
        },

        handleAdd() {
            this.qualifications.push({ value: null });
        },

        handleInput(val, index) {
            this.indesignItems[index].errorType = false;
        },
        handleAddDesignItems() {
            if (this.query.type == 'disabled') return;
            this.indesignItems.push({
                errorType: false,
            });
        },
        handleDel(index) {
            if (this.query.type == 'disabled') return;
            if (index == 0) return;
            this.qualifications.splice(index, 1);
        },
        handleDelDesignItems(index) {
            if (this.query.type == 'disabled') return;
            if (index == 0) return;
            console.log('------------111', index);
            this.indesignItems.splice(index, 1);
        },
        validateAll() {
            return new Promise((resolve, reject) => {
                let isSuccess = true;
                this.indesignItems.forEach(item => {
                    if (!item.value) {
                        item.errorType = true;
                        isSuccess = false;
                    }
                });
                if (isSuccess) {
                    resolve(isSuccess);
                } else {
                    reject(isSuccess);
                }
            });
        },
        resetForm() {
            this.indesignItems = [{}];
        },
        returnData() {
            let _industryQualifications = this.qualifications
                .map(item => item.value)
                .filter(d => d);
            let _designItems = this.indesignItems.map(item => (item.value ? item.value : null));
            let awards = this.awardsImgs.filter(d => d);
            return {
                designItems: _designItems,
                industryQualifications: _industryQualifications,
                awards: awards,
            };
        },

        onAddOption() {
            this.isAddAward = true;
        },
        handleRemoveTag(val) {
            console.log(val, 'val----');
            this.deleteListId = val;
        },
        handleChangeAwards(val) {
            console.log(val, 'val----');
            const getCheckedNodes = this.$refs.cascaderRef.getCheckedNodes();
            let arr = [];
            getCheckedNodes.map(item => {
                if ((item.level != 1 || item.data.isCustom) && item.value != this.deleteListId) {
                    arr.push(item);
                }
            });
            console.log(getCheckedNodes, 'getCheckedNodes');
            this.updateArr1(arr);
        },
        updateArr1(checkedValues) {
            const tempMap = new Map();
            for (const item of checkedValues) {
                tempMap.set(item.value, {
                    id: item.value,
                    content: item.label,
                    contentFile: [],
                });
            }
            this.awardsImgs = this.awardsImgs
                .filter(item => {
                    return tempMap.has(item.id);
                })
                .map(item => {
                    const newItem = tempMap.get(item.id);
                    newItem.contentFile = item.contentFile || [];
                    return newItem;
                });

            for (const [value, newItem] of tempMap.entries()) {
                if (!this.awardsImgs.some(item => item.id === value)) {
                    this.awardsImgs.push(newItem);
                }
            }
        },

        // 荣誉奖项自定义添加
        handleConfirmAwards() {
            if (this.awardName.trim()) {
                let optionsItem = {
                    content: this.awardName,
                    isCustom: true, // 自定义0 是, 1否
                    contentFile: [],
                    id: getRandomNumber(),
                };
                this.result.awards.push(optionsItem.id);
                this.options.push(optionsItem);
                this.awardsImgs.push(optionsItem);
            }
            this.isAddAward = false;
            this.awardName = '';
        },
        handleClearAwards() {
            this.awardName = '';
            this.isAddAward = false;
        },
        handleClear() {},
        sendFileList(file, index) {
            console.log(file, index);
            this.awardsImgs[index].contentFile = file;
        },
    },
    mounted() {
        if (this.$router?.query) {
            this.query = { ...this.$router.query };
        }
    },
};
</script>
<style lang="less" scoped>
.project {
    display: flex;
}

/deep/ .el-form-item__label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    text-align: justify;
    font-style: normal;
}
.flex {
    display: flex;
    align-items: center;
}
.label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    text-align: justify;
    font-style: normal;
}
.must {
    width: 5px;
    height: 5px;
    background: #ff0200;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 8px;
}
.award-input {
    padding-left: 30px;
    padding-right: 20px;
    width: 200px;
}
.add {
    width: 88px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #407fff;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
    span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #407fff;
        line-height: 36px;
        text-align: justify;
        font-style: normal;
    }
}
.add-disabled {
    color: #ccc;
    border-color: #ccc;
    img {
        filter: brightness(0.1);
    }
    span {
        color: #ccc;
    }
}
.is-error {
    border-color: red;
}
</style>
