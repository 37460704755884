<template>
    <el-dialog
        title="新增标签组"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="35%"
        :before-close="handleClose"
    >
        <sc-form-v2
            :columns-num="1"
            :columns="columns"
            :result="form"
            :esay-style="true"
            tablename="createTag"
            :columns-padding-right="10"
            direction="vertical"
            @click="clickFn"
            ref="form"
        >
            <template v-slot:orderSortData>
                <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            排序
                            <span style="color: red">*</span>
                        </div>
                        <el-input
                            v-model.number="form.orderSort"
                            style="width: 100px"
                            type="number"
                            size="mini"
                        ></el-input>
                    </template>
                    <div style="text-align: left"></div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { editgroup } from '@/api/mkt/tag-group';
export default {
    name: 'create-tag-group',
    props: {
        createTagShow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogVisible: false,
            columns: [
                {
                    key: 'groupName',
                    name: '标签组名称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        placeholder: '请输入',
                        maxlength: 12,
                        showWordLimit: true,
                    },
                    spanCol: 1,
                    rules: [{ required: true, message: '请输入' }],
                },
                {
                    key: 'remark',
                    name: '备注',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        placeholder: '请输入',
                        maxlength: 80,
                        showWordLimit: true,
                    },
                    spanCol: 1,
                },
                {
                    key: 'orderSortData',
                    name: '排序',

                    rules: [{ required: true, message: '请输入' }],
                },
            ],

            form: {
                groupName: '',
                remark: '',
                orderSort: 0,
            },
        };
    },

    watch: {
        createTagShow: {
            handler(val) {
                this.dialogVisible = val;
            },
            immediate: true,
        },
    },

    async created() {},
    methods: {
        // 确定
        onSubmit() {
            this.$refs.form.validateAll('createTag', () => {
                this.goToSave();
            });
        },

        async goToSave() {
            let params = {
                groupName: this.form.groupName,
                remark: this.form.remark,
                orderSort: this.form.orderSort,
                groupType: 1,
            };

            let { code } = await editgroup(params);
            if (code === 200) {
                this.$message.success('添加成功！');
                this.dialogVisible = false;
                this.$emit('onCloseCreateTag', { labelGroupId: this.form.groupName });
            }
        },

        // 取消
        handleClose() {
            this.dialogVisible = false;
            this.$emit('onCloseCreateTag');
        },

        clickFn(val) {},
    },
};
</script>

<style lang="less" scoped>
/deep/.percen-box {
    width: 200px;
    .progress-bar {
        width: 200px;
    }
}
</style>
