<template>
    <div>
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        >
            <template v-slot:searchName>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">列表搜索：</div>
                    </template>
                    <div style="text-align: left">
                        <el-select
                            size="small"
                            placeholder="用户姓名"
                            v-model="result.searchType"
                            style="width: 26%"
                            clearable
                        >
                            <template>
                                <el-option key="name" label="用户姓名" value="1"></el-option>
                                <el-option key="name" label="艺名" value="4"></el-option>
                                <el-option key="account" label="BPM账号" value="3"></el-option>
                                <!-- <el-option key="type" label="城市" value="2"></el-option> -->
                            </template>
                        </el-select>
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="result.searchName"
                            size="small"
                            style="width: 70%; margin-left: 10px"
                            clearable
                        ></el-input>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { getRolesList } from '@/api/mkt/user.js';

export default {
    mixins: [mixins],
    data() {
        return {
            columns: {
                searchName: {
                    name: '插槽占位',
                    type: 'text',
                    spanCol: 2, //如果插槽占位，需要跟插槽中span一致
                },
                firstLoginTime: {
                    name: '首次登录时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入创建时间',
                    },
                    spanCol: 2,
                },
                Identification: {
                    name: '用户类型',
                    type: 'select',
                    options: {
                        defaultValueBase: '1',
                        placeholder: '用户类型',
                        options: [
                            { label: '内部员工', value: '2' },
                            { label: '潜在客户', value: '4' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 2,
                },
                role: {
                    name: '角色',
                    type: 'select',
                    options: {
                        defaultValue: [],
                        clearable: true,
                        multiple: true,
                        placeholder: '角色',
                        options: [],
                        props: {
                            value: 'id',
                            label: 'label',
                        },
                    },
                    spanCol: 2,
                },
                rolelist: {},
            },
            result: {
                searchType: '',
                searchName: '',
                Identification: '',
                firstLoginTime: [],
                role: [],
                // labels: [],
                // labelsArr: [],
            },
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
            deleteData: '',
            roleList: [],
        };
    },
    watch: {
        // searchLabelOptions(val) {
        //     if (val) {
        //         this.columns.labels.options.options = val;
        //     }
        // },
    },
    created() {
        this.getSearchTagList();
        this.getRolesList();
    },
    methods: {
        async getRolesList() {
            const res = await getRolesList();
            if (res?.isSuccess) {
                for (let i in this.columns) {
                    if (i == 'role') {
                        this.columns[i].options.options = res.data;
                        this.roleList = res.data;
                    }
                }
            }
        },
        changeFn(e) {
            if (e.row.key == 'firstLoginTime') {
                this.result.bDate = e.value[0] + ' 00:00:00';
                this.result.eDate = e.value[1] + ' 23:59:59';
            }

            // if (e.row.key == 'labels') {
            //     // let newLabelArr = this.changeLabel(e.nodeData, this.deleteData);
            //     this.result.labelsArr = this.disposeData(e.nodeData);
            // }
        },

        // disposeData(arr) {
        //     return arr
        //         .map(item => {
        //             return item.data.id;
        //         })
        //         .toString();
        // },
        // removeTag(val) {
        //     this.deleteData = val.value;
        // },
        clickFn(e) {
            let obj = JSON.parse(JSON.stringify(this.result));
            delete obj['firstLoginTime'];
            delete obj['role'];
            let params = {
                ...obj,
            };
            if (this.result.searchName && !this.result.searchType) {
                params.searchType = '1';
            }
            if (!this.result.searchName) {
                params.searchType = '';
            }
            if (this.result.role?.length) {
                params.role = this.result.role + '';
            }
            // delete params.k2;
            console.log('params', params);

            this.$store.commit('mkt-user/setParams', params); //同步方法保存
            this.$store.dispatch('mkt-user/list');
        },
    },
};
</script>
<style scoped>
/deep/.cascader .el-tag__close {
    display: none !important;
}
</style>
