<template>
    <div>
        <el-drawer
            title="审核笔记"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="30%"
            :wrapperClosable="false"
        >
            <sc-form-v2
                tablename="BESIC"
                name="基本信息"
                :columnsNum="1"
                :result="besicResult"
                :columns="besicColumns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                ref="articleFrom"
            >
                <template v-slot:files>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">图片</div>
                        </template>
                        <div style="text-align: left">
                            <div
                                v-for="(item, ind) in besicResult.files"
                                :key="ind"
                                class="img-list"
                            >
                                <el-image
                                    v-if="item.fileType != 2"
                                    class="img"
                                    :src="item.url"
                                    fit="contain"
                                    style="margin-right: 10px"
                                    :preview-src-list="srcList"
                                ></el-image>
                                <div>{{ item.spaceName }}</div>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>

            <div style="text-align: right; margin-bottom: 20px">
                <el-button size="mini" style="margin-right: 20px" @click="close">取消</el-button>
                <el-button
                    size="mini"
                    style="margin-right: 20px"
                    @click="handleNoPass"
                    type="danger"
                    v-if="localRow.approvalStatus == 1"
                >
                    驳回
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="passbtn"
                    v-if="localRow.approvalStatus == 1"
                >
                    通过
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="submit('3')"
                    v-if="localRow.approvalStatus == 3"
                >
                    确定
                </el-button>
            </div>
        </el-drawer>
        <!-- 点击驳回, 展示弹窗 -->
        <dialog-remark ref="remark" @remarkBak="remarkBak"></dialog-remark>
        <pass-dialog
            ref="passdialog"
            @remarkBak="remarkBak"
            @closeDrawer="closeDrawer1"
        ></pass-dialog>
    </div>
</template>

<script>
import dialogRemark from './dialog-remark.vue';
import passDialog from './pass-dialog.vue';

import mixins from '@/mixins';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-content-article-group');
import { info, approval } from '@/api/mkt/article';
export default {
    name: 'detailDrawer',
    mixins: [mixins],
    components: { dialogRemark, passDialog },
    data() {
        return {
            remark: '',
            localRow: {},
            drawer: false,
            direction: 'rtl',
            worksInfo: {
                labels: [],
                labelsAll: [],
                operationIdsAll: [],
                operationIds: [],
                categoryId: '',
            },
            besicResult: {},
            besicColumns: [
                {
                    key: 'title',
                    name: '笔记名称',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'files',
                    name: '图片',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'content',
                    name: '描述',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'topicName',
                    name: '关联话题',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
            ],
            // suppleColumns: [
            //     {
            //         key: 'categoryId',
            //         name: '分类',
            //         type: 'cascader',
            //         options: {
            //             placeholder: '最多选择20个',
            //             allLevels: false,
            //             clearable: true,
            //             options: [],
            //             props: {
            //                 emitPath: false,
            //                 value: 'value',
            //                 label: 'label',
            //             },
            //         },
            //         // rules: [{ required: true, message: '请选择所属分类' }],
            //     },
            //     {
            //         key: 'operationIdsAll',
            //         name: '运营标签',
            //         type: 'cascader',
            //         options: {
            //             placeholder: '最多选择20个',
            //             allLevels: false,
            //             clearable: true,
            //             options: [],
            //             props: {
            //                 label: 'labelName',
            //                 value: 'id',
            //                 checkStrictly: true,
            //                 emitPath: false,
            //                 multiple: true,
            //             },
            //         },
            //     },
            //     {
            //         key: 'labelsAll',
            //         name: '标签',
            //         type: 'cascader',
            //         options: {
            //             placeholder: '最多选择20个',
            //             allLevels: false,
            //             clearable: true,
            //             options: [],
            //             props: {
            //                 label: 'labelName',
            //                 value: 'id',
            //                 checkStrictly: true,
            //                 emitPath: false,
            //                 multiple: true,
            //             },
            //         },
            //         // rules: [{ required: true, message: '请选择标签' }],
            //     },
            // ],
            srcList: [],
            deleteData: '',
        };
    },
    watch: {
        cloumnsList(val) {
            if (val) {
                this.suppleColumns.map(item => {
                    if (item.key == 'categoryId') {
                        item.options.options = val;
                    }
                });
            }
        },
        normalLabelList(val) {
            if (val) {
                this.suppleColumns.map(item => {
                    if (item.key == 'labelsAll') {
                        item.options.options = val;
                    }
                });
            }
        },
        operationList(val) {
            if (val) {
                this.suppleColumns.map(item => {
                    if (item.key == 'operationIdsAll') {
                        item.options.options = val;
                    }
                });
            }
        },
    },
    created() {
        // this.getColumn();
        // this.getOperationList();
        // this.getLabelCommonList();
    },
    methods: {
        changeFn(val) {
            if (val.row.key == 'categoryId') {
                this.worksInfo.categoryName = val.nodeData && val.nodeData[0].label;
            }
            if (val.row.key == 'labelsAll') {
                if (this.worksInfo.labelsAll?.length > 20) {
                    this.$message.warning('最多选择20个标签');
                    this.worksInfo.labels = this.worksInfo.labelsAll.slice(0, 20);
                }
                this.worksInfo.labels = this.changeLabel(val.nodeData, this.deleteData);
            }
            if (val.row.key == 'operationIdsAll') {
                if (this.worksInfo.operationIdsAll?.length > 20) {
                    this.$message.warning('最多选择20个标签');
                    this.worksInfo.operationIds = this.worksInfo.operationIdsAll.slice(0, 20);
                }
                this.worksInfo.operationIds = this.changeLabel(val.nodeData, this.deleteData);
            }
        },
        handleNoPass() {
            // 驳回
            this.$refs.remark.open();
        },

        removeTag(val) {
            this.deleteData = val.value;
        },
        handleChangeCloumn(val) {
            this.worksInfo.categoryId = val;
        },
        changeLabel(arr, deleteData) {
            // 多选删除事件的时候需要手动删除处理
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理成后端需要的数据得数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        passbtn() {
            this.$refs.passdialog.open(this.localRow, 0);
        },
        submit(str) {
            let params = {
                approvalStatus: str == 4 ? 4 : 3, // 3 审核通过  4 , 审核退回
                categoryId: this.worksInfo.categoryId,
                labels: this.worksInfo.labels,
                articleId: this.worksInfo.articleId,
                operationLabels: this.worksInfo.operationIds,
                approvalRemarks: str == 4 ? this.remark : null,
            };
            approval(params).then(res => {
                if (res.errorCode == 200) {
                    this.close();
                } else {
                    this.$message.info(res.message);
                }
            });
        },
        remarkBak(remark) {
            this.remark = remark;
            this.submit('4');
        },
        openDrawer(row) {
            console.log(row);
            this.drawer = true;

            // this.worksInfo.categoryId = [];
            this.localRow = { ...row };
            if (row) {
                this.showInfo(row);
            }
        },
        closeDrawer1() {
            this.drawer = false;
            this.$emit('closeDrawer');
        },
        close() {
            this.drawer = false;
            this.worksInfo.categoryId = '';
            this.worksInfo.categoryName = '';
            this.worksInfo.operationIds = [];
            this.worksInfo.labels = [];
            this.worksInfo.labelsAll = [];
            this.worksInfo.operationIdsAll = [];
            this.$emit('closeDrawer');
        },
        handleClose(done) {
            this.worksInfo.labelsAll = [];
            this.worksInfo.operationIdsAll = [];
            done();
        },
        // 处理后端需要的数据
        changLabelArr(arr) {
            if (arr.length > 0) {
                arr.map(item => {
                    item.labelId = item.id;
                    delete item.id;
                });
                return arr;
            } else {
                return [];
            }
        },
        showInfo(row) {
            info({ articleId: row.articleId }).then(res => {
                this.besicResult = res.data;
                // 图片预览数组处理
                this.srcList = res?.data?.files.map(item => {
                    return item.url;
                });
                this.worksInfo.articleId = row.articleId;

                this.besicResult.topicName = res?.data?.topicParamList.map(item => {
                    return item.topicName;
                });
                this.besicResult.topicName = this.besicResult.topicName.join(',');
                // this.worksInfo.categoryId = res.data.categoryId || '';
                // // 标签数据回显
                // this.worksInfo.labelsAll = res?.data?.labels.map(item => {
                //     return item.id;
                // });
                // // 运营标签数据回显
                // this.worksInfo.operationIdsAll = res?.data?.operationLabels.map(item => {
                //     return item.id;
                // });
                // // id转成labelId
                // this.worksInfo.labels = this.changLabelArr(res.data.labels);
                // this.worksInfo.operationIds = this.changLabelArr(res.data.operationLabels);
            });
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .self-drawer {
    padding: 0 20px;
}
.img-list {
    float: left;
    .img {
        width: 50px;
        height: 50px;
    }
}
/deep/ .texts {
    padding-left: 0px !important;
    .texts-d {
        font-size: 12px !important;
    }
}

/deep/ .el-descriptions__title {
    font-size: 14px;
}
/deep/ .el-divider--horizontal {
    margin: 0;
}
</style>
