<template>
    <div>
        <el-drawer
            title="编辑"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
            custom-class="self-drawer"
            size="40%"
            :wrapperClosable="true"
            :destroy-on-close="true"
        >
            <div v-if="type != '案例'">
                <sc-search-v2
                    name="案例图库的搜索"
                    :columns="columns"
                    :columnsNum="2"
                    :clientFalg="false"
                    :result.sync="result"
                    :LS="LS"
                    :btn="btn"
                    :size="size"
                    @change="changeFn"
                    @search="clickFn"
                    ref="formWorkCollect"
                >
                    <template v-slot:spaceId>
                        <el-descriptions-item span="1">
                            <template slot="label">
                                <div class="labelName">空间：</div>
                            </template>
                            <div style="text-align: left">
                                <el-cascader
                                    v-model="result.spaceId"
                                    size="small"
                                    :options="coverList"
                                    placeholder="请选择"
                                    :props="{
                                        label: 'spaceName',
                                        value: 'id',
                                        checkStrictly: true,
                                        emitPath: false,
                                    }"
                                    :show-all-levels="false"
                                    ref="spaceRef"
                                    clearable
                                ></el-cascader>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <template v-slot:label>
                        <el-descriptions-item span="1">
                            <template slot="label">
                                <div class="labelName">标签：</div>
                            </template>
                            <div style="text-align: left">
                                <el-cascader
                                    v-model="result.label"
                                    size="small"
                                    :options="labelOptions"
                                    placeholder="请选择"
                                    :props="{
                                        label: 'labelName',
                                        value: 'id',
                                        checkStrictly: true,
                                        emitPath: false,
                                    }"
                                    :show-all-levels="false"
                                    ref="cascader"
                                    clearable
                                ></el-cascader>
                            </div>
                        </el-descriptions-item>
                    </template>

                    <template v-slot:companyCode>
                        <el-descriptions-item span="1">
                            <template slot="label">
                                <div class="labelName">分公司：</div>
                            </template>
                            <div style="text-align: left">
                                <el-select
                                    v-model="result.companyCode"
                                    placeholder="请选择"
                                    clearable
                                >
                                    <el-option
                                        v-for="item in companyData"
                                        :key="item.ouCode"
                                        :label="item.ouName"
                                        :value="item.ouCode"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-descriptions-item>
                    </template>
                </sc-search-v2>
            </div>
            <div style="text-align: left; padding-left: 30px;margin:10px 0;">
                <el-button size="mini" type="primary" @click="handleSetData">
                    {{ show ? '案例组图模式' : '仅灵感图模式' }}
                </el-button>
                <!-- <el-button size="mini" @click="handleSetData"></el-button> -->
            </div>
            <!-- 展示案例 -->
            <div v-if="show" style="padding: 0 30px;">
                <el-card
                    class="box-card"
                    v-for="(item, pindex) in list"
                    :key="item.id"
                    style="margin-bottom:30px;"
                >
                    <div slot="header" class="clearfix">
                        <div class="title">
                            <div class="label label1">案例名称: {{ item.title }}</div>
                            <div class="label label2">案例ID: {{ item.id }}</div>
                            <div class="label label3">设计师: {{ item.createName }}</div>
                            <!-- <div class="label">项目地址: {{ item.projectAddress }}</div> -->
                        </div>
                    </div>

                    <div class="wrap" v-if="item.worksFileVos && item.worksFileVos.length">
                        <div v-for="(items, ind) in item.worksFileVos" :key="ind">
                            <el-checkbox
                                v-model="items.checked"
                                class="checkbox"
                                @change="handleChangeCheckbox(ind, pindex)"
                            ></el-checkbox>
                            <el-image
                                style="width: 80px; height:80px"
                                :src="
                                    items.url +
                                        '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                                "
                                :preview-src-list="[items.url]"
                                :lazy="true"
                            ></el-image>

                            <p>{{ items.spaceName }}</p>
                        </div>
                    </div>
                    <!-- <div v-else style="padding-left:20px;">暂无图片数据</div> -->
                </el-card>
            </div>
            <div class="img-list" v-if="!show">
                <div class="list-for">
                    <div v-for="(items, ind) in localArr" :key="ind" style="margin-bottom:30px;">
                        <el-checkbox
                            v-model="items.checked"
                            class="checkbox"
                            @change="handleChangeCheckboxPicture(ind)"
                        ></el-checkbox>
                        <el-image
                            style="width: 80px; height: 80px;"
                            :src="
                                items.url +
                                    '?x-oss-process=image/resize,m_fixed,h_100/format,jpg/quality,q_75'
                            "
                            :preview-src-list="[items.url]"
                            :lazy="true"
                        ></el-image>
                        <p>{{ items.spaceName }}</p>
                    </div>
                </div>
            </div>

            <div class="page-wrap" v-if="false">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 30, 50, 100, 200]"
                    :page-size="page.size"
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>

            <div class="footer">
                <el-button size="small" @click="handleCancle">取消</el-button>
                <el-button type="primary" size="small" @click="submit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import mixins from '@/mixins';
import { coverList, getCoverList } from '@/api/mkt/exhibit-group';

export default {
    name: 'exhibit-group-search',
    mixins: [mixins],
    props: {
        limitNum: {
            type: Number,
            default: 9,
        },
        selectList: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            drawer: false,
            currentPage: 1,
            show: true,
            list: [{ worksFileVos: [{ checked: false }] }],
            page: { page: 1, size: 10 },
            total: 0,
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            ],
            imgLists: [],
            checked: false,
            columns: {
                contentId: {
                    name: '案例ID',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入案例ID',
                    },
                },
                contentName: {
                    name: '案例名称',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                },
            },
            result: {
                contentId: '',
                contentName: '',
            },
            LS: { 'min-width': '100px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: true,
            checkedList: [],
            localArr: [],
            cacheParams: {},
            addContentIds: [], // 带图片+案例id的数组
            type: '',
        };
    },
    created() {
        // this.init();
        this.getTagList();
        // this.getSpaceList();
        this.getCompany();
    },
    methods: {
        async openDrawer(row, type) {
            this.drawer = true;
            this.cacheParams = { ...row };
            this.result = {
                contentId: '',
                contentName: '',
            };
            if (type == '案例') {
                this.type = type;
                this.getCoverList(row);
            } else {
                this.init(row);
            }
        },

        getCoverList(row) {
            getCoverList({
                contentId: row.contentId,
            }).then(res => {
                // 通过url字段回显
                const selectMap = new Map(this.selectList.map(select => [select.url, true]));
                let arr = [];
                res.data.forEach(item => {
                    item.worksFileVos.forEach(items => {
                        items.checked = selectMap.get(items.url);
                        arr.push(items);
                    });
                });
                this.list = res.data;
                this.localArr = arr;
            });
        },
        handleClose() {
            this.drawer = false;
        },
        handleCancle() {
            this.drawer = false;
        },
        // 案例选择模式
        handleChangeCheckbox(index, pIndex) {
            console.log(this.list, '当前列表所有数据');
            // 前端的处理, 先不要外层的id, 只传图片带的id
            let _list = [];
            let addContentIds = [];
            console.log('-------------limitNum', this.limitNum == 1, this.limitNum);
            if (this.limitNum == 1) {
                this.list.map(item => {
                    item.worksFileVos.map(items => {
                        items.checked = false;
                    });
                });
                this.list[pIndex].worksFileVos[index].checked = true;
            }
            this.list.map(item => {
                item.worksFileVos.map(items => {
                    if (items.checked) {
                        _list.push(items);
                        addContentIds.push({
                            url: items,
                            contentId: item.id,
                        });
                    }
                });
            });
            this.checkedList = _list;
            this.addContentIds = addContentIds;
            this.$forceUpdate();
        },
        // 组图选择模式
        handleChangeCheckboxPicture(index) {
            console.log(this.list, '当前列表所有数据');
            // 前端的处理, 先不要外层的id, 只传图片带的id
            let _list = [];
            let addContentIds = [];
            if (this.limitNum == 1) {
                this.localArr.map(item => {
                    item.checked = false;
                });
                this.localArr[index].checked = true;
            }
            this.localArr.map(items => {
                if (items.checked) {
                    _list.push(items);
                    addContentIds.push({
                        url: items,
                        contentId: items.designWorksId,
                    });
                }
            });

            this.checkedList = _list;
            this.addContentIds = addContentIds;
        },
        submit() {
            if (!this.checkedList.length) {
                this.$message.info('请至少选择一个');
                return;
            }
            if (this.checkedList.length > this.limitNum) {
                this.$message.info(`最多选择${this.limitNum}张`);
                return;
            }
            this.$emit('innerDrawerSubmit', this.checkedList, this.addContentIds);
            this.$nextTick(() => {
                this.list.map(item => {
                    item.worksFileVos.map(items => {
                        items.checked = false;
                    });
                });
            });
            this.handleClose();
        },
        // 设计师榜选择封面列表接口
        init() {
            var param = {};
            let obj = {
                ...this.result,
                spaceId: this.result.spaceId ? this.result.spaceId.toString() : '',
                ...this.cacheParams,
            };
            for (var key in obj) {
                if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                    param[key] = obj[key];
                }
            }
            coverList(param)
                .then(res => {
                    console.log(res, 100000);
                    this.total = res.data.total;
                    const selectMap = new Map(this.selectList.map(select => [select.url, true]));
                    let arr = [];
                    res.data.forEach(item => {
                        item.worksFileVos.forEach(items => {
                            items.checked = selectMap.get(items.url);
                            arr.push(items);
                        });
                    });
                    this.list = res.data;
                    this.localArr = arr;
                    this.$forceUpdate();
                })
                .catch(err => {
                    console.log(err, 11111111111);
                });
        },
        handleSetData() {
            //切换组图类
            this.show = !this.show;
        },
        changeFn(data) {
            console.log(data);
            this.init({ ...this.result });
        },
        clickFn(data, type) {
            console.log(data, type);
            if (type == 'clear') {
                this.result = {
                    ...this.cacheParams,
                };
            }
            if (this.type == '案例') {
                this.getCoverList({ contentId: this.cacheParams.contentId });
            } else {
                this.init({ ...this.result });
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page.size = val;
            this.init({ ...this.page });
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page.page = val;
            this.init({ ...this.page });
        },
    },
};
</script>
<style lang="less" scoped>
.title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .label {
        margin-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .label1 {
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .label2 {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .lalbel3 {
        width: 120px;
    }
}
.list-for {
    display: flex;
    flex-flow: wrap;
}
.wrap,
.list-for {
    display: flex;
    flex-flow: wrap;
    padding-left: 20px;
    > div {
        margin-right: 20px;
        position: relative;
        .checkbox {
            position: absolute;
            right: 3px;
            top: 0;
            z-index: 10;
        }
    }
}
.page-wrap,
.footer {
    display: flex;
    justify-content: flex-end;
}
.footer {
    margin-top: 10px;
    margin-right: 50px;
}
/deep/ .el-input__icon.el-input__validateIcon.el-icon-circle-close {
    display: none;
}
</style>
