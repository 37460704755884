<template>
    <div class="mkt" ref="table">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            :columnsNum="4"
            :moreDown="false"
            @change="searchChangeFn"
            @search="clickFn"
            @click-more="handleClickMore"
            ref="form1"
        >
            <template v-slot:scanTotal>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">扫码总数：</div>
                    </template>
                    <div class="labelContent">{{ scanTotalNum }}</div>
                </el-descriptions-item>
            </template>
            <template v-slot:authorizeTotal>
                <el-descriptions-item span="1">
                    <template slot="label">
                        <div class="labelName">授权总数：</div>
                    </template>
                    <div class="labelContent">{{ authorizeTotal }}</div>
                </el-descriptions-item>
            </template>
        </sc-search-v2>
        <sc-table-v2
            :head="tableHead"
            :data="tableData"
            :page="page"
            :page-hide="false"
            :pop-show="false"
            :show-add="false"
            :show-add-config="tableAddConfig"
            :table-height="tableHeightCalc"
            :read-table="true"
            :load="isLoading"
            @change="changeFn"
            @click="clickFn"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @rowChange="rowChange"
            @dbClick="dbClick"
            @rowClick="rowClick"
            @selection-change="selectionChange"
        ></sc-table-v2>
    </div>
</template>
<script>
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';

import { getCustomerDataStatisticsList, getCustomerDataStatisticsTotal } from '@/api/mkt/user.js';
export default {
    name: 'data-statistics',
    mixins: [mixins, getListMixin],
    components: {},
    data() {
        return {
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '90px' }, //设置label宽度
            scanTotalNum: 0,
            authorizeTotal: 0,

            tableHead: [
                { name: '类别', key: 'sourceName' },
                { name: '扫码人数', key: 'scanNumber' },
                { name: '授权人数', key: 'authorizeNumber' },
                { name: '扫码日期', key: 'createTime' },
            ],
            columns: {
                time: {
                    name: '时间筛选',
                    type: 'date',
                    options: {
                        startPlaceholder: '开始日期',
                        endPlaceholder: '结束日期',
                        separator: '至',
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入创建时间',
                    },
                    spanCol: 2,
                },

                scanTotal: {
                    name: '扫码总数',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    spanCol: 1,
                },
                authorizeTotal: {
                    name: '授权总数',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                    spanCol: 1,
                },
            },
            result: {
                startTime: '',
                endTime: '',
                time: [],
            },
            tableData: [],
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleClickMore() {},

        init() {
            this.getDataList();
            this.getTotalNumber();
        },
        getDataList() {
            const params = {
                startTime: this.result.startTime,
                endTime: this.result.endTime,
                page: this.page.page,
                size: this.page.size,
            };
            getCustomerDataStatisticsList(params).then(res => {
                this.tableData = res.data.children || [];
                this.page.count = res.data.total;
            });
        },
        getTotalNumber() {
            const params = {
                startTime: this.result.startTime,
                endTime: this.result.endTime,
            };
            getCustomerDataStatisticsTotal(params).then(res => {
                this.scanTotalNum = res.data.scanNumber;
                this.authorizeTotal = res.data.authorizeNumber;
            });
        },

        rowClick() {},
        dbClick() {},
        click() {},
        searchChangeFn(data) {
            if (data.row.key == 'time') {
                if (data.value && data.value.length) {
                    this.result.startTime = data.value[0] + ' 00:00:00';
                    this.result.endTime = data.value[1] + ' 23:59:59';
                } else {
                    this.result.startTime = '';
                    this.result.endTime = '';
                }
            }
        },
        changeFn(row, key) {
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                for (const key in this.result) {
                    if (typeof this.result[key] != 'string') {
                        this.result[key] = '';
                    }
                }
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
        pageSizesChange(val) {
            this.page.size = val;
            this.init();
        },
    },
};
</script>
<style lang="less" scoped>
.labelContent {
    text-align: left;
    font-size: 12px;
    margin-top: 6px;
}
.labelName {
    font-size: 12px;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
