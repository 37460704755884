<template>
    <div class="mkt" ref="table">
        <div class="tabs">
            <div class="btns" style="">
                <el-button size="small" type="primary" @click="onEdit()">新增合集</el-button>
            </div>

            <div>
                <sc-table-v2
                    class="list-table"
                    :head="tableHeadGroup"
                    :data="listData"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="contentDbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:deag>
                        <el-table-column label="" width="60" align="center">
                            <i class="el-icon-sort icon" style="font-size:20px;"></i>
                        </el-table-column>
                    </template>
                    <template v-slot:url>
                        <el-table-column label="背景图">
                            <template slot-scope="scope">
                                <el-image
                                    :src="scope.row.url"
                                    fit="contain"
                                    style="width: 50px; height: 50px"
                                    :preview-src-list="[scope.row.url]"
                                ></el-image>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:up>
                        <el-table-column label="是否上架">
                            <template slot-scope="scope">
                                <el-switch
                                    size="small"
                                    v-model="scope.row.isShelf"
                                    active-text="上架"
                                    inactive-text="下架"
                                    :active-value="0"
                                    :inactive-value="1"
                                    @change="handleChangeIsShelf(scope.row)"
                                ></el-switch>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="130">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="contentDbClick(scope.row)"
                                >
                                    配置
                                </el-button>
                                <el-button type="text" size="small" @click="onEdit(scope.row)">
                                    编辑
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    style="margin-left:10px;"
                                    @click="handleDelete(scope.row, 'second')"
                                >
                                    <span style="color:red;">删除</span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>

        <edit-group-dialog ref="editGroupDialog" @handleClose="handleClose"></edit-group-dialog>
        <edit-ranking-dialog
            ref="editRankingDialog"
            @handleClose="handleClose"
        ></edit-ranking-dialog>
        <allocation ref="allocation"></allocation>
    </div>
</template>

<script>
import editRankingDialog from './components/ranking-detail.vue';
import editGroupDialog from './components/group-detail.vue';
import allocation from './components/allocation.vue';
import Sortable from 'sortablejs';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import { debounce } from '@/utils/util';
import {
    getGroupRowList,
    getRankingRowList,
    delGroupRow,
    delRankingRow,
    collectionShelf,
    collectionSort,
} from '@/api/mkt/home-group.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editRankingDialog, editGroupDialog, allocation },
    data() {
        return {
            // tabActive: 'first',
            tableHandle: getGroupRowList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [],
            tableHeadGroup: [
                { name: '1', key: 'deag' },
                {
                    name: 'ID',
                    key: 'id',
                    width: '156px',
                },
                { name: '背景图', key: 'url' },
                { name: '合集名称', key: 'collectionName' },
                { name: '排序值', key: 'orderSort' },
                { name: '上下架', key: 'up' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            tableHeadRanking: [
                { name: '榜单名称', key: 'designerName' },
                { name: '榜单说明', key: 'description' },
                { name: '排序值', key: 'orderSort' },
                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            tableAddConfig: {},
            page: {
                size: 30, //每页多少条数据
                page: 1, //当前页码
                count: 0, //数据总条数
                index: 1,
            },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // if (this.$route.params.tabActive) {
        //     this.tabActive = this.$route.params.tabActive;
        //     this.tabClick({ name: this.tabActive });
        // }
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },

    mounted() {
        const tbody = document.querySelector('.list-table tbody');
        let that = this;
        Sortable.create(tbody, {
            handle: '.icon', // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
            onEnd: debounce(function({ newIndex, oldIndex }) {
                if (newIndex != oldIndex) {
                    that.listData.splice(newIndex, 0, that.listData.splice(oldIndex, 1)[0]);
                    var newArray = that.listData.slice(0);
                    that.listData = [];
                    that.$nextTick(function() {
                        that.listData = newArray.map((item, ind) => {
                            return {
                                ...item,
                                orderSort: ind,
                            };
                        });
                        // 拖拽事件结束
                        let list = [];
                        that.listData.map(item => {
                            list.push(item.id);
                        });
                        collectionSort(list);
                        console.log('move', newIndex, oldIndex, that.listData);
                    });
                }
            }, 500),
        });
    },

    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleChangeIsShelf(row) {
            collectionShelf({
                id: row.id,
                shelf: row.isShelf,
            }).then(res => {
                if (res.isSuccess) {
                    this.$message.success(res.message);
                    this.init();
                } else {
                    row.isShelf = !row.isShelf;
                }
            });
        },
        handleClickMore() {},
        handleClose() {
            this.init();
        },
        init() {
            this.queryDataMixin();
        },
        // tabClick(tab, event) {
        //     // this.tabActive = tab.name;
        //     // if (tab.name == 'first') {
        //     //     this.page.page = 1;
        //     //     this.tableHandle = getRankingRowList;
        //     // }
        //     // if (tab.name == 'second') {
        //     //     this.page.page = 1;
        //     //     this.tableHandle = getGroupRowList;
        //     // }

        //     this.page.page = 1;
        //     this.tableHandle = getGroupRowList;

        //     this.queryDataMixin();
        // },
        designersAllocation(row) {
            this.$refs.allocation.openDrawer();
        },
        allocation(row) {
            this.$router.push({ path: `content-group?id=${row.id}` });
        },
        onEdit(row) {
            console.log(row, 1111111111);
            // if (this.tabActive == 'first') {
            //     this.$refs.editRankingDialog.open(row);
            // } else {
            //     this.$refs.editGroupDialog.open(row);
            // }

            this.$refs.editGroupDialog.open(row);
        },
        rowClick() {},
        dbClick(row) {
            this.$router.push({ path: `designer-ranking?id=${row.id}&?title=${row.title}` });
        },
        contentDbClick(row) {
            this.$router.push({
                path: `content-group?id=${row.id}&title=${row.collectionName}`,
            });
        },
        click() {},
        changeFn(row, key) {
            console.log(row, key, '777');
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        handleDelete(row, type) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let delApi = delRankingRow;
                    if (type == 'second') {
                        delApi = delGroupRow;
                    }
                    delApi({ id: row.id }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                        });
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.btns {
    padding: 20px 0px 0px 25px;
    position: relative;
}

.button-back {
    position: absolute;
    right: 0;
    margin-right: 10px;
}

.mkt {
    margin-top: -10px !important;
}
</style>
