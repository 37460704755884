<template>
    <div class="main">
        <sc-search-v2
            name="案例切片搜索"
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
        ></sc-search-v2>

        <div ref="table" class="content">
            <sc-table-v2
                name="table"
                :load="isLoading"
                title=""
                :head="tableHead"
                :data="listData"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :read-table="true"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @sortChange="sortChange"
                :default-sort="sort"
                ref="refTable"
            >
                <template #url>
                    <el-table-column label="图片" width="72">
                        <template slot-scope="scope">
                            <el-image
                                style="width:50px;height:50px;"
                                :src="scope.row.cover || scope.row.url"
                                :preview-src-list="srcList"
                            ></el-image>
                        </template>
                    </el-table-column>
                </template>
                <template #recommendation>
                    <el-table-column label="推荐语" width="240">
                        <template slot-scope="scope">
                            <el-popover
                                placement="top"
                                width="160"
                                v-model="scope.row.visible"
                                trigger="manual"
                            >
                                <p>确定保存吗？</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleCancel(scope.row)"
                                    >
                                        取消
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="handleSubmit(scope.row)"
                                    >
                                        确定
                                    </el-button>
                                </div>

                                <el-input
                                    type="textarea"
                                    slot="reference"
                                    v-model="scope.row.recommendation"
                                    placeholder="请输入推荐语"
                                    @focus="handleFocus(scope.row)"
                                    @blur="handleAsk(scope.row)"
                                ></el-input>
                            </el-popover>
                        </template>
                    </el-table-column>
                </template>
                <template #control>
                    <el-table-column label="操作" width="60">
                        <template slot-scope="scope">
                            <el-button size="small" type="text" @click="handleEdit(scope.row)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
        <edit ref="edit" @refresh="refresh"></edit>
    </div>
</template>
<script>
import edit from './edit';
import { getList, getDetail, saveWorksGroupRec } from '@/api/mkt/status';
import mixins from '@/mixins/index';
import mixinsLists from '@/mixins/getListMixin';
export default {
    name: 'mkt-cm-status',
    mixins: [mixins, mixinsLists],
    components: { edit },
    watch: {
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 128;
        },
        searchLabelOptions(val) {
            if (val) {
                const result = val.map(item => {
                    const children =
                        item.children?.length &&
                        item.children.map(every => {
                            return {
                                id: every?.id,
                                labelGroupId: every?.labelGroupId,
                                labelName: every?.labelName,
                                parentId: every?.parentId,
                                properties: every?.properties,
                            };
                        });
                    return {
                        ...item,
                        children: children,
                    };
                });
                result.unshift({ id: '100000', labelName: '无' });
                this.columns.labelIds.options.options = result;
            }
        },
        levelList(val) {
            if (val) {
                this.columns.contentLevel.options.options = val;
            }
        },
    },
    data() {
        return {
            sort: { prop: '', order: '' }, //ascending,descending  如果只指定了prop, 没有指定order, 则默认顺序是ascending
            levelList: [],
            tableHandle: getList,
            columns: {
                searchName: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '空间/案例标题/姓名/昵称/描述/ID',
                    },
                    spanCol: 1,
                },

                contentLevel: {
                    name: '评级',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
                labelIds: {
                    name: '标签',
                    type: 'cascader',
                    options: {
                        defaultValueBase: '',
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        collapseTags: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: false,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    spanCol: 1,
                },

                contentLevels: {
                    name: '评级',
                    type: 'hidden',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                        },
                    },
                    spanCol: 1,
                },
                rewardName: {
                    name: '获奖标签',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [],
                        filterable: true,
                    },
                    spanCol: 1,
                },
                isWeight: {
                    name: '权重值',
                    type: 'select',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请选择',
                        options: [
                            { value: 1, label: '无' },
                            { value: 2, label: '有' },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    spanCol: 1,
                },
                createTime: {
                    name: '最近审核时间',
                    type: 'date',
                    options: {
                        defaultValueBase: '',
                        dateType: 'daterange',

                        placeholder: '请输入',
                    },
                    spanCol: 2,
                },
                k1: {},
            },
            result: {
                searchName: '',
                bdate: '',
                edate: '',
                tags: '',
                createTime: [],
                labelIds: [],
                contentLevel: '',
                isWeight: '',
            },
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //显示第二个按钮
            },
            size: 'small',
            moreDown: false,

            tableHeightCalc: 520,
            innerHeight: 0,
            isSearchDown: false,
            tableHead: [
                { name: 'ID', key: 'id', width: '156px' },
                {
                    name: '图片',
                    key: 'url',
                },
                { name: '所属空间', key: 'spaceName', width: '110px' },
                { name: '图片组描述', key: 'picDescribe' },
                { name: '推荐语', key: 'recommendation', width: 200 },
                { name: '权重值', key: 'weightSort', sortable: true, width: '82px' },
                { name: '标签', key: 'labels' },
                { name: '创建人', key: 'name', width: '100px' },
                { name: '评级', key: 'contentLevel', formatter: this.parseLevel, width: '70px' }, // 精选 普通 无
                { name: '最近审核时间', key: 'approvalTime', width: '140px' },
                { name: '操作', key: 'control' },
            ],
            list: [],
            page: {
                size: 30, //每页多少条数据
                page: 1, //当前页码
                count: 0, //数据总条数
                index: 1,
            },
            srcList: [],
            filter: {},
        };
    },
    methods: {
        parseLevel(row) {
            let str = this.levelList.find(item => item.dictValue == row.contentLevel)?.label;
            return str || null;
        },
        filterTableData(list) {
            let _list = list.map(item => {
                return {
                    ...item,
                    visible: false,
                };
            });
            this.srcList = list.map(item => item.cover || item.url);
            return _list;
        },
        changeFn() {
            this.filter = {
                createTime: null,
                bdate:
                    this.result.createTime && this.result.createTime[0]
                        ? this.result.createTime[0] + ' 00:00:00'
                        : null,
                edate:
                    this.result.createTime && this.result.createTime[1]
                        ? this.result.createTime[1] + ' 23:59:59'
                        : null,
            };
            this.page = {
                page: 1,
                size: 30,
                index: 1,
            };
            this.queryDataMixin();
        },
        refresh() {
            this.queryDataMixin();
        },
        clickFn(e, type) {
            console.log('click', e, type);
            if (type == 'clear') {
                this.result.weightSortType = '';
                this.$refs.refTable.$refs['table-box'].clearSort();
            }
            this.filter = {
                createTime: '',
                bdate: this.result.createTime[0] ? this.result.createTime[0] + ' 00:00:00' : '',
                edate: this.result.createTime[1] ? this.result.createTime[1] + ' 23:59:59' : '',
            };
            this.page = {
                page: 1,
                size: 30,
                index: 1,
            };
            console.log(this.page);

            setTimeout(() => {
                this.queryDataMixin();
            }, 500);
        },
        sortChange(e) {
            if (e.order == 'ascending') {
                this.sort.prop = 'weightSort';
                this.result.weightSortType = 3;
            } else if (e.order == 'descending') {
                this.sort.prop = 'weightSort';
                this.result.weightSortType = 2;
            } else {
                this.sort.prop = '';
                this.result.weightSortType = '';
            }
            this.queryDataMixin();
        },
        handleDetail(row) {
            this.$router.push({
                path: '/mkt/cm/status/detail',
                query: { row: JSON.stringify(row) },
            });
        },
        handleAsk(item) {
            item.visible = true;
            this.listData.map(child => {
                if (item.id != child.id) {
                    child.visible = false;
                }
            });
        },
        handleFocus(item) {
            this.listData.map(child => {
                if (item.id != child.id) {
                    child.visible = false;
                }
            });
        },
        handleSubmit(item) {
            // 调修改接口
            saveWorksGroupRec({ id: item.id, recommendation: item.recommendation }).then(res => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    item.visible = false;
                    this.queryDataMixin();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleCancel(item) {
            item.visible = false;
            this.queryDataMixin();
        },
        handleEdit(row) {
            this.$refs.edit.open(row);
        },
    },
    created() {
        this.getLevelList();
        this.queryDataMixin();
        this.getSearchTagList();

        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        that.$nextTick(() => {
            that.tableHeightCalc = window.innerHeight - that.$refs.table.offsetTop - 128;
        });
    },
};
</script>
<style lang="less" scoped>
.main {
    background: #fff;
}
</style>
