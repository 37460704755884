<template>
    <div class="mkt archives-container flex flex-col" style="height: calc(100vh - 60px)">
        <div class="flex-shrink">
            <sc-search-v2
                :columns="columns"
                :result="result"
                :btn="{
                    twoShow: true,
                }"
                :more-down="true"
                :LS="LS"
                :CS="CS"
                @click-more="setTableHeight"
                @change="searchChange"
                @search="searchSearch"
            >
                <template v-slot:timeFrame>
                    <!-- label-class-name="hide-label" -->
                    <el-descriptions-item :span="3">
                        <template slot="label">
                            <div class="labelName">
                                {{ columns.timeFrame.name }}
                                <span v-if="columns.timeFrame.name">：</span>
                            </div>
                        </template>
                        <div class="flex flex-align-center">
                            <el-select
                                style="flex: 1"
                                v-model="result.timeFrame"
                                size="small"
                                :placeholder="columns.timeFrame.options.placeholder"
                                :clearable="columns.timeFrame.options.clearable || false"
                                :value-key="columns.timeFrame.options.valueKey || 'value'"
                                @change="value => searchChange({ row: columns.timeFrame, value })"
                            >
                                <el-option
                                    v-for="(item, index) in columns.timeFrame.options.options"
                                    :key="index"
                                    :label="
                                        item[columns.timeFrame.options.props.label] ||
                                            item[columns.timeFrame.options.props.value] ||
                                            item
                                    "
                                    :value="item[columns.timeFrame.options.props.value] || item"
                                    :disabled="item.disabled || false"
                                ></el-option>
                            </el-select>
                            <el-date-picker
                                v-model="result.dateRange"
                                style="flex: 2; margin-left: 10px"
                                size="small"
                                :clearable="dateRange.clearable || true"
                                :range-separator="dateRange.options.separator || '至'"
                                :placeholder="dateRange.options.placeholder || '选择日期'"
                                :start-placeholder="dateRange.options.startPlaceholder || ''"
                                :end-placeholder="dateRange.options.endPlaceholder || ''"
                                :type="dateRange.options.dateType || 'date'"
                                :format="dateRange.options.format || 'yyyy-MM-dd'"
                                :value-format="dateRange.options.valueFormat || 'yyyy-MM-dd'"
                                :align="dateRange.options.type || 'left'"
                                :validate-event="dateRange.options.validateEvent || true"
                                :picker-options="dateRange.options.pickerOptions || ''"
                                @change="value => searchChange({ row: dateRange, value })"
                            ></el-date-picker>
                        </div>
                    </el-descriptions-item>
                </template>
                <!-- <template v-slot:dateRange></template> -->
            </sc-search-v2>
            <div class="flex flex-align-center sum-number-bar">
                <span class="label">数据统计：</span>
                <div class="value flex flex-align-center flex-1">
                    <span
                        v-for="(item, key) in dossierStatisticsData"
                        :key="key"
                        class="value-item"
                    >
                        {{ item.label
                        }}{{
                            key === 'totalNumber' || key === 'toolNumber' ? getTypeTip(key) : ''
                        }}：{{ item.value }}
                        <el-tooltip class="item" effect="dark" :content="item.tip" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </span>
                </div>
            </div>
        </div>
        <!-- :default-sort="{ prop: 'LastAuditTime', order: 'descending' }" -->
        <sc-table-v2
            class="list-table flex-1 flex flex-col"
            :head="tableHead"
            :data="listData"
            :page="page"
            :load="isLoading"
            :tableHeight="tableHeight"
            ref="refTable"
            @pageChange="pageChange"
            @pageSizesChange="pageSizesChange"
            @sortChange="sortChange"
        >
            <template v-slot:customerStatus>
                <el-table-column label="角色" minWidth="90">
                    <template slot="header">
                        <span>角色</span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="客户登录的手机号和bpm库进行比对，库里有为老潜客，无则是新潜客"
                            placement="top"
                        >
                            <i class="el-icon-question table-header-icon"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        {{ scope.row.customerStatus }}
                    </template>
                </el-table-column>
            </template>
            <template v-slot:control>
                <el-table-column label="操作" fixed="right" width="202">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="clickControlBtn(scope.row)">
                            用户档案
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="openTestAccountDialog(scope.row)"
                        >
                            查看行为明细
                        </el-button>
                        <span>&nbsp;</span>
                        <sc-qrcode-popover
                            v-if="scope.row.customerStatus === '设计师'"
                            :id="scope.row.accountId"
                            type="user"
                        ></sc-qrcode-popover>
                    </template>
                </el-table-column>
            </template>
        </sc-table-v2>
        <testAccountDialog ref="testAccountDialog" :title="title"></testAccountDialog>
    </div>
</template>
<script>
import lodash from 'lodash';
import getListMixin from '@/mixins/getListMixin';
import { getDossierList, getDossierUserList, getDossierStatistics } from '@/api/mkt/user';
import testAccountDialog from './components/testAccount-dialog'; //点收评分

// 用户ID、昵称、手机号、近30天访问次数、身份、角色、是否首次、活跃时间、工具测试、IP地址、登录所属页面、最近预约时间、最近访问时间、首次登录时间、来源人、
const pickerOptions = {
    disabledDate(time) {
        let nowDate = new Date();
        var year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1;
        let date = nowDate.getDate();
        let nowTimer = new Date(`${year}/${month}/${date}`).getTime();
        if (time.getTime() > nowTimer) {
            return true;
        }
        return false;
    },
};
const columns = {
    identity: {
        name: '身份',
        type: 'select',
        options: {
            clearable: true,
            options: [],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    role: {
        name: '角色',
        type: 'select',
        options: {
            clearable: true,
            options: [],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    tool: {
        name: '工具使用',
        type: 'select',
        options: {
            clearable: true,
            options: [],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    appointment: {
        name: '预约咨询',
        type: 'select',
        options: {
            clearable: true,
            options: [],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    device: {
        name: '终端',
        type: 'select',
        options: {
            clearable: true,
            options: [],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },

    isFirst: {
        name: '是否首次',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '0', sysLabel: '是' },
                { dictValue: '1', sysLabel: '否' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    testAccount: {
        name: '是否真实用户',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '0', sysLabel: '是' },
                { dictValue: '1', sysLabel: '否' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    nickName: {
        name: '用户昵称',
        type: 'input',
        options: {
            clearable: true,
            defaultValueBase: '',
            placeholder: '请输入',
        },
        spanCol: 1,
    },

    timeFrame: {
        name: '访问时间范围',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '0', sysLabel: '是' },
                { dictValue: '1', sysLabel: '否' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    mobile: {
        name: '手机号',
        type: 'input',
        options: {
            clearable: true,
            maxlength: 11,
            defaultValueBase: '',
            placeholder: '请输入',
        },
        spanCol: 1,
    },

    latestPromotionChannel: {
        name: '最新来源渠道',
        type: 'input',
        options: {
            defaultValueBase: '',
            placeholder: '搜索最新来源渠道',
        },
        spanCol: 1,
    },
    promotionChannel: {
        name: '首次来源渠道',
        type: 'input',
        options: {
            defaultValueBase: '',
            placeholder: '搜索首次来源渠道',
        },
        spanCol: 1,
    },
    firstLoginTime: {
        name: '首次登录时间',
        type: 'date',
        options: {
            clearable: true,
            dateType: 'daterange',
            defaultValueBase: '',
            placeholder: '请输入首次登录时间',
        },
        spanCol: 2,
    },
    decorationStatus: {
        name: '装修状态',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '173', sysLabel: '即将要装修' },
                { dictValue: '174', sysLabel: '正在装修中' },
                { dictValue: '175', sysLabel: '不需要装修' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    isSource: {
        name: '是否有来源人',
        type: 'select',
        options: {
            clearable: true,
            options: [
                { dictValue: '0', sysLabel: '有' },
                { dictValue: '1', sysLabel: '无' },
            ],
            props: {
                value: 'dictValue',
                label: 'sysLabel',
            },
            placeholder: '请选择',
        },
        spanCol: 1,
    },
    empt2: {},
};
const initResult = {
    identity: null,
    role: '',
    tool: '',
    appointment: '',
    device: '',
    dateRange: '',
    nickName: '',
    mobile: '',
    isFirst: '',
    isSource: '',
    testAccount: '',
    timeFrame: '',
    // bDate: '',
    // eDate: '',
};
export default {
    name: 'user-archives-list',
    components: { testAccountDialog },
    mixins: [getListMixin],
    data() {
        return {
            columns: columns,
            result: {},
            tableHandle: getDossierUserList,

            dossierStatisticsData: {
                totalNumber: {
                    label: '身份',
                    value: 0,
                    tip: '全部身份的统计数',
                },
                registerNumber: {
                    label: '已注册',
                    value: 0,
                    tip: '所有注册过的用户数',
                },
                toolNumber: {
                    label: '工具测试',
                    value: 0,
                    tip: '做过风格测试、智能匹配、客户需求收集、户型规划、装修预算用户数',
                },

                appointmentNumber: {
                    label: '已预约咨询',
                    value: 0,
                    tip: '所有点过预约咨询的用户数',
                },
            },
            LS: {
                'min-width': '100px',
                width: '100px',
                padding: 0,
                verticalAlign: 'bottom',
            },
            CS: {
                'min-width': '180px',
            },
            dateRange: {
                name: '访问时间范围',
                type: 'date',
                key: 'dateRange',
                options: {
                    clearable: true,
                    dateType: 'daterange',
                    defaultValueBase: '',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    format: 'yyyy-MM-dd',
                    valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    placeholder: '请选择',
                    pickerOptions: pickerOptions,
                },
                spanCol: 2,
            },
            tableHead: [
                {
                    name: '用户ID/设备号',
                    key: 'accountId',
                    minWidth: 280,
                    showOverflowTooltip: true,
                },
                { name: '昵称', key: 'nickName', showOverflowTooltip: true, minWidth: 108 },
                { name: '手机号', key: 'mobile', minWidth: 108 },
                {
                    name: '装修状态',
                    key: 'decorationStatus',
                    minWidth: 108,
                    formatter: this.dStatusFormatter,
                },
                { name: '近30天访问次数', key: 'pv', minWidth: 140, sortable: true },
                { name: '身份', key: 'identity' },
                { name: '角色', key: 'customerStatus', minWidth: 90 },
                { name: '是否首次', key: 'isFirst' },
                {
                    name: '是否真实用户',
                    key: 'testAccount',
                    minWidth: 94,
                    formatter: this.testAccountFormatter,
                },
                // { name: '活跃时间', key: 'browseTime' },
                { name: '工具测试', key: 'tool', minWidth: 150, showOverflowTooltip: true },
                { name: '地域', key: 'ipRegion', minWidth: 150, showOverflowTooltip: true },
                {
                    name: '登录所属页面',
                    key: 'loginPage',
                    minWidth: 150,
                    showOverflowTooltip: true,
                    formatter: this.loginPageFormatter,
                },
                { name: '最近预约时间', key: 'appointmentTime', minWidth: 150, sortable: true },
                { name: '最近访问时间', key: 'lastVisitTime', minWidth: 150, sortable: true },
                { name: '首次登录时间', key: 'firstLoginTime', minWidth: 150, sortable: true },
                { name: '来源人', key: 'sourceName', minWidth: 200 },
                {
                    name: '最新来源渠道',
                    key: 'latestPromotionChannel',
                    minWidth: 150,
                },
                {
                    name: '首次来源渠道',
                    key: 'promotionChannel',
                    minWidth: 150,
                    // formatter: this.promotionFormatter,
                },
                { name: '访问终端', key: 'terminal', minWidth: 90, showOverflowTooltip: true },
                { name: '操作', key: 'control' },
            ],
        };
    },
    created() {},
    async mounted() {
        this.setTableHeight();
        // 下拉数据集合
        this.optionsData = {};
        this.filter = {
            bDate: '',
            eDate: '',
            // timeFrame 和 identity 只是为了覆盖接口的参数用
            dateRange: '',
            identity: null,
        }; // 筛选没有绑定result的数据集合
        // 获取搜索筛选项数据
        await this.getDossierListData();
        this.queryDataMixin();
        this.getDossierStatisticsData();
        window.addEventListener('resize', this.windowResize, false);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResize, false);
    },
    methods: {
        windowResize: lodash.debounce(function() {
            this.setTableHeight();
        }, 200),
        openTestAccountDialog(row) {
            this.$refs.testAccountDialog.open(row);
        },
        dStatusFormatter(row) {
            let obj = { 173: '即将要装修', 174: '正在装修中', 175: '不需要装修' };
            return obj[row.decorationStatus] || '';
        },
        testAccountFormatter(row) {
            // let obj = { 1: '测试用户', 0: '真实用户' };
            let obj = { 1: '否', 0: '是' };
            return obj[row.testAccount] || '';
        },
        loginPageFormatter(row) {
            return (
                (row.loginPage &&
                    row.loginPage + (row.loginSecondPage && '-' + row.loginSecondPage)) ||
                ''
            );
        },
        promotionFormatter(row) {
            let result = row.promotionChannel;
            if (row.promotionChannel == 'sczs-offiaccount') {
                result = '尚层装饰公众号';
            }
            return result;
        },
        filterTableData(list) {
            if (list.length === 0) {
                return list;
            }
            let loginObj = {
                1: '否',
                2: '--',
                0: '是',
            };
            for (let item of list) {
                item.isFirst = loginObj[item.isFirst] || '';
                // item.browseTime = this.timeRangeData.find(
                //     val => val.dictValue == item.browseTime
                // )?.sysLabel;
                const roleData = this.optionsData['identity'].find(
                    val => val.dictValue == item.identity
                );
                item.identity = roleData?.sysLabel;
                item.customerStatus = roleData?.children?.find(
                    val => val.dictValue == item.customerStatus
                )?.sysLabel;
                if (Array.isArray(item.tool) && item.tool.length > 0) {
                    item.tool = item.tool
                        .map(val => {
                            return this.optionsData['tool'].find(val2 => val2.dictValue == val)
                                ?.sysLabel;
                        })
                        .join('/');
                } else {
                    item.tool = '';
                }
                item.sourceName = item.sourceName || '';
                if (item.sourceNamejob) {
                    item.sourceName = item.sourceName + ' ' + item.sourceNamejob;
                }
                if (item.sourceIdentity) {
                    let obj = { '22': '员工', '23': '非员工', '26': '游客' };
                    let sourceIdentity = obj[item.sourceIdentity];
                    if (sourceIdentity) {
                        item.sourceName = item.sourceName + ' ' + sourceIdentity;
                    }
                }
                item.terminal =
                    this.optionsData['terminal'].find(val => val.dictValue == item.terminal)
                        ?.sysLabel || '';
            }
            return list;
        },
        getClientHeight() {
            var clientHeight = 0;
            if (document.body.clientHeight && document.documentElement.clientHeight) {
                clientHeight =
                    document.body.clientHeight < document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            } else {
                clientHeight =
                    document.body.clientHeight > document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            }
            return clientHeight;
        },
        setTableHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    // 设置table高度
                    const name = '.list-table';
                    let tableDom = document.querySelector(name);
                    if (tableDom) {
                        let topNum = tableDom.getBoundingClientRect().top;
                        let mainNum = this.getClientHeight();
                        this.tableHeight = mainNum - topNum - 47;
                    }
                }, 0);
            });
        },
        // 点击table 操作按钮
        clickControlBtn(row) {
            let url = `${location.origin}/#/mkt/user/archives/detail?accountId=${
                row.accountId
            }&ipRegion=${row.ipRegion && row.ipRegion.trim()}&customerStatus=${
                row.customerStatus
            }&identity=${row.identity}&terminalIdentification=${row.terminalIdentification}`;
            console.log('-----------row', url);

            window.open(url, '_blank');

            // this.$router.push({
            //     path: '/mkt/user/archives/detail',
            //     query: {
            //         accountId: row.accountId,
            //         ipRegion: row.ipRegion,
            //         customerStatus: row.customerStatus,
            //         identity: row.identity,
            //         terminalIdentification: row.terminalIdentification,
            //     },
            // });
        },
        getDossierListData() {
            return new Promise(resolve => {
                getDossierList({}).then(res => {
                    console.log(res);
                    if (res.code === 200 && res.isSuccess) {
                        // const map = {
                        //     identification: 'identity', // 身份角色
                        //     toolsVo: 'tool', // 工具测试
                        //     reservationVo: 'appointment', // 预约咨询
                        //     terminalVo: 'device', // 对应列表terminal
                        //     terminal: 'device',
                        //     // timeVo: 'timeFrame', // 已废弃 改为选择时间范围
                        // };
                        const map = {
                            identity: 'identification', // 身份角色
                            tool: 'toolsVo', // 工具测试
                            appointment: 'reservationVo', // 预约咨询
                            device: 'terminalVo', // 对应列表terminal
                            terminal: 'terminalVo',
                            timeFrame: 'timeVo', // 已废弃 改为选择时间范围
                        };
                        let obj = {};
                        for (let key in map) {
                            const value = map[key];
                            obj[key] = res.data[value];
                            if (this.columns[key]) {
                                this.columns[key].options.options = obj[key];
                            }
                            if (key === 'identification') {
                                this.columns['role'].options.options = obj[key]?.children || [];
                            }
                        }
                        this.optionsData = obj;
                        this.result = JSON.parse(JSON.stringify(initResult));
                    }
                    resolve();
                });
            });
        },
        getTypeTip(key) {
            const map = {
                totalNumber: 'identity',
                toolNumber: 'tool',
            };
            if (this.optionsData && this.optionsData[map[key]]?.length > 0) {
                let str =
                    this.optionsData[map[key]].find(item => item.dictValue == this.result[map[key]])
                        ?.sysLabel || '全部';
                return `（${str}）`;
            } else {
                return '';
            }
        },
        searchChange(val) {
            console.log(val);
            const { row, value } = val;
            const key = row.key;
            if (key === 'identity') {
                let roleColumnOptions = this.columns['role'].options;
                this.result.role = '';
                roleColumnOptions.options = [];
                if (value) {
                    const roleList = this.optionsData[key].find(el => el.dictValue == value);
                    roleColumnOptions.options = roleList?.children || [];
                    // 后台要求身份也按照数据来传
                    this.filter.identity = [value];
                } else {
                    this.filter.identity = null;
                }
            } else if (key === 'dateRange') {
                if (Array.isArray(value) && value.length > 0) {
                    this.filter.bDate = value[0];
                    this.filter.eDate = value[1].split(' ')[0] + ' 23:59:59';
                    this.result.timeFrame = '';
                } else {
                    this.filter.bDate = '';
                    this.filter.eDate = '';
                }
            } else if (key === 'timeFrame') {
                if (value) {
                    this.result.dateRange = '';
                    this.filter.bDate = '';
                    this.filter.eDate = '';
                }
            } else if (key === 'firstLoginTime') {
                console.log('-----------key', value);
                if (value && value.length) {
                    this.filter.bFirstDate = value[0] + ' 00:00:00';
                    this.filter.eFirstDate = value[1] + ' 23:59:59';
                } else {
                    this.filter.bFirstDate = '';
                    this.filter.eFirstDate = '';
                }
                this.filter.firstLoginTime = null;
            }

            if (this.listTimer) {
                clearTimeout(this.listTimer);
                this.listTimer = null;
            }
            this.listTimer = setTimeout(() => {
                this.isLoading = false;
                this.getDossierStatisticsData();
                this.pageChange(1);
            }, 300);
        },
        searchSearch(val, type) {
            console.log(val, type);
            if (type === 'clear') {
                this.result = JSON.parse(JSON.stringify(initResult));
                this.filter.bDate = '';
                this.filter.eDate = '';
                this.filter.identity = null;
                this.filter.bFirstDate = '';
                this.filter.eFirstDate = '';
                this.filter.firstLoginTime = null;
                this.columns['role'].options.options = [];
            }
            this.getDossierStatisticsData();
            this.pageChange(1);
        },
        sortChange({ column, prop, order }) {
            console.log(column, prop, order);
            if (order == 'ascending') {
                // 上升
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 1;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 3;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 5;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 7;
                        break;
                    default:
                        break;
                }
            } else {
                // 下降
                switch (prop) {
                    case 'pv':
                        this.result.sortType = 2;
                        break;
                    case 'appointmentTime':
                        this.result.sortType = 4;
                        break;
                    case 'lastVisitTime':
                        this.result.sortType = 6;
                        break;

                    case 'firstLoginTime':
                        this.result.sortType = 8;
                        break;
                    default:
                        break;
                }
            }
            this.pageChange(1);
        },
        getDossierStatisticsData() {
            const data = {
                ...this.result,
                ...this.filter,
            };

            delete data.dateRange;
            for (var key in data) {
                let value = data[key];
                if (!value || JSON.stringify(value) === '[]') {
                    delete data[key];
                }
            }
            getDossierStatistics(data).then(res => {
                if (res.code === 200 && res.data) {
                    for (let key in this.dossierStatisticsData) {
                        this.dossierStatisticsData[key].value = res.data[key];
                    }
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
.flex-align-center {
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.flex-col {
    flex-direction: column;
}
.flex-shrink {
    flex-shrink: 0;
}
.mkt {
    min-height: auto;
}
.archives-container {
    height: calc(100vh - 60px);
    background: #fff;
    overflow: hidden;
}

.sum-number-bar {
    margin: 15px 25px;
    padding: 15px 10px;
    background: #f8f8f8;
    font-size: 12px;
    .label {
        font-weight: bold;
        flex-shrink: 0;
    }
    .value {
        margin-left: 10px;
        justify-content: space-around;
        max-width: 867px;
    }
    .value-item {
        margin-right: 15px;
    }
}
.list-table {
    margin: 0 15px;
    padding-top: 0;
}
/deep/ .el-form .el-descriptions__body .el-descriptions-item__content {
    padding-bottom: 0;
}
/deep/ .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    padding-right: 0;
}
/deep/ .searchMain .button-box {
    margin-left: 10px;
}
/deep/ .el-icon-question {
    font-size: 14px;
    &.table-header-icon {
        margin-left: 2px;
    }
}
/deep/ .hide-label {
    display: none;
}
</style>
