<template>
    <!--  获客来源统计-->

    <div class="mkt">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="获客来源统计" name="1"></el-tab-pane>
            <el-tab-pane label="数据统计" name="2"></el-tab-pane>
        </el-tabs>
        <div class="mkt" ref="table" v-if="activeName == 1">
            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="clickFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>
            <div class="tabs">
                <sc-table-v2
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="false"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:sourceType>
                        <el-table-column label="客户来源">
                            <template slot-scope="scope">
                                <div v-if="scope.row.sourceType == '1'">宣传码一</div>
                                <div v-else-if="scope.row.sourceType == '2'">宣传码二</div>
                                <div v-else-if="scope.row.sourceType == '3'">宣传码三</div>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>

        <div v-else>
            <div v-show="activeName == 2">
                <data-statistics></data-statistics>
            </div>
        </div>
    </div>
</template>

<script>
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import dataStatistics from './components/data-statistics.vue';
import { getCustomerSourceStatisticsList } from '@/api/mkt/user.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { dataStatistics },
    data() {
        return {
            activeName: '1',
            tableHandle: getCustomerSourceStatisticsList,
            loading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '90px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '手机号', key: 'mobile' },
                { name: '获客来源', key: 'sourceType' },
                { name: 'IP地址', key: 'ipAddress' },
                { name: '授权时间', key: 'authorizationTime' },
            ],
            columns: {
                sourceType: {
                    name: '获客来源',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        options: [
                            { label: '宣传码一', value: '1' },
                            { label: '宣传码二', value: '2' },
                            { label: '宣传码三', value: '3' },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },
                    spanCol: 1,
                },
            },
            result: {
                name: '',
                sourceType: '',
            },
            tableAddConfig: {},

            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            couponList: [],
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
        },
    },

    methods: {
        handleClick(tab) {
            this.activeName = tab.name;
        },
        handleClickMore() {},

        init() {
            this.queryDataMixin();
        },
        rowClick() {},
        dbClick() {},
        click() {},
        changeFn(row, key) {
            this.page.page = 1;
            this.init();
        },
        clickFn(row, type) {
            if (type == 'clear') {
                for (const key in this.result) {
                    if (typeof this.result[key] != 'string') {
                        this.result[key] = '';
                    }
                }
                this.page.page = 1;
                this.page.size = 30;
            }
            this.init();
        },
        rowChange() {},
        selectionChange() {},
    },
};
</script>
<style lang="less" scoped></style>
