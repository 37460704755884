<template>
    <div class="info-container">
        <div class="left">
            <div class="content-box">
                <div class="title">基本信息</div>
                <p class="content">
                    <span class="left-title">昵称：</span>
                    <span class="special-span">
                        <!-- <img
                            class="img"
                            src="https://dpwcc.voglassdc.com//test/customerApp/market/1686296583837-f6557c9a-c4c3-4c22.jpg"
                            alt=""
                        /> -->
                        {{ row.nickName || '' }}
                    </span>
                </p>
                <p class="content">
                    <span class="left-title">手机号：</span>
                    <span>{{ row.mobile || '' }}</span>
                </p>
                <p class="content">
                    <span class="left-title">性别：</span>
                    <span>{{ row.sex | parseSex }}</span>
                </p>
                <p class="content">
                    <span class="left-title">所属城市：</span>
                    <span>{{ row.city || '' }}</span>
                </p>
                <p class="content">
                    <span class="left-title">收藏内容数：</span>
                    <span>{{ row.collection }}</span>
                </p>
            </div>
            <div class="content-box special-content-box">
                <div class="title">系统信息</div>
                <p class="content">
                    <span class="left-title">来源人：</span>
                    <span>{{ row.firstTouchName }}</span>
                    <span>&nbsp;&nbsp;{{ row.firstTouchJob }}</span>
                </p>
                <p class="content">
                    <span class="left-title">最近访问时间：</span>
                    <span>{{ row.lastLoginTime }}</span>
                </p>
                <p class="content">
                    <span class="left-title">首次登录时间：</span>
                    <span>{{ row.firstLoginTime }}</span>
                </p>
            </div>
        </div>
        <div class="right">
            <div class="content-box">
                <div class="title">装修信息</div>
                <p class="content-match " v-for="(item, index) in renovationList" :key="index">
                    <span class="left-title-match">{{ item.questionsName }}：</span>
                    <span>{{ item.optionName || '' }}</span>
                    <span v-if="item.optionPic">
                        <el-image
                            :src="item.optionPic"
                            fit="contain"
                            :preview-src-list="[item.optionPic]"
                            style="width: 100px; height: 100px"
                        ></el-image>
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { getRenovation } from '@/api/mkt/user.js';

export default {
    name: '',
    props: {
        row: {
            type: Object,
            default: () => {},
        },
    },
    filters: {
        parseSex(val) {
            const obj = {
                1: '男',
                2: '女',
                3: '未知',
                null: '--',
            };
            return obj[val];
        },
    },
    data() {
        return {
            renovationList: [],
        };
    },
    created() {
        this.getRenovation();
    },
    mounted() {},
    methods: {
        async getRenovation() {
            const res = await getRenovation({ userId: this.row.id });
            let arr = [];
            if (res?.isSuccess) {
                if (res.data.length) {
                    let house = '';
                    let houseIndex = 0;
                    let houseClass = '';
                    let houseItem = {};
                    let tagItem = {};
                    let tag = '';
                    let tagIndex = 0;
                    res.data.map((d, index) => {
                        if (d.questionsName == '居住户型') {
                            houseClass += d.optionName;
                        } else if (d.questionsName == '户型' || d.questionsName == '您的房子是') {
                            house = d.optionName;
                            houseIndex = index;
                            houseItem = d;
                        } else if (d.questionsName.indexOf('比较看重设计师哪方面') > -1) {
                            tagItem = d;
                            tag = (tag && tag + '、' + d.optionName) || d.optionName;
                            tagIndex = index;
                        } else {
                            arr.push(d);
                        }
                    });
                    houseItem.optionName = house && house + '  ' + houseClass;
                    arr.splice(houseIndex, 0, houseItem);
                    tagItem.optionName = tag;
                    arr.splice(tagIndex, 0, tagItem);
                }
            }
            this.renovationList = arr || [];
        },
    },
};
</script>
<style lang="less" scoped>
.info-container {
    display: flex;
    .left {
        flex: 1;
        border-right: 1px solid #bbbbbb;
    }
    .right {
        padding-left: 40px;
        flex: 1;
    }
}
.content-box {
    margin-top: 40px;
    margin-left: 20px;
    .title {
        color: rgba(16, 16, 16, 100);
        font-size: 16px;
        font-family: PingFangSC-regular;
    }
    .content {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #bbbbbb;
        width: 387px;
        .left-title {
            width: 150px;
            display: inline-block;
        }
        .span-box {
            padding: 5px 10px;
            background: #000;
            color: #fff;
            border-radius: 4px;
        }
    }
    .content-match {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #bbbbbb;
        width: 600px;
        .left-title-match {
            width: 200px;
            display: inline-block;
        }
    }
}
.special-content-box {
    margin-top: 40px;
}
.special-span {
    display: inline-flex;
    align-items: center;
    .img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }
}
</style>
