<template>
    <div class="home">
        <Toolbar
            style="border:1px solid rgb(204, 204, 204);border-bottom:none"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 300px; overflow-y: hidden;border:1px solid rgb(204, 204, 204)"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="handleChange"
            @customUpload="customUpload"
        />
    </div>
</template>

<script>
// 引入 wangEditor
import { Loading } from 'element-ui';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
// import { url } from '@/config.js';
import { getCookie } from '@/utils/util.js';
import { addFileUpload } from '@/api/common.js';
import { DomEditor } from '@wangeditor/editor';
import { debounce } from '@/utils/util.js';
export default {
    name: 'wangeditor',
    components: { Editor, Toolbar },
    props: {
        editorData: {
            type: String,
            default: function() {
                return '';
            },
        },
        editorDisabled: {
            type: Boolean,
            default: false,
        },
        editorConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        ind: {
            type: Number,
            default: 0,
        },
        menus: {
            type: Array,
            default: function() {
                return [
                    'blockquote', // 引用
                    'header1', //h1,
                    'header2',
                    'header3',
                    // 'headerSelect', // 标题
                    // 'fontSize', // 字号
                    //   'lineHeight', // 行高
                    'bold', // 粗体
                    // 'fontFamily', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'through', // 删除线
                    'color', // 文字颜色
                    'bgColor', // 背景颜色
                    'bulletedList', // 无序列表
                    'numberedList', // 有序列表
                    'justifyLeft', // 对齐方式
                    'justifyRight', // 对齐方式
                    'justifyCenter', // 对齐方式
                    // 'justifyJustify', // 对齐方式

                    // 'emotion', // 表情
                    // 'undo', // 撤销
                    // 'redo', // 重复
                    // 'uploadImage',
                    // 'uploadVideo',
                ];
            },
        },
        editorKey: {
            type: String,
            default: function() {
                return '';
            },
        },
    },
    data() {
        return {
            editor: null,
            html: '',
            init: true,
            toolbarConfig: {
                toolbarKeys: [],
            },
            editorConfigData: {},
            mode: 'default',
        };
    },
    methods: {
        isPTagContainsOnlyWhitespace(html) {
            // 使用正则表达式找到所有的<p>...</p>内容
            const pTagsRegex = /<p>([\s\S]*?)<\/p>/gi;
            let match;
            // 遍历所有匹配的<p>标签
            while ((match = pTagsRegex.exec(html)) !== null) {
                // 提取<p>标签内的内容
                const content = match[1];
                // 检查内容是否仅包含空白字符、<br>标签和&nbsp;实体
                // 注意：\s 包括空格、制表符、换行符等空白字符
                // \u00A0 是 &nbsp; 的 Unicode 编码
                if (!/^\s*(<br\s*\/?>|&nbsp;)*\s*$/.test(content)) {
                    // 如果发现任何一个<p>标签的内容不符合条件，返回false
                    return false;
                }
            }
            // 如果所有<p>标签都符合条件，返回true
            return true;
        },
        handleChange: debounce(function(editor) {
            let html = this.html;
            let valid = /^<p>(<br>\s*)<\/?p>$/;
            if (this.isPTagContainsOnlyWhitespace(html)) {
                html = '';
            }
            this.$emit('getValue', html, this.$props.ind);
        }, 500),
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
            // console.log('%c DomEditor--------------------', 'color:purple', DomEditor);
            this.html = this.editorData;
            this.editor.setHtml(this.editorData);

            // 是否为只读
            if (this.editorDisabled) {
                this.editor.disable();
            }
            // console.log('---------editor.getAllMenuKeys()', editor.getAllMenuKeys());
        },

        async customUpload(file, insertFn) {
            // let imgUrl = await uploadRequest(file);
            // insertFn(imgUrl, '', imgUrl);
        },
    },

    mounted() {
        this.toolbarConfig.toolbarKeys = this.menus;
        this.html = this.editorData;
        this.editorConfigData = {
            ...this.editorConfig,

            MENU_CONF: {
                uploadValue: {
                    server: '/api/upload',
                    onBeforeUpload(file) {
                        this.loadingInstance = Loading.service({
                            text: '上传中...',
                        });
                    },
                },
            },
        };

        //如果回显数据
        // if (this.editorData && this.init) {
        //     this.editor.setHtml(this.editorData);
        // }
        // this.init = false;
    },
    watch: {
        editorData(newVal) {
            this.html = newVal ? newVal : '';
            this.editor.setHtml(this.html);
        },
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.home {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}
/deep/ em {
    font-style: italic !important;
}
// .home-editor {
//     height: 100px;
// }
</style>
