<template>
    <div class="add-tag-container">
        <el-drawer
            :visible.sync="addialogVisible"
            size="30%"
            :title="formName"
            :wrapperClosable="false"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <template v-slot:qq>
                    <el-descriptions-item
                        span="2"
                        :labelStyle="{ background: '#fff' }"
                    ></el-descriptions-item>
                </template>
                <template v-slot:orderSort>
                    <el-descriptions-item span="1" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">排序</div>
                            <el-input
                                v-model.number="result.orderSort"
                                style="width: 100px"
                                type="number"
                                size="mini"
                                @input="handleSort"
                            ></el-input>
                        </template>
                        <div style="text-align: left"></div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="addialogVisible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { editgroup } from '@/api/mkt/tag-group';
export default {
    props: {},
    data() {
        return {
            addialogVisible: false,
            dialogVisible: false,
            columns: {
                groupName: {
                    name: '组名称',
                    type: 'input',
                    options: {
                        placeholder: '',
                        maxlength: 12,
                        showWordLimit: true,
                    },
                    rules: [{ required: true, message: '请输入组名称' }],
                },
                remark: {
                    name: '备注',
                    type: 'textarea',
                    options: {
                        placeholder: '',
                        maxlength: 80,
                        showWordLimit: true,
                    },
                },

                orderSort: {
                    name: '排序值',
                    type: 'input',
                    options: {
                        placeholder: '',
                    },
                },
            },
            result: {
                groupName: '',
                orderSort: 0,
                groupType: '',
                remark: '',
            },
            formName: '',
        };
    },
    mounted() {},
    methods: {
        open(row) {
            this.formName = '创建标签组';
            this.addialogVisible = true;
            this.result = {
                groupName: '',
                orderSort: 0,
                groupType: '',
                remark: '',
            };
            if (row) {
                this.formName = '编辑标签组';
                this.result = { ...row };
            }
        },
        close() {
            this.addialogVisible = false;
        },
        handleClose() {
            // this.$emit('handleClose');
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, 88888);
                let params = {
                    ...this.result,
                    groupType:
                        this.result.groupType == 4 || this.result.groupType == 5
                            ? this.result.groupType
                            : 1, // 固定传1 1系统  4产品运营 5用户定义
                };
                editgroup(params).then(res => {
                    console.log(res, 'res-----');
                    this.close();
                    this.$emit('handleClose');
                });
            });
        },
        handleSort(val) {
            var regPos = /^[0-9]*[1-9][0-9]*$/; // 非负整数
            if (!regPos.test(val)) {
                this.result.orderSort = 0;
                return;
            }
            if (val > 9999) {
                this.result.orderSort = 9999;
            }
        },
        handleReset() {
            this.result = {
                groupName: '',
                orderSort: 0,
                type: 0,
                remark: '',
            };
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            // if (data.row.key == 'orderSort') {
            //     if (parseInt(data.value) < 0) {
            //         this.result.orderSort = '0';
            //         console.log('----------------', this.result.orderSort);
            //     }
            // }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
