import { render, staticRenderFns } from "./upload.vue?vue&type=template&id=151a7452&scoped=true"
import script from "./upload.vue?vue&type=script&lang=js"
export * from "./upload.vue?vue&type=script&lang=js"
import style0 from "./upload.vue?vue&type=style&index=0&id=151a7452&prod&lang=less&scoped=true"
import style1 from "./upload.vue?vue&type=style&index=1&id=151a7452&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151a7452",
  null
  
)

export default component.exports