<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="closeDrawer"
            custom-class="self-drawer"
            :size="size"
        >
            <subject-from @closeDrawer="closeDrawer" v-if="title != '管理专题'" />
            <subject-table :id="topicId" v-else :drawer="drawer" />
        </el-drawer>
    </div>
</template>

<script>
import subjectFrom from './subject-from.vue';
import subjectTable from './subject-table.vue';

export default {
    name: 'details-drawer',
    components: { subjectFrom, subjectTable },
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            title: '',
            size: '50%',
            topicId: '',
        };
    },
    methods: {
        openDrawer(title, row) {
            this.size = title == '管理专题' ? '50%' : '30%';
            this.drawer = true;
            this.title = title;
            this.topicId = row.id;
        },
        closeDrawer() {
            this.$confirm('确认关闭？')
                .then(() => {
                    this.drawer = false;
                })
                .catch(() => {});
        },
    },

    created() {},
};
</script>

<style scoped lang="less">
/deep/ .self-drawer {
    padding: 0 20px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    > div {
        text-align: center;
    }
    .img {
        width: 100px;
        height: 100px;
    }
}
</style>
