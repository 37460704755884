<template>
    <div>
        <div class="title-t">基本信息</div>
        <sc-form-v2
            tablename="CRM_CATEGORY"
            :columnsNum="1"
            :columns="columns"
            :uploadConfig="uploadConfig"
            :result="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
        >
            <template v-slot:categoryId>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            所属分类
                            <span class="must">
                                *
                            </span>
                        </div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width:95%"
                            v-model="result.categoryId"
                            :show-all-levels="false"
                            :size="size"
                            :options="categoryList"
                            @change="selectCateChange"
                            ref="cateCascader"
                            :props="{
                                emitPath: false,
                                value: 'categoryId',
                                label: 'categoryName',
                            }"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:relevanceLabelParams>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            标签
                        </div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width:95%"
                            v-model="relevanceLabelParams"
                            :size="size"
                            :options="labelList"
                            placeholder="最多选择20个"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                                multiple: true,
                            }"
                            :show-all-levels="false"
                            @change="selectTagChange"
                            ref="cascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:operationIds>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            运营标签
                        </div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width:95%"
                            v-model="operationIds"
                            :size="size"
                            :options="operationList"
                            placeholder="最多选择20个"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                                multiple: true,
                            }"
                            :show-all-levels="false"
                            @change="selecttOpeTag"
                            ref="operaCascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:companys v-if="isShowCompanys">
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            分公司
                        </div>
                    </template>
                    <div style="text-align: left">
                        <el-select
                            style="width:95%"
                            v-model="companys"
                            :size="size"
                            multiple
                            placeholder="请选择"
                            @change="seletChange"
                        >
                            <el-option
                                v-for="item in companyData"
                                :key="item.ouCode"
                                :label="item.ouName"
                                :value="item.ouCode"
                            ></el-option>
                        </el-select>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>
        <div class="title-t">管理内容</div>
        <div class="content-art">
            <div class="item-art" v-for="(item, index) in manageContentList" :key="index">
                <span>{{ item.name }}</span>
                <i class="el-icon-circle-close"></i>
            </div>
            <el-button size="mini" icon="el-icon-plus" @click="createArticle">添加内容</el-button>
        </div>
        <div style="text-align: right;margin: 20px 0; ">
            <el-button size="mini" style="margin-right: 20px" @click="cancelClose">
                取消
            </el-button>
            <el-button type="primary" size="mini" @click="handleClose">确定</el-button>
        </div>
        <article-drawer ref="articleDialog" />
    </div>
</template>

<script>
import articleDrawer from './article-drawer';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-article-group');
import mixins from '@/mixins';
import { uploadConfigData } from '@/config.js';
import { getOssConfig } from '@/api/login.js';
export default {
    name: 'subject-from',
    mixins: [mixins],
    components: { articleDrawer },
    data() {
        return {
            size: 'small',
            isShowCompanys: false,
            columns: [
                {
                    key: 'specialName',
                    name: '专题名称',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入文字描述' }],
                },
                {
                    key: 'url',
                    name: '专题封面',
                    type: 'upload',
                    options: {
                        defaultValue: [],
                        btnText: '上传图片',
                        tip:
                            '建议尺寸：1600*1200像素（根据前端设计稿调整本尺寸），单张图片大小不超过2M',
                        size: 2000,
                        fileSize: 2,
                        limit: 1,
                        fileType: 'jpg,png,jpeg',
                    },
                    rules: [{ required: true, message: '请上传专题封面' }],
                },
                {
                    key: 'categoryId',
                    name: '所属分类',
                    type: 'input',
                },
                {
                    key: 'relevanceLabelParams',
                    name: '标签',
                    type: 'input',
                },
                {
                    key: 'operationIds',
                    name: '运营标签',
                    type: 'input',
                },
                {
                    key: 'topicSource',
                    name: '投放城市',
                    type: 'radio',
                    options: {
                        options: [
                            {
                                value: '1',
                                label: '全国可见',
                            },
                            {
                                value: '2',
                                label: '部分城市可见',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择投放城市' }],
                },
                {
                    key: 'companys',
                    name: '分公司',
                    type: 'hidden',
                },
                {
                    key: 'enabled',
                    name: '是否启用',
                    type: 'radio',
                    options: {
                        options: [
                            {
                                value: '1',
                                label: '启用',
                            },
                            {
                                value: '0',
                                label: '停用',
                            },
                        ],
                        props: {
                            value: 'value',
                            label: 'label',
                        },
                    },
                },
            ],
            result: {
                specialName: '',
                url: [],
                categoryId: '',
                companys: [],
                relevanceLabelParams: [],
                operationIds: [],
                topicSource: '',
                enabled: '1',
            },
            relevanceLabelParams: [],
            manageContentList: [],
            operationIds: [],
            companys: [],
            uploadConfig: {},
        };
    },

    computed: {
        ...mapState(['labelList']),
        ...mapState(['categoryList']),
    },
    watch: {},
    created() {
        this.getOssFn();
    },
    methods: {
        getOssFn() {
            getOssConfig().then(res => {
                if (res?.isSuccess) {
                    uploadConfigData.oss.accessKeyId = res.data.stsKeyId;
                    uploadConfigData.oss.accessKeySecret = res.data.stsSecret;
                    uploadConfigData.oss.stsToken = res.data.token;
                    this.uploadConfig = uploadConfigData;
                }
            });
        },
        createArticle() {
            this.$refs.articleDialog.openDrawer();
        },
        changeFn(e) {
            if (e.row.key == 'topicSource' && e.value == '2') {
                this.isShowCompanys = true;
            } else {
                this.isShowCompanys = false;
            }
        },
        seletChange(val) {
            let obj = {};
            this.companyData.map(item => {
                if (val.includes(item.ouCode)) {
                    obj.companyCode = item.ouCode;
                    obj.companyName = item.ouName;
                }
            });
            this.result.companys.push(obj);
        },
        selectCateChange() {
            let data = this.$refs.cateCascader.getCheckedNodes();
            this.result.categoryName = data[0].data.categoryName;
        },
        selectTagChange() {
            if (this.relevanceLabelParams?.length > 20) {
                this.$message.warning('最多选择20个标签');
                this.result.relevanceLabelParams = this.relevanceLabelParams.slice(0, 20);
            }
            let data = this.$refs.cascader.getCheckedNodes();
            let labelItem = {};
            data.map(item => {
                labelItem.labelId = item.data.id;
                labelItem.labelName = item.data.labelName;
                labelItem.properties = item.data.properties;
                labelItem.groupType = item.pathNodes[0].data.groupType;
            });
            this.result.relevanceLabelParams.push(labelItem);
            console.log(this.result.relevanceLabelParams, '9999');
        },
        selecttOpeTag() {
            if (this.operationIds?.length > 20) {
                this.$message.warning('最多选择20个标签');
                this.result.operationIds = this.operationIds.slice(0, 20);
            }
            let data = this.$refs.operaCascader.getCheckedNodes();
            let labelItem = {};
            data.map(item => {
                labelItem.labelId = item.data.id;
                labelItem.labelName = item.data.labelName;
                labelItem.properties = item.data.properties;
                labelItem.groupType = item.pathNodes[0].data.groupType;
            });
            this.result.operationIds.push(labelItem);
            console.log(this.result.operationIds, '9999');
        },
        cancelClose() {
            this.$emit('closeDrawer');
        },
        handleClose() {
            console.log(this.result, 'result');
        },
    },
};
</script>

<style scoped lang="less">
.must {
    color: red;
    vertical-align: -2px;
}
.title-t {
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
}
.content-art {
    margin: 10px;
    .item-art {
        border: 1px solid #dcdfe6;
        border-radius: 3px;
        margin-bottom: 10px;
        padding: 3px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
