<!-- 设计师主页 -->
<template>
    <layout>
        <div class="">
            <div class="info">
                <img src="" class="avatar" alt="" />
                <div class="right">
                    <div class="value first">设计师张三</div>
                    <div class="bottom">
                        <div class="name">
                            性别:
                            <span class="value">
                                男
                            </span>
                        </div>
                        <div class="name">
                            所属城市:
                            <span class="value">北京</span>
                        </div>
                        <div class="name">
                            身份:
                            <span class="value">设计师</span>
                        </div>
                        <div class="name">
                            手机号:
                            <span class="value">110</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="active" @click="handleJumpTo">
                红玺杯/季度评选 提报 活动
            </div>
            <div class="data">
                <div class="title">个人数据</div>
                <div class="data-wrap">
                    <div class="num">
                        个人主页访问量

                        <p>2343</p>
                    </div>
                    <div class="num">
                        个人主页转发量

                        <p>2343</p>
                    </div>
                </div>
            </div>
            <div class="data">
                <div class="title">
                    内容管理
                </div>

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="已发布案例" name="first"></el-tab-pane>
                    <el-tab-pane label="草稿箱" name="second"></el-tab-pane>
                </el-tabs>
                <div class="">
                    <sc-table-v2
                        title=""
                        :head="tableHead"
                        :data="list"
                        :page="page"
                        :page-hide="false"
                        :pop-show="false"
                        :show-add="false"
                        :border="false"
                        :table-height="height"
                        :read-table="true"
                        :load="false"
                        :stripe="true"
                        :selection="false"
                        @pageChange="pageChange"
                        @pageSizesChange="pageSizesChange"
                    >
                        <template v-slot:control>
                            <el-table-column label="操作" fixed="right" width="170">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        @click="handleEdit(scope.row)"
                                        size="mini"
                                    >
                                        编辑
                                    </el-button>
                                    <el-button
                                        type="text"
                                        @click="handleDelete(scope.row)"
                                        size="mini"
                                    >
                                        删除
                                    </el-button>
                                    <el-button
                                        type="text"
                                        @click="handleInvite(scope.row)"
                                        size="mini"
                                    >
                                        邀请评价
                                    </el-button>
                                </template>
                            </el-table-column>
                        </template>
                    </sc-table-v2>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layout.vue';

export default {
    name: '',
    components: { Layout },
    data() {
        return {
            activeName: 'first',
            tableHead: [
                {
                    name: '内容标题', //表头名称
                    key: 'specialName', //数据列的key值
                },
                { name: '内容类型', key: 'categoryName' },
                { name: '访问量', key: 'categoryName' },
                { name: '平均访问时长', key: 'contentLabelId' },
                { name: '点赞量', key: 'enabled' },
                { name: '收藏量', key: 'createUserName' },
                { name: '评论量', key: 'createTime' },
                { name: '案例发布时间', key: 'createTime' },
                { name: '操作', key: 'control' },
            ],
            height: window.screen.height - 580,
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            list: [{}, {}],
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 180;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    mounted() {},
    methods: {
        handleJumpTo() {
            // 跳转提报奖项列表页面
            this.$router.push({
                path: '/mkt/designer-page/award',
                query: {},
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        pageSizesChange(val) {
            console.log(val);
        },
        pageChange(val) {
            console.log(val);
        },
        handleInvite(row) {
            console.log(row);
        },
        handleDelete(row) {
            console.log(row);
        },
        handleEdit(row) {
            console.log(row);
        },
    },
};
</script>
<style lang="less" scoped>
.mkt {
    padding: 20px;
}
.info {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    .avatar {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-right: 20px;
    }
    .name {
        margin-right: 50px;
    }
    .bottom {
        display: flex;
        .name {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #ababab;
            line-height: 21px;
            text-align: justify;
            font-style: normal;
            .value {
                width: 15px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 15px;
                color: #000000;
                line-height: 21px;
                text-align: justify;
                font-style: normal;
            }
        }
    }
}
.active {
    cursor: pointer;
    height: 104px;
    background: linear-gradient(90deg, #e2e7fa 0%, #c2d5f6 100%);
    border-radius: 4px;
    line-height: 104px;
    text-indent: 36px;
    margin: 20px 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 19px;
    color: #000000;
    text-align: justify;
    font-style: normal;
}
.data {
    background: #ffffff;
    border-radius: 4px;
    padding: 24px 30px;
    margin-bottom: 20px;
    .data-wrap {
        display: flex;
    }
    .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 21px;
        color: #000000;
        line-height: 29px;
        text-align: justify;
        font-style: normal;
        margin-bottom: 20px;
        position: relative;
        text-indent: 10px;
    }
    .title:before {
        position: absolute;
        left: 0;
        top: 6px;
        content: '';
        width: 2px;
        height: 18px;
        background: #407fff;
        border-radius: 1px;
    }
    .num {
        // display: flex;
        width: 200px;
        height: 83px;
        background: #fafafa;
        border-radius: 4px;
        margin-right: 30px;
        padding: 16px 0 16px 28px;
        p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            line-height: 28px;
            text-align: justify;
            font-style: normal;
        }
    }
}
</style>
