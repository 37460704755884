<template>
    <div id="app">
        <iframe-head :open="menu" />
        <div id="product-navbar" class="product-main" :class="{ active: menu }">
            <iframe-left ref="iframeLeft" @menu="menuFn" />
            <div class="g-body">
                <transition name="move" mode="out-in" class="el-main">
                    <slot></slot>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import IframeLeft from '@/components/iframe-left.vue';
import IframeHead from '@/components/iframe-head.vue';
export default {
    name: 'Layout',
    data() {
        return {
            menu: true,
        };
    },
    components: {
        IframeLeft,
        IframeHead,
    },
    mounted() {},
    methods: {
        menuFn() {
            this.menu = !this.menu;
        },
    },
};
</script>

<style lang="less" scoped></style>
