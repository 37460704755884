<template>
    <div class="content">
        <div>
            <sc-tables
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="true"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeight"
                :read-table="true"
                :load="false"
                @change="changeFn"
                @click="clickFn"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
            >
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="editRow(scope.row)">配置</el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-tables>
        </div>
        <Add
            :pageId="pageId"
            :pageName="pageName"
            v-if="addiagvisible"
            :addiagvisible="addiagvisible"
            @closeDiag="closeDiag"
            @init="getData"
        ></Add>
    </div>
</template>

<script>
import { getCheckboxList } from '@/api/mkt/checkbox';
// import { createNamespacedHelpers } from 'vuex';
// const { mapState } = createNamespacedHelpers('mkt-tag-group');
import Add from './add.vue';

export default {
    components: {
        Add,
    },
    data() {
        return {
            tableHead: [
                { name: '页面', value: 'pageName' },
                { name: '主要筛选', value: 'primaryFilter' },
                { name: '次要筛选', value: 'secondaryFilter' },
            ],
            list: [],

            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            page: {
                count: 0, //总条数
                index: 1, //当前页
                sizes: [], //每页显示个数选择器的选项设置，默认[30, 50, 100, 200]
                show: true, //是否显示，默认false
            },
            addiagvisible: false,
            pageId: '',
            pageName: '',
            tableHeight: window.innerHeight - 110,
        };
    },
    computed: {
        // ...mapState(['list']), //// 映射 this.list 为 store.state.list
    },
    mounted() {
        // this.$store.dispatch('mkt-checkbox/list', ''); //使用vuex调用接口
        // this.table.tableData = this.$store.state['mkt-checkbox'].list;
        this.getData();
    },
    methods: {
        async getData() {
            let res = await getCheckboxList();
            console.log('-----------res333', res);
            // this.list = [{ pageId: 1, pageName: '首页' }];
            if (res.code == 200) {
                this.list = res.data;
            }
        },
        // 选中项回调方法
        changeFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        // 点击事件回调方法
        clickFn(data) {
            console.log(data);
            //{
            //    row: row,//行数据
            //    block: data//操作项数据
            //}
        },
        pageChange() {},
        pageSizesChange() {},
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
        editRow(row) {
            this.addiagvisible = true;
            this.pageId = row.pageId;
            this.pageName = row.pageName;
        },
        closeDiag() {
            this.addiagvisible = false;
        },
        saveCheckbox() {
            console.log('---saveCheckbox');
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    margin: 10px 15px;
}
.top-btn-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
</style>
