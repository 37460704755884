<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="addialogVisible"
            direction="rtl"
            custom-class="self-drawer"
            size="38%"
            :wrapperClosable="false"
            :before-close="close"
        >
            <!-- <el-dialog
            :visible.sync="addialogVisible"
            width="30%"
            title="编辑"
            :wrapperClosable="false"
        > -->
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <template v-slot:url>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">背景图</div>
                        </template>
                        <div class="upload-box">
                            <Upload
                                ref="uploadbox"
                                :limitNum="1"
                                :width="90"
                                :height="90"
                                :accept="accept"
                                :fileType="'image'"
                                :ossClientCur="ossClient"
                            ></Upload>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="close">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { addRankingRow, editRankingRow } from '@/api/mkt/home-group';
import getOssMixin from '@/mixins/getOssMixin';
import Upload from '@/components/upload';

export default {
    props: {},
    name: 'detailDrawer',
    mixins: [getOssMixin],
    components: { Upload },
    data() {
        return {
            accept: ['jpg', 'gif', 'jpeg', 'png', 'JPG', 'JPEG'],
            title: '新增榜单分组',
            addialogVisible: false,
            dialogVisible: false,
            columns: {
                designerName: {
                    name: '榜单名称',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入',
                    },
                    rules: [{ required: true, message: '请输入榜单名称', trigger: 'blur' }],
                },
                orderSort: {
                    name: '排序值',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入排序值，数值越小权重越高',
                        inputType: 'number',
                        max: 100,
                    },
                    rules: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
                },
                description: {
                    name: '说明',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                    },
                    rules: [{ required: true, message: '请输入说明', trigger: 'blur' }],
                },
                url: {
                    name: '背景图',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '',
                        disabled: true,
                    },
                },
            },
            result: {
                designerName: '',
                description: '',
                orderSort: '',
                url: '',
            },
        };
    },
    mounted() {},
    methods: {
        async open(row = null) {
            await this.getOss(); //调取mixins中获取
            console.log(row, 'show-----row--------');
            this.addialogVisible = true;
            this.result = { ...row };
            let arr = [];
            if (row && row.url) {
                arr = [{ url: row.url }];
            }
            this.$nextTick(() => {
                this.$refs.uploadbox.setList(arr);
            });
        },
        close() {
            this.$refs.uploadbox.setList([]);
            this.result = {
                designerName: '',
                description: '',
                orderSort: '',
                url: '',
            };
            this.addialogVisible = false;
        },
        // handleClose() {
        //     this.$emit('handleClose');
        // },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                console.log(this.result, 88888);
                if (parseInt(this.result.orderSort) > 100) {
                    this.$message.warning(`排序值不能大于100`);
                    return;
                }
                let update = addRankingRow;
                if (this.result.id) {
                    update = editRankingRow;
                }
                let params = {
                    designerName: this.result.designerName,
                    description: this.result.description,
                    id: this.result.id,
                    url: (this.$refs.uploadbox.imgList[0] || {}).url || '',
                    orderSort: parseInt(this.result.orderSort),
                };
                update(params).then(res => {
                    console.log(res, 'res-----');
                    this.close();
                    this.$emit('handleClose');
                });
            });
        },
        handleReset() {
            this.result = {
                publicData: '',
                job: 0,
                type: 0,
                isDisplay: '',
            };
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'orderSort') {
                this.result.orderSort = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.orderSort);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .upload-box {
    .avatar-uploader-icon {
        font-size: 22px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
