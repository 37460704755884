const columns = {
    contentType: {
        name: '推送类型',
        type: 'select',
        options: {
            placeholder: '请选择推送类型',
            // 1-笔记 2-案例 3-切片 4- 设计师主页 5-合集 6-设计师榜 7-风格测试 8-智能匹配
            options: [
                { sysLabel: '笔记', dictValue: 1 },
                { sysLabel: '案例', dictValue: 2 },
                { sysLabel: '切片', dictValue: 3 },
                { sysLabel: '设计师主页', dictValue: 4 },
                { sysLabel: '合集', dictValue: 5 },
                { sysLabel: '设计师榜', dictValue: 6 },
                { sysLabel: '风格测试', dictValue: 7 },
                { sysLabel: '智能匹配', dictValue: 8 },
                { sysLabel: '灵感集', dictValue: 9 },
            ],
            props: {
                label: 'sysLabel',
                value: 'dictValue',
            },
        },
        rules: [{ required: true, message: '请选择推送类型', trigger: 'blur' }],
    },
    vacancy: {},
    contentId: {
        name: '内容ID',
        type: 'input',
        options: {
            placeholder: '内容ID',
            defaultValue: '',
            disabled: true,
        },
        disabled: true,

        rules: [{ required: true, message: '请输入内容ID', trigger: 'blur' }],
    },
    contentTitle: {
        name: '内容标题',
        type: 'input',
        options: {
            placeholder: '内容标题',
            defaultValue: '',
            disabled: true,
        },
        // rules: [{ required: true, message: '请输入内容ID', trigger: 'blur' }],
    },
    title: {
        name: '消息标题',
        type: 'input',
        options: {
            placeholder: '消息标题',
            defaultValue: '',
            maxlength: 20,
            showWordLimit: true,
            // disabled: true,
        },
    },
    describe: {
        name: '描述',
        type: 'input',
        options: {
            placeholder: '描述',
            defaultValue: '',
            maxlength: 100,
            showWordLimit: true,
            // disabled: true,
        },
    },
    contentCover: {},
    sendMode: {
        name: '发送方式',
        type: 'radio',
        options: {
            options: [
                { sysLabel: '立即发送', dictValue: 1 },
                { sysLabel: '定时发送', dictValue: 2 },
            ],
            props: {
                label: 'sysLabel',
                value: 'dictValue',
            },
            placeholder: '',
            defaultValue: '',
            // disabled: true,
        },
    },
    sendDate: {
        name: '发送时间',
        type: 'date',
        options: {
            dateType: 'datetime',
            format: 'yyyy-MM-DD hh:mm',
            valueFormat: 'yyyy-MM-DD hh:mm',
            placeholder: '请选择年-月-日 时:分',
            disabled: true,
        },

        // type: 'date',
        // options: {
        //     dateType: 'month',
        //     format: 'yyyy/MM',
        //     valueFormat: 'yyyy/MM',
        //     placeholder: '请选择年/月',
        //     clearable: true,
        // },
    },
    sendCrowd: {},
    appointor: {
        name: '指定人',
        type: 'select',
        options: {
            defaultValue: '',
            defaultValueBase: '',
            placeholder: '请选择指定人',
            options: [],
            props: {
                label: 'showContent',
                value: 'accountId',
            },
            multiple: true,
            checkStrictly: true,
            filterable: true,
            emitPath: false,
            disabled: false,
        },
    },
    sendNumber: {},
};

export { columns };
