<template>
    <!-- 设计师榜快捷入口 -->
    <div class="mkt" ref="table">
        <div class="tabs">
            <el-tabs v-model="tabActive" @tab-click="tabClick">
                <el-tab-pane
                    :label="item.designerName"
                    :key="item.id"
                    :name="item.id"
                    v-for="(item, index) in tabList"
                ></el-tab-pane>
            </el-tabs>

            <sc-search-v2
                :columns="columns"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="false"
                @change="changeFn"
                @search="searchFn"
                @click-more="handleClickMore"
                ref="form1"
            ></sc-search-v2>

            <div class="btns">
                <el-button class="btns-button" size="small" type="primary" @click="onEdit()">
                    新增设计师榜
                </el-button>
            </div>
            <div class="tabs">
                <sc-table-v2
                    class="list-table"
                    :head="tableHead"
                    :data="listData"
                    :page="page"
                    :page-hide="true"
                    :pop-show="false"
                    :show-add="false"
                    :show-add-config="tableAddConfig"
                    :table-height="tableHeightCalc"
                    :read-table="true"
                    :load="isLoading"
                    @change="changeFn"
                    @click="clickFn"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                    @rowChange="rowChange"
                    @dbClick="dbClick"
                    @rowClick="rowClick"
                    @selection-change="selectionChange"
                >
                    <template v-slot:deag>
                        <el-table-column label="" width="60" align="center">
                            <i class="el-icon-sort icon" style="font-size:20px"></i>
                        </el-table-column>
                    </template>
                    <template v-slot:url>
                        <el-table-column label="背景图">
                            <template slot-scope="scope">
                                <el-image
                                    :src="scope.row.url"
                                    fit="contain"
                                    style="width: 50px; height: 50px"
                                    :preview-src-list="[scope.row.url]"
                                ></el-image>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:isShelf>
                        <el-table-column label="是否上架">
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.isShelf"
                                    active-text="上架"
                                    inactive-text="下架"
                                    @change="handleChangeIsShelf($event, scope.row)"
                                    active-value="0"
                                    inactive-value="1"
                                ></el-switch>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:control>
                        <el-table-column label="操作" fixed="right" width="160">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="tbOnUpdate(scope.row)">
                                    更新
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="tbAllocation(scope.row)"
                                >
                                    配置
                                </el-button>
                                <el-button type="text" size="small" @click="tbOnEdit(scope.row)">
                                    编辑
                                </el-button>
                                <el-button
                                    type="text"
                                    style="color: red"
                                    size="small"
                                    @click="tbOnDel(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </template>
                </sc-table-v2>
            </div>
        </div>

        <Allocation ref="allocation" @alloCationClose="alloCationClose"></Allocation>
        <edit-dialog ref="editDialog" @handleClose="handleClose"></edit-dialog>
    </div>
</template>

<script>
import editDialog from './components/detail.vue';
import Allocation from './components/allocation.vue';
import mixins from '@/mixins';
import getListMixin from '@/mixins/getListMixin';
import Sortable from 'sortablejs';
import {
    getUserDesignerCollectionList,
    getCollectionDelete,
    collectionUpdateNew,
    collectionSort,
    isShelfRanking,
} from '@/api/mkt/designer-ranking-quick.js';

// 老接口
// import {
//     getRankingRowList,
//     getRankingList,
//     delRanking,
//     sortRanking,
// } from '@/api/mkt/home-group.js';
export default {
    name: 'staff-index',
    mixins: [mixins, getListMixin],
    components: { editDialog, Allocation },
    data() {
        return {
            tabActive: 'first',
            tabList: [],
            tableHandle: this.userDesignerCollectionList,
            isLoading: false,
            moreDown: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            LS: { 'min-width': '80px' }, //设置label宽度
            CS: { width: '500px', 'padding-right': '10px' },
            tableHead: [
                { name: '1', key: 'deag' },
                {
                    name: 'ID',
                    key: 'id',
                    width: '156px',
                },
                { name: '标题', key: 'designerName' },
                { name: '副标题', key: 'subtitle' },
                { name: '封面', key: 'url' },
                { name: '风格', key: 'style' },
                { name: '房屋类型', key: 'houseType' },
                { name: '面积', key: 'area' },
                { name: '创建时间', key: 'createTime' },
                { name: '是否上架', key: 'isShelf' },

                {
                    name: '操作',
                    key: 'control',
                    align: 'center',
                    width: '80',
                },
            ],
            listData: [],
            page: {
                size: 999, //每页多少条数据
                page: 1, //当前页码
                count: 0, //数据总条数
                index: 1,
            },
            tableAddConfig: {},
            filter: {},
            columns: {
                designerName: {
                    name: '标题',
                    type: 'input',
                    options: {
                        defaultValue: '',
                        clearable: true,
                        placeholder: '请选择',
                    },
                    spanCol: 1,
                },
            },
            result: {},
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清除搜索', //第二个按钮名称
                twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
        };
    },
    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 240;
            console.log(this.tableHeightCalc, window.innerHeight, this.$refs.table.offsetTop, '38');
        });
        this.init();
    },
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 240;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.table.offsetTop - 240;
        },
    },
    mounted() {
        const tbody = document.querySelector('.list-table tbody');
        let that = this;
        Sortable.create(tbody, {
            handle: '.icon', // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
            onEnd({ newIndex, oldIndex }) {
                if (newIndex != oldIndex) {
                    that.listData.splice(newIndex, 0, that.listData.splice(oldIndex, 1)[0]);
                    var newArray = that.listData.slice(0);
                    that.listData = [];
                    that.$nextTick(function() {
                        that.listData = newArray;
                        // 拖拽事件结束
                        let list = [];
                        that.listData.map(item => list.push(item.id));
                        collectionSort(list);
                        console.log('move', newIndex, oldIndex, that.listData);
                    });
                }
            },
        });
    },
    methods: {
        async init() {
            this.page.page = 1;
            this.page.size = 999;
            this.userDesignerCollectionList();
        },

        async userDesignerCollectionList(type) {
            let params = {};
            if (type == 'search') {
                params.designerName = this.result.designerName;
            }

            params.page = this.page.page;
            params.size = this.page.size;

            this.isLoading = true;
            await getUserDesignerCollectionList(params)
                .then(res => {
                    if (res.data.children) {
                        res.data.children.map(item => {
                            item.isShelf = String(item.isShelf);
                        });
                        this.listData = res.data.children;
                        this.page.count = res.data.total;
                    }
                    this.isLoading = false;
                })
                .catch(err => {
                    this.isLoading = false;
                });
        },

        async handleChangeIsShelf(val, row) {
            let res = await isShelfRanking({
                id: row.id,
                shelf: val,
            });
            if (res) {
                this.$message.success(val == 0 ? '上架成功' : '下架成功'); //接口0上架；1下架
                this.userDesignerCollectionList();
            }
        },
        handleClickMore() {},
        handleClose() {
            this.getList();
        },

        // async getList() {
        //     if (!this.filter.collectionId) {
        //         return false;
        //     }
        //     let data = this.filter;
        //     let res = await getRankingList(data);
        //     this.listData = res.data;
        // },
        tabClick(tab, event) {
            this.tabActive = tab.name;
            this.filter = {
                collectionId: this.tabActive,
            };
            this.getList();
        },

        addTag(row) {
            row.isLabel = true;
            this.$refs.editDialog.open(row);
        },
        tbOnUpdate(row) {
            collectionUpdateNew({ id: row.id }).then(res => {
                this.$message.success('更新成功');
                this.init();
            });
        },
        tbAllocation(row) {
            this.$router.push({ path: `designer-ranking?id=${row.id}` });
        },
        onEdit(row) {
            this.$refs.allocation.openDrawer(row, 'add', '新增设计师榜');
        },
        tbOnEdit(row = {}) {
            this.$refs.allocation.openDrawer(row, 'edit', '编辑设计师榜');
            // row.collectionId = this.tabActive;
            // this.$refs.editDialog.open(row);
        },
        tbOnDel(row) {
            this.$confirm(`确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                getCollectionDelete({ id: row.id }).then(res => {
                    this.$message.success('删除成功');
                    this.init();
                });
            });
        },
        searchFn() {
            console.log('搜索', ',,,,,,,,,,,,,,,');
            console.log(this.result, 1111111111);
            // designerName
            this.userDesignerCollectionList('search');
        },
        alloCationClose() {
            this.init();
            this.$refs.allocation.handleClose();
        },
        rowClick() {},
        dbClick(row) {
            this.$router.push({ path: `designer-ranking?id=${row.id}` });
        },
        click() {},
        rowChange() {},
        selectionChange() {},
        changeFn(data) {},
        // 点击事件回调方法
        clickFn(data) {},
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
.btns {
    padding: 20px 0px 0px 25px;
    position: relative;

    .btns-button {
        padding-left: 20px;
    }
}

.button-back {
    position: absolute;
    right: 0;
    margin-right: 10px;
}
.mkt {
    margin-top: -10px !important;
}
</style>
