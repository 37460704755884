<template>
    <div class="tag-group-container">
        <Search></Search>
        <Content></Content>
    </div>
</template>

<script>
import Search from './search';
import Content from './content';

export default {
    name: 'article',
    components: { Search, Content },

    data() {
        return {};
    },
    beforeDestroy() {
        this.$store.commit('mkt-content-article/clear');
    },
    methods: {},
};
</script>
<style lang="less" scoped>
.tag-group-container {
    background: #fff;
}
.tag-group-title {
    padding: 20px 0 0 30px;
    font-size: 18px;
}
</style>
