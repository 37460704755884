<template>
    <el-drawer :visible.sync="exhibitDiag" size="50%" append-to-body :before-close="handleClose">
        <div ref="searchBox">
            <sc-search-v2
                :columns="columns"
                :columnsNum="2"
                :clientFalg="false"
                :result.sync="result"
                :LS="LS"
                :btn="btn"
                :size="size"
                :moreDown="true"
                @search="searchFn"
            ></sc-search-v2>
        </div>
        <div v-loading="loading">
            <sc-tables
                :head="table.tableHead"
                :data="table.tableData"
                :page="page"
                :page-hide="true"
                :upload-config="table.uploadConfig"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeight"
                :read-table="true"
                :load="false"
                @selection-change="selectChange"
            >
                <template v-slot:special-column>
                    <el-table-column
                        label-class-name="disabledSelection"
                        type="selection"
                        width="55"
                        :selectable="checkboxInit"
                    ></el-table-column>
                </template>
            </sc-tables>
        </div>
        <div class="bottom-btn">
            <el-button type="primary" size="mini" @click="submit">选择</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getDecspec, getDectopic, getDecdesign } from '@/api/mkt/market.js';

export default {
    props: {
        exhibitDiag: {
            type: Boolean,
            default: false,
        },
        diagType: {
            type: String,
            default: '',
        },
        outType: {
            type: String,
            default: '',
        },
    },
    watch: {
        diagType: {
            handler(val) {
                if (this.$refs?.searchBox) {
                    this.tableHeight = window.innerHeight - this.$refs.searchBox.offsetHeight - 200;
                }
                this.result.title = '';
                this.mapType(val, this.result.title);
                if (val === '设计师' || val === 'innerDesign') {
                    this.table.tableHead = this.tableHeadesign;
                } else {
                    this.table.tableHead = this.tableHead;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            columns: {
                title: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '请输入',
                    },
                    spanCol: 1,
                },
            },
            tableHeight: 600,
            result: {
                title: '',
            },
            LS: { 'min-width': '80px' }, //设置label宽度
            CS: { width: '80px', 'padding-right': '0px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空筛选', //第二个按钮名称
                twoShow: false, //隐藏第二个按钮
            },
            size: 'small',
            table: {
                tableHead: [],
                tableData: [],
                uploadConfig: {
                    //上传组件相关配置项
                    oss: {
                        url: '', //oss访问域名
                        accessKeyId: '', //接口返回的keyId
                        accessKeySecret: '', //接口返回的keySecret
                        stsToken: '', //接口返回的token
                        bucket: 'sczs',
                        region: 'oss-cn-beijing',
                    },
                    ENV: 'test', //环境变量（保存文件到对应文件夹下）【dev、test、pre、master】
                    fileModule: 'market', //业务模块名称（保存文件到对应文件夹下）
                },
            },
            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHead: [
                { name: '名称', value: 'title', formatter: this.titleFormatter },
                // { name: '封面', value: 'atlas' },
                // { name: '所属分类', value: 'categoryName' },
                // { name: '标签', value: 'labelName' },
                // { name: '运营标签', value: 'operationName' },
                // { name: '审核状态', value: 'approvalStatus' },
                // { name: '创建人', value: 'createName' },
                // { name: '所属分公司', value: 'companyName' },
            ],
            tableHeadesign: [
                { name: '用户姓名', value: 'name' },
                // { name: '用户类型', value: 'userType' },
                { name: 'BPM账号', value: 'account' },
                { name: '岗位', value: 'job' },
                // { name: '用户标签', value: 'tag' },
                // { name: '贡献内容', value: 'aa' },
                // { name: '用户坐标', value: 'city' },
                // { name: '首次登录时间', value: 'firstLoginTime' },
            ],
            page: {
                count: 0, //总条数
                index: 1, //当前页
                sizes: [], //每页显示个数选择器的选项设置，默认[30, 50, 100, 200]
                show: true, //是否显示，默认false
            },
            selectRowlist: [],
            loading: false,
        };
    },
    mounted() {},
    methods: {
        selectChange(rows) {
            this.selectRowlist = rows;
        },
        async getDecspec() {
            const res = await getDecspec();
            if (res?.isSuccess) {
                this.table.tableData = res.data.children;
            }
        },
        checkboxInit(row) {
            if (this.selectRowlist?.length) {
                if (JSON.stringify(row) == JSON.stringify(this.selectRowlist[0])) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 1;
            }
        },
        async getDectopic() {
            const res = await getDectopic();
            if (res?.isSuccess) {
                this.table.tableData = res.data.children;
            }
        },
        async getDecdesign() {
            let type = this.outType === '笔记' ? 1 : 2;
            const res = await getDecdesign(type);
            if (res?.isSuccess) {
                this.table.tableData = res.data.children;
            }
        },
        searchFn(e) {
            const { title } = e;
            this.mapType(this.diagType, title);
        },
        mapType(type, title) {
            this.loading = true;
            switch (type) {
                case '笔记':
                    this.$store.commit('mkt-content-article/setParams', {
                        keywords: title,
                        approvalStatus: 3,
                    });
                    this.$store.dispatch('mkt-content-article/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-content-article'].list;
                    }, 500);
                    break;
                case '案例':
                    this.$store.commit('mkt-content-exhibit/setParams', {
                        search: title,
                        approvalStatus: 3,
                    });
                    this.$store.dispatch('mkt-content-exhibit/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-content-exhibit'].list;
                    }, 500);
                    break;
                case '图片':
                case '探索灵感':
                    this.$store.commit('mkt-content-exhibit-group/setParams', { search: title });
                    this.$store.dispatch('mkt-content-exhibit-group/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-content-exhibit-group'].list;
                    }, 500);
                    break;
                case '专题':
                    this.$store.commit('mkt-article-group/setParams', { specialName: title });
                    this.$store.dispatch('mkt-article-group/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-article-group'].list;
                    }, 500);
                    break;
                case '话题':
                    this.$store.commit('mkt-topic/setParams', { topicName: title, enabled: 1 });
                    this.$store.dispatch('mkt-topic/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-topic'].list;
                    }, 500);
                    break;
                case '设计师':
                    this.$store.commit('mkt-user/setParams', { searchName: title });
                    this.$store.dispatch('mkt-user/list');
                    setTimeout(() => {
                        this.table.tableData = this.$store.state['mkt-user'].list;
                    }, 500);
                    break;
                case 'innerSub':
                    this.getDecspec();
                    break;
                case 'innerTop':
                    this.getDectopic();
                    break;
                case 'innerDesign':
                    this.getDecdesign();
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        handleClose() {
            this.$emit('closeDiag');
        },
        changeFn() {},
        clickFn() {},
        titleFormatter(row) {
            return row?.title || row?.inspirationName || row?.specialName || row?.topicName;
        },
        submit() {
            if (this.selectRowlist?.length !== 1) {
                this.$message.warning('请选择一条数据');
                return;
            } else {
                switch (this.diagType) {
                    case '笔记':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].articleId,
                            title: this.selectRowlist[0].title,
                            type: 2,
                        });
                        break;
                    case '案例':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].title,
                            type: 3,
                        });
                        break;
                    case '探索灵感':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].inspirationName,
                            type: 3,
                        });
                        break;
                    case '专题':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].specialName,
                            type: 3,
                        });
                        break;
                    case '话题':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].topicId,
                            title: this.selectRowlist[0].topicName,
                            type: 3,
                        });
                        break;
                    case '设计师':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].name,
                            type: 3,
                        });
                        break;
                    case 'innerSub':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].specialName,
                            type: 3,
                            origin: '专题',
                        });
                        break;
                    case 'innerTop':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].topicId,
                            title: this.selectRowlist[0].topicName,
                            type: 3,
                            origin: '话题',
                        });
                        break;
                    case 'innerDesign':
                        this.$emit('closeDiag', {
                            id: this.selectRowlist[0].id,
                            title: this.selectRowlist[0].name,
                            type: 4,
                            origin: '设计师',
                        });
                        break;
                    default:
                        break;
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .self-drawer {
    padding: 0 20px;
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
</style>
<style>
.disabledSelection > .el-checkbox {
    display: none;
}
</style>
