<template>
    <div>
        <div class="cropper-content">
            <!-- 这里有一个上传的逻辑 -->
            <div class="cropper" style="text-align: center; height: 800px;width:800px;">
                <div v-loading="loading">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.outputSize"
                        :outputType="option.outputType"
                        :info="option.info"
                        :canScale="option.canScale"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixedBox="option.fixedBox"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :centerBox="option.centerBox"
                        :infoTrue="option.infoTrue"
                        :full="option.full"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        @realTime="realTime"
                    ></vue-cropper>
                </div>
                <div style="height:130px; margin: 10px 0 0;display: flex;overflow-x: scroll;">
                    <el-form v-if="!uploadImg">
                        <el-form-item label="">
                            <el-upload
                                class="upload-demo"
                                ref="upload"
                                list-type="picture-card"
                                action="*"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :http-request="fnUploadRequest.bind(this)"
                                :on-exceed="handleExceed"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div class="upload-img" v-if="uploadImg">
                        <el-image
                            :src="uploadImg"
                            style="width:100px;height:100px;margin-right:10px;"
                        ></el-image>
                        <i class="el-icon-close" @click="handleDel"></i>
                    </div>
                    <div style="margin-right: 10px;height:100px;"></div>
                    <el-image
                        v-for="(item, ind) in showImgList"
                        :src="item"
                        :key="ind"
                        style="width:100px;height:100px;margin-right:10px;flex-shrink: 0;"
                        fit="contain"
                        @click="changeImg(item)"
                    ></el-image>
                    <!-- <el-button @click="handleChangeSize(1)" size="mini">放大</el-button>
                    <el-button @click="handleChangeSize(-1)" size="mini">缩小</el-button>
                    <el-button @click="handleLeft" size="mini">向左旋转</el-button>
                    <el-button @click="handleRight" size="mini">向右旋转</el-button> -->
                </div>
            </div>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancle">取消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import { VueCropper } from 'vue-cropper';
// import DEV from '@list/config.js';
import { getOssConfig } from '@/api/login';
import { oss } from '@/config.js';
import { ossToBase } from '@/api/mkt/exhibit';
import OSS from 'ali-oss';
import { formatTimeTwo } from '@/utils/util';
// 1、封面图只展示在商场组件位置 不做回显。
// 2、默认回显列表第一张图作为封面
export default {
    name: 'clip-img',
    components: {
        VueCropper,
    },
    props: {
        appendToBody: {
            type: Boolean,
            default: false,
        },
        showImgList: {
            type: Array,
            default: () => [],
        },
        cacheImgUrl: {
            type: String,
            default: '',
        },
        init: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        showImgList: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch showImgList', newV);
                let that = this;
                if (newV?.length) {
                    this.loading = true;
                    that.ossToBase(newV[0], true);
                    return;
                }
                that.option.img = '';
            },
        },
        cacheImgUrl: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch cacheImgUrl', newV);
                this.uploadImg = newV;
            },
        },
        init: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch initClip', newV);
                this.initCanvas = newV;
            },
        },
    },

    data() {
        return {
            uploadImg: '', //默认封面图
            loading: false, //加载base文件
            dialogVisiblex: false,
            initCanvas: false, //初始
            option: {
                // img: '', // 裁剪图片的地址 url 地址, base64, blob
                img: '', // 裁剪图片的地址 url 地址, base64, blob

                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'jpeg', // 裁剪生成图片的格式
                info: true, // 裁剪框的大小信息
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 400, // 默认生成截图框宽度
                autoCropHeight: 400, // 默认生成截图框高度
                fixedNumber: [2, 2], // 截图框的宽高比例
                fixedBox: true, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                canMove: true, // 上传图片是否可以移动
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                full: true, // 是否输出原图比例的截图
                enlarge: '1', // 图片根据截图框输出比例倍数
                mode: 'contain', // 图片默认渲染方式
            },
            fileList: [],
            local: { obj: {} },
            filesuffix: '',
        };
    },

    mounted: function() {
        //oss签名
        getOssConfig().then(res => {
            if (res.code == 200) {
                let ossConfig = res.data;
                this.ossClient = new OSS({
                    accessKeyId: ossConfig.stsKeyId,
                    accessKeySecret: ossConfig.stsSecret,
                    stsToken: ossConfig.token,
                    bucket: oss.bucket,
                    region: oss.region,
                });
            }
        });
    },
    methods: {
        // 裁剪之后的数据
        realTime() {
            this.$refs.cropper.getCropData(data => {
                // 初始化不做对外更新
                if (!this.initCanvas) {
                    this.$emit('selectImg', data, this.filesuffix);
                } else {
                    // console.log('---------------this.initCanvas true', this.option.img);
                }
            });
        },
        handleDel() {
            this.uploadImg = '';
            // this.option.img = '';
            this.$emit('selectImg', '', '');
        },
        changeImg(item) {
            // this.option.img = item;
            this.loading = true;
            this.ossToBase(item);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件`);
            return;
        },
        handleCancle() {
            this.dialogVisiblex = false;
            this.fileList = [];
        },
        fnUploadRequest(option) {
            let file = option.file;
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let tempCheckpoint;
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${oss.folderName}/${date}/${this.file}.${this.filesuffix}`;
            let result = this.ossClient.multipartUpload(url, file, {
                checkpoint: tempCheckpoint,
                // 设置并发上传的分片数量。
                parallel: 1,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 2 * 1024 * 1024,
                meta: {
                    year: 2022,
                    people: 'test',
                },
                mime: 'text/plain',
            });
            result.then(res => {
                if (res.name) {
                    console.log('上传阿里云成功', res);
                    file.url = 'https://sczs.voglassdc.com/' + res.name;
                    // this.imgList.push(file);
                    this.ossToBase(file.url);
                    // this.option.img = file.url;

                    this.uploadImg = file.url;
                }
            });
        },
        beforeUpload(file) {
            console.log(file, 'file-------');
            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            this.filesuffix = file.name.substring(first + 1, file.name.length);
            if (!['jpg', 'jpeg', 'png'].includes(this.filesuffix)) {
                this.$message.warning(`图片格式不对`);
                this.fileList = [];
                return false;
            }
            if (file.size > 5 * 1024 * 1024) {
                // 判断文件大小
                this.$message.warning(`文件超过5M了`);
                this.fileList = [];
                return false;
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            console.log('--------transBase64FromImage');
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width, image.height);
            // 可选其他值 image/jpeg
            return canvas.toDataURL('image/jpeg');
        },
        // 设置需要展示的图片  base64    (等效于ossToBase)
        async setAvatarBase64(src, init) {
            console.log('--------setAvatarBase64');
            // let _this = this;
            // let image = new Image();
            // // 支持跨域图片
            // image.crossOrigin = 'Anonymous';
            // // 处理缓存
            // if (src.indexOf('?') > -1) {
            //     image.src = src + '&v=' + Math.random();
            // } else {
            //     image.src = src + '?v=' + Math.random();
            // }
            // image.onload = function() {
            //     let base64 = _this.transBase64FromImage(image);
            //     console.log('--------base64', base64);

            //     callback && callback(base64);
            // };
        },
        async ossToBase(src, init) {
            let res = await ossToBase({ url: src });
            if (!init) {
                //watch时 不改变initCanvas 不会触发裁剪方法 realTime
                this.initCanvas = false;
            }
            if (res.isSuccess) {
                console.log(res, ossToBase);
                this.option.img = ' data:image/jpg;base64,' + res.data;
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.cropper-content {
    width: 800px;
    height: 650px;
}
.cropper .vue-cropper {
    height: 500px !important;
}
/deep/ .el-form-item {
    flex-shrink: 1;
}
/deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
}
/deep/ .el-upload-list__item-thumbnail,
/deep/ .el-upload-list__item-actions,
/deep/ .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
}
.upload-img {
    position: relative;
    width: 100px;
    height: 100px;
    .el-icon-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: #333;
        background: #fff;
    }
}
</style>
