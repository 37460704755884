<template>
    <el-dialog
        title="设置封面"
        :visible.sync="visible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="840px"
        top="50px"
        ref="clip-img"
        :append-to-body="true"
        :before-close="close"
    >
        <clip-img
            :showImgList="showImgList"
            @selectImg="selectImg"
            :cacheImgUrl="cacheImgUrl"
            :init="visible"
            :closeUpload="closeUpload"
            :fixedChange="fixedChange"
            ref="clipImg"
        ></clip-img>
        <div slot="footer" class="dialog-footer" style="margin-top:20px;">
            <el-button @click="close" size="small">取消</el-button>
            <el-button type="primary" @click="finish" size="small">
                确认
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import { baseToOss } from '../../../../../api/mkt/exhibit';
import { formatTimeTwo } from '@/utils/util';
import { oss } from '@/config.js';
import clipImg from '../../../../../components/clip-img.vue';
export default {
    name: 'set-cover',
    components: { clipImg },

    data() {
        return {
            visible: false,
            loading: false,
            cacheImgUrl: '',
            showImgList: [],
            closeUpload: false,
            fixedChange: false,
        };
    },
    created() {},
    methods: {
        open(showImgList, cacheImgUrl, close, fixedChange) {
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.clipImg.clear();
                if (cacheImgUrl) {
                    this.cacheImgUrl = cacheImgUrl;
                }
                if (showImgList?.length > 0) {
                    this.showImgList = showImgList;
                }
                if (close) {
                    this.closeUpload = true;
                }
                if (fixedChange) {
                    this.fixedChange = fixedChange;
                }
            });
        },

        finish() {
            let that = this;
            this.$refs.clipImg.$refs.cropper.getCropData(url => {
                console.log(url, '99999');
                let date = formatTimeTwo(new Date(), 'YMD');
                let filePath = `${oss.folderName}/${date}/`;
                baseToOss({
                    base64FileStr: url, //进入弹窗肯定存新的base64 所以用新地址 this.cacheImgUrl
                    filePath: filePath,
                }).then(res => {
                    let img = new Image();
                    img.src = res.data.webUrl;
                    img.onload = function() {
                        console.log(
                            '-----------mainImage width height',
                            res.data.webUrl,
                            img.width,
                            img.height
                        );
                        that.$emit('coverImgUrl', {
                            url: res.data.webUrl,
                            coverWidth: img.width,
                            coverHeight: img.height,
                        });
                        that.close();
                    };
                });
            });
            console.log(123, 111111);
            return;
            // 上传图片
            if (!this.cacheImgUrl) {
                this.$emit('coverImgUrl', '');
                return;
            }
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${oss.folderName}/${date}/`;
            baseToOss({
                base64FileStr: this.cacheImgUrl, //进入弹窗肯定存新的base64 所以用新地址 this.cacheImgUrl
                filePath: url,
            }).then(res => {
                // this.loading = false;
                this.$emit('coverImgUrl', res.data.webUrl);
                this.close();
                // this.$set(this.result, 'cover', res.data.webUrl);
            });
        },
        selectImg(imgUrl, type) {
            this.cacheImgUrl = imgUrl;
            this.filesuffix = type;
        },
        close() {
            this.visible = false;
            this.showImgList = [];
            this.cacheImgUrl = '';
        },
    },
};
</script>
