<template>
    <div class="upload-container">
        <div v-if="fileType == 'image'" class="upload-row">
            <draggable
                v-model="imgList"
                handle=".mover"
                animation="300"
                @start="onStart"
                @end="onEnd"
            >
                <transition-group class="img-box">
                    <div
                        class="img-item-box mover"
                        v-for="(item, index) in imgList"
                        :key="item && item.uid + item.id"
                    >
                        <span>
                            <img
                                :src="item.url"
                                :style="{
                                    width: width + 'px',
                                    height: height + 'px',
                                    objectFit: objectFit,
                                }"
                                alt=""
                            />

                            <span class="el-upload-operation" @click="handleImgPreview(item.url)">
                                <i class="el-icon-zoom-in"></i>
                                <i
                                    class="el-icon-delete"
                                    v-if="delShowCtl"
                                    @click.stop="handDelimg(index, item)"
                                ></i>
                            </span>
                        </span>
                    </div>
                </transition-group>
            </draggable>
            <el-upload
                v-show="(limitLength == 1 && !imgList.length) || limitLength != 1"
                action=""
                :before-upload="beforeUploadDemo"
                multiple
                :limit="limitLength"
                :on-exceed="handleExceedDemo"
                :file-list="imgList"
                :show-file-list="false"
                :http-request="fnUploadRequest.bind(this, fileType)"
                :accept="accepts"
            >
                <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="flex-box" v-if="fileType == 'video'">
            <div
                class="img-item-box"
                v-for="(item, index) in imgList"
                :key="item && item.uid + item.id"
                :style="{ width: width + 'px', height: height + 'px' }"
            >
                <img
                    :src="item.videoUrl || item.url"
                    :style="{ width: width + 'px', height: height + 'px', objectFit: objectFit }"
                    alt=""
                />
                <span class="el-upload-operation" @click="handleImgPreview(item.url)">
                    <i class="el-icon-zoom-in"></i>
                    <i class="el-icon-delete" @click.stop="handDelimg(index)"></i>
                </span>
            </div>
            <div v-if="fileType == 'video' && imgList.length" class="edit-btn">
                <div class="text">默认使用视频第一帧作为封面，支持上传</div>
                <!-- 从视频中截取或 -->
                <el-button size="mini" @click="edit">
                    编辑封面
                </el-button>
            </div>
            <el-upload
                action=""
                :before-upload="beforeUploadDemo"
                multiple
                :limit="limitLength"
                :on-exceed="handleExceedDemo"
                :file-list="imgList"
                :show-file-list="false"
                :http-request="fnUploadRequest.bind(this, fileType)"
                v-if="fileType == 'video' && !imgList.length"
                :accept="accepts"
            >
                <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div class="tip" v-if="tip">{{ tip }}</div>
        <div class="percen-box" v-if="showPercen">
            <el-progress :percentage="videoPercentage"></el-progress>
        </div>

        <div class="imgTooltip">{{ uploadTips }}</div>
        <el-dialog append-to-body :visible.sync="previewDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <video-view ref="videoViewRef"></video-view>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import { getOssConfig } from '@/api/login.js';
import { oss } from '@/config.js';
import OSS from 'ali-oss';
import { formatTimeTwo } from '@/utils/util';
import videoView from '@/components/video-view.vue';

export default {
    components: {
        draggable,
        videoView,
    },
    props: {
        rowIndex: {
            type: [Number, String],
            default: '',
        },
        showImgList: {
            type: Array,
            default: () => [],
        },
        uploadTips: {
            type: String,
            default: '',
        },
        limitNum: {
            type: Number,
            default: 1,
        },
        width: {
            type: Number,
            default: 148,
        },
        height: {
            type: Number,
            default: 148,
        },
        path: {
            type: String,
            default: '',
        },
        accepts: {
            type: String,
            default: '',
        },
        accept: {
            type: Array,
            default: () => [],
        }, //允许上传文件后缀类型
        fileType: {
            type: String,
            default: 'image',
        }, //文件区分 image/video
        ossClientCur: {
            type: Object,
            default: () => {},
        },
        showPercen: {
            type: Boolean,
            default: false,
        },
        deleteConfirm: {
            type: Function,
            default: null,
        },
        delShowCtl: {
            type: Boolean,
            default: true,
        },
        objectFit: {
            type: String,
            default: 'cover',
        },
        tip: {
            type: String,
            default: '',
        },
        fileSize: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            imgList: [],
            previewDialogVisible: false,
            dialogImageUrl: '',
            drag: false, // 拖拽事件
            ossClient: {},
            mainImage: { width: '', height: '' }, //主图宽高
            videoPercentage: 0, // 进度条百分比
        };
    },
    watch: {
        showImgList: {
            handler: function(nVal) {
                this.imgList = nVal;
            },
            deep: true,
            immediate: true,
        },
        ossClientCur: {
            handler(val) {
                // if (val) {
                this.ossClient = val;
                console.log('-----------watch ossClientCur');
                // }
            },
            immediate: true,
        },
    },
    computed: {
        limitLength() {
            console.log(this.limitNum, 'computed');
            return this.limitNum;
        },
    },
    mounted() {
        //oss签名
    },
    methods: {
        setList(list) {
            console.log('-------setList', list);
            this.imgList = list;
            this.$forceUpdate();
        },
        // 开始拖拽事件
        onStart() {
            this.drag = true;
        },
        // 拖拽结束事件
        onEnd(e) {
            this.drag = false;
            console.log(this.imgList, '99999');
            this.$emit('sortImgList', this.imgList);
        },
        handleImgPreview(url) {
            if (this.fileType == 'video') {
                this.$refs.videoViewRef.videoViewOpen((this.imgList[0] || {}).url || '');
                return;
            }
            this.dialogImageUrl = url;
            this.previewDialogVisible = true;
        },
        handDelimg(index, item) {
            if (this.deleteConfirm) {
                this.deleteConfirm(item, () => {
                    this.imgList.splice(index, 1);
                    this.videoPercentage = 0;
                });
            } else {
                this.imgList.splice(index, 1);
                this.videoPercentage = 0;
            }
            this.$emit('delImg', item);
        },
        beforeUploadDemo(file) {
            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            let namelength = file.name.length; //取到文件名长度
            this.filesuffix = file.name.substring(first + 1, namelength);

            if (!this.ossClient) {
                this.$message.warning('网络加载中...,请重试');
                return false;
            }
            if (
                this.fileType == 'image' &&
                !['jpg', 'gif', 'jpeg', 'png', 'JPG', 'JPEG'].includes(this.filesuffix)
            ) {
                this.$message.error('暂时不支持此格式，请重新上传');
                return false;
            } else if (
                this.fileType == 'video' &&
                [
                    'video/mp4',
                    'video/ogg',
                    'video/flv',
                    'video/avi',
                    'video/wmv',
                    'video/rmvb',
                    'video/mkv',
                ].indexOf(file.type) == -1
            ) {
                this.$message.error('请上传正确的视频格式!');
                return false;
            }
            let that = this;

            let size_mb = file.size / 1024 / 1024;
            if (this.fileType != 'video' && size_mb > 20) {
                this.$message.error('单张图片大小不能超过20M!');
                return false;
            }
            if (this.$props.fileSize && size_mb > this.$props.fileSize) {
                this.$message.error('上传图片限制1M!');
                return false;
            }

            // 获取图片宽高
            let reader = new FileReader();
            reader.onload = function(event) {
                let text = event.target.result;
                let img = document.createElement('img');
                img.src = text;
                img.onload = function() {
                    //多图设置方式
                    // console.log('-----------mainImage width height', img.width, img.height);
                    that.mainImage['width_' + file.name] = img.width;
                    that.mainImage['height_' + file.name] = img.height;
                };
            };
            reader.readAsDataURL(file);

            if (this.fileType == 'video') {
                // 获取视频时长 临时代码测试
                window.URL = window.URL || window.webkitURL;
                console.log('=-file', file);
                let url = URL.createObjectURL(file);
                let audioElement = document.createElement('video');
                audioElement.src = url;
                let duration;
                audioElement.addEventListener('loadedmetadata', function(_event) {
                    duration = audioElement.duration; //时长为秒，小数，182.36
                    // this.$parent.$data.wDuration = parseInt(duration)
                    that.mainImage['width_' + file.name] = audioElement.videoWidth;
                    that.mainImage['height_' + file.name] = audioElement.videoHeight;
                    duration = parseInt(duration);
                    console.log('视频时长', duration > 10);
                    if (duration > 5 * 60) {
                        that.$message.error('请上传视频时长不能超过5分钟!');
                        that.ossClient.cancel();
                        this.imgList = [];
                        return false;
                    }
                });
                // 获取视频时长结束
            }
        },
        handleExceedDemo() {
            this.$message.error(`当前限制上传${this.limitLength}项内容,请删除后重新上传`);
        },
        fnUploadRequest(itype, option) {
            let that = this;
            let file = option.file;
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let fileNameOrigin = option.file.name;
            let tempCheckpoint;
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${oss.folderName}/${date}/${this.file}.${this.filesuffix}`;
            if (this.fileType == 'video') {
                url = `${oss.folderName}/video/${date}/${this.file}.${this.filesuffix}`;
            }
            let result = this.ossClient.multipartUpload(url, file, {
                progress(p, checkpoint) {
                    if (itype === 'video') {
                        tempCheckpoint = checkpoint;
                        //p为上传进度数
                        that.videoPercentage = parseInt(p * 100);
                    }
                },
                checkpoint: tempCheckpoint,
                // 设置并发上传的分片数量。
                parallel: 1,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 2 * 1024 * 1024,
                meta: {
                    year: 2022,
                    people: 'test',
                },
                mime: 'text/plain',
            });
            result.then(res => {
                if (res.name) {
                    console.log(this.imgList, 11111111111);
                    console.log(this.type, 88888);
                    file.url = 'https://sczs.voglassdc.com/' + res.name;
                    file.smallUrl = file.url;
                    file.width = this.mainImage['width_' + fileNameOrigin] || null;
                    file.height = this.mainImage['height_' + fileNameOrigin] || null;
                    if (this.fileType == 'video') {
                        file.videoUrl =
                            'https://sczs.voglassdc.com/' +
                            res.name +
                            '?x-oss-process=video/snapshot,t_3000,f_jpg';
                    }
                    this.imgList.push(file);
                    this.$emit('uploadImgList', this.imgList);
                }
            });
        },
        edit() {
            this.$emit('edit', this.imgList);
        },
    },
};
</script>
<style lang="less" scoped>
.img-box {
    display: flex;
    flex-wrap: wrap;
}
.img-item-box {
    position: relative;
    // width: 148px;
    // height: 148px;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
        // width: 148px;
        // height: 148px;
        cursor: pointer;
    }
    .el-upload-operation {
        position: absolute;
        height: 30px;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -27px;
        .el-icon-zoom-in {
            opacity: 0;
            font-size: 24px;
            cursor: pointer;
        }
        .el-icon-delete {
            margin-left: 6px;
            opacity: 0;
            font-size: 24px;
            cursor: pointer;
        }
    }
    &:hover {
        img {
            opacity: 0.2;
        }
        .el-icon-zoom-in {
            opacity: 0.8;
        }
        .el-icon-delete {
            opacity: 0.8;
        }
    }
}
.flex-box {
    display: flex;
    .edit-btn {
        font-size: 10px;
        margin-top: 20px;
        .text {
            margin-bottom: 8px;
        }
    }
}
.percen-box .el-progress-bar {
    width: 200px;
}
</style>
<style lang="less">
.upload-container {
    .upload-row {
        display: flex;
    }
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 22px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }
    .tip {
        font-size: 12px;
        color: #606266;
        margin-top: 4px;
    }
}
</style>
