<template>
    <div>
        <el-dialog
            title="数据导入"
            width="35%"
            :visible.sync="visible"
            :before-close="onClose"
            :close-on-click-modal="false"
        >
            <div>
                <div>
                    <div class="template">
                        <div class="template-text">设计师：</div>
                        <div>
                            <div class="template-upload">
                                <el-upload
                                    action=""
                                    :before-upload="beforeUpload.bind(this, 'designer')"
                                    :on-remove="uploadRemove.bind(this, 'designer')"
                                    multiple
                                    :limit="1"
                                    :on-exceed="handleExceedFile"
                                    :file-list="fileList"
                                    :http-request="uploadHttpRequest.bind(this, 'designer')"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        :loading="loadingDesigner"
                                    >
                                        <i class="el-icon-upload el-icon--right"></i>
                                        上传数据
                                    </el-button>
                                    <span>&nbsp;&nbsp;支持.xlsx .xls格式</span>
                                </el-upload>
                                <el-button type="text" size="small" @click="downError('designer')">
                                    下载失败数据
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="template">
                        <div class="template-text">案例案例：</div>
                        <div>
                            <div class="template-upload">
                                <el-upload
                                    action=""
                                    :before-upload="beforeUpload.bind(this, 'case')"
                                    :on-remove="uploadRemove.bind(this, 'case')"
                                    multiple
                                    :limit="1"
                                    :on-exceed="handleExceedFile"
                                    :file-list="fileList"
                                    :http-request="uploadHttpRequest.bind(this, 'case')"
                                    :disabled="!ruleForm.designerSuccess"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        :loading="loadingCase"
                                        :disabled="!ruleForm.designerSuccess"
                                    >
                                        <i class="el-icon-upload el-icon--right"></i>
                                        上传数据
                                    </el-button>
                                    <span>&nbsp;&nbsp;支持.xlsx .xls格式</span>
                                </el-upload>
                                <el-button type="text" size="small" @click="downError('case')">
                                    下载失败数据
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="template">
                        <div class="template-text">案例内容：</div>
                        <div>
                            <div class="template-upload">
                                <el-upload
                                    action=""
                                    :before-upload="beforeUpload.bind(this, 'caseInfo')"
                                    :on-remove="uploadRemove.bind(this, 'caseInfo')"
                                    multiple
                                    :limit="1"
                                    :on-exceed="handleExceedFile"
                                    :file-list="fileList"
                                    :http-request="uploadHttpRequest.bind(this, 'caseInfo')"
                                    :disabled="!ruleForm.caseSuccess"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        :loading="loadingCaseInfo"
                                        :disabled="!ruleForm.caseSuccess"
                                    >
                                        <i class="el-icon-upload el-icon--right"></i>
                                        上传数据
                                    </el-button>
                                    <span>&nbsp;&nbsp;支持.xlsx .xls格式</span>
                                </el-upload>
                                <el-button type="text" size="small" @click="downError('caseInfo')">
                                    下载失败数据
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    uploadOfficialCaseInfo,
    uploadOfficialCase,
    uploadOfficialDesigner,
    excelDataExperimental,
    batchWorks,
    downloadTemplate,
    downloadErrorDesigner,
    downloadErrorCase,
    downloadErrorCaseInfo,
} from '@/api/mkt/exhibit.js';
import { oss, url } from '@/config.js';
import { getOssConfig } from '@/api/login.js';
import OSS from 'ali-oss';
import { downloadExcel, formatTimeTwo } from '@/utils/util';
export default {
    name: 'sc-import-multiple-dialog',
    props: {
        importOfficialShow: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            visible: false,
            fileList: [], //导入模板
            fileList1: [], //关联文件
            ossClient: {},
            selectedCallFun: null,
            folderName: oss.folderName, //文件夹名字
            file: new Date().getTime() + '' + parseInt(Math.random() * 100),
            filesuffix: '',
            ruleForm: {
                designerList: [],
                caseList: [],
                caseInfoList: [],
            },
            filePath: '', //表格文件路径
            filesNumber: 0, //记录上传文件数量
            filesSuccessNumber: 0,
            loadingDesigner: false,
            loadingCase: false,
            loadingCaseInfo: false,
        };
    },

    watch: {
        importOfficialShow: {
            handler(val) {
                this.visible = val;
                if (val) {
                    this.getOssConfig();
                }
            },
            immediate: true,
        },
    },
    mounted() {},
    methods: {
        getOssConfig() {
            //oss签名
            // let ossConfig={
            //     stsKeyId: "STS.NTPyqqFz1sBSSBCfxNbvKCVp2",
            //     stsSecret: "DyDjMgWXZBWKaDNPrVtWhuWetp6nQYicY9koVb3xmn56",
            //     token: "CAIS9AF1q6Ft5B2yfSjIr5flMsvFq6UQxIC4UWTygngbbvlnrJPb0Dz2IHhJf3NvA+8XtPszmm1S6vwflqJIRoReREvCUcZr8symc5swi9OT1fau5Jko1beHewHKeTOZsebWZ+LmNqC/Ht6md1HDkAJq3LL+bk/Mdle5MJqP+/UFB5ZtKWveVzddA8pMLQZPsdITMWCrVcygKRn3mGHdfiEK00he8TontPninpHGs0eC0Q2lk7Uvyt6vcsT+Xa5FJ4xiVtq55utye5fa3TRYgxowr/8r1/Ybo2iW5I7BXQQLv0/bKYjO+9hoNxRla7MmC16LQ0AkfWURGoABbVeNkXZEOKT/uFYDxIdHZMX+/IOeIGFQ6dDE8DbqeXYqBqWqWTX65mlo7kAMaC9pHmuLys/UAFBWWCTP60L3dztDn7/fquiAojhvUCDAbL6cdwejWGe4LhnigoXtfKw0HfPELuDkZSsNE5EfIqjh4ytXocAjLZdEo1XZoIjDWTc="
            // }
            //          this.ossClient = new OSS({
            //             accessKeyId: ossConfig.stsKeyId,
            //             accessKeySecret: ossConfig.stsSecret,
            //             stsToken: ossConfig.token,
            //             bucket: oss?.bucket,
            //             region: oss?.region,
            //         });
            getOssConfig().then(res => {
                if (res.code === 200) {
                    let ossConfig = res.data;
                    this.ossClient = new OSS({
                        accessKeyId: ossConfig.stsKeyId,
                        accessKeySecret: ossConfig.stsSecret,
                        stsToken: ossConfig.token,
                        bucket: oss?.bucket,
                        region: oss?.region,
                    });
                }
            });
        },

        // 下载模板
        async downloadTemplate() {
            let params = {};
            let { code, data, msg } = await downloadTemplate(params);
            if (code === 200) {
                if (data) {
                    window.open(data);
                }
            } else {
                this.$message.error(msg);
            }
        },
        // 下载错误数据
        async downError(itype) {
            if (itype == 'designer') {
                let res = await downloadErrorDesigner();
                downloadExcel(res, '设计师失败数据');
            } else if (itype == 'case') {
                let res = await downloadErrorCase();
                downloadExcel(res, '案例案例失败数据');
            } else if (itype == 'caseInfo') {
                let res = await downloadErrorCaseInfo();
                downloadExcel(res, '案例内容失败数据');
            }
        },

        beforeUpload(itype, file) {
            console.log('-----------file', file);
            this.filesNumber = this.filesNumber + 1;
            if (this.selectedCallFun) {
                this.selectedCallFun();
            }

            let first = file.name.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            let namelength = file.name.length; //取到文件名长度
            this.filesuffix = file.name.substring(first + 1, namelength);

            if (!['xlsx', 'xls'].includes(this.filesuffix)) {
                this.$message.error('暂时不支持此格式，请重新上传');
                return false;
                //给个flag终止上传行为发生
            }
            if (itype == 'designer') {
                this.loadingDesigner = true;
            } else if (itype == 'case') {
                this.loadingCase = true;
            } else if (itype == 'caseInfo') {
                this.loadingCaseInfo = true;
            }
        },

        uploadRemove(itype, file, fileList) {
            let obj = { designer: 'designerList', case: 'caseList', caseInfo: 'caseInfoList' };
            let list = this.ruleForm[obj[itype]];
            console.log('------------itype', this.ruleForm[obj[itype]]);
            this.ruleForm[obj[itype]] = list.filter(d => {
                if (file.name) {
                    //当前上传的数据有 file.name;旧数据没有file.name,使用url匹配
                    return d.fileName != file.name;
                } else {
                    let isTrue = file.url.indexOf(d.filePath) == -1; //之前上传旧数据；过滤不包含的
                    return isTrue;
                }
            });
            if (itype == 'designer') {
                this.loadingDesigner = false;
            } else if (itype == 'case') {
                this.loadingCase = false;
            } else if (itype == 'caseInfo') {
                this.loadingCaseInfo = false;
            }
            this.ossClient.cancel();
        },

        handleExceedFile(files, fileList) {
            this.$message.warning('请删除当前文件后重新上传');
        },

        //oss上传   注意文件夹 ${this.folderName}/other
        async uploadHttpRequest(itype, option) {
            this.isUpload = true;
            const _that = this;
            let fileNameOrigin = option.file.name;

            let file = option.file;
            this.file = new Date().getTime() + '' + parseInt(Math.random() * 100);
            let tempCheckpoint;
            let date = formatTimeTwo(new Date(), 'YMD');
            let url = `${this.folderName}/${date}/${this.file}.${this.filesuffix}`;
            // let url = `${this.folderName}/node/${this.file}.${this.filesuffix}`;
            console.log('--------------111', this.ossClient);
            let result = this.ossClient.multipartUpload(url, file, {
                progress(p, checkpoint) {
                    tempCheckpoint = checkpoint;
                    //p为上传进度数
                    if (itype == 'designer') {
                        if (_that.filesSuccessNumber === _that.filesNumber - 1) {
                            _that.percentage = parseInt(p * 100);
                        }
                    }
                },

                checkpoint: tempCheckpoint,
                // 设置并发上传的分片数量。
                parallel: 1,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 2 * 1024 * 1024,
                meta: {
                    year: 2022,
                    people: 'test',
                },
                mime: 'text/plain',
            });
            let res = await result;
            console.log('--------------222', result);

            // this.filesSuccessNumber = ++this.filesSuccessNumber;

            if (res?.name) {
                let obj = {
                    designer: 'designerList',
                    case: 'caseList',
                    caseInfo: 'caseInfoList',
                };
                this.ruleForm[obj[itype]] = [{ filePath: res.name, fileName: fileNameOrigin }];
                console.log('------------itype', this.ruleForm[obj[itype]]);
                if (itype == 'designer') {
                    await _that.onSubmitDesigner(res.name);
                } else if (itype == 'case') {
                    await _that.onSubmitCase(res.name);
                } else if (itype == 'caseInfo') {
                    await _that.onSubmitCaseInfo(res.name);
                }
                if (this.successCallFun) {
                    this.successCallFun(res.res.requestUrls[0]);
                }
            }
        },

        // 保存设计师数据
        async onSubmitDesigner(filePath) {
            let params = { filePath };
            try {
                let { code, data, isSuccess, message } = await uploadOfficialDesigner(params);
                this.loadingDesigner = false;
                if (code === 200) {
                    if (data && data != null) {
                        this.$message.success('操作成功');
                        // this.$alert(data, '提示', {
                        //     confirmButtonText: '确定',
                        // });
                    } else {
                        this.$message.success('操作成功');
                    }
                    this.ruleForm.designerSuccess = true;
                } else {
                    this.ruleForm.designerSuccess = false;
                    this.$message({
                        showClose: true,
                        message: message || '操作失败',
                        type: 'error',
                    });
                }
            } catch (error) {
                this.loadingDesigner = false;
                console.log('------------------error', error);
            }
        },
        // 保存案例数据
        async onSubmitCase(filePath) {
            let params = { filePath };
            try {
                let { code, data, isSuccess, message } = await uploadOfficialCase(params);
                if (code === 200) {
                    if (data && data != null) {
                        this.$message.success('上传案例数据成功');
                        // this.$alert(data, '提示', {
                        //     confirmButtonText: '确定',
                        // });
                    } else {
                        this.$message.success('上传案例数据成功');
                    }
                    this.loadingCase = false;
                    this.ruleForm.caseSuccess = true;
                } else {
                    this.$message({
                        showClose: true,
                        message: message || '操作失败',
                        type: 'error',
                    });
                    this.loadingCase = false;
                    this.ruleForm.caseSuccess = false;
                }
            } catch (error) {
                console.log('------------------error', error);
                this.loadingCase = false;
            }
        },
        async onSubmitCaseInfo(filePath) {
            let params = { filePath };
            try {
                let { code, errorCode, data, isSuccess, message } = await uploadOfficialCaseInfo(
                    params
                );
                this.loadingCaseInfo = false;
                if (errorCode === 200) {
                    if (data && data != null) {
                        this.$message.success('上传案例内容数据成功');

                        // this.$alert(data, '提示', {
                        //     confirmButtonText: '确定',
                        // });
                    } else {
                        this.$message.success('上传案例内容数据成功');
                    }
                    this.ruleForm.caseInfoSuccess = true;
                } else {
                    this.$message({
                        showClose: true,
                        message: message || '操作失败',
                        type: 'error',
                    });
                }
            } catch (error) {
                this.loadingCaseInfo = false;
                console.log('------------------error', error);
            }
        },

        onClose() {
            this.visible = false;
            this.ruleForm.designerSuccess = false;
            this.ruleForm.caseSuccess = false;
            this.ruleForm.caseInfoSuccess = false;
            this.loadingDesigner = false;
            this.loadingCase = false;
            this.loadingCaseInfo = false;
            this.$emit('onClose');
        },
    },
};
</script>

<style lang="less" scoped>
.delete-btn {
    margin-left: 10px;
}

.template {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.template1 {
    display: flex;
    flex-direction: row;
}

.template-text {
    line-height: 2;
    min-width: 80px;
}

.template-upload {
    margin-left: 8px;
    min-width: 400px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
}

.progress {
    width: 150px;
    margin-left: 10px;
}

.common {
    display: inline-block;
}

.tips {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
}

.template-tag {
    display: flex;
    margin-top: 10px;
}

.associated-file {
    margin-top: 10px;
}

.main {
    margin-top: -23px;
}

.template-upload-text {
    display: flex;
}

.template1 {
    /deep/ .el-upload-list__item {
        transition: none !important;
        -webkit-transition: none !important;
    }
    /deep/ .el-upload-list__item-name {
        transition: none !important;
        -webkit-transition: none !important;
    }

    /deep/ .el-upload-list__item.is-ready {
        display: none;
    }
}
.template {
    /deep/ .el-upload-list {
        max-width: 300px;
    }
}
</style>
