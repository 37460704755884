<template>
    <div class="info-main">
        <div v-if="JSON.stringify(data) !== '{}'" class="info-container">
            <div class="bread-box">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item, index) in bread" :key="index">
                        {{ item }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="info-content-box">
                <div class="info-content-item title">{{ data.moduleName }}</div>
                <div class="info-content-item number">编号：{{ data.number }}</div>
                <div class="info-content-item number">创建时间：{{ data.createTime }}</div>
                <div class="img-box">
                    <img :src="data.sampleUrl" />
                </div>
                <div class="info-content-item">
                    <p>{{ data.moduleName }}</p>
                </div>
                <div v-if="data.controlType === 1" class="info-content-item">
                    <p>图片比例{{ data.pictureScale }}</p>
                </div>
                <div v-if="data.controlType === 3" class="info-content-item">
                    <p>列表类型：{{ data.listType }}</p>
                </div>
            </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        bread: {
            type: Array,
            default: () => {
                return [];
            },
        },
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            breList: ['首页'],
        };
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="less" scoped>
.info-main {
    min-width: 330px;
    min-height: 83vh;
    background-color: #fff;
}
.info-container {
    padding: 15px 20px;
}
.info-content-box {
    margin-top: 40px;
    .img-box {
        margin-top: 20px;
        width: 280px;
        img {
            width: 100%;
        }
    }
    .title {
        font-size: 18px;
    }
    .number {
        color: #767671;
    }
    .info-content-item {
        margin: 10px 0;
    }
}
</style>
