<template>
    <el-drawer
        :visible.sync="inneriagvisible"
        size="30%"
        :before-close="handleClose"
        custom-class="self-drawer"
        :append-to-body="true"
    >
        <sc-form-v2
            tablename="CRM_CATEGORY"
            :columnsNum="1"
            :columns="columns"
            :result="result"
            direction="vertical"
            :esayStyle="true"
            :columnsPaddingRight="20"
            @change="changeFn"
        >
            <template v-slot:project>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <div style="width: 45vw">
                        <div
                            v-for="(item, index) in options"
                            :key="index"
                            style="text-align: left; margin-bottom: 10px"
                            v-if="item.propertiesList && item.propertiesList.length"
                        >
                            <div>{{ item.attributeName }}</div>
                            <!-- <div>{{ item.filterIds }}</div> -->
                            <el-form-item>
                                <el-checkbox-group v-model="item.filterIds" :key="index">
                                    <el-checkbox
                                        :label="two.filterId"
                                        name="type"
                                        v-for="(two, tIndex) in item.propertiesList"
                                        :key="index + '-' + tIndex"
                                        :disabled="disabledFn(two)"
                                    >
                                        {{ two.filterName }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:categoryId>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">
                            所属分类
                            <!-- <span class="must">
                                *
                            </span> -->
                        </div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width: 95%"
                            v-model="result.categoryId"
                            :show-all-levels="false"
                            size="small"
                            :options="categoryList"
                            @change="selectCateChange"
                            ref="cateCascader"
                            :props="{
                                emitPath: false,
                                checkStrictly: true,
                                value: 'value',
                                label: 'label',
                            }"
                            clearable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
            <template v-slot:tagId>
                <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                    <template slot="label">
                        <div class="labelName">标签</div>
                    </template>
                    <div style="text-align: left">
                        <el-cascader
                            style="width: 95%"
                            v-model="result.tagId"
                            size="small"
                            :options="labelList"
                            placeholder="请选择标签"
                            :props="{
                                label: 'labelName',
                                value: 'id',
                                checkStrictly: true,
                                emitPath: false,
                            }"
                            :show-all-levels="false"
                            @change="selectTagChange"
                            ref="cascader"
                            clearable
                            filterable
                        ></el-cascader>
                    </div>
                </el-descriptions-item>
            </template>
        </sc-form-v2>
        <div class="bottom-btn">
            <el-button size="mini" @click="handleClose">取 消</el-button>
            <el-button size="mini" type="primary" @click="handleCommit">确 定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-article-group');
import mixins from '@/mixins';
import { getSyscategoryList } from '@/api/mkt/category.js';
import { getTagList } from '@/api/mkt/tag-group.js';
export default {
    props: {
        inneriagvisible: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: function () {
                return [];
            },
        },
        optionType: {
            type: String,
            default: 'primaryFilterList',
        },
        originList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        primaryslotList: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    mixins: [mixins],
    data() {
        return {
            relevanceLabelParams: [],
            result: {
                project: '',
                categoryId: '',
                tagId: '',
            },
            columns: [
                {
                    key: 'project',
                    name: '所属类目',
                    type: 'input',
                },
                {
                    key: 'categoryId',
                    name: '所属分类',
                    type: 'input',
                },
                {
                    key: 'tagId',
                    name: '标签',
                    type: 'input',
                },
            ],
            categoryList: [],
        };
    },
    computed: {
        // ...mapState(['labelList']),
        // ...mapState(['categoryList']),
        disabledFn() {
            return function (item) {
                return (
                    this.originList.filter(origin => origin.filterId == item.filterId).length > 0
                );
            };
        },
    },
    watch: {
        inneriagvisible(val) {
            if (val) {
                this.result.categoryId = '';
                this.result.tagId = '';
            }
        },
    },
    mounted() {
        this.getSyscategoryList();
        this.getTagList();
    },
    methods: {
        async getSyscategoryList() {
            // 筛选分类给前台用 取上架和启用的分类数据
            let res = await getSyscategoryList({ iShow: 0, putStatus: 0 });
            console.log('---------res', res);
            this.quit(res.data.children);
            this.categoryList = res.data.children;
        },
        async getTagList() {
            let params = {
                page: 1,
                size: 200,
                orderSort: 1,
            };
            let res = await getTagList(params);

            console.log('---------res', res);
            for (let item of res.data.children) {
                item.labelName = item.groupName;
            }

            this.labelList = res.data.children.filter(d => d.state === 0);
        },
        quit(list) {
            if (list && list.length) {
                for (let one of list) {
                    if (one.children && one.children.length) {
                        this.quit(one.children);
                    } else {
                        one.disabled = true;
                    }
                }
            }
            return list;
        },
        selectCateChange(value) {
            let data = this.$refs.cateCascader.getCheckedNodes();
            console.log('------------selectCateChange', value);
            this.result.categoryName = data[0]?.data?.label;
            console.log('------------selectCateChange', value, data[0].data.label);
        },
        // 处理后端需要的数据
        selectTagChange() {
            if (this.relevanceLabelParams?.length > 20) {
                this.$message.warning('最多选择20个标签');
                this.result.relevanceLabelParams = this.relevanceLabelParams.slice(0, 20);
            }
            let data = this.$refs.cascader.getCheckedNodes();
            this.result.tagName = data[0]?.data?.labelName || '';
            console.log('-------selectTagChange', data, data[0].data.labelName);
        },
        handleClose() {
            this.$emit('closeinnerDiag');
        },
        handleCommit() {
            let result = this.result;
            let optionType = this.optionType;

            // 获取属性name
            let newArr = [];
            let filterIdsArr = [];
            for (let one of this.options) {
                if (one.propertiesList) {
                    newArr = newArr.concat(one.propertiesList);
                }
                filterIdsArr = filterIdsArr.concat(one.filterIds);
            }
            console.log(
                '-----handleCommit newArr',
                newArr,
                '--optionType--',
                optionType,
                optionType == 'primaryFilterList',
                filterIdsArr
            );
            let labelArr = [];
            newArr.map(item => {
                if (item.filterId && filterIdsArr.indexOf(item.filterId) > -1) {
                    labelArr.push(item.filterName);
                }
            });
            let filterArr = newArr.filter(d => filterIdsArr.includes(d.filterId));

            if (result.categoryId) {
                console.log('---------');
                filterIdsArr.push(result.categoryId);
                labelArr.push(result.categoryName);
                let cate = {
                    filterId: result.categoryId,
                    filterName: result.categoryName,
                    attributeType: (
                        this.options.filter(d => d.attributeName == '分类属性')[0] || {}
                    ).attributeType,
                };
                filterArr.push(cate);
            }
            if (result.tagName) {
                filterIdsArr.push(result.tagId);
                labelArr.push(result.tagName);
                let cate = {
                    filterId: result.tagId,
                    filterName: result.tagName,
                    attributeType: (
                        this.options.filter(d => d.attributeName == '标签属性')[0] || {}
                    ).attributeType,
                };
                filterArr.push(cate);
            }
            console.log('-------------------------------------------', filterIdsArr);
            if (
                optionType == 'primaryFilterList' &&
                filterIdsArr.length + this.primaryslotList.length > 3
            ) {
                return this.$message.error('主要筛选 只能选择3项');
            }
            let filterType = this.optionType == 'primaryFilterList' ? 1 : 2;
            filterArr.map(d => (d.filterType = filterType)); //保存为主筛选还是次筛选
            console.log('-----handleCommit', newArr, filterIdsArr, labelArr, filterArr);

            let data = {
                labelArr, //label集合
                filterIdsArr, //id集合
                filterArr, //所以值集合
            };
            this.$emit('commitData', data, optionType);
        },
        changeFn() {},
        clickFn() {},
    },
};
</script>
<style lang="less" scoped>
/deep/ .self-drawer {
    padding: 0 20px;
}
.bottom-btn {
    margin: 20px 10px 10px 0;
    float: right;
}
.title {
    margin: 20px 0 0 120px;
}
.left {
    margin-left: 120px;
}
</style>
