<template>
    <div>
        <el-drawer
            title="审核案例"
            :visible.sync="drawer"
            :direction="direction"
            custom-class="self-drawer"
            size="30%"
            :wrapperClosable="false"
        >
            <sc-form-v2
                tablename="BESIC"
                name="案例信息"
                :columnsNum="1"
                :result="worksInfo"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                ref="articleFrom"
            >
                <template v-slot:title>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName"></div>
                        </template>
                        <div>
                            <div style="display:flex; align-items:flex-start;">
                                <div class="cover con">
                                    <span class="space-name">封面</span>
                                    <el-image
                                        class="img"
                                        :src="worksInfo.cover"
                                        fit="cover"
                                        style="margin-right: 10px; width: 80px; height: 80px;z-index:1;"
                                        :preview-src-list="[worksInfo.cover]"
                                    ></el-image>
                                </div>
                                <div>
                                    <h3 style="font-weight:500;font-size:16px;">
                                        {{ worksInfo.title }}
                                    </h3>
                                    <!-- <p style="color:#999;">{{ worksInfo.worksDescribe }}</p> -->
                                </div>
                            </div>
                            <br />
                            <div v-for="(item, ind) in worksInfo.groupInfos" :key="ind">
                                <span v-for="(img, index) in item.urls" :key="index" class="con">
                                    <span class="space-name">{{ item.spaceName }}</span>
                                    <el-image
                                        class="img"
                                        :src="img"
                                        fit="cover"
                                        style="margin-right: 10px; width: 80px; height: 80px;z-index:1;"
                                        :preview-src-list="item.urls"
                                    ></el-image>
                                </span>
                                <br />
                                {{ item.picDescribe }}
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
                <!-- <template v-slot:files>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">图片</div>
                        </template>
                        <div style="text-align: left; display: flex;flex-wrap:wrap;">
                            <div
                                v-for="(item, ind) in worksInfo.worksFileList"
                                :key="ind"
                                class="img-list"
                            >
                                <el-image
                                    class="img"
                                    :src="item.url"
                                    fit="cover"
                                    style="margin-right: 10px; width: 80px; height: 80px"
                                    :preview-src-list="[item.url]"
                                ></el-image>
                                <div>{{ item.spaceName }}</div>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template> -->
            </sc-form-v2>
            <el-divider></el-divider>
            <sc-form-v2
                tablename="BESIC"
                name="项目信息"
                :columnsNum="2"
                :result="worksInfo"
                :columns="projectColumns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                ref="articleFrom"
            >
                <template v-slot:files>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">图片</div>
                        </template>
                        <div style="text-align: left; display: flex">
                            <div
                                v-for="(item, ind) in worksInfo.worksFileList"
                                :key="ind"
                                class="img-list"
                            >
                                <el-image
                                    class="img"
                                    :src="item.smallUrl"
                                    fit="cover"
                                    style="margin-right: 10px; width: 80px; height: 80px"
                                    :preview-src-list="[item.url]"
                                ></el-image>
                                <div>{{ item.spaceName }}</div>
                            </div>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <el-divider></el-divider>
            <div style="text-align: right">
                <el-button size="mini" style="margin-right: 20px" @click="close">取消</el-button>
                <el-button
                    size="mini"
                    style="margin-right: 20px"
                    @click="handleNoPass"
                    type="danger"
                    v-if="localRow.approvalStatus == 1"
                >
                    驳回
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="passbtn"
                    v-if="localRow.approvalStatus == 1"
                >
                    通过
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="submit('3')"
                    v-if="localRow.approvalStatus == 3"
                >
                    确定
                </el-button>
            </div>
        </el-drawer>
        <dialog-remark ref="remark" @remarkBak="remarkBak"></dialog-remark>
        <pass-dialog
            ref="passdialog"
            @remarkBak="remarkBak"
            @closeDrawer="closeDrawer1"
        ></pass-dialog>
    </div>
</template>

<script>
import dialogRemark from '../article/dialog-remark.vue';

import passDialog from './pass-dialog.vue';
import mixins from '@/mixins';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('mkt-content-exhibit-group');
import { info, approval, approvalReject } from '@/api/mkt/exhibit';
export default {
    name: 'detailDrawer',
    mixins: [mixins],
    components: { dialogRemark, passDialog },
    data() {
        return {
            columns: [
                {
                    key: 'title',
                    name: '标题',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                // {
                //     key: 'files',
                //     name: '图片',
                //     type: 'text',
                //     options: {
                //         defaultValue: '',
                //     },
                // },
            ],
            remark: '',
            localRow: {},
            drawer: false,
            direction: 'rtl',
            worksInfo: {
                title: '',
                labels: [],
                operationIds: [],
            },

            projectColumns: [
                {
                    key: 'cuscode',
                    name: '客户编码',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'projectAddress',
                    name: '项目地址',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'companyName',
                    name: '所属分公司',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'accountName',
                    name: '设计师',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'styleLabelName',
                    name: '风格',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'colorLabelName',
                    name: '色系',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'houseLayoutName',
                    name: '户型',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'area',
                    name: '面积',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'ownerDemand',
                    name: '业主需求',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'designIdeas',
                    name: '设计思路',
                    type: 'text',
                    options: {
                        defaultValue: '',
                    },
                },
            ],
            suppleColumns: [
                {
                    key: 'operationIds',
                    name: '运营标签',
                    type: 'input',
                    options: {
                        defaultValue: '',
                    },
                },
                {
                    key: 'labels',
                    name: '标签',
                    type: 'input',
                    options: {
                        defaultValue: '',
                    },
                    // rules: [{ required: false, message: '请选择标签' }],
                },
            ],
            labels: [],
            operationIds: [],
            nodeData: '',
        };
    },
    watch: {},
    methods: {
        handleNoPass() {
            // 驳回
            this.$refs.remark.open();
        },
        selecttOpeTag() {
            if (this.worksInfo.operationIds?.length > 20) {
                this.$message.warning('最多选择20个运营标签');
                this.worksInfo.operationIds = this.worksInfo.operationIds.slice(0, 20);
            }
            let data = this.$refs.oper.getCheckedNodes();
            data.map((item, index) => {
                if (this.nodeData == item.value) {
                    data.splice(index, 1);
                }
            });
            this.worksInfo.operationIds = this.changeLabel(data);
        },
        changeFn2() {},
        selectTagChange() {
            console.log('val----', this.labels);
            if (this.worksInfo.labels?.length > 20) {
                this.$message.warning('最多选择20个标签');
                this.worksInfo.labels = this.worksInfo.labels.slice(0, 20);
            }
            let data = this.$refs.tagcasc.getCheckedNodes();

            data.map((item, index) => {
                if (this.nodeData == item.value) {
                    data.splice(index, 1);
                }
            });
            this.worksInfo.labels = this.changeLabel(data);
        },
        removeTag(val) {
            this.nodeData = val;
        },

        passbtn() {
            this.$refs.passdialog.open(this.localRow, 0);
        },
        // 处理标签得数据
        changeLabel(arr) {
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        submit(num) {
            let params = {
                approvalStatus: num, // 3通过 4 驳回
                labels: this.worksInfo.labels || [],
                operationIds: this.worksInfo.operationIds || [],
                worksId: this.worksInfo.worksId,
                approvalRemarks: num == 4 ? this.remark : null,
            };
            approval(params).then(res => {
                if (res.errorCode == 200) {
                    this.close();
                }
            });
        },
        openDrawer(row) {
            this.drawer = true;
            this.operationIds = [];
            this.labels = [];
            this.localRow = { ...row };
            this.showInfo(row);
        },
        changeFn() {},
        clickFn() {},
        close() {
            this.drawer = false;
            this.$emit('closeDrawer');
        },

        closeDrawer1() {
            this.drawer = false;
            this.$emit('closeDrawer');
        },
        remarkBak(remark) {
            let params = {
                worksId: this.worksInfo.worksId,
                approvalStatus: 4, // 3 审核通过  4 , 审核退回
                approvalRemarks: remark,
            };
            approvalReject(params).then(res => {
                if (res.errorCode == 200) {
                    this.close();
                } else {
                    this.$message.info(res.message);
                }
            });
        },
        showInfo(row) {
            info({ id: row.id }).then(res => {
                this.worksInfo = res.data.worksInfo;
                this.worksInfo.worksId = row.id;
                this.worksInfo.labels = res.data.labels;
                this.worksInfo.operationIds = res.data.operationLabels;
            });
        },
    },

    created() {
        this.getOperationList();
        this.getLabelCommonList();
    },
};
</script>

<style scoped lang="less">
/deep/.el-dialog__body {
    padding: 0 20px !important;
}
/deep/ .self-drawer {
    padding: 0 20px;
}
/deep/ .el-descriptions__title {
    font-size: 14px;
}
/deep/ .label-tag:after {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    > div {
        text-align: center;
    }
    .img {
        width: 100px;
        height: 100px;
    }
}
// .img-list:nth-child(4n) {
//     /deep/ .el-image {
//         margin-right: 0 !important;
//     }
// }
.must {
    color: #f56c6c;
}

.con {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 10px;
}
.space-name {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    color: #fff;
    z-index: 2;
    padding: 2px;
    font-size: 10px;
}
.cover {
    bottom: 10px;
}
</style>
