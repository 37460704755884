<template>
    <div v-if="data.length" class="content-config-main">
        <div class="pub-config-title">
            <el-input
                class="pub-config-input"
                v-model="activeModuleData.activeModuleName"
                size="mini"
                placeholder="请输入内容"
                :disabled="activeModuleData.modulePublishStatus === 1"
            ></el-input>
            <div class="pub-config-num">
                <p>编号: {{ activeModuleData.activeModuleId }}</p>
            </div>
        </div>
        <div v-for="(item, index) in data" :key="index" class="pub-model-main">
            <label class="pub-model-label">{{ item.attributeName }}</label>
            <div v-if="item.templateType === 0" class="pub-model-cont">
                <div
                    v-for="(el, idx) in item.attributeOptinldVoList"
                    :key="idx"
                    class="pub-model-item"
                    :class="{ 'active-pub-model': activeContentModel == idx }"
                    @click="contentModelHandle(el, idx, item)"
                >
                    <img :src="el.picUrl" />
                    <p>{{ el.attributeOptionName }}</p>
                </div>
            </div>
            <div v-else-if="item.templateType === 1" class="radio-group-box">
                <el-radio-group v-model="item.attributeOptionId">
                    <el-radio
                        v-for="(ele, ind) in item.attributeOptinldVoList"
                        :key="ind"
                        :label="ele.attributeOptionId"
                    >
                        {{ ele.attributeOptionName }}
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="pub-submit-main">
            <el-button
                v-if="!activeModuleData.modulePublishStatus"
                size="small"
                type="primary"
                @click="contentSubmitHandle"
            >
                保存
            </el-button>
            <el-button
                v-if="!activeModuleData.modulePublishStatus"
                size="small"
                type="primary"
                @click="modulePublishHandle(1)"
            >
                发布
            </el-button>
            <el-button
                v-if="activeModuleData.modulePublishStatus"
                size="small"
                type="primary"
                @click="modulePublishHandle(0)"
            >
                取消发布
            </el-button>
        </div>
    </div>
</template>

<script>
import { editControl, setModulePublish } from '@/api/mkt/markets.js';

export default {
    name: 'content-list',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        activeModuleData: {
            type: Object,
            default: () => {
                return {
                    activeModuleId: '',
                    activeModuleName: '',
                    modulePublishStatus: 0,
                };
            },
        },
    },
    watch: {
        data: {
            handler(newVal) {
                newVal.forEach(item => {
                    if (item.templateType === 0) {
                        this.initModelShowHandle(item.attributeOptinldVoList);
                    }
                });
            },
        },
    },
    data() {
        return {
            contentConfigForm: [],
            activeContentModel: null,
            modulePicUrl: '',
        };
    },
    mounted() {},
    methods: {
        async contentSubmitHandle() {
            const attributeOptionId = this.data.map(item => {
                return item.attributeOptionId;
            });
            const attributeOptionIdStatus = attributeOptionId.every(item => {
                return item !== null;
            });
            if (!attributeOptionIdStatus) {
                this.$message.error('数据为空,请选择');
                return false;
            }
            const { activeModuleId, activeModuleName } = this.activeModuleData;
            if (!activeModuleName) {
                this.$message.error('名称不能为空');
                return false;
            }
            const { isSuccess } = await editControl({
                attributeOptionId,
                moduleId: activeModuleId,
                moduleName: activeModuleName,
            });
            if (isSuccess) {
                this.$emit('updateData', this.modulePicUrl, this.activeModuleData.activeModuleName);
                this.$message.success('保存成功');
            }
        },
        contentCancelHandle() {
            this.$emit('cancel');
        },
        contentModelHandle(row, index, arr) {
            if (this.activeContentModel !== index) {
                this.activeContentModel = index;
                arr.attributeOptionId = row.attributeOptionId;
                this.modulePicUrl = row.picUrl;
            }
        },
        initModelShowHandle(arr) {
            this.activeContentModel = null;
            arr.forEach((item, index) => {
                if (item.isShow) {
                    this.activeContentModel = index;
                }
            });
        },
        initImageHandle() {
            this.modulePicUrl = '';
        },
        async modulePublishHandle(status) {
            const { activeModuleId } = this.activeModuleData;
            const { isSuccess } = await setModulePublish({
                moduleId: activeModuleId,
                publishType: status,
            });
            if (isSuccess) {
                this.$emit('publishChange');
                this.$message.success(status ? '发布成功' : '取消成功');
            }
        },
    },
};
</script>
<style lang="less" scoped>
.pub-config-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 700;
    margin-left: 10px;
    font-size: 16px;
    color: #333;
    &::after {
        content: '';
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 12px;
        background-color: #3a379b;
    }
    .pub-config-input {
        width: 150px;
    }
    .pub-config-num {
        font-size: 14px;
    }
    /deep/.el-input__inner {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
    }
    /deep/.el-input.is-disabled .el-input__inner {
        background-color: #fff;
    }
}
.pub-model-main {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    .pub-model-label {
        display: block;
        margin-bottom: 10px;
        color: #515151;
    }
    .pub-model-cont {
        display: flex;
        align-items: center;
        flex-flow: wrap;
    }
    .pub-model-item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 30%;
        height: 150px;
        padding: 10px;
        text-align: center;
        color: #999;
        border: 1px dashed #ccc;
        margin-bottom: 10px;
        margin-right: 15px;
        cursor: pointer;
        img {
            width: auto;
            height: 80%;
        }
        p {
            font-size: 12px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
        &.active-pub-model {
            border: 1px solid #8280cb;
        }
    }
}
.pub-submit-main {
    text-align: center;
}
</style>
