<template>
    <div class="add-tag-container">
        <el-drawer
            :title="title"
            :visible.sync="drawer"
            direction="rtl"
            custom-class="self-drawer"
            size="80%"
            :wrapperClosable="false"
            :before-close="closeDrawer"
        >
            <div v-if="type == 'add'">
                <base-form
                    name=""
                    tablename="designer-add-info"
                    :columnsNum="4"
                    :columns="addColumns"
                    labelPosition="top"
                    :result="addResult"
                    direction="vertical"
                    :LS="LS"
                    title="校验账号"
                    :CS="infoCs"
                    :columnsPaddingRight="0"
                    @change="changeFn"
                    @click="clickFn"
                    ref="addForm"
                >
                    <template v-slot:btn>
                        <el-button size="small" type="primary" @click="handleCheck">确定</el-button>
                    </template>
                </base-form>
            </div>
            <!--  -->
            <div v-if="continueAddInfo">
                <div
                    style="display:flex;align-items:center;margin-bottom:10px;font-weight:700;font-size: 15px;color: #333;"
                >
                    <span class="red-line"></span>
                    头像
                    <span class="must" style="margin-left:8px;"></span>
                </div>
                <base-upload-dpwcc
                    :fileList="result.avatarImgUrl"
                    tipTitle=""
                    tip=""
                    :fileType="'.jpg, .jpeg, .png, .gif, JPG'"
                    :fileSize="20"
                    :limit="1"
                    btnText="上传图片"
                    :disabled="disabled"
                    :crop="true"
                    @crop="handleCrop"
                    @fileList="sendAvatarImg"
                ></base-upload-dpwcc>
                <div>
                    <div class="info">
                        <div v-for="(item, key) in info" :key="key" class="item">
                            <div class="label">{{ item.label }}</div>
                            <div class="value">{{ result[key] || '--' }}</div>
                        </div>
                    </div>
                </div>
                <base-form
                    name=""
                    tablename="designer-base-info"
                    :columnsNum="4"
                    :columns="columnsBaseInfo"
                    labelPosition="top"
                    :result="localResult"
                    direction="vertical"
                    :LS="LS"
                    title="基本信息"
                    :CS="infoCs"
                    :columnsPaddingRight="0"
                    @change="changeFn"
                    @click="clickFn"
                    ref="baseFormRef"
                    :disabled="disabled"
                ></base-form>
                <div>
                    <design-project
                        ref="designProjectRef"
                        :awardsList="awardsList"
                        :backResult="result"
                        :errorStyle="errorStyle"
                    ></design-project>
                </div>
                <sc-form-v2
                    name=""
                    tablename="DESIGNERS_INFO"
                    :columnsNum="1"
                    :columns="columns"
                    :result.sync="result"
                    direction="vertical"
                    :esayStyle="true"
                    :columnsPaddingRight="20"
                    @change="changeFn"
                    @click="clickFn"
                    ref="groupform"
                >
                    <!-- 设计理念 -->
                    <template v-slot:K1>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <div style="width:100%;padding-right: 20px;position: relative;">
                                <div style="margin:10px 0;vertical-align: middle;">
                                    设计理念
                                    <span class="must"></span>
                                </div>
                                <el-input
                                    v-model="result.content"
                                    type="textarea"
                                    placeholder="请输入设计理念"
                                    :rows="4"
                                    maxlength="1000"
                                    show-word-limit
                                    style="margin-bottom:10px;"
                                    :class="errorType ? 'is-error' : ''"
                                    @input="handleChangeTextarea"
                                ></el-input>
                                <div
                                    v-if="errorType"
                                    style="color: #F56C6C;font-size: 12px;line-height: 1;padding-top: 4px;position: absolute;left: 0;margin-top:-11px;"
                                >
                                    请填写设计理念
                                </div>
                                <base-upload-dpwcc
                                    :fileList="result.contentFile"
                                    tipTitle=""
                                    tip=""
                                    :fileType="'.jpg, .jpeg, .png, .gif, JPG'"
                                    :fileSize="20"
                                    :limit="10"
                                    btnText="上传图片"
                                    :disabled="disabled"
                                    @fileList="sendFileList"
                                ></base-upload-dpwcc>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <!-- 设计师特质 多条 -->
                    <template v-slot:speciality>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">
                                    特质
                                    <span class="must"></span>
                                </div>
                            </template>
                            <div
                                style="display:flex;justify-content:space-between;position: relative;"
                            >
                                <div
                                    style="width:100%;padding-right: 20px;"
                                    v-for="(item, index) in result.speciality"
                                    :key="index"
                                >
                                    <el-input
                                        maxlength="22"
                                        show-word-limit
                                        v-model="result.speciality[index]"
                                        style="margin: 0 20px 10px 0;"
                                        :class="specialityErrorType ? 'is-error' : ''"
                                        @input="specialityErrorType = false"
                                    ></el-input>
                                </div>
                                <div
                                    v-if="specialityErrorType"
                                    style="color: #F56C6C;font-size: 12px;line-height: 1;padding-top: 4px;position: absolute;top: 100%;left: 0;margin-top:-11px;"
                                >
                                    请填写特质
                                </div>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <!-- 设计师说 多条 -->
                    <template v-slot:designerSaidList>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">设计师说</div>
                            </template>
                            <div
                                style="width:100%;padding-right: 20px;"
                                v-for="(item, index) in result.designerSaidList"
                                :key="index"
                            >
                                <editorToo
                                    :editorKey="'designerSaid' + index"
                                    :editorData="result.designerSaidList[index]"
                                    :editorDisabled="false"
                                    :showLink="false"
                                    :editorConfig="{
                                        placeholder: '请输入',
                                        zIndex: '9',
                                        maxLength: 100,
                                    }"
                                    :ind="index"
                                    :ref="`designerSaidEditor${index}`"
                                    @getValue="getValue"
                                ></editorToo>
                            </div>
                            <div style="text-align: right;padding-right: 20px;">
                                <el-button size="mini" type="text" @click="addDesignerSaidList">
                                    添加+
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="deleteDesignerSaidList"
                                    v-if="
                                        currentLength > 1 &&
                                            currentLength == result.designerSaidList?.length
                                    "
                                >
                                    删除-
                                </el-button>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <!-- 小故事-->
                    <template v-slot:storyTitle>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">小故事标题</div>
                            </template>
                            <div style="width:100%;padding-right: 20px;">
                                <el-input
                                    maxlength="15"
                                    show-word-limit
                                    v-model="result.storyTitle"
                                    style="margin: 0 20px 10px 0;"
                                ></el-input>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <template v-slot:storyContent>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">小故事正文</div>
                            </template>
                            <div style="width:100%;padding-right: 20px;">
                                <editorToo
                                    editorKey="storyContent"
                                    :editorData="result.storyContent"
                                    :editorDisabled="false"
                                    :showLink="false"
                                    :editorConfig="{
                                        placeholder: '请输入',
                                        zIndex: '9',
                                        maxLength: 300,
                                    }"
                                    :ind="0"
                                    ref="shortStoryTextEditer"
                                    @getValue="getStoryValue"
                                ></editorToo>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <!-- 我们 -->
                    <template v-slot:us>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">我们</div>
                            </template>
                            <div>
                                <base-upload-dpwcc
                                    :fileList="result.us"
                                    tipTitle="上传说明："
                                    :tip="'1.推荐尺寸：345 x 192px（图片比例9:5)'"
                                    :fileType="'.jpg, .jpeg, .png, .gif, JPG'"
                                    :fileSize="20"
                                    :limit="3"
                                    btnText="上传团队照"
                                    :disabled="disabled"
                                    @fileList="sendUsList"
                                ></base-upload-dpwcc>
                            </div>
                        </el-descriptions-item>
                    </template>
                    <!-- 关于我 -->
                    <template v-slot:aboutMe>
                        <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                            <template slot="label">
                                <div class="labelName">关于我</div>
                            </template>
                            <div>
                                <base-upload-dpwcc
                                    :fileList="result.aboutMe"
                                    tipTitle="上传说明："
                                    :tip="'建议视频时长 <10min，视频大小≤500MB'"
                                    :fileType="'.mp4, .mov, .MP4, .MOV, .avi, .AVI'"
                                    :fileSize="2000"
                                    :limit="3"
                                    btnText="个人介绍视频"
                                    :disabled="disabled"
                                    @fileList="sendAboutMeList"
                                ></base-upload-dpwcc>
                            </div>
                        </el-descriptions-item>
                    </template>
                </sc-form-v2>
                <div class="dialog-footer">
                    <el-button size="small" @click="close">取消</el-button>
                    <el-button size="small" type="primary" @click="handleSubmit">确定</el-button>
                </div>
            </div>
        </el-drawer>
        <set-cover
            ref="set-cover"
            :newClip="true"
            @coverImgUrl="getCoverImgUrl"
            :showImgList="showImgList"
        ></set-cover>
    </div>
</template>
<script>
import baseUploadDpwcc from '@/components/base-upload-dpwcc.vue';
import { columnsBaseInfo, addColumns } from './data.js';
import { updateDesignerInfo, getDesignerInfo } from '@/api/mkt/user';
import editor from '@/components/wang-editor.vue';
import editorToo from '@/components/wang-editorToo.vue';
import designProject from './design-project.vue';
import {
    designerAdd,
    informationEditEchoListIntervene,
    informationEditEchoIntervene,
    informationEditIntervene,
} from '@/api/mkt/message.js';
import ossState from '@/utils/ossClient.js';
import setCover from '../../../cm/exhibit/components/set-cover-new.vue';
export default {
    props: {},
    components: { editor, editorToo, designProject, baseUploadDpwcc, setCover },
    data() {
        return {
            showImgList: '',
            LS: { 'min-width': '0' }, //设置label宽度
            labelWidth: { 'min-width': '0' },
            infoCs: { 'max-width': '207px' },
            type: 'add', // edit 弹窗状态, 新增, 还是补充
            title: '',
            drawer: false,
            dialogVisible: false,
            info: {
                name: { label: '姓名' },
                // nickName: { label: '昵称' },
                mobile: { label: '手机号' },
                account: { label: '员工账号' },
                job: { label: '岗位' },
                companyName: { label: '分公司' },
            },
            columns: {
                K1: {
                    name: '设计理念',
                },
                speciality: {
                    name: '特质',
                },
                designerSaidList: {
                    name: '设计师说',
                },
                storyTitle: {
                    name: '小故事标题',
                },
                storyContent: {
                    name: '小故事正文',
                },
                us: {
                    name: '我们',
                },
                aboutMe: {
                    name: '关于我',
                },
            },
            result: {
                speciality: ['', '', ''],
                designerSaidList: [''],
                storyTitle: '',
                storyContent: '',
                aboutMe: [],
                us: [],
                awards: [],
            },
            disabled: false,
            columnsBaseInfo: columnsBaseInfo,
            localResult: {
                sex: null,
                individuality: [], //个人特质
                style: [],
                constellation: '',
                yearsService: '',
                age: '',
                city: [],
                province: '',
                rank: '',
                hobby: [],
                communicationStyle: [],
                nickName: '',
            },
            addColumns: addColumns,
            addResult: {
                account: '',
                mobile: '',
            },
            awardsList: [],
            currentLength: 1,
            continueAddInfo: false,
            designConcept: {
                content: '',
                contentFile: [],
            },
            accountId: '',
            specialItem: ['individuality', 'style', 'communicationStyle'],
            copyData: {
                individuality: '',
                style: '',
                communicationStyle: '',
                city: '',
                province: '',
                hobby: [],
            },
            dictionaryList: {},
            cacheAward: {},
            cascaderArrObj: {},
            errorStyle: [{ value: '', errorType: false }],
            errorType: false,
            specialityErrorType: false,
            storyContent: '',
        };
    },
    mounted() {
        ossState.getOssClient();
        this.getDictionary();
    },
    methods: {
        getCoverImgUrl(file) {
            console.log(file, '-file-----');
            this.result.imgUrl = file.url;
            this.result.avatarImgUrl = [{ ...file }];
        },
        avatarClip() {
            // 头像裁剪
            this.$refs['set-cover'].open([this.result.imgUrl], this.result.imgUrl, '', '');
        },
        initHobby(list) {
            list.forEach(item => {
                this.cascaderArrObj[item.id] = item;
                item.lableList && this.initHobby(item.lableList);
            });
        },
        initAwards(list) {
            list.forEach(item => {
                this.cacheAward[item.id] = item;
                item.lableList && this.initAwards(item.lableList);
            });
        },
        handleCheck() {
            let { account, mobile } = this.addResult;
            if (!account || !mobile) {
                this.$message.info('请输入必要信息');
                return;
            }
            designerAdd({ ...this.addResult }).then(res => {
                if (res.isSuccess && res.data) {
                    this.result.accountId = res.data;
                    this.$message.success('添加成功,继续填写信息吧');
                    this.continueAddInfo = true;
                    this.handleInformationEditEchoIntervene({ id: res.data });
                }
            });
        },
        handleChangeTextarea(val) {
            if (val) {
                this.errorType = false;
            }
        },
        open(row) {
            if (row && row.id) {
                this.title = '补充';
                this.type = 'edit';
                this.accountId = row.id;
                this.continueAddInfo = true;
                this.handleInformationEditEchoIntervene(row);
            } else {
                this.title = '新增设计师';
                this.type = 'add';
                this.addResult = {
                    account: '',
                    mobile: '',
                };
                this.continueAddInfo = false;
            }
            this.drawer = true;
        },
        sendAvatarImg(file) {
            this.result.imgUrl = file.length ? file[0].url : '';
            this.result.avatarImgUrl = file;
        },
        handleCrop() {
            this.$refs['set-cover'].open([this.result.imgUrl], this.result.imgUrl, '', '');
        },
        handleInformationEditEchoIntervene(row) {
            informationEditEchoIntervene({ accountId: row.id }).then(res => {
                let _individuality = res.data.individuality.map(item => item.id);
                let _style = res.data.style.map(item => item.id);
                let _communicationStyle = res.data.communicationStyle.map(item => item.id);
                this.localResult = {
                    ...res.data,
                    individuality: _individuality,
                    style: _style,
                    communicationStyle: _communicationStyle,
                };
                let _speciality0 = res.data.speciality[0] ? res.data.speciality[0] : '';
                let _speciality1 = res.data.speciality[1] ? res.data.speciality[1] : '';
                let _speciality2 = res.data.speciality[2] ? res.data.speciality[2] : '';
                this.result = {
                    ...res.data,
                    contentFile: res.data.designConcept.contentFile,
                    content: res.data.designConcept.content,
                    speciality: [_speciality0, _speciality1, _speciality2],
                    designerSaidList: res.data.designerSaid?.length ? res.data.designerSaid : [''],
                    storyTitle: res.data.storyTitle,
                    storyContent: res.data.storyContent,
                    city: [res.data.province, res.data.city],
                    province: res.data.province,
                    avatarImgUrl: res.data.imgUrl ? [{ url: res.data?.imgUrl }] : [],
                    awards: res.data.awards,
                };
                this.copyData.style = res.data.style;
                this.$refs.baseFormRef.resetForm();
            });
        },
        getInfo(row) {
            let params = {
                accountId: row.id,
            };
            getDesignerInfo(params).then(res => {
                res.data.accountId = row.id;
                res.data.speciality = res.data.speciality?.length
                    ? res.data.speciality
                    : ['', '', ''];
                if (res.data.speciality?.length < 3) {
                    res.data.speciality.length = 3;
                }

                res.data.designerSaidList = res.data.designerSaidList?.length
                    ? res.data.designerSaidList
                    : [''];

                this.result = { ...res.data };
            });
        },
        close(type) {
            this.drawer = false;
            this.result = {
                speciality: ['', '', ''],
                designerSaidList: [''],
                storyTitle: '',
                storyContent: '',
                awards: [],
            };
            this.$forceUpdate();
        },
        closeDrawer() {
            this.close('init');
        },
        updateSpeciality: function(index, e) {
            // e.target.outerText
        },
        addSpeciality() {
            this.result.speciality.push('');
        },
        addDesignerSaidList() {
            this.result.designerSaidList.push('');
            this.currentLength += 1;
        },
        deleteDesignerSaidList() {
            this.currentLength -= 1;
            this.result.designerSaidList.pop();
        },
        getValue(data, ind) {
            this.result.designerSaidList[ind] = data;
        },
        getStoryValue(data, ind) {
            console.log('getStoryValue', data);
            this.storyContent = data;
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('DESIGNERS_INFO', () => {
                //校验回调
                let designerSaidList = this.result.designerSaidList;
                // let nums = this.result.designerSaidList.map((d, index) => index);

                // for (let dindex of nums) {
                //     let item =
                //         this.$refs[`designerSaidEditor${dindex}`][0].editor.getText() == ''
                //             ? this.$refs[`designerSaidEditor${dindex}`][0].editor.getText()
                //             : this.$refs[`designerSaidEditor${dindex}`][0].editor.getHtml();

                //     if (item) {
                //         designerSaidList.push(item);
                //     }
                // }

                let obj = this.$refs.designProjectRef.returnData();
                let _awards =
                    obj.awards &&
                    obj.awards.map(item => {
                        if (this.cacheAward[item.id]) {
                            return {
                                ...this.cacheAward[item.id],
                                contentFile: item.contentFile,
                            };
                        } else {
                            return {
                                id: '',
                                content: item.content,
                                contentFile: item.contentFile,
                            };
                        }
                    });
                _awards = (_awards && _awards.filter(d => d)) || [];
                obj.awards = _awards;
                let params = {
                    ...this.copyData,
                    ...this.result,
                    ...this.localResult,
                    accountId: this.result.accountId || this.accountId, // 设计师id
                    // designerSaidList: designerSaidList, // 设计师说
                    storyTitle: this.result.storyTitle, // 小故事标题
                    storyContent: this.storyContent, // 故事正文
                    speciality: this.result.speciality.filter(d => d),
                    designConcept: {
                        content: this.result.content,
                        contentFile: this.result.contentFile,
                    },
                    aboutMe: this.result.aboutMe,
                    us: this.result.us,
                    ...obj,
                    imgUrl: this.result.avatarImgUrl[0]?.url,
                    city: this.copyData.city || this.result.city[1],
                    province: this.copyData.province || this.result.province,
                    designerSaid: designerSaidList, // 设计师说
                };
                params.hobby = params.hobby?.map(item => {
                    if (this.cascaderArrObj[item]) {
                        return this.cascaderArrObj[item];
                    }
                });
                params.individuality = this.filterList('individualityList', params.individuality);
                params.style = this.filterList('styleList', params.style);

                params.communicationStyle = this.filterList(
                    'communicationStyleList',
                    params.communicationStyle
                );
                console.log('params-----', params);

                let flag = this.validateCurrentPage(params);
                if (flag) {
                    informationEditIntervene(params).then(res => {
                        if (res.isSuccess) {
                            this.close();
                            this.$emit('handleClose');
                        }
                    });
                }
            });
        },
        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            if (data.row.key == 'orderSort') {
                this.result.orderSort = data.value.replace(/^(0+)|[^\d]|\./g, '');
            }
            if (this.specialItem.includes(data.key)) {
                this.copyData[data.key] = this.filterList(data.key + 'List', data.value);
            } else if (data.key == 'city') {
                this.copyData.city = data.value[1];
                this.copyData.province = data.value[0];
            } else if (data.key == 'hobby') {
                let _hobby = data.value?.map(item => {
                    if (this.cascaderArrObj[item]) {
                        return this.cascaderArrObj[item];
                    }
                });
                this.copyData.hobby = _hobby;
            } else {
                this.copyData[data.key] = data.value;
            }
        },

        filterList(name, arr) {
            if (!arr?.length) return;
            let _arr = [];
            arr.map(item => {
                let obj = this.dictionaryList[name].find(row => row.id == item);
                _arr.push(obj);
            });
            return _arr;
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
        },
        getDictionary() {
            informationEditEchoListIntervene().then(res => {
                if (res.isSuccess) {
                    // this.dictionary = res.data;
                    this.columnsBaseInfo.age.options.options = res.data.ageList;
                    this.columnsBaseInfo.city.options.options = res.data.cityList;
                    this.columnsBaseInfo.communicationStyle.options.options =
                        res.data.communicationStyleList;
                    this.columnsBaseInfo.hobby.options.options = res.data.hobbyList;
                    this.columnsBaseInfo.individuality.options.options = res.data.individualityList;
                    this.columnsBaseInfo.rank.options.options = res.data.rankList;
                    this.columnsBaseInfo.style.options.options = res.data.styleList;
                    this.awardsList = res.data.awardsList;
                    this.dictionaryList = res.data;
                    this.initHobby(res?.data?.hobbyList);
                    this.initAwards(res?.data?.awardsList);
                }
            });
        },
        sendFileList(file) {
            this.result.contentFile = file;
        },
        sendUsList(file) {
            this.result.us = file;
        },
        sendAboutMeList(file) {
            this.result.aboutMe = file;
        },
        validateArray(arr) {
            return arr.map(item => {
                console.log(item, 'item');
                const hasError = !item || item.value === '';
                return {
                    errorType: hasError,
                    value: item,
                };
            });
        },
        validateCurrentPage(params) {
            let flag = true;
            if (!params.avatarImgUrl[0]?.url) {
                this.$message.warning('请上传头像');
                flag = false;
                return;
            }
            this.$refs.baseFormRef.validateAll(res => {
                if (!res) {
                    this.$message.warning('基本信息必填');
                    flag = false;
                    return;
                }
            });
            let arr = this.validateArray(params.designItems);

            console.log(arr, params.designItems);
            this.errorStyle = arr;
            let obj = arr.find(item => item.errorType);
            if (obj) {
                this.$message.warning('项目经验必填');
                flag = false;
                return;
            }
            if (!params.designConcept.content) {
                this.errorType = true;
                this.$message.warning('设计理念必填');
                flag = false;
                return;
            }
            // 特质必填
            if (!params?.speciality?.length) {
                this.specialityErrorType = true;
                this.$message.warning('特质必填');
                flag = false;
                return;
            }
            if (params?.storyTitle?.length > 15) {
                this.$message.warning('故事标题最多15个字');
                flag = false;
                return;
            }
            return flag;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
    position: relative;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    background: #fff;
    text-align: right;
    // position: fixed;
    // bottom: 0;
    // right: 0;
    // left: 0;
    padding-right: 40px;
    height: 80px;
}
.speciality-input {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 6px 12px;
    outline: none;
    color: #606266;
    margin-bottom: 10px;
    &:focus {
        border: 1px solid #409eff;
    }
}
.must {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #ff0200;
    border-radius: 50%;
    color: #ff0200;
    // margin-left: 8px;
}
.red-line {
    display: block;
    width: 2px;
    height: 16px;
    background: #b64242;
    margin-right: 10px;
}
.info {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    .item {
        min-width: 150px;
        max-width: 400px;
        display: flex;
        margin-right: 100px;
    }
    .label {
        height: 21px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #ababab;
        line-height: 21px;
        text-align: justify;
        font-style: normal;
        // min-width: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .value {
        height: 21px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        line-height: 21px;
        text-align: justify;
        font-style: normal;
        // margin-right: 149px;
        margin-bottom: 20px;
    }
}
.is-error {
    border-color: red;
    /deep/ textarea {
        border-color: red;
    }
}
</style>
