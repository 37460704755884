<template>
    <div>
        <el-dialog
            title="审核设置"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :before-close="handleClose"
            width="40%"
        >
            <el-form :model="form" ref="remarkForm">
                <el-form-item label="审核说明:">
                    <div class="setCen">
                        启用【审核{{ workType == 0 ? '笔记' : '案例' }}】，则前台用户提交{{
                            workType == 0 ? '笔记' : '案例'
                        }}后,后台人工审核判定是否通过；禁用【审核{{
                            workType == 0 ? '笔记' : '案例'
                        }}】，前台用户提交{{
                            workType == 0 ? '笔记' : '案例'
                        }}后，系统自动通过，无需人工审核。
                    </div>
                </el-form-item>
                <el-form-item :label="workType == 0 ? '审核笔记:' : '审核案例:'">
                    <el-radio-group v-model="form.value">
                        <el-radio label="1">启用</el-radio>
                        <el-radio label="0">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { manualReview, manualReviewEcho } from '@/api/mkt/article';

export default {
    name: 'audit-settings',
    props: {
        settingsShow: {
            type: Boolean,
            default: false,
        },
        workType: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            dialogFormVisible: false,
            form: {
                value: '0',
            },
        };
    },
    watch: {
        settingsShow: {
            handler(val) {
                this.dialogFormVisible = val;
                if (val) {
                    this.manualReviewEcho();
                }
            },
        },
    },
    methods: {
        manualReviewEcho() {
            var params = {
                workType: this.workType,
            };
            manualReviewEcho(params).then(res => {
                if (res.errorCode == 200) {
                    console.log(res);
                    this.form.value = res.data;
                }
            });
        },
        submit() {
            var params = {
                workType: this.workType,
                value: this.form.value,
            };
            manualReview(params).then(res => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.$emit('onCloseSet');
                } else {
                    this.$message.info(res.message);
                }
            });
        },
        handleClose() {
            this.dialogFormVisible = false;
            this.$emit('onCloseSet');
        },
    },
};
</script>
<style scoped>
.setCen {
    display: flex;
    margin-top: 10px;
    line-height: 20px;
    text-align: justify;
}
</style>
