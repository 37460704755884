<template>
    <div class="add-tag-container">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="356px"
            :title="title"
            :before-close="close"
        >
            <sc-form-v2
                name=""
                tablename="CRM_TAG_GROUP"
                :columnsNum="1"
                :columns="columns"
                :result.sync="result"
                :esayStyle="true"
                :LS="LS"
                :columnsPaddingRight="20"
                @change="changeFn"
                @click="clickFn"
                ref="groupform"
            >
                <!-- <template v-slot:labels>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName" style="min-width: 60px;">标签:</div>
                        </template>
                        <div style="text-align: left;padding-right: 20px;">
                            <el-cascader
                                v-model="selfSelect"
                                :options="labelsAllOptions"
                                :props="props"
                                clearable
                                @change="changeSelect"
                                @remove-tag="removeSelect"
                                ref="selfSelect"
                                style="width:100%;padding-right: 20px;"
                                size="small"
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template> -->
            </sc-form-v2>
            <div class="dialog-footer">
                <el-button size="mini" @click="close">取消</el-button>
                <el-button size="mini" type="primary" @click="handleSubmit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mixins from '@/mixins';
import { addGroup } from '../../../../../api/mkt/home-group';
export default {
    props: {},
    mixins: [mixins],
    data() {
        return {
            title: '新增',
            addialogVisible: false,
            dialogVisible: false,
            props: { multiple: true, label: 'labelName', value: 'id', checkStrictly: true },
            LS: { 'min-width': '60px' }, //设置label宽度

            columns: {
                contentId: {
                    name: '内容ID',
                    type: 'input',
                    hidden: false,
                    options: {
                        placeholder: '请输入内容ID',
                    },

                    rules: [{ required: true, message: '请输入内容ID', trigger: 'blur' }],
                },
                // defOrder: {
                //     name: '排序值',
                //     type: 'input',
                //     options: {
                //         placeholder: '请输入排序值，数值越小权重越高',
                //         inputType: 'number',
                //         max: 99999999,
                //     },
                //     rules: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
                // },
            },
            result: {
                contentId: '',
                collectionId: '',
            },
            selfSelect: [],
        };
    },
    mounted() {
        this.getLabelCommonList();
    },
    methods: {
        open(row) {
            this.addialogVisible = true;
            console.log('--------row', row);
            this.result.collectionId = row.collectionId;
            if (row.id) {
                this.title = '编辑';
                this.result = {
                    contentId: row.contentId || '',
                    collectionId: row.collectionId,
                };
            }
            if (!row.id) {
                this.title = '新增';
            }
            console.log('--------result', this.result);

            this.$forceUpdate();
        },
        close() {
            this.addialogVisible = false;
            this.result = {
                contentId: '',
                collectionId: '',
            };
        },
        handleSubmit() {
            this.$refs.groupform.validateAll('CRM_TAG_GROUP', () => {
                //校验回调
                let params = {
                    collectionId: this.result.collectionId,
                    contentId: this.result.contentId,
                };

                addGroup(params).then(res => {
                    console.log(res, 'res-----');

                    if (res.errorCode == 200) {
                        let message = !this.result.id ? '添加成功' : '保存成功';
                        this.$message.success(message);
                        this.close();
                        this.$emit('handleClose');
                    } else {
                        this.$message.info(res.message);
                    }
                });
            });
        },

        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
            // console.log(data);
            if (data.row.key == 'defOrder') {
                this.result.defOrder = data.value.replace(/^(0+)|[^\d]|\./g, '');
                console.log('----------------', this.result.defOrder);
            }
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
        changeSelect(val) {
            console.log(val, 'val--------');
        },
        removeSelect(val) {
            console.log(val, 'removeSelect');
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
</style>
