<template>
    <div style="display:flex;align-items:flex-start;">
        <div class="cropper-content">
            <vue-cropper
                ref="cropper"
                :img="option.img"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :can-scale="option.canScale"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                @real-time="realTime"
                :high="option.high"
                @img-load="imgLoad"
                mode="contain"
                :max-img-size="option.max"
                @crop-moving="cropMoving"
            ></vue-cropper>
        </div>
        <div
            class="show-preview"
            :style="{
                width: previews.w + 'px',
                overflow: 'hidden',
                margin: '0 5px',
            }"
        >
            <div :style="previews.div" style="height:auto;">
                <img :src="previews.url" :style="previews.img" />
            </div>
        </div>
    </div>
</template>

<script>
import { VueCropper } from 'vue-cropper';
// import DEV from '@list/config.js';
import { getOssConfig } from '../api/login';
import { oss } from '@/config.js';
import { ossToBase } from '../api/mkt/exhibit';
import OSS from 'ali-oss';
import { formatTimeTwo } from '@/utils/util';
// 1、封面图只展示在商场组件位置 不做回显。
// 2、默认回显列表第一张图作为封面
export default {
    name: 'clip-img',
    components: {
        VueCropper,
    },
    props: {
        appendToBody: {
            type: Boolean,
            default: false,
        },
        showImgList: {
            type: Array,
            default: () => [],
        },
        cacheImgUrl: {
            type: String,
            default: '',
        },
        init: {
            type: Boolean,
            default: false,
        },
        closeUpload: {
            type: Boolean,
            default: false,
        },
        // 截取框按图片尺寸大小展示
        fixedChange: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        showImgList: {
            immediate: true,
            handler(newV, oldVal) {
                // console.log('-----watch showImgList', newV);
                let that = this;
                if (newV?.length) {
                    that.loading = true;
                    let _url = that.$props.cacheImgUrl || newV[0];
                    that.ossToBase(_url, true);
                    return;
                }
                that.option.img = '';
            },
        },
        cacheImgUrl: {
            immediate: true,
            handler(newV, oldVal) {
                this.option.img = newV;
            },
        },
        init: {
            immediate: true,
            handler(newV, oldVal) {
                this.initCanvas = newV;
            },
        },
        fixedChange: {
            immediate: true,
            handler(newV, oldVal) {
                if (newV) {
                    this.option.fixed = false;
                    this.$forceUpdate();
                } else {
                    this.option.fixed = true;
                }
            },
        },
    },

    data() {
        return {
            model: false,
            modelSrc: '',
            crap: false,
            previews: {},
            lists: [
                {
                    img: 'https://avatars2.githubusercontent.com/u/15681693?s=460&v=4',
                },
            ],
            option: {
                img: '', // https://avatars2.githubusercontent.com/u/15681693?s=460&v=4
                size: 1,
                full: true,
                outputType: 'png',
                canMove: true,
                fixedBox: false,
                original: false,
                canMoveBox: true,
                autoCrop: true,
                // 只有自动截图开启 宽度高度才生效
                autoCropWidth: 340,
                autoCropHeight: 340,
                centerBox: true,
                high: false,
                max: 99999,
                canScale: false,
            },

            fixed: false,
            fixedNumber: [16, 9],
        };
    },

    mounted: function() {
        //oss签名
        this.uploadImg = '';
        getOssConfig().then(res => {
            if (res.code == 200) {
                let ossConfig = res.data;
                this.ossClient = new OSS({
                    accessKeyId: ossConfig.stsKeyId,
                    accessKeySecret: ossConfig.stsSecret,
                    stsToken: ossConfig.token,
                    bucket: oss.bucket,
                    region: oss.region,
                });
            }
        });
    },
    methods: {
        changeImg() {
            this.option.img = this.cacheImgUrl;
        },
        startCrop() {
            // start
            this.crap = true;
            this.$refs.cropper.startCrop();
        },
        stopCrop() {
            //  stop
            this.crap = false;
            this.$refs.cropper.stopCrop();
        },
        clearCrop() {
            // clear
            this.$refs.cropper.clearCrop();
        },
        refreshCrop() {
            // clear
            this.$refs.cropper.refresh();
        },
        changeScale(num) {
            num = num || 1;
            this.$refs.cropper.changeScale(num);
        },
        rotateLeft() {
            this.$refs.cropper.rotateLeft();
        },
        rotateRight() {
            this.$refs.cropper.rotateRight();
        },
        finish(type) {
            // 输出
            // var test = window.open('about:blank')
            // test.document.body.innerHTML = '图片生成中..'
            if (type === 'blob') {
                this.$refs.cropper.getCropBlob(data => {
                    console.log(data);
                    var img = window.URL.createObjectURL(data);
                    this.model = true;
                    this.modelSrc = img;
                });
            } else {
                this.$refs.cropper.getCropData(data => {
                    this.model = true;
                    console.log(data, 'base64');
                    this.modelSrc = data;
                });
            }
        },
        // 实时预览函数
        realTime(data) {
            console.log(data, '实时预览');
            this.previews = data;
        },

        finish2(type) {
            this.$refs.cropper2.getCropData(data => {
                this.model = true;
                this.modelSrc = data;
            });
        },
        finish3(type) {
            this.$refs.cropper3.getCropData(data => {
                this.model = true;
                this.modelSrc = data;
            });
        },
        down(type) {
            // event.preventDefault()
            var aLink = document.createElement('a');
            aLink.download = 'demo';
            // 输出
            if (type === 'blob') {
                this.$refs.cropper.getCropBlob(data => {
                    this.downImg = window.URL.createObjectURL(data);
                    aLink.href = window.URL.createObjectURL(data);
                    aLink.click();
                });
            } else {
                this.$refs.cropper.getCropData(data => {
                    this.downImg = data;
                    aLink.href = data;
                    aLink.click();
                });
            }
        },

        uploadImg(e, num) {
            //上传图片
            // this.option.img
            var file = e.target.files[0];
            if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种');
                return false;
            }
            var reader = new FileReader();
            reader.onload = e => {
                let data;
                if (typeof e.target.result === 'object') {
                    // 把Array Buffer转化为blob 如果是base64不需要
                    data = window.URL.createObjectURL(new Blob([e.target.result]));
                } else {
                    data = e.target.result;
                }
                if (num === 1) {
                    this.option.img = data;
                } else if (num === 2) {
                    this.example2.img = data;
                }
            };
            // 转化为base64
            // reader.readAsDataURL(file)
            // 转化为blob
            reader.readAsArrayBuffer(file);
        },
        imgLoad(msg) {
            console.log(msg);
        },
        cropMoving(data) {
            console.log(data, '截图框当前坐标');
        },
        returnData() {
            let _data;
            this.$refs.cropper.getCropData(data => {
                // do something
                console.log(data);
                _data = data;
            });
            return _data;
        },
    },
};
</script>
<style lang="less" scoped>
.cropper-content {
    width: 650px;
    height: 500px;
}
* {
    margin: 0;
    padding: 0;
}

.cut {
    width: 500px;
    height: 500px;
    margin: 30px auto;
}

.c-item {
    max-width: 800px;
    margin: 10px auto;
    margin-top: 20px;
}

.content {
    margin: auto;
    max-width: 1200px;
    margin-bottom: 100px;
}

.test-button {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c0ccda;
    color: #1f2d3d;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 20px 10px 0px 0px;
    padding: 9px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #50bfff;
    border-color: #50bfff;
    transition: all 0.2s ease;
    text-decoration: none;
    user-select: none;
}

.des {
    line-height: 30px;
}

code.language-html {
    padding: 10px 20px;
    margin: 10px 0px;
    display: block;
    background-color: #333;
    color: #fff;
    overflow-x: auto;
    font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type,
        Writer, Ubuntu, Mono;
    border-radius: 5px;
    white-space: pre;
}

.show-info {
    margin-bottom: 50px;
}

.show-info h2 {
    line-height: 50px;
}

/*.title, .title:hover, .title-focus, .title:visited {
        color: black;
      }*/

.title {
    display: block;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    margin: 20px 0px;
    background-image: -webkit-linear-gradient(
        left,
        #3498db,
        #f47920 10%,
        #d71345 20%,
        #f7acbc 30%,
        #ffd400 40%,
        #3498db 50%,
        #f47920 60%,
        #d71345 70%,
        #f7acbc 80%,
        #ffd400 90%,
        #3498db
    );
    color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: slide 5s infinite linear;
    font-size: 40px;
}

.test {
    height: 500px;
}

.model {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}

.model-show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.model img {
    display: block;
    margin: auto;
    max-width: 80%;
    user-select: none;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(
            45deg,
            #eee 25%,
            transparent 25%,
            transparent 75%,
            #eee 75%,
            #eee 100%
        ),
        linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}

.c-item {
    display: block;
    user-select: none;
}

@keyframes slide {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -100% 0;
    }
}
</style>
