<template>
    <div class="mkt">
        <sc-search-v2
            :columns="columns"
            :result.sync="result"
            :LS="LS"
            :btn="btn"
            :size="size"
            @change="changeFn"
            @search="clickFn"
            ref="form1"
            @click-more="handleClickMore"
        ></sc-search-v2>
        <div class="content">
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeight"
                :read-table="true"
                :load="loading"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @rowChange="rowChange"
                @dbClick="dbClick"
                @rowClick="rowClick"
                @selection-change="selectionChange"
                @sortChange="sortChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <template v-slot:nickName>
                    <el-table-column>
                        <template slot="header">昵称</template>
                        <template slot-scope="scope">
                            <div class="nick-box">
                                <span>{{ scope.row.nickName }}</span>
                                <!-- <img :src="scope.row.nickName" alt=""> -->
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:firstTouchName>
                    <el-table-column width="150">
                        <template slot="header">来源人</template>
                        <template slot-scope="scope">
                            {{ scope.row.firstTouchName || scope.row.firstTouchMobile }}
                            <el-tag size="mini" v-if="scope.row.firstTouchJob">
                                {{ scope.row.firstTouchJob }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </template>

                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="110">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="onEdit(scope.row)">
                                详情
                            </el-button>
                            <span>&nbsp;</span>
                            <!-- <sc-qrcode-popover :id="scope.row.id" type="user"></sc-qrcode-popover> -->
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
    </div>
</template>

<script>
import { getPotentList } from '@/api/mkt/user.js';
import { treeFlat } from '@/utils/tree';
import mixins from '@/mixins';

export default {
    name: 'potentalCus',
    mixins: [mixins],
    data() {
        return {
            loading: false,
            columns: {
                keyWord: {
                    name: '关键词',
                    type: 'input',
                    options: {
                        defaultValueBase: '',
                        placeholder: '搜索昵称/手机号',
                    },
                    spanCol: 1,
                },
                softwareType: {
                    name: '来源平台',
                    type: 'select',
                    options: {
                        clearable: true,
                        options: [
                            {
                                dictValue: 1,
                                sysLabel: '尚层装饰',
                            },
                            {
                                dictValue: 2,
                                sysLabel: '尚层家',
                            },
                        ],
                        props: {
                            value: 'dictValue',
                            label: 'sysLabel',
                        },
                        placeholder: '请选择',
                    },
                    spanCol: 1,
                },
                loginTime: {
                    name: '最近访问时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入创建时间',
                    },
                    spanCol: 2,
                },
                customerStatus: {
                    name: '角色',
                    type: 'select',
                    options: {
                        defaultValue: [],
                        clearable: true,
                        options: [
                            { id: 1, label: '新潜客' },
                            { id: 2, label: '老潜客' },
                            { id: 3, label: '服务中客户' },
                            { id: 4, label: '竣工客户' },
                        ],
                        props: {
                            value: 'id',
                            label: 'label',
                        },
                    },
                    rules: [{ required: true, message: '请选择' }],
                },
                firstLoginTime: {
                    name: '首次登录时间',
                    type: 'date',
                    options: {
                        dateType: 'daterange',
                        defaultValueBase: '',
                        placeholder: '请输入创建时间',
                    },
                    spanCol: 2,
                },
            },
            result: {
                keyWord: '',
                softwareType: '',
                firstLoginTime: [],
                loginTime: [],
                sortType: null,
            },
            LS: { 'min-width': '120px' }, //设置label宽度
            CS: { width: '300px', 'padding-right': '10px' },
            btn: {
                oneTxt: '搜索', //第一个按钮名称
                twoTxt: '清空筛选', //第二个按钮名称
                // twoShow: true, //隐藏第二个按钮
            },
            size: 'small',
            moreDown: false,
            tableHead: [
                { name: '昵称', key: 'nickName' },
                { name: '手机号', key: 'mobile' },
                { name: '性别', key: 'sex', formatter: this.formatterSex, width: 80 },
                { name: '角色', key: 'customerStatus', formatter: this.formattRole },
                { name: '来源人', key: 'firstTouchName' },
                { name: '收藏内容数', key: 'collection', sortable: true },
                { name: '所属城市', key: 'city', width: 80 },
                { name: '最近预约时间', key: 'appointmentTime', sortable: true, minWidth: 92 },
                { name: '最近访问时间', key: 'lastLoginTime', sortable: true, minWidth: 92 },
                { name: '首次登录时间', key: 'firstLoginTime', sortable: true, minWidth: 92 },
                { name: '来源平台', key: 'softwareType', formatter: this.formatterSource },
                { name: '终端', key: 'terminal', minWidth: 60, formatter: this.formatterTerminal },
                { name: '操作', key: 'control' },
            ],

            // 默认都是true，哪个不需要对应单个设置就行，可以只设置一项
            tableAddConfig: {
                before: true,
                after: true,
                selectBefore: true,
                selectAfter: true,
                del: true,
            },
            tableHeight: window.innerHeight - 185,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            showBtn: false,
        };
    },
    created() {
        this.getPotentList();
        let userInfo = this.$store.state.userInfo;
        let { routerList } = userInfo;
        treeFlat(routerList, 'children', item => {
            if (item.name == '潜客详情') {
                this.showBtn = true;
            }
        });
        console.log('-------------showBtn', this.showBtn);
    },
    methods: {
        handleClickMore() {
            this.tableHeight = window.innerHeight - 240;
        },
        formatterSex(row) {
            let _sex = row.sex;
            const obj = {
                1: '男',
                2: '女',
                3: '未知',
                // null: '--',
            };
            return obj[_sex];
        },
        formattRole(row) {
            let obj = { 1: '新潜客', 2: '老潜客', 3: '服务中客户', 4: '竣工客户' };
            return obj[row.customerStatus];
        },
        formatterSource(row) {
            let softwareType = row.softwareType;
            const obj = {
                1: '尚层装饰',
                2: '尚层家',
            };
            console.log('----------softwareType', row, row.softwareType && obj[row.softwareType]);

            return (row.softwareType && obj[softwareType]) || '';
        },
        formatterTerminal(row) {
            const obj = {
                1: '安卓',
                2: '小程序',
                3: '苹果',
            };
            return (row.terminal && obj[row.terminal]) || '';
        },
        async getPotentList() {
            this.loading = true;
            let params = {
                ...this.result,
                page: this.page.page,
                size: this.page.size,
            };
            if (this.result.sortType) {
                params.sortType = this.result.sortType;
            }
            if (params.firstLoginTime?.length) {
                params.bfDate = params.firstLoginTime[0] + ' 00:00:00';
                params.efDate = params.firstLoginTime[1] + ' 23:59:59';
            } else {
                params.bfDate = '';
                params.efDate = '';
            }
            if (params.loginTime?.length) {
                params.bDate = params.loginTime[0] + ' 00:00:00';
                params.eDate = params.loginTime[1] + ' 23:59:59';
            } else {
                params.bDate = '';
                params.eDate = '';
            }
            delete params.firstLoginTime;
            delete params.loginTime;
            let searchResultTemp = {};
            for (var key in params) {
                let value = params[key];
                if (
                    value !== null &&
                    value !== '' &&
                    value !== 'undefined' &&
                    value !== undefined &&
                    JSON.stringify(value) != '[]'
                ) {
                    searchResultTemp[key] = value;
                }
            }
            params = searchResultTemp;
            const res = await getPotentList(params);
            this.loading = false;
            if (res?.isSuccess) {
                this.list = res.data.children || [];
                this.page.count = res.data.total;
            }
        },
        changeFn(e) {
            console.log(777, e);
        },
        sortChange({ column, prop, order }) {
            console.log(column, prop, order);
            if (order == 'ascending') {
                // 上升
                switch (prop) {
                    case 'collection':
                        this.result.sortType = 1;
                        break;
                    case 'lastLoginTime':
                        this.result.sortType = 3;
                        break;
                    case 'firstLoginTime':
                        this.result.sortType = 5;
                        break;

                    default:
                        break;
                }
            } else {
                // 下降
                switch (prop) {
                    case 'collection':
                        this.result.sortType = 2;
                        break;
                    case 'lastLoginTime':
                        this.result.sortType = 4;
                        break;
                    case 'firstLoginTime':
                        this.result.sortType = 6;
                        break;

                    default:
                        break;
                }
            }
            this.getPotentList();
        },
        clickFn(e, type) {
            if (type == 'clear') {
                this.result.sortType = null;
            }
            this.page.size = 30;
            this.page.page = 1;
            this.getPotentList();
        },
        pageChange(val) {
            this.page.page = val;
            this.getPotentList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getPotentList();
        },
        rowChange() {},
        dbClick() {},
        rowClick() {},
        selectionChange() {},
        onEdit(row) {
            if (!this.showBtn) {
                this.$message('您暂无查看权限');
                return false;
            }
            this.$router.push({
                path: '/mkt/user/potental-cus/detail',
                query: { row: JSON.stringify(row) },
            });
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-table__body .cell .text:empty::before {
    content: '--';
}
</style>
