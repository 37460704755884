<template>
    <el-dialog
        :title="null"
        :visible.sync="videoViewVisible"
        class="video-group"
        append-to-body
        :before-close="videoViewClose"
    >
        <video
            v-if="videoUrl"
            class="video-item"
            :src="videoUrl"
            controls="controls"
            autoplay="autoplay"
        ></video>

        <div id="videoDom" v-else>
            <video id="videoPlayer" class="video-item video-js vjs-default-skin"></video>
        </div>
    </el-dialog>
</template>
<script>
import Videojs from 'video.js';
import 'video.js/dist/video-js.css';
let player;

export default {
    name: 'video-view',
    data() {
        return {
            videoViewVisible: false,
            videoUrl: '',
            videoPlayerOption: {
                controls: true, //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                poster: '', //封面
                autoplay: true, //自动播放属性,
                language: 'zh-CN', //汉化
                muted: false, // 静音播放
                preload: 'auto', //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                fluid: true, // 当true时，将按比例缩放以适应其容器
                notSupportedMessage: '此视频暂无法播放，请稍后再试',
                sources: [
                    {
                        // type: 'application/x-mpegURL',
                        src: '', //视频播放地址
                    },
                ],
            },
            videoType: {
                m3u8: 'application/x-mpegURL',
                mp4: 'video/mp4',
                flv: 'video/x-flv',
                mov: 'rtmp/mp4',
                webm: 'video/webm',
                ogg: 'video/ogg',
                avi: 'video/avi',
                wmv: 'video/wmv',
            },
        };
    },
    mounted() {},
    methods: {
        videoViewOpen(url) {
            this.videoViewVisible = true;
            let first = url.lastIndexOf('.'); //取到文件名开始到最后一个点的长度
            let suffix = url.substring(first + 1, url.length);
            if (suffix == 'm3u8') {
                if (player) {
                    this.createDom();
                }
                this.videoPlayerOption.sources = [
                    {
                        type: 'application/x-mpegURL',
                        src: url, //视频播放地址
                    },
                ];
                setTimeout(() =>
                    this.$nextTick(() => {
                        player = Videojs(
                            document.getElementById('videoPlayer'),
                            this.videoPlayerOption,
                            () => {
                                console.log('onPlayerReady', this);
                                player.play();
                            }
                        );
                    })
                );
            } else {
                this.videoUrl = url;
            }
        },
        videoViewClose() {
            if (player && !this.videoUrl) {
                player.dispose(); // 该方法会重置videojs的内部状态并移除dom
            }
            this.videoViewVisible = false;
            this.videoUrl = '';
        },
        createDom() {
            const newVideoElement = document.createElement('video');
            newVideoElement.id = 'videoPlayer';
            newVideoElement.className = 'video-item video-js vjs-default-skin';
            newVideoElement.ref = 'videoPlayerRef';
            const oldVideoElement = document.getElementById('videoDom');
            oldVideoElement.appendChild(newVideoElement);
        },
    },
};
</script>
<style lang="less" scoped>
.video-item {
    margin: 0 auto;
    width: 100%;
    height: 500px;
    object-fit: contain;
}
.video-group /deep/ .el-dialog__header {
    // padding: 0;
}
.video-group/deep/ .el-dialog__body {
    min-height: 500px;
}
</style>
