<template>
    <div class="top-btn-container">
        <el-select
            class="sel-cls"
            style="width:300px"
            size="mini"
            v-model="value"
            placeholder="请选择分公司"
            @change="companyChange"
        >
            <el-option
                v-for="item in companyData"
                :key="item.ouCode"
                :label="item.ouName"
                :value="item.ouCode"
            ></el-option>
        </el-select>
        <el-select class="sel-cls" size="mini" v-model="value" placeholder="请选择">
            <el-option
                v-for="item in companyData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>

<script>
import mixins from '@/mixins';
export default {
    name: '',
    mixins: [mixins],
    data() {
        return {
            value: '',
        };
    },
    mounted() {},
    methods: {
        companyChange(value) {
            console.log('------value', value);
            let params = this.$store.state['mkt-pages'].params;
            params.companyCode = value;
            this.$store.commit('mkt-pages/setParams', params);
            console.log('------value', this.$store.state['mkt-pages'].params);
            this.$store.dispatch('mkt-pages/list');
        },
        // this.$emit('getCompany',value)
    },
};
</script>
<style lang="less" scoped>
.top-btn-container {
    padding: 10px 0 10px 30px;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #eee;
}
.top-title {
    font-size: 18px;
}
.sel-cls {
    margin-left: 20px;
}
</style>
