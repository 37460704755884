<template>
    <div class="dialogmain">
        <el-dialog
            title="补充信息"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :before-close="close"
            width="30%"
        >
            <sc-form-v2
                tablename="SUPPLE"
                :columnsNum="1"
                :result="result"
                :columns="columns"
                direction="vertical"
                :esayStyle="true"
                :columnsPaddingRight="20"
                @change="changeFn"
                @removeTag="removeTag"
                ref="From2"
            >
                <template v-slot:labelsAll>
                    <el-descriptions-item span="2" :labelStyle="{ background: '#fff' }">
                        <template slot="label">
                            <div class="labelName">标签:</div>
                        </template>
                        <div style="text-align: left">
                            <el-cascader
                                v-model="selfSelect"
                                :options="labelsAllOptions"
                                :props="props"
                                clearable
                                @change="changeSelect"
                                @remove-tag="removeSelect"
                                ref="selfSelect"
                                style="width:100%;"
                            ></el-cascader>
                        </div>
                    </el-descriptions-item>
                </template>
            </sc-form-v2>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini" :loading="loading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mixins from '@/mixins/index';
import getSelect from '@/mixins/getSelect';
import { info, approval, worksEdit } from '@/api/mkt/exhibit';

export default {
    name: 'dialog-remark',
    mixins: [mixins, getSelect],

    data() {
        return {
            localRow: {},
            dialogFormVisible: false,
            form: {
                remark: '',
            },
            loading: false,
            result: {
                labels: [],
                labelsAll: [],
                operationIdsAll: [],
                operationIds: [],
                categoryId: '',
                contentLevel: '',
                Years: '',
                Months: '',
                recommendHighlights: '',
            },

            columns: [
                {
                    key: 'categoryId',
                    name: '分类',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            emitPath: false,
                            checkStrictly: true,
                            value: 'value',
                            label: 'label',
                        },
                    },
                    // rules: [{ required: true, message: '请选择所属分类' }],
                },
                {
                    key: 'operationIdsAll',
                    name: '运营标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择3个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                },
                {
                    key: 'labelsAll',
                    name: '标签',
                    type: 'cascader',
                    options: {
                        placeholder: '最多选择20个',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },
                    },
                    // rules: [{ required: true, message: '请选择标签' }],
                },
                {
                    key: 'contentLevel',
                    name: '评级',
                    type: 'select',
                    options: {
                        placeholder: '请选择',
                        allLevels: false,
                        clearable: true,
                        options: [],
                        props: {
                            label: 'label',
                            value: 'dictValue',
                            checkStrictly: true,
                            emitPath: false,
                        },
                    },
                },
                {
                    key: 'weightSort',
                    name: '权重值',
                    type: 'number',
                    options: {
                        placeholder: '请输入权重值',
                        clearable: true,
                        max: 9999,
                        min: 0,
                    },
                },
                {
                    key: 'rewardName',
                    name: '获奖标签标题',
                    type: 'input',
                    options: {
                        placeholder: '请输入获奖标签标题',
                        clearable: true,
                    },
                },
                {
                    key: 'Years',
                    name: '获奖年',
                    type: 'input',
                    options: {
                        placeholder: '请输入年',
                        clearable: true,
                    },
                },
                {
                    key: 'Months',
                    name: '获奖月',
                    type: 'input',
                    options: {
                        placeholder: '请输入月',
                        clearable: true,
                    },
                },
                {
                    key: 'recommendHighlights',
                    name: '案例亮点',
                    type: 'input',
                    options: {
                        placeholder: '请输入案例亮点（以英文 ; 号分割）',
                        clearable: true,
                    },
                },
            ],
            levelList: [],
        };
    },
    watch: {
        cloumnsList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'categoryId') {
                        val.forEach(ele => {
                            ele.disabled = true;
                        });
                        item.options.options = val;
                    }
                });
            }
        },
        levelList(val) {
            if (val) {
                this.columns.map(item => {
                    if (item.key == 'contentLevel') {
                        item.options.options = val;
                    }
                });
            }
        },
        // normalLabelList(val) {
        //     if (val) {
        //         this.columns.map(item => {
        //             if (item.key == 'labelsAll') {
        //                 const result = val.map(item => {
        //                     const children =
        //                         item.children?.length &&
        //                         item.children.map(every => {
        //                             return {
        //                                 id: every?.id,
        //                                 labelGroupId: every?.labelGroupId,
        //                                 labelName: every?.labelName,
        //                                 parentId: every?.parentId,
        //                                 properties: every?.properties,
        //                             };
        //                         });
        //                     return {
        //                         ...item,
        //                         children: children,
        //                     };
        //                 });
        //                 item.options.options = result;
        //             }
        //         });
        //     }
        // },
        operationListDis(val) {
            if (val) {
                val.map(d => (d.disabled = true));
                this.columns.map(item => {
                    if (item.key == 'operationIdsAll') {
                        item.options.options = val;
                    }
                });
            }
        },
        'result.operationIdsAll'(newValue, oldValue) {
            if (newValue && newValue.length > 3) {
                this.$nextTick(() => {
                    this.result.operationIdsAll = oldValue;
                    this.result.operationIds = this.result.operationIds.filter(d =>
                        this.result.operationIdsAll.includes(d.labelId)
                    );
                });
            }
        },
    },
    created() {
        this.getColumn();
        this.getOperationListDis();
        this.getLabelCommonList();
        this.getLevelList();
    },
    methods: {
        changeFn(val) {
            console.log('---------------111', val);
            if (val.row.key == 'categoryId') {
                this.result.categoryName = val.nodeData && val.nodeData[0].label;
            }
            if (val.row.key == 'labelsAll') {
                if (this.result.labelsAll?.length > 20) {
                    this.$message.warning('最多选择20个标签');
                    this.result.labels = this.result.labelsAll.slice(0, 20);
                }
                this.result.labels = this.changeLabel(val.nodeData, this.deleteData);
            }
            if (val.row.key == 'operationIdsAll') {
                if (this.result.operationIdsAll?.length > 2) {
                    this.$message.warning('最多选择3个标签'); //在监听中处理超出
                }
                this.result.operationIds = this.changeLabel(val.nodeData, this.deleteData);
            }
            if (val.row.key == 'contentLevel') {
                if (val.value != 1) {
                    this.result.rewardYears = '';
                    this.result.rewardName = '';
                    this.result.weightSort = '';
                }
            }
            if (this.result.Years && this.result.Months) {
                this.result.rewardYears = this.result.Months + '·' + this.result.Years;
            }
            if (val.row.key == 'Months') {
                if (!val.value) {
                    this.result.rewardYears = '';
                }
            }
            if (val.row.key == 'Years') {
                if (!val.value) {
                    this.result.rewardYears = '';
                }
            }
        },
        removeTag(val) {
            this.deleteData = val.value;
        },
        handleChangeCloumn(val) {
            this.result.categoryId = val;
        },
        changeLabel(arr, deleteData) {
            // 多选删除事件的时候需要手动删除处理
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理成后端需要的数据得数据
            return arr.map(item => {
                return {
                    labelId: item.data.id,
                    labelName: item.data.labelName,
                    properties: item.data.properties,
                    groupType: item.pathNodes[0].data.groupType,
                };
            });
        },
        open(row, type) {
            this.type = type;
            this.localRow = { ...row };
            this.dialogFormVisible = true;
            if (row) {
                this.showInfo(row);
            }
        },
        showInfo(row) {
            info({ id: row.id }).then(res => {
                this.result = {
                    Years: '',
                    Months: '',
                };
                this.result = { ...res.data.worksInfo };
                this.besicResult = res.data;
                this.result.articleId = row.articleId;
                this.result.categoryId = res.data.worksInfo?.categoryId;
                this.result.contentLevel = res.data.worksInfo.contentLevel + '' || '';
                if (res.data.worksInfo?.rewardYears) {
                    let _arr = res.data.worksInfo?.rewardYears.split('·');
                    this.$set(this.result, 'Years', _arr[1]);
                    this.$set(this.result, 'Months', _arr[0]);
                }

                // 标签数据回显
                let tagList = [];
                if (res.data.labels && res.data.labels.length) {
                    this.result.labelsAll = res?.data?.labels.map(item => {
                        return item.labelId;
                    });
                    res.data.labels.map(item => {
                        tagList.push(item.labelId);
                    });
                }
                // 运营标签数据回显

                if (res.data.operationLabels && res.data.operationLabels.length) {
                    this.result.operationIdsAll = res?.data?.operationLabels.map(item => {
                        return item.labelId;
                    });
                }

                // id转成labelId

                this.result.labels = res.data.labels;
                this.result.operationIds = res.data.operationLabels;
                // this.result.labels = this.changLabelArr(res.data.labels);
                // this.result.operationIds = this.changLabelArr(res.data.operationLabels);

                let showTagList = [];
                this.labelsAllOptions.map(item => {
                    if (item.labelList && item.labelList.length) {
                        item.labelList.map(child => {
                            if (tagList.includes(child.id)) {
                                showTagList.push([item.id, child.id]);
                            }
                        });
                    }
                });
                // selOldData  在这里对 selOldData 进行赋值
                this.initSelectData(showTagList);
            });
        },
        // 处理后端需要的数据
        changLabelArr(arr) {
            if (arr.length > 0) {
                arr.map(item => {
                    item.labelId = item.id;
                    delete item.id;
                });
                return arr;
            } else {
                return [];
            }
        },
        submit() {
            let arr = [];
            this.selfSelect.map(item => {
                return arr.push(item[1]);
            });
            let paramsArr = [];
            this.labelsAllOptions.map(item => {
                if (item.labelList && item.labelList.length) {
                    item.labelList.map(child => {
                        if (arr.includes(child.id)) {
                            paramsArr.push({
                                labelId: child.id,
                                labelName: child.labelName,
                                properties: child.properties,
                                groupType: item.groupType,
                            });
                        }
                    });
                }
            });
            if (this.result.recommendHighlights) {
                let length = this.result.recommendHighlights.match(/;|；/g).length;
                if (length > 2) {
                    this.$message.warning('案例亮点最多输入三项'); //在监听中处理超出
                    return;
                }
            }
            this.result.recommendHighlights =
                (this.result.recommendHighlights &&
                    this.result.recommendHighlights.replace(/；/g, ';')) ||
                '';
            this.loading = true;
            let params = {
                approvalStatus: 3, // 3 审核通过  4 , 审核退回
                worksId: this.localRow.id,
                categoryId: this.result.categoryId || '',
                labels: paramsArr,
                operationIds: this.result.operationIds || [],
                weightSort: this.result.weightSort,
                rewardYears: this.result.rewardYears,
                rewardName: this.result.rewardName,
                recommendHighlights: this.result.recommendHighlights,
            };
            if (this.result.contentLevel) {
                params.contentLevel = parseInt(this.result.contentLevel);
            }
            if (this.type == 0) {
                // 审核
                approval(params)
                    .then(res => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.$message.success('审核通过');
                            this.close();
                        } else {
                            this.$message.info(res.message);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                delete params.approvalStatus;
                // 补充功能时候不传递审批状态和审批备注字段内容
                worksEdit(params)
                    .then(res => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            this.$message.success('编辑成功');
                            this.close();
                        } else {
                            this.$message.info(res.message);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
        close() {
            this.dialogFormVisible = false;
            this.result.categoryId = '';
            this.result.categoryName = '';
            this.result.operationIds = [];
            this.result.labels = [];
            this.result.labelsAll = [];
            this.result.operationIdsAll = [];
            this.result.recommendHighlights = '';
            this.$emit('closeDrawer');
        },
    },
};
</script>
