<template>
    <!-- 点赞 收藏 评论 分享 -->
    <div class="item-box">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="80%"
            title="分享动作明细"
            :before-close="close"
        >
            <div class="infor-item">
                <sc-table-v2
                    v-if="addialogVisible"
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :read-table="true"
                    :load="load"
                    :pop-show="false"
                    :show-add="false"
                    :table-height="tableHeightCalc"
                    :selection="false"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                >
                    <!-- <template v-slot:readNum>
                        <el-table-column label="打开人数">
                            <template slot-scope="scope">
                                <a href="javaScript:void(0);">
                                    <div class="item-box-link" @click="goAcquCustomer(scope.row)">
                                        {{ scope.row.readNum }}
                                    </div>
                                </a>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:readPersons>
                        <el-table-column label="打开用户信息">
                            <template slot-scope="scope">
                                <div class="item-box-user">
                                    <div
                                        v-for="(item, index) in scope.row.readPersons"
                                        :key="index"
                                        class="item-box-user-item"
                                    >
                                        <el-image
                                            class="image"
                                            v-if="item.imgUrl"
                                            fit="contain"
                                            style=""
                                            :src="item.imgUrl"
                                            :preview-src-list="[item.imgUrl]"
                                        ></el-image>
                                        <div class="text">{{ item.nickName }}</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template> -->
                </sc-table-v2>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getShareInfoList } from '@/api/mkt/user.js';

export default {
    name: 'browse-list',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {},
    data() {
        return {
            load: false,
            addialogVisible: false,
            tableHead: [
                { name: '分享时间', key: 'createTime', minWidth: 126 },
                { name: '分享页面', key: 'contentName', minWidth: 100 },
                { name: '内容标题', key: 'title', minWidth: 100 },
                { name: '分享用户昵称', key: 'nickName' },
                { name: '分享用户手机号', key: 'mobile', minWidth: 150 },
                // { name: '打开人数', key: 'readNum', minWidth: 150 },
                // { name: '打开用户信息', key: 'readPersons', minWidth: 150 },
                {
                    name: '用户是否打开',
                    key: 'isOpen',
                    formatter: this.isOpenFormatter,
                },
            ],
            tableHeightCalc: window.innerHeight - 420,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            type: '',
        };
    },
    methods: {
        getShareInfoListFn() {
            this.load = true;
            getShareInfoList({
                shareAccountId: this.row.id,
                page: this.page.page,
                size: this.page.size,
            }).then(res => {
                if (res.code == 200) {
                    this.load = false;
                    this.list = res.data.children;
                    this.page.count = res.data.total;
                }
            });
        },
        open(row) {
            this.page.page = 1;
            this.row = row || {};
            this.getShareInfoListFn();
            this.addialogVisible = true;
        },
        close() {
            this.addialogVisible = false;
        },
        goAcquCustomer(row) {
            this.$router.push({
                path: '/mkt/acquisition/acqu-customer',
                query: {
                    sharingRecordsId: row.id,
                },
            });
        },
        isOpenFormatter(row) {
            let obj = { 1: '是', 0: '否' };
            return obj[row.isOpen] || '';
        },
        pageChange(val) {
            this.page.page = val;
            this.getShareInfoListFn();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getShareInfoListFn();
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    /deep/ .el-dialog__body {
        padding: 10px 20px !important;
    }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .item-box-link {
        cursor: pointer;
        color: #006eff;
    }
    .item-box-user {
        display: flex;
        text-align: center;
        justify-content: flex-start;
        .item-box-user-item + .item-box-user-item {
            margin-left: 8px;
        }
        .image {
            width: 50px;
            height: 50px;
        }
        .text {
            text-wrap: nowrap;
        }
    }
}
</style>
