<template>
    <div>
        <el-drawer
            title="内容库"
            :visible.sync="articleDrawer"
            :direction="direction"
            :before-close="handleClose"
            :append-to-body="true"
            custom-class="self-drawer"
            size="50%"
        >
            <el-button type="info" style="margin-left:10px" plain size="small">
                批量添加
            </el-button>
            <subsetSearch @callback="onCallback" />
            <sc-table-v2
                title=""
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :table-height="height"
                :read-table="true"
                :load="false"
                ref="table"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
                @selection-change="handleSelectionChange"
            >
                <template v-slot:selection>
                    <el-table-column type="selection" width="55"></el-table-column>
                </template>
                <template v-slot:createUserName>
                    <el-table-column label="创建人" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.createUserName }}
                        </template>
                    </el-table-column>
                </template>
                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="60">
                        <template slot-scope="scope">
                            <span class="btn-style" type="text">添加</span>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </el-drawer>
    </div>
</template>

<script>
import subsetSearch from './subset-search.vue';
import { getContentList } from '@/api/mkt/article-group.js';
// import { createNamespacedHelpers } from 'vuex';
// const { mapState, mapMutations } = createNamespacedHelpers('mkt-article-group');
export default {
    name: 'subject-from',
    components: { subsetSearch },
    data() {
        return {
            articleDrawer: false,
            direction: 'rtl',
            tableHead: [
                { name: '', key: 'selection' },
                {
                    name: '内容名称', //表头名称
                    key: 'specialName', //数据列的key值
                },
                { name: '内容类型', key: 'categoryName' },
                { name: '所属分类', key: 'categoryName' },
                { name: '运营标签', key: 'contentLabelId' },
                { name: '标签', key: 'enabled' },
                { name: '创建人', key: 'createUserName' },
                { name: '创建时间', key: 'createTime' },
                { name: '投放城市', key: 'createTime' },
                { name: '操作', key: 'control' },
            ],
            height: window.screen.height - 340,
            page: {
                count: 0,
                page: 1,
                size: 30,
            },
            list: [],
        };
    },

    created() {},
    methods: {
        onCallback(e) {
            var param = {};
            let obj = e;
            for (var key in obj) {
                if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                    param[key] = obj[key];
                }
            }
            this.getData(param);
        },
        getData(param) {
            let searchData = Object.assign(this.page, param);
            getContentList(searchData).then(res => {
                if (res?.isSuccess) {
                    console.log(res, '列表数据');
                    this.list = res.data.children || [];
                    this.page.count = res.data.total || 0;
                }
            });
        },
        pageSizesChange(val) {
            console.log(val, '条数修改');
            this.contentPage.size = val;
        },
        pageChange() {},
        openDrawer() {
            this.articleDrawer = true;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleClose() {
            this.$confirm('确认关闭？')
                .then(() => {
                    this.articleDrawer = false;
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped lang="less">
.subject-btn {
    margin-bottom: 10px;
    overflow: hidden;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}
</style>
