<template>
    <div ref="configTable" class="list-main">
        <div class="list-container" v-if="rowModuleList.value">
            <div class="top-btn-box">
                <el-button
                    size="mini"
                    v-for="(item, index) in btnList"
                    :key="index"
                    @click="operationHandle(item.type)"
                >
                    {{ item.name }}
                </el-button>
                <el-button
                    size="mini"
                    @click="operationHandle('add')"
                    :disabled="tableData.length >= 20"
                >
                    +添加
                </el-button>

                <div v-if="rowModuleList.controlType !== 3 && showStatus" class="only-is-show">
                    <el-switch
                        style="margin-left: 20px"
                        active-color="#13ce66"
                        v-model="isShowParams"
                        @change="initSearchHandle"
                    ></el-switch>
                    只看展示中
                </div>
                <el-input
                    v-model="searchValue"
                    style="width: 100px; margin: 0 10px 0 20px"
                    size="mini"
                    placeholder="标题"
                ></el-input>
                <el-button type="primary" size="mini" @click="initSearchHandle">搜索</el-button>
            </div>
            <div class="table-box">
                <el-table
                    size="mini"
                    :data="tableData"
                    :key="Math.random()"
                    border
                    class="_listTable"
                    :height="tableHeightCalc"
                    @selection-change="selectListHandle"
                    :header-cell-style="{ background: 'rgb(250, 250, 250)' }"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <!-- <el-table-column
                        v-if="rowModuleList.controlType !== 3"
                        prop="id"
                        label="名称(ID)"
                    ></el-table-column> -->
                    <el-table-column prop="resourcePath" label="广告图">
                        <template slot-scope="scope">
                            <img style="width: 45px; height: 45px" :src="scope.row.resourcePath" />
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="rowModuleList.controlType !== 3"
                        prop="title"
                        label="名称"
                    ></el-table-column>
                    <el-table-column
                        v-if="rowModuleList.controlType === 3"
                        prop="title"
                        label="名称"
                    ></el-table-column>

                    <!-- 只有首页顶部banner需要排序 -->
                    <!-- <el-table-column
                        v-if="rowModuleList.controlType == 1"
                        prop="orderSort"
                        label="排序"
                    ></el-table-column> -->
                    <el-table-column prop="shelfStatus" label="是否上架">
                        <template slot-scope="scope">
                            <el-switch
                                style="display: block"
                                v-model="scope.row.shelfStatus == 0"
                                active-color="#13ce66"
                                inactive-color="#cccccc"
                                @change="listUpDownHandle(scope.row)"
                            ></el-switch>
                            <!-- <p>{{ scope.row.shelfStatus ? '下架' : '上架' }}</p> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                v-if="rowModuleList.controlType == 1"
                                @click="editTag(scope.row)"
                            >
                                编辑
                            </el-button>
                            <!-- <el-button
                                v-if="rowModuleList.controlType == 1"
                                type="text"
                                @click="editTag(scope.row, 'read')"
                            >
                                查看
                            </el-button>
                            <el-button
                                type="text"
                                v-if="rowModuleList.controlType == 1"
                                @click="listUpDownHandle(scope.row)"
                            >
                                {{ scope.row.shelfStatus ? '上架' : '下架' }}
                            </el-button> -->
                            <el-button type="text" @click="listDeleHandle(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-box" v-if="false">
                    <el-pagination
                        @size-change="listSizeHandle"
                        @current-change="listCurrentHandle"
                        :page-sizes="[10, 30, 50, 100]"
                        :current-page="page.index"
                        :page-size="page.size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.count"
                    ></el-pagination>
                </div>
            </div>
            <Detail
                v-if="detailDiag"
                :detailDiag="detailDiag"
                :module-id="rowModuleList.value"
                :id="currentRowid"
                :read="read"
                @closeDiag="closeDiag"
            ></Detail>
            <Exhibit
                v-if="exhibitDiag"
                :exhibitDiag="exhibitDiag"
                :id="rowModuleList.value"
                :listType="rowModuleList.listTypeValue"
                :read="read"
                @closeDiag="closeExdiag"
            ></Exhibit>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
    </div>
</template>

<script>
import Sortable from 'sortablejs'; //引入插件
import Detail from './details.vue';
import Exhibit from './exhibit-detail.vue';
import { getModuleList, hitTheShelfSoldOut, moduleListDelete, orderDec } from '@/api/mkt/market.js';

export default {
    name: '',
    components: {
        Detail,
        Exhibit,
    },
    props: {
        rowModuleList: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            btnList: [
                // {
                //     type: 'edit',
                //     name: '编辑',
                // },
                {
                    type: 'dele',
                    name: '删除',
                },
                {
                    type: 'upStatus',
                    name: '上架',
                },
                {
                    type: 'downStatus',
                    name: '下架',
                },
                // {
                //     type: 'add',
                //     name: '+添加',
                // },
            ],
            currentRowid: '',
            isShowParams: false,
            tableData: [],
            page: {
                count: 0, //总条数
                index: 1, //当前页
                size: 9999,
                show: true, //是否显示，默认false
            },
            detailDiag: false,
            exhibitDiag: false,
            orderIndexSortFlag: 2,
            searchValue: '',
            moduleIdArr: [],
            read: false,
            showStatus: false,
            tableHeightCalc: 120,
            innerHeight: 0,
            isSearchDown: false,
        };
    },

    created() {
        // 监控屏幕的高度
        const that = this;
        window.onresize = () =>
            (() => {
                that.innerHeight = window.innerHeight;
            })();
        this.$bus.$on('serchMore', data => {
            this.isSearchDown = data;
        });
        this.$nextTick(() => {
            console.log('created');
            this.tableHeightCalc = window.innerHeight - this.$refs.configTable.offsetTop - 190;
        });
    },
    mounted() {},
    watch: {
        isSearchDown: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.configTable.offsetTop - 190;
        },
        // 监控屏幕大小时设置高度
        innerHeight: function() {
            this.tableHeightCalc = window.innerHeight - this.$refs.configTable.offsetTop - 190;
        },
    },
    methods: {
        //行拖拽
        rowDrop() {
            const wrapperRow = document.querySelector('._listTable tbody');
            const that = this;
            Sortable.create(wrapperRow, {
                onEnd({ newIndex, oldIndex }) {
                    if (newIndex == oldIndex) return;
                    that.tableData.splice(newIndex, 0, that.tableData.splice(oldIndex, 1)[0]);
                    var newArray = that.tableData.slice(0);
                    that.tableData = [];
                    that.$nextTick(function() {
                        that.tableData = newArray;
                        // 拖拽事件结束
                        let list = [];
                        that.tableData.map(item => list.push(item.id));
                        const params = {
                            ids: list,
                        };
                        that.orderDec(params);
                        console.log('move', newIndex, oldIndex, that.tableData);
                    });
                },
            });
        },
        async orderDec(params) {
            const res = await orderDec(params);
            if (res?.isSuccess) {
                this.getModuleListHandle();
                // this.getTextimglist();
            }
        },
        editTag(row, type) {
            if (type) {
                this.read = true;
            } else {
                this.read = false;
            }
            if (this.rowModuleList.controlType == 3) {
                this.exhibitDiag = true;
            } else {
                this.currentRowid = row.id;
                this.detailDiag = true;
            }
        },
        closeDiag(type) {
            if (type) {
                this.getModuleListHandle();
            }
            this.detailDiag = false;
        },
        closeExdiag(type) {
            this.exhibitDiag = false;
            if (type) {
                this.getModuleListHandle();
            }
        },
        operationHandle(type) {
            switch (type) {
                case 'edit':
                case 'add':
                    this.read = false;
                    if (this.rowModuleList.controlType == 3) {
                        this.exhibitDiag = true;
                    } else {
                        this.detailDiag = true;
                    }
                    this.currentRowid = '';
                    break;
                case 'upStatus':
                    this.hitTheShelfSoldOutHandle(0);
                    break;
                case 'downStatus':
                    this.hitTheShelfSoldOutHandle(1);
                    break;
                case 'dele':
                    this.moduleListDeleteHandle();
                    break;
                default:
                    break;
            }
        },
        async getModuleListHandle() {
            const { index, size } = this.page;
            const { orderIndexSortFlag, searchValue, isShowParams } = this;
            const listParams = {
                page: index,
                size,
                orderIndexSortFlag,
                searchValue,
                isShow: isShowParams ? 0 : 1,
                moduleId: this.rowModuleList.value,
            };
            const { data, isSuccess } = await getModuleList(listParams);
            if (isSuccess) {
                // this.page.count = data.total;
                this.tableData = data.children;
                if (this.tableData.length) {
                    this.$nextTick(() => {
                        this.rowDrop(); //行拖拽
                    });
                }
            }
        },
        selectListHandle(arr) {
            this.moduleIdArr = arr.map(item => {
                return item.id;
            });
        },
        // 上下架
        async hitTheShelfSoldOutHandle(status) {
            const { moduleIdArr } = this;
            if (!moduleIdArr.length) {
                this.$message.info('请至少选择一项');
                return;
            }
            const { isSuccess } = await hitTheShelfSoldOut({
                id: moduleIdArr,
                state: status,
            });
            if (isSuccess) {
                this.moduleIdArr = [];
                this.$message.success(status ? '下架成功' : '上架成功');
                this.getModuleListHandle();
            }
        },
        // 删除
        moduleListDeleteHandle() {
            const { moduleIdArr } = this;
            if (!moduleIdArr.length) {
                this.$message.info('请至少选择一项');
                return;
            }
            this.$confirm('确定删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    moduleListDelete({
                        ids: moduleIdArr,
                    }).then(res => {
                        if (res.isSuccess) {
                            this.moduleIdArr = [];
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                            });
                            this.getModuleListHandle();
                        }
                    });
                })
                .catch(() => {});
        },
        listSizeHandle(val) {
            this.page.size = val;
            this.getModuleListHandle();
        },
        listCurrentHandle(val) {
            this.page.index = val;
            this.getModuleListHandle();
        },
        initSearchHandle() {
            this.page.index = 1;
            this.getModuleListHandle();
        },
        listDeleHandle(row) {
            this.moduleIdArr = [];
            this.moduleIdArr.push(row.id);
            this.moduleListDeleteHandle();
        },
        listUpDownHandle(row) {
            this.moduleIdArr = [];
            this.moduleIdArr.push(row.id);
            this.hitTheShelfSoldOutHandle(row.shelfStatus === 0 ? 1 : 0);
        },
    },
};
</script>
<style lang="less" scoped>
.list-main {
    min-height: 83vh;
    background: #fff;
}
.top-btn-box {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 20px;
}
.table-box {
    padding: 15px 10px;
}
.only-is-show {
    display: flex;
    align-items: center;
}
.pagination-box {
    text-align: right;
    margin-top: 5px;
}
/deep/ .el-table__row {
    td {
        // 设置拖动样式，好看一些
        cursor: move;
    }
}
</style>
