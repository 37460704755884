var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-t"},[_vm._v("基本信息")]),_c('sc-form-v2',{attrs:{"tablename":"CRM_CATEGORY","columnsNum":1,"columns":_vm.columns,"uploadConfig":_vm.uploadConfig,"result":_vm.result,"direction":"vertical","esayStyle":true,"columnsPaddingRight":20},on:{"change":_vm.changeFn},scopedSlots:_vm._u([{key:"categoryId",fn:function(){return [_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v(" 所属分类 "),_c('span',{staticClass:"must"},[_vm._v(" * ")])])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('el-cascader',{ref:"cateCascader",staticStyle:{"width":"95%"},attrs:{"show-all-levels":false,"size":_vm.size,"options":_vm.categoryList,"props":{
                            emitPath: false,
                            value: 'categoryId',
                            label: 'categoryName',
                        },"clearable":""},on:{"change":_vm.selectCateChange},model:{value:(_vm.result.categoryId),callback:function ($$v) {_vm.$set(_vm.result, "categoryId", $$v)},expression:"result.categoryId"}})],1)],2)]},proxy:true},{key:"relevanceLabelParams",fn:function(){return [_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v(" 标签 ")])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('el-cascader',{ref:"cascader",staticStyle:{"width":"95%"},attrs:{"size":_vm.size,"options":_vm.labelList,"placeholder":"最多选择20个","props":{
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },"show-all-levels":false,"clearable":"","filterable":""},on:{"change":_vm.selectTagChange},model:{value:(_vm.relevanceLabelParams),callback:function ($$v) {_vm.relevanceLabelParams=$$v},expression:"relevanceLabelParams"}})],1)],2)]},proxy:true},{key:"operationIds",fn:function(){return [_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v(" 运营标签 ")])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('el-cascader',{ref:"operaCascader",staticStyle:{"width":"95%"},attrs:{"size":_vm.size,"options":_vm.operationList,"placeholder":"最多选择20个","props":{
                            label: 'labelName',
                            value: 'id',
                            checkStrictly: true,
                            emitPath: false,
                            multiple: true,
                        },"show-all-levels":false,"clearable":"","filterable":""},on:{"change":_vm.selecttOpeTag},model:{value:(_vm.operationIds),callback:function ($$v) {_vm.operationIds=$$v},expression:"operationIds"}})],1)],2)]},proxy:true},(_vm.isShowCompanys)?{key:"companys",fn:function(){return [_c('el-descriptions-item',{attrs:{"span":"2","labelStyle":{ background: '#fff' }}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelName"},[_vm._v(" 分公司 ")])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('el-select',{staticStyle:{"width":"95%"},attrs:{"size":_vm.size,"multiple":"","placeholder":"请选择"},on:{"change":_vm.seletChange},model:{value:(_vm.companys),callback:function ($$v) {_vm.companys=$$v},expression:"companys"}},_vm._l((_vm.companyData),function(item){return _c('el-option',{key:item.ouCode,attrs:{"label":item.ouName,"value":item.ouCode}})}),1)],1)],2)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"title-t"},[_vm._v("管理内容")]),_c('div',{staticClass:"content-art"},[_vm._l((_vm.manageContentList),function(item,index){return _c('div',{key:index,staticClass:"item-art"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"el-icon-circle-close"})])}),_c('el-button',{attrs:{"size":"mini","icon":"el-icon-plus"},on:{"click":_vm.createArticle}},[_vm._v("添加内容")])],2),_c('div',{staticStyle:{"text-align":"right","margin":"20px 0"}},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"size":"mini"},on:{"click":_vm.cancelClose}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleClose}},[_vm._v("确定")])],1),_c('article-drawer',{ref:"articleDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }